import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { compose } from 'recompose';

import Cabecera from '../Cabecera/index_365'
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import Portada from '../Landing/index copy'
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';

import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import  green from '@material-ui/core/colors/green';
import Grid from '@material-ui/core/Grid';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
});

const SignUpPage = () => (
  <div>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    const { username, email, passwordOne, passwordTwo } = this.state;
    if(passwordOne!==passwordTwo) {  this.setState({ error:"Las contraseñas deben de ser iguales" });}
    else{ this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        // Create a user in your Firebase realtime database
        return this.props.firebase
          .user(authUser.user.uid)
          .set({
            username,
            email,
          });
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
   }
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const {
      username,
      email,
      passwordOne,
      passwordTwo,
      error,
    } = this.state;
    const isInvalid =
    passwordOne !== passwordTwo ||
    passwordOne === '' ||
    email === '' ||
    username === '';
    return (
      <Grid >
      <Cabecera/>
      <hr/>      
           <Grid>
            <Portada/>
            <Grid    style={{position:'absolute', top:130, right: 10, width:'40%'  }}>      
             <ThemeProvider theme={theme}>       
                  <Grid container direction="row"  justifyContent="center" >    
                    
                      <Avatar   backgroundColor= '#12C10F'>
                               <LockOutlinedIcon />
                      </Avatar>      
                  </Grid>    
                  <Grid container direction="row"  justifyContent="center" >
                      <h1>Recupera tu contraseña</h1>
                  </Grid>
                  <form onSubmit={this.onSubmit}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="username"
                          label="Nombre Completo"
                          name="username"
                          autoComplete="username"
                          autoFocus
                          value={username}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Nombre Completo"
        
                        />
                    
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          value={email}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Email Address"
        
                        />
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="passwordOne"
                          label="passwordOne"
                          id="passwordOne"
                          autoComplete="current-password"
                          value={passwordOne}
                          onChange={this.onChange}
                          type="password"
                          placeholder="Introduce la contraseña"
                 
                        />
                      <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="passwordTwo"
                          label="PasswordTwo"
                          id="passwordTwo"
                          autoComplete="current-password"
                          value={passwordTwo}
                          onChange={this.onChange}
                          type="password"
                          placeholder="Confirmar contraseña"
                 
                        />
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          disabled={isInvalid}
                          >
                          Entrar
                        </Button>
                        {error && <p>{error.message}</p>} 
                        </form>
            
                  </ThemeProvider>
      </Grid>
    </Grid>
    </Grid>
    );
  }
}

const SignUpLink = () => (
  <p>
    ¿No tienes cuenta? <Link to={ROUTES.SIGN_UP}>Regístrate</Link>
  </p>
);
const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);
export default SignUpPage;
export { SignUpForm, SignUpLink };