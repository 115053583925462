import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import {Modal,Snackbar,ListItemIcon,Paper,IconButton,ListItemSecondaryAction,TextField,
  Box,Backdrop,CircularProgress,Fab,Divider,List,ListItem,Grid,ListItemText
,Checkbox,Button,Card,CardHeader} from '@material-ui/core';
import {Visibility,Close,Add,Search,Delete,BorderColor,Save,DoneOutline} from '@material-ui/icons';
import BotonesTriger from '../Botones/botonTrige'
import Cabecera from '../Cabecera/index_365'
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import green from '@material-ui/core/colors/green'; 
import ModalTutoriales, {traerTutoriales} from  '../Tutoriales/tutoriales'
import firebase2  from 'firebase'
import Copyright from '../Privacidad/copyright';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
      claro: green[200]
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#e81446',
    },
  },
});

class Categorias extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid:"",
      añadir: true,
      incis: [],
      identIncial:'',
      comus: [],
      open: false,
      comunidad:'', 
      tipo:'guardar',
      categoria:'',
      mostrarTabla: false,
      selectedIndex:'',
      email: '',
      categoriaBorrar:"",
      password: '',
      error: null,
      error2: null,
      modificar: true,
      listaComunidades: [],
      ComunidadesAsig:[],
      ComunidadesNoAsig:[],
      tenemosLista:false,
      leftChecked:[],
      rightChecked:[],
      left:[1,2,3,4],
      leftOriginal:[],
      right:[5,6,711], 
      checked:[],
      snack:false, snack2:false, vertical: 'top',
      horizontal: 'center', 
      carpetas:[],
      Pdfs:[]

  };
    //this.handleChange = this.handleChange.bind(this);
    this.BuscarCategorias= this.BuscarCategorias.bind(this);
    this.handleCheckedRight=this.handleCheckedRight.bind(this);
    this.preparaComunidad= this.preparaComunidad.bind(this);
    this.borrarFiltros = this.borrarFiltros.bind(this);
    this.añadir = this.añadir.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.buscarComunidades = this.buscarComunidades.bind(this);
    this.comprobarExistecategoria = this.comprobarExistecategoria.bind(this)
    this.traerCategorias=this.traerCategorias.bind(this)
  
    this.handleClose =     this.handleClose.bind(this)
    this.anularcategoria = this.anularcategoria.bind(this)

  }

handleClose () {
  this.setState({snack:false, snack2:false})
};

componentDidMount() {
  this.buscarComunidades(); 
  this.traerCategorias()
  traerTutoriales(this, 'Categorias').then((data)=>{
    this.setState({Pdfs:data})})
 
 
}
  
buscarComunidades(){
  this.props.firebase.comus().once('value', snapshot => {
    const usersObject = snapshot.val();
    if(usersObject){
      
    const comusList = Object.keys(usersObject).map(key => ({
      ...usersObject[key],
      uid: key,
    }));
    comusList.sort(function (A, B) {
      var a =Number(A.Id.trim())
      var b =Number(B.Id.trim())
      if(!a){a=-1}
      if(!b){b=-1}
      if( a > b) {
        return 1;
      }
      if (a < b) {
        return -1;

      }
      return 1;
    })
    this.setState({comus: comusList});
  }else{ this.setState({comus: []});}
  });

}
componentWillUnmount() {
  this.props.firebase.categorias().off()
  
}
traerCategorias(){
    this.props.firebase.categorias().on('value', snapshot => {
      const usersObject = snapshot.val();
      if(usersObject){

      
          const categoriaList = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));

          categoriaList.sort(function (a, b) {
            if (a.Nombre> b.Nombre) {
              return 1;
            }
            if (a.Nombre < b.Nombre) {
              return -1;
            }
          // a must be equal to b
            return 0;
          });

          
          this.setState({categs: categoriaList });
          this.setState({ mostrarTabla: true, open:false }); 

        }
        else{ this.setState({ mostrarTabla: true, open:false }); }
      
  
        })


}
BuscarCategorias(){
    this.buscarComunidades();
    this.setState({open:true, mostrarTabla: false, añadir: true, tenemosLista:false })  
    this.borrarFiltros();
    this.traerCategorias();
  } 
borrarFiltros(){ 
        this.setState({ComunidadesNoAsig:[] , ComunidadesAsig:[], categs:[], nombre:'', descripcion:'', 
        tenemosLista:false });
  }

filtrarComunidad(listaComunidades){;

    var comunidadesAFiltrar=listaComunidades;
    var comunidades = this.state.comus.filter(comunidad => comunidad.uid !== -1 );
    var comunidades2 =this.state.comus.filter(comunidad => comunidad.uid !== -1 );

    if(!this.state.comus.empty){
      if(comunidadesAFiltrar){
          comunidades= comunidades.filter(comunidad => comunidad.Id ===comunidadesAFiltrar[comunidad.Id] );
          if (!comunidades.empty){    
                comunidades2= comunidades2.filter(comunidad =>
                comunidad.Id !==comunidadesAFiltrar[comunidad.Id] );
                this.setState({left: comunidades,leftOriginal:comunidades, right: comunidades2, tenemosLista:true, leftChecked : [],
                  rightChecked:[], checked:[]})
          }
      }else{
        this.setState({left:[], right: comunidades2, tenemosLista:true, leftChecked : [], leftOriginal:[],
        rightChecked:[], checked:[]}) 
      }
        
    }
    return true
  }
handleListItemClick (event, categoria, categ){   
    const comunidades = this.filtrarComunidad(categoria.Comunidad)
    this.setState({categoria: categoria, identIncial:categoria.Nombre})
    if (categ === "visualizar"){ 
      
       this.setState({nombre:categoria.Nombre, descripcion:categoria.Descripcion, modificar:true, tenemosLista:true }) 
    }
    else if (categ === "modificar"){
      this.setState({nombre:categoria.Nombre, descripcion:categoria.Descripcion, modificar:false, tenemosLista:true
      ,tipo:"guardar" }) 
    }
    else if (categ === "delete"){
      this.setState({nombre:categoria.Nombre,uid:categoria.uid, descripcion:categoria.Descripcion, modificar:true,snack2:true,
      categoriaBorrar:"La categoria "+ categoria.Nombre+ " será borrada"})
     // alert('¿Deseas borrar la categoria?: ' + categoria.Nombre )

    }


 }; 
preparaComunidad(){
  var resul={}
  this.state.left.map((value) => resul[value.Id]=value.Id ); 
  return resul;
}
 onSubmit = event => {
  const resul = this.preparaComunidad(); 
  const comprobar =this.comprobarExistecategoria()
  
      if(this.state.tipo==="guardar"){
        if(this.state.identIncial ===this.state.nombre || 
          (this.state.identIncial !==this.state.nombre&&comprobar===0) ){
             this.props.firebase.categoria(this.state.categoria.uid).update({
              Nombre: this.state.nombre,
              Descripcion: this.state.descripcion,
              Comunidad : resul,
              Id:firebase2.database.ServerValue.TIMESTAMP,
              Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
            }).then(() => {
                //Eliminamos la relacion de la categoria para las comunidad de la columna izquierda
                this.anularyasociarComunidadDecategoria(this.state.categoria.uid)
                this.setState({snack:true, 
                  error2:"La categoría " +this.state.nombre+" has sido actualizada correctamente" })
              })
              .catch(error => {
                this.setState({ error });
              });
            
        }else{ this.setState({snack:true, 
            error2:"La categoría " +this.state.nombre+" ya existe, por favor verificar el nombre" });}
      }
     else {
      
          if (comprobar.length === 0){
                this.props.firebase.categorias().push({
                Nombre: this.state.nombre,
                Descripcion: this.state.descripcion,
                Comunidad : resul,
                Id: firebase2.database.ServerValue.TIMESTAMP,
                Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
             }).then((data) => {
                this.anularyasociarComunidadDecategoria(data.key)
                this.setState({snack:true, 
                  error2:"La categoria " +this.state.nombre+" se ha creado correctamente" })
              
                })
                .catch(error => { this.setState({ error });
                });

          }else{ this.setState({snack:true, 
            error2:"La categoria " +this.state.nombre+" ya existe, por favor verificar el nombre" });}
         }

    event.preventDefault();
};
comprobarExistecategoria() {
  var existe=[];
  const categorias =this.state.categs;
  if(!categorias || categorias.lenght<1){return existe}
  existe = categorias.filter(categoria => categoria.Nombre===this.state.nombre )
  return existe;       
}
onChange = event => {
  this.setState({ [event.target.name]: event.target.value });
};

anularyasociarComunidadDecategoria(uid){
  //la primera promesa para cancelar las comunidades de la columna de la izquierda
  new Promise(resolve => {
    
    const listaderecha = this.state.right
    if(listaderecha&&listaderecha.length>0){
        this.state.right.forEach((comunidad) => {
      //      let Com = this.state.comus.filter(comuni => comuni.Id === comunidad)
            this.props.firebase.comu(comunidad.uid+'/Categorias/'+uid).remove()
        //     this.componentDidMount();
           
            .then(() => {resolve()})
        })
      }else{ resolve()}

    }).then (()=>{ 
      new Promise(resolve => {
        setTimeout(() => {
          //MIRAMOS CUALES SON LAS NUEVAS RESPECTO A LA LISTA INICIAL DE COMUNIDADES ASOCIADAS
            var  crearnueva=this.state.left.filter(comunidad => (!this.state.leftOriginal.includes(comunidad))) 
            
            if (crearnueva){
                crearnueva.forEach((comunidad) => {
                    this.props.firebase.comu(comunidad.uid+'/Categorias/').update({
                      [uid]:this.state.nombre ,})
                        
                    
                    
                  })
            }
          //Actalicemos o no porque no haya qeu actualizar. si que la lista original de la ziqueirda cambia
          this.setState({leftOriginal:this.state.left})
          resolve()
        }, 1);
      })
      .then (()=>{ 
      this.BuscarCategorias()   
    })
    })
}

anularcategoria() {
  
 this.state.left.forEach((comunidad) => { 
    
//     this.componentDidMount();
  this.props.firebase.comu(comunidad.uid+'/Categorias/'+this.state.uid).remove()
})


  this.props.firebase.categoria(this.state.uid).remove()
 

    this.setState({snack2:false, snack:true, error2:"La categoría ha sido borrada" });  
    this.BuscarCategorias();

    
  
  
}

añadir(){
    
    this.setState({añadir: false, mostrarTabla:false
    , modificar: false, tenemosLista: true, nombre:'', descripcion:'', tipo:"añadir"})
    this.filtrarComunidad();
}
cancelar(){
  this.setState({añadir: true, mostrarTabla:true, tenemosLista: false
  , modificar: true})
  this.borrarFiltros()
}

 render() {

    const { mostrarTabla } = this.state;
    //const comus = this.state.comus
    const categs = this.state.categs;
    const { nombre, descripcion, error } = this.state;
    const isInvalid = nombre === '' || descripcion === '';
   const vertical= this.state.vertical;
   const horizontal= this.state.horizontal;
   return (     
      <Grid >
        <Cabecera/>
        <hr />
        <Grid style={{ position: 'absolute', right: 20, top: 20 , maxWidth:"15%", maxHeight:"15%"}}>
          <Button variant="contained" color="primary" onClick={() => { this.setState({ mostrarAyuda: true }) }}>
            AYUDA (TUTORIALES)
          </Button>
        </Grid>

        <BotonesTriger/>       
        <Grid container spacing={0}>
        <ThemeProvider theme={theme}>
         <Snackbar         anchorOrigin={{ vertical , horizontal }}
                          key={`${this.state.vertical},${this.state.horizontal}`}
                          open={this.state.snack}
                          autoHideDuration={5000}
                          onClose={this.handleClose}
                          message={this.state.error2}
                          action={
                            <div>
                              <Button color="primary" size="small" onClick={this.handleClose}>
                                Cerrar
                              </Button>
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={this.handleClose}
                              >
                                <Close/>
                              </IconButton>
                            </div>
                          }
         />
           <Snackbar         anchorOrigin={{ vertical , horizontal }}
                          key={`${this.state.vertical},${this.state.horizontal}`}
                          open={this.state.snack2}
                          autoHideDuration={5000}
                          onClose={this.handleClose}
                          message={this.state.categoriaBorrar}
                          action={
                          <div>
                                 <Button onClick={this.anularcategoria}>  
                                      <Fab  size="small" color="primary" aria-label="add"  variant="extended">
                                          <DoneOutline/>
                                        CONFIRMAR
                                       </Fab>
                                  </Button>
                                  <Button onClick={this.handleClose}>  
                                      <Fab  size="small" color="secondary" aria-label="add"  variant="extended">
                                      <Close/>
                                        CANCELAR
                                       </Fab>
                                  </Button>
                            </div>
                          }
         />           
        
              <Grid item xs style={{marginLeft:20}}>
                  <Button onClick={this.BuscarCategorias} variant="outlined"  
                  size="large" color="primary"  startIcon={<Search />}>
                                      LISTADO ACTUAL DE CATEGORÍAS
                  </Button>
                  <Backdrop  color = 'green' open={this.state.open}>
                            <CircularProgress color="green" />
                  </Backdrop>
                 {(this.state.añadir ?
                                 <Button onClick={this.añadir}>  
                                      <Fab  size="small" color="primary" aria-label="add"  variant="extended">
                                          <Add />
                                        Añadir
                                       </Fab>
                                  </Button>
                      :
                                <Button onClick={this.cancelar}>  
                                    <Fab  size="small" color="secondary" aria-label="cancel"   variant="extended">
                                    <Close/>
                                      Cancelar
                                    </Fab>
                                </Button>
                   )}
                
                  {(mostrarTabla&& <Paper style={{maxHeight: 490, overflow: 'auto', width:600}}>
              
                    <List component="nav" dense="true"  aria-label="main mailbox folders" justify="space-around" alignItems="center" >
                      {categs && categs.map(categoria => (                  
                      <Grid>
                        <Divider />
                      <ListItem
                      button
                      selected={this.selectedIndex === categoria.Nombre}
                      onClick={(event) => this.handleListItemClick(event, categoria, "visualizar" )}
                      >
                      <ListItemText primary={categoria.Nombre} secondary={categoria.Id} />
                      <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete"
                          onClick={(event) => this.handleListItemClick(event, categoria, "delete" )}>
                          <Delete />
                          </IconButton>               
                          <IconButton edge="end" aria-label="visibility"
                          onClick={(event) => this.handleListItemClick(event, categoria, "visualizar" )}>
                          <Visibility />
                          </IconButton>
                          <IconButton edge="end" aria-label="borderColor" 
                          onClick={(event) => this.handleListItemClick(event, categoria, "modificar" )}>
                          <BorderColor />
                          </IconButton>
                      </ListItemSecondaryAction>
                      </ListItem>                      
                      </Grid>
                      ))}
                  </List>
              </Paper>)}
              </Grid>
              {(this.state.tenemosLista&&<Grid item xs style={{marginRight:20, marginTop:10}}>
              <Paper style={{ width:700}}>
                 <form >
                       
                 
                       <Button onClick={this.onSubmit}    fullWidth 
                             size="large" color="primary"  startIcon={<Save />   }   variant="contained"
                             disabled ={this.state.modificar ||isInvalid}   >                                  
                             GUARDAR
                       
                        </Button>
                        {error && <p>{error.message}</p>} 
                  
                        <TextField  InputLabelProps={{ shrink: true }}
                          disabled ={this.state.modificar}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="nombre"
                          
                          label="Nombre categoria"
                          name="nombre"
                          autoComplete="nombre"
                          autoFocus
                          value={this.state.nombre}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Nombre Categoría"
                        />
                        <TextField  InputLabelProps={{ shrink: true }}
                        disabled ={this.state.modificar}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="descripcion"
                          label="Descripción"
                          id="descripcion"
                          autoComplete="descripcion"
                          value={descripcion}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Descripción categoria"
                        />
                    
                        <Grid container spacing={1} justifyContent="center" alignItems="center" margin= 'auto'>
     
                          <Grid item>{this.customList('Comunidades Asignadas', this.state.left,"izquierdo")}</Grid>
                          <Grid item>
                            <Grid container direction="column" alignItems="center">
                            <Button
                              variant="outlined"
                              size="small"
                            
                              onClick={this.handleCheckedRight}
                              disabled={(this.state.leftChecked.length === 0)||(this.state.left.length === 0)
                                 || this.state.modificar} 
                              aria-label="move selected right"
                            >
                              &gt;
                            </Button>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={this.handleCheckedLeft}
                              disabled={(this.state.rightChecked.length === 0)||(this.state.right.lenght === 0)
                                 || this.state.modificar }
                              aria-label="move selected left"
                            >
                              &lt;
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item>{this.customList('Comunidades Seleccionables', this.state.right,"derecho")}</Grid>
                      </Grid>
                    </form>  

              </Paper>
              </Grid>)}
            </ThemeProvider>
      </Grid>
      
      <Box mt={40}>
              <Copyright />
      </Box>
      <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}

              open={this.state.mostrarAyuda}
              onClose={e=>this.setState({mostrarAyuda:false})}


            >
            {this.state.mostrarAyuda&&<ModalTutoriales Pdfs= {this.state.Pdfs}/> 
          }
           </Modal>
      
    </Grid>
    );
  }
  handleCheckedLeft = () => {
    this.setState({left: this.state.left.concat(this.state.rightChecked)})
    this.setState({right: this.not(this.state.right,this.state.rightChecked)})
    this.setState({checked:this.not(this.state.checked, this.state.rightChecked)});
    this.setState({ leftChecked : this.intersection(this.state.checked, this.state.left),
      rightChecked:[]})
    
  };
  handleCheckedRight (){
    
    this.setState({right: this.state.right.concat(this.state.leftChecked)})
    this.setState({left: this.not(this.state.left,this.state.leftChecked)})
    this.setState({checked: this.not(this.state.checked,this.state.leftChecked)});
    
      
    this.setState({ leftChecked : [],
      rightChecked:this.intersection(this.state.checked, this.state.right)})
};

numberOfChecked (items) {
    return (this.intersection(this.state.checked, items).length)
}

handleToggleAll = (items, lado) => () => {
    if (this.numberOfChecked(items) === items.length) {
   //   setChecked(not(this.state.checked, items.index));
      this.setState({checked:this.not(this.state.checked, items)});
      if (lado==="derecho"){
        this.setState({  rightChecked:[]});
      }else{
         this.setState({ leftChecked:[]});
      }   
    } else {
      this.setState({checked:this.union(this.state.checked, items)});
      if (lado==="derecho"){
        this.setState({  rightChecked: this.state.right});
      }else{
         this.setState({ leftChecked:this.state.left});
      }
   
    }
   
    
  };

not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

union(a, b) {
  return [...a, ...this.not(b, a)];
}
handleToggle (value){
  const currentIndex = this.state.checked.indexOf(value);
  const newChecked = this.state.checked;

  if (currentIndex === -1) {
    newChecked.push(value);
  } else {
    newChecked.splice(currentIndex, 1);
  }

  this.setState({checked: newChecked})
  this.setState({  leftChecked:this.intersection(this.state.checked, this.state.left) });
  this.setState({  rightChecked:this.intersection(this.state.checked, this.state.right) });
    
};
customList = (title, items, lado) => (
  <Card >
     <ThemeProvider>
    <CardHeader
      avatar={
        <Checkbox  
          onClick={this.handleToggleAll(items,lado)}
          checked={this.numberOfChecked(items) === items.length && items.length !== 0}
          indeterminate={this.numberOfChecked(items) !== items.length && this.numberOfChecked(items) !== 0}
          disabled={items.length === 0 || this.state.modificar}
          inputProps={{ 'aria-label': 'all items selected' }}
          color= "primary"
        />
      }
      title={title}
      subheader={`${this.numberOfChecked(items)}/${items.length} selected`}
    />
    <Divider />
    <List style={{width: 200, height: 230,backgroundColor: "primary", overflow: 'auto',}} dense component="div" role="list">
      {items.map((value) => 
           <ListItem key={value} role="listitem" button 
           onClick={() => {this.handleToggle(value)}}
           disabled = {this.state.modificar } >
            <ListItemIcon>
              <Checkbox
                checked={this.state.checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple1 
                disabled = {this.state.modificar}
                inputProps={{ 'aria-labelledby': `transfer-list-all-item-${value}-label` }}
                color= "primary"
              />
              
            </ListItemIcon>
            <ListItemText id={`transfer-list-all-item-${value}-label`}primary={`${value.Id} - ${value.Nombre}`}/> 
            
          </ListItem>
      )}
    </List>
    </ThemeProvider>
  </Card>
 );
}

const condition = authUser => !!authUser;
export default withFirebase(withAuthorization(condition)(Categorias));