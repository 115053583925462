import React , {useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withFirebase } from '../Firebase';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SyncIcon from '@material-ui/icons/Sync';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import { green, red } from '@material-ui/core/colors';
import DuoIcon from '@material-ui/icons/Duo';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import GavelIcon from '@material-ui/icons/Gavel';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import SpeedIcon from '@material-ui/icons/Speed';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

import LooksOneIcon from '@material-ui/icons/LooksOneOutlined';
import LooksTwoIcon from '@material-ui/icons/LooksTwoOutlined';
import Looks3Icon from '@material-ui/icons/Looks3Outlined';
import Looks4Icon from '@material-ui/icons/Looks4Outlined';

import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Link from '@material-ui/core/Link';


import Video from  '../../Imagenes/mundo.mp4'

import Sidebar from './Sidebar/Sidebar';

import Header from './Header/Header';
import CardMedia from '@material-ui/core/CardMedia';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Copyright from '../Privacidad/copyright'
import Miscookies from '../Privacidad/cookies'
import './Landing.css'
import { withStyles } from "@material-ui/core/styles";
import { Breakpoint, useCurrentBreakpointName  } from 'react-socks';
import DoneIcon from '@material-ui/icons/Done';
import { createTheme  } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import TagManager from 'react-gtm-module'


const tagManagerArgs = {
  dataLayer: {
      userId: '001',
      userProject: 'project',
      page: 'landing'
  },
  dataLayerName: 'PageDataLayer'
}

const theme = createTheme ({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
      claro: green[200]
    },
    secondary: {
      // This is green.A700 as hex.
      main:  'rgb(26, 5, 77)',
    },
  },
});

export const CustomComponent = () => {
  const breakpoint = useCurrentBreakpointName()
  if (breakpoint === 'small' || breakpoint === 'xsmall' ||breakpoint === 'medium'  ) {
    return ('pequeño')
  } else {
    return ('grande')
  }
}

 export function cumulativeOffset(element) {
  var top = 0, left = 0;
  do {
      top += element.offsetTop  || 0;
      left += element.offsetLeft || 0;
      element = element.offsetParent;
  } while(element);

  return {
      top: top,
      left: left
  };
};

export function focoBoton(boton){
  const boton1 = document.getElementById("boton1");
  const boton2 = document.getElementById("boton2");
  const boton3 = document.getElementById("boton3");
  const boton4 = document.getElementById("boton4");
  const boton5 = document.getElementById("boton5");
  const boton6 = document.getElementById("boton6");
  const boton7 = document.getElementById("boton7");
  const boton8 = document.getElementById("boton8");

  const ventana1 = document.getElementById("ventana1");
  const ventana2 = document.getElementById("ventana2");
  const ventana3 = document.getElementById("ventana3");
  const ventana4 = document.getElementById("ventana4");
  const ventana5 = document.getElementById("ventana5");
  const ventana6 = document.getElementById("ventana6");
  const ventana7 = document.getElementById("ventana7");
  const ventana8 = document.getElementById("ventana8");
  if(boton1){boton1.style.backgroundColor='transparent'}
  if(boton2){boton2.style.backgroundColor='transparent'}
  if(boton3){boton3.style.backgroundColor='transparent'}
  if(boton4){boton4.style.backgroundColor='transparent'}
  if(boton5){boton5.style.backgroundColor='transparent'}
  if(boton6){boton6.style.backgroundColor='transparent'}
  if(boton7){boton7.style.backgroundColor='transparent'}
  if(boton8){boton8.style.backgroundColor='transparent'}
  

  const boton1pos =cumulativeOffset(ventana1)
  const boton2pos =cumulativeOffset(ventana2)
  const boton3pos =cumulativeOffset(ventana3)
  const boton4pos =cumulativeOffset(ventana4)
  const boton5pos =cumulativeOffset(ventana5)
  const boton6pos =cumulativeOffset(ventana6)
  const boton7pos =cumulativeOffset(ventana7)
  const boton8pos =cumulativeOffset(ventana8)
  const ajuste=0
  var titulo_app=document.getElementById("titulo_app")

  if (boton>=boton1pos.top-ajuste&&boton<boton2pos.top-ajuste){    
    if(titulo_app){
      titulo_app.classList.remove("titulo_app");
      titulo_app.classList.add("titulo_app");
      titulo_app.textContent='Quienes somos'}
    if(boton1){boton1.style.backgroundColor='rgb(18, 92, 40)'}}
  if (boton>=boton2pos.top-ajuste&&boton<boton3pos.top-ajuste){  
    if(titulo_app){titulo_app.textContent='Producto'}
    if(boton2){boton2.style.backgroundColor='rgb(18, 92, 40)'}}
  if (boton>=boton3pos.top-ajuste&&boton<boton4pos.top-ajuste){ 
    if(titulo_app){titulo_app.textContent='Ventajas'}
    if(boton3){boton3.style.backgroundColor='rgb(18, 92, 40)'}}
  if (boton>=boton4pos.top-ajuste&&boton<boton5pos.top-ajuste){ 
    if(titulo_app){titulo_app.textContent='Valores'}
    if(boton4){boton4.style.backgroundColor='rgb(18, 92, 40)'}}
  if (boton>=boton5pos.top-ajuste&&boton<boton6pos.top-ajuste){  
    if(titulo_app){titulo_app.textContent='Contratación'}
    if(boton5){boton5.style.backgroundColor='rgb(18, 92, 40)'}}
  if (boton>=boton6pos.top-ajuste&&boton<boton7pos.top-ajuste){ 
    if(titulo_app){titulo_app.textContent='Medio Ambiente'}
    if(boton6){boton6.style.backgroundColor='rgb(18, 92, 40)'}}
  if (boton>=boton7pos.top-ajuste&&boton<boton8pos.top-ajuste){  
    if(titulo_app){titulo_app.textContent='Equipo'}
    if(boton7){boton7.style.backgroundColor='rgb(18, 92, 40)'}}

  if (boton>=boton8pos.top){  
    if(titulo_app){titulo_app.textContent='Actualidad'}
    if(boton8){boton8.style.backgroundColor='rgb(18, 92, 40)'}}
  

}



function fijarCabecera(offsetStick, setoffsetStick,setmostrarFoto) {
  
  var header = document.getElementById("cabecera");
 if(header){

  //Dejamos hueco a la barra superior restando 40



  var sticky = cumulativeOffset(header).top 
  var barraSuperior =''
  if (window.pageYOffset >= sticky) {
    
    barraSuperior = document.getElementById("barraSuperior");

    if(barraSuperior){
      barraSuperior.style.transform = 'translateY(12.5ch)'
      barraSuperior.style.transition = 'transform 0.7s easy'
    }

  }else{
    barraSuperior = document.getElementById("barraSuperior");

    if(barraSuperior){
      barraSuperior.style.transform = 'translateY(-12.5px)'
      barraSuperior.style.transition = 'transform 0.7s easy'
    }


  }

  if (sticky<0){sticky=offsetStick}
  
  var cabe2 =''
  var sidebar =''
  var sidebar1 =''
   if (window.pageYOffset >= sticky) {
    setoffsetStick(sticky)
    //cuando está la cabecera arriba fija tamaño grande

  
    header.classList.add("sticky");
     cabe2 = document.getElementById("cabe2");

    if(cabe2){ 
      cabe2.style.backgroundColor = 'rgb(100, 165, 120)'
      cabe2.style.transition= 'background-color 0.7s ease';
     
      
    }

    //cuando está la cabecera arriba fija tamaño pequeño
  
    sidebar = document.getElementById("sidebar-top");
    if(sidebar){ sidebar.style.backgroundColor =  'rgb(100, 165, 120)'
                sidebar.style.transition= 'background-color 0.7s ease';
                sidebar.style.marginTop='2.3vh'}
    sidebar1 = document.getElementById("sidebar");
    if(sidebar1){ sidebar1.style.backgroundColor ='rgb(100, 165, 120)'}
    focoBoton(window.pageYOffset)
    setmostrarFoto(false)
  }
  else {
    setmostrarFoto(true)
    header.classList.remove("sticky");
   // setoffsetStick(-1);
     //cuando está la cabecera abajo fija tamaño pequeño
     var  titulo_app = document.getElementById("titulo_app");
     
    if(titulo_app&&titulo_app.textContent!==''){titulo_app.textContent=''}
    cabe2 = document.getElementById("cabe2");
    if
    (cabe2){cabe2.style.backgroundColor = 'rgb(29, 68, 29)'
    cabe2.style.transition= 'background-color 0.7s ease';
    cabe2.style.marginTop=''
    focoBoton(-1)
        }

   //cuando está la cabecera abajo fija tamaño pequeño
    sidebar = document.getElementById("sidebar-top");  
    if(sidebar){ sidebar.style.backgroundColor ='rgb(29, 68, 29)'
                sidebar.style.transition= 'background-color 0.7s ease';
               sidebar.style.marginTop='';
              }
    sidebar1 = document.getElementById("sidebar");
    if(sidebar1){ sidebar1.style.backgroundColor ='rgb(29, 68, 29)'}
  }



}
}

const LimitedBackdrop = withStyles({
  root: {
    position: "absolute",
    zIndex: 1
  }
 })(Backdrop);



function Landing(props) {
  TagManager.dataLayer(tagManagerArgs)
 // const [tamaño, setTamaño] = useState(CustomComponent());
  const [offsetStick, setoffsetStick] = useState(10000);
  const [abrirAviso, setabrirAviso] = useState(false);
  const [texto_nombre, setNombre] =useState(''); 
  const [texto_email, setEmail] =useState(''); 
  const [texto_tel, setTel] =useState(''); 
  const [texto_obser, setObser] =useState(''); 
  const [cliente_tipo, setCliente] = useState('comu')
  const [okEnvioAviso,setokEnvioAviso] = useState(false)
  const [abrirPrueba,setoPrueba] = useState(false)
  const [openBackdrop,setOpenBackdrop] = useState(false)

const [mostrarFoto,setmostrarFoto] = useState(true)
  
  
  function borrarTextos() {   
    setNombre('')
    setEmail('')
    setTel('')
    setObser('')
    setCliente('comu')
    
   
  }

  function enviarFormulario(){
    setabrirAviso(false)
    setOpenBackdrop(true)
    var generateEmailVerificationLink= props.firebase.functions.httpsCallable('enviarEmailSolicitudInfo');
                                        
    new Promise((resolve,reject) => {  
      if(cliente_tipo)
         
      generateEmailVerificationLink({texto_email:texto_email,texto_tel:texto_tel,
        texto_nombre:texto_nombre, texto_obser:texto_obser, cliente_tipo:cliente_tipo}).then(function(result){
          // Read result of the Cloud Function.
         
          setOpenBackdrop(false)
          setokEnvioAviso(true)
          resolve()                                 

        }).catch(function(error) {
          // Getting the Error details.
        
          reject(error)
          // ...
        });
      })

  }
  
  window.onscroll = function() { 
    if (!abrirAviso && !abrirPrueba){fijarCabecera(offsetStick, setoffsetStick, setmostrarFoto)}
 

  //'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;'Lucida Sans Regular', 
  }

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (abrirPrueba) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [abrirPrueba]);

return (
  <Grid  >

    <ThemeProvider theme={theme}>  
   

    <LimitedBackdrop open={openBackdrop}>
         <CircularProgress  size={80} color="primary" />
           
    </LimitedBackdrop>
    
    <Miscookies/>    
   
      <Breakpoint large up>        
      <Grid  class="paperContainer" >
      
         <div class="titulo" >
          <div className='cuadro_portada'>     
          <p className='titulo_mediano' ><p className='titulo_grande'>La app definitiva para tu comunidad de vecinos</p>Nuestra plataforma en cloud y app móvil conecta 
            a todos los vecinos y permite gestionar todos los procesos de una comunidad en un sólo click.
             
            </p>
           
          </div>
          <div className="imagen" >
        
            <a className="b" href="# "  role="button" onClick={(e) =>setabrirAviso(true)}>Únete</a>
             
          </div>
          
      </div>
      <div className="barraSuperior" id="barraSuperior" >
             <div class="imagen_peq_barra">  
                  
                    <div className='separacion'>
                    <a className="a" href="# "  role="button" onClick={(e) =>{setoPrueba(true)}}>Prueba</a> 
                    </div>
                    <div className='separacion'>
                      <a className="a" href="# " role="button"  onClick={(e) =>{setabrirAviso(true)}}>Únete</a>
                    </div>
              </div>
        </div>   
      <Header  />

        </Grid>
      
    </Breakpoint>
   
      <Breakpoint medium down>   
        
      <div className="papel2" > 
         {mostrarFoto&& <img src={require('../../Imagenes/foto5chica_p.jpg')} alt=''   className="foto_app"  />  }   
     
     
         <div className='papel_principal'id='titulo_portada'>
           
           {mostrarFoto&&<div className="papel" id='titulo_portada' >
       
              <div className="imagen_peq" >   <a className="a"  href="# "  role="button"  onClick={(e) =>{
                setabrirAviso(true)}}>Únete</a> </div>
              <spam className='titulo_grande_app'>La app definitiva para tu comunidad de vecinos</spam><br/>
              <spam className='titulo_mediano_app'>
                  Somos la plataforma disruptiva que conecta a todos los vecinos y permite gestionar todos los procesos de una comunidad con un sólo click.         
              </spam>
              
             </div>}
             <Sidebar />
             <div className="barraSuperior_app" id="barraSuperior" >
             <div class="imagen_peq_barra">  
                  
                    <div className='separacion'>
                    <a className="a"  href="# "  role="button" onClick={(e) =>{setoPrueba(true)}}>Prueba</a> 
                    </div>
                    <div className='separacion'>
                      <a className="a"  href="# "  role="button" onClick={(e) =>{setabrirAviso(true)}}>Únete</a>
                    </div>
              </div>
        </div>

       </div>
    
     </div>
  
     </Breakpoint>
    
     
     <div class="paperContainerSinimagen" id='ventana1'  tabindex="-1"> 
      <Breakpoint large up>     
     
           <p class="titulo2" >
              <p className='titulo_grande2'>Somos la plataforma pionera en cloud y app para administradores, comunidades y vecinos</p>
              <div className="papel_espacios_entre" >
                 
                  <div  className="alineamiento_titulo" >     
                    < SyncIcon style={{ fontSize: 50, color: green[500] }}/>
                    <p className='titulo_mediano_bold'>Solución 360º</p >
                    <p className="titulo_mediano_icono">Cubrimos todas las necesidades del vecino y su comunidad.
                    Digitalizamos la operación de los gestores de las comunidades.</p>
                  </div>
                  <div className="alineamiento_titulo" >     
                    <SpeedIcon style={{ fontSize: 50, color: green[500] }}/>
                    <p className='titulo_mediano_bold'>Agilidad</p>
                    <p className="titulo_mediano_icono"> Aplicamos mejora continua en procesos actuales y nuevos, somos ágiles.    </p>
                  </div>
                  <div className="alineamiento_titulo" >                
                    <PeopleOutlineIcon style={{ fontSize: 50, color: green[500] }}/>
                    <p className='titulo_mediano_bold'>Partners</p>
                    <p className="titulo_mediano_icono">  
                     Creamos un ecosistema maximizando la rentabilidad con nuestros socios. 
                     <br/>Monetiza y libera recursos enfocándolos donde verdad quieres.</p>
                  </div>
                  <div className="alineamiento_titulo" >     
                    <BeenhereIcon style={{ fontSize: 50, color: green[500] }}/>
                    <p className='titulo_mediano_bold'>Todo incluido</p>
                    <p className="titulo_mediano_icono" >Cuota mensual incluye entorno Cloud 24x7, acceso seguro desde cualquier lugar
                    y dispositivos, y muchas cosas más...</p>
                  </div>
            
              </div>
            </p>
      
       </Breakpoint>

       <Breakpoint medium down>  
           <p className="titulo2_app" >
              <p className='titulo_grande2_app'>Somos la plataforma en cloud y app pionera para administradores, comunidades y vecinos</p>
                  <div className="papel_espacios_entre_app" >
                 
                  <div className="alineamiento_titulo_app" >     
                    < SyncIcon style={{ fontSize: '30px', color: green[500] }}/>
                    <p className='titulo_mediano_bold'>Solución 360º</p>
                    <p className="titulo_mediano_icono" >Cubrimos todas las necesidades del vecino y su comunidad.
                    Digitalizamos la operación de los gestores de las comunidades</p>
                  </div>
                  <div className="alineamiento_titulo_app" >     
                    <SpeedIcon style={{ fontSize: '30px', color: green[500] }}/>
                    <p className='titulo_mediano_bold'>Agilidad</p>
                    <p className="titulo_mediano_icono" > Aplicamos mejora continua en procesos actuales y nuevos, somos ágiles.</p>
                  </div>

                  </div>
                  <div className="papel_espacios_entre_app" >
                  <div className="alineamiento_titulo_app" >                
                    <PeopleOutlineIcon style={{ fontSize: '30px', color: green[500] }}/>
                    <p className='titulo_mediano_bold'>Partners</p>
                    <p className="titulo_mediano_icono" >  
                    Creamos un ecosistema maximizando la rentabilidad con nuestros socios. Monetiza y libera recursos enfocándolos donde verdad quieres.</p>
                 </div>
                  <div className="alineamiento_titulo_app" >   
                    <BeenhereIcon style={{ fontSize: '30px', color: green[500] }}/>
                    <p className='titulo_mediano_bold'>Todo incluido</p>
                    <p className="titulo_mediano_icono" >Cuota mensual incluye entorno Cloud 24x7, acceso seguro desde cualquier lugar
                    y dispositivos, y muchas cosas más...</p>
                  </div>
            
              </div>
           </p>
      
       </Breakpoint>
      </div>
      
     <div  class="paperContainerSinimagen2"   id='ventana2'  tabindex="-1">
        
     
       <p className="titulo3" >
       <p  className={(CustomComponent()==='grande' ? 'titulo_grande2':'titulo_grande2_app')}
        style={{textAlign:'center',marginLeft:'3vw' }}>Nuestro producto, lo que ves</p>
           <p  className={(CustomComponent()==='grande' ? 'titulo_mediano_claro':'titulo_mediano_claro')}
           style={{textAlign:'center',marginLeft:'3vw' }}>App móvil para Android e IOS, entorno WEB para la autogestión de todo el ecosistema.</p>
                  
           <div className={(CustomComponent()==='grande' ? "papel_espacios_entre3":'papel_espacios_entre3-peque')} style={{marginLeft:'3vw',marginRight:'3vw' }} >
         
               {CustomComponent()==='grande'&&<div className="papel_espacios_entre2" >
                    
                    <div  className="alineamiento_titulo" >     
                      < AlarmOnIcon style={{ fontSize: 40, color: green[500] }}/>
                      <p className='titulo_peque_bold'>Tramitar avisos</p >
                      <p className="titulo_peque_claro">Incidencias y siniestros al gremio/seguro contratado
                        por tu comunidad, al administrador, otros implicados,
                        simultáneamente en tiempo real 24 h/365d.
                        Seguimiento digitalizado.</p>
                    </div>
                    <div className="alineamiento_titulo" >     
                      <FileCopyIcon style={{ fontSize: 40, color: green[500] }}/>
                      <p className='titulo_peque_bold'>Consultar cuentas </p >
                      <p className="titulo_peque_claro">
                        Actas, presupuestos, cuentas, tablón de anuncios , en
                        resumen, cuantos documentos en pdf quieras poner a
                        disposición de tus clientes. Súbelos de forma ágil y
                        compártelos en tiempo real.</p>
                    </div>
                   
                 
               </div>}
           
            
             
              </div>
  
              <Breakpoint large up>     
     
              <div className="papel_espacios_entre2" >
                    
                      <div  className="alineamiento_titulo" >     
                        < BeenhereIcon style={{ fontSize: 40, color: green[500] }}/>
                        <p className='titulo_peque_bold'>Reservas zona</p >
                        <p className="titulo_peque_claro">Reserva pista de padel, de tenis, gestiona la cita previa
                  y controla el aforo y el acceso de la piscina en tiempo real.</p>
                      </div>
                      <div className="alineamiento_titulo" >     
                        <MonetizationOnIcon style={{ fontSize: 40, color: green[500] }}/>
                        <p className='titulo_peque_bold'>Ahorra costes</p >
                        <p className="titulo_peque_claro">
                      Comunicación inmediata a los smarthphone de los
                      vecinos. Ahorra envíos, antiestética cartelería, protege
                      los datos del tablón de anuncios. Acaba con el papel.</p>
                      </div>
                      <div  className="alineamiento_titulo" >     
                      < DuoIcon style={{ fontSize: 40, color: green[500] }}/>
                      <p className='titulo_peque_bold'>Realizar reuniones telemáticas</p >
                      <p className="titulo_peque_claro">
                                              Por canal excusivo. Realiza encuestas-votaciones,
                      incluso Juntas Generales. (en fase de aprobación su
                      validez.) Proporcionando la herramienta legal (LOPD),
                      y proporcionando un acceso exclusivo al alcance de
                      todos los propietarios. Automatiza escrutinios y
                      publícalos en tiempo real.</p>
                    </div>

                    <div  className="alineamiento_titulo" >     
                      < PlaylistAddCheckIcon style={{ fontSize: 40, color: green[500] }}/>
                      <p className='titulo_peque_bold'>Control de empleados y/o personal</p >
                      <p className="titulo_peque_claro">Sistema de fichaje de entradas/salidas por
                      geoposición, sin contacto. SISTEMA OBLIGATORIO y
                      homologado para el control de jornadas de
                      trabajadores según el Ministerio de Trabajo y AASS.
                      Controla los accesos de gremios externos, protege tu
                      propiedad.</p>
                    </div>

      
               </div>
        
              <div className="papel_espacios_entre2" >
              
                    <div className="alineamiento_titulo" >     
                      <EmojiObjectsIcon style={{ fontSize: 40, color: green[500] }}/>
                      <p className='titulo_peque_bold'>Rastreador de ofertas de luz y gas</p>
                      <p className="titulo_peque_claro">
                              A través de la comisión nacional del mercado de la
                      competencia, o por medio diferentes proveedores de
                      energía, a título particular para que ahorres en tus
                      facturas.</p>
                    </div>
                   
                    <div className="alineamiento_titulo" >     
                      <GavelIcon style={{ fontSize: 40, color: green[500] }}/>
                      <p className='titulo_peque_bold'>Digitalización LOPD</p>
                      <p className="titulo_peque_claro" >Digitaliza la documentación de LOPD de las comunidades con la Consultora.</p>
                    </div>
                    {CustomComponent()==='grande'&& <p  className={(CustomComponent()==='grande' ? "eframe":"eframe2")}>
           
                    <img src={require('../../Imagenes/somos2.png')}   alt=''  className={(CustomComponent()==='grande' ? "eframe":"eframe2")}  />     
                    </p>}
                </div>

              </Breakpoint>

              <Breakpoint medium down>  
                <div className="papel_espacios_entre2_app" >
                      
                      <div  className="alineamiento_titulo_app" >     
                        < AlarmOnIcon style={{ fontSize: 30, color: green[500] }}/>
                        <p className='titulo_peque_bold'>Tramita avisos</p >
                        <p className="titulo_peque_claro">Incidencias y siniestros al gremio/seguro contratado
                        por tu comunidad, al administrador, otros implicados,
                        simultáneamente en tiempo real 24 h/365d.
                        Seguimiento digitalizado.</p>
                      </div>
                      <div className="alineamiento_titulo_app" >     
                        <FileCopyIcon style={{ fontSize: 30, color: green[500] }}/>
                        <p className='titulo_peque_bold'>Consulta cuentas</p >
                        <p className="titulo_peque_claro">
                        Actas, presupuestos, cuentas, tablón de anuncios , en
                        resumen, cuantos documentos en pdf quieras poner a
                        disposición de tus clientes. Súbelos de forma ágil y
                        compártelos en tiempo real.</p>
                      </div>
                </div>
                <div className="papel_espacios_entre2_app" >
                      <div className="alineamiento_titulo_app" >                
                      < BeenhereIcon style={{ fontSize: 30, color: green[500] }}/>
                        <p className='titulo_peque_bold'>Reservas zona</p >
                        <p className="titulo_peque_claro">Reserva pista de padel, de tenís, gestiona la cita previa
                  y controla el aforo y el acceso de la piscina en tiempo
                  real.</p>
                      </div>
                      <div className="alineamiento_titulo_app" >     
                          <MonetizationOnIcon style={{ fontSize: 30, color: green[500] }}/>
                            <p className='titulo_peque_bold'>Ahorra costes</p >
                            <p className="titulo_peque_claro">
                          Comunicación inmediata a los smarthphone de los
                          vecinos. Ahorra envíos, antiestética cartelería, protege
                          los datos del tablón de anuncios. Acaba con el papel.</p>
                       </div>
                  
                  </div>
                  {CustomComponent()!=='grande'&& <p  className={(CustomComponent()==='grande' ? "eframe":"eframe2")}>
                      <img src={require('../../Imagenes/somos2.png')}   alt=''  className={(CustomComponent()==='grande' ? "eframe":"eframe2")}  />     
                    </p>}
                <div className="papel_espacios_entre2_app" >
                       <div  className="alineamiento_titulo_app" >     
                              < DuoIcon style={{ fontSize: 30, color: green[500] }}/>
                              <p className='titulo_peque_bold'>Realizar reuniones telemáticas</p >
                              <p className="titulo_peque_claro">
                                                      Por canal excusivo. Realiza encuestas-votaciones,
                              incluso Juntas Generales. (en fase de aprobación su
                              validez.) Proporcionando la herramienta legal (LOPD),
                              y proporcionando un acceso exclusivo al alcance de
                              todos los propietarios. Automatiza escrutinios y
                              publícalos en tiempo real.</p>
                          </div>

                        <div  className="alineamiento_titulo_app" >     
                        < PlaylistAddCheckIcon style={{ fontSize: 30, color: green[500] }}/>
                      <p className='titulo_peque_bold'>Control de empleados y/o personal</p >
                      <p className="titulo_peque_claro">Sistema de fichaje de entradas/salidas por
                      geoposición, sin contacto. SISTEMA OBLIGATORIO y
                      homologado para el control de jornadas de
                      trabajadores según el Ministerio de Trabajo y AASS.
                      Controla los accesos de gremios externos, protege tu
                      propiedad.</p>
                        </div>
                    </div>
                  <div className="papel_espacios_entre2_app" >
                      <div className="alineamiento_titulo_app" >     
                          <EmojiObjectsIcon style={{ fontSize: 30, color: green[500] }}/>
                          <p className='titulo_peque_bold'>Rastreador de ofertas de luz y gas</p>
                          <p className="titulo_peque_claro">
                                  A través de la comisión nacional del mercado de la
                          competencia, o por medio diferentes proveedores de
                          energía, a título particular para que ahorres en tus
                          facturas.</p>
                      </div>
                    
                      <div className="alineamiento_titulo_app" >     
                      <GavelIcon style={{ fontSize: 30, color: green[500] }}/>
                      <p className='titulo_peque_bold'>Digitalización LOPD</p>
                      <p className="titulo_peque_claro" >Digitaliza la documentación de LOPD de las comunidades con la Consultora.</p>
                    </div>
                
                  </div>
              </Breakpoint>

            </p>
 


     </div>  
     <Grid class="paperContainerSinimagen3"  id='ventana3'  tabindex="-1">
        
     <p class="titulo3"  >

        <p  className={(CustomComponent()==='grande' ? 'titulo_grande2':'titulo_grande2_app')}
        style={{textAlign:'center',marginLeft:'3vw' }}>A la última y sin compromisos</p>
           
        <p  className={(CustomComponent()==='grande' ? 'titulo_mediano_claro':'titulo_mediano_claro')}
            style={{textAlign:'center',marginLeft:'3vw' }}>Continuamente mejorando los procesos y la tecnología</p>
      
         <Grid className={(CustomComponent()==='grande' ? 'papel_ventanas':'papel_ventanas_app')}>  
 
            <p  className="titulo_peque_claro">

            {CustomComponent()==='grande' ? 
             <p className="papel_espacio_ventanas" >
                <p style={{width:'50%'}}>       </p> 
                
                <img src={require('../../Imagenes/LOGOC365.png')}  className='img3' alt="logo" />
                <p className='otros' >OTROS</p> 
                </p>
                :
                <p className="papel_espacio_ventanas" >
                  <p style={{width:'330px'}}>       </p> 
              
                    <img src={require('../../Imagenes/LOGOC365.png')}  className='img4' alt="logo" />
                    <p className='otros2' >OTROS</p> 
                  </p>}
              
                <hr/>
                
                <p className="papel_espacio_ventanas" >
                <p style={{width:'40%'}}>Solución en Cloud</p> 
                
                  <CheckIcon style={{ fontSize: 40, color: green[500] }} />
                  <CloseIcon style={{ fontSize: 40, color: red[500] }} />
                </p>
                
                <hr/>
                 
                <p className="papel_espacio_ventanas" >
                     <p style={{width:'40%'}}>No requiere integración con otros sistemas</p> 
                
                  <CheckIcon style={{ fontSize: 40, color: green[500] }} />
                   <CloseIcon style={{ fontSize: 40, color: red[500] }} />
                </p>
          
                
                <hr/>
                
                <p className="papel_espacio_ventanas" >
                <p style={{width:'40%'}}>Sin limitación de vecinos/usuarios</p> 
                 
                  <CheckIcon style={{ fontSize: 40, color: green[500] }} />
                   <CloseIcon style={{ fontSize: 40, color: red[500] }} />
                </p>
             
                
                <hr/>
                
                <p className="papel_espacio_ventanas" >
                <p style={{width:'40%'}}>Plataforma datos a tiempo real</p> 
                
                  <CheckIcon style={{ fontSize: 40, color: green[500] }} />
                   <CloseIcon style={{ fontSize: 40, color: red[500] }} />
                </p>
               
                
                <hr/>
                
                <p className="papel_espacio_ventanas" >
                <p style={{width:'40%'}}>Una sola app (ios, android) para todo</p> 
                  <CheckIcon style={{ fontSize: 40, color: green[500] }} />
                  <CloseIcon style={{ fontSize: 40, color: red[500] }} />
                </p>
               
                
                <hr/>  
              </p>
             
             
      </Grid>
         
      </p>
 

     </Grid>  
     <Grid class="paperContainerSinimagen5"  id='ventana4' tabindex="-1">
      <Breakpoint large up>
      
         
       <div  className='cuadro-titulo'>Valores por los que nos proyectamos sin límites</div>
         <div className='posicion'>                      
             <div className='wrapper4'>
                  <div  className='cuadro-mapa'>
                  <spam className='texto-cuadro'>El vecino y propietario lo primero</spam> <br/> <br/> <br/> 
                  Su mayor satisfacción nuestro principal indicador (UX ++)
                  </div>
              <div  className='cuadro-mapa'>
                <spam className='texto-cuadro'>Pasión por lo que hacemos</spam> <br/> <br/> 
                  Desde nuestra mas profunda creencia nos apalancamos en este apasionante reto
              </div>
            </div>
             <div className='wrapper4'>
                  <div  className='cuadro-mapa'>
                  <spam className='texto-cuadro'> Innovación y creatividad</spam> <br/> <br/> <br/> 
                    Pilares básicos en nuestro desafío continuo de digitalización
                  </div>
                  <div  className='cuadro-mapa'>
                    <spam className='texto-cuadro'>Compromiso y responsabilidad</spam> <br/> <br/> <br/> 
                    Nos basamos en la lealtad y sinceridad con nuestros partners
                  </div>
            </div>       
        
        </div>
    
        <video className='videoL'
          autoPlay
          loop

        currentTime={11.3}
        src  ={Video} 
        type="video/mp4"/>
      
    
  
  

      </Breakpoint>
      <Breakpoint medium down>
              
       <div style={{paddingTop:'80px'}} > 
            
           <div className='posicion_app'>   
            <div  className='cuadro-titulo_app'>
                  Valores por los que nos proyectamos sin límites
                  
            </div>                   
             <div className='wrapper4_app'>
            
                <div  className='cuadro-mapa_app'>
                <spam className='texto-cuadro_app'>Vecino y propietario lo primero</spam> <br/> 
                  Su mayor satisfacción nuestro principal indicador (UX ++)
                    
                </div>
                <div  className='cuadro-mapa_app'>
                <spam className='texto-cuadro_app'>Pasión por lo que hacemos</spam> <br/> 
                  Porque nos gusta y amamos este apasionante reto
               
               </div>
        
                <div  className='cuadro-mapa_app'>
             
              <spam className='texto-cuadro_app'>Innovación, creatividad</spam> <br/> 
              Pilares básicos en nuestro desafío continuo de digitalización
            </div>
                <div  className='cuadro-mapa_app'>
            <spam className='texto-cuadro_app'>Compromiso y responsabilidad</spam> <br/> 
             Nos basamos en la lealtad y sinceridad con nuestros partners
             
            </div>
           
             </div>       
        
          </div>
    

            <img src={require('../../Imagenes/valores1.jpg')}   alt=''  className="valores_app"  />     
      
      </div>
  
  

      </Breakpoint>


        </Grid>  
 
     <Grid class="paperContainerSinimagen4"  id='ventana5'   tabindex="-1">
        
        <p class="titulo3"  >
          <p  className={(CustomComponent()==='grande' ? 'titulo_grande2':'titulo_grande2_app')}
                    style={{marginLeft:'3vw'}}>Déjate de líos</p>
                        
          <p  className={(CustomComponent()==='grande' ? 'titulo_mediano_claro':'titulo_mediano_claro')}
                        style={{marginLeft:'3vw'}}>La contratación y puesta en marcha extremadamente sencilla</p>
                  
           <p  className={(CustomComponent()==='grande' ? 'titulo_peque_claro':'titulo_peque_claro')}
                        style={{marginLeft:'3vw'}}>Dinos si eres comunidad o despacho, escoje el paquete que quieras lanzar, sigue los siguientes pasos y <br/>
                    lanza tu C365!!</p>
        
        <Grid className={(CustomComponent()==='grande' ? 'papel_contratacion':'papel_ventanas_app')}>  
             
           
            <div  className={(CustomComponent()==='grande' ? 'papel_espacios_entre2':'')} >
           
                {CustomComponent()!=='grande'&&<p className='titulo_peque_bold_contratacion'>Despacho...</p> }
                {CustomComponent()==='grande'&&<img src={require('../../Imagenes/despacho1_p.jpg')}   alt=''
                    className="imagen5"  />  }
                {CustomComponent()!=='grande'&&<img src={require('../../Imagenes/despacho1.jpg')}   alt=''
                className="imagen52"  />  }      
                  <div className={(CustomComponent()==='grande' ? 'alineamiento_titulo_contratacion':'alineamiento_titulo_contratacion_app')}  >     
                     
                  {CustomComponent()==='grande'&&<p className='titulo_mediano_bold_contratacion'>Despacho...</p> }  
                     <Grid  container  direction="row" justifyContent="flex-start"     alignItems="center">
                        <LooksOneIcon style={{ fontSize:(CustomComponent()==='grande' ? 60:40)
                           , color: green[500] }}/>  
                        <p   className={(CustomComponent()==='grande' ? 'titulo_peque_bold':'titulo_peque_bold_app')}  >     
                                        Danos gremios, comunidades y te los cargamos </p> 
                     </Grid>
                     <p className="titulo_peque_claro">
                      Mediante un extracción muy sencilla de tus sistemas podemos dejarte cargados los gremios y las comunidades, o puedes crearlos tu mismo. <br/>
                      Si lo deseas podemos hacer una carga previa de los usuarios, aunque como verás no es necesario (el vecino se registra).
                      </p>
                     <Grid  container  direction="row" justifyContent="flex-start"     alignItems="center">
                        <LooksTwoIcon style={{ fontSize:(CustomComponent()==='grande' ? 60:40)
                           , color: green[500] }}/>  
                            <p   className={(CustomComponent()==='grande' ? 'titulo_peque_bold':'titulo_peque_bold_app')}  >  Acceso, formación y soporte</p> 
                     </Grid>
                     <p className="titulo_peque_claro">
                     Te damos acceso a tu entorno WEB cloud, te mostramos los primeros pasos y te damos soporte inicial para tus dudas.
                     </p>
                     <Grid  container  direction="row" justifyContent="flex-start"     alignItems="center">
                        <Looks3Icon style={{ fontSize:(CustomComponent()==='grande' ? 60:40)
                           , color: green[500] }}/>  
                          <p   className={(CustomComponent()==='grande' ? 'titulo_peque_bold':'titulo_peque_bold_app')}  >  Ya puedes lanzar tus primeras comunidades</p> 
                     </Grid>
                     <p className="titulo_peque_claro">                      
                       Facilita el código único por comunidad y cada vecino ya podrá acceder.<br/>
                       Te acompañamos si lo deseas en los primeros lanzamientos.</p>
                       <Grid  container  direction="row" justifyContent="flex-start"     alignItems="center">
                        <Looks4Icon style={{ fontSize:(CustomComponent()==='grande' ? 60:40)
                           , color: green[500] }}/>  
                            <p   className={(CustomComponent()==='grande' ? 'titulo_peque_bold':'titulo_peque_bold_app')}  >  Ya eres C365</p> 
                     </Grid>
                    </div>
                 </div>
                
                
         </Grid>
        <Grid className={(CustomComponent()==='grande' ? 'papel_contratacion':'papel_ventanas_app')}>  
             
           <div  className={(CustomComponent()==='grande' ? 'papel_espacios_entre2':'')} >
             
               {CustomComponent()!=='grande'&&<p className='titulo_peque_bold_contratacion'>...Comunidad</p> }
                  {CustomComponent()!=='grande'&&<img src={require('../../Imagenes/foto5chica.jpg')}   alt=''
                className="imagen52"  />  }      
                  <div className={(CustomComponent()==='grande' ? 'alineamiento_titulo_contratacion':'alineamiento_titulo_contratacion_app')}  >     
                
          
                  {CustomComponent()==='grande'&&<p className='titulo_mediano_bold_contratacion'>...Comunidad</p> }
             
                     <Grid  container  direction="row" justifyContent="flex-start"     alignItems="center">
                        <LooksOneIcon style={{ fontSize:(CustomComponent()==='grande' ? 60:40)
                           , color: green[500] }}/>  
                           <p   className={(CustomComponent()==='grande' ? 'titulo_peque_bold':'titulo_peque_bold_app')}  >  Danos tus datos básicos y los configuramos </p> 
                     </Grid>
                     <p className="titulo_peque_claro">
                        Dirección o direcciones de la comunidad, espacios comunes si deseas
                         gestionarlos por la C365, documentos etc...
                        <br/>Danos también los gremios que trabajan para la comunidad, nosotros te lo configuraremos.
                      </p>
                     <Grid  container  direction="row" justifyContent="flex-start"     alignItems="center">
                        <LooksTwoIcon style={{ fontSize:(CustomComponent()==='grande' ? 60:40)
                           , color: green[500] }}/>   
                            <p   className={(CustomComponent()==='grande' ? 'titulo_peque_bold':'titulo_peque_bold_app')}  >  Acceso a C365, formación y soporte</p> 
                     </Grid>
                     <p className="titulo_peque_claro">
                       Te damos acceso al entorno c365, descarga de la app, formación y soporte.
                       <br/>Te acompañamos durante todo es proceso.
                        </p>
                     <Grid  container  direction="row" justifyContent="flex-start"     alignItems="center">
                        <Looks3Icon style={{ fontSize:(CustomComponent()==='grande' ? 60:40)
                           , color: green[500] }}/>  
                            <p   className={(CustomComponent()==='grande' ? 'titulo_peque_bold':'titulo_peque_bold_app')}  >  Lanza la aplicación para todos los vecinos </p> 
                     </Grid>
                     <p className="titulo_peque_claro">Comparte con todo los vecinos el código de acceso, para que una vez se instalen la app, en sus  dispositivos, puedan acceder a  su comunidad  </p>
                     <Grid  container  direction="row" justifyContent="flex-start" alignItems="center">
                        <Looks4Icon style={{ fontSize:(CustomComponent()==='grande' ? 60:40)
                           , color: green[500] }}/>  
                            <p   className={(CustomComponent()==='grande' ? 'titulo_peque_bold':'titulo_peque_bold_app')}  >  Ya eres C365</p> 
                     </Grid>
                        
                       
                        

              </div>
              {CustomComponent()==='grande'&&<img src={require('../../Imagenes/foto5chica.png')}   alt=''
                className="imagen5"  />  }
      
        
              </div>
           
             
             
          </Grid>

         <Grid className={(CustomComponent()==='grande' ? 'papel_contratacion':'papel_ventanas_app')}>  
             
             <div className={(CustomComponent()==='grande' ? 'papel_espacios_entre2':'')}  >
        
               {CustomComponent()!=='grande'&&<p className='titulo_peque_bold_contratacion'>Vecino</p> }   
               {CustomComponent()==='grande'&&<img src={require('../../Imagenes/foto6chica.png')}  alt=''  
                className="imagen5"  />  }
                  {CustomComponent()!=='grande'&&<img src={require('../../Imagenes/foto6chica.jpg')}   alt='' 
                className="imagen52"  />  }  
              
               <div className={(CustomComponent()==='grande' ? 'alineamiento_titulo_contratacion':'alineamiento_titulo_contratacion_app')}  >     
                     
                  {CustomComponent()==='grande'&&<p className='titulo_mediano_bold_contratacion'>Vecino</p> }
                    
                     <Grid  container  direction="row" justifyContent="flex-start"     alignItems="center">
                        <LooksOneIcon style={{ fontSize:(CustomComponent()==='grande' ? 60:40)
                           , color: green[500] }}/>  
                             <p   className={(CustomComponent()==='grande' ? 'titulo_peque_bold':'titulo_peque_bold_app')}  >  Descárgate la app en IOS o Android </p> 
                     </Grid>
                     <p className="titulo_peque_claro">
                    Descárgate C365 en los dispositivos móviles, tablets que quieras usar para acceder a tu comunidad.</p>
                     <Grid  container  direction="row" justifyContent="flex-start"     alignItems="center">
                        <LooksTwoIcon style={{ fontSize:(CustomComponent()==='grande' ? 60:40)
                           , color: green[500] }}/>  
                            <p   className={(CustomComponent()==='grande' ? 'titulo_peque_bold':'titulo_peque_bold_app')}  >  Regístrate</p> 
                     </Grid>
                     <p className="titulo_peque_claro">
                       Desde la plantalla principal de la app usando el código de vecino que se te haya facilitado.<br/>
                       Añade todos tus datos como vecino y acepta el registro.<br/>
                       Recibirás un email de confirmación de tu nueva cuenta de usuario.  ¡¡¡CONFÍRMALA!!
                      </p>
                     <Grid  container  direction="row" justifyContent="flex-start"     alignItems="center">
                        <Looks3Icon style={{ fontSize:(CustomComponent()==='grande' ? 60:40)
                           , color: green[500] }}/>  
                             <p   className={(CustomComponent()==='grande' ? 'titulo_peque_bold':'titulo_peque_bold_app')}  >  Accede a la aplicación C365</p> 
                     </Grid>
                     <p className="titulo_peque_claro">
                      Con tu email y contraseña usados en el registro, accede desde la pantalla inicial de C365 a tu comunidad.
                    </p>
                    <Grid  container  direction="row" justifyContent="flex-start" alignItems="center">
                        <Looks4Icon style={{ fontSize:(CustomComponent()==='grande' ? 60:40)
                           , color: green[500] }}/>  
                             <p   className={(CustomComponent()==='grande' ? 'titulo_peque_bold':'titulo_peque_bold_app')}  >  Ya eres C365</p> 
                     </Grid>
                   </div>
              </div>
             
  
        </Grid>  
        </p>
      </Grid>
     <Grid class="paperContainerSinimagen6"  id='ventana6'  tabindex="-1">
        
     <Breakpoint large up>        
      <Grid   class="paperContainer2_parallax" >
    
         <div class="titulo_2" >

           
          <p className='titulo_mediano_2' ><p className='titulo_grande_2'>Ayúdanos a preservar los bosques
          </p>Evitando el envío masivo de cartas y documentos hacia todos los vecinos. <br/>
           Usando C365 podemos compartir toda la documentación de la comunidad, actualizada en
            tiempo real, sin talar un árbol.
            </p>
           
        
       
         

      </div>

        </Grid>
    </Breakpoint>
   
      <Breakpoint medium down>   
      <Grid   class="paperContainer3_parallax" >
    
         <div class="titulo_2_app" >

           
          <p className='titulo_mediano_2_app' ><p className='titulo_grande_2_app'>Ayúdanos a preservar los bosques
          </p>Evitando el envío masivo de cartas y documentos hacia todos los vecinos. <br/>
           Usando C365 podemos compartir toda la documentación de la comunidad, actualizada en
            tiempo real, sin talar un árbol.
            </p>
           
        
       
         

      </div>

        </Grid>
        
         

     </Breakpoint>

        </Grid>  
     <Grid class="paperContainerSinimagen7"  id='ventana7'  tabindex="-1">
        
     
     <p class="titulo2" >
              <p className={(CustomComponent()==='grande' ?  'titulo_grande_equipo': 'titulo_grande_equipo_app')}>Los impulsadores de C365
              <p  className={(CustomComponent()==='grande' ? 'titulo_mediano_claro':'titulo_mediano_claro')}
                        style={{marginLeft:'3vw'}}>Equipo de profesionales con dilatada experiencia en administración de fincas y  transfomación digital 
                         </p>                 
   
              </p>
              
          <div  className={(CustomComponent()==='grande' ? 'titulo_mediano_claro':'titulo_mediano_claro')} >
           
            <div className={(CustomComponent()==='grande' ?  'alineamiento_titulo_7': 'alineamiento_titulo_7_app')} >     
          
              <div className='cuadro_trasero'>
               <img src={"https://firebasestorage.googleapis.com/v0/b/dogarfirbase.appspot.com/o/pablo.jpg?alt=media&token=4275a70a-057e-45a5-883e-4d3d9e321ec3"}
                   alt=''
                 className='foto_avatar' />
              </div>
              <p className='titulo_mediano_bold'>Pablo Rondón Grijalba</p>
              <p className='titulo_mediano_bold'>Cofundador</p>
              <p className="titulo_mediano_icono_7">Profesional en consultoría tecnológica y tranformación digital con mas de 17 años de experiencia
              en el sector (Indra, Accenture, EY) experiencia internacional (7 años Brasil). Algunos clientes: Endesa (Enel), Gestamp, Telefónica.

                    </p>
                  
                    <br/>
                Universidad de Zaragoza:
                Centro Politénico Superior (CPS) título de Ingeniero Superior en Informática.
                <br/>

                <hr/>
              
                <br/>      
              
    
            </div>
        
            <div className={(CustomComponent()==='grande' ?  'alineamiento_titulo_7': 'alineamiento_titulo_7_app')} >     
                   <div className='cuadro_trasero'>
              <img src={"https://firebasestorage.googleapis.com/v0/b/dogarfirbase-100001/o/logo%2FWcWGKmSBQwgZLrdqwxNmqAi1Yjt2?alt=media&token=a80c6d07-cacd-4109-9e56-c36d212a6985"}
               alt=''
             className='foto_avatar' />
            </div>
    
             <p className='titulo_mediano_bold'>Jesús María Domingo García</p>
              <p className='titulo_mediano_bold'>Cofundador</p>
              <p className="titulo_mediano_icono_7">
              Administrador de fincas desde el Año 93. Colegiado en Aragón.
              <br/>Perito judicial.  Asesor jurídico.
               Promotor y colaborador en la obtención de apoyos para la reforma de la Ley de Propiedad Horizontal
                en el Senado (stop morosos) promovida por el Consejo General de Administradores de fincas de España.
                

                    </p>
                 
                    <br/>
                Universidad politécnica de Zaragoza: Grado en administración de empresas y finanzas.
                Universidad de Alcalá: 
                Diplomado en Derecho inmobiliario.
                Universidad de Zaragoza:
                Posgrado en mediación civil y mercantil, intra-extrajudicial.
                <br/>

                <hr/>

                <br/>      
               
               
    

    
            </div>
        
      
        </div>
      </p>


    
      </Grid>  
      <Grid class="paperContainerSinimagen8"  id='ventana8'  tabindex="-1">
        
     
           <p class="titulo2" >
                 <p className={(CustomComponent()==='grande' ?  'titulo_grande_equipo': 'titulo_grande_equipo_app')}>Contexto, prensa, colaboradores
                    <p  className={(CustomComponent()==='grande' ? 'titulo_mediano_claro':'titulo_mediano_claro')}
                           style={{marginLeft:'3vw'}}>Covid, proceso de transformación digital en las comunidades de vecinos.
                            </p>
                 </p>
                           
                <div className='fotos_actualidad'>      
               
                
                <a href="
                https://www.heraldo.es/multimedia/imagenes/economia/aplicacion-movil-aragonesa-para-comunidades-de-vecinos/">
                  <img src={require('../../Imagenes/heraldo.jpg')} 
                     alt=''
                    className={(CustomComponent()==='grande' ?  "imagen6": "imagen6_app") } /> 
      
                </a>
                  <a href="
                  https://firebasestorage.googleapis.com/v0/b/dogarfirbase-c36501/o/Documentación%20General%2FATT00157.pdf?alt=media&token=e25112b4-b126-44be-958f-997784084a57">
                  <img src={require('../../Imagenes/heraldo2.jpg')} 
                      alt=''
                     className={(CustomComponent()==='grande' ?  "imagen6": "imagen6_app") } /> 
                 
                  </a> 
               </div>
             <div  
              
              className={(CustomComponent()==='grande' ? 'papel_espacios_entre':"papel_espacios_entre3-peque" )}>
              
               <div className={(CustomComponent()==='grande' ?  'alineamiento_titulo_7': 'alineamiento_titulo_7_app')} >     
                
                 <div className='cuadro_trasero'>
                 <p className='titulo_mediano_bold'>Contexto</p>
                 </div>
                 
              

    <p  className='titulo_mediano_bold'>   < DoneIcon style={{ fontSize: 30, color: green[500] }}/>Digitalización</p>
     <p className="titulo_mediano_icono_7">El sector de las comunidades de vecinos, es un sector en vías de tranformación digital.
                 El usuario vecino cada vez más demanda un servicio más digitalizado, exigiendo una mayor agilidad y conexión con su entorno
                  comunitario. 
    </p>
    <p  className='titulo_mediano_bold'>   < DoneIcon style={{ fontSize: 30, color: green[500] }}/>COVID-19</p>
    <p className="titulo_mediano_icono_7">En época de pandemia se ha acelerado el proceso de digitalización en las comunidades de vecinos.
    Esta especial situación ha sido la catalizadora para impulsar procesos como las reuniones virtuales, votaciones, control de aforos etc..
    </p>
                  
         <hr/>
                 
                   <br/>      
                
       
               </div>
           
               <div className={(CustomComponent()==='grande' ?  'alineamiento_titulo_7': 'alineamiento_titulo_7_app')} >     
                      <div className='cuadro_trasero'>
                      <p className='titulo_mediano_bold'>Colaboradores</p>
              </div>
       
                <p className='titulo_mediano_bold'>Algunos de nuestros principales partners</p>
                
              
                <>
                 <div className='separacionV'>
                  <div className='separacionH'>
                  
                  <a href="https://www.dogar.es">
                    <img src=
                    {'https://firebasestorage.googleapis.com/v0/b/dogarfirbase-100001/o/logo%2FLOGODOGAR.png?alt=media&token=5555184f-f602-4121-ac10-db2a085e90a6'} 
                    alt=''
                     className={(CustomComponent()==='grande' ?  "imagen_partner": "imagen_partner_app") } /> 
                  </a>
                  <a href="https://www.administradordefincaszaragoza.org">
                    <img src=
                    {'https://firebasestorage.googleapis.com/v0/b/dogarfirbase-100004/o/logo%2FAISER.png?alt=media&token=611a89dc-ea17-41c8-85bd-388c639211a1'} 
                    alt=''
                    className={(CustomComponent()==='grande' ?  "imagen_partner": "imagen_partner_app") } /> 
                  </a>
                  <a href="http://www.batiment.es/">
                     <img src=
                    {'https://firebasestorage.googleapis.com/v0/b/dogarfirbase.appspot.com/o/batiment2.jpg?alt=media&token=67ca6a66-1c38-407a-8cba-5eed3c963aa1'} 
                    alt=''
                    className={(CustomComponent()==='grande' ?  "imagen_partner": "imagen_partner_app") }  /> 
                  </a>

                  <a href="http://www.administradorfincaszaragoza.org/">
                    <img src=
                    {'https://firebasestorage.googleapis.com/v0/b/dogarfirbase-100006/o/logo%2FlogoASC.png?alt=media&token=021635d5-1a16-4be1-86fa-aa8f380c24f6'} 
                    alt=''
                    className={(CustomComponent()==='grande' ?  "imagen_partner": "imagen_partner_app") }  /> 
                  </a>   
                  
                </div>
                  <div className='separacionH2'>
                 <a href="https://www.portalartico.es/">
                    <img src=
                    {'https://firebasestorage.googleapis.com/v0/b/dogarfirbase.appspot.com/o/cropped-logoArticolor-removebg-preview_opt.png?alt=media&token=2c11e592-54b9-4da9-beb5-3ac497d91d38'} 
                    alt=''
                    className={(CustomComponent()==='grande' ?  "imagen_partner": "imagen_partner_app") } /> 
                  </a>
                  <a href="http://fincagestion.es/">
                    <img src=
                    {'https://firebasestorage.googleapis.com/v0/b/dogarfirbase-100002/o/logo%2Ffincagestion_logo3.png?alt=media&token=d6b2bcc9-c2a0-40f9-8a5b-de48946a7a02'} 
                    alt=''
                    className={(CustomComponent()==='grande' ?  "imagen_partner": "imagen_partner_app") } /> 
                  </a> 
                  <a href="https://aticoadf.es/">
                    <img src=
                    {'https://firebasestorage.googleapis.com/v0/b/dogarfirbase.appspot.com/o/atico3.png?alt=media&token=563531a3-f437-4d80-bb84-42c68ff95cd9'} 
                    alt=''
                  className={(CustomComponent()==='grande' ?  "imagen_partner": "imagen_partner_app") } /> 
                  </a>
                  <a href="https://www.santedfincas.com/">
                    <img src=
                    {"https://firebasestorage.googleapis.com/v0/b/dogarfirbase-100009/o/logo%2FCaptura%20de%20pantalla%202021-05-17%20a%20las%2017.49.04.png?alt=media&token=15a58fd9-a402-45d2-bdbc-942d923e8fb2"
                  } alt=''
                   className={(CustomComponent()==='grande' ?  "imagen_partner": "imagen_partner_app") } /> 
                  </a>

     
                </div>
                 </div>

                
                </> 

               </div>
           
         
           </div>
           
         </p>
   
   
       
         </Grid>  
   
    <Copyright/>
  
    <Dialog open={abrirAviso} /*onClick={thi.handleClickClose}*/ aria-labelledby="form-dialog-title"
   scroll="body"   fullWidth={true}
   maxWidth='lg'      >
 
  <DialogContent dividers>

  <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          > 
         
    <spam  className='dialogTitle2'>Únete a C365</spam>

    <p  className='dialogTitle' id="form-dialog-title">
    Confirma y rellena los siguientes campos para poder entender mejor tu necesidad y ajustar al máximo tu propuesta
    </p>

    <RadioGroup  row={CustomComponent()==='grande'}
     aria-label="position" name="position"
     value={cliente_tipo}
     onChange={(event)=>{setCliente(event.target.value)}}
   
     >
    <FormControlLabel  className='dialogTitle' 
      value="comu"
      control={<Radio color="secondary" />}
      label="Comunidad de Vecinos"
      labelPlacement="top"
    />
    <FormControlLabel   className='dialogTitle' 
      value="admin"
      control={<Radio color="secondary" />}
      label="Despacho de administración de fincas"
      labelPlacement="top"
    />
  </RadioGroup>
  {CustomComponent()==='grande'&&   
  <TextField className='cuadro_input'     
    autoFocus
    margin="dense"
    id="titulo"
    name ="texto_titulo"
    label="¿A quien vamos a dirigirnos?"
    type="text"
    value={texto_nombre}
    onChange={(event)=>{setNombre(event.target.value)}}
              
    fullWidth/>}
  
  <div  className={(CustomComponent()==='grande' ? 'espacios_entre':'espacios_entre_col')}>
  
  {CustomComponent()!=='grande'&&   <TextField className='cuadro_input'     
                 autoFocus
    margin="dense"
    id="titulo"
    name ="texto_titulo"
    label="¿A quien vamos a dirigirnos?"
    type="text"
    value={texto_nombre}
    onChange={(event)=>{setNombre(event.target.value)}}
            ///  value={texto_titulo}
             // onChange={this.onChange}
    fullWidth/> }    



   <TextField  className='cuadro_input'                          
        
    margin="dense"
    id="texto_envio"
    name ="texto_envio"
    label="Danos un email de contacto"
              type="email"
              fullWidth={CustomComponent()!=='grande'}
   value={texto_email}
   onChange={(event)=>{setEmail(event.target.value)}}
            //  onChange={this.onChange}
             
              />
  <TextField        className='cuadro_input'             
              
  
              margin="dense"
              id="texto_envio"
              name ="texto_envio"
              label="Si lo deseas un teléfono de contacto"
              type="tel"
              fullWidth={CustomComponent()!=='grande'}
              onChange={(event)=>{setTel(event.target.value)}}
               value={texto_tel}
                      //  onChange={this.onChange}
                       
                        />
  
      {CustomComponent()!=='grande'&&<TextField   className='cuadro_input2'   
                multiline
                margin="dense"
                id="texto_url"
                name="texto_url"
                label="Observaciones a tener en cuenta"
                type="text"
                value={texto_obser}
          //      onChange={this.onChange}
                fullWidth
                onChange={(event)=>{setObser(event.target.value)}}
                variant="outlined"
        /> }
  </div>           
  
  {CustomComponent()==='grande'&& <TextField   className='cuadro_input2'   
                multiline
                margin="dense"
                id="texto_url"
                name="texto_url"
                label="Añade cualquier observación que debamos tener en cuenta"
                type="text"
                value={texto_obser}
                onChange={(event)=>{setObser(event.target.value)}}
                fullWidth
                variant="outlined"
  />}
     </DialogContentText>
  
              
  </DialogContent>
    <DialogActions>
    <Button  onClick={(e) => {setabrirAviso(false) ; borrarTextos()}} color="primary">
      Cancelar
    </Button>
    <Button variant="contained"
       disabled ={texto_email==="" || texto_nombre===""}
     onClick={(e) => {enviarFormulario()
      window.scrollTo(window.pageYOffset,0);focoBoton(-1)
      fijarCabecera(offsetStick, setoffsetStick, setmostrarFoto)}} color="primary"
    >
      Confirmar
    </Button>
  </DialogActions>
  </Dialog> 

    <Dialog open={abrirPrueba} onClick={(e) => {setoPrueba(false)}} aria-labelledby="form-dialog-title"
    scroll="body"   fullWidth={true}
    maxWidth='lg'    >
     
  
     <DialogContent>   
    
     <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          > 
         

      <p className='dialogTitle' id="form-dialog-title">
      <spam  className='dialogTitle2'>Prueba la app C365</spam><br/>
        Haz click o lee el código QR de más abajo.
      También puedes ir directamente a tu App o Google store y buscar por "C365". <br/>
      Usa el siguiente código para registrarte y accede a nuestra Comunidad Ficticia como vecino:
      <spam  style={{ color: 'red' }}> c3650110</spam>
      </p>
     
   
       
        <div className={(CustomComponent()==='grande' ? 'tarjetas':'tarjetas_app')}>
  
      
          <Link color="inherit" href="https://apps.apple.com/es/app/c365/id1513718960">
   
          <Card className={(CustomComponent()==='grande' ? 'tarjeta':'tarjeta_app')}>
             <CardContent>
            
               <CardMedia className={(CustomComponent()==='grande' ? 'tarjeta_media':'tarjeta_media_app')}
                  image= {require ("../../Imagenes/landing/appStoreHo.png")}      />
           
                
                </CardContent>
            
          </Card>
        </Link>


        <Link color="inherit" href="https://play.google.com/store/apps/details?id=com.c365&gl=ES">
            <Card className='tarjeta' >
               <CardContent>
            
                  <CardMedia className={(CustomComponent()==='grande' ? 'tarjeta_media':'tarjeta_media_app')}
               image= {require ("../../Imagenes/landing/playStoreHo.png")}      />
              
                 
                </CardContent>
           
        
              </Card>
          </Link>
        
      </div>
      </DialogContentText>
      <p className='dialogTitle'>*Si quieres hacer un test de la herramienta web, mándanos un email a
        soporte@comunidad365.es y te activaremos tu usuario.  </p>
      </DialogContent>
 
      <DialogActions>
        
      <Button  variant="outlined" size="large" onClick={(e) => {
        
      //dataLayer.push({'event':'aceptar_boton'})
        setoPrueba(false)
        window.scrollTo(window.pageYOffset,0); focoBoton(-1)
        fijarCabecera(offsetStick, setoffsetStick, setmostrarFoto)
      }} color="primary">
          Aceptar
      </Button>
      </DialogActions>
      </Dialog>
       
    <Dialog open={okEnvioAviso} /*onClick={thi.handleClickClose}*/ aria-labelledby="form-dialog-title"
    fullWidth="true"         >
    <spam  className='dialogTitle2'>Únete a C365</spam>
    <DialogTitle className='dialogTitle' id="form-dialog-title">Gracias por enviarnos
    la solicitud, te hemos enviado un email a tu correo ({texto_email}) y en breves recibirás también una propuesta detallada. Saludos C365</DialogTitle>
    <DialogActions>
    <Button    onClick={(e) => {setokEnvioAviso(false)}} color="primary">
        Aceptar
    </Button>
    </DialogActions>
    </Dialog>

   
    </ThemeProvider>
  </Grid>
);
}



export default withFirebase(Landing);

