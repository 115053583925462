export function validar_dni(dni,DatosPais)
{
    
    switch (DatosPais.Pais) {
      case 'esp':
   
        const extranjero = dni.slice(0,1)
        var cadena1 = dni.slice(0,8);
        if(extranjero==='Y'||extranjero==='y'){cadena1='1'+dni.slice(1,8)}
        else if(extranjero==='X'||extranjero==='x'){cadena1='0'+dni.slice(1,8)}
        else if(extranjero==='Z'||extranjero==='y'){cadena1='2'+dni.slice(1,8)}
    
        var cadena2 = cadena1.slice(0, 8);
        var Quotient =cadena2 % 23;  
        var vectorLetras=['T','R','W','A','G','M','Y','F','P','D','X','B','N','J','Z','S','Q','V','H','L','C','K','E']
        var  vectorLetras2=['t','r','w','a','g','m','y','f','p','d','x','b','n','j','z','s','q','v','h','l','c','k','e']
    
        console.log(extranjero, dni, cadena1, cadena2, Quotient)
        if(vectorLetras[Quotient]!== dni.slice(8,9)) {            
        if(vectorLetras2[Quotient]!== dni.slice(8,9)) {
            console.log('mal')
            return false}
        }
        return true
    
      case 'uru':
            
            if (dni.length !== 12){
                return false;
            }
            if (!/^([0-9])*$/.test(dni)){
                    return false;
            }

            console.log(dni, dni.length)
            var dc = dni.substr(11, 1);
            var rut = dni.substr(0, 11);
            var total = 0;
            var factor = 2;
        
            for (var i = 10; i >= 0; i--) {
                total += (factor * rut.substr(i, 1));
                factor = (factor === 9)?2:++factor;
            }
        
            var dv = 11 - (total % 11);
        
            if (dv === 11){
                dv = 0;
            }else if(dv === 10){
                dv = 1;
            }
            console.log(dv,dc)
            if(dv === dc){
                
                return true;
            }else{

                console.log('melon mal')
                return false;
            }
      default: return false
    }

}