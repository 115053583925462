import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import { NavLink } from 'react-router-dom'

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

export default function TriggersTooltips(props) {

 
  const usuario = (props.usur ? props.usur :false) 
  
  const Administrador = (!usuario ? true :usuario.Administrador)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [anchorEl3, setAnchorEl3] = React.useState(null);

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }
  function handleClick2(event) {
    if (anchorEl2 !== event.currentTarget) {
      setAnchorEl2(event.currentTarget);
    }
  }
  function handleClose2() {
    setAnchorEl2(null);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  function handleClick3(event) {
    if (anchorEl3 !== event.currentTarget) {
      setAnchorEl3(event.currentTarget);
    }
  }
  function handleClose3() {
    setAnchorEl3(null);
  }

  
 
  return (
    
      <Grid container justifyContent="center">
        <Grid item>
          <Tooltip disableFocusListener title="Ir página inicial">
            <Button href={ROUTES.LANDING}> Página inicial</Button>
          </Tooltip>
        </Grid>
  
        {Administrador&&<>
        <Grid item>
         
       <div>
    
       <Button

        aria-owns={anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onMouseOver={handleClick}
      >
         MENÚ
      </Button>
         
  
    <Menu
         id="simple-menu"
         anchorEl={anchorEl}
         keepMounted
         open={Boolean(anchorEl)}
         onClose={handleClose}
         getContentAnchorEl={null}
        MenuListProps={{ onMouseLeave: handleClose }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ horizontal: "center" }}
      >
         <MenuItem onClick={handleClose}>
        <NavLink to="/home"  style={{ textDecoration: 'none', color:'green', fontWeight: 'bold' }} >IR a MENÚ</NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <NavLink to="/inci"  style={{ textDecoration: 'none', color:'black' }} >INCIDENCIAS</NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
        <NavLink to="/documentos"  style={{ textDecoration: 'none', color:'black' }} >DOCUMENTOS </NavLink>
        </MenuItem>
        <MenuItem>    
        
     
            <div
             
              aria-owns={anchorEl2 ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick2}
            >DATOS MAESTROS
            </div>
          
            <Menu
              id="simple-menu"
              anchorEl={anchorEl2}
              keepMounted
              open={Boolean(anchorEl2)}
              onClose={handleClose2}
              getContentAnchorEl={null}
              MenuListProps={{ onMouseLeave: handleClose2 }}
              anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
              transformOrigin={{ horizontal: "center" }}
            >
               <MenuItem onClick={handleClose}>
            <NavLink to="/datosmaestros"  style={{ textDecoration: 'none', color:'green', fontWeight: 'bold' }} >IR a DATOS MAESTROS
            </NavLink>
            </MenuItem>
             <MenuItem onClick={handleClose2}>
                  <NavLink to="/comunidades"  style={{ textDecoration: 'none', color:'black' }} >COMUNIDADES</NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose2}>
                  <NavLink to="/gremios"  style={{ textDecoration: 'none', color:'black' }} >GREMIOS</NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose2}>
                  <NavLink to="/zonascomunes"  style={{ textDecoration: 'none', color:'black' }} >ZONAS COMUNES</NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose2}>
                  <NavLink to="/actividades"  style={{ textDecoration: 'none', color:'black' }} >ACTIVIDADES</NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose2}>
                  <NavLink to="/categorias"  style={{ textDecoration: 'none', color:'black' }} >CATEGORIAS</NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose2}>
                  <NavLink to="/usuarios"  style={{ textDecoration: 'none', color:'black' }} >USUARIOS</NavLink>
              </MenuItem>
              <MenuItem onClick={handleClose2}>
                  <NavLink to="/roles"  style={{ textDecoration: 'none', color:'black' }} >ROLES</NavLink>
              </MenuItem>
            
            
            </Menu>
          
      
       </MenuItem>
         <MenuItem>       <div>
     
     <div

       aria-owns={anchorEl3 ? "simple-menu" : undefined}
       aria-haspopup="true"
       onClick={handleClick3}
     >JUNTAS/VOTACIONES
     </div>
   
     <Menu
       id="simple-menu"
       anchorEl={anchorEl3}
       keepMounted

       open={Boolean(anchorEl3)}
       onClose={handleClose3}
       getContentAnchorEl={null}
       MenuListProps={{ onMouseLeave: handleClose3 }}
       anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
       transformOrigin={{ horizontal: "center" }}
     >
        <MenuItem onClick={handleClose}>
        <NavLink to="/virtuales"  style={{ textDecoration: 'none', color:'green', fontWeight: 'bold' }} >IR a JUNTAS/VOTACIONES</NavLink>
        </MenuItem>
      <MenuItem onClick={handleClose3}>
           <NavLink to="/junta"  style={{ textDecoration: 'none', color:'black' }} >JUNTAS VIRTUALES</NavLink>
       </MenuItem>
       <MenuItem onClick={handleClose3}>
           <NavLink to="/votaciones"  style={{ textDecoration: 'none', color:'black' }} >VOTACIONES</NavLink>
       </MenuItem>
      
     
     </Menu>
   
</div>
</MenuItem>
    </Menu>
   
      </div>
 
         
        </Grid>
  
        <Grid item>
          <Tooltip disableFocusListener disableTouchListener title="Administrador">
          <Button href={ROUTES.ADMIN}>Administrador</Button>
          </Tooltip>
        </Grid>
        </>}
        <Grid item>
      
          <SignOutButton />
          
        </Grid>
        
       
      </Grid>
    
    

  );
}
