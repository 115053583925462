import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';

import Mapa2 from '../Horario/Mapa2'

import MaterialTable from 'material-table'
import BotonesTriger from '../Botones/botonTrige'
import Cabecera from '../Cabecera/index_365'
import {Textofield, selecto,checkBoxLectura,checkBoxModificar} from '../Fomulario/formulario'
 import {formu_Despacho} from '../Fomulario/formu_despacho'
import moment from 'moment';
import {Fab,ListItemText,Select,Checkbox,Chip, Button, FormHelperText,List,ListItem,MenuItem,FormControl,Modal,
Grid,Tooltip,ButtonGroup,CardHeader,ListItemIcon,Card,Divider,InputLabel,DialogTitle,DialogContentText,
DialogContent,DialogActions,Dialog,TextField,CircularProgress,Backdrop,Box,IconButton,Paper,
Snackbar} from '@material-ui/core';

import { ThemeProvider } from '@material-ui/styles';
import ModalTutoriales,{traerTutoriales} from  '../Tutoriales/tutoriales'
import  firebase2  from 'firebase'
import { forwardRef } from 'react';
import {DoneOutline,AddBox,ArrowDownward,Check,ChevronLeft,ChevronRight,Clear,DeleteOutline,Edit,FilterList, Close
,FirstPage,LastPage,Remove,SaveAlt,Search,ViewColumn,LockOpen,VerifiedUser} from '@material-ui/icons';
import Copyright from '../Privacidad/copyright';
import { createTheme } from '@material-ui/core/styles'

import {traeUsuariosDespacho} from '../DatosMaestros/usuarios'
import { trim } from 'jquery';
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main:  '#00a049',
      claro: '#00a049'
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#00a049',
    },
  },
});


export function  traeUsuariosDespacho2(tthis) {
  
      
   return new Promise(resolve => {  
    if(tthis.state.usuariosDespacho.length>0){
      resolve(tthis.state.usuariosDespacho)
      traeUsuariosDespacho(tthis.props.firebase).then(users=>{tthis.setState({usuariosDespacho:users})})
    }else{
      resolve( traeUsuariosDespacho(tthis.props.firebase)) 
    }
    
    
    
    })
  }

class Administrador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nombreDesp:'', cifDesp:0,codigoDesp:0,poblacionDesp:'',direccionDesp:'',cpDesp:'',personaDesp:'',emailDesp:'',telefonoDesp:'',telefono2Desp:'',
      rowData:[],
      usuariosDespacho:[],
      tipoRegistro:'',
      abrir_cambios:false, 
      abrir_cambios2:false,
      mNombre:'', mApellido:'',mTelefono:'',mRoles:[],mRol:'',editar:false,
      memail:'',mDNI:'',memailVerified:'',mFichar:'',
      mPorcentaje:'',mlastSignInTime:'',mcreationTime:'',
      mTodo:false, mIncidencias:false,mSiniestros:false
      ,mCambios:false,mAdminCon:false, mSeguros:false,
      Mapa1:null,
      offset:'0%',
      centrosTrabajo:[],
      añoActual:'',
      años:[],
      centrosTrabajoMostrar:[],
      listaHorasTrabajadasDia:[],
      Fecha_registro:'',
      usuariosFichar:[],
      ficharList:[],
      usuarios:[],
      uid:"",
      direccion:"",
      añadir: true,
      incis: [],
      comus: [],
      open: false,
      comunidad:'', 
      mostrarAyuda:false,
      tipo:'guardar',
      cambioZona:true,
      mostrarHorario:false,
      Id:'',
      mostrarTabla: false,
      selectedIndex:'',
      email: '',
      gremioBorrar:"",
      password: '',
      error: null,
      error2: null,
      modificar: true,
      tenemosLista:false,
      snack:false, snack2:false, vertical: 'top',
      horizontal: 'center', 
      seguro : false,  
      deshabilitar:false,
      actividades:[],    
      lista_comunidades:{},
        leftChecked:[],
        abrir_horas:false,
        nombreyapellidos:'',
      rightChecked:[],
      left:[1,2,3,4],
      leftOriginal:[],
      right:[5,6,711], 
      checked:[],
      roles:[],
      contraseña:'',
      abrirContraseña:false,
      listaHorasTrabajadas:[],
      listaHorasTrabajadasFiltrada:[],

      clave: '',
      flujoCorreos:[],
      zonaEnvio:[],
      abrir_zonas_envio:false,
      despacho:
      {
        "Nombre": "",
        "CIFNIF": "",
        "Codigo": "",
        "Fecha_registro": "",
        "Telefono1":"",
        "Telefono2":"",
        "Correo":"",
        "Direccion":"",
        "Cp":"",
        "Poblacion":"",
        "Persona":"",
        "Logo":"",
        "Iban":"",
        "uid":""
      },
      usuario:'',
      tituloEnvio:'',
      zonasEnvio:[
        
        { key: 0, label: 'Todos', click:false },
        { key: 1, label: 'Incidencias',  click:false },
        { key: 2, label: 'Siniestros',  click:false},
        { key: 3, label: 'Modificación Cliente',  click:false},
        { key: 4, label: 'Consulta Admin'  ,click:false },
        { key: 5, label: 'Consulta Seguros' , click:false },
        { key: 6, label: 'ROLES' , click:false },
  

      ],
   
  };
  
    this.borrarFiltros = this.borrarFiltros.bind(this);
    this.buscarUsuarios = this.buscarUsuarios.bind(this);
    this.comprobarExisteUsuario = this.comprobarExisteUsuario.bind(this)
    this.handleClose =     this.handleClose.bind(this)
    this.anularGremio = this.anularGremio.bind(this)
    this.buscarComunidades=this.buscarComunidades.bind(this)
    this.comprobarDni = this.comprobarDni.bind(this)
    this.buscarDespacho = this.buscarDespacho.bind(this)
    this.handleClickClose = this.handleClickClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.traerTablaCorreos=this.traerTablaCorreos.bind(this)
    this.envioEmailVerificacion= this.envioEmailVerificacion.bind(this)
    this.cerrarGestionZonas= this.cerrarGestionZonas.bind(this)
    this.cerrarGestionZonasOK= this.cerrarGestionZonasOK.bind(this)
    this.traerUsuarioLogueado = this.traerUsuarioLogueado.bind(this)
  }

  gestionHoras=(rowData)=>()=>{
    this.setState({nombreyapellidos: rowData.Nombre+" "+rowData.Apellido})
                                 
    this.traer_horas_trabajadas(rowData.uid).then(()=>{

    this.setState({abrir_horas:true})
   })


}
cerrarGestionHoras = ()=> {
  this.setState({abrir_horas:false})
  this.setState({nombreyapellidos: ''})                                   
}


traer_horas_trabajadas (uid){

  return    new Promise((resolve,reject) => {  
     this.props.firebase.fichar(uid).once('value', snapshot => {
      const usersObject = snapshot.val();
      var lista= []
      const centrosTrabajo=this.state.centrosTrabajo
     
      if (usersObject){   
          var  listaFicha = Object.entries(usersObject ).map(([key, val]) => ({
          
         "fecha": key,"slot":(val ? val:[])
        }));
        var index=0
        var año=''
        var años=[]
        listaFicha.forEach(function(registro){
         
          registro.slot.forEach((datos)=>{
            var duracion=0
            if (datos.Salida!=="SIN REGISTRO"){
              var breakfast = moment(datos.Entrada,'HH:mm');
              var lunch = moment(datos.Salida,'HH:mm');
              duracion = lunch.diff(breakfast, 'hours', true)
              
            }                
            const centro = centrosTrabajo.filter(centr=>centr.uid===datos.Centro)
            const fecha = registro.fecha.slice(6,8) +"/"+registro.fecha.slice(4,6)+"/"+registro.fecha.slice(0,4);
           
            if(año!==registro.fecha.slice(0, 4)){
              años=años.concat({'Nombre':registro.fecha.slice(0, 4)})
              año= registro.fecha.slice(0, 4);
            }
            lista = lista.concat( {
               "Fecha":fecha, "Entrada":  datos.Entrada,
               'Año':registro.fecha.slice(0,4),
             "Salida": datos.Salida, "Horas":(duracion===0 ? "NA": duracion.toString().slice(0,4).replace('.',',')),
      //       "uid":(centro&&centro.length>0 ? centro[0].uid:""),
             "indice":index,
             "Semana":moment(fecha, "DD/MM/YYYY").week().toString(),
             "Centro":(centro&&centro.length>0 ? centro[0]:""), 
             'uid':registro.fecha,           
             "Usuario_Mod":(registro.Usuario_Mod ? registro.Usuario_Mod:'') ,                                                                                          
             "Fecha_Modificacion": (registro.Fecha_Modificacion ? registro.Fecha_Modificacion:'')           
            })
            index++
            
          })
          
         })
      }
  
      lista.sort(function (a, b) {
        const date1= moment(a.Fecha, "DD/MM/YYYY")
        const date2 = moment(b.Fecha, "DD/MM/YYYY")
        if (date1> date2) {
          return 1;
        }
        if (date1 < date2) {
          return -1;
          
        }
      // a must be equal to b
        return 0;
      })
      let añoActual = moment(new Date()).format('YYYY')
      const nuevaLista = lista.filter(zon=>zon.Año===añoActual)
  
      this.setState({listaHorasTrabajadas:lista, listaHorasTrabajadasFiltrada:nuevaLista,añoActual:añoActual,
        años:años})
      resolve(lista)
    })
  })

  }

 
  handleClose () {
  this.setState({snack:false, snack2:false})
  };
  buscarComunidades(){
  this.props.firebase.comus().once('value', snapshot => {
    const usersObject = snapshot.val();
    if(usersObject){
      const comusList = Object.keys(usersObject).map(key => ({
      ...usersObject[key],
      uid: key,
    }));

    let lista_comunidades={}
    comusList&&comusList.forEach(comu =>{
      const id  = comu.Id;
      //quitmaos las actividades con id -1 y la de seguros porque no aplica a las  activdades de Incidencias
      if (comu!== '')  {lista_comunidades[ id ] = id}
      
     } )
     lista_comunidades[""] = ""

  
    this.setState({comus: comusList, lista_comunidades:lista_comunidades});

    }else{  this.setState({comus: [], lista_comunidades:[]})}
  });
  }
  traerTablaCorreos(){
    var correosList =[]
    this.props.firebase.flujoCorreos().once('value', snapshot => {
      const usersObject = snapshot.val();
      if (usersObject){
        correosList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
      
     
        }
      }).then(()=>{  this.setState({flujoCorreos:correosList})

      })
  


  }
  componentDidMount() {
 //   this.setState({open:true})
  traerTutoriales(this, 'Admin').then((data)=>{this.setState({VideoTuto:data})})
  traeUsuariosDespacho(this.props.firebase).then(users=>{this.setState({usuariosDespacho:users})})

    this.buscarDespacho()
    this.traerTablaCorreos()

 
    this.buscarComunidades(); 
   
    this.props.firebase.roles2().once('value', snapshot => {
      const usersObject = snapshot.val();
     
      if (usersObject){
  
        var rolesList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
        rolesList.sort(function (a, b) {
          if (a.Nombre> b.Nombre) {
            return 1;
          }
          if (a.Nombre < b.Nombre) {
            return -1;
            
          }
        // a must be equal to b
          return 0;
        })
       
     
        this.setState({roles:rolesList})
      }
    })
 
}
  buscarDespacho(){

    var despacho= this.state.despacho
    

   this.props.firebase.despacho().once('value', snapshot => {
      const usersObject = snapshot.val();
      despacho.Nombre= usersObject.Nombre
      despacho.CIFNIF=usersObject.CIFNIF
      despacho.Codigo=usersObject.Codigo
      despacho.Fecha_registro=usersObject.Fecha_registro
      despacho.Telefono1=usersObject.Telefono1
      despacho.Telefono2=usersObject.Telefono2
      despacho.Correo=usersObject.Correo
      despacho.Direccion=usersObject.Direccion
      despacho.Cp=usersObject.Cp
      despacho.CentroTrabajo=usersObject.CentroTrabajo
      despacho.Poblacion=usersObject.Poblacion
      despacho.Persona=usersObject.Persona
      despacho.Logo=usersObject.Logo
      despacho.Iban=usersObject.Iban
      despacho.Tipo=usersObject.Tipo      
      despacho.uid=snapshot.key
     }).then (()=> this.setState({despacho:despacho}))
     .catch((e)=>{
      const error= e
     })
    

  }
  traerUsuarioLogueado(){
    var user =  this.props.firebase.auth.currentUser;
    var usuario=[]
    if(user) {
      var usuariosList = this.state.usuarios
     
      usuario = usuariosList.find(usu => usu.Clave ===user.uid) 
      if(usuario&&!usuario.SuperUser){
  
            usuariosList = usuariosList.filter(usu => !usu.SuperUser &&( usu.Lopd===false ||!usu.Lopd)) 
           
          this.setState({usuarios:usuariosList})
      
      }
     
    }    
    
    
  }
  
  envioEmailVerificacion = (email) => () =>{
    
        var actionCodeSettings = {
          url: 'https://comunidad365.es/home',
          handleCodeInApp: true,
     //     dynamicLinkDomain: 'c365.page.link'
       //   https://c365.page.link/jTpt
        };
        var generateEmailVerificationLink= this.props.firebase.functions.httpsCallable('generateEmailVerificationLink');
                                        
        new Promise((resolve,reject) => {  
             
          generateEmailVerificationLink({useremail:email,actionCodeSettings:actionCodeSettings}).then(function(result){
              // Read result of the Cloud Function.
            
              resolve(result.data)                                 
    
            }).catch(function(error) {
              // Getting the Error details.
            
              reject(error)
              // ...
            });
          }).then(()=>{

            alert('Se ha mandado el correo de verificación a '+ email)
          })
     
          
      
    
  }
  buscarUsuarios(){

  this.setState({open:true,mostrarHorario:false, mostrarTabla: false, añadir: true, tenemosLista:false,deshabilitar:false }) 
  this.traerTablaCorreos()
  const despachoCode =this.props.firebase.despachoCode
  const P1 =new Promise(resolve => {
          this.props.firebase.users().once('value', snapshot => {
              const usersObject = snapshot.val();
              if (usersObject){
                  var usuariosList = Object.keys(usersObject).map(key => ({
                    ...usersObject[key],
                    uid: key,
                  }));
                  usuariosList.forEach(function(usuario) {
                    if(usuario.Roles&&usuario.Roles.length>0){    
                    usuario.Roles = usuario.Roles.filter(rol =>rol !== "")
                    }else {usuario.Roles=[]}
                  } )
                  usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false && usuario.Administrador === true
                    )
                  resolve(usuariosList)
                }
            })
    })
    const P2=new Promise(resolve => {    traeUsuariosDespacho2(this).then((result)=>{
       resolve(result)} )})  
    
    var usuarios=[]
   

    const getData = async () => {return Promise.all([P1,P2])}
    
    getData().then(datos => {
          
      var usuariosList=datos[0]  
      usuarios = datos[1]
      var nuevosUsuarios=[]

                  // Read result of the Cloud Function.
                
       usuariosList.forEach((usuario)=>{
                  
                      var usu =     usuarios.filter(us =>us.uid===usuario.Clave)
                      if(usu&&usu.length>0){
                          const melon= usu[0].metadata.lastSignInTime
                        if(melon){
                          let fecha= new Date(melon)    

                          usu[0].metadata.lastSignInTime=fecha.toLocaleString("es-ES")
                        }  
                        const melon2= usu[0].metadata.creationTime
                        if(melon2){
                          let fecha= new Date(melon2)    

                          usu[0].metadata.creationTime=fecha.toLocaleString("es-ES")
                        }  

                          var Obj = { ...usu[0],...usuario}
                          nuevosUsuarios= nuevosUsuarios.concat(Obj)      
                      }
                  })
         //Filtramos los datos con el valor de la comunidad:
         if(this.state.comunidad&&this.state.comunidad !==''){
           datos = datos.filter(usuario => usuario.Comunidad===this.state.comunidad)
         }     
     
         
         var nuevosUsuarios2=[]
         const usuariosList2 =this.state.flujoCorreos
         
         nuevosUsuarios.map(usuario=>{

             var usu = usuariosList2.filter(us =>us.uid===usuario.uid)
             if(usu&&usu.length>0){
                 var Obj = {...usuario, ...usu[0]}
                 nuevosUsuarios2= nuevosUsuarios2.concat(Obj)      
             }
         })


         nuevosUsuarios2.sort(function (a, b) {
          if (a.uid< b.uid) {
            return 1;
          }
          if (a.uid > b.uid) {
            return -1;
            
          }
        // a must be equal to b
          return 0;
        });
        var guardaDespacho = this.props.firebase.functions.httpsCallable('guardaDespacho');
       
        const getData = async () => { return Promise.all(nuevosUsuarios2.map((us)=>{
            
          return new Promise(resolve => { 
            const customClaims=us.customClaims
            if(customClaims){
              resolve(true)
            }
            else{
          guardaDespacho({'uid':us.Clave,'despacho':despachoCode.Codigo,'BaseDatos':despachoCode.BaseDatos, 
          'Almacen':despachoCode.Almacen, 'Code':despachoCode.Code}).then(()=>{           
          resolve(true)}
          )}

        })
        }))
      }
      getData().then(data => {
    


         this.setState({usuarios:nuevosUsuarios2})
         this.traerUsuarioLogueado() 
         this.setState({ mostrarTabla: true, open:false });  
         this.borrarFiltros();
        })
      })     
      
     
  }
  borrarFiltros(){ 
       this.setState({comunidad:'', direccion:'',
       tenemosLista:false, seguro:false });
  }
  comprobarDni(dni) {
 
  if(dni=== '11111111'){return true}
  var cadena1 = dni.slice(0,8);
  const extranjero = dni.slice(0,1)
  if(extranjero==='Y'||extranjero==='y'){cadena1='1'+dni.slice(1,8)}
		else if(extranjero==='X'||extranjero==='x'){cadena1='0'+dni.slice(1,8)}
		else if(extranjero==='Z'||extranjero==='y'){cadena1='2'+dni.slice(1,8)}
  
  var cadena2 = cadena1.slice(0, 8);
  var Quotient =cadena2 % 23;  




  const vectorLetras=['T','R','W','A','G','M','Y','F','P','D','X','B','N','J','Z','S','Q','V','H','L','C','K','E']
  const vectorLetras2=['t','r','w','a','g','m','y','f','p','d','x','b','n','j','z','s','q','v','h','l','c','k','e']
  if(vectorLetras[Quotient]!== dni.slice(8,9) && vectorLetras2[Quotient]!== dni.slice(8,9)) {return false}  
  return true;       
}
  comprobarExisteUsuario(dni) {
  var existe=[];
  if(this.state.usuarios){
    existe = this.state.usuarios.filter(usuario =>{
      const usu= usuario.DNI
      
      return(usu.toUpperCase()===dni.toUpperCase())} )
  }
    return existe;       
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  };
  anularGremio() {
  new Promise(resolve => {
      const uidGremio=this.state.uid
      resolve(this.state.comus.forEach((comunidad) => {
     this.props.firebase.comu(comunidad.uid+"/ActivGremios/")
     
     .once('value', snapshot => {
     //     const usersObject = snapshot.val();
          snapshot.forEach((registro) => {
          
              const melon = registro.val()
              if (melon===uidGremio) {
                this.props.firebase.comu(comunidad.uid+"/ActivGremios/")
                .update({[registro.key] :"-1",  })
                this.props.firebase.comu(comunidad.uid+"/ActivGremioAuxiliar/").update({[registro.key] :"-1",  })
                
              }
          });
          this.props.firebase.gremio(uidGremio).update({ "Borrado":"true",  })
    
    
        })    
       })
    
      
    )
 }).then(() => {
    this.setState({snack2:false, snack:true, error2:"El gremio ha sido borrado" });  
    this.buscarUsuarios();

    })
    .catch(error => { 
      this.setState({snack2:false, snack:true, error2:"Ha  habido un problema y no se ha podido borrar" });  
      this.setState({ error });
    });

  }
  checkEnSeguro = (oldData) => () =>{
  var newData= oldData
  var data = this.state.gremios;
  
    if(!oldData.Seguro&&window.confirm('¿Quieres que el Gremio pase a ser del Tipo compañia de Seguros?')){
        newData.Seguro = true;       
        data[data.indexOf(oldData)] = newData;
        this.setState({gremios:data }); 
        new Promise(resolve => {
          setTimeout(() => {
              resolve();
                this.props.firebase.gremio(oldData.uid+'/').update({ 
               
                  Fecha_Modificacion:firebase2.database.ServerValue.TIMESTAMP,
                  Seguro: true      
              })
          }, 1);
          }).then(() => { this.componentDidMount()})

    }
    else if(oldData.Seguro&&window.confirm('¿Quieres que el Gremio deje de ser del Tipo compañia de Seguros?')){
    
      newData.Seguro = false;      
      data[data.indexOf(oldData)] = newData;
      this.setState({gremios:data }); 
      new Promise(resolve => {
        setTimeout(() => {
            resolve();
              this.props.firebase.gremio(oldData.uid+'/').update({ 
             
                Fecha_Modificacion:firebase2.database.ServerValue.TIMESTAMP,
                Seguro: false    
            })
        }, 1);
        }).then(() => { this.componentDidMount()})

      
  }

  
  }
  handleChangeAct = (oldData) => (event) => {
  var newData= oldData
//  this.setState({actividades:event.target.value})
  var data = this.state.roles;
  newData.Roles = event.target.value;
  newData.Roles = newData.Roles.filter(rol => rol!=='');
   
  data[data.indexOf(oldData)] = newData;
  this.setState({roles:data }); 

  //  setPersonName(event.target.value);
  }
  handleClickOpen (clave) {this.setState({abrirContraseña:true, contraseña:'',clave:clave})       };
  handleClickCloseOk() {
                                    
    return new Promise((resolve,reject) => {  
      var updatePass= this.props.firebase.functions.httpsCallable('updatePass');
      updatePass({uid:this.state.clave, contraseña:this.state.contraseña}).then((result)=>{
          // Read result of the Cloud Function.
        
          resolve(true)           
          alert("La contraseña ha sido actualizada. Nueva contraseña: " + this.state.contraseña)        
          this.setState({abrirContraseña:false, contraseña:'', clave:''})                            

        }).catch(function(error) {
          // Getting the Error details.
     //   var code = error.code;
       //   var message = error.message;
         // var details = error;
          
          reject(error)
        
          // ...
        });
      }).then(() => { 
      
      })
     
  }
  handleClickClose = () => {this.setState({abrirContraseña:false, contraseña:'', clave:''})       }; 


  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });

    
    const eventoNombre=event.target.name
    const eventoValor= event.target.value
    //reiniciamos centros de TRabajo
    var centrosList=[]
    this.props.firebase.puestosdeTrabajo().once('value' , snapshot => {
   
      const usersObject= snapshot.val()
      if (usersObject){
        centrosList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }))
        this.setState({centrosTrabajo:centrosList})
      }else{this.setState({centrosTrabajo:[]})
      }
    }).then(()=>{

      if (eventoNombre==='usuario'){
          const usuario = eventoValor
          this.setState({centrosTrabajoMostrar:centrosList, abrir_horas:false,
            listaHorasTrabajadas:[],listaHorasTrabajadasFiltrada:[],Fecha_registro:''})
          if(usuario!==''){ 
            const centrosTrabajoMostrar=this.state.centrosTrabajo.filter(centro =>
              (centro.Usuarios&&centro.Usuarios.length>0 ? centro.Usuarios.includes(usuario.uid):false))
          


            this.setState({centrosTrabajoMostrar:centrosTrabajoMostrar})
            this.setState({Mapa1:[]})
            this.traer_horas_trabajadas (usuario.uid).then(()=>{
              this.setState({nombreyapellidos: usuario.Nombre+" "+usuario.Apellido})
              this.setState({abrir_horas:true})
              const registro={ "despacho":this.state.despacho, "centrosTrabajo":centrosTrabajoMostrar,
              "offset":this.state.offset}

              const Mapa1 =  <Mapa2 {...registro} />
             
              this.setState({Mapa1:Mapa1})
      
            
            })      
          }else{
        
            this.setState({Mapa1:[]})
            const registro={ "despacho":this.state.despacho, "centrosTrabajo":centrosList,
            "offset":this.state.offset}

            const Mapa1 =  <Mapa2 {...registro} />
           
            this.setState({Mapa1:Mapa1})

          }

      }
      else if (eventoNombre==='Fecha_registro'){
              this.setState({Mapa1:[]})
              const date =  eventoValor
              const listaHorasTrabajadas= this.state.listaHorasTrabajadas
              const fecha1 = date.slice(8,10) +"/"+date.slice(5,7)+"/"+date.slice(0,4);
              var centrosTrabajoMostrar=[]
              if(listaHorasTrabajadas&&listaHorasTrabajadas.length>0){

                const centrosTrabajo=centrosList
              
                centrosTrabajo.forEach(centro=>{
                  const centroMostrar = listaHorasTrabajadas.filter(slot => slot.Centro.uid===centro.uid&&
                        slot.Fecha===fecha1)
                  if(centroMostrar&&centroMostrar.length>0){
                    var centrol = centro
                    centrol.Entradas=centroMostrar
                    var totalHoras=0
                    centroMostrar.map(centro1=>totalHoras=totalHoras+Number(centro1.Horas.replace(',','.')))
                    centrol.totalHoras=totalHoras
                    centrosTrabajoMostrar=centrosTrabajoMostrar.concat(centrol)
                  }
                })
              }

              if(centrosTrabajoMostrar.length>1){
                
               // this.animateCircle()
                //this.setState({abrir_horas:false})
              }

              this.setState({centrosTrabajoMostrar:centrosTrabajoMostrar})
              const registro={ "despacho":this.state.despacho, "centrosTrabajo":centrosTrabajoMostrar,
              "offset":this.state.offset}

              const Mapa1 =  <Mapa2 {...registro} />
             
              this.setState({Mapa1:Mapa1})
              
              
              
      }
  
    })
  }

  seleccionarZonaEnvio  = (zona)  => () => {
    this.setState({tituloEnvio:zona.label, zonaEnvio:zona,  leftChecked : [], rightChecked:[]})
  }
  cerrarGestionZonas = () => {
   
 
    this.setState({abrir_zonas_envio:false, tituloEnvio:''})

  }

  cerrarGestionZonasOK = () => {
   
    const zonasEnvio = this.state.zonasEnvio
    
    var newData=this.state.rowData
    
 //   if(newData.correoZonas.Todos&&newData.correoZonas.Todos.length>0){ newData.correoZonas.Todos = []}
 //   if(newData.correoZonas.Incidencias&&newData.correoZonas.Incidencias.length>0){   newData.correoZonas.Incidencias =[]}
 //   if(newData.correoZonas.Siniestros&&newData.correoZonas.Siniestros.length>0){ newData.correoZonas.Siniestros =[]}
 //   if(newData.correoZonas.Cambios&&newData.correoZonas.Cambios.length>0){ newData.correoZonas.Cambios =[]}
 //   if(newData.correoZonas.AdminCon&&newData.correoZonas.AdminCon.length>0){ newData.correoZonas.AdminCon =[]}
 //   if(newData.correoZonas.Seguros&&newData.correoZonas.Seguros.length>0){ newData.correoZonas.Seguros =[] }
    
    if(!newData.correoZonas){newData.correoZonas={}}
     newData.correoZonas.Todos = []
     newData.correoZonas.Incidencias =[]
     newData.correoZonas.Siniestros =[]
     newData.correoZonas.Cambios =[]
     newData.correoZonas.AdminCon =[]
     newData.correoZonas.Seguros =[] 
     newData.correoZonas.Roles =[] 
    zonasEnvio[0].left.map((zona)=>newData.correoZonas.Todos =   newData.correoZonas.Todos.concat(zona.Id))
    zonasEnvio[1].left.map((zona)=>newData.correoZonas.Incidencias =   newData.correoZonas.Incidencias.concat(zona.Id))
    zonasEnvio[2].left.map((zona)=>newData.correoZonas.Siniestros =   newData.correoZonas.Siniestros.concat(zona.Id))
    zonasEnvio[3].left.map((zona)=>newData.correoZonas.Cambios=   newData.correoZonas.Cambios.concat(zona.Id))
    zonasEnvio[4].left.map((zona)=>newData.correoZonas.AdminCon=   newData.correoZonas.AdminCon.concat(zona.Id))
    zonasEnvio[5].left.map((zona)=>newData.correoZonas.Seguros=   newData.correoZonas.Seguros.concat(zona.Id))
    zonasEnvio[6].left.map((zona)=>newData.correoZonas.Roles=   newData.correoZonas.Roles.concat(zona.Id))
  
        var data = this.state.usuarios;
      
        var indice=-1
        data.forEach((usuario, ind)=>{
        if(usuario.uid===newData.uid){
          indice = ind
          }
        })
        data[indice]= newData

      
      
      this.setState({usuarios:data}); 
          
        
        this.setState({abrir_zonas_envio:false, tituloEnvio:'', rowData:newData})
  }

  gestionZonas(rowData) {  
   
    var zonasEnvio= this.state.zonasEnvio
    var comunidades = this.state.comus.filter(comunidad => comunidad.uid !== -1 );    
    const zoqui =rowData.correoZonas
    if(zoqui){
      zonasEnvio[0].click=!rowData.Todo
      var comus = comunidades
     const Todoss=rowData.correoZonas.Todos
      if(Todoss&&Todoss.length>0){
          comus = comunidades
          var comufinal=[]
          var comun=[]
            Todoss.forEach((comu)=>{
              comun = comus.filter(com=>  com.Id===comu)
              if(comun){comufinal=comufinal.concat(comun)}
          })   
          zonasEnvio[0].left= comufinal
          comus = comunidades
          Todoss.map((comu)=>comus = comus.filter(com=>  com.Id!==comu))
        
          zonasEnvio[0].right=comus
      }else {
        zonasEnvio[0].left=[]
        zonasEnvio[0].right=comunidades
        
      }

      zonasEnvio[1].click=!rowData.Incidencias
      const Incidenciass=rowData.correoZonas.Incidencias
      if(Incidenciass&&Incidenciass.length>0){
          comus = comunidades
           comufinal=[]
           comun=[]
           Incidenciass.forEach((comu)=>{
            comun = comus.filter(com=>  com.Id===comu)
            if(comun){comufinal=comufinal.concat(comun)}
        })
          zonasEnvio[1].left= comufinal
        comus = comunidades
        Incidenciass.map((comu)=>  comus = comus.filter(com=>  com.Id!==comu))
        zonasEnvio[1].right=comus
      }else {
        zonasEnvio[1].left=[]
        zonasEnvio[1].right=comunidades
        
      }

      zonasEnvio[2].click=!rowData.Siniestros
      const Siniestross=rowData.correoZonas.Siniestros
      if(Siniestross&&Siniestross.length>0){
           comus = comunidades
           comufinal=[]
           comun=[]
           Siniestross.forEach((comu)=>{
            comun = comus.filter(com=>  com.Id===comu)
            if(comun){comufinal=comufinal.concat(comun)}
         })
         zonasEnvio[2].left= comufinal
         comus = comunidades
         Siniestross.map((comu)=>comus = comus.filter(com=>  com.Id!==comu))
         zonasEnvio[2].right=comus
       }else {
         zonasEnvio[2].left=[]
         zonasEnvio[2].right=comunidades
         
       }
       
      zonasEnvio[3].click=!rowData.Cambios
      const Cambioss=rowData.correoZonas.Cambios
      if(Cambioss&&Cambioss.length>0){
          comus = comunidades
          comufinal=[]
          comun=[]
          Cambioss.forEach((comu)=>{
            comun = comus.filter(com=>  com.Id===comu)
            if(comun){comufinal=comufinal.concat(comun)}
        })
        zonasEnvio[3].left= comufinal
        comus = comunidades
        Cambioss.map((comu)=>comus = comus.filter(com=>  com.Id!==comu))
        zonasEnvio[3].right=comus
      }else {
        zonasEnvio[3].left=[]
        zonasEnvio[3].right=comunidades
        
      }

      zonasEnvio[4].click=!rowData.AdminCon
      const AdminConn=rowData.correoZonas.AdminCon
      if(AdminConn&&AdminConn.length>0){
          comus = comunidades
          comufinal=[]
          comun=[]
          AdminConn.forEach((comu)=>{
            comun = comus.filter(com=>  com.Id===comu)
            if(comun){comufinal=comufinal.concat(comun)}
        })
        zonasEnvio[4].left= comufinal
        comus = comunidades
        AdminConn.map((comu)=>comus = comus.filter(com=>  com.Id!==comu))
        zonasEnvio[4].right=comus
      }else {
        zonasEnvio[4].left=[]
        zonasEnvio[4].right=comunidades
        
      }
 

      zonasEnvio[5].click=!rowData.Seguros
      const Seguross=rowData.correoZonas.Seguros
      if(Seguross&&Seguross.length>0){
          comus = comunidades
          comufinal=[]
          comun=[]
          Seguross.forEach((comu)=>{
            comun = comus.filter(com=>  com.Id===comu)
            if(comun){comufinal=comufinal.concat(comun)}
        })
        zonasEnvio[5].left= comufinal
        comus = comunidades
      
        Seguross.map((comu)=>comus = comus.filter(com=>  com.Id!==comu))
        zonasEnvio[5].right=comus
      }else {
        zonasEnvio[5].left=[]
        zonasEnvio[5].right=comunidades
        
      }

      zonasEnvio[6].click=false
      if(rowData.correoZonas.Roles&&rowData.correoZonas.Roles.length>0){
          comus = comunidades
          comufinal=[]
          comun=[]
          rowData.correoZonas.Roles.forEach((comu)=>{
            comun = comus.filter(com=>  com.Id===comu)
            if(comun){comufinal=comufinal.concat(comun)}
        })
        zonasEnvio[6].left= comufinal
        comus = comunidades
        rowData.correoZonas.Roles.map((comu)=>comus = comus.filter(com=>  com.Id!==comu))
        zonasEnvio[6].right=comus
      }else {
        zonasEnvio[6].left=[]
        zonasEnvio[6].right=comunidades
        
      }

     
    }else{
      rowData.correoZonas= {
          Todos:[],
          Incidencias:[],
          Siniestros:[],
          Cambios:[],
          AdminCon:[],
          Seguros:[]
      }
      zonasEnvio[0].click=!rowData.Todo
      zonasEnvio[0].left=[]
      zonasEnvio[0].right=comunidades
      
      zonasEnvio[1].click=!rowData.Incidencias
      zonasEnvio[1].left=[]
      zonasEnvio[1].right=comunidades
      
      zonasEnvio[4].click=!rowData.AdminCon
      zonasEnvio[4].left=[]
      zonasEnvio[4].right=comunidades
     
      zonasEnvio[3].click=!rowData.Cambios
      zonasEnvio[3].left=[]
      zonasEnvio[3].right=comunidades
      
      zonasEnvio[2].click=!rowData.Siniestros
      zonasEnvio[2].left=[]
      zonasEnvio[2].right=comunidades
      
      zonasEnvio[5].click=!rowData.Seguros
      zonasEnvio[5].left=[]
      zonasEnvio[5].right=comunidades
      zonasEnvio[6].click=false
      zonasEnvio[6].left=[]
      zonasEnvio[6].right=comunidades
    }

    this.setState({rowData:rowData, cambioZona:true})
    this.setState({abrir_zonas_envio:true,  zonasEnvio:zonasEnvio})
  }

  cerrarGestionCambios (){
    this.setState({ abrir_cambios: false, rowData: [], editar:false })

  }
  rowAñadir(newData){

    return  new Promise((resolve, reject)  => {
      var ejecuta= true
          setTimeout(() => {
              
               if(this.comprobarExisteUsuario(newData.DNI).length> 0)
                {alert("El DNI ya existe, por favor utiliza otro"); ejecuta= !ejecuta}

               else if(!newData.DNI || newData.DNI ===''){alert("El campo DNI no puede ser vacío"); ejecuta= !ejecuta}
               else if(!this.comprobarDni(newData.DNI))
                    {alert("El DNI introducido no es válido"); ejecuta= !ejecuta}
                else if(!newData.displayName ||newData.displayName ==='')
                    {alert("El campo Nombre no puede ser vacío"); ejecuta= !ejecuta}
               else if(!newData.Apellido ||newData.Apellido ==='')
                    {alert("El campo Apellido no puede ser vacío"); ejecuta= !ejecuta}
               else if(!newData.email ||newData.email ===''){alert("El campo Correo no puede ser vacío"); ejecuta= !ejecuta}
               else if(!newData.Telefono ||newData.Telefono ===''){alert("El campo Teléfono no puede ser vacío");ejecuta= !ejecuta }
               if(ejecuta){
           
                if(!newData.Comunidad || newData.Comunidad===''){ newData.Comunidad=""}
                if(!newData.Direccion ||newData.Direccion ===''){newData.Direccion =''}
                if(!newData.Portal ||newData.Portal ===''){newData.Portal =''}
                if(!newData.AdminCon ||newData.AdminCon ===''){newData.AdminCon =false}
                if(!newData.Incidencias ||newData.Incidencias ===''){newData.Incidencias =false}
                if(!newData.Siniestros ||newData.Siniestros ===''){newData.Siniestros =false}
                if(!newData.Cambios ||newData.Cambios ===''){newData.Cambios =false}
                if(!newData.Seguros ||newData.Seguros ===''){newData.Seguros =false}
                if(!newData.Todo ||newData.Todo ===''){newData.Todo =false}
            
           
                var createUser= this.props.firebase.functions.httpsCallable('createUser');
                var nuevoUid=''
                new Promise((resolve,reject) => {  
                  var telefono1 =newData.Telefono
                  if (newData.Telefono.slice(0,3) !== "+34")
                  {telefono1="+34" + newData.Telefono}
                  if(!newData.emailVerified){newData.emailVerified=false}
                  if(!newData.Fichar){newData.Fichar=false}
                  
                  
                  createUser({email: newData.email, telefono:telefono1, 
                     nombre:newData.displayName, admin:  this.state.despacho.Codigo,
                     emailVerified:newData.emailVerified}).then((result)=>{
                       // Read result of the Cloud Function.
                       nuevoUid= result.data;
                       const despachoCode =this.props.firebase.despachoCode
                       var guardaDespacho = this.props.firebase.functions.httpsCallable('guardaDespacho');
                       guardaDespacho({'uid':nuevoUid,'despacho':despachoCode.Codigo,'BaseDatos':despachoCode.BaseDatos, 
                       'Almacen':despachoCode.Almacen, 'Code':despachoCode.Code}).then(()=>{
                         traeUsuariosDespacho(this.props.firebase).then(users=>{this.setState({usuariosDespacho:users})})
                       })
                       resolve(nuevoUid)                                 
                     }).catch(function(error) {
                       // Getting the Error details.
                      
                       reject(error)
                       // ...
                     });
                   }).then((data) => { 
                                     
                        if(!newData.Rol){newData.Rol=""}
                        const despacho=this.state.despacho     
                        newData.Clave=data       
                        new Promise((resolve,reject) => {  
                         let melon= this.props.firebase.users().push({
                          Nombre: newData.displayName,
                          Apellido: newData.Apellido,
                          Administrador : true,
                          DNI : newData.DNI,
                          Comunidad: (newData.Comunidad ? newData.Comunidad:(despacho.Com_interna ? despacho.Com_interna:'')),
                          Roles : newData.Roles,
                          Rol:newData.Rol,
                          Fichar:newData.Fichar,
                          Borrado:false,
                          Fecha_Modificacion:firebase2.database.ServerValue.TIMESTAMP,
                          Email: newData.email,
                          Clave: data,
                          Direccion:( newData.Direccion ? newData.Direccion:''),
                          Numero: ( newData.Numero ? newData.Numero:''),
                          Portal:( newData.Portal ? newData.Portal:''),
                          Piso:( newData.Piso ? newData.Piso:''),
                          Letra:( newData.Letra ? newData.Letra:''),
                          Porcentaje:( newData.Porcentaje ? newData.Porcentaje:0),

                          Telefono: newData.Telefono,
                      
                          Codigo9: "",
                          Portal:newData.Portal})  
                          resolve(melon)
                         }).then((data)=> {
                            newData.uid=data.key
                            this.props.firebase.flujoCorreo(data.key+'/').update({
                 
                              AdminCon : newData.AdminCon,
                              Cambios : newData.Cambios,
                              Incidencias : newData.Incidencias,
                              Seguros : newData.Seguros,
                              Siniestros : newData.Siniestros,
                              Todo : newData.Todo
                            }).then(()=> {
                              
                              var usus = this.state.usuarios
                              var com =[]
                              com[0] = newData
                              com = com.concat(usus)
                              this.setState({usuarios:com})
                             // this.buscarUsuarios()
                              alert("El registro ha sido creado correctamente");  
                              resolve(true)              
                              
                            })

                            })



        
                     }).catch(function(error) {
                                
                         if(error&&error.message==="01"){ alert("El formato del correo no es correcto: "+ newData.email)}
                         else if(error&&error.message==="02"){ alert("El formato del Teléfono no es correcto: "+ newData.Telefono)}
                         else if(error&&error.message==="03"){ alert("El correo ya existe en el sistema, por favor usa otro: "+ newData.email)}
                 
                         else {alert('Ha habido un error creando el usuario: '+ error.message)}  
                       

                         resolve(false)
         
                               });
                             
               }else (resolve(false))
    
          
        }, 1)
  })
  }
  rowActualiza(newData,oldData){
    return new Promise(resolve => {
      var ejecuta= true   

             if(this.comprobarExisteUsuario(newData.DNI).length> 0
            && newData.DNI !== oldData.DNI ){alert("El DNI ya existe, por favor utiliza otro"); ejecuta= !ejecuta}
             else  if(!newData.DNI || newData.DNI ===''){alert("El campo DNI no puede ser vacío"); ejecuta= !ejecuta}
             else if(!this.comprobarDni(newData.DNI))
                          {alert("El DNI introducido no es válido"); ejecuta= !ejecuta}
             else if(!newData.displayName ||newData.displayName ==='')
                          {alert("El campo Nombre no puede ser vacío"); ejecuta= !ejecuta}
             else if(!newData.Apellido ||newData.Apellido ==='')
                          {alert("El campo Apellido no puede ser vacío"); ejecuta= !ejecuta}
             else if(!newData.email ||newData.email ===''){alert("El campo Correo no puede ser vacío"); ejecuta= !ejecuta}
             else if(!newData.Telefono ||newData.Telefono ===''){alert("El campo Teléfono no puede ser vacío");ejecuta= !ejecuta }
             if(ejecuta){
                if(!newData.Comunidad || newData.Comunidad===''){ newData.Comunidad=""}
                if(!newData.Direccion ||newData.Direccion ===''){newData.Direccion =''}
                if(!newData.Portal ||newData.Portal ===''){newData.Portal =''}
                
                if(!newData.correoZonas){
                  newData.correoZonas= {
                    Todos:[],
                    Incidencias:[],
                    Siniestros:[],
                    Cambios:[],
                    AdminCon:[],
                    Seguros:[]
                  }
                }
         //       var correoZonas = newData.correoZonas
                
                if(!newData.AdminCon ||newData.AdminCon ===''){newData.AdminCon =false
                    newData.correoZonas.AdminCon=[]}
                if(!newData.Incidencias ||newData.Incidencias ===''){
                  newData.correoZonas.Incidencias=[]
                  newData.Incidencias =false}
                if(!newData.Siniestros ||newData.Siniestros ===''){
                  newData.correoZonas.Siniestros=[]
                  newData.Siniestros =false}
                if(!newData.Cambios ||newData.Cambios ===''){
                  newData.correoZonas.Cambios=[]
                  newData.Cambios =false}
                if(!newData.Seguros ||newData.Seguros ===''){
                  newData.correoZonas.Seguros=[]
                  newData.Seguros =false}
                if(!newData.Todo ||newData.Todo ===''){
                  newData.correoZonas.Todos=[]
                  newData.Todo =false}
    

                
                 var updateUser= this.props.firebase.functions.httpsCallable('updateUser');
                
                  new Promise((resolve,reject) => {  
                    var telefono1 =newData.Telefono
                    if (newData.Telefono.slice(0,3) !== "+34"){telefono1="+34" + newData.Telefono}
                       
                    updateUser({uid:newData.Clave, email: newData.email, telefono:telefono1, 
                      nombre:newData.displayName, emailVerified:newData.emailVerified}).then((result)=>{
                        // Read result of the Cloud Function.
                        traeUsuariosDespacho(this.props.firebase).then(users=>{this.setState({usuariosDespacho:users})})
                        resolve(ejecuta)                                 
            
                      }).catch(function(error) {
                        // Getting the Error details.
                 
                        reject(error)
                        // ...
                      });
                    }).then((datos) => { 
                      var data = this.state.roles;
                       data[data.indexOf(oldData)] = newData;
                       
                       if(!newData.Rol){newData.Rol=""}
                       
                       this.setState({gremios:data }); 

                       this.props.firebase.user(newData.uid+'/').update({
                       
                         Nombre: newData.displayName,
                         Apellido: newData.Apellido,
                         DNI : newData.DNI,
                         correoZonas:  newData.correoZonas,
                         Comunidad: newData.Comunidad,
                         Roles : newData.Roles,
                         Fichar: newData.Fichar,
                         Rol:newData.Rol,
                         Direccion: newData.Direccion,
                         Fecha_Modificacion:firebase2.database.ServerValue.TIMESTAMP,
                         Email: newData.email,
                         Telefono: newData.Telefono,
                         Direccion:( newData.Direccion ? newData.Direccion:''),
                          Numero: ( newData.Numero ? newData.Numero:''),
                          Portal:( newData.Portal ? newData.Portal:''),
                          Piso:( newData.Piso ? newData.Piso:''),
                          Letra:( newData.Letra ? newData.Letra:''),
                          Porcentaje:( newData.Porcentaje ? newData.Porcentaje:0),
                         Codigo9: "",
                         Portal:newData.Portal
                        }).then(() => { 
                          this.props.firebase.flujoCorreo(newData.uid+'/').update({
                       
                            AdminCon : newData.AdminCon,
                            Cambios : newData.Cambios,
                            Incidencias : newData.Incidencias,
                            Seguros : newData.Seguros,
                            Siniestros : newData.Siniestros,
                            Todo : newData.Todo
                          }).then(()=> {
                               var data = this.state.usuarios;
                             data[data.indexOf(oldData)] = newData;
                              this.setState({usuarios:data }); 

                              alert("El registro de "+newData.Nombre+" ha sido actualizado correctamente" );  
                           
                              
                              resolve(true)

                         })
                       })
                      }).catch(function(error) {
          
                        if(error.message==="01"){ alert("El formato del correo no es correcto: "+ newData.email)}
                        else if(error.message==="02"){ alert("El formato del Teléfono no es correcto: "+ newData.phoneNumber)}
                        else {alert(error.message)}  
                        ejecuta=false

                        resolve(ejecuta)
          
                      });
                              
             }else (resolve(false))
     
    })
  }
  render() {

   const { mostrarTabla, usuariosFichar,listaHorasTrabajadasFiltrada } = this.state;
   var listaHorasTrabajadas= this.state.listaHorasTrabajadas
   const vertical= this.state.vertical;
   const horizontal= this.state.horizontal;
   const contraseña = this.state.contraseña
   const despacho = this.state.despacho
   const zonasEnvio = this.state.zonasEnvio
   const tituloEnvio= this.state.tituloEnvio
   const mostrarHorario =this.state.mostrarHorario
   const usuario= this.state.usuario
   var centrosTrabajo= this.state.centrosTrabajo
  


   const botonZonas =(rowData)=>
       <Button
            //  disabled = { rowData.estado=== "CANCELADA" || rowData.estado=== "CERRADA"}
            variant="outlined" color="primary" disableElevation
            disabled = { !rowData.Todo&&!rowData.Incidencias&&!rowData.Siniestros&&
              !rowData.Cambios&&!rowData.AdminCon&&!rowData.Seguros}
            onClick={e => {
           
              this.gestionZonas(rowData)}}
          >
                FILTRO por Comunidad
    </Button>

   if(usuario){
    centrosTrabajo= centrosTrabajo.filter(centro =>
       (centro.Usuarios&&centro.Usuarios.length>0 ? centro.Usuarios.includes(usuario.uid):false))
   }
   return (     
      <Grid  >
        {!this.state.open&&<>
                   <Grid style={{ position: 'absolute', right: 20, top: 20 , maxWidth:"15%", maxHeight:"15%"}}>
          <Button variant="contained" color="primary" onClick={() => { this.setState({ mostrarAyuda: true }) }}>
            AYUDA (TUTORIALES)
          </Button>
        </Grid>    
       
        <Grid style={{ position: 'absolute', left: 20, top: 5, width:"50%", height:"50%"}}>
        <img src={this.state.despacho.Logo} alt='' style={{ width:"17%",height:'15%'}}/>
        </Grid>  
        <Cabecera/>
         
         <hr/>
    
        <BotonesTriger/>   </>}    
        <ThemeProvider theme={theme}>
     
      
            <Snackbar         anchorOrigin={{ vertical , horizontal }}
                          key={`${this.state.vertical},${this.state.horizontal}`}
                          open={this.state.snack}
                          autoHideDuration={5000}
                          onClose={this.handleClose}
                          message={this.state.error2}
                          action={
                            <div>
                              <Button color="primary" size="small" onClick={this.handleClose}>
                                Cerrar
                              </Button>
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={this.handleClose}
                              >
                                <Close/>
                              </IconButton>
                            </div>
                          }         />
           <Snackbar         anchorOrigin={{ vertical , horizontal }}
                          key={`${this.state.vertical},${this.state.horizontal}`}
                          open={this.state.snack2}
                          autoHideDuration={5000}
                          onClose={this.handleClose}
                          message={this.state.gremioBorrar}
                          action={
                          <div>
                                 <Button onClick={this.anularGremio}>  
                                      <Fab  size="small" color="primary" aria-label="add"  variant="extended">
                                          <DoneOutline/>
                                        CONFIRMAR
                                       </Fab>
                                  </Button>
                                  <Button onClick={this.handleClose}>  
                                      <Fab  size="small" color="secondary" aria-label="add"  variant="extended">
                                      <Close/>
                                        CANCELAR
                                       </Fab>
                                  </Button>
                            </div>
                          }                />           
                  <Grid item xs style={{marginLeft:10, marginRight:10}}>
                  {!this.state.open&&<>
              <Paper style={{backgroundColor:'mintcream'}}>
                <Grid style={{margin:10}}>
                 <form >
                       <Grid     container      direction="row"       justify='space-around'     alignItems="center"      >
                          <TextField  InputLabelProps={{ shrink: true }}
                          //disabled ={true}
                          variant="filled"
                          margin="dense"
                          id="codigo"
                          label="Código Administrador"
                          name="codigo"
                          size="small"
                          autoComplete="codigo"
                          
                          value={despacho.Codigo}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Código Administrador"
                        />
                          <TextField  InputLabelProps={{ shrink: true }}
                          variant="filled"
                          margin="normal"
                          id="registro"
                          label="Fecha de Registro"
                          name="registro"
                          autoComplete="registro"
          
                          value={despacho.Fecha_registro}
                          size="small"
                          type="text"
                          placeholder="Fecha de Registro"
                        />
                      </Grid>
                        <TextField  InputLabelProps={{ shrink: true }}
                         // disabled = {true}
                          variant="filled"
                          fullWidth
                          margin="normal"     
                          name="nombre"
                          label="Nombre Administrador o Razón Social"
                          id="nombre"
                          autoComplete="nombre"
                          value={despacho.Nombre}
                          size="small"
                          type="text"
                          placeholder="Nombre Administrador o Razón Social"
                         />
                         <Grid     container      direction="row"       justify='space-between'     alignItems="center"      >
                         <TextField  InputLabelProps={{ shrink: true }}
                         // disabled ={true}
                          variant="filled"
                          margin="normal"
                          name="cif"
                          label="CIF o NIF"
                          id="cif"
                          autoComplete="cif"
                          value={despacho.CIFNIF}
                          type="numeric"
                          placeholder="CIF o NIF"
                          size="small"
                         />
                        <TextField  InputLabelProps={{ shrink: true }}
                         // disabled ={true}
                          variant="filled"
                          margin="normal"
                          name="telefono1"
                          label="Telefono Principal"
                          id="telefono1"
                          autoComplete="telefono1"
                          value={despacho.Telefono1}
                          type="numeric"
                          placeholder="Teléfono Principal"
                          size="small"
                         />
                          <TextField  InputLabelProps={{ shrink: true }}
                          //disabled ={true}
                          variant="filled"
                          margin="normal"
                          name="telefono2"
                          label="Telefono Secundario"
                          id="telefono2"
                          autoComplete="telefono2"
                          value={despacho.Telefono2}
                          type="numeric"
                          placeholder="Teléfono Secundario"
                          size="small"
                         />
                     
                      </Grid>
                      <Grid     container      direction="row"       justify='space-between'     alignItems='flex-start'      >
       
                      <div style={{width: '40%'}}>
                      <TextField  InputLabelProps={{ shrink: true }}
                         // disabled ={true}
                          variant="filled"
                          margin="normal"
                          fullWidth
                          id="direccion"
                          label="Dirección Completa  Calle/Nº/Piso"
                          name="direccion"
                          autoComplete="direccion"
                          value={despacho.Direccion}
                          type="text"
                          size="small"
                          color= '#00a049'
                          placeholder="Dirección Completa  Calle/Nº/Piso"
                        />
                        </div>
                        <div style={{width: '40%'}}>

                              <TextField  InputLabelProps={{ shrink: true }}
                          //disabled ={false}
                          fullWidth
                          variant="filled"
                          margin="normal"
                          name="email"
                          label="Correo"
                          id="email"
                          autoComplete="email"
                          value={despacho.Correo}
                          type="email"
                          placeholder="Correo"
                          size="small"
                         />
                         </div>
                        </Grid>
                 
                      <Grid     container      direction="row"       justify='space-between'     alignItems='flex-start'      >
                          <TextField  InputLabelProps={{ shrink: true }}
                        //  disabled ={true}
                          variant="filled"
                          margin="normal"
                          size="small"
                          id="cp"
                          label="Código Postal"
                          name="cp"
                          autoComplete="cp"
                          value={despacho.Cp}
                          type="text"
                          placeholder="Código Postal"
                        />
                          <TextField  InputLabelProps={{ shrink: true }}
                       
                          size="small"
                          variant="filled"
                          margin="normal"
                          id="poblacion"
                          label="Población"
                          name="poblacion"
                          autoComplete="poblacion"
                          value={despacho.Poblacion}
                          type="text"
                          placeholder="Población"
                        />
                      <div style={{width: '40%'}}>
                      <TextField  InputLabelProps={{ shrink: true }}
                          //disabled ={true}
                          size="small"
                          variant="filled"
                          margin="normal"
                          id="contacto"
                          label="Persona de Contacto"
                          name="contacto"
                          autoComplete="contacto"
                          value={despacho.Persona}                          
                          type="text"
                          placeholder="Persona de Contacto"
                          color="#00a049"
                          fullWidth
                        />
                       </div>
                           </Grid>  
              
                      <TextField  InputLabelProps={{ shrink: true }}
                          
                          variant="filled"
                          margin="normal"
                          fullWidth
                          id="iban"
                          label="Código IBAN"
                          name="iban"
                          autoComplete="iban"
                          value={despacho.Iban}
                          type="text"
                          size="small"
                          color="primary"
                          placeholder="Código IBAN"
                        />
                        </form>
                </Grid>   
              </Paper>
               
              <Grid  item xs style={{marginTop:10}}>
                            
                          <Grid item>
                              <FormControl styles= {{   minWidth: 120}} >
                                   <ButtonGroup size="large"  variant="contained" color="primary" aria-label="large outlined primary button group">
                                            <Button onClick={this.buscarUsuarios} 
                                             color="primary"
                                              startIcon={<Search />}>
                                              
                                              LISTADO DE USUARIO ADMINISTRADORES
                                            </Button>
                                            <Button  
                                             color="primary"
                                             onClick={(event) => {
                                              this.setState({usuariosFichar:[],usuario:'',listaHorasTrabajadas:[],listaHorasTrabajadasFiltrada:[]})
                                          
                                              var centrosList =[]
                                              this.props.firebase.users().once('value', snapshot => {
                                              const usersObject = snapshot.val();
                                                 if (usersObject){
                                                    var usuariosList = Object.keys(usersObject).map(key => ({
                                                      ...usersObject[key],
                                                      uid: key,
                                                    }))
                                                    usuariosList = usuariosList.filter(usu=>usu.Fichar===true&&usu.Borrado===false
                                                      &&!usu.SuperUser)
                                                    usuariosList.sort(function (a, b) {
                                                      if (a.Nombre> b.Nombre) {
                                                        return 1;
                                                      }
                                                      if (a.Nombre < b.Nombre) {
                                                        return -1;
                                                    
                                                      }
                                                     // a must be equal to b
                                                      return 0; 
                                                    });
                                                    this.setState({usuariosFichar:usuariosList})
                                                  }
                                                }).then(()=>{
                                                  this.props.firebase.puestosdeTrabajo().once('value' , snapshot => {
   
                                                    const usersObject= snapshot.val()
                                                    if (usersObject){
                                                       centrosList = Object.keys(usersObject).map(key => ({
                                                        ...usersObject[key],
                                                        uid: key,
                                                      }))
                                                      this.setState({centrosTrabajo:centrosList})
                                                    }else{this.setState({centrosTrabajo:[]})

                                                    }
                                                    
                                                }).then(()=>{
                                                  const registro={ "despacho":this.state.despacho, "centrosTrabajo":centrosList,
                                                  "offset":this.state.offset}

                                                  const Mapa1 =  <Mapa2 {...registro} />
                                                 // let Mapa66 = <Mapa3 {...registro} />
                                                  this.setState({Mapa1:Mapa1,mostrarHorario:true, mostrarTabla:false,  centrosTrabajoMostrar:centrosList })
                                                })
                                              })
                                             }} >REGISTRO HORARIO</Button>
                                         </ButtonGroup>
                                     
                                          <Backdrop  color = '#00a049' open={this.state.open}>
                                            <CircularProgress color="#00a049" />
                                          </Backdrop>      
                                          
                                    </FormControl>
                          </Grid>
             </Grid> </>}      
              {(mostrarHorario&&  
                <Paper elevation={4} style={{width:'100%', overflowX: 'auto',backgroundColor:'mintcream'}}>
               
                 
                  <Grid  container direction="row" justify="space-around"   alignItems="center"  >
                      
                  
                          <FormControl styles= {{   minWidth: 120}} >
                                        <InputLabel id="demo-simple-select-label">Usuario</InputLabel>
                                        <Select
                                          labelId="usuario"
                                          id="usuario"
                                          name ="usuario"
                                          value={this.state.usuario}
                                          onChange={this.handleChange}
                                        >

                                      <MenuItem  key={''} value={''}></MenuItem>
                                        {usuariosFichar && usuariosFichar.map(usu => (
                                          <MenuItem key={usu} value={usu}>{usu.Nombre+' '+usu.Apellido}
                                        
                                          </MenuItem>
                                        ))}
                          
                                       </Select>
                                        <FormHelperText>Selecciona un usuario</FormHelperText>
                                    </FormControl>
                     
                          <TextField  InputLabelProps={{ shrink: true }}
                            
                                id="Fecha_registro"
                                name ="Fecha_registro"
                                label="Fecha de Registro"
                                type="date"
                                disabled= {!this.state.usuario|| this.state.ususario===''} 
                                defaultValue={new Date()}
                                value={this.state.Fecha_registro}
                                onChange={this.handleChange}
                                placeholder="Fecha de Registro"
                          />
                         
                        
                         
                  </Grid>
                  
                  <div  style={{  width:'100%',alignContent:'center', marginLeft:'15%'}}>                    
                   {this.state.Mapa1}
                  </div>     
                 {this.state.abrir_horas&&
                  <Paper style={{width:'100%', overflowX:'auto', margin:10}}>      

            
                    <MaterialTable

                     
                      options={{
                        doubleHorizontalScroll:true,
                        exportButton: {
                          csv: true,
                          pdf: false
                        },
                        addRowPosition:'first',
                        filtering: true,
                        exportDelimiter: ";",
                        exportAllData:true,
                        grouping: true,
                        pageSize: 25,
                        maxBodyHeight: "70vh",
                        pageSizeOptions:	[25, 50, 100],
                        headerStyle: {
                          backgroundColor: '#00a049',
                          color: 'white'},
                          paddingTop:5,
                          paddingBottom:5
                                
                      }}
                      
                      icons={tableIcons}
                      localization={{
                        toolbar: {
                          exportCSVName: "Exportar a Excel (csv)",
                          exportPDFName: "Export as pdf!!"
                        },
                          body: { editRow:
                         { deleteText: '¿Quieres borrar a este registro de hora?' } }
                      }}
                            
                     title={ <><h2  style={{ color: '#00a049' }}>PLANTILLA CONTROL DE HORAS</h2>
                             <h4  style={{ color: '#00a049' }}>{this.state.nombreyapellidos}</h4>
                             <Grid container spacing={1}>
                              <Grid item sm={12}>
                                <FormControl fullWidth margin='normal'>
                                <InputLabel shrink htmlFor="select-multiple-native">
                              Listado para el año
                                </InputLabel>

                                    <Select
                                      value={this.state.añoActual}
                                      // disabled={bloquear}
                                      onChange={e => {
                                    
                                          this.setState({añoActual:e.target.value})
                                          const lista=this.state.listaHorasTrabajadas
                                          const nuevaLista = lista.filter(zon=>zon.Año===e.target.value)

                                          this.setState({listaHorasTrabajadasFiltrada: nuevaLista})
                                      
                                        //                                          newRowData.Seguro =melon[0].uid
                                      
                                      }}    >
                                      { this.state.años &&  this.state.años.map((año) => (
                                        <MenuItem value={año.Nombre} >
                                          {año.Nombre}
                                        </MenuItem>
                                      ))


                                      }
                                    </Select>
                                </FormControl>  
                              </Grid>
                            </Grid> </>}
 
                     columns={[
                          {title: 'Fecha', field: 'Fecha',
                                editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
                              
                                  if (!rowData.Fecha){rowData.Fecha= moment(new Date()).format('YYYY-MM-DD')}
                                  else{
                                    const fecha= rowData.Fecha.split('/')
                                    if(fecha.length>1){
                                      rowData.Fecha=fecha[2]+'-'+fecha[1]+'-'+fecha[0]
                                    }
                                   
                                  }
                                  return(
                                    <input   
                                    value={rowData.Fecha} type="date"
                                                    
                                    onChange={e => {
                                      var newRowData = { ...rowData, Fecha: e.target.value };
                                      newRowData.Fecha = e.target.value 
                                      rowData.Fecha = e.target.value 
                                      newRowData.Semana =  moment(newRowData.Fecha, "YYYY-MM-DD").week().toString()
                                      onRowDataChange(newRowData);
                                    }}
                                    />
                                     
                                    )
                                  }
                              
                           },
                           {title: 'Semana', field: 'Semana',editable:"never"},
                          {title: 'Centro', field: 'Centro.Nombre',
                            render: rowData =>{
                                  var centro= rowData.Centro
                                  if(!centro||centro===''){
                                      centro='NO DISPONE, el centro ha sido borrado'
                                 //   centro.Nombre= rowData
                                  }else{
                                    centro=centro.Nombre
                                  }
                                   return(
                                   
                                   <Select
                                      
                                      value={centro}
                                      renderValue={(selected) => (
                                        <div style={{  display: 'flex', flexWrap: 'wrap'}}>
                                        
                                            <Chip variant="outlined" color='primary' key={selected} label={selected} style={{margin:6}}/>
                                          
                                        </div>
                                      )}
                                      />
                                    
                                  
                                   )
                                  
                                },
                            editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
                                    if (!rowData.Centro|| rowData.Centro===''){ 
                                      rowData.Centro={'Nombre':''}
                                    }
                                    return(
                                      
                                        <FormControl>
                                           <Select
                                              
                                                  value={(rowData.Centro.Nombre ? rowData.Centro.Nombre:'')}
                                                  onChange={e => {
                                                    var newRowData = { ...rowData, Centro: e.target.value };
                                                    newRowData.Centro = e.target.value 
                                                    rowData.Centro = e.target.value 
                                                                        
                                                    onRowDataChange(newRowData);
                                                  }
                                                }
                                                renderValue={(selected) => (
                                                  <div style={{  display: 'flex', flexWrap: 'wrap',}}>
                                                   
                                                      <Chip variant="outlined" color='primary' key={selected} label={selected} style={{margin:6}}/>
                                                    
                                                  </div>
                                                )}
                                                  
                                                >
                                                  {centrosTrabajo.map((centro) => (
                                                    <MenuItem key={centro.uid} value={centro} >
                                                      {centro.Nombre}
                                                    </MenuItem>
                                                  ))}
                                   
                                                    
                                                </Select>
                                              </FormControl>
                                  )}
                                        
                              },  
                              
                          {title: 'Entrada', field: 'Entrada',
                                editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
                                  if (!rowData.Entrada){rowData.Entrada='00:00:00'}
                                  return(
                                    <input   
                                    value={rowData.Entrada} type="time"
                                    //name="apertura"
                                    list="limittimeslist" 
                                    step="1800"
                                    defaultValue='07:00:00'
                                  
                                    
                                    onChange={e => {
                                      var newRowData = { ...rowData, Entrada: e.target.value };
                                      newRowData.Entrada = e.target.value 
                                      rowData.Entrada = e.target.value 


                                      var duracion=0
                                      if (rowData.Salida!=="SIN REGISTRO"&&rowData.Salida!=="00:00:00"){
                                        var breakfast = moment(rowData.Entrada,'HH:mm');
                                        var lunch = moment(rowData.Salida,'HH:mm');
                                        duracion = lunch.diff(breakfast, 'hours', true)

                                        newRowData.Horas = (duracion<=0 ? "HORA SALIDA MENOR O IGUAL QUE HORA ENTRADA":
                                        duracion.toString().slice(0,4).replace('.',','))
                         /*             
                                       const listaHorasTrabajadas = this.state.listaHorasTrabajadas
                                       const fecha= rowData.Fecha.split('-')                                        
                                       const fechaComparar=fecha[2]+'/'+fecha[1]+'/'+fecha[0]
                                       const entraHora = Number( moment(rowData.Entrada,'HH:mm').format('HH'));
                                       const entradaMin =Number( moment(rowData.Entrada,'HH:mm').format('mm'));
                                       const siesta = listaHorasTrabajadas.filter(slot=>{
                                         if(slot.Fecha===fechaComparar){
                                            if(entraHora>=Number( moment(slot.Entrada,'HH:mm').format('HH'))&&
                                              entraHora<=Number( moment(slot.Salida,'HH:mm').format('HH'))){
                                          
                                                if(entraHora===Number( moment(slot.Salida,'HH:mm').format('HH'))){
                                                  if(entradaMin>=Number( moment(slot.Salida,'HH:mm').format('mm'))){
                                                    return false}

                                                }
                                                newRowData.Horas = "LA HORA DE ENTRADA ESTÁ INCLUIDA EN OTRO INTERVALO YA REGISTRADO PARA ESTE DÍA"
                         



                                              return true
                                            }
                                          
                                          
                                          
                                          }
                                          return false
                                        })  
                                        */
                                      }                       
                                                                                                
                                      onRowDataChange(newRowData);
                                    }}
                                    />
                                  //  disabled ={this.state.modificar || horario.noModificar}
                                    
                                    )
                                  }
                              },
                          {title: 'Salida', field: 'Salida',
                                render: rowData =>{
                                            
                                  
                                    return(
                                  <div style={{color:(rowData.Salida==='SIN REGISTRO' ? 'red':'black'), flexWrap: 'wrap',}}>
                                  
                                      {rowData.Salida}
                                  </div>)
             
                                },
                                editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
                                  if (!rowData.Salida||rowData.Salida==="SIN REGISTRO"){rowData.Salida='00:00:00'}
                                  return(
                                    <input   
                                    value={rowData.Salida} type="time"
                                    //name="apertura"
                                    list="limittimeslist" 
                                    step="1800"
                                    defaultValue='07:00:00'
                                  
                                    
                                    onChange={e => {
                                      var newRowData = { ...rowData, Salida: e.target.value };
                                      newRowData.Salida = e.target.value 
                                      rowData.Salida = e.target.value 
                                      var duracion=0
                             
                                      if (rowData.Entrada!==""){
                                        var breakfast = moment(rowData.Entrada,'HH:mm');
                                        var lunch = moment(rowData.Salida,'HH:mm');
                                        duracion = lunch.diff(breakfast, 'hours', true)

                                        newRowData.Horas = (duracion<=0 ? "HORA SALIDA MENOR O IGUAL QUE HORA ENTRADA":
                                        duracion.toString().slice(0,4).replace('.',','))
                                      
                     /*                  const listaHorasTrabajadas = this.state.listaHorasTrabajadas
                                       const fecha= rowData.Fecha.split('-')                                        
                                       const fechaComparar=fecha[2]+'/'+fecha[1]+'/'+fecha[0]
                                       const salidaHora = Number( moment(rowData.Salida,'HH:mm').format('HH'));
                                       const salidaMin =Number( moment(rowData.Salida,'HH:mm').format('mm'));
                                       const siesta = listaHorasTrabajadas.filter(slot=>{
                                         if(slot.Fecha===fechaComparar){
                                            if(salidaHora>=Number( moment(slot.Entrada,'HH:mm').format('HH'))&&
                                              salidaHora<Number( moment(slot.Salida,'HH:mm').format('HH'))){

                                                if(salidaHora===Number( moment(slot.Entrada,'HH:mm').format('HH'))){
                                                  if(salidaMin<=Number( moment(slot.Entrada,'HH:mm').format('mm'))){
                                                    return false}

                                                }

                                                newRowData.Horas = "LA HORA DE ENTRADA ESTÁ INCLUIDA EN OTRO INTERVALO YA REGISTRADO PARA ESTE DÍA"
                         
                                              return true
                                            }
                                          
                                          
                                          
                                          }
                                          return false
                                        })  
                                        */
                                      }  
                                 
                                       
                                                          
                                      onRowDataChange(newRowData);
                                    }}
                                    />
                                  //  disabled ={this.state.modificar || horario.noModificar}
                                    
                                    )
                                  }
                              },
                          {title: 'Horas', field: 'Horas',editable:"never"},

                      ]}
                      onOrderChange={(columna, direccion)=>{
                        //orderDirection: "asc" | "desc") => void;
              
                        
                      }}
                     data={listaHorasTrabajadasFiltrada}
                     editable={{
                        //     isEditable: (rowData) => !this.state.modificar, 
                        //   isDeletable: (rowData) => !this.state.modificar,
                        onRowUpdateCancelled: () =>{
                               //    this.buscarUsuarios()                             
                                   },
                                 onRowAdd: newData =>
                                       new Promise((resolve,reject) => {
                                         var ejecuta= true
                                     
                                             setTimeout(() => {
                                                 
                                              if(!newData.Fecha || newData.Fecha ===''){
                                                alert("El campo Fecha no puede ser vacío"); ejecuta= !ejecuta}
                                                else if(!newData.Centro ||newData.Centro.Nombre ==='')
                                                {alert("El campo Centro no puede ser vacío"); ejecuta= !ejecuta}
                                                else if(!newData.Entrada ||newData.Entrada ==='')
                                                {alert("El campo Entrada no puede ser vacío"); ejecuta= !ejecuta}
                                                else if(!newData.Salida ||newData.Salida ==='')
                                                {alert("El campo Salida no puede ser vacío"); ejecuta= !ejecuta}

                                                else if(!newData.Horas ||newData.Horas === "HORA SALIDA MENOR O IGUAL QUE HORA ENTRADA")
                                                {alert("Revisa el intervalo de horas, la hora de salida no puede sera anterior a la hora de entrada"); ejecuta= !ejecuta}


                                               
                 
                                            if(ejecuta){
                                              const usuario=this.state.usuario
                                              const fecha= newData.Fecha.split('-')
                                              const fechafinal= fecha[0]+fecha[1]+fecha[2]
                                              newData.Fecha=fecha[2]+'/'+fecha[1]+'/'+fecha[0]

                                              var data = this.state.listaHorasTrabajadas;
                                               var  data2=[{ 
                                              "Fecha":newData.Fecha,
                                              'Año':fecha[0],
                                              "Centro":(newData.Centro ? newData.Centro:''),
                                              "Entrada" : newData.Entrada,
                                              "Salida":  newData.Salida,
                                              "Horas": newData.Horas,  
                                              "Semana" :  moment(newData.Fecha, "DD/MM/YYYY").week().toString(),
                                              "Usuario_Mod":usuario.Nombre +' '+ usuario.Apellido,                                                                                     
                                              "Fecha_Modificacion": moment(new Date()).format('DD-MM-YYYY HH:mm'),
                                              "uid":fechafinal
                                              }]
                                              data=data.concat(data2)
                                   
                                              var data3=[]
                                              data.forEach((registro,indice)=>{
                                                registro.indice=indice
                                                data3=data3.concat(registro)
                                              })

                                              const listanueva= data3.filter(reg=>reg.Año===this.state.añoActual)                                        
                                              this.setState({listaHorasTrabajadas:data3,listaHorasTrabajadasFiltrada:listanueva})
                                              //comprobamos que si el año es  nuevo y no está en la lista de años para filtrar
                                              var años=this.state.años.filter(reg=>reg.Nombre===fecha[0])
                                              if(!años || años.length===0){
                                                años=this.state.años.concat({'Nombre':fecha[0]})
                                                this.setState({años:años})

                                              }

                                              data3=data3.filter(registro=> registro.uid===fechafinal)
                                              var dataFinal=[]
                                              data3.map(registro=>
                                                dataFinal=dataFinal.concat({
                                                  "Centro":(registro.Centro.uid ? registro.Centro.uid:''),
                                                  "Entrada" : registro.Entrada,
                                                  "Salida":  registro.Salida,
                                                  "Horas": registro.Horas,  
                                                  "Usuario_Mod":registro.Usuario_Mod,                                                                                           
                                                  "Fecha_Modificacion": registro.Fecha_Modificacion,  
                                                })
                                                
    
                                              )
                                              this.props.firebase.fichar(usuario.uid+'/').update({[fechafinal]:dataFinal                                                     
                                              }).then(()=>{
                                                   
                                                        this.setState({snack:true, 
                                                        error2:"El registro de "+usuario.Nombre +' '+ usuario.Apellido+" ha sido creado correctamente" });  
                                                     
                                                        resolve(true)
    
                                              }).catch(function(error) {
                                      
                                                    alert(error)                                               
                                                    ejecuta=false
                                                    reject(ejecuta)
                                      
                                                  });
                                         
                                              
                                        
                                                   
                                            }else (reject(ejecuta))
                                              }
                                        
                  
                                             
                                           , 1)
                                     })
                                     ,
                                 onRowUpdate: (newData, oldData) =>
                               
                                  
                                 new Promise((resolve, reject) => {
                                   var ejecuta= true   
                                   setTimeout(() => {
                                     
                                         
                                         if (oldData) {
                                           if(!newData.Fecha || newData.Fecha ===''){
                                             alert("El campo Fecha no puede ser vacío"); ejecuta= !ejecuta}
                                             else if(!newData.Centro ||newData.Centro.Nombre ==='')
                                             {alert("El campo Centro no puede ser vacío"); ejecuta= !ejecuta}
                                             else if(!newData.Entrada ||newData.Entrada ==='')
                                             {alert("El campo Entrada no puede ser vacío"); ejecuta= !ejecuta}
                                             else if(!newData.Salida ||newData.Salida ==='')
                                             {alert("El campo Salida no puede ser vacío"); ejecuta= !ejecuta}
                                             else if(!newData.Horas ||newData.Horas === "HORA SALIDA MENOR O IGUAL QUE HORA ENTRADA")
                                             {alert("Revisa el intervalo de horas, la hora de salida no puede sera anterior a la hora de entrada"); ejecuta= !ejecuta}


                                         if(ejecuta){
                                          const usuario=this.state.usuario
                        
                                         newData.Usuario_Mod=usuario.Nombre +' '+ usuario.Apellido                                                                                        
                                          newData.Fecha_Modificacion= moment(new Date()).format('DD-MM-YYYY HH:mm')
                                          var data = this.state.listaHorasTrabajadas
                                          const ind= data.findIndex(dato=>dato.indice===newData.indice)
                                          data[ind] = newData
                                         
                                          var data22 = this.state.listaHorasTrabajadasFiltrada
                                          const ind2= data22.findIndex(dato=>dato.indice===newData.indice)
                                          data22[ind2] = newData

                                          this.setState({listaHorasTrabajadas:data,listaHorasTrabajadasFiltrada:data22 }); 
                                          const fecha= newData.Fecha.split('-')
                                          newData.Fecha=fecha[2]+'/'+fecha[1]+'/'+fecha[0]
                                          if(newData.Fecha!==oldData.Fecha){
                                            //borramos el registro acual y creamos el nuevo
                                         
                                            const fechafinal= fecha[0]+fecha[1]+fecha[2]
                                            var dataBorrar=data.filter(registro=>
                                               registro.uid===newData.uid&&registro.indice!==newData.indice
                                                )
                                                var  dataBorrarfinal=[]
                                                dataBorrar.map(registro=>
                                                  dataBorrarfinal=dataBorrarfinal.concat({
                                                    "Centro":(registro.Centro.uid ? registro.Centro.uid:''),
                                                    "Entrada" : registro.Entrada,
                                                    "Salida":  registro.Salida,
                                                    "Horas": registro.Horas,  
                                                    "Usuario_Mod":registro.Usuario_Mod,                                                                                           
                                                    "Fecha_Modificacion": registro.Fecha_Modificacion,  
                                                  })
                                                )
                                                this.props.firebase.fichar(usuario.uid+'/').update({[oldData.uid]:
                                                  (dataBorrarfinal&&dataBorrarfinal.length>0 ? dataBorrarfinal:"")})                                                   
   
                                            newData.uid= fechafinal


                                          }
                                 
                                          data=data.filter(registro=> registro.uid===newData.uid)
                                          var  data2=[]
                                          data.map(registro=>
                                            data2=data2.concat({
                                              "Centro":(registro.Centro.uid ? registro.Centro.uid:''),
                                              "Entrada" : registro.Entrada,
                                              "Salida":  registro.Salida,
                                              "Horas": registro.Horas,  
                                              "Usuario_Mod":registro.Usuario_Mod,                                                                                           
                                              "Fecha_Modificacion": registro.Fecha_Modificacion,  
                                            })
                                          )
                                            
                                          const fechafinal= newData.uid
                                      
                                          this.props.firebase.fichar(usuario.uid+'/').update({[fechafinal]:data2                                                     
                                             }).then(() => { 
                                                    
                                                         this.setState({snack:true, 
                                                           error2:"El registro de "+usuario.Nombre +' '+ usuario.Apellido+" ha sido actualizado correctamente" });  
                                                         
                                                           resolve(true)
     
                                             }).catch(function(error) {
                                       
                                                     alert(error)                                               
                                                     ejecuta=false
                                                     reject(ejecuta)
                                       
                                                   });
                                                           
                                         }else (reject(ejecuta))
                                           }
                                     
                                     
                                     }, 1);
                                     }),
                                 onRowDelete: oldData =>                            
                                 new Promise((resolve, reject) => {
                                  const usuario=this.state.usuario
                                
                                  var data = this.state.listaHorasTrabajadas
                                  data.splice(oldData.indice,1)
                                  var data3=[]
                                  data.forEach((registro,indice)=>{
                                      registro.indice=indice
                                      data3=data3.concat(registro)
                                  })


                                  this.setState({listaHorasTrabajadas:data3 }); 
                                             
                                  data3=data3.filter(registro=> registro.uid===oldData.uid)
                                  var  data2=[]
                                  data3.map(registro=>
                                    data2=data2.concat({
                                      "Centro":(registro.Centro.uid ? registro.Centro.uid:''),
                                      "Entrada" : registro.Entrada,
                                      "Salida":  registro.Salida,
                                      "Horas": registro.Horas,  
                                      "Usuario_Mod":registro.Usuario_Mod,                                                                                           
                                      "Fecha_Modificacion": registro.Fecha_Modificacion,  
                                    })
                                  )
                                
                                  const fechafinal= oldData.uid
                                  this.props.firebase.fichar(usuario.uid+'/').update({[fechafinal]:
                                        (data2&&data2.length>0 ? data2:"")})                                                 
                                                     
                                  .then(()=>{  resolve(true)})
                                  .catch(()=>{  reject(false)})
                                 })              
                                  
                                   
     
                               }}
 
                          />
                  </Paper>}
                
              </Paper>
                 )}  
            </Grid>
             {mostrarTabla&&
              <Paper style={{width:'100%', overflowX:'auto', margin:10}}>        
                               <MaterialTable 
                               options={{
                                doubleHorizontalScroll:true,
                                exportButton: {
                                  csv: true,
                                  pdf: false
                                },
                            
                                addRowPosition:'first',
                                filtering: true,
                                exportDelimiter: ";",
                                exportAllData:true,
                                pageSize: 10,
                                maxBodyHeight: "70vh",
                                pageSizeOptions:	[5, 10, 20],
                                headerStyle: {
                                  backgroundColor: '#00a049',
                                  color: 'white'},
                                  paddingTop:5,
                                  paddingBottom:5
                                        
                              }}
                              
                              icons={tableIcons}
                              localization={{ body: { editRow:
                                 { deleteText: '¿Quieres borrar a este usuario?' } } }}
                              title={ <h2  style={{ color: '#00a049' }}>USUARIOS - ADMINISTRADORES</h2>}

                              columns={[
                                { title: 'Avatar', field: 'Foto', editable: 'never',
                                render: rowData => <img src={rowData.Foto} alt='' style={{width: 40, borderRadius: '50%'}}/> },
                                {title: 'Nombre', field: 'displayName'},
                              //   { title: 'Identificador', field: 'Uid', editable: 'never' },
                              {title: 'Apellido', field: 'Apellido'},
                              {title: 'DNI', field: 'DNI'},
                              {title: 'Correo', field: 'email'},
                              {title: 'Telefono', field: 'Telefono'},

                              {title: 'Dirección', field: 'Direccion', hidden:(despacho.Tipo!=='Comunidades'),
                              editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => { 
                                const comu = this.state.comus.filter(com=>com.Id==rowData.Comunidad)
                             
                           
                                return(
                                <FormControl>
                                  <Select
                                    value={rowData.Direccion}
                                    onChange={e => {
                                      var newRowData = { ...rowData, tipo: e.target.value };
                                      rowData.Direccion = e.target.value 
                                
                                      newRowData.Direccion=e.target.value

                                   
                                     onRowDataChange(newRowData);
                            
                                      }}    >
                                       {comu&&comu.length>0&&comu[0].Direcciones.map((comu) => (
                                          <MenuItem value= {comu} >
                                          {comu}
                                        </MenuItem>
                                       ))}
                                
                                        
                                                    </Select>
                                  </FormControl>
                                 ) }},
                              

                              {title: 'Numero', field: 'Numero', hidden:(despacho.Tipo!=='Comunidades')},
                              {title: 'Portal', field: 'Portal',hidden:(despacho.Tipo!=='Comunidades')},
                              {title: 'Piso', field: 'Piso',hidden:(despacho.Tipo!=='Comunidades')},
                              {title: 'Letra', field: 'Letra',hidden:(despacho.Tipo!=='Comunidades')},
                              {title: 'Porcentaje', field: 'Porcentaje',hidden:(despacho.Tipo!=='Comunidades')},

                              
                                { title: "Todos los Correo", field: "Todo",initialEditValue:true,
                                type: "boolean",

                                  render: rowData =>checkBoxLectura(rowData.Todo)
                               ,
                                  editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (

                                    checkBoxModificar ('Todo',false,rowData, onRowDataChange,this)
                                    
                                 )
                              },
                              { title: "Recibir Incis", field: "Incidencias",initialEditValue:true,
            
                              type: "boolean",

                                render: rowData =>checkBoxLectura(rowData.Incidencias)
                               ,
                                editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                                  checkBoxModificar ('Incidencias',rowData.Todo,rowData, onRowDataChange,this)
                                  )
                              },
                              { title: "Recibir Siniestros", field: "Siniestros" ,initialEditValue:true,
                              type: "boolean",

                                render: rowData =>checkBoxLectura(rowData.Siniestros)
                              ,
                                editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                                  checkBoxModificar ('Siniestros',rowData.Todo,rowData, onRowDataChange,this)
                   
                                
                                  )
                              },
                              { title: "Recibir modificacion cliente", field: "Cambios",initialEditValue:true,
                              type: "boolean",

                                render: rowData =>checkBoxLectura(rowData.Cambios)
                            ,
                                editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                                  checkBoxModificar ('Cambios',rowData.Todo,rowData, onRowDataChange,this)
                             
                               
                                  )
                              },
                              { title: "Recibir consulta Admin", field: "AdminCon" ,initialEditValue:true,
                              type: "boolean",

                                render: rowData =>checkBoxLectura(rowData.AdminCon)
                               ,
                                editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                                  checkBoxModificar ('AdminCon',rowData.Todo,rowData, onRowDataChange,this)
                                )
                              },  
                              { title: "Recibir consulta Seguros", field: "Seguros",initialEditValue:true,
                              type: "boolean",

                                render: rowData =>checkBoxLectura(rowData.Seguros)
                                ,
                                editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                                  checkBoxModificar ('Seguros',rowData.Todo,rowData, onRowDataChange,this)
                
                                 
                                  )
                              },
                              { title: 'Envío por Comunidad', field: 'zonasEnvio',  editable:'onUpdate',
                              render: rowData =>
                                  <div>  <Button 
                                  disabled = { true}
                                  variant="outlined" color="primary" disableElevation 
                                  >
                                  FILTRO por Comunidad
                                  </Button>        
                        

                                </div>,
                              editComponent: ({ onChange, onRowDataChange, rowData }) => ( 
                                botonZonas(rowData)
                              )   
                            }, 
                       //       {title: 'Comunidad', field: 'Comunidad', lookup: this.state.lista_comunidades},
                              {title: 'Rol Asignado', field: 'Roles',
                              render: rowData =><Select
                                  labelId="demo-mutiple-chip-label"
                            //      disabled={true}
                                  multiple
                                  value={rowData.Roles}
                                  renderValue={(selected) => (
                                    <div style={{  display: 'flex', flexWrap: 'wrap',}}>
                                      {selected.map((value) => (
                                        <Chip variant="outlined" color='primary' key={value} label={value} style={{margin:6}}/>
                                      ))}
                                    </div>
                                  )}>
                                  </Select>,
                                    editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
                                      if (!rowData.Roles|| rowData.Roles.length===0){ 
                                        rowData.Roles=[]
                                        rowData.Roles[0]=""}
                                      return(
                                        
                                          <FormControl>
                                             <Select
                                                    multiple
                                                    value={rowData.Roles}
                                                    onChange={e => {
                                                      var newRowData = { ...rowData, Roles: e.target.value };
                                                      newRowData.Roles = e.target.value 
                                                      rowData.Roles = e.target.value 
                                                      if(newRowData.Roles.length>1){
                                                        newRowData.Roles=newRowData.Roles.filter((rol)=>rol!=='')
                                                      }else {  newRowData.Roles.concat({0:''})}
                                                      
                                                 
                                                      onRowDataChange(newRowData);
                                                    }
                                                  }
                                                  renderValue={(selected) => (
                                                    <div style={{  display: 'flex', flexWrap: 'wrap',}}>
                                                      {selected.map((value) => (
                                                        <Chip variant="outlined" color='primary' key={value} label={value} style={{margin:6}}/>
                                                      ))}
                                                    </div>
                                                  )}
                                                    
                                                  >
                                                    {this.state.roles.map((ce) => (
                                                      <MenuItem key={ce.Nombre} value={ce.Nombre} >
                                                        {ce.Nombre}
                                                      </MenuItem>
                                                    ))}
                                     
                                                      
                                                  </Select>
                                                </FormControl>
                                    )}
                                  },
                              {title: 'Sub-Rol', field: 'Rol'},
               
                              { title: "Verificación email",
                                    field: "emailVerified",
                                    type: "boolean",

                                    render: rowData =>checkBoxLectura(rowData.emailVerified)
                                    ,
                                    editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                                      checkBoxModificar ('emailVerified',false,rowData, onRowDataChange,this)
                                     
                                      )
                                 },
                              { title: "Fichar",
                                 field: "Fichar",
                                 type: "boolean",

                                 render: rowData =>checkBoxLectura(rowData.Fichar)
                               ,
                                 editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (

                                  checkBoxModificar ('Fichar',!rowData.Roles|| rowData.Roles.length===0,
                                  rowData, onRowDataChange,this)
                                  
                                     )
                              },  
                              {title: 'Último-Acceso', field: 'metadata.lastSignInTime', editable: 'never' ,

                              customSort: (a, b) =>
                              {  
                                const aa= moment(a.metadata.lastSignInTime,'DD/MM/YYYY, HH:mm:ss').format('YYYYMMDDHHmmss')
                                const bb= moment(b.metadata.lastSignInTime,'DD/MM/YYYY, HH:mm:ss').format('YYYYMMDDHHmmss')
                               
                                  return(aa - bb)
                                }
                            ,
                                 
                            
                            
                            },
                              {title: 'Fecha de Creación', field: 'metadata.creationTime', editable: 'never' }      
                              ]}
                              data={this.state.usuarios}
                              detailPanel={[
                                {
                                  tooltip: 'Show Name',
                                 
                                  render: rowData => {
                        
                                    return (
                                      <div
                                        style={{
                                          fontSize: 15,
                                          textAlign: 'start',
                                          color: 'primary',
                                          backgroundColor: 'azure',
                                          marginLeft:30,
                                          marginRight:30
                                        }}
                                      >
                                        <Box fontWeight="fontWeightBold" m={1}> 
                                              Fecha creación Usuario:{rowData.metadata.creationTime}
                                              <hr/>
                                              Ultimo acceso App: {rowData.metadata.lastSignInTime}
                                              <hr/>
                                              <Button  onClick={() => {this.handleClickOpen(rowData.Clave)}} variant="contained"  
                                                size="large" color="primary"  startIcon={<LockOpen />}>
                                                                    RESETEAR LA CONTASEÑA
                                              </Button>
                                              <hr/>
                                              <Button onClick={this.envioEmailVerificacion(rowData.email)} variant="contained"
                                                size="large" color="primary"  startIcon={<VerifiedUser />}>
                                                                    SOLICITAR CONFIRMACIÓN CUENTA POR EMAIL
                                              </Button>
                                              <hr/>
                                            
                                             
                                        </Box>
                                                           
                                     
                                      </div>
                                    )
                                  },
                                }
                              ]}
                              onRowClick={(event, rowData, togglePanel) => {
                                this.setState({rowData:rowData, abrir_cambios:true, mNombre:rowData.displayName, mApellido:rowData.Apellido
                                  ,mTelefono:rowData.Telefono,  mRoles:(rowData.Roles ? rowData.Roles:[]),mRol:rowData.Rol,
                                  memail:rowData.email,mDNI:rowData.DNI,  tipoRegistro:'modificacion',                           
                                  memailVerified:rowData.emailVerified,
                                  mFichar:rowData.Fichar, mPorcentaje:rowData.Porcentaje,
                                  mlastSignInTime:rowData.metadata.lastSignInTime,
                                  mcreationTime:rowData.metadata.creationTime,
                                  mTodo:rowData.Todo, mIncidencias:rowData.Incidencias,mSiniestros:rowData.Siniestros
                                  ,mCambios:rowData.Cambios,mAdminCon:rowData.AdminCon, mSeguros:rowData.Seguros
                                  })
                                }}
                              actions={[
                                  {
                                    icon:  () => <AddBox />,
                                    tooltip: 'Añadir un nuevo usuario administrador',
                                    // This makes add button to appear in table toolbar instead for each row
                                    isFreeAction: true,
                                    onClick: (event, rowData) => {
                                      this.setState({abrir_cambios:true, editar:true, tipoRegistro:'nuevo',
                                    rowData:[],mNombre:'', mApellido:'',mTelefono:'',  mRoles:[],
                                  memail:'',mDNI:'',memailVerified:false, mFichar:false, mPorcentaje:'',
                                  mlastSignInTime:'',mcreationTime:'',mRol:'',
                                  mTodo:true,mIncidencias:true,mSiniestros:true,mCambios:true,mAdminCon:true,
                                    mSeguros:true})
                                    }
                                  }
                                ]}
                              editable={{
                           //     isEditable: (rowData) => !this.state.modificar, 
                             //   isDeletable: (rowData) => !this.state.modificar,
                            onRowUpdateCancelled: () =>{
                     //         this.buscarUsuarios()                             
                              },
                            onRowUpdate: (newData, oldData) =>this.rowActualiza(newData,oldData),
                            onRowDelete: oldData =>                            
                              new Promise(resolve => {
                                       var deleteUser= this.props.firebase.functions.httpsCallable('deleteUser');
                                                  
                                        new Promise((resolve,reject) => {  
                                          deleteUser({uid:oldData.Clave}).then(function(result){
                                              // Read result of the Cloud Function.
                                            
                                              resolve(result)                                 
                                  
                                            }).catch(function(error) {
                                         
                                              reject(error)
                                              // ...
                                            })
                                        
                                        }).then(() => { 
                                                      resolve(this.props.firebase.user(oldData.uid).update({ "Borrado":"true"}))


                                                      this.props.firebase.flujoCorreo(oldData.uid).remove()

                                                      var data =this.state.usuarios.filter(usu => usu.uid !== oldData.uid)
                                                      this.setState({usuarios:data})
                                                      alert("El usuario  "+oldData.Nombre+" ha sido borrado");  
                                                     
                                                                     
                                                    //  alert("Usuario borrado correctamente")
                                                    }).catch(function(error) {alert("No se ha podido borrar el registro")  });
                              })              
                             
                              

                        
                        
                            }}
                            />
                  </Paper>
              }
     
     <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              open={this.state.abrir_cambios}
              onClose={e=>this.cerrarGestionCambios()}

            >
            <Paper style={{ height: '60%', marginTop:'-10%',  width:'80%'}}>
                <Paper  style={{padding:10,   
                maxHeight: '100%', overflowY: 'auto',
              
              }}> 
          
                <h3 style={{ color: 'white', backgroundColor:'#00a049', marginTop:'-0.5%' }}>Usuario: {this.state.mNombre} </h3>
               <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={1}>
                <Grid container spacing={1}>
                  <Grid item sm={4}>
                  {Textofield ('mNombre','Nombre del usuario',!this.state.editar,null,this)}
                  </Grid>
                  <Grid item sm={4}>
                  {Textofield ('mApellido','Apellido usuario',!this.state.editar,null,this)}
                  </Grid>
                  <Grid item sm={4}>
                  {Textofield ('mDNI','DNI',!this.state.editar,null,this)}
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item sm={4}>
                  {Textofield ('memail','Correo',!this.state.editar,null,this)}
                  </Grid>
                  <Grid item sm={4}>
                  {Textofield ('mTelefono','Teléfono',!this.state.editar,null,this)}
                  </Grid>
                  {despacho.Tipo!=='Comunidades'&&<Grid item sm={4} style={{textAlign: "center"}}>
                    <div style={{padding: 10 }}>
                    <Button
                        //  disabled = { rowData.estado=== "CANCELADA" || rowData.estado=== "CERRADA"}
                        variant="outlined" color="primary" disableElevation
                        disabled = {!this.state.editar ||( !this.state.mTodo&&!this.state.mIncidencias&&!this.state.mSiniestros&&
                          !this.state.mCambios&&!this.state.mAdminCon&&!this.state.mSeguros)}
                        onClick={e => {
                          const rowData ={...this.state.rowData ,displayName:this.state.mNombre, Apellido:this.state.mApellido,
                            Telefono:this.state.mTelefono,
                             Roles:this.state.mRoles,Rol:this.state.mRol,
                             email:this.state.memail,DNI:this.state.mDNI,
                             emailVerified:this.state.memailVerified,
                             Fichar:this.state.mFichar,
                             Todo:this.state.mTodo, Incidencias:this.state.mIncidencias,Siniestros:this.state.mSiniestros
                             ,Cambios:this.state.mCambios,AdminCon:this.state.mAdminCon, Seguros:this.state.mSeguros
                
                           }
                          this.gestionZonas(rowData)}}
                      >
                FILTRO por Comunidad
               </Button>
                     </div>
                    </Grid>       }
                </Grid>


                
                <Grid container spacing={1}  >
                  <Grid item sm={2} style={{textAlign: "center"}}>
                  <h5 style={{color: 'white', backgroundColor:(this.state.editar ? '#00a049':'darkseagreen') }}>Todos</h5>
               
                  {checkBoxModificar ('Todo',!this.state.editar,this.state.rowData, null,this)}
       
                  </Grid>
                  <Grid item sm={2} style={{textAlign: "center"}}>
                    <h5 style={{ color: 'white', backgroundColor:(this.state.editar ? '#00a049':'darkseagreen') }}>Incis</h5>
               
                   {checkBoxModificar ('Incidencias',!this.state.editar ||this.state.mTodo ,this.state.rowData, null,this)}
                  </Grid>
                  <Grid item sm={2} style={{textAlign: "center"}}>
                  <h5 style={{ color: 'white', backgroundColor:(this.state.editar ? '#00a049':'darkseagreen') }}>Siniestros</h5>
               
               {checkBoxModificar ('Siniestros',!this.state.editar ||this.state.mTodo,this.state.rowData, null,this)}
                    </Grid>
                    <Grid item sm={2} style={{textAlign: "center"}}>
                  <h5 style={{ color: 'white', backgroundColor:(this.state.editar ? '#00a049':'darkseagreen') }}>Modificación cliente</h5>
               
               {checkBoxModificar ('Cambios',!this.state.editar ||this.state.mTodo,this.state.rowData, null,this)}
                  </Grid>
                  <Grid item sm={2} style={{textAlign: "center"}}>
                  <h5 style={{ color: 'white', backgroundColor:(this.state.editar ? '#00a049':'darkseagreen') }}>Consultas</h5>
               
               {checkBoxModificar ('AdminCon',!this.state.editar ||this.state.mTodo,this.state.rowData, null,this)}
                 </Grid>
                 <Grid item sm={2} style={{textAlign: "center"}}>
                  <h5 style={{ color: 'white', backgroundColor:(this.state.editar ? '#00a049':'darkseagreen') }}>Seguros</h5>
               
               {checkBoxModificar ('Seguros',!this.state.editar ||this.state.mTodo,this.state.rowData, null,this)}
                 </Grid>
                </Grid>
            
                <Grid container spacing={1}>
                   <Grid item sm={4} style={{textAlign: "center"}}>
                      <h5 style={{ color: 'white', backgroundColor:(this.state.editar ? '#00a049':'darkseagreen') }}>Verificación email</h5>
                      {checkBoxModificar ('emailVerified',!this.state.editar,this.state.rowData, null,this)}
                   </Grid>
                   <Grid item sm={4} style={{textAlign: "center"}}>
                   <h5 style={{ color: 'white', backgroundColor:(this.state.editar ? '#00a049':'darkseagreen') }}>Fichar</h5>
                    {checkBoxModificar ('Fichar',!this.state.editar || !this.state.mRoles|| this.state.mRoles.length===0,
                        this.state.rowData, null,this)}
                      </Grid>
                   <Grid item sm={4} style={{textAlign: "center"}}>
                   <h5 style={{ color: 'white', backgroundColor:(this.state.editar ? '#00a049':'darkseagreen') }}>Roles asginados</h5>
                      {selecto('Roles','mRoles',null,this.state.rowData,this, !this.state.editar,this.state.roles)}
                
                    
                  </Grid>
                     
                </Grid>
                <Grid container spacing={1}>
                 
                    <Grid item sm={4}>
                    {Textofield ('mRol','Subrol',!this.state.editar,null,this)}
                    </Grid>
                  
                    <Grid item sm={4}>
                    {Textofield ('mlastSignInTime','lastSignInTime',true,null,this)}
                   </Grid>
                   <Grid item sm={4}>
                    {Textofield ('mcreationTime','creationTime',true,null,this)}
                   </Grid>
                    
        
                </Grid>
           
                <Grid container spacing={1}>
             
                {(!this.state.rowData.MultiCom||this.state.rowData.MultiCom===false)&&this.state.tipoRegistro==='modificacion'&&
                     <Grid item sm={6}>
                     <hr/>
                     <Tooltip title="Resetea la contraseña del usuario creando una nueva">
                        
                        <Button
                        fullWidth
                        onClick={() => {this.handleClickOpen(this.state.rowData.Clave)}} variant="contained"  
                                                size="large" color="primary"  startIcon={<LockOpen/>}>
                                    RESETEAR LA CONTRASEÑA
                       </Button>
                       </Tooltip>
                         <hr/>
                        <Tooltip title="Vuelve a enviar la confirmación de cuenta al usuario sobre el correo que tiene asociado">
                        <Button
                        fullWidth
                                  onClick={() => {
                                    this.envioEmailVerificacion(this.state.rowData.Email)}} 
                                  variant="contained"
                                  size="large" color="primary"  startIcon={<VerifiedUser/>}>
                                        SOLICITAR CONFIRMACIÓN CUENTA POR EMAIL
                        </Button>
                        </Tooltip>
                                               <hr/>
               
                          </Grid>}   
        
             
      
                </Grid>
                </Grid>
         
                </Paper>
                {!this.state.editar&&
               
                  <Button   variant="contained" 
                      onClick={e => { this.setState({editar:true})}}    
                      color="primary"
                      style={{top:'1%' }}
                  >Editar</Button>
                }
               {this.state.editar&&
               <>
                  <Button  variant="contained"    style={{top:'1%', backgroundColor:'orange' }}
                      onClick={e => { 

                        if(this.state.tipoRegistro==='nuevo')  {   this.setState({ abrir_cambios:false, editar:false})}
                        else{
                        const rowData=this.state.rowData
                        this.setState({ abrir_cambios:true,editar:false
                          ,mNombre:rowData.displayName, mApellido:rowData.Apellido,
                          mTelefono:rowData.Telefono,
                          mRoles:(rowData.Roles ? rowData.Roles:[]),mRol:rowData.Rol,
                          memail:rowData.email,mDNI:rowData.DNI,
                          memailVerified:rowData.emailVerified,
                          mFichar:rowData.Fichar, mPorcentaje:rowData.Porcentaje,
                          mTodo:rowData.Todo, mIncidencias:rowData.Incidencias,mSiniestros:rowData.Siniestros
                          ,mCambios:rowData.Cambios,mAdminCon:rowData.AdminCon, mSeguros:rowData.Seguros
             
                       //   mlastSignInTime:rowData.metadata.lastSignInTime,
                         // mcreationTime:rowData.metadata.creationTime
                        })
                      }
                        
                      }}    
                      color="primary"

                  >Cancelar</Button>
          
                 
                  <Button variant="contained"   color="primary" type="submit"  style={{top:'1%', left:5 }}

                    disabled={this.state.mNombre.trim()===''||this.state.mDNI.trim()===''||this.state.mApellido.trim()===''||
                    this.state.mTelefono.trim()===''||   this.state.memail.trim()===''}
                    onClick={e => { this.setState({editar:false})
                      const newData={...this.state.rowData ,displayName:this.state.mNombre, Apellido:this.state.mApellido,
                       Telefono:this.state.mTelefono,
                        Roles:this.state.mRoles,Rol:this.state.mRol,
                        email:this.state.memail,DNI:this.state.mDNI,
                        emailVerified:this.state.memailVerified,
                        Fichar:this.state.mFichar,
                        Todo:this.state.mTodo, Incidencias:this.state.mIncidencias,Siniestros:this.state.mSiniestros
                        ,Cambios:this.state.mCambios,AdminCon:this.state.mAdminCon, Seguros:this.state.mSeguros
           
                      }
                      if(this.state.tipoRegistro!=='nuevo')  {   
                        let guardar=false
                        if(this.state.abrir_cambios){guardar=true}
                        this.setState({open:true,abrir_cambios:false,abrirContraseña:false,mostrarTabla:false})
                  
                        this.rowActualiza(newData, this.state.rowData).then((data)=>{

                        if(data){this.setState({rowData:newData})}
                        else{  
                          this.setState({ 
                            mNombre:this.state.rowData.displayName, mApellido:this.state.rowData.Apellido,
                            mTelefono:this.state.rowData.Telefono,
                            mRoles:(this.state.rowData.Roles ? this.state.rowData.Roles:[]),mRol:this.state.rowData.Rol,
                            memail:this.state.rowData.email,mDNI:this.state.rowData.DNI,
                            memailVerified:this.state.rowData.emailVerified,
                            mFichar:this.state.rowData.Fichar, mPorcentaje:this.state.rowData.Porcentaje,
                            mTodo:this.state.rowData.Todo, mIncidencias:this.state.rowData.Incidencias,mSiniestros:this.state.rowData.Siniestros
                            ,mCambios:this.state.rowData.Cambios,mAdminCon:this.state.rowData.AdminCon, mSeguros:this.state.rowData.Seguros
               
                         //   mlastSignInTime:rowData.metadata.lastSignInTime,
                           // mcreationTime:rowData.metadata.creationTime
                          })

                        
                        }
                        this.setState({open:false,mostrarTabla:true})
                        if(guardar){this.setState({abrir_cambios:true})}
                      })
                    }else{
                      this.setState({abrir_cambios:false,open:true,mostrarTabla:false})
                      this.rowAñadir(newData).then((data)=>{
                        
                        this.setState({abrir_cambios:true,open:false,mostrarTabla:true, editar:true})
                        if(data){ this.setState({abrir_cambios:false})}
                      })

                    }
       
                            
                    }}    
                  >Guardar</Button>
                  </>
                }
                </Paper>
            
            </Modal>
    
              <Dialog open={this.state.abrirContraseña} /*onClick={this.handleClickClose}*/ aria-labelledby="form-dialog-title"
                                    fullWidth="true"
                                    maxWidth="sm"
                                  scroll="paper"
                                  >
                                  <DialogTitle id="form-dialog-title">RESETEO CONTRASEÑA</DialogTitle>
                                    <DialogContent dividers>
                                          <DialogContentText
                                            id="scroll-dialog-description"
                                        //    ref={descriptionElementRef}
                                            tabIndex={-1}
                                            style={{whiteSpace: "pre-wrap"}}
                                          >
                                       
                    {"Se va a resetear la contaseña del usuario, en el momento que se realice la actualización el usuario deberá acceder a la aplicación con la nueva contraseña"}
                                          </DialogContentText>
                                            <TextField
                                              autoFocus
                                              margin="dense"
                                              id="name"
                                              name ="contraseña"
                                              label="Añade la nueva contraseña (mínimo 6 dígitos)"
                                              type="text"
                                              value={contraseña}
                                              onChange={this.onChange}
                                              fullWidth
                                              />
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={this.handleClickClose} color="primary"
                                    >
                                      Cancelar
                                    </Button>
                                    <Button 
                                      disabled={contraseña.length<6}
                                      onClick={e => {
                                        let guardar=false
                                        if(this.state.abrir_cambios){guardar=true}
                                        this.setState({open:true,abrir_cambios:false,abrirContraseña:false,mostrarTabla:false})
                                      
                                        this.handleClickCloseOk().then(()=>{
                                          this.setState({open:false,mostrarTabla:true})
                                          if(guardar){this.setState({abrir_cambios:true})}
                                        })
                                        
                                        
                                        }} color="primary"
                                    >
                                      Confirmar
                                    </Button>
                                  </DialogActions>
                                </Dialog>
              <Modal
                         aria-labelledby="transition-modal-title"
                         aria-describedby="transition-modal-description"
                         style={{display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center'}}
                         open={this.state.abrir_zonas_envio}
                         onClose={this.cerrarGestionZonas}
                                  
              >
                  <Paper>
                   <Paper style={{ display: 'flex',
                          justifyContent: 'center',
                          flexWrap: 'wrap', margin:20,
                          padding:10,
                          listStyle: 'none'
                        }}> 

                      
                   {zonasEnvio.map((data) => {

                        return (
                       <li key={data.key}>
                             { data.label!=="Todos"&&<Chip
                        //      icon={icon}
                              label={data.label}
                              variant="outlined"
                              disabled= {data.click}
                              clickable
                              color="primary" 
                              style={{margin:6}}
                              onClick={this.seleccionarZonaEnvio(data)}
                             // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
//                              className={classes.chip}
                            />}
                          </li>
                        );
                      })}

                    
                  </Paper>
                      <Paper    style={{ 
                          justifyContent: 'center',
                          flexWrap: 'wrap', margin:20}}> 

                       <h3 style={{color:'#00a049', textAlign:'center', alignSelf:'center', marginBottom:5}}>{tituloEnvio}</h3>
                      
                       
                       </Paper>
                      {tituloEnvio&&tituloEnvio!==''&&
                      <Paper style={{ width:700,    justifyContent: 'center',
                      flexWrap: 'wrap', margin:20}}>
                       
                       {tituloEnvio!=='ROLES'&&<h5 style={{color:'#00a049', textAlign:'center', alignSelf:'center', marginLeft:30,
                        marginRight:30}}>
                         Sólo recibirás emails de las comunidades situadas en la columna izquierda. Si la columna izquierda
                         está vacía recibirás de todas las comunidades</h5>}
                         {tituloEnvio==='ROLES'&&<h5 style={{color:'#00a049', textAlign:'center', alignSelf:'center', marginLeft:30,
                        marginRight:30}}>
                         Sólo aparecerás con tu Rol en la comunidades de la columna izquierda.
                         Cuando entres por al aplicación del móvil sólo tedrás acceso a las comunidades de la columna izquierda.
                         Si la columna izquierda está vacía recibirás correos y tendrás acceso a todas las comunidades. </h5>}

                          <Grid container spacing={1} justify="center" alignItems="center" margin= 'auto'>
     
                          <Grid item>{this.customList('Lista a enviar email', this.state.zonaEnvio.left,"izquierdo")}</Grid>
                          <Grid item>
                            <Grid container direction="column" alignItems="center">
                            <Button
                              variant="outlined"
                              size="small"
                            
                              onClick={this.handleCheckedRight}
                              disabled={(this.state.leftChecked.length === 0)||(this.state.zonaEnvio.left === 0)
                              } 
                              aria-label="move selected right"
                            >
                              &gt;
                            </Button>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={this.handleCheckedLeft}
                              disabled={(this.state.rightChecked.length === 0)||(this.state.zonaEnvio.right === 0)
                                  }
                              aria-label="move selected left"
                            >
                              &lt;
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item>{this.customList('Comunidades Seleccionables', this.state.zonaEnvio.right,"derecho")}</Grid>
                      </Grid>
                      <hr/>
                           

                           <Button onClick={this.cerrarGestionZonas} color="primary"
                                    >
                                      Cancelar
                          </Button>
                           <Button
                                       disabled ={this.state.cambioZona}
                                       onClick={this.cerrarGestionZonasOK} color="primary"
                                    >
                                      Confirmar
                            </Button>
                            </Paper>}
                  </Paper>
                  
              </Modal>
              <Modal
              aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}

              open={this.state.mostrarAyuda}
              onClose={e=>this.setState({mostrarAyuda:false})}


            >
            {this.state.mostrarAyuda&&<ModalTutoriales Pdfs= {this.state.VideoTuto}/> 
          }
           </Modal>
    
    
      {this.state.abrir_cambios2&&<>{formu_Despacho(this)}</>}
    
       <Backdrop  color = 'primary' open={this.state.open}>
                  <CircularProgress color="primary" />
       </Backdrop>   
     
      </ThemeProvider>
    <Box mt={40}>
        {!this.state.open&&  <Copyright />}
    </Box>
    
  
  </Grid>
  );
  }

handleCheckedLeft = () => {

  var zonaEnvio=this.state.zonaEnvio 
  zonaEnvio.right= this.not(this.state.zonaEnvio.right,this.state.rightChecked)
  zonaEnvio.left= this.state.zonaEnvio.left.concat(this.state.rightChecked)

  this.setState({zonaEnvio:zonaEnvio})
  //this.setState({left: this.state.zonaEnvio.left.concat(this.state.rightChecked)})
  //this.setState({right: this.not(this.state.zonaEnvio.right,this.state.rightChecked)})
  this.setState({checked:this.not(this.state.checked, this.state.rightChecked)});
  this.setState({ leftChecked : this.intersection(this.state.checked, this.state.zonaEnvio.left),
    rightChecked:[], cambioZona:false})
  
};
handleCheckedRight= () => {
  
  var zonaEnvio=this.state.zonaEnvio 
  zonaEnvio.right= this.state.zonaEnvio.right.concat(this.state.leftChecked)
  zonaEnvio.left=this.not(this.state.zonaEnvio.left,this.state.leftChecked)
  this.setState({zonaEnvio:zonaEnvio})
  

  //this.setState({zonaEnvio.left: this.not(this.state.zonaEnvio.left,this.state.leftChecked)})
  this.setState({checked: this.not(this.state.checked,this.state.leftChecked) ,cambioZona:false});
  
    
  this.setState({ leftChecked : [],
    rightChecked:this.intersection(this.state.checked, this.state.zonaEnvio.right)})
};

numberOfChecked (items) {
  return (this.intersection(this.state.checked, items).length)
}

handleToggleAll = (items, lado) => () => {
  if (this.numberOfChecked(items) === items.length) {
 //   setChecked(not(this.state.checked, items.index));
    this.setState({checked:this.not(this.state.checked, items)});
    if (lado==="derecho"){
      this.setState({  rightChecked:[]});
    }else{
       this.setState({ leftChecked:[]});
    }   
  } else {
    this.setState({checked:this.union(this.state.checked, items)});
    if (lado==="derecho"){
      this.setState({  rightChecked: this.state.zonaEnvio.right});
    }else{
       this.setState({ leftChecked:this.state.zonaEnvio.left});
    }
 
  }
 
  
};

not(a, b) {
return a.filter((value) => b.indexOf(value) === -1);
}

intersection(a, b) {
return a.filter((value) => b.indexOf(value) !== -1);
}

union(a, b) {
return [...a, ...this.not(b, a)];
}
handleToggle (value){
const currentIndex = this.state.checked.indexOf(value);
const newChecked = this.state.checked;

if (currentIndex === -1) {
  newChecked.push(value);
} else {
  newChecked.splice(currentIndex, 1);
}

this.setState({checked: newChecked})
this.setState({  leftChecked:this.intersection(this.state.checked, this.state.zonaEnvio.left) });
this.setState({  rightChecked:this.intersection(this.state.checked, this.state.zonaEnvio.right) });
  
};
customList = (title, items1, lado) =>{

var items= items1
items.sort(function (a, b) {
  if (a.Id> b.Id) {
    return 1;
  }
  if (a.Id < b.Id) {
    return -1;

  }
 // a must be equal to b
  return 0; 
});
return(
<Card >
   <ThemeProvider>
  <CardHeader
    avatar={
      <Checkbox  
        onClick={this.handleToggleAll(items,lado)}
        checked={this.numberOfChecked(items) === items.length && items.length !== 0}
        indeterminate={this.numberOfChecked(items) !== items.length && this.numberOfChecked(items) !== 0}
        disabled={items.length === 0}
        inputProps={{ 'aria-label': 'all items selected' }}
        color= "primary"
      />
    }
    title={title}
    subheader={`${this.numberOfChecked(items)}/${items.length} selected`}
  />
  <Divider />
  <List style={{width: 260, height: 230,backgroundColor: "primary", overflow: 'auto',}}
   dense component="div" role="list">
    {items.map((value) => 
         <ListItem key={value} role="listitem" button 
         onClick={() => {this.handleToggle(value)}}
         disabled = {false } >
          <ListItemIcon>
            <Checkbox
              checked={this.state.checked.indexOf(value) !== -1}
              tabIndex={-1}
              disableRipple1 
              disabled = {false}
              inputProps={{ 'aria-labelledby': `transfer-list-all-item-${value}-label` }}
              color= "primary"
            />
            
          </ListItemIcon>
          <ListItemText id={`transfer-list-all-item-${value}-label`}primary={`${value.Id} - ${value.Nombre}`}/> 
          
        </ListItem>
    )}
  </List>
  </ThemeProvider>
</Card>
)}

}
/*
function initMap() {
  const map = new window.google.maps.Map(document.getElementById("map"), {
    center: { lat: 20.291, lng: 153.027 },
    zoom: 6,
    mapTypeId: "terrain",
  });
  // Define the symbol, using one of the predefined paths ('CIRCLE')
  // supplied by the Google Maps JavaScript API.
  const lineSymbol = {
    path: window.google.maps.SymbolPath.CIRCLE,
    scale: 8,
    strokeColor: "#393",
  };
  // Create the polyline and add the symbol to it via the 'icons' property.
  const line = new window.google.maps.Polyline({
    path: [
      { lat: 22.291, lng: 153.027 },
      { lat: 18.291, lng: 153.027 },
    ],
    icons: [
      {
        icon: lineSymbol,
        offset: "100%",
      },
    ],
    map: map,
  });
  animateCircle(line);
}

// Use the DOM setInterval() function to change the offset of the symbol
// at fixed intervals.
function animateCircle(line) {
  let count = 0;
  window.setInterval(() => {
    count = (count + 1) % 200;
    const icons = line.get("icons");
    icons[0].offset = count / 2 + "%";
    line.set("icons", icons);
  }, 20);
}

*/

const condition = authUser => !!authUser;
export default withFirebase(withAuthorization(condition)(Administrador));