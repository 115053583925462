import React, { Component } from 'react';

import { withAuthorization } from '../Session';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import Home from './home'


class ButtonBases extends Component {
  constructor(props) {
    super(props);
    this.state = { sin_lopd:true, despacho:'',usuarioLogueado:'' };
    //this.traerUsuarioLogueado = this.traerUsuarioLogueado.bind(this)
  }
  componentWillMount() {
 
    this.props.firebase.despacho().off()
  }

  traerUsuarioLogueado() {
    return new Promise ((resolve,reject)  => {  
    var user = this.props.firebase.auth.currentUser;
    if (user) {
        this.props.firebase.users().orderByChild('/Clave').equalTo(user.uid)
      .once('value', snapshot => {
        const usersObject = snapshot.val();
        if (usersObject) {
          const usuario = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));
  
          this.setState({ usuarioLogueado: usuario[0] })  
          resolve(usuario[0])
        }
      })
    }else{resolve(false)}  
  })
  }

  componentDidMount() {
    this.traerUsuarioLogueado().then(()=>{
    this.props.firebase.despacho().on('value', snapshot => {
        const usersObject = snapshot.val();
        var despacho =''
        if(usersObject){
            const lopd =  usersObject.Lopd
            despacho =usersObject
            if (lopd) {
            //  this.props.history.push(ROUTES.SIGN_IN);
            this.setState({sin_lopd:false, despacho:despacho})
            }

        }else{  this.setState({sin_lopd:true,despacho:despacho}) 
           //this.props.history.push(ROUTES.SIGN_IN);
          
          }
      
       
      })

    })
  }
  render() {
  const sin_lopd= this.state.sin_lopd
  return (
    <Home sin={sin_lopd} despacho={this.state.despacho} usuarioLogueado={this.state.usuarioLogueado}/>  
     
  )
}
}

const condition = authUser => !!authUser;
export default  withRouter (withFirebase (withAuthorization(condition)(ButtonBases)));