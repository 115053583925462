import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import Cabecera from '../Cabecera/index_365'
import Divider from '@material-ui/core/Divider';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import { PasswordForgetLink } from '../PasswordForget';
import Portada from '../Landing/index copy'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { createTheme  } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import  green from '@material-ui/core/colors/green';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Firebase from '../Firebase';

import CircularProgress from '@material-ui/core/CircularProgress';
import Copyright from '../Privacidad/copyright'

const theme = createTheme ({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
});


const SignInPage = () => (
  <div>
    
    <SignInForm />
  
  </div>
);
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  firebase:'',
  entrar:true,
  open:false,
  
};
class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE, 
      abrir_lista:false, 
      usuariosLopd:[],
      usuarioLogueado:'',despachos:[] ,uid:'', despacho:[]
    ,despachos_lopd:[]};
    this.traerUsuarioLogueado = this.traerUsuarioLogueado.bind(this)
  }
  componentDidMount() {
   

    let callable2 = this.props.firebase.functions.httpsCallable('traerDatosTabla')
    callable2({tabla:'/Despachos',base:{'BaseDatos':'https://dogarfirbase.firebaseio.com'}})        
    .then((result) => {       
      const usersObject = result.data
          if (usersObject){
            const despachos = Object.values(usersObject).map(value=> 
              value,
          
            )
            this.setState({despachos:despachos})
          }  
    })
    let callable3 = this.props.firebase.functions.httpsCallable('traerDatosTabla')
    callable3({tabla:'/LopdUsuario',base:{'BaseDatos':'https://dogarfirbase.firebaseio.com'}})        
    .then((result) => {       
      const usersObject = result.data
      if (usersObject){
              const usuariosLopd = Object.entries(usersObject).map(([key, val]) => ({
                "value": val , "uid": key ,  
            })); 
              this.setState({usuariosLopd:usuariosLopd })
            }   
    })
  }

  traerUsuarioLogueado(user,customClaims){
    var administrador=false
    if(user) {
      const code= (customClaims.code ? customClaims.code :'')
      
      const callable2 = this.props.firebase.functions.httpsCallable('traerDatosTabla')
        callable2({tabla:code+'/Usuarios',base:{'BaseDatos':customClaims.basedatos}})        
        .then((result) => {       
            const usersObject = result.data;
   
            if (usersObject){
                var usuariosList = Object.keys(usersObject).map(key => ({
                  ...usersObject[key],
                  uid: key,
                }));
                usuariosList = usuariosList.filter(usu => usu.Clave ===user.uid) 
                if(usuariosList&&usuariosList.length>0){
                administrador =usuariosList[0]
                }
                if(administrador.Administrador){ 
                    const lopd =this.state.usuariosLopd.filter(usu=> usu.uid===user.uid)
                    if(lopd&&lopd.length>0){  
                      const value = lopd[0].value
                      const callable2 = this.props.firebase.functions.httpsCallable('actualizaUsuario2')
                      callable2({uid:usuariosList[0].uid,'BaseDatos':customClaims.basedatos, 'Code':customClaims.code,
                        'registro':(lopd&&lopd.length>0  ? {'Lopd':value}:{'Lopd':false})})        
                      .then((result) => {  })
                       this.props.history.push(ROUTES.HOME)
                      }else{  this.props.history.push(ROUTES.HOME)}
                    
                }
                  
                else{   
                 
                  var entrar = true
                  //Antes de entrar hay que ver si tiene el rol que le impide ver la documentación
                  const roles =administrador.Roles
                  if(roles&&roles.length>0){
              
            
                    const callable2 = this.props.firebase.functions.httpsCallable('traerDatosTabla')
                    callable2({tabla:code+'/Roles2',base:{'BaseDatos':customClaims.basedatos}})        
                    .then((result) => {  
            
                
                        const ROLES = result.data
                        roles.forEach((rol)=>{
                          const sihay = ROLES.some(rhol => rhol.Nombre===rol &&(rhol.Documentos===false||!rhol.Documentos) )
                          if(sihay){ entrar=false}
                            
                        })
                    
                    
                        if(entrar){
                          this.props.history.push(ROUTES.HOME)
                          this.props.history.push(ROUTES.DOCUMENTOS);
                          this.props.history.push(ROUTES.DOCUMENTOS);
                          this.props.history.push(ROUTES.DOCUMENTOS);
                                          
                        }else{
                          this.setState({error:"Usuario no dispone de perfil para acceder, no eres Admiistrador y probablemente tengas un rol asigando que te impide acceder", open:false})
                          this.props.firebase.doSignOut()
                        }
                    
                      }
                    
                    )
                  }
                  else{
                  this.props.history.push(ROUTES.DOCUMENTOS);
                  this.props.history.push(ROUTES.HOME)
                  this.props.history.push(ROUTES.DOCUMENTOS);
                  this.props.history.push(ROUTES.DOCUMENTOS);
                  this.props.history.push(ROUTES.DOCUMENTOS);
                  }
 //     
                }
           }else {
             
                  this.setState({error:"Usuario no encontrado", open:false})
                  this.props.firebase.doSignOut()
         
            }       
        }).catch((e)=>{
          this.setState({error:"Inténtalo de nuevo", open:false})
        const err=e
        })
      
    }else {
      this.props.firebase.doSignOut()
      this.setState({error:"No se ha podido recuperar tu usuario, por favor refresca el navegador o inténtalo más tarde", open:false})}
  }
  onSubmit = event => {
    const { email, password } = this.state;
    this.setState({open:true})
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then((paco) => {

        var user =  paco.user
        const callable2 = this.props.firebase.functions.httpsCallable('getUser')
        callable2({uid:user.uid})        
        .then((result) => {  
         const res=result.data
          const lopd = this.state.usuariosLopd.filter(usu => usu.uid===user.uid)
          if(lopd&&lopd.length>0&&lopd[0].value){
          var despachos = this.state.despachos
          despachos= despachos.filter(des =>des.Lopd===true)
          this.setState({abrir_lista:true, despachos_lopd:despachos});
          }else {
             this.traerUsuarioLogueado(user,res.customClaims)}
           
          })  
      
       // this.props.firebase.doSignOut()
       // this.props.history.push(ROUTES.HOME);
      })
      .catch(error => {

        if(error.code==='auth/wrong-password'){   this.setState({ error:"La contraseña no es correcta", open:false });}
        
        else if(error.code==='auth/invalid-email'){   this.setState({ error:"El formato del email no es correcto", open:false});}
     //   this.setState({ error });
  
        else if(error.code==='auth/user-not-found'){   this.setState({ error:"Usuario no encontrado" , open:false});}
   
    });
    event.preventDefault();
  };
  handleListItemClick = (event, despacho) => {
      this.setState({abrir_lista:false, despacho:despacho});
      
    var user =  this.props.firebase.auth.currentUser

    
    const uid= user.uid


    const callable2 = this.props.firebase.functions.httpsCallable('getUser')
        callable2({uid:uid})        
        .then((result) => {  
         const res=result.data
         this.traerUsuarioLogueado(user,res.customClaims)
           
          })  
   
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    const { email, password, error, despachos_lopd } = this.state;
    const isInvalid = password === '' || email === '';
    return (
      <Paper elevation={0}  style={{height:'100%',  width:'100%'}}>
                    
      <ThemeProvider theme={theme}>     
   

         {!this.state.open&& 
           <Paper elevation={0}  style={{marginLeft:'1%',  width:'98%'}}>
  
          <Cabecera/>
   
        <hr/>    
            
            <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>  
       
    
                <Grid item  xs={11} sm={11} md={5}  alignItems="center" spacing={1}  >
                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>  
                      <Avatar style={{ color: '#fff',  backgroundColor: green[500]}}  >
                               <LockOutlinedIcon />
                      </Avatar>      
            
                          <h1>Acceder a Comunidad 365</h1>
                         
                        
                        <form onSubmit={this.onSubmit}>
                            {error && <p  style={{color:"red"}}>{error}</p>} 
                            <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              id="email"
                              label="Email Address"
                              name="email"
                              autoComplete="email"
                              autoFocus
                              value={email}
                              onChange={this.onChange}
                              type="text"
                              placeholder="Email Address"
            
                            />
                            <TextField
                              variant="outlined"
                              margin="normal"
                              required
                              fullWidth
                              name="password"
                              label="Password"
                              type="password"
                              id="password"
                              autoComplete="current-password"
                              value={password}
                              onChange={this.onChange}
                              placeholder="Password"
                    
                            />
                            <FormControlLabel
                              control={<Checkbox value="remember" color="primary" />}
                              label="Recordar"
                            />
                            <Button variant="outlined" color="primary"     type="submit"
                              fullWidth
                              disabled= {isInvalid}>
                              ENTRAR
                            </Button>
                        </form>
                            
                      
                  
                        </Grid> 
                        <PasswordForgetLink/>

                </Grid>
                <Grid item  xs={12} sm={12} md={7}  >
                <Portada/>
                </Grid>  
            </Grid>
            </Paper>
        }
       
        <Modal
                           aria-labelledby="transition-modal-title"
                           aria-describedby="transition-modal-description"
                           style={{display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center'}}
                            open={this.state.abrir_lista}
                            onClose={e=> {this.setState({abrir_lista:false,email:'', password:'', error:'',
                            open:false})
                           }}                                        
                                                    >
                   <Paper style={{maxHeight: 500, overflow: 'auto',maxWidth:'80%', alignContent:'center',  alignItems:'center'}}>   
                       <h2  style={{   margin:20,color: 'green' }}>PERFIL LOPD</h2>
                          <hr/>
                       <h3  style={{  margin:20, color: 'green' }}>SELECCIONA UN DESPACHO</h3>                              
        
                      <Grid container spacing={0}>
                
                      <Grid item xs >
                                        
                  
                          <hr/>

                        <Paper style={{maxHeight: 300, overflow: 'auto', margin:20}}>
                      
                            <List component="nav" dense="true"  aria-label="main mailbox folders" justifyContent="space-around" alignItems="center" >
                              { despachos_lopd &&  despachos_lopd.map(despacho => (                  
                              <Grid>
                                <Divider />
                              <ListItem
                              button
                              selected={this.selectedIndex ===despacho }
                              onClick={(event) => this.handleListItemClick(event, despacho)}
                              >
                              <ListItemText primary={despacho.Codigo+' - '+ despacho.Nombre}/>
                        
                              </ListItem>                      
                              </Grid>
                              ))}
                          </List>
                      </Paper>
                      </Grid>
                  
              </Grid>
              



                  </Paper>
              </Modal>


    
     
       
       
        <Box mt={5}>
        {!this.state.open&&<Copyright />}
              
        </Box>
        <Backdrop  color = 'primary' open={this.state.open}>
                                          <CircularProgress color="primary" />
          </Backdrop>

          </ThemeProvider>  

    </Paper>
    );
  }
}
const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);
export default SignInPage;
export { SignInForm };