import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { createTheme  } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import  green from '@material-ui/core/colors/green';
import Box from '@material-ui/core/Box';
import {  responsiveFontSizes } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import  { Breakpoint } from 'react-socks';


let theme = createTheme ({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
      claro: green[200]
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#e81446',
    },
  
  },
});
theme = responsiveFontSizes(theme);


const piePagina = 

  
    <Grid>
        <ThemeProvider theme={theme}>
       <Typography variant="h6"  color="primary" align='left'>
        TEXTOS LEGALES
        </Typography>
        <br/>
      <Typography variant="body1"  color="primary" align="left">
          <Link color="inherit" href="http://comunidad365.es/politica_privacidad">
          POLÍTICA DE PRIVACIDAD
          </Link>
      </Typography>

      <Typography variant="body1"  color="primary" align="left">
          <Link color="inherit" href="http://comunidad365.es/politica_cookies">
          POLÍTICA DE COOKIES
          </Link>
      </Typography>
      <Typography variant="body1"  color="primary" align="left">
          <Link color="inherit" href="http://comunidad365.es/aviso_legal">
            AVISO LEGAL
          </Link>
      </Typography>
      <br/>
     
   </ThemeProvider>
   
  </Grid>

const enlacesInteres = 

  
      <Grid>   
          <ThemeProvider theme={theme}>
      <Typography variant="h6"  color="primary" align='left'>

        ENLACES DE INTERÉS
      </Typography>
      <br/>
      <Typography variant="body1"  color="primary" align="left">

      <Link color="inherit" href="http://comunidad365.es/signin">
        Espacio administrador
      </Link>

      </Typography>  
       <Typography variant="body1"  color="primary" align="left">

      <Link color="inherit" href="http://portalartico.es">
        Ártico consultores
      </Link>
      </Typography>
      <br/>
      
      </ThemeProvider>
      </Grid>

const contactos = 

  
          <Grid>
              <ThemeProvider theme={theme}>
                      <Typography variant="h6"  color="primary" align='left'>
                      CONTACTO
                      </Typography>  
                      <br/>     
                      <Typography color="primary" align='left'>
                      info@comunidad365.es
                      </Typography>                   
                      <Typography  color="primary" align='left'>
                      soporte@comunidad365.es
                      </Typography>
                      <Typography  color="primary" align='left'>
                      976120160 
                      </Typography>
                      <Typography color="primary" align='left'>
                       Pablo Sarasate 20-3, Zaragoza  50010,       
                      </Typography>
              </ThemeProvider>

</Grid>
  

function Copyright() {
 
  return (
        <Grid id='contacto' style={{backgroundColor:'white'}} >
          
          <ThemeProvider theme={theme}>
         <Breakpoint large up>  
          <Grid container direction="row"  justifyContent="space-around"  alignItems='flex-start'>
          
            {enlacesInteres}
            <Divider orientation="vertical" flexItem />
            {contactos}
            <Divider orientation="vertical" flexItem />
            {piePagina}
          
          
        
            </Grid>
          </Breakpoint>
          <Breakpoint medium down>
          <Grid container
              direction="column"
              justify="space-around"
              alignItems="flex-start" style={{ marginLeft:10}}
              >
                

            <Grid    style={{ widht:'100vw', marginTop:20}} >
                <hr/>
                {enlacesInteres}
              </Grid>
          
           
              <Grid    style={{ marginTop:20}} >
                <hr/>
              {contactos}
              </Grid>
              <Grid    style={{ marginTop:20}} >
                <hr/>
                {piePagina}
              </Grid>
             
              
              
            
        
      
          </Grid>

          </Breakpoint>

      <Grid style={{marginTop:80}} alignItems="center">
        <Typography variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <Link color="inherit" href="https://comunidad365.es/">
            www.comunidad365.es
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Grid>
      <Box mt={10}>
             
            </Box>
      </ThemeProvider>
  </Grid>
  );
}


export default Copyright;
