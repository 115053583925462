import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Copyright from './copyright';
import  Cabecera  from '../Cabecera/index_365';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { createTheme , responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
//let theme = createTheme ();

let theme = createTheme ({
  typography: {
    // Tell Material-UI what the font-size on the html element is.
    htmlFontSize: 14,
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
      claro: green[200]
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#e81446',
    },

  },
});
theme = responsiveFontSizes(theme);
theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};
theme.typography.h4 = {
  fontSize: '0.9rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.1rem',
  },
};

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(responsable,tipo,nombre,finalidad, duracion) {
  return {responsable,tipo,nombre,finalidad, duracion };
}
const rows = [
  

  createData('.google.com', 'Analítica',	'1P_JAR',	'Recopilar estadísticas del sitio web y rastrear las tasas de conversión',	'1 semana'),
  createData('.google.com, .youtube.com','Analítica',	'CONSENT',	'Ayuda a almacenar el estado de consentimiento de cookies del usuario en el ámbito de las Redes Sociales',	'20 años'),

  createData('.google.com',	'Publicitaria',	'ANID',	'Medir el rendimiento de los anuncios y proporcionar recomendaciones relativas a productos basadas en datos estadísticos',	'1 mes'),
  createData('.google.com', 'Analítica',	'NID',	'Contiene un ID único que Google utiliza para recordar tus preferencias y otra información. Visualización de mapas mediante la aplicación de Google Maps.',	'6 meses'),

  createData('.google.com', 'Analítica',	'UULE',	'Cookie asociada al servicio de Google Maps. Permite la geolocalización de nuestra fábrica en la sección correspondiente de este sitio web',	'24 horas'),

  createData('.google.com', 'Analítica',	'OGPC',	'Estas cookies son utilizadas por Google para almacenar las preferencias del usuario y la información durante la visualización de las páginas con los mapas de Google.',	'2 meses'),

  createData('.google.com', 'Analítica',	'DV',	'Proveer servicios y extraer información anónima sobre la navegación.',	'24 horas'),
  createData('.google.com',	'Publicitaria',	'OTZ',	'Permiten optimizar y personalizar los anuncios mostrados al navegar. Sirve para analizar pautas de tráfico a la web, duración de la sesión del navegador. Está relacionada con el sistema de publicidad personalizada de Google Adwords.','1 mes'),
  createData('.doubleclick.net',	'Publicitaria Comportamental',	'DSID',	'Su objetivo es mostrarle anuncios relacionados con las preferencias de su navegación.',	'1 año'),
  
  createData('.youtube.com',	'Analítica',	'VISITOR_INFO1_LIVE',	'Realiza el seguimiento de los videos visitados que se encuentran incrustados en la web.',	'8 meses'),
  
  createData('.youtube.com',	'Analítica',	'YSC'	,'Medir las reproducciones de videos realizadas por el usuario y registra los eventos de “Me gusta” o “Compartir video”',	'Sesión'),  

];
function BasicTable() {
const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="right">DOMINIO/RESPONSABLE</TableCell>
            <TableCell align="right">TIPO</TableCell>
            <TableCell align="right">NOMBRE</TableCell>
            <TableCell align="right">FINALIDAD</TableCell>
            <TableCell align="right">DURACIÓN</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
             
              <TableCell align="right">{row.responsable}</TableCell>
              <TableCell align="right">{row.tipo}</TableCell>
              <TableCell align="right">{row.nombre}</TableCell>
              <TableCell align="right">{row.finalidad}</TableCell>
              <TableCell align="right">{row.duracion}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
class Aviso_legal extends Component {

    render() {
        return (
          <Grid >   <Cabecera/>    <hr/> 
          <Grid style={{ marginLeft: 20, marginRight:20}}>
          <br />
            <Typography variant='h3' color='primary' align='center'>
            POLÍTICA DE COOKIES
              </Typography>
            <br />
              <hr/>
              <ThemeProvider theme={theme}>
              <Typography   align='justify'>

                  Esta web utiliza cookies, pixels u otros métodos propios y de terceros para prestar sus servicios, recoger información estadística y mostrarle publicidad relacionada con sus preferencias mediante el análisis de sus hábitos de navegación. 

                  <br/>
                  <br/>
                  Las cookies son ficheros creados en el navegador del usuario que mediante el almacenamiento de datos permiten registrar su actividad en el Sitio Web y llevan a cabo distintas funcionalidades como el análisis estadístico, una gestión del tráfico web eficiente, la compra a través de la página, etcétera. Las cookies se instalan automáticamente en el ordenador del usuario, pero este puede administrarlas o bloquearlas totalmente mediante los enlaces que aportamos al final de la política. 
                  <br/>
                  <br/>
                  Las cookies también podrán compartir información sobre su navegación en nuestro sitio web con terceros colaboradores (agencias, patrocinadores, empresas publicitarias, etc.) que nos ayudan en la implantación de publicidad y en la gestión analítica y de redes sociales.
                  <br/>
                  <hr/>
              
            <Typography variant='h4' color='primary' align='center'>
            Finalidad de las Cookies utilizadas
              </Typography>
                  <br />
                -	Cookies técnicas: Son aquéllas que son estrictamente necesarias y que permiten al usuario la correcta navegación a través de una página web, plataforma o aplicación, que el contenido de la página web se descarga correctamente y la utilización de las diferentes opciones o servicios que en ella existan, como acceder a una intranet, realizar un pedido, rellenar un formulario o emplear elementos de seguridad, permitiendo la navegación por el sitio web.
                  <br/>
                  <br/>

                  -	Cookies de personalización: Son aquéllas que permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario configurando así el sitio web de una manera determinada según las preferencias del usuario, como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.
                  <br/>
                  <br/>

                  -	Cookies de análisis, analíticas o de estadística de uso: Son aquéllas que permiten al responsable de las mismas, mediante la recopilación de datos de la actividad de los usuario, llevar el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas con finalidades estadísticas (como realizar estadísticas del número de visitas, servicios/productos demandados, etc.) que permiten más tarde mejorar la forma en que se ofrecen los servicios, mejorar el funcionamiento y servicio al usuario y elaborar perfiles de los usuarios. El seguimiento se hace de manera agregada y anónima.
                  <br/>
                  <br/>

                  -	Cookies publicitarias: Son aquéllas que permiten el seguimiento del uso y la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.
                  <br/>
                  <br/>

                  -	Cookies de publicidad comportamental o cookies de publicidad dirigida online: Estas cookies recogen y almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.
                  <br/>
                  <br/>


                  El webmaster responsable deberá revisar las distintas cookies en uso de la página web y completar o ampliar la tabla:
                  <br/>
                  <br/>

                  Relación de cookies utilizadas en la página web:
                  <br/>
                  <br/>
                  <BasicTable/>
                  <br/>
                  <hr/>
        
            <Typography variant='h4' color='primary' align='center'>
            Uso de las cookies de Google Analytics en sitios web
              </Typography>
                  <br />
                 Información sobre el uso de cookies de Google Analytics <Link color="primary" href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es">
                              +Info
                            </Link> 
                            <br/>
                          <br/>

                  Para evitar que Google Analytics recopile y utilice sus datos, ponemos a su disposición la extensión de inhabilitación para navegadores de Google Analytics en el siguiente enlace <Link color="primary" href="https://tools.google.com/dlpage/gaoptout/index.html?hl=es">
                              +Info
                            </Link> 
                            <br/>
                  <br/>

                  Además Google tiene su propia política relativa al uso de cookies publicitarias <Link color="primary" 
                  href="http://www.google.es/policies/technologies/ads/">
                              +Info
                            </Link> 

                            <br/>    
                  
                            <br/>

                            <hr/>
              
            <Typography variant='h4' color='primary' align='center'>
            Redes Sociales
              </Typography>
                  <br />
                 
                  La entidad puede administrar páginas en redes sociales para publicitarse y servir de punto de contacto con sus clientes. Estas páginas pueden instalar cookies a sus visitantes incluso aunque estos no se encuentren registrados en las mismas. Los tipos de Cookies que se instalan y sus finalidades están descritas en las oportunas páginas de Cookies de cada red social (Facebook, Twitter, Youtube, Instagram, etcétera.).
                  <br/>
                  <br/>

                  Plug-ins – Si se hace uso de los plug-ins sociales instalados en la página web, estos comunicarán datos generados por su interacción con el plug-in al responsable de la web social. Si se ha iniciado sesión en la red social, esta podrá asociar la visita a la web, con la cuenta de dicha red social. Los tratamientos y sus finalidades están descritos en las oportunas políticas de privacidad de cada red social.
                  
                  <br/>
                  <hr/>
                
            <Typography variant='h4' color='primary' align='center'>
            Desactivar Cookies en Navegadores
              </Typography>
                  <br />
                  	Chrome  <Link color="primary" 
                  href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647">
                              +Info
                            </Link> 
                            <br/>
                  <br/>

                  	Explorer  <Link color="primary" 
                  href="https://support.microsoft.com/es-es/help/17442/windows-internet-explorer-delete-manage-cookies">
                              +Info
                            </Link> 
                            <br/>
                  <br/>

                  	Firefox  <Link color="primary" 
                  href="https://support.mozilla.org/es/kb/Borrar cookies">
                              +Info
                            </Link> 
                            <br/>
                  <br/>

                  	Safari  <Link color="primary" 
                  href="https://support.apple.com/es-es/guide/safari/sfri11471/mac">
                              +Info
                            </Link> 
                            <br/>
                  <br/>

                  	Opera  <Link color="primary" 
                  href="https://help.opera.com/en/latest/web-preferences/#cookies">
                              +Info
                            </Link> 
                            <br/>
                  <br/>

                  La desactivación de Cookies puede provocar en la web una navegación menos fluida y que algunos de nuestros servicios en la misma dejen de funcionar correctamente.
                  <br/>
                  <br/>

                  En   <Link color="primary" 
                  href="http://www.google.es/policies/technologies/ads/">
                              www.allaboutcookies.org/es/
                            </Link> y <Link color="primary" href= "http://www.youronlinechoices.com/es/">
                            http://www.youronlinechoices.com/es/ 
                            </Link> puede encontrar más información sobre las cookies y cómo gestionarlas o eliminarlas.

                  </Typography>
                        <hr/>
                           <br/>
                  <br/>   <br/>
                  
              <Box mt={5}>
                  <Copyright />
            </Box>     
            <Box mt={5}>
                
            </Box>     
           </ThemeProvider>
        </Grid>
        
        </Grid>
        );
      }
    }

export default Aviso_legal;