import React, { useState, useEffect} from 'react';
import { CSSTransition } from 'react-transition-group';
import DehazeIcon from '@material-ui/icons/Dehaze';
//import TmdbIcon from '../../assets/tmdb-power.png';
import './Sidebar.scss';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { AnimateOnChange } from 'react-animation'

import {BootstrapButton, llevarFoco } from '../Header/Header'
import {cumulativeOffset,focoBoton } from '../nueva'



const Sidebar = () => {
  const [expandedLinks, setExpandedLinks] = useState(false);


    

  const toggleLinks = () => {
    
    setExpandedLinks(!expandedLinks);
  
    
  };

  useEffect(() => {
    
    if(expandedLinks){   focoBoton(window.pageYOffset) }
    }, [expandedLinks])
  

  const animacion = (tituloNuevo) =>{
    document.getElementById("titulo_app").textContent=tituloNuevo
    //setTitulo(tituloNuevo)



   
  
  }





  var  color = 'transparent'
  const sidebar = document.getElementById("sidebar-top");
  if(sidebar){ 
    color = sidebar.style.backgroundColor
  }

  return (
    <div  id="cabecera" >
      <ul className="sidebar-top" id='sidebar-top'>
        <div className="sidebar-links">
          <li className="sidebar-link bars" onClick={(e) => {
                     
                      const header = document.getElementById("cabecera");
                      if(header){
                        if (window.pageYOffset < cumulativeOffset(header).top) { 
                   //       window.removeEventListener("scroll", function() {setExpandedLinks(false)})              
                          scroll('cabecera')
                      
                   //      window.addEventListener("scroll", function() {setExpandedLinks(false);})
                          setExpandedLinks(true)
                                       
                         
                        }else{ toggleLinks() }
                    }
                     
            }}>
            <DehazeIcon fontSize="large" />
          </li>
      
<AnimateOnChange

  animationIn="bounceIn"
  animationOut="bounceOut"
  durationOut={1000}
>
<div  className='titulo_app' id='titulo_app'></div>
</AnimateOnChange>
     
                   
           
            <p  className='cuad-img54' >
            <img src={require('../../../Imagenes/LOGOC365.png')}  className='img54' alt="logo"   />
            </p>
          
        </div>
      </ul>

      <CSSTransition
        in={expandedLinks}
        timeout={400}
        classNames="visible"
        unmountOnExit
       
      >
        <div  id='sidebar'  style={{backgroundColor:color}} >

               <Grid className="sidebar-link" >
                  <Tooltip  title="">
                  <BootstrapButton id='boton1' onClick={(e) => { scroll('ventana1');
                                                      animacion('Quienes somos');
                                                      toggleLinks()}}>Quienes somos</BootstrapButton>
                  </Tooltip>
                </Grid>
                <Grid className="sidebar-link" >
                  <Tooltip  title="">
                  <BootstrapButton id='boton2' onClick={(e) => { scroll('ventana2');
                                                            
                                                              animacion('Producto');
                                                              toggleLinks() }}>Producto</BootstrapButton>
                  </Tooltip>
                </Grid>
          
                <Grid className="sidebar-link" >
                  <Tooltip  disableTouchListener title="">
                  <BootstrapButton id='boton3' onClick={(e) => { scroll('ventana3');
                                                                 animacion('Ventajas')
                                                                  toggleLinks() }}>Ventajas</BootstrapButton>
                  </Tooltip>
                </Grid>
                <Grid className="sidebar-link" >
                  <Tooltip  disableTouchListener title="">
                  <BootstrapButton id='boton4' onClick={(e) => { scroll('ventana4'); 
                   animacion('Valores') ;toggleLinks() }}>Valores</BootstrapButton>
                  </Tooltip>
                </Grid>
                <Grid className="sidebar-link" >
                  <Tooltip  disableTouchListener title="">
                  <BootstrapButton id='boton5' onClick={(e) => { scroll('ventana5');
                   animacion('Contratación'); toggleLinks() }}>Contratación</BootstrapButton>
                  </Tooltip>
                </Grid>
                <Grid className="sidebar-link" >
                  <Tooltip  disableTouchListener title="">
                  <BootstrapButton id='boton6' onClick={(e) => { scroll('ventana6');
                   animacion('Medio Ambiente'); toggleLinks() }}>Medio ambiente</BootstrapButton>
                  </Tooltip>
                </Grid>
                <Grid className="sidebar-link" >
                  <Tooltip  disableTouchListener title="">
                  <BootstrapButton id='boton7' onClick={(e) => { scroll('ventana7'); 
                   animacion('Equipo');toggleLinks() }}>Equipo</BootstrapButton>
                  </Tooltip>
                </Grid>
                <Grid className="sidebar-link" >
                  <Tooltip  disableTouchListener title="">
                  <BootstrapButton id='boton8' onClick={(e) => { scroll('ventana8');
                   animacion('Actualidad'); toggleLinks() }}>Actualidad</BootstrapButton>
                  </Tooltip>
                </Grid>
                
           
        </div>

      </CSSTransition>
     </div>
  );
};


export function scroll(element1) {
  let start = null;
  const element= document.getElementById(element1);
  const target = element && element? cumulativeOffset(element).top : 0;
  const firstPos = window.pageYOffset || document.documentElement.scrollTop;
  let pos = 150;

  (function() {
    
    var browser = ['ms', 'moz', 'webkit', 'o'];
    
    for(let x = 0, length = browser.length; x < length && !window.requestAnimationFrame; x++) {
      window.requestAnimationFrame = window[browser[x]+'RequestAnimationFrame'];
      window.cancelAnimationFrame = window[browser[x]+'CancelAnimationFrame'] 
      || window[browser[x]+'CancelRequestAnimationFrame'];
    }
    
  }());
  
function showAnimation (timestamp) {

    if(!start) { start = timestamp || new Date().getTime(); } //get id of animation
    
  //  let elapsed = timestamp - start;

  
    
  
    pos = (target < firstPos) ? ( window.pageYOffset  - (pos * 0.05)) : ( window.pageYOffset  + (pos* 0.1));
    
    window.scrollTo(0, pos);
        
    if(pos >= target&&target > firstPos) {
      llevarFoco(element1)
        cancelAnimationFrame(start);
      //  if(element) { element.focus(); }
        pos = 0;
    } else if(pos < target&&target<firstPos)
          {
            llevarFoco(element1)
            cancelAnimationFrame(start); pos=0}   
    else {
        window.requestAnimationFrame(showAnimation);
    }
  }
  window.requestAnimationFrame(showAnimation);
  
}



export default Sidebar;