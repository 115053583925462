import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import firebase2 from 'firebase'


var config = {
  apiKey: 'AIzaSyBQeTK0VTNn9jfPB5nuW5Kjc7cRBMr0deU',
  authDomain: 'https://dogarfirbase.firebaseio.com',
  databaseURL: 'https://dogarfirbase.firebaseio.com',
  projectId: 'dogarfirbase',
  storageBucket: 'dogarfirbase.appspot.com',
  messagingSenderId: '1061123091455'
};
class Firebase {
    constructor(databaseURL,deposito,codeDespacho,despacho) {
      if(databaseURL!==''){config.databaseURL=databaseURL}
        const appp= app.initializeApp(config);
        this.auth = app.auth();
        this.db = app.database();
        this.storage = app.storage();
        this.firebas1e2 = firebase2;
        this.functions = app.functions();
        this.storage_propio = appp.storage(deposito);
        this.appp = appp
        this.app = app
        this.deposito=deposito
        this.codeDespacho=codeDespacho
        this.despachoCode=despacho
     
            }
    
    // *** Auth API ***

    
    storage_propio_completo = (uid) => {    return (uid ? this.storage_propio.ref(`${uid}`) :this.storage_propio.ref())};
    
    almacen =(url) => app.storage(url);

    doCreateUserWithEmailAndPassword = (email, password) =>
      this.auth.createUserWithEmailAndPassword(email, password);
  
    doSignInWithEmailAndPassword = (email, password) =>
      this.auth.signInWithEmailAndPassword(email, password);
  
    doSignOut = () => this.auth.signOut();
  
    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  
    doPasswordUpdate = password =>
      this.auth.currentUser.updatePassword(password);



    carpetas = () =>  ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Carpetas'):this.db.ref('Carpetas')); //this.db.ref('Carpetas');
    fichar = uid =>  ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Fichar/${uid}`):this.db.ref(`Fichar/${uid}`)); //this.db.ref(`Fichar/${uid}`);

    rol = uid => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Roles/${uid}`):this.db.ref(`Roles/${uid}`)); //this.db.ref(`Roles/${uid}`);

    roles = () => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Roles'):this.db.ref('Roles'));// this.db.ref('Roles');

    
    rol2 = uid =>  ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Roles2/${uid}`):this.db.ref(`Roles2/${uid}`));

    roles2 = () => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Roles2'):this.db.ref('Roles2'));
    categorias = () => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Categorias'):this.db.ref('Categorias'));
    categoria = uid =>( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Categorias/${uid}`):this.db.ref(`Categorias/${uid}`));// this.db.ref(`Categorias/${uid}`);
 
    actividad_tipo = () => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Actividad_Tipo'):this.db.ref('Actividad_Tipo'));// this.db.ref('Actividad_Tipo');
    despacho = () =>( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Despacho'): this.db.ref('Despacho'));
   

    despachos = () =>( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Despachos'): this.db.ref('Despachos'));
 
  

    flujoCorreos = () => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Flujocorreos'):this.db.ref('Flujocorreos'));// this.db.ref('Flujocorreos');
    flujoCorreo =  uid =>(this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Flujocorreos/${uid}`):this.db.ref(`Flujocorreos/${uid}`));// this.db.ref(`Flujocorreos/${uid}`);
    reservaszona = () => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Reservaszona'):this.db.ref('Reservaszona')); //this.db.ref('Reservaszona');
    reservazona =  uid => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Reservaszona/${uid}`):this.db.ref(`Reservaszona/${uid}`));// this.db.ref(`Reservaszona/${uid}`);
    
    zonascomunes = () =>  ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Zonascomunes'):this.db.ref('Zonascomunes'));//this.db.ref('Zonascomunes');
    zonacomun = uid => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Zonascomunes/${uid}`):this.db.ref(`Zonascomunes/${uid}`));// this.db.ref(`Zonascomunes/${uid}`);
    dispositivos = () => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Dispositivos'):this.db.ref('Dispositivos'));// this.db.ref('Dispositivos');
    //dispositivo = uid => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Dispositivos/${uid}`):this.db.ref(`Dispositivos/${uid}`));// this.db.ref(`Dispositivos/${uid}`);

    userss  = uid =>  this.db.ref(`Users/${uid}`);
    user = uid => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Usuarios/${uid}`):this.db.ref(`Usuarios/${uid}`));// this.db.ref(`Usuarios/${uid}`);

    users = () =>( this.codeDespacho ? this.db.ref(this.codeDespacho+'/Usuarios'): this.db.ref('Usuarios'))

    inci = uid => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Incidencias/${uid}`):this.db.ref(`Incidencias/${uid}`));// this.db.ref(`Incidencias/${uid}`);

    incis = () =>( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Incidencias'):this.db.ref('Incidencias'));// this.db.ref('Incidencias');

    comu = uid =>  ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Comunidad/${uid}`):this.db.ref(`Comunidad/${uid}`));//this.db.ref(`Comunidad/${uid}`);

    comus = () =>  ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Comunidad'):this.db.ref('Comunidad'))

    activ = uid => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Actividad/${uid}`):this.db.ref(`Actividad/${uid}`));// this.db.ref(`Actividad/${uid}`);

    votaciones =  () => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Votaciones'):this.db.ref('Votaciones'));//this.db.ref('Votaciones');
    votacion = uid => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Votaciones/${uid}`):this.db.ref(`Votaciones/${uid}`));// this.db.ref(`Votaciones/${uid}`);

    activs = () =>( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Actividad'):this.db.ref('Actividad'));// this.db.ref('Actividad');

    gremio = uid => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Gremio/${uid}`):this.db.ref(`Gremio/${uid}`));// this.db.ref(`Gremio/${uid}`);

    gremios = () =>( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Gremio'):this.db.ref('Gremio'));// this.db.ref('Gremio');
    
    dispo = uid => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Dispositivos/${uid}`):this.db.ref(`Dispositivos/${uid}`));// this.db.ref(`Dispositivos/${uid}`);

    estados = () =>( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Estados'):this.db.ref('Estados'));// this.db.ref('Estados');
    tipos = () => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Tipos'):this.db.ref('Tipos'));//this.db.ref('Tipos');  
    puestosdeTrabajo = () => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'PuestoTrabajo'):this.db.ref('PuestoTrabajo'));//this.db.ref('PuestoTrabajo');
          
    puestodeTrabajo = uid => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`PuestoTrabajo/${uid}`):this.db.ref(`PuestoTrabajo/${uid}`));// this.db.ref(`PuestoTrabajo/${uid}`);
  
    soloPresi = uid =>  ( this.codeDespacho ?this.db.ref(this.codeDespacho+'/'+`SoloPresi/${uid}`):this.db.ref(`SoloPresi/${uid}`));
    SoloPresis = () =>( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'SoloPresi'):this.db.ref('SoloPresi'));// this.db.ref('SoloPresi');
    GrupoRoles = () =>( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'GrupoRoles'):this.db.ref('GrupoRoles'));// this.db.ref('GrupoRoles');
    notificaciones = () =>( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+'Notificaciones'):this.db.ref('Notificaciones'));// this.db.ref('Notificaciones');

    notificacion = uid => ( this.codeDespacho ? this.db.ref(this.codeDespacho+'/'+`Notificaciones/${uid}`):this.db.ref(`Notificaciones/${uid}`));// this.db.ref(`Notificaciones/${uid}`);

  }

  
  export default Firebase;