import React,  { Component } from 'react';
import { GoogleMap, LoadScript} from '@react-google-maps/api';
import moment from 'moment';
const mapStyles = {        
  height: "70vh",
  width: "70%"
};


function infoWindow (marker,map){

  window.google.maps.event.addListener(marker,'click',fun => {
   // map.setCenter(marker.getPosition());
    const selected= marker.item
    const textoMar=
   "<div>"+
    " <p  style='font-weight: bold;'> <span style='color:green;'>Centro: </span>"+selected.name+" </p>"
    +(selected.logo ? "<img src="+selected.logo+" width='80px' alt='Logo'/>":"")+
     (selected.descripcion ?
      " <p  style='font-weight: bold;'> <span style='color:green;'>Descripción: </span>"+selected.descripcion+" </p>":"")

   
    + (selected.Entradas&&selected.Entradas.length>0 ?
      " <p style='font-weight: bold;'> <span style='color:green;'>Total Horas: </span>"+
       + (selected.totalHoras ? selected.totalHoras:'PENDIENTE SALIDA' )+"</p>":"")

    +(selected.Entradas ? selected.Entradas.map((entr,index) => (                  
      "<div  >"+
            "<Divider />"+
            " <p style='font-weight: bold;'> <span style='color:green;'>Entrada: </span>"+entr.Entrada+
            "<span style='color:green;'>Salida: </span>"+entr.Salida+" </p>"
     +" </div>")):"")
     +"</div>"

    const infowindow = new window.google.maps.InfoWindow({
      content: textoMar,
    });
    infowindow.open(map, marker);
        
        
    
  });
}

function animateCircle(line,paths,locations,map) {
  const svgMarker = {
    path:
      "M20.5 15h-9c-1.104 0-2 0.896-2 2s0.896 2 2 2h9c1.104 0 2-0.896 2-2s-0.896-2-2-2zM13.583 8l-1.083 6h7l-1.084-6h-4.833zM16 29l1.5-9h-3l1.5 9zM13 7h6c0.828 0 1.5-0.672 1.5-1.5s-0.672-1.5-1.5-1.5h-6c-0.829 0-1.5 0.672-1.5 1.5s0.671 1.5 1.5 1.5z",
    fillColor: "blue",
    fillOpacity: 0.6,
    strokeColor: "blue",
    strokeWeight: 1,
    rotation: 340,
    scale: 2,
    anchor: new window.google.maps.Point(15, 30),
  };
  let count = 0;
  var i = 0
  var recorrida=0
  var  unaVez=false
  const metros= window.google.maps.geometry.spherical.computeLength(line.getPath())
  var ofsetTramo=0
  const  intervalId=window.setInterval(() => {
    count = (count + 1) % 200;
    
    const icons = line.get("icons");
    icons[0].offset = count / 2 + "%";
    const offseActual =count / 200
    line.set("icons", icons);
 //   const position = window.google.maps.geometry.spherical.interpolate(inicio,fin,offseActual)
   
    
    if(i+1<paths.length){
    
      const ini= new window.google.maps.LatLng(paths[i].lat, paths[i].lng)
      const final  =  new window.google.maps.LatLng(paths[i+1].lat, paths[i+1].lng)
      const distancia= window.google.maps.geometry.spherical.computeDistanceBetween(ini, final)
    
      const dis = (distancia+recorrida)/metros

      const disTramo = distancia/metros
  
     if(offseActual+0.005>=dis){
        

        const loc=locations.filter(loca=>
                  loca.location.lng===final.lng()&&loca.location.lat===final.lat())
        const marker =  new window.google.maps.Marker({
          position: final,
          map,
          item:loc[0],
          zIndex:3,
          animation: window.google.maps.Animation.DROP,
        })
        recorrida= recorrida+distancia
        infoWindow(marker,map)
            
        i++
        unaVez=false
        ofsetTramo=offseActual

     }else if(offseActual+0.005-ofsetTramo>=disTramo/2&&!unaVez){
      unaVez=true //Indicamos la etiqueta con el valor del tiempo en ir de un sitio al otro
      const heading = window.google.maps.geometry.spherical.computeHeading(ini,final );
      const marker =  new window.google.maps.Marker({
        position: window.google.maps.geometry.spherical.computeOffset(ini, distancia/2, heading),
        map,
        icon: svgMarker,
       // item:loc[0],
        zIndex:3,
        animation: window.google.maps.Animation.DROP,
      })
      const breakfast = moment(paths[i].Salida,'HH:mm');
      const lunch = moment(paths[i+1].Entrada,'HH:mm');
      const duracion = lunch.diff(breakfast)
      const diffDuration = moment.duration(duracion);
      const infowindow = new window.google.maps.InfoWindow({
        content:  "<div>"+
        " <p style='font-weight: bold;'> <span style='color:green;'>Tiempo Desplazamiento:</span></p>"
        +"<p style='font-weight: bold;'> <span style='color:green;'></span>"+diffDuration.hours()+" horas y "
        +diffDuration.minutes()+" minutos</p>"
          +"</div>",
      });
      infowindow.open(map, marker);
      window.google.maps.event.addListener(marker,'click',fun => {
      //  map.setCenter(marker.getPosition());
        infowindow.open(map, marker);
            
      });

     }
  
      }      
    }, 50);
  return intervalId
}

function addLatLng(event) {

  }

class Mapa2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Entradas:false,
      uid:"",
      selected:[],
      despacho:[],
      abrirOver:true,
      centrosTrabajo:[],
      defaultCenter:{},
      locations:[],
      Polyline1:'',
      offset:'0%',
      paths:[],
      path2:[],
      path3:[],
      options:{}

  }
}

 
 onMapLoad = map => {

    var markers=[]
    const locations= this.state.locations
   locations.forEach((item,index) => {
    const marker= new window.google.maps.Marker({position: item.location,map, item:item
      // animation: google.maps.Animation.DROP,
     })

   
    markers.push(marker);
    infoWindow(marker,map)

   })
   
  
   map.addListener("click", addLatLng);
  

   if(this.state.Entradas){ 
    for (let i = 1; i < markers.length; i++) {
      markers[i].setMap(null);
    }
    markers = [];
  
     
    const lineSymbol = {
      path: window.google.maps.SymbolPath.CIRCLE,
      scale: 8,
      strokeColor: "#393",
    };
    // Create the polyline and add the symbol to it via the 'icons' property.
    
    const line = new window.google.maps.Polyline({
      strokeColor: "blue",
      path:this.state.paths,
      zIndex: 1,
      icons: [
        {
          icon: lineSymbol,
          offset: "100%",
        },
      ],
      map: map,


    });
    const intervalId=animateCircle(line,this.state.paths, this.state.locations,map);

    this.setState({intervalId:intervalId}) 
    } 
    
   
  };

  componentDidMount() {
 
    const despacho = this.props.despacho
    const centrosTrabajo=  this.props.centrosTrabajo

    
   
    const defaultCenter = {
      lat: despacho.CentroTrabajo.lat, lng: despacho.CentroTrabajo.lng
    }  
    var locations = []
    
    var entradas=[]
    centrosTrabajo&&centrosTrabajo.forEach(centro=>{
      const Entradas=centro.Entradas
      var entradanueva=''
      if(Entradas&&Entradas.length>0){
            Entradas.forEach(entrada=>{
              entradanueva= entradanueva + '\nHora Entrada:'+entrada.Entrada+' Hora Salida:'+entrada.Salida
              entradas=entradas.concat({ "lat": Number(centro.Coordenadas.lat), 
                    "lng":  Number(centro.Coordenadas.lng), 
                      "Entrada":entrada.Entrada,"Salida":entrada.Salida})
             
            })
      }
      
  
      
      locations=locations.concat({
        name: centro.Nombre,
        descripcion:centro.Descripcion,
        logo:"",
        totalHoras:centro.totalHoras,
        location: { 
          lat: Number(centro.Coordenadas.lat),
          lng: Number(centro.Coordenadas.lng)
        },
        Entradas:centro.Entradas
        
  
      })
  
    })
    this.setState({ locations:locations})
  
    if(entradas.length>1){
      
      entradas.sort(function (a, b) {
        const EntraA = Number(a.Entrada.split(':')[0])
        const EntraB = Number(b.Entrada.split(':')[0])
        
        if (EntraA> EntraB) {
          return 1;
        }
        if (EntraA < EntraB) {
          return -1;
      
        }
       // a must be equal to b
        return 0; 
      });
      
      
     
      this.setState({Entradas:true})
     
    }


    this.setState({ despacho:despacho,paths:entradas,centrosTrabajo:centrosTrabajo, defaultCenter:defaultCenter})
  }
  componentWillUnmount() {
    window.clearInterval(this.state.intervalId)
  }
 onSelect = item => {

    this.setState({selected:item})
  }
 

 
  render() {
    const defaultCenter=this.state.defaultCenter

  //animateCircle(Polyline1.props.options)
    return (
      <LoadScript  id='mapa'
      libraries={["geometry"]}
       googleMapsApiKey="AIzaSyC0Puy9_5-7Cv8KXP-FBxGnQPFeWIw4GAY"
    >
        <GoogleMap
          mapContainerStyle={mapStyles}
          zoom={12}
          onLoad={map => this.onMapLoad(map)}
          center={defaultCenter}>       
     </GoogleMap>
    </LoadScript>
    )
  }
}

export default React.memo(Mapa2)
