import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import  Cabecera  from '../Cabecera/index_365';
import  BotonTriger from '../Botones/botonTrige';
import Grid from '@material-ui/core/Grid';
import { withAuthorization } from '../Session';
import { withRouter } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Copyright from '../Privacidad/copyright'
let images = [
  {
    url: 'https://source.unsplash.com/featured/?maintenance,home',
    title: 'INCIDENCIAS',
    width: '30%',
    href:ROUTES.INCI
  },
  {
    url: 'https://source.unsplash.com/featured/?file,carpter',
    title: 'DOCUMENTOS',
    width: '30%',
    href: ROUTES.DOCUMENTOS
  },
  ]; 
let images2 = [
  {
    url: 'https://source.unsplash.com/featured/?master,data',
    title: 'DATOS MAESTROS',
    width: '30%',
    href: ROUTES.MASTER
  },
  {
    url: 'https://source.unsplash.com/featured/?meeting,virtual',
    title: 'JUNTAS VIRTUALES',
    width: '30%',
    href: ROUTES.JUNTASVIRTUALES
  },
  {
    url: 'https://source.unsplash.com/featured/?data,protection',
    title: 'LOPD',
    width: '30%',
    href: ROUTES.LOPD
  },
];
let images3 = [
  {
    url: 'https://source.unsplash.com/featured/?master,data',
    title: 'DATOS MAESTROS',
    width: '30%',
    href: ROUTES.MASTER
  },
  {
    url: 'https://source.unsplash.com/featured/?meeting,virtual',
    title: 'JUNTAS VIRTUALES',
    width: '30%',
    href: ROUTES.JUNTASVIRTUALES
  },

];

let images4 = [
  {
    url: 'https://source.unsplash.com/featured/?maintenance,home',
    title: 'INCIDENCIAS',
    width: '30%',
    href:ROUTES.INCI
  },
  {
    url: 'https://source.unsplash.com/featured/?users,data',
    title: 'USUARIOS',
    width: '30%',
    href: ROUTES.USUARIOS
  },

];

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 300,
    width: '100%',
  },
  image: {
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('xs')]: {
      width: '50%', // Overrides inline-style
      height: 100,
    },
    '&:hover, &$focusVisible': {
      zIndex: 1,
      '& $imageBackdrop': {
        opacity: 0.15,
      },
      '& $imageMarked': {
        opacity: 0,
      },
      '& $imageTitle': {
        border: '4px solid currentColor',
      },
    },
  },
  focusVisible: {},
  imageButton: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
  },
  imageBackdrop: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
  },
  imageTitle: {

    position: 'relative',
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  },
}));


function ButtonBases(props) {
  const classes = useStyles();
  const usuarioLogueado=props.usuarioLogueado

  if(usuarioLogueado.SuperUser){
  const esta = images.find(im=>im.title==='SUPERUSER')
   const concatenar=  {
      url: 'https://source.unsplash.com/featured/?master',
      title: 'SUPERUSER',
      width: '30%',
      href: ROUTES.SUPERUSER
    }
    if(!esta){
      images=images.concat(concatenar)

    }
    const esta2 = images4.find(im=>im.title==='SUPERUSER')
    if(!esta2){
    images4=images4.concat(concatenar)
    }

  }

  return (
    <Grid >   <Cabecera/>    <hr/>  <BotonTriger/>
    <Grid  container  justifyContent="space-evenly"  alignItems="center" >
    <Grid  container   direction="row"  justifyContent="space-evenly"  alignItems="center">
    
      {props.despacho.Tipo!=="Servicios"&&
      images.map(image => (
        <ButtonBase href={image.href}
          focusRipple
          key={image.title}
          className={classes.image}
          focusVisibleClassName={classes.focusVisible}
          style={{
            width: image.width,
          }}
        >
          <span
            className={classes.imageSrc}
            style={{
              backgroundImage: `url(${image.url})`,
            }}
          />
          <span className={classes.imageBackdrop} />
          <span className={classes.imageButton}>
            <Typography
              component="span"
              variant="subtitle1"
              color="inherit"
              className={classes.imageTitle}
            >
              {image.title}
              <span className={classes.imageMarked} />
            </Typography>
          </span>
        </ButtonBase>
      ))}
      </Grid>
      <hr/> 
    <Grid  container   direction="row"  justifyContent="space-evenly"  alignItems="center" >
    </Grid>
    
    {props.despacho.Tipo!=="Servicios"&&!props.sin&&images2.map(image => (
      <ButtonBase href={image.href}
        focusRipple
        key={image.title}
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
        style={{
          width: image.width,
        }}
      >
        <span
          className={classes.imageSrc}
          style={{
            backgroundImage: `url(${image.url})`,
          }}
        />
        <span className={classes.imageBackdrop} />
        <span className={classes.imageButton}>
          <Typography
            component="span"
            variant="subtitle1"
            color="inherit"
            className={classes.imageTitle}
          >
            {image.title}
            <span className={classes.imageMarked} />
          </Typography>
        </span>
      </ButtonBase>
    ))}
   
   {props.despacho.Tipo!=="Servicios"&&props.sin&&images3.map(image => (
      <ButtonBase href={image.href}
        focusRipple
        key={image.title}
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
        style={{
          width: image.width,
        }}
      >
        <span
          className={classes.imageSrc}
          style={{
            backgroundImage: `url(${image.url})`,
          }}
        />
        <span className={classes.imageBackdrop} />
        <span className={classes.imageButton}>
          <Typography
            component="span"
            variant="subtitle1"
            color="inherit"
            className={classes.imageTitle}
          >
            {image.title}
            <span className={classes.imageMarked} />
          </Typography>
        </span>
      </ButtonBase>
    ))}

{props.despacho.Tipo==="Servicios"&&images4.map(image => (
      <ButtonBase href={image.href}
        focusRipple
        key={image.title}
        className={classes.image}
        focusVisibleClassName={classes.focusVisible}
        style={{
          width: image.width,
        }}
      >
        <span
          className={classes.imageSrc}
          style={{
            backgroundImage: `url(${image.url})`,
          }}
        />
        <span className={classes.imageBackdrop} />
        <span className={classes.imageButton}>
          <Typography
            component="span"
            variant="subtitle1"
            color="inherit"
            className={classes.imageTitle}
          >
            {image.title}
            <span className={classes.imageMarked} />
          </Typography>
        </span>
      </ButtonBase>
    ))} 
    </Grid>

  
      <Box mt={10}>
              <Copyright />
            </Box>
    </Grid>
  );
}

const condition = authUser => !!authUser;
export default  withRouter (withFirebase (withAuthorization(condition)(ButtonBases)));