import React, { Component, useRef } from 'react';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import Box from '@material-ui/core/Box';

import CircularProgress from '@material-ui/core/CircularProgress';
import MaterialTable , {MTableBody}from 'material-table'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import BotonesTriger from '../Botones/botonTrige'
import Cabecera from '../Cabecera/index_365'


import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';

import { createTheme  } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import  green from '@material-ui/core/colors/green';

import  firebase2  from 'firebase'
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import  { Breakpoint} from 'react-socks';
import { CSVReader } from 'react-papaparse'
import Copyright from '../Privacidad/copyright';
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const theme = createTheme ({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
      claro: green[200]
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#e81446',
    },
  },
});
const buttonRef = React.createRef()
class Gremios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mostrarAyuda:false,
      abrir_vista_previa:false,
      uid:"",
      direccion:"",
      resumen_carga:false, 
      resumen_lista:[],
      añadir: true,
      incis: [],
      comus: [],
      open: false,
      comunidad:'', 
      tipo:'guardar',
      Id:'',
      mostrarTabla: false,
      selectedIndex:'',
      email: '',
      gremioBorrar:"",
      password: '',
      error: null,
      error2: null,
      modificar: true,
      tenemosLista:false,
      snack:false, snack2:false, vertical: 'top',
      horizontal: 'center', 
      seguro : false,  
      deshabilitar:false,
      actividades:[],
      gremios:[],
      cargando:false,
      lista_actividades:{},
      ficheroCsv:[]
  };
    this.BuscarGrem= this.BuscarGrem.bind(this);
 
    this.borrarFiltros = this.borrarFiltros.bind(this);
   
    this.buscarGremios = this.buscarGremios.bind(this);
    this.comprobarExisteGremio = this.comprobarExisteGremio.bind(this)
    this.handleClose =     this.handleClose.bind(this)
    this.anularGremio = this.anularGremio.bind(this)
    this.filtrarComunidad= this.filtrarComunidad.bind(this)
    this.filtrarActividades= this.filtrarActividades.bind(this)
    this.buscarComunidades=this.buscarComunidades.bind(this)
    this.ActualizarRegistroComunidad = this.ActualizarRegistroComunidad.bind(this)

  }

  filterRef () {return ( React.createRef(""))}
  handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.open(e)
    }
  }
  handleOnDrop = (data) => {
 
    var listaGremios=[]
    data.forEach(gremio =>{
      if(gremio.data[0]&&gremio.data[0]!==''){
        listaGremios = listaGremios.concat({Nombre:gremio.data[0], Actividad:gremio.data[1],email:gremio.data[2],
          Seguro:gremio.data[3], Telefono1:gremio.data[4], Telefono2:gremio.data[5],Direccion:gremio.data[6]     })
    
      }
    
    })
    
    this.setState({abrir_vista_previa:true, ficheroCsv:data,ficheroList:listaGremios})
    console.log('---------------------------')
  }
  handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
    alert(err,file,inputElem,reason)
  }
  handleOnRemoveFile = (data) => {
  
    console.log(data)
  }
  cerrarVistaPrevia = ()=> {
   // this.handleRemoveFile(this.state.ficheroCsv) 
   if(!this.state.cargando){
    this.setState({abrir_vista_previa:false, ficheroCsv:[]})
   }                                   
  }
  cerrarResumenCarga = ()=> {
    // this.handleRemoveFile(this.state.ficheroCsv) 
     this.setState({abrir_vista_previa:false, ficheroCsv:[], resumen_carga:false, resumen_lista:[]})
                                       
  }
 
borrarGremios= (data) => {
  this.setState({open:true})
    const gremios = data

    this.setState({ cargando:true})
    const getData = async () => {
      return Promise.all(gremios.map(gremio =>{ 
        return  new Promise((resolve, reject)=> {
                resolve(  this.anularGremio(gremio))
                                        
          })
      }))
  
    }

    getData().then(data => {
        this.setState({snack2:false, snack:true, error2:"Los gremios han sido borrados" });  
        this.BuscarGrem();
    
        
        this.setState({open:false})
        
    })
  
  
}

cargarGremios= (data) => {
  const gremios = data
  this.setState({ cargando:true})
  const getData = async () => {
    return Promise.all(gremios.map(newData => this.cargarGremio(newData,gremios)))
  }
    
  getData().then(data => {
       
      this.setState({abrir_vista_previa:false,cargando:false, ficheroCsv:[], resumen_carga:true, resumen_lista:data})
      this.setState({open:false})
      
  })




}

 cargarGremio(newData, gremios){
  var primero = false;
  return  new Promise((resolve, reject)=> {
    var Aviso=""

    
    const melon=gremios.filter(gremio => gremio.Nombre===newData.Nombre)
    if(melon.length>1){
      if(primero){
        return(resolve({Error:"KO: El Gremio ya existe "+ newData.Nombre}))

      }else{primero=true}

    } 
 
    if(!newData.Nombre || newData.Nombre ===''){
      return(resolve({Error:"KO: El campo Nombre del gremio no puede ser vacío," +newData}))
    }
//       if(!newData.email ||newData.email ===''){
//       return(resolve({Error:"KO: El campo Correo del gremio no puede ser vacío," +newData.Nombre}))
 //   }
    if(!newData.Telefono1 ||newData.Telefono1 ===''){
      return(resolve({Error:"KO: El campo Teléfono Principal del gremio no puede ser vacío," +newData.Nombre}))
    }


   
    var resul={}
    let resultado ='ko'
    //preparamos el  vector de actividades
    if(newData.Actividad){
      const actividades = newData.Actividad.split(',')
      
     var i=0
     actividades.forEach(actividad => {
          if(actividad&&actividad!==""){
            const actv= this.state.activs.filter(act => act.Id===actividad)
            if(actv.length>0){
              resul[i]= actv[0].Nombre   
              i++              
              resultado='ok'
            }else {Aviso = Aviso.concat("- La actividad "+ actividad+" no existe - ")}

          }
        })  
    }                                          
     if(resultado==='ko'){resul={0:""}}
     if(!newData.Telefono2){newData.Telefono2=""}
     if(!newData.email){newData.email=""}
     if(!newData.Direccion){newData.Direccion=""}
      var seguro= false
     if (newData.Seguro==="true"){seguro=true}

     const gremu=this.comprobarExisteGremio(newData.Nombre)
     if(gremu.length> 0 ){
       
       this.props.firebase.gremio(gremu[0].uid).update({
        Nombre: newData.Nombre,
        email: newData.email,  
        Actividades: resul,                                      
        Id:firebase2.database.ServerValue.TIMESTAMP,
        Telefono1:newData.Telefono1,
        Telefono2:newData.Telefono2,
        Direccion: newData.Direccion,
        Seguro:seguro,
        Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
      }).then((grem)=>{
        resolve({Error:"OK, Actualizado correctamente," +gremu[0].uid + " "+ Aviso})
      })                        


      // return(resolve({Error:"KO: El Gremio ya existe "+ newData.Nombre}))
    }else{

      this.props.firebase.gremios().push({
        Nombre: newData.Nombre,
        email: newData.email,  
        Actividades: resul,                                      
        Id:firebase2.database.ServerValue.TIMESTAMP,
        Telefono1:newData.Telefono1,
        Telefono2:newData.Telefono2,
        Direccion: newData.Direccion,
        Seguro:seguro,
        Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
      }).then((grem)=>{
        resolve({Error:"OK, Cargado correctamente," +grem.key + " "+ Aviso})
      })

    }
  
                              
})


}

handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e)
    }
  }
handleClose () {
  this.setState({snack:false, snack2:false})
 };
buscarComunidades(){
  this.props.firebase.comus().on('value', snapshot => {
    const usersObject = snapshot.val();
    if(usersObject){
    const comusList = Object.keys(usersObject).map(key => ({
      ...usersObject[key],
      uid: key,
    }));
    this.setState({comus: comusList});
  }else{this.setState({comus: []});}
  });
  
  }
componentWillMount(){
    this.props.firebase.gremios().off()
    this.props.firebase.comus().off()
    this.props.firebase.activs().off()
  }
componentDidMount() {
    this.buscarGremios()
 
  this.props.firebase.activs().on('value', snapshot => {
    const usersObject = snapshot.val();
    const actividadList = Object.keys(usersObject).map(key => ({
      ...usersObject[key],
      uid: key,
    }));
    actividadList.sort(function (a, b) {
      if (a.Nombre> b.Nombre) {
        return 1;
      }
      if (a.Nombre < b.Nombre) {
        return -1;
      }
     // a must be equal to b
      return 0;
    });
    let lista_actividades={}
    actividadList&&actividadList.forEach(actividad =>{
      const id  = actividad.Nombre;
      //quitmaos las actividades con id -1 y la de seguros porque no aplica a las  activdades de Incidencias
      if (id!== '' && id!=='SEGUROS')  {lista_actividades[ id ] = id}
      
     } )
    this.setState({activs: actividadList, lista_actividades:lista_actividades });
    this.buscarComunidades(); 
    this.setState({activs: actividadList });

  })
}
buscarGremios(){
    this.props.firebase.gremios().once('value', snapshot => { 
    const usersObject = snapshot.val();
    if (usersObject){
      var gremiosList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));
      gremiosList.sort(function (a, b) {
        if (a.Nombre> b.Nombre) {
          return 1;
        }
        if (a.Nombre < b.Nombre) {
          return -1;
          
        }
      // a must be equal to b
        return 0;
      });
      //Quitamos los borrados
      gremiosList = gremiosList.filter(gremio => !gremio.Borrado )
      this.setState({
        gremios: gremiosList,
      });
      //quitamos las de id  negativo -1
      
      this.filtrarGremios();
      this.setState({ mostrarTabla: true, open:false });  
    }else{   this.setState({ mostrarTabla: true, open:false });  }
   
  });
}
BuscarGrem(){
    this.setState({open:true, mostrarTabla: false, añadir: true, tenemosLista:false,deshabilitar:false }) 
    this.borrarFiltros();
    this.buscarGremios();
  
    //this.traerActividades();
   
} 
borrarFiltros(){ 
        this.setState({gremios:[], nombre:'', email:'', Id:'', telefono1:'',telefono2:'', uid:'', direccion:'',
        tenemosLista:false, nombreInicial:'', seguro:false });
}
  filtrarGremios(){
    //quitamos las de código negativo
    var gremios = this.state.gremios.filter(gremio => gremio.uid !== -1);
   
    gremios.forEach(function(gremio) {
      gremio.Actividades = gremio.Actividades.filter(actividad =>actividad !== "")

    } )

    this.setState({gremios:gremios})
  }
  filtrarComunidad(listaComunidades){;
    var comunidadesAFiltrar=listaComunidades;
    var comunidades = this.state.comus.filter(comunidad => comunidad.uid >= 0 );
    var comunidades2 =this.state.comus.filter(comunidad => comunidad.uid >= 0 );

    if(!this.state.comus.empty){
      if(comunidadesAFiltrar){
          comunidades= comunidades.filter(comunidad => comunidad.Id ===comunidadesAFiltrar[comunidad.Id] );
          if (!comunidades.empty){    
                comunidades2= comunidades2.filter(comunidad =>
                comunidad.Id !==comunidadesAFiltrar[comunidad.Id] );
                this.setState({left: comunidades, right: comunidades2, tenemosLista:true, leftChecked : [],
                  rightChecked:[], checked:[]})
          }
      }else{
        this.setState({left:[], right: comunidades2, tenemosLista:true, leftChecked : [],
        rightChecked:[], checked:[]}) 
      }
        
    }
    return true
  }  
  filtrarActividades(listaActividades){
  
    var ActividadesaFiltrar=listaActividades;
    var actividades = this.state.activs.filter(actividad => actividad.uid !== -1  );
    var actividades2 =this.state.activs.filter(actividad => actividad.uid !== -1 );
    var listafinalLeft=[]
   
    if(ActividadesaFiltrar&&ActividadesaFiltrar!==''){
        const gremiosList = Object.keys(ActividadesaFiltrar).map(key => ({
        ...ActividadesaFiltrar[key],
      
      }));
      const result10 = Object.entries(gremiosList[0]).map(([key, val]) => ({ "Actividades": val ,  }));    
      result10.forEach(function(value) {
          actividades= actividades.filter(actividad => actividad.uid === value.Actividades)      
          if (actividades.length!==0){ listafinalLeft= listafinalLeft.concat(actividades)  }
          actividades =actividades2 
         
      });
      if(listafinalLeft&&listafinalLeft.length>0){
            actividades =actividades2
            listafinalLeft.forEach(function(value) {
              actividades= actividades.filter(
                actividad => actividad.uid !==  value.uid)      
        
            });
      }
   }
    this.setState({left2:listafinalLeft, right2:actividades , tenemosLista:true, leftChecked : [],
        rightChecked:[], checked:[]}) 
  }
  ActualizarRegistroComunidad(nombre, listaActividades){
 //traer lista actual de la comuninad Actividads. Gremios.
 
  const Gremio = nombre;
  const comunidad = this.state.left[0].Id;
  const clave = this.state.comus.filter(comunidad => comunidad.Id===this.state.left[0].Id)
  const root =clave[0].uid+"/ActivGremios";
  const listaActividades1 =listaActividades;
  const uidGremio = this.state.uid;
  const GremioBorrar= this.state.left2;
  var melona=''
  const p1 =new Promise(resolve => {
    resolve(
      this.props.firebase.comu(root).once('value', snapshot => {
          
            snapshot.forEach((change) => {                
              melona =change.val();
              if(melona===uidGremio){ 
                  this.props.firebase.comu(root).update({[change.key] :"-1",  })
            //      this.props.firebase.gremio(root).update({  [primeraComunidad] : resul2,

                      
              }             
            //borramos la asociacion del antiguo gremio con la comunidad
               else{ GremioBorrar.forEach((actividad) => { 
              
                              if(actividad.uid === change.key) {
                                alert('El actual gremio asociado a la comunidad '+comunidad+
                                'y actividad'+actividad.Nombre+' será sustituido por el Gremio' +Gremio ) 
                                
                                 let root2 =change.val()+"/Actividades/"+comunidad+"/"+change.key 
                                this.props.firebase.gremio(root2).remove() 
                              }

                });
              }
      

            })
              

//              GremioBorrar= GremioBorrar.filter( gremio => gremio. )
          
              

    }))
  }) // ACtualizamos las nuevas actividades delgremio
 p1.then((registro) => {
      var updates={}
      listaActividades1.forEach((change) => { updates[change.uid] = uidGremio;  });
       this.props.firebase.comu(root).update( updates)
       this.setState({snack:true, 
        error2:"El registro de "+this.state.nombre+" ha sido actulizado correctamente" });  

        this.BuscarGrem()

      })
  }                     
  comprobarExisteGremio(gremioN) {
    var existe=[];
    if(this.state.gremios){
      existe = this.state.gremios.filter(gremio => gremio.Nombre===gremioN )
    }
      return existe;       
    }
  onChange = event => {
  this.setState({ [event.target.name]: event.target.value })
 
  };
anularGremio(data) {
  return new Promise(resolve => {
      const uidGremio=data.uid
      resolve(this.state.comus.forEach((comunidad) => {
        this.props.firebase.comu(comunidad.uid+"/ActivGremios/").once('value', snapshot => {
          snapshot.forEach((registro) => {
          //    const melona = registro.key
              const melon = registro.val()
              if (melon===uidGremio) {
                this.props.firebase.comu(comunidad.uid+"/ActivGremios/").update({[registro.key] :"-1",  })
                this.props.firebase.comu(comunidad.uid+"/ActivGremioAuxiliar/").update({[registro.key] :"-1",  })
                
              }
          });
          this.props.firebase.gremio( uidGremio).remove()
    
    
        })    
       })
    
      
      )
    
  
  })
  }
  checkEnSeguro = (oldData) => () =>{
  var newData= oldData
  var data = this.state.gremios;
  
    if(!oldData.Seguro&&window.confirm('¿Quieres que el Gremio pase a ser del Tipo compañía de Seguros?')){
        newData.Seguro = true;       
        data[data.indexOf(oldData)] = newData;
        this.setState({gremios:data }); 
        new Promise(resolve => {
          setTimeout(() => {
              resolve();
                this.props.firebase.gremio(oldData.uid+'/').update({ 
               
                  Fecha_Modificacion:firebase2.database.ServerValue.TIMESTAMP,
                  Seguro: true      
              })
          }, 1);
          }).then(() => { this.componentDidMount()})

    }
    else if(oldData.Seguro&&window.confirm('¿Quieres que el Gremio deje de ser del Tipo compañía de Seguros?')){
    
      newData.Seguro = false;      
      data[data.indexOf(oldData)] = newData;
      this.setState({gremios:data }); 
      new Promise(resolve => {
        setTimeout(() => {
            resolve();
              this.props.firebase.gremio(oldData.uid+'/').update({ 
             
                Fecha_Modificacion:firebase2.database.ServerValue.TIMESTAMP,
                Seguro: false    
            })
        }, 1);
        }).then(() => { this.componentDidMount()})

      
  }

  
  }
  handleChangeAct = (oldData) => (event) => {
  var newData= oldData
//  this.setState({actividades:event.target.value})
  var data = this.state.gremios;
  newData.Actividades = event.target.value;
  newData.Actividades = newData.Actividades.filter(actividad => actividad!=='');
  oldData.Actividades = newData.Actividades
 // const index3 = data.findIndex(item => item.Id === oldData.Id)
  //data[index3] = newData;
  this.setState({gremios:data }); 

  //  setPersonName(event.target.value);
  }
  render() {

   const { mostrarTabla } = this.state;
   const vertical= this.state.vertical;
   const horizontal= this.state.horizontal;
   return (     
      <Grid >
        <Cabecera/>
         <hr/>
         <Breakpoint large up>
         <Grid style={{ position: 'absolute', right: 20, top: 20 }}>
           
          <Button variant="contained" color="primary" onClick={() => { this.setState({ mostrarAyuda: true }) }}>
            AYUDA (TUTORIALES)
          </Button>
         
        </Grid> 
        </Breakpoint>
        <BotonesTriger/>      
        <ThemeProvider theme={theme}> 
        
        <Grid >  
            <Grid  container
                direction="row"
                justifyContent="space-between"
                alignItems="center">
                
                  <Grid style={{marginLeft:10}}>

                        <Button onClick={this.BuscarGrem} variant="outlined"  
                        size="large" color="primary"  startIcon={<SearchIcon />}>
                                            LISTADO DE GREMIOS
                        </Button>
                      
                  </Grid>
      

                  
                <Breakpoint medium down>

                        
                        <Button variant="contained" color="primary" onClick={() => { this.setState({ mostrarAyuda: true }) }}>
                          AYUDA (TUTORIALES)
                        </Button>

                      </Breakpoint>
                  
                  {this.state.mostrarTabla&&<Grid  style={{height:'20%',  marginRight:10, marginTop:0}}>
                      <Breakpoint large up>
                        <h5 style={{color:'green', textAlign:'center', alignSelf:'center', marginBottom:0}}>SUBIR GREMIOS FICHERO</h5>
                        <CSVReader
//                              ref={buttonRef}
                                onDrop={this.handleOnDrop}
                                onError={this.handleOnError}
                                style={{ }}
                                config={{}}
                                addRemoveButton
                                onRemoveFile={this.handleOnRemoveFile}
                              >
                                <span style={{textAlign:'center'}}>Arrastra el fichero CSV aquÍ o haz click para abrir el diálogo.</span>
                      
                      </CSVReader>
                      </Breakpoint>
                  </Grid>}
                 
            </Grid>
            {mostrarTabla&&
           
                  <Paper style={{width:'100%', overflowX:'auto', margin:10}}>                           
                            <MaterialTable 
                             components={{
                              Body: props => <MTableBody {...props} onFilterChanged={(columnId, value) => {
                                  props.onFilterChanged(columnId, value);
                                  const field= props.columns[columnId].field
                                  this.filterRef[field] = value
                              }} />
                              }}
                               options={{
                                doubleHorizontalScroll:true,
                                exportButton: {
                                  csv: true,
                                  pdf: false
                                },
                                addRowPosition:'first',
                                maxBodyHeight: "80vh",
                                selection: true,   
                                filtering: true,
                                pageSize: 50,  
                                exportDelimiter: ";",
                                exportAllData:true,
                                pageSizeOptions:	[50, 70, 100],
                                headerStyle: {
                                  backgroundColor: 'green',
                                  color: 'white',
                                  paddingTop:5,
                                  paddingBottom:5},
                                        
                              }}
                              actions={[
                                {
                                  tooltip: 'Borrar gremios seleccionados',
                                  icon:  () => <DeleteOutline />,
                                  onClick: (evt, data) => 
                                    this.borrarGremios(data)
                                }
                              ]}
                              
                              icons={tableIcons}
                              localization={
                                { body: { editRow:
                                 { deleteText: '¿Quieres borrar el gremio?' } 
                                },   
                                header: {
                                  actions: 'Acciones'                        
             
                                },
                                toolbar: {
                                  nRowsSelected: '{0} Gremio(s) seleccionados para ser borrados'
                              },}}
                              title={ <h2  style={{ color: 'green' }}>GREMIOS</h2>}

                              columns={[
                                {title: 'Nombre', field: 'Nombre', defaultFilter: this.filterRef['Nombre']},
                              //   { title: 'Identificador', field: 'Uid', editable: 'never' },
                                {title: 'Actividad', field: 'Actividades', defaultFilter: this.filterRef['Actividades'],//lookup: this.state.lista_actividades,
                              
                              
                                render: rowData =><Select
                                      labelId="demo-mutiple-chip-label"
                                //      disabled={true}
                                      multiple
                                      value={rowData.Actividades}
                                      renderValue={(selected) => (
                                        <div style={{  display: 'flex', flexWrap: 'wrap',}}>
                                          {selected.map((value) => (
                                            <Chip variant="outlined" color='primary' key={value} label={value} style={{margin:6}}/>
                                          ))}
                                        </div>
                                      )}>
                                  </Select>,
                                  editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
                                    if (!rowData.Actividades|| rowData.Actividades.length===0){ 
                                      rowData.Actividades=[]
                                      rowData.Actividades[0]=""}
                                    return(
                                      
                                        <FormControl>
                                           <Select
                                                  multiple
                                                  value={rowData.Actividades}
                                                  onChange={e => {
                                                    var newRowData = { ...rowData, Actividades: e.target.value };
                                                    newRowData.Actividades = e.target.value 
                                                    if(newRowData.Actividades.length>1){
                                                      newRowData.Actividades=newRowData.Actividades.filter((rol)=>rol!=='')
                                                    }else {  newRowData.Actividades.concat({0:''})}
                                                    
                                               
                                                    onRowDataChange(newRowData);
                                                  }
                                                } renderValue={(selected) => (
                                                    <div style={{  display: 'flex', flexWrap: 'wrap',}}>
                                                      {selected.map((value) => (
                                                        <Chip variant="outlined" color='primary' key={value} label={value} style={{margin:6}}/>
                                                      ))}
                                                    </div>
                                                  )}
                                                  
                                                >
                                                   {this.state.activs.map((actividad) => (
                                                    <MenuItem key={actividad.Nombre} value={actividad.Nombre} >
                                                      {actividad.Nombre}
                                                    </MenuItem>
                                                  ))}
                                   
                                                    
                                                </Select>
                                              </FormControl>
                                  )}
                                },
                     
                                   { title: 'Correo', field: 'email', defaultFilter: this.filterRef['email'] },
                                
                                   { title: "Es Compañia de Seguros",
                                    field: "Seguro",defaultFilter: this.filterRef['Seguro'],
                                    type: "boolean",
                                    render: rowData =>
                                    <Checkbox                                    
                                    checked={rowData.Seguro}
                                    color= "primary"
                          
                                    />,
                                    editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                                      <Checkbox
                                        {...p}
                                        checked={rowData.Seguro}
                                        color= "primary"
                                        onChange={e => {
                                           const newRowData = { ...rowData, Seguro: e.target.checked };
                                          rowData.Seguro = e.target.checked 
                                          onRowDataChange(newRowData);
                                        }}
                                        />
                                      )
                                 },   
                                { title: 'Teléfono Principal', field: 'Telefono1', defaultFilter: this.filterRef['Telefono1'] },
                                { title: 'Teléfono Secundario', field: 'Telefono2', defaultFilter: this.filterRef['Telefono2'] },
                                { title: 'Dirección', field: 'Direccion', defaultFilter: this.filterRef['Direccion'] }
                          
                              ]}
                              data={this.state.gremios}
                            
    
                              editable={{
               
                                onRowAdd: newData =>
                                new Promise((resolve,reject) => {
                                  setTimeout(() => {
                  
                                      if(this.comprobarExisteGremio(newData.Nombre).length> 0 ){
                                        alert("Este Gremio ya existe utiliza otro Nombre"); reject();return}
                                      if(!newData.Nombre || newData.Nombre ===''){
                                        alert("El campo Nombre del gremio no puede ser vacío"); reject(); return}
                               //       if(!newData.email ||newData.email ===''){alert("El campo Correo del gremio no puede ser vacío"); return null}
                                      if(!newData.Telefono1 ||newData.Telefono1 ===''){
                                        alert("El campo Teléfono Principal del gremio no puede ser vacío");  reject(); return}
                                       if(!newData.Telefono2){newData.Telefono2=""}
                                       if(!newData.email){newData.email=""}
                                       if(!newData.Direccion){newData.Direccion=""}
                                      this.props.firebase.gremios().push({
                                        Nombre: newData.Nombre,
                                        email: newData.email,  
                                        Actividades: newData.Actividades,                                      
                                        Id:firebase2.database.ServerValue.TIMESTAMP,
                                        Telefono1:newData.Telefono1,
                                        Telefono2:newData.Telefono2,
                                        Direccion: newData.Direccion,
                                        Seguro:(newData.Seguro ? newData.Seguro:false ),
                                        Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
                                      }).then(() => { 
                                        if(!newData.Seguro ){newData.Seguro=false}
                                      
                                        var data =this.state.gremios;                        
                                        data =[newData].concat(data);
                                        this.setState({ gremios: data });
                                        alert('Registro de gremio creado correctamente')
                                        resolve(true)
                                                  // thi
                                        })
                                        .catch(function(error) {
                                                    alert(error.message)     
                                                    reject(); return
                                     
                                                  });
                                    }, 1)
                              })
                              ,
                            onRowUpdate: (newData, oldData) =>
                            new Promise((resolve,reject) => {
                                setTimeout(() => {
                                    if (oldData) {
                                      if(this.comprobarExisteGremio(newData.Nombre).length> 0
                                        && newData.Nombre !== oldData.Nombre ){alert("Este Gremio ya existe utiliza otro Nombre");  reject(); return}
                                      if(newData.Nombre ===''){alert("El campo Nombre del gremio no puede ser vacío"); reject(); return}
                                    //  if(newData.email ===''){alert("El campo Correo del gremio no puede ser vacío"); return null}
                                      if(newData.Telefono1 ===''){alert("El campo Telefon Principal del gremio no puede ser vacío");  reject(); return}
                                     
                                     
                                      this.props.firebase.gremio(newData.uid+'/').update({
                                      
                                        Nombre: newData.Nombre,
                                        Actividades : newData.Actividades,
                                        Direccion: newData.Direccion,
                                        Fecha_Modificacion:firebase2.database.ServerValue.TIMESTAMP,
                                        email: newData.email,
                                        Telefono1: newData.Telefono1,
                                        Telefono2: newData.Telefono2,
                                        Seguro: newData.Seguro
                                      
                                      }).then(() => { 
                                  
                                  
                                      var data = this.state.gremios;
                                      data[data.indexOf(oldData)] = newData;
                                      this.setState({gremios:data }); 
                                    
                            
                                        alert('Registro de gremio actualizado correctamente')
                                      resolve(true)
                                                // thi
                                      })
                                                .catch(function(error) {
                                                  alert(error.message)     
                                                  reject(); return
                                   
                                                });
                                                       
                              
                                                       
                                  }else {reject(); return}
                              
                                    
                             
                                  }, 1)
                            })
                            ,
                                
                            onRowDelete: oldData =>
                            new Promise(resolve => {
                              const uidGremio=oldData.uid
                              resolve(this.state.comus.forEach((comunidad) => {
                            
                                this.props.firebase.comu(comunidad.uid+"/ActivGremios/").once('value', snapshot => {
                                  snapshot.forEach((registro) => {
                                      const melon = registro.val()
                                      if (melon===uidGremio) {
                                        this.props.firebase.comu(comunidad.uid+"/ActivGremios/").update({[registro.key] :"-1",  })
                                        this.props.firebase.comu(comunidad.uid+"/ActivGremioAuxiliar/").update({[registro.key] :"-1",  })
                                        
                                      }
                                  });
                                  this.props.firebase.gremio(uidGremio).remove()
                            
                            
                                })    
                               })
                            
                              
                              )
                            
                          
                          }).then((data)=> {
                                this.componentDidMount()
                                alert('Registro borrado correctamente')
                                })
                          }}
                              
                              
                            />

                     
                  </Paper>
             }

      
        </Grid>
        <Snackbar         anchorOrigin={{ vertical , horizontal }}
                          key={`${this.state.vertical},${this.state.horizontal}`}
                          open={this.state.snack}
                          autoHideDuration={5000}
                          onClose={this.handleClose}
                          message={this.state.error2}
                          action={
                            <div>
                              <Button color="primary" size="small" onClick={this.handleClose}>
                                Cerrar
                              </Button>
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={this.handleClose}
                              >
                                <CloseIcon/>
                              </IconButton>
                            </div>
                          }         />
  
                <Modal
                                                      aria-labelledby="transition-modal-title"
                                                      aria-describedby="transition-modal-description"
                                                      style={{display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center'}}
                                                      
                                                      open={this.state.abrir_vista_previa}
                                                      onClose={this.cerrarVistaPrevia}
                                                    
                                                    >
                   <Paper style={{minHeight:90, maxHeight: 600,  overflow: 'auto',
                    marginLeft:20, marginRight:20,marginTop:20, backgroundColor:'lightgreen'}}>
                    {this.state.cargando&&
                      <div style={{ width: '95%', height:'95%', backgroundColor:'transparent'}}>
                    
                   
                        <CircularProgress size={80}/>

                      </div>
                      }
                        {!this.state.cargando&&
                       <MaterialTable
                                 icons={tableIcons}
                                 options={{
                                     doubleHorizontalScroll:true,
                                     exportButton: {
                                      csv: true,
                                      pdf: false
                                    },
                 
                                 selection:true,
                                 pageSize: 50,  
                        //         grouping: true,
                                 pageSizeOptions:	[5, 10, 20],
                                 headerStyle: {
                                   backgroundColor: 'green',
                                   color: 'white'},
                                   paddingTop:5,
                                   paddingBottom:5
                                         
                               }}
                               localization={{ 
                         
                                toolbar: {
                                  nRowsSelected: '{0} Registro(s) seleccionados para ser CARGADOS'
                              },
                              }}
                        
                              actions={[
                                {
                                  tooltip: 'Crear los gremios seleccionados',
                                  icon:  () => <AddBox />,
                                  onClick: (evt, data) => 
                                    this.cargarGremios(data)
                                }
                              ]}
                               
                            
                        //                 <h5  style={{ color: 'green' }}>{this.state.nombreyapellidos}</h5>
                         
                               title={ <h2  style={{ color: 'green' }}>VISTA PREVIA CARGAR GREMIOS </h2>}
 
                               columns={[
                                {title: 'Nombre', field: 'Nombre'},
                                {title: 'Actividad', field: 'Actividad'},
                                {title: 'Correo', field: 'email'},
                                {title: 'Seguro', field: 'Seguro'},
                                {title: 'Telefono', field: 'Telefono1'},
                                {title: 'Telefono Seccundario', field: 'Telefono2'},
                                {title: 'Dirección', field: 'Direccion'},
                              ]}
                    
 
                              data={this.state.ficheroList}
 
                        />}
                            
                          
                  </Paper>
              </Modal>
              <Modal
                                                      aria-labelledby="transition-modal-title"
                                                      aria-describedby="transition-modal-description"
                                                      style={{display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center'}}
                                                      
                                                      open={this.state.resumen_carga}
                                                      onClose={this.cerrarResumenCarga}
                                                    
                                                    >
                   <Paper style={{maxHeight: 700, overflow: 'auto',  marginLeft:20, marginRight:20,marginTop:20}}>                                 
                   <List style={{width: 700, height: 230,backgroundColor: "primary", overflow: 'auto',}} dense component="div" role="list">
                        {this.state.resumen_lista.map((value) => 
                              <ListItem>
                                <ListItemText primary={`${value.Error}`}/> 
                              
                              </ListItem>
                        )}
                    </List>
                  </Paper>
              </Modal>
              <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}

              open={this.state.mostrarAyuda}
              onClose={e => this.setState({mostrarAyuda:false})}

            >
              <Paper style={{ maxHeight: 500, overflow: 'auto',justifyContent:'center' }}>
                 <h5  style={{ color: 'green', textAlign: 'center', alignSelf: 'center'}}>
                   CREAR y MANTENIMIENTO DE GREMIOS</h5>
                <iframe title="mimelon"
                  style={{
                     marginLeft:10,
                     marginBottom:10,
                    width: "90%",
                    height: "90%"
                  }}
                  
                  src="https://www.youtube.com/embed/u6gZg-ujkTM"
                  frameBorder="5"
                  allowFullScreen='allowFullScreen'
                />
         

              </Paper>
            </Modal>
    </ThemeProvider>
    <Box mt={40}>
          <Copyright />
    </Box>
  
  </Grid>
  );
  }
}

const condition = authUser => !!authUser;
export default withFirebase(withAuthorization(condition)(Gremios));