import React from 'react';
import {Textofield2,checkBoxStandar,selectStandar} from '../Fomulario/formulario';


import {Paper,Grid,Modal,Checkbox,InputLabel,Button,FormControlLabel} from '@material-ui/core';



export const  formu_Despacho = (tthis) => 
<Modal
aria-labelledby="transition-modal-title"
aria-describedby="transition-modal-description"
style={{
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}}
open={tthis.state.abrir_cambios2}
onClose={e=>tthis.setState({abrir_cambios2:false})}
>
<Paper style={{ height: '30%', marginTop:'-5%',  width:'80%'}}>
  <Paper  style={{padding:10, maxHeight: '100%', overflowY: 'auto'}}> 

  <h3 style={{ color: 'white', backgroundColor:'#00a049', marginTop:'-0.5%' }}>DESPACHO: {tthis.state.nombre} </h3>
 <Grid container >
  <Grid container spacing={1} style={{ marginTop:'0.5%' }}>
    <Grid item sm={3}>

    {Textofield2 ( 'nombreDesp','Nombre despacho',tthis.state.modificar,null,tthis,'text',null)}

    </Grid>
    <Grid item sm={3}>

    {Textofield2 ( 'cifDesp','CIF despacho',tthis.state.modificar,null,tthis,'number',null)}

 
    </Grid>
    <Grid item sm={3} >

    {Textofield2 ( 'codigoDesp','Código despacho',tthis.state.modificar,null,tthis,'number',null)}
    

    </Grid>
    <Grid item sm={3} >
   

  

    </Grid>
  </Grid>

  <Grid container spacing={1}  style={{ marginTop:'1%' }}>
    <Grid item sm={3}>

    {Textofield2 ( 'poblacionDesp','Población',tthis.state.modificar,null,tthis,'text',null)}

    
    </Grid>
    <Grid item sm={3}>
    {Textofield2 ( 'direccionDesp','Dirección',tthis.state.modificar,null,tthis,'text',null)}

    </Grid>
    <Grid item sm={3}>
   
    {Textofield2 ( 'cpDesp','Código Postal',tthis.state.modificar,null,tthis,'text',null)}

    </Grid>


   
  </Grid>

  <Grid container spacing={1} style={{ marginTop:'1%' }}>


  <Grid item sm={3} >

    {Textofield2 ( 'personaDesp','Persona de contacto',tthis.state.modificar,null,tthis,'number',null)}

    </Grid>


      <Grid item sm={3}>
    
      {Textofield2 ( 'emailDesp','Correo email',tthis.state.modificar,null,tthis,'text',null)}
    </Grid>
      <Grid item sm={3}>
  
      {Textofield2 ( 'telefonoDesp','Teléfono Contato 1',tthis.state.modificar,null,tthis,'number',null)}
      </Grid>
   
      <Grid item sm={3}>
  
  {Textofield2 ( 'telefono2Desp','Teléfono Contato 2',tthis.state.modificar,null,tthis,'number',null)}
  </Grid>


  </Grid>
  

</Grid>

  </Paper>

  {tthis.state.modificar&&
 
    <Button   variant="contained" 
        onClick={e => { tthis.setState({modificar:false,tipo:"guardar"})
       
      }}    
        color="primary"
        style={{top:'1%' }}
    >Editar</Button>
  }
 {!tthis.state.modificar&&
 <>

    <Button  variant="contained"    style={{top:'1%', backgroundColor:'orange' }}
        onClick={e => { tthis.setState({ abrir_cambios2:false, modificar:true})}}    
        color="primary"

    >Cancelar</Button>

   
    <Button variant="contained"   color="primary" type="submit"  style={{top:'1%', left:5 }}

      disabled={tthis.state.modificar||tthis.state.nombre===''||tthis.state.tipo_zona===''}
      onClick={e => {
        tthis.onSubmit()
      
      
      }}    
    >Guardar</Button>
    </>
  }
  </Paper>

</Modal>

