import React, { Component,forwardRef } from 'react';
import enviarAvisos, {cargarResultado} from './enviarAvisos'
import { CSVReader } from 'react-papaparse'
import { withFirebase } from '../Firebase';
import MaterialTable , {MTableBody}from 'material-table'
import { withAuthorization } from '../Session';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import {ElementsConsumer, IbanElement} from '@stripe/react-stripe-js';
import {Paper,Snackbar,InputLabel,IconButton,TextField, Box, Backdrop,Tooltip,Avatar,
  CircularProgress,DialogActions,DialogContent, Dialog,FormHelperText,
  DialogTitle, Fab, Grid, List,ListItem,ListItemText,Select,Checkbox,FormControl,Modal,
Chip,Button,MenuItem} from '@material-ui/core';

import moment from 'moment';

import ValidateIBAN from '../Fomulario/validadIBAN'

import { crearEstructurDirectorios2 } from '../DatosMaestros/documentos'
import BotonesTriger from '../Botones/botonTrige'
import Cabecera from '../Cabecera/index_365'

import { ThemeProvider } from '@material-ui/styles';
//import {green} from '@material-ui/core/colors';

import firebase2 from 'firebase'
import {AddBox,ArrowDownward, Check,ChevronLeft,ChevronRight,Clear,DeleteOutline
,Edit,FilterList,FirstPage,LastPage,Remove,SaveAlt,Search,ViewColumn} from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close';
import SendIcon from '@material-ui/icons/Send';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme } from '@material-ui/core/styles'
import ModalTutoriales,{traerTutoriales,traerPlantillas} from  '../Tutoriales/tutoriales'

import Copyright from '../Privacidad/copyright';
import {Textofield,checkBoxStandar,TextofieldIBAN} from '../Fomulario/formulario'

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#00a049",
      claro: "#00a049"
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#e81446',
    },
  },
});



//<Formulario tthis={this} />

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class Comunidades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      PropsStripe:'',
      plantillaCsv:'',
      rows:[],
      abrir_vista_previa_estruct:false,
      activarEstructura:false,
      editar:false,
     Nombre:'', Cif:'',Fecha_Creacion:'',Id:'',Codigo_seguridad:'',Codigo_vecino:'',categorias1:[],direccionfinal1:'',tipoRegistro:'', 
     Cp:'',Poblacion:'',Poliza:'',Seguro_nombre:'', Observaciones:'', Seguro:'',Pago_App:false,
     IBAN:'',Vecinos:'',
     direcciones1:[],
      abrir_cambios:false,
      UID:'',
      rowData:"",
      comus_enviar: [],
      abrir_vista_previa: false,
      ficheroCsv: [],
      abrir_direcciones: false,
      resumen_carga: false,
      resumen_lista: [],
      VideoTuto:[],
      abrir_zonas: false,
      zonas: [],
      listaZonasRecuperadas: [],
      listaZonasRecuperadasFiltrada:[],
      añoActual:'',
      años:[],
      //ListaActivComunidad: [],
      nombreZona: '',
      uid: "",
      gremio: '',
      direccion: "",
      lista_final:[],
      incis: [],
      comus: [],
      comus2: [],
      mostrarAyuda: false,
      open: false,
      comunidad: '',
      tipo: 'guardar',
      gremio_poliza: [],
      errorIBANComunidad:false,
      actividad: '',
      identificador: '',
      mostrarTabla: true,
      selectedIndex: '',
      email: '',
      poliza: '',
      polizas: [],
      seguro: '',
      comunidadBorrar: "",
      password: '',
      error: null,
      error2: null,
      modificar: true,
      listaComunidades: [],
      ComunidadesAsig: [],
      ComunidadesNoAsig: [],
      tenemosLista: false,
      leftChecked: [],
      rightChecked: [],
      left: [1, 2, 3, 4],
      right: [5, 6, 711],
      checked: [],
      snack: false, snack2: false, vertical: 'top',
      horizontal: 'center',
      gremio_seguros: [],
      identIncial: '',
      ListaActivComunidad: [],
      gremios: [],
      lista_gremios: {},
      direccionfinal: '',
      lista_actividades: {},
      abrirDescrip: false,
      descripicion: "",
      descripcionfinal: '',
      abrirAviso: false,
      usuarioLogueado: '',
      usuarios: [],
      dispostivos: [],
      usuariosComu: [],
      texto_envio: '',
      texto_url:'',
      texto_titulo: '',
      zonascomunes: [],
      categorias: [],
      usuariosTotal:[],
      index: '',
      despacho:
      {
        "Nombre": "",
        "CIFNIF": "",
        "Codigo": "",
        "Fecha_registro": "",
        "Telefono1": "",
        "Telefono2": "",
        "Correo": "",
        "Direccion": "",
        "Cp": "",
        "Poblacion": "",
        "Persona": "",
        "Logo": "",
        "Iban": "",
        "uid": ""
      },
      resultado_aviso:[],  
      avisos_enviados:false,
      tablaDirecciones:'',
      gestion_direcciones:false
    };
    this.BuscarComus = this.BuscarComus.bind(this);
    this.enviarAviso = this.enviarAviso.bind(this)
  
    this.buscarComunidades = this.buscarComunidades.bind(this);
    this.comprobarExisteComunidadID = this.comprobarExisteComunidadID.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.comprobarExisteCif = this.comprobarExisteCif.bind(this)

    this.buscarGremios = this.buscarGremios.bind(this)
    this.busquedaAyuda = this.busquedaAyuda.bind(this)
    this.dialogoEnvio = this.dialogoEnvio.bind(this)
    this.recuperarListaactidadesgremio = this.recuperarListaactidadesgremio.bind(this)
    this.traerUsuarioLogueado = this.traerUsuarioLogueado.bind(this)
    this.comprobarCif = this.comprobarCif.bind(this)
    this.buscarDespacho = this.buscarDespacho.bind(this)
    this.cerrarGestionZonas = this.cerrarGestionZonas.bind(this)

  }
  filterRef () {return ( React.createRef(""))}
  filterRef2 () {return ( React.createRef(""))}

  gestionIbanStripe() {

    const {stripe, elements} = this.props

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const despachoCode=   this.props.firebase.despachoCode
    const callable2 =   this.props.firebase.functions.httpsCallable('setupIntent')
    callable2()        
            .then((result) => { 
              const setupIntent=result.data
                      
    const iban = elements.getElement(IbanElement);
    // For brevity, this example is using uncontrolled components for
    // the accountholder's name and email. In a real world app you will
    // probably want to use controlled components.
    // https://reactjs.org/docs/uncontrolled-components.html
    // https://reactjs.org/docs/forms.html#controlled-components

    //const accountholderName = event.target['accountholder-name'];
    //const email = event.target.email;

    stripe.confirmSepaDebitSetup( setupIntent.client_secret, {
      payment_method: {
        sepa_debit: iban,
        billing_details: {
     //     name: accountholderName.value,
     //     email: email.value,
        },
      }
    }).then((result)=>{
       
        if (result.error) {
            // Show error to your customer.
            console.log(result.error.message);
          } else {
            const setupIntent=result.setupIntent
            
            const callable3 =   this.props.firebase.functions.httpsCallable('actualizarCliente2')
            callable3({cliente:'cus_M66z3OuwVmhz3B',default_payment_method:setupIntent.payment_method})        
            .then((custom) => { 
                      const cliente=custom.data
                      const callable4 =   this.props.firebase.functions.httpsCallable('crearSubscripcion')
                      callable4({cliente:'cus_M66z3OuwVmhz3B',precio:'price_1LQ46vItPJ2Wwqc9loFRMJXu',cantidad:'44'})        
                      .then((subscribcion) => {

                            const sub=subscribcion.data
                      })
            // Show a confirmation message to your customer.
            // The PaymentIntent is in the 'processing' state.
            // SEPA Direct Debit payments are asynchronous,
            // so funds are not immediately available.
          
                    })
                    .catch(error => {

                        const a = error
                    })
                    
        }
      
    }) .catch(error => {

        const a = error
    })
    }) 
  };
  handleOnDrop2(data){

    var listaEstructura = []
    data.forEach(direc => {
      if (direc.data[0] && direc.data[0] !== '') {
        listaEstructura = listaEstructura.concat({
          direccion: direc.data[0], numero: direc.data[1], portal: direc.data[2],
          piso: direc.data[3], letra: direc.data[4], porcentaje: (direc.data[5] ? Number(direc.data[5]):0),
          email: (direc.data[6] ? direc.data[6]:''),
          dni: (direc.data[7] ? direc.data[7]:''),
          cod_cliente: (direc.data[8] ? direc.data[8]:''),
        })

      }

    })

    this.setState({ abrir_vista_previa_estruct: true, ficheroCsv: data, ficheroList: listaEstructura })
  }

  handleOnDrop = (data) => {

    var listaComunidades = []
    data.forEach(comunidad => {
      if (comunidad.data[0] && comunidad.data[0] !== '') {
        listaComunidades = listaComunidades.concat({
          Nombre: comunidad.data[0], Cif: comunidad.data[1], Id: comunidad.data[2],
          Codigo_seguridad: comunidad.data[3], Categorias: comunidad.data[4], Direcciones: comunidad.data[5],
          Cp: comunidad.data[6], Poblacion: comunidad.data[7], Seguro: comunidad.data[8],
          Poliza: comunidad.data[9], IBAN: comunidad.data[10],Vecinos:comunidad.data[11],
           Observaciones: comunidad.data[12]
        })

      }

    })

    this.setState({ abrir_vista_previa: true, ficheroCsv: data, ficheroList: listaComunidades })
  }

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
    alert(err, file, inputElem, reason)
  }

  actualizaDirecciones(lista_final){
    
    var direcciones1=[]
    lista_final.map(dire=> {
      const index=direcciones1.findIndex(direc=>direc===dire.direccion)
      if(index<0){direcciones1= direcciones1.concat(dire.direccion)}
    })
    this.setState({direcciones1:direcciones1, direccionfinal1:''})

  }
  
  cargarEstructura(direcciones){
  
    this.setState({ abrir_vista_previa_estruct: false, gestion_direcciones:false, mostrarTabla:false,abrir_cambios:false,open: true })


  setTimeout(() => {
      var  resumen_lista=[]
        
      return new Promise(resolve => {
        var lista_final=[]
        direcciones.map((newData,index) => {
         const cambios=[{'newData':newData}]
         const resul=validarCambio(cambios, lista_final)
         const indi=index+1
         if(resul===true){

          lista_final=lista_final.concat(newData)
          resumen_lista=resumen_lista.concat({ Error: "Línea del fichero: "+ indi +"  OK, registro creado correctamente"  })

        }else{
          resumen_lista=resumen_lista.concat({ Error: "Línea del fichero: "+ indi+' ERROR DE CARGA '+resul})
        }

        })
        this.actualizaDirecciones(lista_final)
        resolve(lista_final)

    }).then((lista_final)=>{
      this.setState({ open: false,lista_final:lista_final })
      this.setState({ficheroCsv: [], resumen_lista: resumen_lista,mostrarTabla:true,abrir_cambios:true,
        gestion_direcciones:true },()=>{
          this.setState({resumen_carga: true})
          alert('La carga se ha ejecutado correctamente, por favor revisa el log de errores para revisar si ha habido algún error.')
        })
      

    })
    
  }, 1000)
  
  }

  cargarComunidades = (comunidades) => {

    this.setState({ cargando: true, open:true })
    const getData = async () => {
      return Promise.all(comunidades.map(newData => {
        return new Promise(resolve => {

  
        //  const melon = comunidades.filter(comunidad => comunidad.Nombre === newData.Nombre)
     //     if (melon.length > 1) {
     //       return (resolve({
     //         Error: "KO: Tienes en el fichero de carga la comunidad repetida, debe de ser única ,"
     //           + newData.Nombre
     //       }))
     //     }

          if (!newData.Nombre || newData.Nombre === '') {
            return (resolve({ Error: "KO: El campo Nombre dela comunidad no puede ser vacío," + newData }))
          }

          if (!newData.Cif || newData.Cif === '') {
            return (resolve({ Error: "KO: El campo CIF de la comunidad no puede ser vacío," + newData.Nombre }))
          }

          //                else if(!this.comprobarCif(newData.Cif)){this.setState({snack:true,   error2:"El CIF " +
          //                 newData.Cif+" no es válido" });
          //                ejecuta=false}
       //   if (this.comprobarExisteCif(newData.Cif).length > 0) {

         //   return (resolve({ Error: "KO: El campo CIF ya existe," + newData.Cif }))
         // }
          if (!newData.Id || newData.Id === '') {
            return (resolve({ Error: "KO: El campo Id no puede ser vacío," + newData.Nombre }))
          }
          if (!newData.Codigo_seguridad || newData.Codigo_seguridad === '') {
            return (resolve({ Error: "KO: El campo Código de seguridad no puede ser vacío," + newData.Nombre }))
          }
          if (!newData.Vecinos || newData.Vecinos === '') {
            return (resolve({ Error: "KO: El campo número de vecinos no puede ser vacío," + newData.Nombre }))
          }

          else if (!newData.Direcciones || newData.Direcciones.length < 1) {
                              
            return (resolve({ Error: "KO: El campo Direeciones no puede ser vacío," + newData.Nombre }))
          }

//          if (comprobar.length > 0) {

  //          return (resolve({ Error: "KO: El Id " + newData.Id + " ya está asignado a otra comunidad, por favor usa otro" }))

    //      }
          var resul1 = {}
          var Aviso = ""
          const categorias = newData.Categorias.split(',')
          categorias.forEach((value, key) => {
            var clave = this.state.categorias.filter(cate => cate.Id === value)
            if (clave.length > 0 && clave) {
              this.props.firebase.categoria(clave[0].uid +
                "/Comunidad").update({

                  [newData.Id]: newData.Id,
                })
              resul1[clave[0].uid] = clave[0].Nombre

            }
            else { Aviso = Aviso.concat("- La categoría " + value + " no existe - ") }
          });
        
          
     //     if (!resultado2 || resultado2.length === 0) { resul1 = { 0: "" } }
          //rescatamos todas las actividades y las creamos con esta -1
          var updates = {}
          let activs = this.state.activs.filter(actividad => actividad.Nombre !== 'SEGUROS' &&
          actividad.Estandar)
          activs.forEach((change) => {
            var comunidades=[]
            if(change.Comunidad){ comunidades=comunidades.concat(change.Comunidad)}
            comunidades=comunidades.concat(newData.Id)
            this.props.firebase.activ(change.uid).update({"Comunidad": comunidades})
            updates[change.uid] = "-1"
            // damos de alta enla actividad la comunidad que estemos dando de alta
          });

          newData.ActivGremios = updates
          newData.ActivGremioAuxiliar = updates

          let fecha = new Date()

          const observaciones = "<" + this.state.usuarioLogueado.Nombre
            + "> " + fecha.toLocaleString("en-GB") + ": Creación comunidad " + newData.Observaciones

          let momento = moment(fecha).format('DD/MM/YYYY')

          if (newData.Seguro&&newData.Seguro!==''){
            var seguro = this.state.gremios.filter(seguro => seguro.Nombre === newData.Seguro)
            if (seguro && seguro.length > 0) {
              newData.Seguro =seguro[0].uid

            }else{newData.Seguro = "" }
          } else{ newData.Seguro = "" }
         
           
          if (!newData.Pago_App) { newData.Pago_App = false }
       
          if (!newData.Poliza) { newData.Poliza = "" }
          var direcciones = newData.Direcciones.split(',')
          if (!direcciones) {  newData.Direcciones = [] }else{
            newData.Direcciones= direcciones
          }
          if (!newData.Cif) { newData.Cif = "" }
          if (!newData.IBAN) { newData.IBAN = "" }
          if (!newData.Cp) { newData.Cp = "" }
          if (!newData.Poblacion) { newData.Poblacion = "" }
          //      if(!newData.Codigo_seguridad){newData.Codigo_seguridad=""}
          const despacho = this.state.despacho
          const comprobar = this.comprobarExisteComunidadID(newData.Id)
          if (comprobar&&comprobar.length>0) {
           this.props.firebase.comu(comprobar[0].uid).update({
              Nombre: newData.Nombre,
              Direcciones: newData.Direcciones,
              Id: newData.Id,
              Categorias: resul1,
              Fecha_Creacion: momento,
              Seguro: newData.Seguro,
              ActivGremios: updates,
              ActivGremioAuxiliar: updates,
              Poliza: newData.Poliza,
              Cif: newData.Cif,
              Codigo_seguridad: newData.Codigo_seguridad,
              Codigo_vecino: despacho.Codigo + newData.Id + newData.Codigo_seguridad,
              Cp: newData.Cp,
              Vecinos: newData.Vecinos,
              IBAN: newData.IBAN,
              Observaciones: observaciones,
              Pago_App: newData.Pago_App,
              Poblacion: newData.Poblacion,
              Lopd : {
                "Activa" : false,
                "Camaras" : false,
                "Contrato_fir" : false,
                "Empleados" : false,
                "Firma_baja" : false,
                "Firma_presi" : false
              },
              Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
            
           
            }).then(() => {
          
              return (resolve({ Error: "OK, ACTUALIZADO correctamente," +comprobar[0].uid  + " " + Aviso }))
  
  
            })
              .catch(error => {
                this.setState({ error });
                this.setState({ descripcionfinal: "" });
              });
  
        }
          
          else{
          this.props.firebase.comus().push({
            Nombre: newData.Nombre,
            Direcciones: newData.Direcciones,
            Id: newData.Id,
            Categorias: resul1,
            Fecha_Creacion: momento,
            Seguro: newData.Seguro,
            ActivGremios: updates,
            ActivGremioAuxiliar: updates,
            Poliza: newData.Poliza,
            Cif: newData.Cif,
            Codigo_seguridad: newData.Codigo_seguridad,
            Codigo_vecino: despacho.Codigo + newData.Id + newData.Codigo_seguridad,
            Cp: newData.Cp,
            Vecinos: newData.Vecinos,
            IBAN: newData.IBAN,
            Observaciones: observaciones,
            Pago_App: newData.Pago_App,
            Poblacion: newData.Poblacion,
            Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
            Junta: {"Titulo": "Junta Ordinaria", "Estado": false}
         
          }).then((quei) => {

            const fire= this.props.firebase
            crearEstructurDirectorios2((fire.codeDespacho ? fire.codeDespacho:""), newData.Id, "Directorio Raiz", this.props.firebase, false)



            return (resolve({ Error: "OK, Cargado correctamente," + quei.key + " " + Aviso }))


          })
            .catch(error => {
              this.setState({ error });
              this.setState({ descripcionfinal: "" });
            });


          }
         })

      }))

    }
    getData().then(data => {

      this.setState({ abrir_vista_previa: false, cargando: false, ficheroCsv: [], resumen_carga: true, resumen_lista: data })
      this.setState({ open: false })
      this.buscarComunidades()

    })


  }
  handleOnRemoveFile = (data) => {

    console.log(data)
  }
  cerrarVistaPrevia = () => {
    // this.handleRemoveFile(this.state.ficheroCsv) 
    if (!this.state.cargando) {
      this.setState({ abrir_vista_previa: false, ficheroCsv: [] })
    }
  }

  cerrarResumenCarga = () => {
    // this.handleRemoveFile(this.state.ficheroCsv) 
    this.setState({ abrir_vista_previa: false, ficheroCsv: [], resumen_carga: false, resumen_lista: [], mostrarAyuda: false })
    alert('Recuerda que para guadar los cambios debes "CERRAR" la ventana y escoger la opción "GUARDAR, gracias')

  }
  handleChangeCat = (oldData) => (event) => {
    var newData = oldData
    newData.categorias = event.target.value;
    newData.categorias = newData.categorias.filter(rol => rol !== '');
    oldData.categorias = newData.categorias
    // data[data.indexOf(oldData)] = newData;
   // this.setState({ categorias: data });

    //  setPersonName(event.target.value);
  }
  seleccionarZona = (zona) => () => {

    this.setState({ nombreZona: zona.Nombre, años:[] })
    const zonaList = Object.keys(zona.zon).map(key => ({
      ...zona.zon[key],

    }));
    var lista = []
    const usuarios = this.state.usuariosComu
    var año=''
    var años=[]
    zonaList.forEach(zon => {
      if (zon.Fecha !== "uid") {
        const fecha = zon.Fecha.slice(6, 8) + "/" + zon.Fecha.slice(4, 6) + "/" + zon.Fecha.slice(0, 4);
        const melon = zon.val

        if(año!==zon.Fecha.slice(0, 4)){
          años=años.concat({'Nombre':zon.Fecha.slice(0, 4)})
          año= zon.Fecha.slice(0, 4);
        }
      
     
        melon.forEach((hora,index) => {
          if (zona.Aforo !== hora.AforoActual) {
            //   const reservas= zona.Aforo- hora.AforoActual

            var entrada = Object.entries(hora).map(([key, val]) => ({
              "clave": key,val}));
         
            entrada= entrada.filter(entr=>entr.clave!=='AforoActual'&&entr.clave!=='hora')
            entrada.forEach(entr => {
                const usu = usuarios.filter(usuario => usuario.uid === entr.clave)
               
                var nombre = ''
                if (usu && usu.length > 0) {
                  nombre = usu[0].Nombre
                  const cierre= (melon[index+1] ? melon[index+1].hora:'24:00')
                  lista = lista.concat({
                    "Estado":( entr.val.Cancelada ? 'Cancelada':'Ok'),
                    "Fecha": fecha,
                    "Año":zon.Fecha.slice(0, 4),
                    "Hora": hora.hora, "Usuario": nombre+' '+usu[0].Apellido, "Direccion": usu[0].Direccion,
                    "Numero":usu[0].Numero, "Piso":usu[0].Piso, 'Letra':usu[0].Letra,
                    "Portal": usu[0].Portal, "DNI": usu[0].DNI, "Personas": entr.val.NumPersonas,
                     "HoraFin":(hora.hora_fin ? hora.hora_fin:cierre),
                  })
                }
              
            })

          }
        
        })
      }


    })
    let añoActual = moment(new Date()).format('YYYY')
    const nuevaLista = lista.filter(zon=>zon.Año===añoActual)

    this.setState({ listaZonasRecuperadas: lista, listaZonasRecuperadasFiltrada: nuevaLista,añoActual:añoActual,
    años:años})

  }

  gestionZonas (comu) {
      this.setState({abrir_cambios:false})
      this.setState({ mostrarTabla: false, open: false });
    var usersObject = ''
    var zonaList = []
    this.props.firebase.users().orderByChild("Comunidad").equalTo(comu).once('value', snapshot => {
      const usersObject = snapshot.val();
      if (usersObject) {
        const lista = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }))
        this.setState({ usuariosComu: lista })
      }
    })
      .then(() => {

        this.props.firebase.reservazona(comu).once('value', function (snapshot) {
          usersObject = snapshot.val();
          if (usersObject) {
            zonaList = Object.keys(usersObject).map(key => ({
              ...usersObject[key],
              uid: key,
            }));


          }

        }).then(() => {
          if (zonaList && zonaList.length > 0) {
            const zonas = this.state.zonascomunes
            var zonasBuenas = []
            zonaList.forEach(zonaReserva => {
              const zonaNombre = zonas.filter(zona => zona.uid === zonaReserva.uid)
              if (zonaNombre && zonaNombre.length > 0) {
                const hola = zonaNombre[0].Nombre
                const Aforo = zonaNombre[0].Aforo

               
                var zon = Object.entries(zonaReserva).map(([key, val]) => ({
                  "Fecha": key, val,
                }));

                zon=zon.filter(item=>item.Fecha!=='uid')
                zonasBuenas.push({
                  zon, "Nombre": hola, "Aforo": Aforo
                })
              }

            })
            this.setState({ zonas: zonasBuenas, abrir_zonas: true,abrir_cambios:false })
            this.setState({ mostrarTabla: true, open: false });

          } else { alert("Esta comunidad no dispone de Zonas asociadas") }

        })

      })
  }

  cerrarGestionZonas = () => {
    this.setState({ abrir_zonas: false,años:[], listaZonasRecuperadas: [],listaZonasRecuperadasFiltrada:[], zonaNombre: '' })

  }
  traerDirecciones(rowData){

    return new Promise((resolve,reject) => {
    if(this.state.tipoRegistro!=='nuevo'){
      this.props.firebase.users().orderByChild("Comunidad").equalTo(rowData.Id).once('value', snapshot => {
      const usersObject = snapshot.val();
      var usuarios=[]
      if (usersObject) {
         usuarios = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }))
      
      }
          const lista_direcciones_cod = rowData.Direcciones_cod
          const coeficientes = (rowData.Coeficientes ? rowData.Coeficientes:{})
            var lista_final= []
            lista_direcciones_cod&& lista_direcciones_cod.map(direcciones=>{
                  var direccion={"direccion":direcciones.Nombre}   
                  const Numeros=direcciones.Numero
                  if(Numeros&&Numeros.length>0){
                      Numeros.map(numeros=>{
        
                      direccion={"direccion":direcciones.Nombre, "numero":numeros.Nombre}   
                      const Portales= numeros.Portal
                      if(Portales&&Portales.length>0){
                          Portales.map(portales=>{
                              direccion={"direccion":direcciones.Nombre, "numero":numeros.Nombre, "portal":portales.Nombre}
                              const Pisos= portales.Piso 
                              if(Pisos&&Pisos.length>0){
                                  Pisos.map(pisos=>{
                                      direccion={"direccion":direcciones.Nombre, "numero":numeros.Nombre, "portal":portales.Nombre, "piso":pisos.Nombre}
                                      const Letras= pisos.Letra
                                      if(Letras&&Letras.length>0){
                                          Letras.map(letras=>{
                                            direccion={"direccion":direcciones.Nombre, "numero":numeros.Nombre,
                                              "portal":portales.Nombre, "piso":pisos.Nombre, "letra":letras}
                                            const direccionbuscar= direccion.direccion+direccion.numero+direccion.portal+direccion.piso+direccion.letra
                                            const usuario = usuarios.filter(usu =>
                                              !usu.Borrado&&usu.Direccion===direccion.direccion&&
                                              usu.Numero.toString().toUpperCase()===direccion.numero.toString().toUpperCase()
                                              &&usu.Portal.toString().toUpperCase()===direccion.portal.toString().toUpperCase()
                                              &&usu.Piso.toString().toUpperCase()===direccion.piso.toString().toUpperCase()
                                              &&usu.Letra.toString().toUpperCase()===direccion.letra.toString().toUpperCase()
                                             )

                                              direccion={...direccion, "porcentaje":(coeficientes[direccionbuscar] ? coeficientes[direccionbuscar]:0)
                                                 ,"estado":(usuario.length>0 ? 'OCUPADA':'LIBRE')   }
                                               
                                              lista_final=lista_final.concat(direccion)
                                          })
                                          
                                      }else{
                                        const direccionbuscar= direccion.direccion+direccion.numero+direccion.portal+direccion.piso
                                        const usuario = usuarios.filter(usu =>
                                          !usu.Borrado&&usu.Direccion===direccion.direccion&&
                                          usu.Numero.toString().toUpperCase()===direccion.numero.toString().toUpperCase()
                                          &&usu.Portal.toString().toUpperCase()===direccion.portal.toString().toUpperCase()
                                          &&usu.Piso.toString().toUpperCase()===direccion.piso.toString().toUpperCase()
                                            &&usu.Letra==="")
                                        direccion={...direccion, "porcentaje":(coeficientes[direccionbuscar] ? coeficientes[direccionbuscar]:0)
                                           ,"estado":(usuario.length>0 ? 'OCUPADA':'LIBRE')   }
                                           
                                          lista_final=lista_final.concat(direccion)
                                      }
                                  })
                            
                              }else{
                                const direccionbuscar= direccion.direccion+direccion.numero+direccion.portal
                                const usuario = usuarios.filter(usu =>
                                  !usu.Borrado&&usu.Direccion===direccion.direccion&&
                                  usu.Numero.toString().toUpperCase()===direccion.numero.toString().toUpperCase()
                                  &&usu.Portal.toString().toUpperCase()===direccion.portal.toString().toUpperCase()
                                  &&usu.Piso===""&&usu.Letra==="")
                                direccion={...direccion, "porcentaje":(coeficientes[direccionbuscar] ? coeficientes[direccionbuscar]:0)
                                   ,"estado":(usuario.length>0 ? 'OCUPADA':'LIBRE')   }
                                        lista_final=lista_final.concat(direccion)                            
                              }
                          })
        
        
                      }
                      else{
                          const direccionbuscar= direccion.direccion+direccion.numero
                          const usuario = usuarios.filter(usu => 
                            !usu.Borrado&&usu.Direccion===direccion.direccion&&
                            usu.Numero.toString().toUpperCase()===direccion.numero.toString().toUpperCase()
                            &&usu.Portal===""&&usu.Piso===""&&usu.Letra==="")
                          direccion={...direccion, "porcentaje":(coeficientes[direccionbuscar] ? coeficientes[direccionbuscar]:0)
                             ,"estado":(usuario.length>0 ? 'OCUPADA':'LIBRE')   }
                          lista_final=lista_final.concat(direccion)
                      }
                      })
        
                  }else{
                  //solo está la dirección
                          lista_final=lista_final.concat(direccion)
        
                  }
        
            })
            this.setState({lista_final:lista_final})
            resolve(true)
          
        
      
      
        
     
      })  
    }else{
      //Estamos cuando se crea una comunidad
      this.setState({lista_final:[]})
      resolve(true)
    }
  })
  }
  gestionDirecciones (rowData){

    this.setState({rowData:rowData,gestion_direcciones:true})
  }

  cerrarGestionCambios (){
    this.setState({ abrir_cambios: false, rowData: [], editar:false, lista_final:[] })

  }
  buscarDespacho() {
    this.props.firebase.despacho().once('value', snapshot => {
      this.setState({ despacho:  snapshot.val() })
    })
  }
  handleClose() {
    this.setState({ snack: false, snack2: false })
  };
  buscarGremios() {
    this.props.firebase.gremios().once('value', snapshot => {
      const usersObject = snapshot.val();
      if (usersObject) {
        var gremiosList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
        //Quitamos los borrados
        gremiosList = gremiosList.filter(gremio => !gremio.Borrado)
        gremiosList.sort(function (a, b) {
          if (a.Nombre > b.Nombre) {
            return 1;
          }
          if (a.Nombre < b.Nombre) {
            return -1;

          }
          // a must be equal to b
          return 0;
        });

        
        this.setState({
          gremios: gremiosList,
        });
      }
    });
  }
  comprobarCif(cif1) {
    var cif= cif1.toUpperCase()
    var CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
    var match = cif.match(CIF_REGEX);
    if (!match) { return false }
    var letter = match[1], number = match[2], control = match[3];
    var even_sum = 0;
    var odd_sum = 0;
    var n;

    for (var i = 0; i < number.length; i++) {
      n = parseInt(number[i], 10);

      // Odd positions (Even index equals to odd position. i=0 equals first position)
      if (i % 2 === 0) {
        // Odd positions are multiplied first.
        n *= 2;

        // If the multiplication is bigger than 10 we need to adjust
        odd_sum += n < 10 ? n : n - 9;

        // Even positions
        // Just sum them
      } else {
        even_sum += n;
      }

    }
    var control_digit = (10 - (even_sum + odd_sum).toString().substr(-1));
    if (control_digit===10){control_digit=0}
    var control_letter = 'JABCDEFGHI'.substr(control_digit, 1);

    // Control must be a digit
    if (letter.match(/[ABEH]/)) {
      return control.toString() === control_digit.toString();

      // Control must be a letter
    } else if (letter.match(/[KPQS]/)) {
      return control.toString() === control_letter.toString();

      // Can be either
    } else {
      return  control.toString()===  control_digit.toString() ||  control.toString() ===  control_letter.toString();
    }

  }

  traerUsuarioLogueado() {
    var user = this.props.firebase.auth.currentUser;
    if (user) {
        this.props.firebase.users().orderByChild('/Clave').equalTo(user.uid)
      .once('value', snapshot => {
        const usersObject = snapshot.val();
        if (usersObject) {
          const usuario = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));

          this.setState({ usuarioLogueado: usuario[0] })  
        }
      })
    }
  }

  componentWillMount() {
    this.props.firebase.comus().off()
    this.props.firebase.categorias().off()
  }

  componentDidMount() {
    traerPlantillas(this,'Plantilla_carga_ejemplo.csv').then((data)=>{
      this.setState({plantillaCsv:data})
    })

  
    traerTutoriales(this, 'Comunidades').then((data)=>{this.setState({VideoTuto:data})})
    this.setState({ mostrarTabla: false });
    this.props.firebase.comus().once('value', snapshot => {

      const usersObject = snapshot.val();

      if (usersObject) {
        var comusList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
        comusList = comusList.filter(comu => comu.Id !== '')
        comusList.sort(function (A, B) {
          var a =Number(A.Id.trim())
          var b =Number(B.Id.trim())
          if(!a){a=-1}
          if(!b){b=-1}
          if( a > b) {
            return 1;
          }
          if (a < b) {
            return -1;

          }
          // a must be equal to b
          return 0;
        })

        this.setState({ comus2: comusList })
      }    


    })
    var zonasList1 = []
    this.props.firebase.zonascomunes().once('value', function (snapshot) {
      const usersObject = snapshot.val();

      if (usersObject) {
        zonasList1 = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
      }
    }).then(() => {
      this.setState({ zonascomunes: zonasList1 })
    })
    this.traerUsuarioLogueado()
    this.buscarDespacho()
    this.buscarGremios();
    this.props.firebase.activs().once('value', snapshot => {
      const usersObject = snapshot.val();
      const actividadList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));
      actividadList.sort(function (a, b) {
        if (a.Nombre > b.Nombre) {
          return 1;
        }
        if (a.Nombre < b.Nombre) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      let lista_actividades = {}
      actividadList && actividadList.forEach(function (actividad) {
        const id = actividad.Nombre;
        //quitamos las actividades con id -1 y la de seguros porque no aplica a las  activdades de Incidencias
        if (id !== '' && id !== 'SEGUROS') { lista_actividades[id] = id }

      })
      this.setState({ activs: actividadList, lista_actividades: lista_actividades });

    }).then(() => {
     
    var lista_gremios = {}
    var gremiosList = []
    this.props.firebase.gremios().once('value', snapshot => {
        const usersObject = snapshot.val();
        if (usersObject) {
          gremiosList = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));

          //Quitamos los borrados
          gremiosList = gremiosList.filter(gremio => !gremio.Borrado)
          gremiosList.sort(function (a, b) {
            if (a.Nombre > b.Nombre) {
              return 1;
            }
            if (a.Nombre < b.Nombre) {
              return -1;
  
            }
            // a must be equal to b
            return 0;
          });
          lista_gremios[""] = ""
          gremiosList && gremiosList.forEach(gremio => {
            const id = gremio.Nombre;
            if (id !== '') {
              lista_gremios[id] = id

            }

          })
          // ponemos un valor vacío para que en la seleccion pueda escoger el campo vacío
        } this.setState({
          gremios: gremiosList, lista_gremios: lista_gremios
        });


      }).then(() => {
        var gremio_seguros = this.state.gremios.filter(gremio => gremio.Seguro);
        this.setState({ gremio_seguros: gremio_seguros });
        this.buscarComunidades()
      })

    }).catch((e) => {})
    this.props.firebase.categorias().once('value', snapshot => {
      const usersObject = snapshot.val();
      if (usersObject) {

        const categoriasList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));


        this.setState({ categorias: categoriasList })
      }
    })

  }

  buscarComunidades() {
    const gremio_seguros = this.state.gremio_seguros
    this.props.firebase.comus().once('value', snapshot => {

      const usersObject = snapshot.val();

      if (usersObject) {
        var comusList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
        comusList = comusList.filter(comu => comu.Id !== '')
        comusList.sort(function (A, B) {
          var a =Number(A.Id.trim())
          var b =Number(B.Id.trim())
          if(!a){a=-1}
          if(!b){b=-1}
          if( a > b) {
            return 1;
          }
          if (a < b) {
            return -1;

          }
          // a must be equal to b
          return 0;
        })


 //this.setState({ comus2: comusList })
        //buscamos ell nombre de la compañía de seguros.

        comusList.forEach(function (comunidad) {
          if (comunidad.Seguro && comunidad.Seguro !== "") {
            var seguro = gremio_seguros.filter(seguro => seguro.uid === comunidad.Seguro)
            if (seguro && seguro.length > 0) {
              comunidad.Seguro_nombre = seguro[0].Nombre

            }

          } else { comunidad.Seguro_nombre = "" }

          if (comunidad.Categorias) {
            var catego = Object.entries(comunidad.Categorias).map(([key, val]) => (
              val

            ));
          }
          comunidad.categorias = catego
        })
        //filtramos si se ha escogido una comunidad en concreto
       
        if (this.state.comunidad !== "") {
          comusList = comusList.filter(comunid => comunid.Id === this.state.comunidad)
        }

        this.setState({ comus: comusList });
        //quitamos las de id  negativo -1
        this.filtrarComunidad(comusList);
        this.recuperarListaactidadesgremio(comusList)
      } else {
         this.setState({ comus: [] }) }

      this.setState({ mostrarTabla: true, open: false });
    })
  }

  comprobarExisteCif(cif) {
    var existe = [];
    if (this.state.comus) {
      existe = this.state.comus.filter(comu => comu.Cif === cif)
    }
    return existe;
  }
  BuscarComus() {
    this.setState({ open: true, mostrarTabla: false, tenemosLista: false })
    this.componentDidMount();
  //  this.buscarComunidades();
  }

  dialogoEnvio = () => () => { this.setState({ abrirAviso: true }) };

  cerrarAviso = () => { this.setState({ abrirAviso: false, texto_envio: '', texto_url:'',texto_titulo: '' }) };

  enviarAviso = () => {

    this.setState({ open: true,mostrarTabla:false, abrirAviso: false })
    var comunidades = this.state.comus
    this.setState({ dispositivos: [] })
    
    //traemis kas comunidades
    // comunidades = comunidades.filter(comunidad => comunidad.Id==='050')
    comunidades = this.state.comus_enviar
    
    
   
  var comus=[]
   comunidades.map((value) => comus= comus.concat(value.Id))
 
 //  const getData = async () => {
   //    return Promise.all(comus.map(comunidad =>{ 
         //enviamos el aviso a los usuarios según comunidad
         new Promise((resolve, reject) => {
         resolve (enviarAvisos(comus,
          this.state.texto_envio,this.state.texto_titulo,this.state.texto_url, this.props.firebase,
        "","", "otro",[], this.state.usuarioLogueado, this.state.despacho,[]))})
     // getData().then(data2 => {
      .then(data2 => {
        if (data2){
                    const registro= cargarResultado(data2,[], this.props.firebase,this.state.usuarioLogueado)  
           
              this.setState({resultado_aviso:registro.archivo_final,avisos_enviados:true,open: false,mostrarTabla:true,
                usuariosTotal:registro.usuariosTotal,  texto_envio: '', texto_titulo: '',texto_url:''})   
        //     alert("El envío se ha realizado correctamente")
              this.setState({Categorias:[]})
          //    })
            }else { alert("Ha habido un problema y no se ha podido enviar")
              this.setState({Categorias:[]})}

          
            this.cerrarAviso()
      })

      .catch(reason => { 
        console.log(reason)
      });
      
  }
  filtrarComunidad(comusList) {
    //quitamos las de código negativo
    var comunidades = comusList.filter(comunidad => comunidad.uid !== -1);
    this.setState({ comus: comunidades })
  }
  busquedaAyuda(nombre) {
    var busquedAyuda = this.state.gremios.filter(function (gremio) {
      var esta = []
      if (gremio.Actividades && gremio.Actividades.length > 0) {
        esta = gremio.Actividades.filter(actividad => actividad === nombre)
      }
      if (esta.length > 0) { return true }
      return false
    })
    // if(busquedAyuda.length===0){busquedAyuda=[]}
    busquedAyuda = busquedAyuda.concat("")

    return busquedAyuda;
  }
  recuperarListaactidadesgremio(comusList) {
    var result = []
    var result2 = []
    //  var comusList =this.state.comus
    const actividades =  (this.state.activs ? this.state.activs : [])
    const gremios = (this.state.gremios ? this.state.gremios :[]) 
    comusList.forEach(function (comunidad) {
      if (!comunidad.ActiviGemios && comunidad.ActivGremios !== '') {
        //organizamos las actividades por gremio asociadas de la comunidad seleccionada
        result = Object.entries(comunidad.ActivGremios).map(([key, val]) => ({
          "Actividad": key, "Gremio": val,
        }));
        //buscamos las actividades que su gremio es Auxiliar
        result2 = Object.entries(comunidad.ActivGremioAuxiliar).map(([key, val]) => ({
          "Actividad": key, "GremioAux": val,
        }));


        var tabla = []

        var active = false

        result.forEach(function (registro) {

          var actividadNombre = actividades.filter(actividad => actividad.uid === registro.Actividad)
          if(actividadNombre&&actividadNombre.length>0){

          
              var gremioAuxiliar = result2.filter(actividad => actividad.Actividad === registro.Actividad)

              var busquedAyuda = gremios.filter(function (gremio) {
                var esta = []
                if (gremio.Actividades && gremio.Actividades.length > 0) {
                  esta = gremio.Actividades.filter(actividad => actividad === actividadNombre[0].Nombre)
                }
                if (esta.length > 0) { return true }
                return false

              })
              busquedAyuda = busquedAyuda.concat("")
          //if(busquedAyuda.length===0){busquedAyuda[0]=""}

              if (gremioAuxiliar[0].GremioAux === 1) { active = true }
              else { active = false }
              var gremioNombre = []
              if (registro.Gremio === "-1") {
                gremioNombre.push({
                  Nombre: "",
                  Telefono1: "",
                  Telefono2: "",
                  email: ""
                })
              }
              else {gremioNombre = gremios.filter(gremio => gremio.uid === registro.Gremio) }


              if (!gremioNombre || gremioNombre.length < 1) {
                gremioNombre.push({
                  Nombre: "",
                  Telefono1: "",
                  Telefono2: "",
                  email: ""
                })

              }
              tabla.push({
                Nombre: actividadNombre[0].Nombre,
                Uid: registro.Actividad,
                uid: gremioNombre[0].uid,
                GremioNombre: gremioNombre[0].Nombre,
                Telefono1: gremioNombre[0].Telefono1,
                Telefono2: gremioNombre[0].Telefono2,
                Correo: gremioNombre[0].email,
                Auxiliar: gremioAuxiliar[0].GremioAux,
                active: active,
                ayudaBusqueda: busquedAyuda
              })
          }
        });

        //Ordenamos el listado por Noombre actividad

        tabla.sort(function (a, b) {
          if (a.Nombre > b.Nombre) {
            return 1;
          }
          if (a.Nombre < b.Nombre) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        comunidad.ListaActivComunidad = tabla

      } else { comunidad.ListaActivComunidad = [] }

    })

    this.setState({ comus: comusList })

  }
  comprobarExisteComunidadID(Id) {
    var existe = [];
    existe = this.state.comus.filter(comunidad => comunidad.Id === Id)
    return existe;
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  };
  checkGremioAuxiliar = (oldData) => () => {

    var newData = oldData
    var respuesta = false
    if (newData.Auxiliar === "-1") {
      newData.Auxiliar = "1"
      respuesta = window.confirm("¿Quieres que el Gremio " + oldData.GremioNombre + " pase a ser sobre la actividad "
        + oldData.Nombre + " un gremio Auxiliar?")
    }
    else {
      respuesta = window.confirm("¿Quieres que el Gremio " + oldData.GremioNombre + " pase a ser sobre la actividad "
        + oldData.Nombre + " un gremio Principal?")
      newData.Auxiliar = "-1"
    }
    if (respuesta) {
      var Actividad = this.state.activs.filter(actividad => actividad.Nombre === newData.Nombre)
      var data = this.state.ListaActivComunidad;
      data[data.indexOf(oldData)] = newData;
      this.setState({ ListaActivComunidad: data });
      this.props.firebase.comu(this.state.uid + '/ActivGremioAuxiliar/').update({
        [Actividad[0].uid]: newData.Auxiliar,
      })
      this.componentDidMount();
    }
  }

rowActualiza (newData, oldData) {

   return new Promise((resolve,reject) => {
    
        //  resolve();
           const comprobar = this.comprobarExisteComunidadID(newData.Id)
    
           const esbueno=this.comprobarCif(newData.Cif)
            if (!newData.Nombre || newData.Nombre === '') {
             alert("El Nombre de la comunidad no puede ser vacío");
              reject(); return}
            

            else if (!newData.Cif || newData.Cif === '') {
           alert("El CIF no puede ser vacío");
              reject(); return
            }
         
            else if(!esbueno&&newData.Cif!=='A000000'){
                           alert("El CIF "+newData.Cif+" no es válido" );
                           reject(); return
                          }

            else if (this.comprobarExisteCif(newData.Cif).length > 0 && newData.Cif !== oldData.Cif) {

              alert("El CIF " +
              newData.Cif + " ya existe, por favor utiliza otro");
              reject(); return
            }


            else if (!newData.Id || newData.Id === '') {
              alert( "El Id Comunidad no puede ser vacío" );
              reject(); return
            }
            else if (!newData.Codigo_seguridad || newData.Codigo_seguridad === '') {
    
              alert( "El Código de Seguridad no puede ser vacío" );
              reject(); return

            }

            else if (comprobar !== 0 && oldData.Id !== newData.Id) {
            
              alert( "El Id " + newData.Id
              + " ya está asignado a otra comunidad, por favor usa otro" );
              reject(); return
            }

            else if (!newData.Direcciones || newData.Direcciones.length < 1) {
            
              alert( "La driección no puede ser vacía" );
              reject(); return
            }

            else if (!newData.Vecinos || newData.Vecinos.length < 1) {
               alert( "El campo número de vecinos no puede ser vacío"  );
              reject(); return
            }
            else if (newData.Pago_App && newData.IBAN==='') {
              alert( "Si está activo el pago mensual de la app por la comunidad el IBAN debe estar rellenado correctamente"  );
             reject(); return
           }
               
              if (oldData.categorias && oldData.categorias.length > 0) {
                var categoriasBorrar = []

            
                    oldData.categorias.forEach((value, key) => {
                      if(this.state.categorias&&this.state.categorias.length>0){
                          var clave = this.state.categorias.filter(cate => cate.Nombre === value)
                          if (clave.length > 0 && clave) {
                            this.props.firebase.categoria(clave[0].uid +
                              "/Comunidad/" + newData.Id).remove()

                          }


                          var clave2 = newData.categorias.filter(cate => cate === value)
                          if (clave2.length === 0 || !clave2) {
                            categoriasBorrar = categoriasBorrar.concat(value)
                          }


                    }
                    });
                
                if (categoriasBorrar && categoriasBorrar.length > 0) {
                  //Borramos de todos los usuarios los que tienen estas categorias asociadas

                  var usuarios = this.state.usuarios.filter(usu => usu.Comunidad === newData.Id)

                  if (usuarios && usuarios.length > 0) {

                    usuarios.forEach((usuario) => {
                      var cates = usuario.Categorias

                      if (cates && cates.length > 0) {
                        categoriasBorrar.map((catBorrar) =>cates = cates.filter(cate => cate !== catBorrar))

                        if (cates && cates.length > 0) {
                          this.props.firebase.user(usuario.uid +
                            "/Categorias/").remove().then(() => {
                              this.props.firebase.user(usuario.uid +
                                "/Categorias/").update(cates).then(() => {
                                  this.traerUsuarioLogueado()

                                }

                                )

                            })
                        } else {
                          this.props.firebase.user(usuario.uid +
                            "/Categorias/").remove().then(() => {

                              this.props.firebase.user(usuario.uid +
                                "/Categorias/").update({ 0: "" }).then(() => {
                                  this.traerUsuarioLogueado()

                                }

                                )
                            })
                        }



                      }

                    })
                  }





                }

              }

              var resul1 = {}
              if(!newData.categorias){newData.categorias =[""]}
             newData.categorias.forEach((value, key) => {
                if(this.state.categorias&&this.state.categorias.length>0){
                  var clave = this.state.categorias.filter(cate => cate.Nombre === value)
                  if (clave.length > 0 && clave) {
                    this.props.firebase.categoria(clave[0].uid +
                      "/Comunidad").update({

                        [newData.Id]: newData.Id,
                      })
                    resul1[clave[0].uid] = value

                  }
                }
              }

              );

            //  if (!resultado2 || resultado2.length === 0) { resul1 = { 0: "" } }
              if(this.state.categorias&&this.state.categorias.length>0){
                  var data2 = this.state.categorias;
                  data2[data2.indexOf(oldData)] = newData;
                  this.setState({ categorias: data2 });
              }

              let fecha = new Date()
              if (newData.descripcionfinal&&newData.descripcionfinal !== "") {
                newData.Observaciones =newData.Observaciones +'\n<' + this.state.usuarioLogueado.Nombre
                  + "> " + fecha.toLocaleString("en-GB") + ": " + newData.descripcionfinal
                  newData.descripcionfinal=''

                  this.setState({Observaciones:newData.Observaciones})
              }
              if (!newData.Pago_App) { newData.Pago_App = false }
              if (!newData.Seguro) { newData.Seguro = "" }
              if (!newData.Poliza) { newData.Poliza = "" }
              //     if(!newData.Direccion){newData.Direccion=""}


              if (!newData.Cif) { newData.Cif = "" }
              if (!newData.IBAN) { newData.IBAN = "" }
              if (!newData.Cp) { newData.Cp = "" }
              if (!newData.Poblacion) { newData.Poblacion = "" }
              if (!newData.Codigo_seguridad) { newData.Codigo_seguridad = "" }

              if (newData.Seguro === "") { newData.Poliza = "" }

              const coef=(newData.activarEstructura ? (newData.Coeficientes ? newData.Coeficientes:null): null)
              console.log('Probando...',{

                Nombre: newData.Nombre,
                Direcciones: newData.Direcciones,
                Id: newData.Id,
                Seguro: newData.Seguro,
                Categorias: resul1,
                Poliza: newData.Poliza,
                Cif: newData.Cif,
                Codigo_seguridad: newData.Codigo_seguridad,
                Codigo_vecino: newData.Codigo_vecino,
                Cp: newData.Cp,
                IBAN: newData.IBAN,
                Observaciones: newData.Observaciones,
                Pago_App: newData.Pago_App,
                Poblacion: newData.Poblacion,
                Vecinos:newData.Vecinos,
                Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
                activarEstructura:(newData.activarEstructura ? newData.activarEstructura:false),
                Coeficientes:coef,
                Direcciones_cod:(newData.activarEstructura ? (newData.Direcciones_cod ? newData.Direcciones_cod:null):null),
                Añadido_direcciones:(newData.activarEstructura ? (newData.Añadido_direcciones ? newData.Añadido_direcciones:null):null)

              });
              this.props.firebase.comu(newData.uid).update({

                Nombre: newData.Nombre,
                Direcciones: newData.Direcciones,
                Id: newData.Id,
                Seguro: newData.Seguro,
                Categorias: resul1,
                Poliza: newData.Poliza,
                Cif: newData.Cif,
                Codigo_seguridad: newData.Codigo_seguridad,
                Codigo_vecino: newData.Codigo_vecino,
                Cp: newData.Cp,
                IBAN: newData.IBAN,
                Observaciones: newData.Observaciones,
                Pago_App: newData.Pago_App,
                Poblacion: newData.Poblacion,
                Vecinos:newData.Vecinos,
                Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
                activarEstructura:(newData.activarEstructura ? newData.activarEstructura:false),
                Coeficientes:coef,
                Direcciones_cod:(newData.activarEstructura ? (newData.Direcciones_cod ? newData.Direcciones_cod:null):null),
                Añadido_direcciones:(newData.activarEstructura ? (newData.Añadido_direcciones ? newData.Añadido_direcciones:null):null)

              }).then(() => {              
                 alert( "El registro de " + newData.Nombre + " ha sido actualizado correctamente")
                var data = this.state.comus;
                //Actualizamos el valor de activar Estrcutura ya que no se actualiza de la forma que lo hacen el resto de valores
                console.log('Sigo por aquí...');
                data[data.indexOf(oldData)] = newData;
                this.setState({ comus: data, descripcionfinal: "" });
                resolve(true)


              })

                .catch(error => {
                  console.log('Skkko por aquí...');
                  console.log('Erorr',error);
                  this.setState({ error });
                  this.setState({ descripcionfinal: "" });
                });
            
          
        
      })

}

rowAñadir(newData){

 return new Promise((resolve,reject) => {
 
      //resolve();
      const comprobar = this.comprobarExisteComunidadID(newData.Id)
      var ejecuta = true


      if (!newData.Nombre || newData.Nombre === '') {
        alert( "El Nombre de la comunidad no puede ser vacío");
      
        ejecuta = false
    
      }

      else if (!newData.Cif || newData.Cif === '') {
        alert(  "El CIF no puede ser vacío" );
        ejecuta = false
      }

      else if(!this.comprobarCif(newData.Cif)&&newData.Cif!=='A000000'){
        alert( "El CIF " +newData.Cif+" no es válido" );
        ejecuta=false}
      else if (this.comprobarExisteCif(newData.Cif).length > 0) {
        alert(  "El CIF " +
            newData.Cif + " ya existe, por favor utiliza otro");
        ejecuta = false
      }

      else if (!newData.Id || newData.Id === '') {
        alert( "El Id Comunidad no puede ser vacío" );
        ejecuta = false
      }
      else if (!newData.Codigo_seguridad || newData.Codigo_seguridad === '') {
        alert(  "El Código de Seguridad no puede ser vacío"
        );
        ejecuta = false
      }

      else if (comprobar.length > 0) {
        alert( " ya está asignado a otra comunidad, por favor usa otro"
        );
        ejecuta = false
      }
      else if (!newData.Direcciones || newData.Direcciones.length < 1) {
        alert( "La driección no puede ser vacía" );
        ejecuta = false
      }
      else if (!newData.Vecinos || newData.Vecinos.length < 1) {
        alert(  "El campo número de vecinos no puede ser vacío" );
        ejecuta = false
      }
      if (ejecuta) {
        var resul1 = {}
        newData.categorias.forEach((value, key) => {
          if(this.state.categorias&&this.state.categorias.length>0){
          var clave = this.state.categorias.filter(cate => cate.Nombre === value)
            if (clave.length > 0 && clave) {
              this.props.firebase.categoria(clave[0].uid +
                "/Comunidad").update({

                [newData.Id]: newData.Id,
                })
              resul1[clave[0].uid] = value

            }
          }

          } );
        
      //  if (!resultado2 || resultado2.length === 0) { resul1 = { 0: "" } }
        var data2 = this.state.categorias;
        data2 = data2.concat(newData.categorias);
        this.setState({ categorias: data2 });
        

        //rescatamos todas las actividades y las creamos con esta -1
        var updates = {}
        let activs = this.state.activs.filter(actividad => actividad.Nombre !== 'SEGUROS' &&
          actividad.Estandar)
          activs.forEach((change) => {
           // var comunidades=change.Comunidad
           // if(!comunidades){comunidades=[]}
           // comunidades.push(newData.Id)
           updates[change.uid] = "-1"
            // damos de alta enla actividad la comunidad que estemos fando de alta
          });



        newData.ActivGremios = updates
        newData.ActivGremioAuxiliar = updates

        let fecha = new Date()
        if (newData.descripcionfinal&&newData.descripcionfinal === "") {
          newData.Observaciones = "<" + this.state.usuarioLogueado.Nombre
            + "> " + fecha.toLocaleString("en-GB") + ": Creación comunidad"
        } else {
          newData.Observaciones = "<" + this.state.usuarioLogueado.Nombre
            + "> " + fecha.toLocaleString("en-GB") + ": " + newData.descripcionfinal
            newData.descripcionfinal=''
        }

        let momento = moment(fecha).format('DD/MM/YYYY')

        if (!newData.Pago_App) { newData.Pago_App = false }
        if (!newData.Seguro) { newData.Seguro = "" }
        if (!newData.Poliza) { newData.Poliza = "" }
        if (!newData.Direccion) { newData.Direccion = "" }
        if (!newData.Cif) { newData.Cif = "" }
        if (!newData.IBAN) { newData.IBAN = "" }
        if (!newData.Cp) { newData.Cp = "" }
        if (!newData.Poblacion) { newData.Poblacion = "" }
        //      if(!newData.Codigo_seguridad){newData.Codigo_seguridad=""}

        this.props.firebase.comus().push({
          Nombre: newData.Nombre,
          Direcciones: newData.Direcciones,
          Id: newData.Id,
          Categorias: resul1,
          Fecha_Creacion: momento,
          Seguro: newData.Seguro,
          ActivGremios: updates,
          ActivGremioAuxiliar: updates,
          Poliza: newData.Poliza,
          Cif: newData.Cif,
          Codigo_seguridad: newData.Codigo_seguridad,
          Codigo_vecino: newData.Codigo_vecino,
          Cp: newData.Cp,
          IBAN: newData.IBAN,
          Observaciones: newData.Observaciones,
          Pago_App: newData.Pago_App,
          Poblacion: newData.Poblacion,
          Vecinos:newData.Vecinos,
          Lopd : {
            "Activa" : false,
            "Camaras" : false,
            "Contrato_fir" : false,
            "Empleados" : false,
            "Firma_baja" : false,
            "Firma_presi" : false
          },

          Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
          Junta: {"Titulo": "Junta Ordinaria", "Estado": false},
          activarEstructura:(newData.activarEstructura ? newData.activarEstructura:null),
          Coeficientes:(newData.Coeficientes ? newData.Coeficientes:null),
          Direcciones_cod:(newData.Direcciones_cod ? newData.Direcciones_cod:null)

        }).then((quei) => {
          const fire= this.props.firebase
          crearEstructurDirectorios2((fire.codeDespacho ? fire.codeDespacho:""),newData.Id, "Directorio Raiz", this.props.firebase, false).then((dato)=>{
              if(dato){
                
                  alert( "La comunidad " + newData.Nombre + " se ha creado correctamente, igualmente se han creado las carpetas de acuerdo con la estructura predefinida");

              }else{
                alert("La comunidad " + newData.Nombre + " se ha creado correctamente, pero NO se ha podio crear la estrcutura de directorios asociada");

                
              }




            })

       
          var comus = this.state.comus
          var com = []
          var newData2 = newData
          newData2.uid = quei.key
          com[0] = newData2
          com = com.concat(comus)
          this.setState({ comus: com, descripcionfinal: "" })
          this.recuperarListaactidadesgremio(com)
          resolve(true)
        })
          .catch(error => {
            this.setState({ error });
            this.setState({ descripcionfinal: "" });
            reject()
          });
      }else{resolve(false)}
    
  }) 
}

borrarDireccionesTabla(label){
  const lista_final=this.state.lista_final

  const lista_nueva=lista_final.filter(dire=> dire.direccion!==label)
  const diferencia=lista_final.length-lista_nueva.length
  alert('La dirección '+label+' ha sido eliminada, recuerda que para guardar este cambio debes "CERRAR" esta ventana y escoger la opción "GUARDAR"')

  if(lista_nueva&&lista_nueva.length>-1&&diferencia>0){
  
    this.setState({lista_final:lista_nueva})
    alert('Se ha eliminado de la tabla de estructura de direcciones '+diferencia+' línea/s, que contenían la dirección: '+label)
   
  }
  return true
}
    
  render() {

    const comus = this.state.comus
    const vertical = this.state.vertical;
    const horizontal = this.state.horizontal;
    
    const despacho = this.state.despacho
    const { texto_titulo, texto_envio, texto_url,listaZonasRecuperadas,listaZonasRecuperadasFiltrada, zonas } = this.state

    const botonZonas =(rowData)=>
       <Button
            //  disabled = { rowData.estado=== "CANCELADA" || rowData.estado=== "CERRADA"}
            variant="outlined" color="primary" disableElevation
            onClick={e => {
           
              this.gestionZonas(rowData.Id)}}
          >
              Zona Reservas
    </Button>
    const botonDirecciones =(rowData)=>
          <Button
               //  disabled ={disable}
                variant="outlined" color="primary" disableElevation
               
               onClick={e => {     
                this.traerDirecciones(rowData).then(()=>{
                  this.gestionDirecciones(rowData)  
                })
                }}
             >
                 Direcciones
       </Button>
    const selecto_sin_chip = (rowData,onRowDataChange,bloquear,labela) =>
    <FormControl fullWidth margin='normal'>
    <InputLabel shrink htmlFor="select-multiple-native">
    {labela}
     </InputLabel>
        
   
        <Select
           value={(onRowDataChange ? rowData.Seguro_nombre: this.state.Seguro_nombre)}
           disabled={bloquear}
          onChange={e => {
            var nuevoSeguro=''
            var melon = this.state.gremio_seguros.filter(gremio => gremio.Nombre === e.target.value)
            if (melon && melon.length > 0) {
              nuevoSeguro = melon[0].uid

            }
            if(onRowDataChange){
            const newRowData = { ...rowData, Seguro_nombre: e.target.value };
            rowData.Seguro_nombre = e.target.value
             newRowData.Seguro = nuevoSeguro
          
            onRowDataChange(newRowData);
          }else{
              this.setState({Seguro:nuevoSeguro,Seguro_nombre : e.target.value})
          }
            //                                          newRowData.Seguro =melon[0].uid
           
          }}    >
          { this.state.gremio_seguros &&  this.state.gremio_seguros.map((gremio) => (
            <MenuItem value={gremio.Nombre} >
              {gremio.Nombre}
            </MenuItem>
          ))


          }
        </Select>
    </FormControl>  
    

    const selecto_direcciones = (rowData,bloquear,labela,onRowDataChange) =>
    <FormControl fullWidth margin='normal'>
    <InputLabel shrink htmlFor="select-multiple-native">
    {labela}
     </InputLabel>
        
   
        <Select
           value={rowData.direccion}
           disabled={bloquear}
          onChange={e => {
            const newRowData = { ...rowData, direccion: e.target.value };
            rowData.direccion = e.target.value
           //  newRowData.direccion = nuevoSeguro
          
            onRowDataChange(newRowData);
          
           
          }}    >
          { this.state.direcciones1 &&  this.state.direcciones1.map((direc) => (
            <MenuItem value={direc} >
              {direc}
            </MenuItem>
          ))


          }
        </Select>
    </FormControl>  
    
    const PaperDireccciones = (rowData,onRowDataChange,editar) =>  {
    var direcciones = (onRowDataChange ? rowData.Direcciones:this.state.direcciones1)
    //  this.setState({ rowData: rowData })
      var direcfinal = []
      if (direcciones && direcciones.length > 0) {

        var i = 0
        direcciones.forEach(function (direc) {
          const dir = {
            key: i, label: direc, click: false
          }
          i++
          direcfinal = direcfinal.concat(dir)
        })

      }
      return(   
        
        <Paper style={{  justifyContent: 'center',flexWrap: 'wrap', padding: 3,listStyle: 'none'}}>      
        <Paper style={{
        display: 'flex',
        justifyContent: 'start',
        flexWrap: 'wrap',margin:3,
        padding: 5,
        listStyle: 'none'
      }}>

        {direcfinal.map((data) => {
          return (
            <li key={data.key}>
              <Chip
                //      icon={icon}
                label={data.label}
                variant="outlined"
                disabled={!this.state.editar}
                clickable
                color="primary"
                style={{ margin: 3 }}

                //   onClick={this.seleccionarZonaEnvio(data)}
                onDelete={e => {
                  if(onRowDataChange){
                    if (rowData.Direcciones.length === 1) {
                      alert("La comunnidad debe tener al menos una dirección asociada")
                
                    } else {
                    const newRowData = { ...rowData, direccionfinal: e.target.value };
                    newRowData.Direcciones = rowData.Direcciones.filter((dire) => dire !== data.label)
                 
        
                    onRowDataChange(newRowData);
                    this.borrarDireccionesTabla(data.label)
                    }
                  }
                  else{
                      var Direcciones1 =this.state.direcciones1
                      if (Direcciones1.length === 1) {
                        alert("La comunnidad debe tener al menos una dirección asociada")
                  
                      } else {
                      
                        Direcciones1= Direcciones1.filter((dire) => dire !== data.label)
                        this.setState({direcciones1:Direcciones1})
                        this.borrarDireccionesTabla(data.label)
                   
                      }
                  }
                      
                }}    
              />
            </li>
          );
        })}
        </Paper>
    <Paper style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',margin:3,
        padding: 5,
        listStyle: 'none'
      }}>
        <TextField
          disabled={!this.state.editar}
          multiline
          margin="dense"
          id="direccionfinal"
          name="direccionfinal"
          label="Añade nueva dirección"
          type="text"
          value={(onRowDataChange ? rowData.direccionfinal: this.state.direccionfinal1)}
          onChange={e => {
            if(onRowDataChange){
            const newRowData = { ...rowData, direccionfinal: e.target.value };
            rowData.direccionfinal = e.target.value 

            onRowDataChange(newRowData);
            }else{
                  this.setState({direccionfinal1:e.target.value})
            }
          }}    
          fullWidth
        />

    </Paper>
    
    {this.state.editar&& <Button
        disabled={(onRowDataChange ? !rowData.direccionfinal || rowData.direccionfinal === "":
        !this.state.direccionfinal1 || this.state.direccionfinal1 ==='')}
        onClick={e => {
          if(onRowDataChange){
            const newRowData = { ...rowData, direccionfinal: e.target.value };
            newRowData.Direcciones = rowData.Direcciones.concat(rowData.direccionfinal)
            newRowData.direccionfinal = ''

            onRowDataChange(newRowData);
          }else{
            var direcciones1 = this.state.direcciones1
            direcciones1= direcciones1.concat(this.state.direccionfinal1)
            this.setState({direcciones1:direcciones1, direccionfinal1:''})
            alert('La dirección '+this.state.direccionfinal1+' ha sido añadida correctamente, recuerda que para grabar estos cambios debes "CERRAR" esta ventana y escoger la opción "GUARDAR", gracias')
          }
         
        
        }}    
        color="primary"
      >
        AÑADIR
     </Button>}
    </Paper>)}

    const selecto = (rowData,Nombre,cuadro) =>
    
    <FormControl fullWidth>
   
    <Select
    labelId="demo-mutiple-chip"
    color="primary"
    disabled
    multiple
    value={(cuadro ? this.state[Nombre] : rowData[Nombre])}
    renderValue={(selected) => (
      <div style={{ display: 'flex', flexWrap: 'wrap', }}>
        {selected.map((value) => (
          <Chip variant="outlined" color='primary' key={value} label={value}
            disabled
            style={{ margin: 6 }} />
        ))}
      </div>
    )}>
  </Select>
    </FormControl>
    const selecto2 = (rowData, onRowDataChange) =>  
  <FormControl fullWidth>
 
    
    <Select
    multiple
     label='pavo'
    value={(onRowDataChange ? rowData.categorias: this.state.categorias1)}
      onChange={e => {
        if(onRowDataChange){
          var newRowData = { ...rowData, [e.target.name]: e.target.value };
          newRowData.categorias = e.target.value

          if( newRowData.categorias.length>1){
            newRowData.categorias =   newRowData.categorias.filter((cate)=>cate!=='')
          }
          else if(!newRowData.categorias&&newRowData.categorias.length===0){
            newRowData.categorias.concat({0:''})
          }                  
        
            onRowDataChange(newRowData);

        }else{
          var categorias1=e.target.value
          if( categorias1.length>1){
            categorias1=  categorias1.filter((cate)=>cate!=='')
          }
          else if(!categorias1||categorias1.length===0){
            categorias1.concat({0:''})
          }    
            this.setState({categorias1:categorias1})
        }
      }}    
      
    
    renderValue={(selected) => (
      <div style={{ display: 'flex', flexWrap: 'wrap', }}>
        {selected.map((value) => (
          <Chip variant="outlined" color='primary' key={value}

            label={value} style={{ margin: 6 }} />
        ))}
      </div>
    )}

  >
    {this.state.categorias && this.state.categorias.map(actividad =>
      (<MenuItem key={actividad.Nombre} value={actividad.Nombre} >
        {actividad.Nombre}

      </MenuItem>
      ))}


  </Select>
    </FormControl>

 const TablaDirecciones =()=>{
  this.filterRef2['direccion']=''
  this.filterRef2['numero']=''
  this.filterRef2['portal']=''
  this.filterRef2['piso']=''
  this.filterRef2['letra']=''
  this.filterRef2['porcentaje']=''
  this.filterRef2['estado']=''
return(
  <Paper style={{ height:(this.state.activarEstructura ? '70%': '40%'), marginTop:'-10%',  width:'80%'}}>
  <Paper  style={{paddingRight:10, paddingLeft:10, height: '100%', overflowY: 'auto'}}> 
       <Grid container direction="row"  alignItems="flex-start" spacing={1} style={{ marginTop:'-1%' }}>
         
          <Grid item sm={(this.state.activarEstructura ? 7:10)}>
          <h4 style={{ color: 'white', backgroundColor:'#00a049' }}>Direcciones</h4>
                  {PaperDireccciones(this.state.rowData, null)}
          </Grid>
          <Grid item sm={2}  style={{textAlign: "center"}}>
       
          <h4 style={{ color: 'white', backgroundColor:'#00a049' }}>{(this.state.activarEstructura ? "Desactivar":"Activar")} estructura direcciones</h4>
          <Checkbox
                      disabled={!this.state.editar}
                      checked={this.state.activarEstructura}
                      color="primary"
                      onChange={e => {
                        this.setState({activarEstructura:e.target.checked})
                        if(!e.target.checked&&this.state.lista_final.length>0){
                          alert("AVISO: Al desmarcar esta opción, si guardas la transacción la tabla de la extructura de direcciones será borrada")
                        }}
                      }
            />
          </Grid>
        {this.state.activarEstructura&&this.state.editar&&<Grid item sm={3}  style={{textAlign: "center"}}>

          <h4 style={{ color: 'white', backgroundColor:'#00a049' }}>CARGAR ESTRUCTURA CSV</h4>
          <Grid container  alignItems="center" spacing={2} >
          {this.state.plantillaCsv!==''&&<Grid item sm={12}  >
           <span>
                <Button
              variant="outlined"
              size="small"
              color="primary"
              startIcon={<Avatar src={'https://firebasestorage.googleapis.com/v0/b/dogarfirbase.appspot.com/o/logo%2FExcel-icon.png?alt=media&token=0d9212d1-8a26-4920-9d71-1edf020e51c6'} />}
              onClick={(event) =>  window.open(this.state.plantillaCsv.url) }>
              Plantilla ayuda
            </Button>
            </span>
            </Grid>}
            <Grid item sm={12}  >
                <CSVReader
                  //                              ref={buttonRef}
                  onDrop={e => {this.handleOnDrop2(e)}}
                  onError={this.handleOnError}
                  style={{height:700}}
                  config={{}}
                  addRemoveButton
                  onRemoveFile={this.handleOnRemoveFile}
                >
                  <span style={{ textAlign: 'center' }}>Arrastra el fichero CSV aquÍ o haz click para abrir el diálogo.</span>

                </CSVReader>
                </Grid>
            </Grid>
          </Grid>}
   </Grid>
   {this.state.activarEstructura&&<MaterialTable
      components={{
  Body: props => <MTableBody {...props} onFilterChanged={(columnId, value) => {
      props.onFilterChanged(columnId, value);
      const field= props.columns[columnId].field
      this.filterRef2[field] = value
  }} />
  }}
  options={{
      doubleHorizontalScroll:false,
      exportButton: {
        csv: true,
        pdf: false
      },
      addRowPosition: 'first',
      filtering: true,
       selection: false,
      pageSize: 15,
      exportDelimiter: ";",
      exportAllData:true,
      maxBodyHeight: "100vh",
      pageSizeOptions: [5, 15, 20],
      headerStyle: {
        backgroundColor: '#00a049',
        color: 'white',
        paddingTop: 5,
        paddingBottom: 5
      },

    }}
  icons={tableIcons}
  localization={{
    body: { editRow: { deleteText: '¿Quieres borrar la estructura de dirección?' } },
    header: {
      actions: 'Acciones'

    },
  }}
  title={<h4 style={{ color: '#00a049' }}>ESTRUCTURA DIRECCIONES PORCENTAJES</h4>}
  columns={[
    {title: 'Dirección', field: 'direccion' ,defaultFilter: this.filterRef2['direccion'],
      editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
      selecto_direcciones(rowData,false,"",onRowDataChange)
    )},
    { title: 'Número', field: 'numero', defaultFilter: this.filterRef2['numero'] },
    { title: 'Portal', field: 'portal', defaultFilter: this.filterRef2['portal']},
    { title: 'Piso', field: 'piso',  defaultFilter: this.filterRef2['piso'] },
    { title: 'Letra', field: 'letra', defaultFilter: this.filterRef2['letra']},
    { title: 'Porcentaje', field: 'porcentaje', type: 'numeric' , defaultFilter: this.filterRef2['porcentaje']},
    { title: 'Estado', field: 'estado', editable: 'never', defaultFilter: this.filterRef2['estado'] ,
    render: rowData =><h5 style={{ color: (rowData.estado==='LIBRE' ? '#00a049':'red') }}>{rowData.estado}</h5>}
  ]}

  data={this.state.lista_final}

  editable={{
    onBulkUpdate:this.state.gestion_direcciones&&!this.state.editar ? undefined : this.state.lista_final.length==0 ? undefined : (changes) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          // write your logic here
      //    this.setState({lista_final:[]})
         const cambios= Object.values(changes)
         const resul=validarCambio(cambios,this.state.lista_final)
         if(resul===true){
     
           const lista=actualizar(cambios,this.state.lista_final)
           this.setState({lista_final:lista})
           resolve();
        }else{
            alert(resul)
            reject();
        }
        
          /* setData([...data, newData]); */
         
        }, 1000);
      }),
      onRowDelete:this.state.gestion_direcciones&&!this.state.editar ? undefined :  oldData =>
      new Promise(resolve => {
        setTimeout(() => {

          var lista= this.state.lista_final
          const index = lista.indexOf(oldData);
          lista.splice(index, 1);
          this.setState({lista_final:lista})
          resolve()

   

        }, 1);
      }),
      onRowAdd:this.state.gestion_direcciones&&!this.state.editar ? undefined :  newData =>
      new Promise((resolve,reject) => {
        setTimeout(() => {
           
          var lista= this.state.lista_final
          const cambios=[{'newData':newData}]
          const resul=validarCambio(cambios,lista)
          if(resul===true){
           lista=lista.concat(newData)
           this.setState({lista_final:lista})
           alert('La tabla ha sido actualizada correctamente, para guardar los cambios debes "CERRAR" esta ventana  y  escoger "GUARDAR" la transacción, gracias.')
           resolve()
          }else{
            alert(resul)
            reject()
          }
        
          }, 1)
    })
  }}

/>   }
    </Paper>
               
                  <Button   variant="contained" 
                      onClick={e => { this.setState({gestion_direcciones:false})}}    
                      color="primary"
                      style={{top:'1%' }}
                  >Cerrar</Button>
                
</Paper>  
)
 }
const TablaGremios = (rowData, enTabla) => {
      const comunidad = rowData.uid
      const uid=  this.filterRef2['UID']
    
      if( comunidad!==uid){
        this.filterRef2['Nombre']=''
        this.filterRef2['GremioNombre']=''
        this.filterRef2['active']=''
        this.filterRef2['Telefono1']=''
        this.filterRef2['Telefono2']=''
        this.filterRef2['Correp']=''
        this.filterRef2['UID']=rowData.uid
      }
     
      const lista = this.state.ListaActivComunidad
      var ListaActivComunidad = []
      if (lista && lista.length > 0) {
        ListaActivComunidad = lista
        this.setState({ ListaActivComunidad: [] })
      } else {
        ListaActivComunidad = rowData.ListaActivComunidad
      }

      //      this.setState({ListaActivComunidad:rowData.ListaActivComunidad})
      const comun = rowData
      return (   
            <MaterialTable
             components={{
              Body: props => <MTableBody {...props} onFilterChanged={(columnId, value) => {
                  props.onFilterChanged(columnId, value);
                  const field= props.columns[columnId].field
                  this.filterRef2[field] = value
              }} />
              }}
              options={{
                doubleHorizontalScroll:enTabla,
                exportButton: {
                  csv: true,
                  pdf: false
                },
                addRowPosition: 'first',
                filtering: true,
                pageSize: 15,
                exportDelimiter: ";",
                exportAllData:true,
                maxBodyHeight:(enTabla  ? "150vh": null),
                pageSizeOptions: [15, 30, 50],
                headerStyle: {
                  backgroundColor:(enTabla  ? 'darkseagreen': '#00a049'),
                  color: 'white',
                  paddingTop: 5,
                  paddingBottom: 5
                },

                //    selection: true,      
                //  selectionProps: rowData => ({
                //     disabled: this.state.modificar,
                //   color: 'primary'
                // })1

              }}
              icons={tableIcons}
              localization={{
                body: { editRow: { deleteText: '¿Quieres borrar la actividad?' } },
                header: {
                  actions: 'Acciones'


                },
              }}
              title={<h2 style={{ color:(enTabla  ? 'darkseagreen': '#00a049') }}>ACTIVIDADES-GREMIOS </h2>}
              columns={[
                {
                  title: 'Actividad', field: 'Nombre', defaultFilter: this.filterRef2['Nombre'], editable: 'onAdd', lookup: this.state.lista_actividades,
                  editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                    <FormControl>
                      <Select
                        labelId="demo-mutiple-chip-label"
                        //   disabled={ this.state.modificar || rowData.GremioNombre ===""}
                        id="demo-mutiple-chip"

                        value={rowData.Nombre}
                        onChange={e => {
                          const newRowData = { ...rowData, Nombre: e.target.value };
                          rowData.Nombre = e.target.value
                          var filtrado = this.busquedaAyuda(rowData.Nombre)
                          newRowData.ayudaBusqueda = filtrado;

                          onRowDataChange(newRowData);
                        }}
                      >
                        {this.state.activs && this.state.activs.map((gremio) => (
                          <MenuItem value={gremio.Nombre} >
                            {gremio.Nombre}
                          </MenuItem>
                        ))
                        }
                      </Select>
                    </FormControl>
                  )
                },

                {
                  title: 'Gremio', field: 'GremioNombre', defaultFilter: this.filterRef2['GremioNombre'], lookup: this.state.lista_gremios,
                  editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                    <FormControl>
                      <Select
                        labelId="demo-mutiple-chip-label"
                        disabled={!rowData.Nombre}
                        id="demo-mutiple-chip"

                        value={rowData.GremioNombre}
                        onChange={e => {
                          const newRowData = { ...rowData, GremioNombre: e.target.value };
                          rowData.GremioNombre = e.target.value
                          var gremio = this.state.gremios.filter(gremio => gremio.Nombre === e.target.value)
                          if (gremio && gremio.length > 0) {
                            newRowData.Telefono1 = gremio[0].Telefono1
                            newRowData.Telefono2 = gremio[0].Telefono2
                            newRowData.Correo = gremio[0].email
                            newRowData.uid = gremio[0].uid
                          } else {
                            newRowData.Telefono1 = ""
                            newRowData.Telefono2 = ""
                            newRowData.Correo = ""
                            newRowData.uid = ""
                          }

                          onRowDataChange(newRowData)

                        }}
                      >
                        {(rowData.ayudaBusqueda ? rowData.ayudaBusqueda.map((gremio) => (
                          <MenuItem value={gremio.Nombre} >
                            {gremio.Nombre}
                          </MenuItem>
                        ))
                          : this.state.gremios.map((gremio) => (
                            <MenuItem value={gremio.Nombre} >
                              {gremio.Nombre}
                            </MenuItem>
                          ))
                        )}

                      </Select>
                    </FormControl>
                  )
                },

                {
                  title: "Gremio Auxiliar",
                  field: "active", defaultFilter: this.filterRef2['active'],
                  type: "boolean",
                  editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                    <Checkbox
                      {...p}
                      disabled={!rowData.GremioNombre}
                      checked={rowData.active}
                      color="primary"
                      onChange={e => {
                        const newRowData = { ...rowData, active: e.target.checked };
                        rowData.active = e.target.checked
                        if (e.target.checked) { newRowData.Auxiliar = "1" }
                        else { newRowData.Auxiliar = "-1" }

                        onRowDataChange(newRowData);
                      }}
                    />
                  )
                },
                { title: 'Teléfono Principal', field: 'Telefono1', defaultFilter: this.filterRef2['Telefono1'], editable: 'never' },
                { title: 'Teléfono Secundario', field: 'Telefono2', defaultFilter: this.filterRef2['Telefono2'], editable: 'never' },
                { title: 'Correo', field: 'Correo', defaultFilter: this.filterRef2['Correo'], editable: 'never' }
              ]}

              data={ListaActivComunidad}
              editable={{
                //     isEditable: (rowData) => !this.state.modificar, 
                //   isDeletable: (rowData) => !this.state.modificar,

                onRowAdd: this.state.abrir_cambios&&!this.state.editar ? undefined : newData => 
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                     // resolve();

                      if (!newData.Nombre) {
                        alert("Debes seleccionar 1 actividad")
                         reject(); return;
                      }
                      var comprobar = comun.ListaActivComunidad
                        .filter(actividad => actividad.Nombre === newData.Nombre);
                      if (comprobar && comprobar.length > 0) {
                        alert("La actividad " + comprobar[0].Nombre + " ya la tienes asiganda a esta comunidad")
                        reject(); return;
                      }

                      var Actividad = this.state.activs.filter(actividad => actividad.Nombre === newData.Nombre)
                      //      var cargar=[];
                      var gremio1 = "-1"
                      if (!newData.GremioNombre || newData.GremioNombre === '') {

                        newData.Telefono1 = ""
                        newData.Telefono2 = ""
                        newData.Correo = ""
                        newData.Auxiliar = "-1"
                        newData.active = false

                      } else { gremio1 = newData.uid }


                      this.props.firebase.comu(comunidad + '/ActivGremios/').update({
                        [Actividad[0].uid]: gremio1,
                      })
                        .then(() => {
                          this.props.firebase.comu(comunidad + '/ActivGremioAuxiliar/').update({
                            [Actividad[0].uid]: "-1",
                          })
                     //     const comuni= Actividad[0].Comunidad.concat(comun.Id)
            
                       })
                        .then(() => {


                          const data1 = this.state.comus;
                          const index1 = data1.indexOf(comun);

                          let data = comun.ListaActivComunidad;

                          var com = []
                          com[0] = newData
                          data = com.concat(data)
                          comun.ListaActivComunidad = data



                          data1[index1] = comun;
                          this.setState({ comus: data1 })
                          resolve()

                        })
                    }, 1);
                  }),
                onRowUpdate: this.state.abrir_cambios&&!this.state.editar ? undefined :  (newData, oldData) => 
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                      var gremio1 = "-1"
                      if (oldData) {

                        var Actividad = this.state.activs.filter(actividad => actividad.Nombre === newData.Nombre)

                        if (!newData.GremioNombre || newData.GremioNombre === '') {

                          newData.Telefono1 = ""
                          newData.Telefono2 = ""
                          newData.Correo = ""
                          newData.Auxiliar = "-1"
                          newData.active = false

                        } else { gremio1 = newData.uid }



                        this.props.firebase.comu(comunidad + '/ActivGremioAuxiliar/').update({
                          [Actividad[0].uid]: newData.Auxiliar,
                        })
                        this.props.firebase.comu(comunidad + '/ActivGremios/').update({
                          [Actividad[0].uid]: gremio1,
                        }).then(() => {

                          var data = ListaActivComunidad
                          data[data.indexOf(oldData)] = newData;
                          //  var data1 = this.state.comus

                          //      data1[data1.indexOf(comun.ListaActivComunidad)] = data
                          this.setState({ ListaActivComunidad: data })


                          //   oldData =newData
                          //this.componentDidMount();
                        })
                      }
                    }, 1);
                  }),
                onRowDelete: this.state.abrir_cambios&&!this.state.editar ? undefined :  oldData =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      var Actividad = this.state.activs.filter(actividad =>
                        actividad.Nombre === oldData.Nombre)



                      this.props.firebase.comu(comunidad + '/ActivGremioAuxiliar/').update({
                        [Actividad[0].uid]: "-1",
                      })
                      //     this.componentDidMount();
                      this.props.firebase.comu(comunidad + '/ActivGremios/' + Actividad[0].uid).remove()
                        
                        .then(() => {
                          const data1 = this.state.comus;
                          const index1 = data1.indexOf(comun);

                          let data = comun.ListaActivComunidad;
                          const index = data.indexOf(oldData);
                          data.splice(index, 1);
                          comun.ListaActivComunidad = data

                          data1[index1] = comun;
                          this.setState({ comus: data1 })
                          resolve()
                        })
                    }, 1);
                   })
              }}

            />      )
    }
    var totalMensajesOK= 0
    var totalMensajesKO= 0

    const resultado_aviso= this.state.resultado_aviso
    if(resultado_aviso&&resultado_aviso.length>0){
       totalMensajesOK= resultado_aviso.filter((usuario)=>usuario.error==='NO').length
       totalMensajesKO= resultado_aviso.filter((usuario)=>usuario.error==='SI').length
    }

 //   var gremio_seguros = this.state.gremio_seguros
  //  gremio_seguros = gremio_seguros.concat({ Nombre: "" })
    return (
      <Grid>
          {!this.state.open&&<>
        <Cabecera />
        <hr />
        <Grid style={{ position: 'absolute', right: 20, top: 20 , maxWidth:"15%", maxHeight:"15%"}}>
          <Button variant="contained" color="primary" onClick={() => {
                this.setState({ mostrarAyuda: true })
             }}>
            AYUDA (TUTORIALES)
          </Button>
        </Grid>

        <BotonesTriger /> </>}
        <ThemeProvider theme={theme}>
     
       
            <Snackbar anchorOrigin={{ vertical, horizontal }}
              key={`${this.state.vertical},${this.state.horizontal}`}
              open={this.state.snack}
              autoHideDuration={7000}
              onClose={this.handleClose}
              message={this.state.error2}
              action={
                <div>
                  <Button color="primary" size="small" onClick={this.handleClose}>
                    Cerrar
                              </Button>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              }
            />
            <Snackbar anchorOrigin={{ vertical, horizontal }}
              key={`${this.state.vertical},${this.state.horizontal}`}
              open={this.state.snack2}
              autoHideDuration={5000}
              onClose={this.handleClose}
              message={this.state.comunidadBorrar}
              action={
                <div>
                  <Button onClick={this.anularComunidad}>
                    <Fab size="small" color="primary" aria-label="add" variant="extended">
                      <DoneOutlineIcon />
                                        CONFIRMAR
                                       </Fab>
                  </Button>
                  <Button onClick={this.handleClose}>
                    <Fab size="small" color="secondary" aria-label="add" variant="extended">
                      <CloseIcon />
                                        CANCELAR
                                       </Fab>
                  </Button>
                </div>
              }
            />
            {!this.state.open&&  <Grid container direction="row" spacing={1} style={{marginLeft:'1%', width:'98%'}} >
              <Grid item sm={4}  style={{ textAlign:'start'}}>
                <FormControl styles={{ minWidth: 120 }} >
                  <Select
                    labelId="comunidad"
                    id="comunidad"
                    name="comunidad"
                    value={this.state.comunidad}
                    onChange={this.onChange}
                  >
                    <MenuItem key={-1} value="">{""}</MenuItem>
                    {this.state.comus2 && this.state.comus2.map(comu => (

                      <MenuItem key={comu.Id} value={comu.Id}>{comu.Id}</MenuItem>
                    ))
                    }
                  </Select>
                  <FormHelperText>Búsqueda rápida por ID de comunidad</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={4}  style={{ textAlign: "center"}}>
                <Tooltip title="Recupera las comunidades de la base de datos">
                  <Button onClick={this.BuscarComus} variant="outlined"
                    size="large" color="primary" startIcon={<SearchIcon />}>BUSCAR COMUNIDADES
                  </Button>
                </Tooltip>
       
              </Grid>

              <Grid item sm={4}  style={{ textAlign: 'end'}}>
                <Tooltip title="Envía avisos a las comunidades previamente seleccionadas">
                
                    <Button onClick={this.dialogoEnvio()} variant="outlined"
                      disabled={!this.state.comus_enviar.length > 0}
                      size="large" color="primary" startIcon={<SendIcon />}>ENVIAR AVISO
                    </Button>
                
                </Tooltip>

              </Grid>
             {false&&this.state.comus.length > 0 && this.state.mostrarTabla && <Grid item xs style={{ height: '20%', marginRight: 20, marginTop: -90 }}>
                <h5 style={{ color: '#00a049', textAlign: 'center', alignSelf: 'center', marginBottom: 5 }}>SUBIR COMUNIDADES FICHERO</h5>
                <CSVReader
                  //                              ref={buttonRef}
                  onDrop={this.handleOnDrop}
                  onError={this.handleOnError}
                  style={{}}
                  config={{}}
                  addRemoveButton
                  onRemoveFile={this.handleOnRemoveFile}
                >
                  <span style={{ textAlign: 'center' }}>Arrastra el fichero CSV aquÍ o haz click para abrir el diálogo.</span>

                </CSVReader>

              </Grid>}

            </Grid>}

            {this.state.mostrarTabla &&
              
           <Paper style={{width:'98%', overflowX:'auto', margin:10}}>          

                  <MaterialTable
                  
                    components={{
                      Body: props => <MTableBody {...props} onFilterChanged={(columnId, value) => {
                          props.onFilterChanged(columnId, value);
                          const field= props.columns[columnId].field
                          this.filterRef[field] = value
                      }} />
                      }}

                    options={{
                      doubleHorizontalScroll:true,
                      exportButton: {
                        csv: true,
                        pdf: false
                      },
                      addRowPosition: 'first',
                      filtering: true,
                      selection: true,
                      pageSize: 15,
                      exportDelimiter: ";",
                      exportAllData:true,
                      maxBodyHeight: "70vh",
                      pageSizeOptions: [5, 15, 20],
                      headerStyle: {
                        backgroundColor: '#00a049',
                        color: 'white',
                        paddingTop: 5,
                        paddingBottom: 5
                      },

                    }}
                         //          onRowClick={(event, rowData, togglePanel) =>{ togglePanel()}}
                    onSelectionChange={(rows) => {
                      if (rows.length > 0) {
                        this.setState({ comus_enviar: rows })

                      } else { this.setState({ comus_enviar: [] }) }
                      //alert('You selected ' + rows[0].Nombre + ' rows')}
                    }
                    }

                    icons={tableIcons}
                    localization={{
                      body: {
                        editRow: {
                          saveTooltip: "Guardar",
                          cancelTooltip: "Cancelar",
                          deleteText: '¿Quieres borrar la comunidad?'
                        },
                        addTooltip: "Crear una nueva",
                        deleteTooltip: "Borrar",
                        editTooltip: "Modificar"
                      },
                      header: {
                        actions: 'Acciones'

                      },
                      toolbar: {
                        nRowsSelected: '{0} Comunidad(es) seleccionadas para el envío de AVISO'
                      },
                    }}
                    title={<h2 style={{ color: '#00a049' }}>Comunidades</h2>}
                    columns={[
                      {
                        title: 'Nombre', field: 'Nombre', defaultFilter: this.filterRef['Nombre'], cellStyle: {
                          width: 300,
                          minWidth: 300
                        },
                        headerStyle: {
                          width: 300,
                          minWidth: 300
                        }
                      },
                      { title: 'CIF', field: 'Cif', defaultFilter: this.filterRef['Cifcomunidad'], cellStyle: {
                        width: 100,
                        minWidth: 100
                      },
                      headerStyle: {
                        width: 100,
                        minWidth: 100
                      }
                     },
                      { title: 'Fecha de alta', field: 'Fecha_Creacion', defaultFilter: this.filterRef['Fecha_Creacion'], editable: 'never', 
                      customSort: (a, b) =>
                      {  
                        const bF=b.Fecha_Creacion.split('/')
                        const aF=a.Fecha_Creacion.split('/')                    
                        return(Number(aF[2]+aF[1]+aF[0]) - Number(bF[2]+bF[1]+bF[0]))
                        }
                    ,
                         
                    },

                      {
                        title: 'Id Comunidad', field: 'Id', defaultFilter: this.filterRef['Id'], editable: 'onAdd',
                        editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                          <div>
                            <TextField InputLabelProps={{ shrink: true }}
                              margin="normal"
                              fullWidth
                              inputProps={{ maxLength: 5 }}
                              id="id"
                              name="id"
                              value={rowData.Id}
                              helperText="max 5 dígitos"
                              type="text"
                              //    onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                              onChange={e => {
                                const newRowData = { ...rowData, Id: e.target.value };
                                rowData.Id = e.target.value
                                var melon = despacho.Codigo + rowData.Id
                                if (rowData.Codigo_seguridad) {

                                  newRowData.Codigo_vecino = melon.concat(rowData.Codigo_seguridad)

                                } else { newRowData.Codigo_vecino = melon }

                                onRowDataChange(newRowData);
                              }}
                            />
                          </div>
                        )
                      },

                      {
                        title: 'Código Seguridad', field: 'Codigo_seguridad', defaultFilter: this.filterRef['Codigo_seguridad'],
                        editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                          <div>

                            <TextField InputLabelProps={{ shrink: true }}

                              margin="normal"
                              fullWidth
                              inputProps={{ maxLength: 5 }}
                              id="id"
                              name="id"
                              helperText="max 5 dígitos"
                              value={rowData.Codigo_seguridad}
                              type="text"
                              //  onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, '') }}
                              onChange={e => {
                                const newRowData = { ...rowData, Codigo_seguridad: e.target.value };
                                rowData.Codigo_seguridad = e.target.value
                                if (rowData.Id) {
                                  const melon = despacho.Codigo + rowData.Id
                                  newRowData.Codigo_vecino = melon.concat(rowData.Codigo_seguridad)

                                } else {
                                  //newRowData.Codigo_vecino= rowData.Codigo_seguridad  
                                }

                                onRowDataChange(newRowData);
                              }}
                            />
                          </div>
                        )
                      },
                      {
                        title: 'Código Vecino', field: 'Codigo_vecino', defaultFilter: this.filterRef['Codigo_vecino'], cellStyle: {
                          width: 200,
                          minWidth: 200
                        },
                        headerStyle: {
                          width: 200,
                          minWidth: 200
                        },
                        editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
                          const melon = rowData.Codigo_vecino
                          //    if(rowData.Codigo_vecino){const melon=despacho.Codigo+rowData.Codigo_vecino}
                          // else{}
                          return (
                            <div>

                              <TextField InputLabelProps={{ shrink: true }}
                                //    disabled={true}
                                margin="normal"
                                fullWidth
                                id="id"
                                name="id"
                                helperText="Cod Admin + Cod Comunidad + Cod Seguridad"
                                value={melon}
                                type="text"
                              />
                            </div>
                          )


                        }
                       },
                      {
                        title: 'Categorías', field: 'categorias', defaultFilter: this.filterRef['Categorias'],
                        render: rowData =>selecto(rowData,'categorias',false),

                        editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
                          if (!rowData.categorias || rowData.categorias.length === 0) {
                            rowData.categorias = []
                            rowData.categorias[0] = ""
                          }
                          return (
                            selecto2(rowData, onRowDataChange)

                         
                          )
                        }
                      },
                      {
                        title: 'Zonas', field: 'zonas', defaultFilter: this.filterRef['zonas'],
                        render: rowData =>botonZonas(rowData)
                         ,
                        editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                          botonZonas(rowData)
                        )
                      },
                      {
                        title: 'Direcciones', field: 'direcciones', defaultFilter: this.filterRef['direcciones'],
                        render: rowData =>botonDirecciones(rowData)
                         ,
                        editComponent: ({ onChange, onRowDataChange, rowData, ...p }) =>
                        {
                          if(!this.state.editar){
                            this.setState({editar:true, activarEstructura:(rowData.activarEstructura ? rowData.activarEstructura:false),
                              lista_final:[] })}
                          return(botonDirecciones(rowData))}
                      },                
                      { title: 'Código Postal', field: 'Cp', defaultFilter: this.filterRef['Cp'], },
                      { title: 'Población', field: 'Poblacion', defaultFilter: this.filterRef['Poblacion'], },
                      {
                        title: 'Compañía de Seguros', field: 'Seguro_nombre', defaultFilter: this.filterRef['Seguro_nombre'], tooltip: 'Compañia de seguros asociada a la Comunidad',
                        editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                          selecto_sin_chip(rowData,onRowDataChange,false,"")
                        )
                      },

                      { title: 'Póliza', field: 'Poliza' , defaultFilter: this.filterRef['Poliza'], cellStyle: {
                        //  width: 400,
                          minWidth: 250
                        },
                        headerStyle: {
                         // width: 400,
                          minWidth: 250
                        },
                         editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
                     //   const melon = rowData.Poliza
                        //    if(rowData.Codigo_vecino){const melon=despacho.Codigo+rowData.Codigo_vecino}
                        // else{}
                        return (
                          <div>

                            <TextField InputLabelProps={{ shrink: true }} //inputProps={{ type: 'number'}}
                              disabled={!rowData.Seguro_nombre|| rowData.Seguro_nombre===''}
                              margin="normal"
                              fullWidth
                              id="id"
                              name="id"
                              helperText="Número de póliza seguro"
                              value={rowData.Poliza}
                              type="text"
                              onChange={e => {
                                const newRowData = { ...rowData, Poliza: e.target.value };
                                rowData.Poliza = e.target.value

                                            
                                onRowDataChange(newRowData);
                              }}
                              
                            />
                          </div>
                        )
                        }
                      },

                      { title: 'Cuenta IBAN', field: 'IBAN', defaultFilter: this.filterRef['IBAN'], },
                      { title: 'Número de Vecinos', field: 'Vecinos', defaultFilter: this.filterRef['Vecinos'], },
                     {
                        title: 'Pago Mensual APP', field: 'Pago_App', defaultFilter: this.filterRef['Pago_App'], type: "boolean",

                        render: rowData =>
                          <Checkbox
                            checked={rowData.Pago_App}
                            color="primary"

                          />,
                        editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                          <Checkbox
                            {...p}
                            //    disabled= {!rowData.Roles|| rowData.Roles.length===0}
                            checked={rowData.Pago_App}
                            color="primary"
                            onChange={e => {
                              const newRowData = { ...rowData, Pago_App: e.target.checked };
                              rowData.Pago_App = e.target.checked
                              onRowDataChange(newRowData);
                            }}
                          />
                        )
                      },

                      {
                        title: 'Observaciones', field: 'Observaciones',  defaultFilter: this.filterRef['Observaciones'],cellStyle: {
                          width: 400,
                          minWidth: 400
                        },
                        headerStyle: {
                          width: 400,
                          minWidth: 400
                        },
                        render: rowData =>
                          <div>
                            <TextField InputLabelProps={{ shrink: true }}
                              margin="normal"
                              fullWidth
                              multiline
                              rowsMax={3}
                              
                              id="descripcion"
                              name="descripcion"
                              value={rowData.Observaciones}
                              type="text"
                            />
                          </div>,

                        editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
                          if (!rowData.descripcionfinal) { rowData.descripcionfinal = '' }
                          return (
                            <div>
                              <TextField InputLabelProps={{ shrink: true }}
                                margin="normal"
                                fullWidth
                                multiline
                                rowsMax={3}
                                name="descripcionfinal"
                                id="descripcionfinal"
                                value={rowData.descripcionfinal}
                                onChange={e => {
                                  const newRowData = { ...rowData, descripcionfinal: e.target.value };
                                  rowData.descripcionfinal = e.target.value

                                  onRowDataChange(newRowData);
                                }}
                                variant="filled"
                                type="text"
                              />
                            </div>)
                        }
                      },

                    ]}
                   onRowClick={(event, rowData, togglePanel) => {
                      const esbueno= ValidateIBAN(rowData.IBAN)||rowData.IBAN===''
                    if(esbueno){
                      this.setState({errorIBANComunidad:false,textoError:''})
                    }else{ this.setState({errorIBANComunidad:true,textoError:'Formato incorrecto'})}

                  
                    this.traerDirecciones(rowData)
                    this.setState({rowData:rowData, abrir_cambios:true,tipoRegistro:'modificacion',
                       Nombre:rowData.Nombre, Cif:rowData.Cif, activarEstructura:(rowData.activarEstructura ? rowData.activarEstructura:false),
                      Fecha_Creacion:rowData.Fecha_Creacion,Id:rowData.Id,Codigo_seguridad:rowData.Codigo_seguridad,
                      Codigo_vecino:despacho.Codigo+rowData.Id+rowData.Codigo_seguridad,direcciones1:(rowData.Direcciones ? rowData.Direcciones :[]),
                      categorias1:(rowData.categorias ? rowData.categorias:[]), editar:false,direccionfinal1:'',
                      Cp:rowData.Cp, Poblacion:rowData.Poblacion,Seguro_nombre:rowData.Seguro_nombre,
                      Seguro:rowData.Seguro, Pago_App:rowData.Pago_App,
                      Poliza:rowData.Poliza, IBAN:rowData.IBAN,Vecinos:rowData.Vecinos,
                      Observaciones:rowData.Observaciones,
                      descripcionfinal:(rowData.descripcionfinal ? rowData.descripcionfinal:null)

                      })
                    }}
                    detailPanel={[
                      {
                        tooltip: 'Abrir Actividades-Gremio',
                      
                        render: rowData =>
                          <Grid style={{ backgroundColor: 'darkseagreen', maxWidth: 1300 }}>
                            <Paper style={{ maxHeight: 500, overflow: 'auto', width: 1100, marginLeft: 100 }}>
                                {TablaGremios(rowData,true)}
                             </Paper>
                          </Grid> 
                      }]}
                    data={comus}
                     editable={{
                      onRowUpdateCancelled: rowData =>  this.setState({editar:false}),
//                          isEditable: (rowData) =>                           this.setState({editar:true}), 
                      //  isDeletable: (rowData) => !this.state.modificar,
             
    //                  onRowUpdate: (newData, oldData) =>{
                      
      //                newData.activarEstructura=this.state.activarEstructura
          //            this.rowActualiza(newData, oldData)
        //          
                  
            //      }      
              //       ,
                      onRowDelete: oldData =>
                        new Promise(resolve => {
                          setTimeout(() => {
                            resolve()

                            this.props.firebase.comu(oldData.uid).remove()


                              .then(() => {
                                if (oldData.categorias && oldData.categorias > 0) {
                                  oldData.categorias.forEach((value, key) => {
                                    var clave = this.state.categorias.filter(cate => cate.Nombre === value)
                                    if (clave.length > 0 && clave) {
                                      this.props.firebase.categoria(clave[0].uid +
                                        "/Comunidad/" + oldData.Id).remove()

                                    }
                                  }

                                  );
                                }
                                var data = this.state.comus.filter(comu => comu.uid !== oldData.uid)
                                this.setState({
                                  snack2: false, snack: true,
                                  error2: "La comunidad con  Id " + oldData.Id + " ha sido borrada", comus: data
                                });


                                this.state.activs.forEach((change) => {
                                  const comi0 = change.Comunidad
                                  if (comi0&&comi0.length>0){
                                    const comun = comi0.filter(comu=> comu!==oldData.Id)
                                    if(comun&&comun.length>0){
                                      change.Comunidad= comun
                                      this.props.firebase.activ(change.uid + "/").update({"Comunidad":comun})
                    
                                    }
  
                                  }
                                })

                               
                              })
                              .catch(error => {
                                this.setState({ snack2: false, snack: true, error2:
                                   "Ha habido un problema y no se ha podido borrar "+error });
                                this.setState({ error });
                              });

                          }, 1);
                        })
                    }}
                    actions={ [
                      
                      {
                          icon:  () => <AddBox />,
                          tooltip: 'Añadir un nuevo usuario',
                          // This makes add button to appear in table toolbar instead for each row
                          isFreeAction: true,
                          disabled:despacho.Tipo&&despacho.Tipo==='Comunidades',
                          onClick: (event, rowData) => {
                            this.setState({abrir_cambios:true, editar:true, tipoRegistro:'nuevo',
                            rowData:[], Nombre:'', Cif:'',Fecha_Creacion:'',Id:'',Codigo_seguridad:'',
                            Direcciones:[],direcciones1:[],
                            Codigo_vecino:'',categorias1:[],direccionfinal1:'',Cp:'', Poblacion:'',Seguro_nombre:'',
                            Seguro:'',   Poliza:'', IBAN:'',Vecinos:'', Observaciones:'',descripcionfinal:'',
                          
                           })
                       }
                    }
                    ]}
                  />


           </Paper>
              }

          <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              open={this.state.abrir_cambios}
              onClose={e=>this.cerrarGestionCambios()}

            >
            <Paper style={{ height: '60%', marginTop:'-10%',  width:'80%'}}>
                <Paper  style={{padding:10,   
                maxHeight: '100%', overflowY: 'auto',
              
              }}> 
          
                <h3 style={{ color: 'white', backgroundColor:'#00a049', marginTop:'-0.5%' }}>Comunidad: {this.state.Nombre} </h3>
               <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                <Grid container spacing={1}>
                  <Grid item sm={4}>
                  {Textofield ('Nombre','Nombre de la comunidad',!this.state.editar,null,this)}
                  </Grid>
                  <Grid item sm={4}>
                  {Textofield ('Cif','Cif de la comunidad',!this.state.editar,null,this)}
                  </Grid>
                  <Grid item sm={4}>
                  {Textofield ('Fecha_Creacion','Feha de creación de la comunidad',true,null,this)}
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item sm={4}>
                  {Textofield ('Id','Identificador de la comunidad',this.state.tipoRegistro==='modificacion',5,this)}
                  </Grid>
                  <Grid item sm={4}>
                  {Textofield ('Codigo_seguridad','Código seguridad (max 5 dígitos)',!this.state.editar,5,this)}
                  </Grid>
                  <Grid item sm={4}>
                  {Textofield ('Codigo_vecino','Código del vecino para su registro',true,null,this)}
                  </Grid>
                

                 
                </Grid>

                <Grid container spacing={1}>
                  <Grid item sm={8}   style={{ textAlign: "center"}}>
                  <h5 style={{ color: 'white', backgroundColor:(!this.state.editar ? 'darkseagreen':'#00a049') }}>Categorías</h5>
                  {!this.state.editar&&selecto(this.state.rowData,'categorias1',true,"Categorías")}
                  {this.state.editar&&selecto2(this.state.rowData, null)}
                
                  </Grid>
                  <Grid item sm={2}  style={{ textAlign: "center"}}>
                  <h5 style={{ color: 'white', backgroundColor:(!this.state.editar ? 'darkseagreen':'#00a049') }}>Zonas Comunes</h5>
                  { botonZonas(this.state.rowData)}
                
                  </Grid>
            
                  <Grid item sm={2}  style={{ textAlign: "center"}}>
                  <h5 style={{ color: 'white', backgroundColor:(!this.state.editar ? 'darkseagreen':'#00a049') }}>Direcciones</h5>
                          {botonDirecciones(this.state.rowData,!this.state.editar)} 
                    </Grid>
                 
             
                   
                </Grid>
                <Grid container spacing={1}>
                    <Grid item sm={3}>
                    {Textofield ('Cp','Código Postal',!this.state.editar,null,this)}
                    </Grid>
                    <Grid item sm={3}>
                    {Textofield ('Poblacion','Población',!this.state.editar,null,this)}
                    </Grid>
                    <Grid item sm={3}>
                    {selecto_sin_chip(this.state.rowData,null,!this.state.editar,"Compañía de seguros")}
        
                    </Grid>
                    <Grid item sm={3}>
                    {Textofield ('Poliza','Póliza de seguro',!this.state.editar,null,this)}
        
                    </Grid>
                </Grid>
                   
                <Grid container spacing={1}>
                    <Grid item sm={12}>
                    {!this.state.editar&&Textofield ('Observaciones',' Observaciones',true,null,this)}          
                    {this.state.editar&&Textofield ('descripcionfinal',' Ecribe la nueva observación',false,null,this)}
                    </Grid>                    
                </Grid>
             
                <Grid container spacing={1}>
          
                   
                    <Grid item sm={4}>
                    {Textofield ('Vecinos','Número de Vecinos',!this.state.editar,null,this)}
        
                    </Grid>
                    <Grid item sm={4}   style={{textAlign: "center", marginTop:'-0.5%'}}>
                    <h5 style={{ marginBottom:-2, color: 'white', backgroundColor:(this.state.editar ? '#00a049':'darkseagreen') }}>Pago mensual App</h5>
                  
                    {checkBoxStandar('Pago_App','',this,!this.state.editar)}
                
                                 
                </Grid>
                    <Grid item sm={4}>
                    {TextofieldIBAN ('IBAN','Iban comunidad',!this.state.editar,null,this,'errorIBANComunidad')}
                 
                  </Grid>
                   
                  
                </Grid>
               
             
               {this.state.tipoRegistro!=='nuevo'&& <Grid container spacing={1}>     
                      <Grid item sm={12}>               
                          {TablaGremios(this.state.rowData,false)}
                      </Grid>
                      
                 </Grid>}
 
                </Grid>
         
                </Paper>
                {!this.state.editar&&
               
                  <Button   variant="contained" 
                      onClick={e => { this.setState({editar:true})
                      this.traerDirecciones(this.state.rowData)
                    }}    
                      color="primary"
                      style={{top:'1%' }}
                  >Editar</Button>
                }
               {this.state.editar&&
               <>
             
                  <Button  variant="contained"    style={{top:'1%', backgroundColor:'orange' }}
                      onClick={e => { 
                        if(this.state.tipoRegistro==='nuevo')  {   this.setState({ abrir_cambios:false})}
                        else{
                        const rowData=this.state.rowData
                        this.traerDirecciones(this.state.rowData)

                        this.setState({ abrir_cambios:true, Nombre:rowData.Nombre, Cif:rowData.Cif,
                          Fecha_Creacion:rowData.Fecha_Creacion,Id:rowData.Id,Codigo_seguridad:rowData.Codigo_seguridad,
                          Codigo_vecino:despacho.Codigo+rowData.Id+rowData.Codigo_seguridad, editar:false,
                          direcciones1:(rowData.Direcciones ? rowData.Direcciones:[]),direccionfinal1:'',Poblacion:rowData.Poblacion,
                          Cp:rowData.Cp, Vecinos:rowData.Vecinos, IBAN:rowData.IBAN,Poliza:rowData.Poliza,
                          Observaciones:rowData.Observaciones,descripcionfinal:"",  Pago_App:rowData.Pago_App,  
                          Seguro:rowData.Seguro,                   
                          Seguro_nombre:rowData.Seguro_nombre, categorias1:(rowData.categorias ? rowData.categorias :[]),
                        
                          activarEstructura:(rowData.activarEstructura ? rowData.activarEstructura:false)

                        })
                  
                      }
                      }}    
                      color="primary"

                  >Cancelar</Button>
          
                 
                  <Button variant="contained"   color="primary" type="submit"  style={{top:'1%', left:5 }}

                    disabled={this.state.Nombre.trim()===''||this.state.Codigo_seguridad.trim()===''||this.state.Cif.trim()===''|| this.state.direcciones1.length===0}
                    onClick={e => {
               //       this.gestionIbanStripe()
                      this.setState({editar:false})
                      if(!this.state.activarEstructura){    this.setState({lista_final:[]})}
                      const newData={...this.state.rowData, Nombre:this.state.Nombre,Direcciones:this.state.direcciones1,
                              Codigo_vecino:this.state.Codigo_vecino, categorias:this.state.categorias1,
                              Coeficientes:(this.state.activarEstructura ? pasarCoeficientes(this.state.lista_final):[]),
                              Poblacion:this.state.Poblacion,IBAN:this.state.IBAN, Vecinos:this.state.Vecinos,
                              descripcionfinal:this.state.descripcionfinal, Pago_App:this.state.Pago_App,
                              Seguro:this.state.Seguro,
                              Seguro_nombre:this.state.Seguro_nombre,Poliza:this.state.Poliza,Observaciones:this.state.Observaciones,
                              Cp:this.state.Cp,Cif:this.state.Cif, Id:this.state.Id,Codigo_seguridad:this.state.Codigo_seguridad,
                              activarEstructura:(this.state.activarEstructura ? this.state.activarEstructura:false),
                              Direcciones_cod: (this.state.activarEstructura ? pasarDirecciones(this.state.lista_final,this.state.direcciones1):[]),
                              Añadido_direcciones: (this.state.activarEstructura ? pasarAñadido(this.state.lista_final):[]),
                            
                            }

                            
                         if(this.state.tipoRegistro!=='nuevo')  {   
                                let guardar=false
                                if(this.state.abrir_cambios){guardar=true}
                                this.setState({open:true,abrir_cambios:false,abrirContraseña:false,mostrarTabla:false})
                          
                              this.rowActualiza(newData, this.state.rowData).then((data)=>{

                                if(data){this.setState({rowData:newData})}
                                this.setState({open:false,mostrarTabla:true})
                                if(guardar){this.setState({abrir_cambios:true})}
                                
                              })  .catch((error)=>{

                               
                                this.setState({open:false,mostrarTabla:true,editar:true})
                                if(guardar){this.setState({abrir_cambios:true})}
                            
                                
                              })  
                        }else{
                          this.setState({abrir_cambios:false,open:true,mostrarTabla:false})
                           this.rowAñadir(newData).then((data)=>{
                              this.setState({abrir_cambios:true,open:false,mostrarTabla:true, editar:true})
                              if(data){ this.setState({abrir_cambios:false})}
                        })}   
                    }}    
                  >Guardar</Button>
                  </>
                }
                </Paper>
            
            </Modal>
       
              <Dialog open={this.state.abrirAviso} /*onClick={thi.handleClickClose}*/ aria-labelledby="form-dialog-title"
              fullWidth="true" maxWidth="md" scroll="paper"          >
              <DialogTitle id="form-dialog-title">ENVÍO AVISO</DialogTitle>
              <DialogContent dividers>

                <TextField
                  autoFocus
                  margin="dense"
                  id="titulo"
                  name="texto_titulo"
                  label="Añade el título del AVISO"
                  type="text"
                  value={texto_titulo}
                  onChange={this.onChange}
                  fullWidth
                />
                <TextField

                  multiline
                  margin="dense"
                  id="texto_envio"
                  name="texto_envio"
                  label="Añade el texto del AVISO"
                  type="text"
                  value={texto_envio}
                  onChange={this.onChange}
                  fullWidth
                />

                  <TextField

                  multiline
                  margin="dense"
                  id="texto_url"
                  name="texto_url"
                  label="(Opcional) Añade url"
                  type="text"
                  value={texto_url}
                  onChange={this.onChange}
                  fullWidth
                  />



              </DialogContent>
              <DialogActions>
                <Button onClick={this.cerrarAviso} color="primary"
                >
                  Cancelar
                                    </Button>
                <Button
                  disabled={this.state.texto_envio === "" || this.state.texto_titulo === ""}
                  onClick={this.enviarAviso} color="primary"
                >
                  Confirmar
                                    </Button>
              </DialogActions>
            </Dialog>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              open={this.state.abrir_zonas}
              onClose={this.cerrarGestionZonas}

            >
              <Paper style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap', margin: 20,          
                maxHeight: 500, overflow: 'auto', 
                paddingTop:10,
           
                listStyle: 'none',
            
              }}>
            
                {zonas.map((data) => {
                  return (
                    <li key={data.key}>
                      <Chip
                        //      icon={icon}
                        label={data.Nombre}
                        variant="outlined"
                        disabled={data.click}
                        clickable
                        color="primary"
                        style={{ margin: 6 }}
                        onClick={this.seleccionarZona(data)}

                      //           onDelete={data.label === 'React' ? undefined : this.handleDelete(data)}
                      //                              className={classes.chip}
                      />
                    </li>
                  );
                })}
        
                <MaterialTable
                  options={{
                    doubleHorizontalScroll:true,
                    exportButton: {
                      csv: true,
                      pdf: false
                    },
                    filtering: true,

                    grouping: true,
                    exportDelimiter: ";",
                    exportAllData: true,
                    maxBodyHeight: 600,
                    pageSize: 20,
                    pageSizeOptions: [10, 20, 30],
                    headerStyle: {
                      backgroundColor: '#00a049',
                      color: 'white'
                    },
                    paddingTop: 5,
                    paddingBottom: 5,
                    width   :'100%'

                  }}

                  icons={tableIcons}

                  title={<><h3 style={{ color: '#00a049' }}>
                    RESUMEN RESERVA ZONA, {this.state.nombreZona}</h3>
                      <Grid container spacing={1}>
                      <Grid item sm={10}>
                        <FormControl fullWidth margin='normal'>
                        <InputLabel shrink htmlFor="select-multiple-native">
                      Listado para el año
                        </InputLabel>
    
                            <Select
                              value={this.state.añoActual}
                              // disabled={bloquear}
                              onChange={e => {
                            
                                  this.setState({añoActual:e.target.value})
                                  const lista=this.state.listaZonasRecuperadas
                                  const nuevaLista = lista.filter(zon=>zon.Año===e.target.value)
    
                                  this.setState({listaZonasRecuperadasFiltrada: nuevaLista})
                              
                                //                                          newRowData.Seguro =melon[0].uid
                              
                              }}    >
                              { this.state.años &&  this.state.años.map((año) => (
                                <MenuItem value={año.Nombre} >
                                  {año.Nombre}
                                </MenuItem>
                              ))
    
    
                              }
                            </Select>
                        </FormControl>  
                      </Grid>
                    
                  </Grid></>}

                  columns={[
                    {
                      title: 'Estado', field: 'Estado', cellStyle: {
                        width: 10,
                        minWidth: 10
                      },
                      headerStyle: {
                        width: 10,
                        minWidth: 10
                      }
                    },

                    {
                      title: 'Fecha', field: 'Fecha', cellStyle: {
                        width: 10,
                        minWidth: 10
                      },
                      headerStyle: {
                        width: 10,
                        minWidth: 10
                      }
                    },
                    { title: 'Hora Inicio', field: 'Hora' },
                    { title: 'Hora Fin', field: 'HoraFin' },
                    { title: 'Usuario', field: 'Usuario' },
                    { title: 'Dirección', field: 'Direccion' },
                    { title: 'Portal', field: 'Portal' },
                    { title: 'Número', field: 'Nmero' },
                    { title: 'Piso', field: 'Piso' },
                    { title: 'Letra', field: 'Letra' },
                    
                    { title: 'DNI', field: 'DNI' },

                    { title: 'Personas incluidas', field: 'Personas' },

                  ]}


                  data={listaZonasRecuperadasFiltrada}

                />
      
              </Paper>
            </Modal>
       
       
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}

              open={this.state.abrir_vista_previa}
              onClose={this.cerrarVistaPrevia}

            >
              <Paper style={{
                minHeight: 90, maxHeight: 600, maxWidth: 950, overflow: 'auto',
                marginLeft: 20, marginRight: 20, marginTop: 20, backgroundColor: 'lightgreen'
              }}>
                {!this.state.cargando &&
                  <MaterialTable
                    icons={tableIcons}
                    options={{
               
                      doubleHorizontalScroll:true,
                      exportButton: {
                        csv: true,
                        pdf: false
                      },
                      selection: true,
                      pageSize: 10,
                      exportDelimiter: ";",
                      exportAllData: true,
                      maxBodyHeight: 600,
                      //         grouping: true,
                      pageSizeOptions: [5, 10, 20],
                      headerStyle: {
                        backgroundColor: '#00a049',
                        color: 'white'
                      },
                      paddingTop: 5,
                      paddingBottom: 5

                    }}
                    localization={{

                      toolbar: {
                        nRowsSelected: '{0} Registro(s) seleccionados para ser CARGADOS'
                      },
                    }}

                    actions={[
                      {
                        tooltip: 'Crear las comunidades seleccionadas',
                        icon: () => <AddBox />,
                        
                        onClick: (evt, data) =>
                          this.cargarComunidades(data)
                      }
                    ]}


                    //                 <h5  style={{ color: '#00a049' }}>{this.state.nombreyapellidos}</h5>

                    title={<h2 style={{ color: '#00a049' }}>VISTA PREVIA CARGAR COMUNIDADES </h2>}

                    columns={[
                      { title: 'Nombre', field: 'Nombre' },
                      { title: 'Cif', field: 'Cif' },
                      { title: 'Id', field: 'Id' },
                      { title: 'Codigo', field: 'Codigo_seguridad' },
                      { title: 'Categorias', field: 'Categorias' },
                      { title: 'Direcciónes', field: 'Direcciones' },
                      { title: 'Código Postal', field: 'Cp' },
                      { title: 'Población', field: 'Poblacion' },
                      { title: 'Seguro', field: 'Seguro' },
                      { title: 'Poliza', field: 'Poliza' },
                      { title: 'Iban', field: 'IBAN' },
                      { title: 'Número Vecinos por comunidad', field: 'Vecinos' },
                      { title: 'Observaciones', field: 'Observaciones' }
                    ]}


                    data={this.state.ficheroList}

                  />}


              </Paper>
            </Modal>
      
      
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}

              open={this.state.abrir_vista_previa_estruct}
              onClose={e=>this.setState({abrir_vista_previa_estruct:false})}
             
            >
         
               <Paper style={{
                minHeight: 90, maxHeight: 600, maxWidth: 950, overflow: 'auto',
                marginLeft: 20, marginRight: 20, marginTop: 20, backgroundColor: 'lightgreen'
              }}>
        
                  <MaterialTable
                    icons={tableIcons}
                    options={{
                   
                      doubleHorizontalScroll:true,
                      exportButton: {
                        csv: true,
                        pdf: false
                      },
                      selection: true,
                      pageSize: 10,
                      exportDelimiter: ";",
                      exportAllData: true,
                      maxBodyHeight: 600,
                      //         grouping: true,
                      pageSizeOptions: [5, 10, 20],
                      headerStyle: {
                        backgroundColor: '#00a049',
                        color: 'white'
                      },
                      paddingTop: 5,
                      paddingBottom: 5

                    }}
                    localization={{

                      toolbar: {
                        nRowsSelected: '{0} Registro(s) seleccionados para ser CARGADOS'
                      },
                    }}
                    onSelectionChange={(rows) => {
                      if(rows.length>0){
                        alert('Cuando ejecutes la carga de los registros seleccionados, tanto las direcciones actuales como la estructura actual serán actualizados por las includidas en la plantilla de carga')
                      }
                      this.setState({rows: rows})}
                    }

                    actions={[
                      {
                        tooltip: 'Crear direcciones seleccionadas',
                        icon: () => <AddBox />,
                        
                        onClick: (evt, data) =>{
                          data.map(row=>{

                            row.tableData.checked=false
                       

                          } )
                        
                          this.setState({rows:data})
                          this.cargarEstructura(data)
                      }}
                    ]}


                    //                 <h5  style={{ color: '#00a049' }}>{this.state.nombreyapellidos}</h5>

                    title={<h2 style={{ color: '#00a049' }}>VISTA PREVIA CARGAR ESTRUCTURA</h2>}

                    columns={[
                      { title: 'Dirección', field: 'direccion' },
                      { title: 'Número', field: 'numero' },
                      { title: 'Portal', field: 'portal' },
                      { title: 'Piso', field: 'piso' },
                      { title: 'Letra', field: 'letra' },
                      { title: 'Porcentaje', field: 'porcentaje' },
                      { title: 'Email', field: 'email' },
                      { title: 'Dni', field: 'dni' },
                      { title: 'Cod_cliente', field: 'cod_cliente' }
                    ]}


                    data={this.state.ficheroList}

                  />


              </Paper>
            </Modal>
         
      
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}

              open={this.state.resumen_carga}
              onClose={this.cerrarResumenCarga}

            >
              <Paper style={{ maxHeight: 700, overflow: 'auto', marginLeft: 20, marginRight: 20, marginTop: 20 }}>
                <List style={{ width: 700, height: 230, backgroundColor: "primary", overflow: 'auto', }} dense component="div" role="list">
                  {this.state.resumen_lista.map((value) =>
                    <ListItem>
                      <ListItemText primary={`${value.Error}`} />

                    </ListItem>
                  )}
                </List>
              </Paper>


            </Modal>           
            <Modal
                           aria-labelledby="transition-modal-title"
                           aria-describedby="transition-modal-description"
                           style={{display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center'}}
                            open={this.state.avisos_enviados}
                            onClose={e=> this.setState({avisos_enviados:false,resultado_aviso:[],
                              usuariosTotal:[]})}                                        
                                                    >
                   <Paper style={{maxHeight: 500, overflow: 'auto', alignContent:'center',  alignItems:'center',
                      marginLeft:20, marginRight:20,marginTop:20}}>   
                       <h2  style={{   marginLeft:20,color: '#00a049' }}>RESULTADOS AVISOS ENVIADOS </h2>
                       <h3  style={{  marginLeft:20, color: '#00a049' }}>Total USUARIOS que se ha enviado el AVISO: {this.state.usuariosTotal.length}</h3>                              
                       <h3  style={{  marginLeft:20, color: '#00a049' }}>Total dispositivos enviado AVISO: {this.state.resultado_aviso.length}</h3>                              
                  
                     
                       <h3  style={{  marginLeft:20, color: '#00a049' }}>Total dispositivos Ok: {totalMensajesOK}</h3>                              
                       <h3  style={{  marginLeft:20, color: '#00a049' }}>Total dispositivos KO: {totalMensajesKO}</h3>                              
          
                       <MaterialTable
                                 options={{
                                  doubleHorizontalScroll:true,
                                  exportButton: {
                                    csv: true,
                                    pdf: false
                                  },
                                 filtering: true,
                                 selection: false,   
                                 pageSize: 10,  
                                 grouping: true,
                                 exportDelimiter: ";",
                                 exportAllData: true,
                                 pageSizeOptions:	[5, 10, 20],
                                 headerStyle: {
                                   backgroundColor: '#00a049',
                                   color: 'white'},
                                   paddingTop:5,
                                   paddingBottom:5
                                         
                               }}
                               
                               localization={{ 
                               
                                toolbar: {
                                  nRowsSelected: 'TOTAL DISPOSITIVOS que se ha enviado el aviso: {0}'
                              },
                              }}
 
                               icons={tableIcons}
                        
                               title={'TABLA DETALLE RESULTADOS ENVÍO AVISO'}
 
                               columns={[
                                {title: 'Nombre', field: 'Nombre'},
                                {title: 'Apellido', field: 'Apellido'},
                                {title: 'DNI', field: 'DNI'},
                                {title: 'Administrador', field: 'Administrador'},
                                
                                {title: 'Comunidad', field: 'Comunidad'},
                                {title: 'Error de Envío', field: 'error'},
                                {title: 'Código Error', field: 'codigo'},
                                {title: 'Mensaje Error', field: 'mensaje'},
                            
                                {title: 'Dispositivo', field: 'Dispositivos'},

                              ]}
                 
                              data={this.state.resultado_aviso}
 
                          />
                  </Paper>
              </Modal>

              <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}

              open={this.state.mostrarAyuda}
              onClose={e=>this.setState({mostrarAyuda:false})}


            >
            {this.state.mostrarAyuda&&<ModalTutoriales Pdfs= {this.state.VideoTuto}/> 
          }
           </Modal>
       
       
         {this.state.gestion_direcciones&&
           <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              open={this.state.gestion_direcciones}
              onClose={e => {
                this.setState({ gestion_direcciones:false})}}
            >
            
            {TablaDirecciones()}
      
            
            </Modal>}
       
       
       
       
       <Box mt={40}>{!this.state.open&& <Copyright />}  </Box>
        <Backdrop color='primary' open={this.state.open}>
                  <CircularProgress color="primary" />
        </Backdrop>

        </ThemeProvider>
      </Grid>
    );
  }
}

function pasarDirecciones(lista_final, direcciones1){

  const Coeficientes={}
  var Direcciones=[]
  direcciones1&&direcciones1.map((direcc)=>{
    
    const lista= lista_final.filter((item)=>item.direccion===direcc)
    
    if(lista.length===0){
      //No exite en la tabla de estructura por lo que solo se usa como una dirección

    }else{
      //Miramos el número
      var Numero=[]
    
      lista.map((nuevo)=>{
        const index=Numero.findIndex(it=>it.Nombre===nuevo.numero)
        if(index>=0){

          Numero[index].Portal=Numero[index].Portal.concat({portal:nuevo.portal,piso:nuevo.piso,letra:nuevo.letra})
      //   Numero[index]={'Nombre':nuevo.numero,'Portal':num.Portal }
        }else{
          if(nuevo.portal){
          var Portal=[{portal:nuevo.portal,piso:nuevo.piso,letra:nuevo.letra}]  
          Numero= Numero.concat({'Nombre':nuevo.numero,'Portal':Portal })
          }else{Numero= Numero.concat({'Nombre':nuevo.numero })}
        }
      })

      Numero.map((nuevo)=>{
        var Portal=[]  
        const vPortal= nuevo.Portal
        if(nuevo.Portal){
        vPortal&&vPortal.map(otro=>{
          const index=Portal.findIndex(it=>it.Nombre===otro.portal)
          if(index>=0){
  
            Portal[index].Piso=Portal[index].Piso.concat({piso:otro.piso,letra:otro.letra})
        //   Numero[index]={'Nombre':nuevo.numero,'Portal':num.Portal }
          }else{
            if(otro.piso){

            var Piso=[{piso:otro.piso,letra:otro.letra}]
            
            Portal= Portal.concat({'Nombre':otro.portal,'Piso':Piso })
            }else{
              Portal= Portal.concat({'Nombre':otro.portal })
            }
          }
  

        })

        //Sacamos los pisos
        Portal.map(otro2=>{
          var Piso=[]  
          const vPiso= otro2.Piso
          if(vPiso){
          vPiso&&vPiso.map(otro=>{
            const index=Piso.findIndex(it=>it.Nombre===otro.piso)
            if(index>=0){
              Piso[index].Letra=Piso[index].Letra.concat(otro.letra)
          //   Numero[index]={'Nombre':nuevo.numero,'Portal':num.Portal }
            }else{
              if(otro.letra){
                const Letra=[otro.letra]
                
                Piso= Piso.concat({'Nombre':otro.piso,'Letra':Letra })
              }else{
            
                Piso= Piso.concat({'Nombre':otro.piso })
              }
            }
  

          })
          if(Piso.length>0){ otro2.Piso=Piso}
          }

         })
       
          nuevo.Portal=Portal
        }
      })
    
    
      Direcciones=Direcciones.concat({"Nombre":direcc,'Numero':Numero } )
    }
    
  })
  return Direcciones;
  
}
function pasarCoeficientes(lista_final){

  const Coeficientes={}
  lista_final&&lista_final.map((item)=>{
     const direccion= (item.direccion ? item.direccion:'')
     const numero= (item.numero ? item.numero:'')
     const portal= (item.portal ? item.portal:'')
     const piso= (item.piso ? item.piso:'')
     const letra= (item.letra ? item.letra:'')
     Coeficientes[direccion+numero+portal+piso+letra]=(item.porcentaje ? Number(item.porcentaje):0)
     
  })
  return Coeficientes;
  
}

function pasarAñadido(lista_final){

  const Añadido_direcciones={}
  lista_final&&lista_final.map((item)=>{
     const direccion= (item.direccion ? item.direccion:'')
     const numero= (item.numero ? item.numero:'')
     const portal= (item.portal ? item.portal:'')
     const piso= (item.piso ? item.piso:'')
     const letra= (item.letra ? item.letra:'')
     Añadido_direcciones[direccion+numero+portal+piso+letra]={
      email:item.email?item.email:'',
      dni:item.dni?item.dni:'',
      cod_cliente:item.cod_cliente?item.cod_cliente:'',
      direccion:direccion,
      numero:numero,
      portal:portal,
      piso:piso,
      letra:letra
     }
    })
  return Añadido_direcciones;
  
}
function actualizar(cambios,lista_final){
const lista_nueva=[...lista_final]
cambios.map(cambio=>{
  const newData=cambio.newData
  const index =Number(cambio.oldData.tableData.id)
  lista_nueva[index]=newData

})
alert('La tabla ha sido actualizada correctamente, para guardar los cambios debes "CERRAR" esta ventana  y  escoger "GUARDAR" la transacción, gracias.')
 

return lista_nueva
}                       
function validarCambio(cambios, lista_final){

var correcto=true
cambios.map(cambio=>{
    var newData=cambio.newData
    const tableDataid=cambio.oldData

    if(newData.portal===''){newData.portal=undefined}
    if(newData.piso===''){newData.piso=undefined}
    if(newData.letra===''){newData.letra=undefined}
    //if(newData.direccion===''&&newData.numero===''&&newData.portal===''&&newData.piso===''&&newData.letra===''){
        //Hay que borrar la línea
    //    correcto=false
    //  return}

    if(newData.direccion===''||!newData.direccion){
      correcto=false
      if(tableDataid){
        const linea =Number(tableDataid.tableData.id)+1
        correcto='La dirección no puede ser vacía, línea de la tabla: ' + linea
        //alert('La dirección no puede ser vacía, línea de la tabla: ' + linea)
      }else{
        correcto='La dirección no puede ser vacía'
       // alert('La dirección no puede ser vacía')
      }
      return}


    if(newData.numero===''||!newData.numero){
      //El numero es vacio  no puede ser
    //  correcto=false
      if(tableDataid){
        const linea =Number(tableDataid.tableData.id)+1
        correcto='El número no puede ser vacío, línea de la tabla: ' + linea
       // alert('El número no puede ser vacío, línea de la tabla: ' + linea)
      }else{
        correcto='El número no puede ser vacío'
//        alert('El número no puede ser vacío')
      }
      return}
      if((newData.portal===''||!newData.portal)&&(newData.piso&&newData.piso!==''||newData.letra&&newData.letra!=='')){
        //El portal y piso o letra tiene algun valor
     //   correcto=false
        if(tableDataid){
          const linea =Number(tableDataid.tableData.id)+1
          correcto='El portal no puede ser vacío si en la misma línea piso o letra tiene algún valor, línea de la tabla: ' + linea
      ///    alert('El portal no puede ser vacío si en la misma línea piso o letra tiene algún valor, línea de la tabla: ' + linea)
        }else{
          correcto='El portal no puede ser vacío si en la misma línea piso o letra tiene algún valor'
       //   alert('El portal no puede ser vacío si en la misma línea piso o letra tiene algún valor')
        }
        return}

        if((newData.piso===''||!newData.piso)&&newData.letra&&newData.letra!==''){
          //El  piso es vacío y letra tiene algun valor
      //    correcto=false
          if(tableDataid){
            const linea =Number(tableDataid.tableData.id)+1
            correcto='El piso no puede ser vacío si en la misma línea letra tiene algún valor, línea de la tabla: ' + linea
      
          //  alert('El piso no puede ser vacío si en la misma línea letra tiene algún valor, línea de la tabla: ' + linea)
          }else{
            correcto='El piso no puede ser vacío si en la misma línea letra tiene algún valor'
      
      //      alert('El piso no puede ser vacío si en la misma línea letra tiene algún valor') 
          }
          return}
      
          //Comprobamos que el campo de porcentaje sea número

          if(isNaN(newData.porcentaje)){
            correcto='El campo porcentaje debe ser un valor númerico'
            return

          }

  
      
        const filtroRepe = lista_final.filter((dire, index)=>{
          
          return(
            dire.direccion===newData.direccion&&
            dire.numero===newData.numero&&
            dire.portal===newData.portal&&
            dire.piso===newData.piso&&
            dire.letra===newData.letra&& index!==(tableDataid ? tableDataid.tableData.id:-199))
        })
        if(filtroRepe.length>0){
        //El cambio o el nuevo registro está repetido y no se puede grabar
      //  correcto=false
          if(tableDataid){
            const linea =Number(tableDataid.tableData.id)+1
            correcto='Ya existe una combinación con los mismos datos de dirección, número, portal, piso y letra. Por favor cambia alguno de los valores para que el registro sea único,, línea en la tabla: ' + linea
//            alert('Ya existe una combinación con los mismos datos de dirección, número, portal, piso y letra. Por favor cambia alguno de los valores para que el registro sea único,, línea en la tabla: ' + linea)
          }else{
            correcto='Ya existe una combinación con los mismos datos de dirección, número, portal, piso y letra. Por favor, cambia alguno de los valores para que el registro sea único'
//            alert('Ya existe una combinación con los mismos datos de dirección, número, portal, piso y letra. Por favor, cambia alguno de los valores para que el registro sea único')
          }
          return
        }
        //Miramos si el nuevo registro o el nuevo cambio existen pero con mas datos
        const filtro = lista_final.filter((dire, index)=>{
          //Primer paso es que si el rgustro nuevo tiene menos datos que el registro a comparar
          const primerPaso= dire.direccion===newData.direccion&&
          dire.numero===newData.numero&&(newData.portal ? (dire.portal===newData.portal&&(newData.piso ? dire.piso===newData.piso
            &&(dire.letra ? dire.letra===newData.letra:true):true )):true)&& index!==(tableDataid ? tableDataid.tableData.id:-199)
         // dire.piso===(newData.piso ? newData.piso:''||undefined)&&
          if(primerPaso){return(true)}
          else{
            //Segunfdo paso es que el regstro nuevo tiene mas datos que el registro que ya existe.
            return(
              dire.direccion===newData.direccion&&
              dire.numero===newData.numero&&(dire.portal ? (newData.portal===dire.portal&&(dire.piso ? newData.piso===dire.piso
                &&(newData.letra ? newData.letra===dire.letra:true):true )):true)&& index!==(tableDataid ? tableDataid.tableData.id:-199)
             // dire.piso===(newData.piso ? newData.piso:''||undefined)&&
              //dire.letra===(newData.letra ? newData.letra:''||undefined)&& index!==(tableDataid ? tableDataid.tableData.id:-199)
              )
  
          }
        })
        if(filtro.length>0){
        //  correcto=false
          if(tableDataid){
            const linea =Number(tableDataid.tableData.id)+1
            correcto='Ya existe una combinación que está usando portal, piso y/o letra, por lo que no puedes usarla. Por favor cambia alguno de los valores para que el registro sea único, línea en la tabla: ' + linea
//            alert('Ya existe una combinación que está usando portal, piso y/o letra, por lo que no puedes usarla. Por favor cambia alguno de los valores para que el registro sea único, línea en la tabla: ' + linea)
          }else{
            correcto='Ya existe una combinación que está usando portal, piso y/o letra, por lo que no puedes usarla. Por favor, cambia alguno de los valores para que el registro sea único'
//            alert('Ya existe una combinación que está usando portal, piso y/o letra, por lo que no puedes usarla. Por favor, cambia alguno de los valores para que el registro sea único')
          }
       
          return
        }


      
    })
return correcto
}
const condition = authUser => !!authUser;
export default withFirebase(withAuthorization(condition)(Comunidades));