import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import {VerifiedUser,LockOpen} from '@material-ui/icons';
import  {enviaAviso,cargarResultado,guardarNotificaciones,
  buscarUsuariosAdmin,buscarDispositivosUsuario} from './enviarAvisos'
import {Folder} from '@material-ui/icons/';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NoteAddIcon from '@material-ui/icons/NoteAdd';  
import {validar_dni}  from '../Lozalizacion/identificador'
import MaterialTable  , {MTableBody}from 'material-table'
import './styles.css'
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

import {handleClickBorrar, handleClickBorrarFichero,buscarPadre,modificarNombrefun,crearEstructurDirectorios2,
  directorioYfich}  from '../DatosMaestros/documentos'
import BotonesTriger from '../Botones/botonTrige'
import Cabecera from '../Cabecera/index_365'
import ApartmentIcon from '@material-ui/icons/Apartment';
import {Grid,Fab,Divider,DialogActions,DialogContent,DialogContentText,DialogTitle,InputLabel,Dialog,
  CircularProgress,ButtonGroup,Backdrop,Box,IconButton,Snackbar,Paper,Tooltip,
  ListItemAvatar,ListItem,List,ListItemIcon,CardHeader,Card,Avatar,ListItemSecondaryAction,TextField,
  ListItemText,Select,Checkbox,Chip,Button,FormHelperText,MenuItem,Modal,FormControl} from '@material-ui/core';
import { DropzoneArea } from "material-ui-dropzone";
import BorderColor from '@material-ui/icons/BorderColor';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DeleteIcon from '@material-ui/icons/Delete';

import moment from 'moment';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
//import  green from '@material-ui/core/colors/green';

import  firebase2  from 'firebase'
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Copyright from '../Privacidad/copyright';

import  { Breakpoint} from 'react-socks';
import { CSVReader } from 'react-papaparse'

import {Textofield, selecto,selec_lectura, selecSimple,checkBoxLectura,checkBoxModificar} from '../Fomulario/formulario'
import ModalTutoriales,{traerTutoriales} from  '../Tutoriales/tutoriales'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main:"#00a049",
      claro:"#00a049"
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#e81446',
    },
  },
});
async function  getUser(uid,usuario,functions){
 
  return new Promise ((resolve,reject)  => {
    const callable2 = functions.httpsCallable('getUser')
    callable2({uid:uid})        
    .then((result) => {  

       
        let usu=result.data
        const melon=usu.metadata.lastSignInTime
        if(melon){
          let fecha= new Date(melon)    

              usu.lastSignInTime=fecha.toLocaleString("es-ES")
        }  
        const melon2= usu.metadata.creationTime
        if(melon2){
          let fecha= new Date(melon2)    

          usu.creationTime=fecha.toLocaleString("es-ES")
        }  

          var Obj = { ...usu,...usuario}
        
          resolve(Obj)   
    }).catch((e)=>{resolve(false)})
  
 })
}

export function  traeUsuariosDespacho(firebase) {
const  listAllUsers2 = firebase.functions.httpsCallable('listAllUsers');
    
 return new Promise(resolve => {  
        listAllUsers2({'despacho':firebase.despachoCode.Codigo}).
        then(
          function(result) {resolve(result.data)
        }).catch( 
          function(error) {
              // Getting the Error details.
              // ...
      });
  })
}



class Usuarios extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ascendente:false,
      usuariosDespacho:[],
      lista_convivientes:[],
      mostrarConvivientes:false,
      tipoRegistro:'',
      mNombre:'', mApellido:'', mComunidad:'',mTelefono:'',mCategorias:'',
      mRoles:[],mRol:'',memail:'', mDireccion:'',mDNI:'',
      mNumero:'',mPortal:'',mEscalera:'',mPiso:'',mLetra:'',
      memailVerified:false,mFichar:false, mPorcentaje:'',
      mlastSignInTime:'', mcreationTime:'',
      abrir_cambios:false,
      ErrorCaracter:false,
      usuariosClave:[],
      extension:'',
      Pdf:'',
      carpeta:'',
      modificarNombre:false,
      directorioPrincipal:'',
      directorio:'',
      directorio_modificar:'',
      Ref_padre: [],
      PathDirectorioActual:"",
      VideoTuto:[],
      carpetaActual:'',
      abrirDialog:false,
      pdfEnviar:'',
      carpetas:[],
      Pdfs:[],
      mostrarAyuda:false,
      abrir_horas:false,
      usuariosAdmin:[],
      nombreyapellidos:'',
      comusMulti:[],
      usuarios_enviar:[],
      usuarios:[],
      uid:"",
      direccion:"",
      añadir: true,
      incis: [],
      comus: [],
      open: false,
      comunidad:'', 
      tipo:'guardar',
      Id:'',
      mostrarTabla: true,
      selectedIndex:'',
      email: '',
      gremioBorrar:"",
      password: '',
      error: null,
      usuarioLogueado:'',
      error2: null,
      modificar: true,
      tenemosLista:false,
      snack:false, snack2:false, vertical: 'top',
      horizontal: 'center', 
      seguro : false,  
      deshabilitar:false,
      actividades:[],    
      lista_comunidades:{},
      roles:[],
      contraseña:'',
      abrirContraseña:false,
      clave: '',
      abrirAviso:false,
      texto_envio:'',
      texto_titulo:'',
      texto_url:'',
      usuario:[],
      usuariosTotal:[],
      categorias:[],
      listaHorasTrabajadas:[],
      resultado_aviso:[],
      avisos_enviados:false,
      leftChecked : [],
      rightChecked:[],
      left:[1,2,3,4],
      right:[5,6,711], 
      checked:[],
      abrir_multicomunidad:false,    
      rowData:[],
      usuario_multi:[],
      abrir_documentos:false,
      usuario_noMulti:'',
      despacho:
      {
        "Nombre": "",
        "CIFNIF": "",
        "Codigo": "",
        "Fecha_registro": "",
        "Telefono1":"",
        "Telefono2":"",
        "Correo":"",
        "Direccion":"",
        "Cp":"",
        "Poblacion":"",
        "Persona":"",
        "Com_Interna":"",
        "Logo":"",
        "Iban":"",
        "uid":""
      }
  
  };
  
    this.borrarFiltros = this.borrarFiltros.bind(this);

    this.buscarUsuarios = this.buscarUsuarios.bind(this);
    this.comprobarExisteUsuario = this.comprobarExisteUsuario.bind(this)
    this.handleClose =     this.handleClose.bind(this)
    this.anularGremio = this.anularGremio.bind(this)
    this.buscarComunidades=this.buscarComunidades.bind(this)
    this.comprobarDni = this.comprobarDni.bind(this)
    this.envioEmailVerificacion=this.envioEmailVerificacion.bind(this)

    this.handleClickClose = this.handleClickClose.bind(this)
    this.handleChange = this.handleChange.bind(this)

  
    this.cerrarGestionHoras = this.cerrarGestionHoras.bind(this)
    this.buscarDespacho = this.buscarDespacho.bind(this)
    this.traer_horas_trabajadas = this.traer_horas_trabajadas.bind(this)
  }

  filterRef () {return ( React.createRef(""))}
  cerrarGestionCambios (){
    this.setState({ abrir_cambios: false, rowData: [], editar:false })

  }
  traerClave(oldData){

    return new Promise (resolve=>{

      const usuario_multi = this.state.usuariosClave.filter(usu=>usu.DNI===oldData.DNI)
      if(usuario_multi&&usuario_multi.length>0){
      var clave=''
      usuario_multi.forEach(usu=>{
        if(usu.Clave&&usu.Clave!==''){
            clave=usu.Clave

        }
      })
      resolve(clave)
     }else{resolve(false)}
  })
  }
  borrarMulticomu(oldData){

    return new Promise (resolve=>{

      const usuario_multi = this.state.usuariosClave.filter(usu=>usu.DNI===oldData.DNI)
      if(usuario_multi&&usuario_multi.length>0){
      var clave=''
      usuario_multi.forEach(usu=>{
        this.props.firebase.user(usu.uid+'/').update({'Borrado':true})   
        if(usu.Clave&&usu.Clave!==''){
            clave=usu.Clave

        }
      })
      resolve(clave)
     }else{resolve(false)}
  })
  }
  actualizadMulti(newData, usuarios,oldData){

    
    const usuario_multi = usuarios.filter(usu=>usu.DNI===oldData.DNI&&usu.MultiCom===true)
    if(usuario_multi&&usuario_multi.length>0){

      usuario_multi.forEach(usu=>{

        this.props.firebase.user(usu.uid+'/').update({
          'Nombre':newData.displayName,
          'Apellido': newData.Apellido,
          'DNI':newData.DNI,
          'Telefono':newData.Telefono,
          'Email':newData.email,
        })
        var usuarios = this.state.usuarios
        const index = usuarios.findIndex(usur => usur.uid ===usu.uid );
       
        var usurr =usuarios[index]
        usurr.displayName=newData.displayName
        usurr.Apellido=newData.Apellido
        usurr.DNI=newData.DNI
        usurr.Telefono=newData.Telefono
        usurr.email=newData.email
        usurr.Email=newData.Email
        usurr.emailVerified=(newData.emailVerified ? newData.emailVerified:false)
        usuarios[index] = usurr
        this.setState({usuarios:usuarios})
      })


    }


  }

  traerUsuarioLogueado() {
    var user = this.props.firebase.auth.currentUser;
    if (user) {
        this.props.firebase.users().orderByChild('/Clave').equalTo(user.uid)
      .once('value', snapshot => {
        const usersObject = snapshot.val();
        if (usersObject) {
          const usuario = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));

          this.setState({ usuarioLogueado: usuario[0] })  
        }
      })
    }
  }
  buscarDespacho(){  

   this.props.firebase.despacho().once('value', snapshot => {
     const usersObject = snapshot.val();
     this.setState({despacho:usersObject})
    
   })
  }
  traer_horas_trabajadas (uid){

  return  this.props.firebase.fichar(uid).once('value', snapshot => {
      const usersObject = snapshot.val();

      if (usersObject){
   
      var  listaFicha = Object.entries(usersObject ).map(([key, val]) => ({
          "fecha": key,
          "valores":  Object.entries(val ).map(([key1, val2]) => ({
            "Entrada" : key1,"Salida" : val2,
          }))
        }));
      }
      var lista= []
      listaFicha.forEach(function(registro){
   
        registro.valores.forEach(function(dato){
            var breakfast = moment(dato.Entrada,'HH:mm');
            var lunch = moment(dato.Salida,'HH:mm');
            var  duracion = lunch.diff(breakfast, 'hours', true)
            const fecha = registro.fecha.slice(6,8) +"/"+registro.fecha.slice(4,6)+"/"+registro.fecha.slice(0,4);
            lista = lista.concat( { "Fecha":fecha, "Entrada":  dato.Entrada, "Salida": dato.Salida, "Horas": duracion.toString().slice(0,4)}) })
      })
     
      this.setState({listaHorasTrabajadas:lista})
      
    })

  }

  cerrarGestionHoras = ()=> {
    this.setState({abrir_horas:false})
    this.setState({nombreyapellidos: ''})                                   
  }
  dialogoEnvio(usuario){this.setState({abrirAviso:true, usuario:usuario, mostrarTabla:false})       };
 
  enviaAvisoUsuario () {
    this.setState({abrirAviso:false,open:true,mostrarTabla:false})
    var usuariosList =[]
    if(this.state.usuario !=='masivo'){
    usuariosList = [this.state.usuario]      
    } else {
      usuariosList = this.state.usuarios_enviar
    }
    var comunidades2 =[]
    usuariosList.forEach(usu =>{
      if (!comunidades2.includes(usu.Comunidad)){comunidades2=comunidades2.concat(usu.Comunidad)}

    })

    buscarUsuariosAdmin(this.props.firebase,this.state.despacho).then((listaUsuarios)=>{

      usuariosList= usuariosList.concat(listaUsuarios)
      if(window.confirm("¿Deseas envíar el aviso también a todos los implicados por email?" )){
        let Pdfss=this.state.Pdfs
        if(Pdfss&&Pdfss.length>0){
         //Tenemos que incluir en el path la lista de enlaces
         if(this.state.pdfEnviar!==''){
           Pdfss=Pdfss.filter(pdff=>pdff.Nombre===this.state.pdfEnviar)
           //el mensaje va asociado sobre solo 1 documento

         }
        }
         const despachoCode =this.props.firebase.despachoCode
         const envioEmailAviso = this.props.firebase.functions.httpsCallable('envioEmailAviso');
        
       
         envioEmailAviso({'BaseDatos':despachoCode.BaseDatos,'titulo':this.state.texto_titulo,'texto':this.state.texto_envio,
         'url':this.state.texto_url,'path':'','usuarios':usuariosList, 'Code':despachoCode.Code,'Pdfs':Pdfss}).then(()=>{

            alert('Se ha realizado en el envío de email correctamente')
            }).catch((e)=>{
              alert(e)
            })
            
         
    
       }  



      guardarNotificaciones(this.props.firebase,usuariosList,this.state.texto_envio,this.state.texto_titulo,
        this.state.texto_url, this.state.PathDirectorioActual,this.state.directorio_modificar,this.state.usuarioLogueado,comunidades2)

     .then((key)=>{

                
            const getData = async () => {
          //   return Promise.all(usuariosList.map(usuario => this.buscarDispositivosUsuario(usuario.uid)))
              return Promise.all(usuariosList.map(usuario => buscarDispositivosUsuario(usuario.uid, this.props.firebase)))
            }

            getData().then(data => {
        return new Promise(resolve => {
          var dispositivos= []
            data.map(dispositivo => dispositivos= dispositivos.concat(dispositivo) )
          const id=(this.state.PathDirectorioActual===""? "otro": "1")
           resolve(enviaAviso(dispositivos,usuariosList, this.state.texto_envio,this.state.texto_titulo, 
          this.props.firebase, this.state.PathDirectorioActual,this.state.directorio_modificar,id,key))
           }).then((data2)=>{

            if (data2){
         //     enviarDispositivosAdmin(this.state.texto_envio,
         //       this.state.texto_titulo, this.props.firebase,
        //        "","", "otro",[data2]).then((data3)=>{  
                        const registro= cargarResultado(data2,[], this.props.firebase,this.state.usuarioLogueado)  
   
                        this.setState({resultado_aviso:registro.archivo_final,avisos_enviados:true,open: false,
                    mostrarTabla:true, abrirBackdrop:false, texto_envio: '', texto_titulo: '', texto_url:'',
                    usuariosTotal:registro.usuariosTotal})   
          
                  //})
            }else { alert("Ha habido un problema y no se ha podido enviar")}
    
              
                this.cerrarAviso()
           })  
      })
     })
    }) 
    
  };
 
  cerrarAviso = () => {this.setState({abrirAviso:false, mostrarTabla:true, texto_envio:'', texto_titulo:'',texto_url:'', usuario:''})       };   
 
  handleClose () {
  this.setState({snack:false, snack2:false})
  };


  abrirMulticomunidad2 = (rowData)  => () => {  
  
    const usuarios = this.state.usuariosClave
    
    const usuario_multi = usuarios.filter(usu=>usu.DNI===rowData.DNI&&usu.MultiCom)
 
    const usu = usuarios.filter(usu=>usu.DNI===rowData.DNI&&!usu.MultiCom)

    const comusMulti=this.state.comus.filter(com=>com.Id!==usu[0].Comunidad)
      
 
    this.setState({usuario_multi:usuario_multi, comusMulti:comusMulti, usuario_noMulti:usu[0]})
    this.setState({abrir_multicomunidad:true, rowData:rowData})


  }

  buscarComunidades(){
  this.props.firebase.comus().once('value', snapshot => {
    const usersObject = snapshot.val();
    if(usersObject){
    const comusList = Object.keys(usersObject).map(key => ({
      ...usersObject[key],
      uid: key,
    }));

    let lista_comunidades={}
    comusList.sort(function (A, B) {
      var a=Number(A.Id.trim())
      var b=Number(B.Id.trim())       
      if(!a){ a=-1}
      if(!b){b=-1}
      console.log(a,b)
      
      if (a>b) {
        return 1;
      }
      if (a < b) {
        return -1;
      }           
      return 0;
    })
   
    comusList&&comusList.forEach(comu =>{
      const id  = comu.Id;
      //quitamos las actividades con id -1 y la de seguros porque no aplica a las  activdades de Incidencias
      if (comu!== '')  {lista_comunidades[ id ] = id}
      
     })
  
    this.setState({ lista_comunidades:lista_comunidades, comus:  comusList});
  } else { this.setState({ lista_comunidades:[],comus:[]});}
  });
  }
  componentWillMount(){
    this.props.firebase.categorias().off()
    this.props.firebase.roles2().off()
    this.props.firebase.users().off()
   }
  componentDidMount() {
    traerTutoriales(this, 'Usuarios').then((data)=>{this.setState({VideoTuto:data})})
    traeUsuariosDespacho(this.props.firebase).then(usus=>{this.setState({usuariosDespacho:usus})})
    this.props.firebase.users().orderByChild('/Administrador').equalTo(true)
    .on('value', snapshot => {
      const usersObject = snapshot.val();
      if (usersObject) {
        var usuarios = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
        usuarios=usuarios.filter(usu=>usu.Borrado===false)
        this.setState({ usuariosAdmin: usuarios })  
      }
    })

    this.buscarComunidades(); 
    this.buscarDespacho()
    this.traerUsuarioLogueado()
    var rolesList=[]
    this.props.firebase.roles2().on('value', snapshot => {
      const usersObject = snapshot.val();
     
      if (usersObject){
  
        rolesList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
        rolesList.sort(function (a, b) {
          if (a.Nombre> b.Nombre) {
            return 1;
          }
          if (a.Nombre < b.Nombre) {
            return -1;
            
          }
        // a must be equal to b
          return 0;
        })
       
     
        this.setState({roles:rolesList})
      }
    })
    this.props.firebase.categorias().on('value', snapshot => {
      const usersObject = snapshot.val();
      if (usersObject){
      
          const categoriasList = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));
      
       
       this.setState({categorias:categoriasList})
        }
      })
}
  buscarUsuarios(){

    this.setState({open:true, mostrarTabla: false, añadir: true, tenemosLista:false,deshabilitar:false }) 

    ///////////////////////////// P1
    const P1 =new Promise(resolve => {  
       this.props.firebase.users().once('value', snapshot => {
      const usersObject = snapshot.val();
      if (usersObject){
          var usuariosList = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));
      
          usuariosList.forEach(function(usuario) {
            if(  usuario.Roles&&  usuario.Roles.length>0){
              usuario.Roles = usuario.Roles.filter(rol =>rol !== "")
            }
            usuario.lista_convivientes=( usuario.lista_convivientes ?  usuario.lista_convivientes:[])
     
           // usuario.tienefichero=(usuario.tienefichero ? usuario.tienefichero:false)
          
          } )
         usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false && usuario.Administrador === false )     
       
 /*      var contador=1
         usuariosList.map((usu)=>{

          if(usu.Comunidad==='75'){
            var zoqui = usu
            //zoqui.uid=''
          const zoqui=  {
              "Administrador" : usu.Administrador,
              "Apellido" : usu.Apellido,
              "Borrado" : usu.Borrado,
              "Categorias" : usu.Categorias,
              "Clave" :(usu.Clave ? usu.Clave:false),
              "Codigo9" :(usu.Codigo9 ? usu.Codigo:false),
              "Comunidad" : usu.Comunidad,
              "DNI" : usu.DNI,
              "Direccion" :usu.Direccion,
              "Email" :usu.Email,
              "Escalera" : usu.Escalera,
              "Fecha_Modificacion" :usu.Fecha_Modificacion,
              "Fichar" : (usu.Fichar ? usu.Fichar:false),
              "Foto" :(usu.Foto ? usu.Foto:false),
              "Letra" :(usu.Letra ? usu.Letra:false),
              "Lopd" : (usu.Lopd ? usu.Lopd:false),
              "Nombre" : usu.Nombre,
              "Numero" : usu.Numero,
              "Ocultar_Telefono" :(usu.Ocultar_Telefono ? usu.Ocultar_Telefono:false),
              "Piso" : usu.Piso,
              "Portal" : usu.Portal,
              "Publicidad" : (usu.Publicidad ? usu.Publicidad:false),
              "Rol" :(usu.Rol ? usu.Rol:false),
              "Roles" : (usu.Roles ? usu.Roles:false),
              "SuperUser" : (usu.SuperUser ? usu.SuperUser:false),
              "Telefono" : (usu.Telefono ? usu.Telefono:false)
            }
          

contador++
           
              }
          
        
          
        })
        const soq=contador
       */
         resolve(usuariosList)
      }else{resolve([])} 
      }).catch((e)=>{
        const error=e
      })
    })
    const despachoCode =this.props.firebase.despachoCode
        
    
    var usuariosMulti=[]
    var usuarioMultiotroDespacho = []
      ///////////////////////////// P2
    const P2=new Promise(resolve => {  

            if(this.state.usuariosDespacho.length>0){
              resolve(this.state.usuariosDespacho)
              traeUsuariosDespacho(this.props.firebase).then(users=>{this.setState({usuariosDespacho:users})})
            }else{
              resolve( traeUsuariosDespacho(this.props.firebase)) 
            }
           
      })
        
      const getData = async () => {return Promise.all([P1,P2])}
    
      getData().then(datos => {
        var nuevosUsuarios=[]
        const  usuariosList=datos[0]  
        const usuarios = datos[1]
        usuariosList.map((usuario)=>{
    
            var usu =usuarios.find(us =>us.uid===usuario.Clave)

            if(usu){
          
              const melon= usu.metadata.lastSignInTime
              if(melon){
                let fecha= new Date(melon)    

                usu.lastSignInTime=fecha.toLocaleString("es-ES")
              }  
              const melon2= usu.metadata.creationTime
              if(melon2){
                let fecha= new Date(melon2)    

                usu.creationTime=fecha.toLocaleString("es-ES")
              }  

                var Obj = { ...usu,...usuario}
                nuevosUsuarios= nuevosUsuarios.concat(Obj)   
               
            }else if(usuario.Clave){
              //EStamos con que l usuario tiene clave pero el fichero no lo ha traido porque en su campo despacho tiene otro despacho diferente.
              //Usuarios multi despacho.
              usuarioMultiotroDespacho=usuarioMultiotroDespacho.concat(usuario)
       
            }
            
            else{
              const usu2 = nuevosUsuarios.find(uss => uss.DNI===usuario.DNI)
              if(usu2){
                var obj =  { ...usu2,...usuario}
                if(!usuario.MultiCom){obj.MultiCom=null}
                nuevosUsuarios= nuevosUsuarios.concat(obj)
              
              }else{
                usuariosMulti=usuariosMulti.concat(usuario)
              }
            }
            //guardamos los que no tienen clave y son multicomunidad

        
        })
          //Uncluimos si hay los de multicomunidad
        var multi=[]
        if(usuariosMulti&&usuariosMulti.length>0){
              usuariosMulti.map(usur=>{
               const melon= nuevosUsuarios.find(dato =>dato.DNI===usur.DNI)
                  if(melon){      
                      var obj =  { ...melon,...usur}
                      if(!usur.MultiCom){obj.MultiCom=null}
                      multi= multi.concat(obj)
                  }else{
                  // Hay que hacer recovery, debemos actualizar laclave en el primer usuario
                    //primero buscamos la clave por su email
                    // Si existe es un usuario que es bueno pero ha perdido su clave.

                    const usu =usuarios.find(us =>us.email===usur.Email)
                    if(usu){

                      const recoveryuser={...usur, 'Clave':usu.uid}
                      let obj1 =  { ...usu,...recoveryuser}
                      if(!usur.MultiCom){obj1.MultiCom=null}
                      nuevosUsuarios=nuevosUsuarios.concat(obj1)                  
                      this.props.firebase.user(usur.uid).update(recoveryuser)
  
                    }
                    
                  }
                
              })
        }

        if(multi&&multi.length>0){nuevosUsuarios=nuevosUsuarios.concat(multi)}
        nuevosUsuarios.sort(function (a, b) {
            if (a.Nombre> b.Nombre) {
              return 1;
            }
            if (a.Nombre < b.Nombre) {
              return -1;
              
            }
          // a must be equal to b
            return 0;
        });

        //Filtramos los datos con el valor de la comunidad:
          this.setState({usuariosClave:nuevosUsuarios})
         

     
        const getData1 = async () => {
        //Usuarios multi comunidad pero que tiene su codgo despacho con el otro despacho que estamos actualmente. En la primera busqeuda no lo ha encontrado.
          return Promise.all(
            usuarioMultiotroDespacho.map( (usuario) => {
              return  new Promise ((resolve,reject)  => { 
                resolve(getUser(usuario.Clave,usuario,this.props.firebase.functions))})
            })
          )}
      
        getData1().then(data => {
          //Lanzamos esto para dar de alta alos usuarios en firebase
          if(data&&data.length>0){
            var usuarios= nuevosUsuarios
            data.map(us=>{
              if(us){usuarios=usuarios.concat(us)}
            })
            this.setState({usuarios:nuevosUsuarios,usuariosClave:nuevosUsuarios})
          }
        
        }) 
    //    var guardaDespacho = this.props.firebase.functions.httpsCallable('guardaDespacho');
     //     nuevosUsuarios=nuevosUsuarios.filter(us=>us.Borrado==false)
        const getData = async () => { return Promise.all(nuevosUsuarios.map((us)=>{
            
            return new Promise(resolve => { 
              const customClaims=us.customClaims
              if(customClaims&&customClaims.despacho === this.state.despacho.Codigo ){
                resolve(true)
              }
              else{
       //         if(us.Comunidad!=='64'){
         //   guardaDespacho({'uid':us.Clave,'despacho':despachoCode.Codigo,'BaseDatos':despachoCode.BaseDatos, 
           // 'Almacen':despachoCode.Almacen, 'Code':despachoCode.Code}).then(()=>{           
           // resolve(true)})
            //  }
             // else{
                resolve(true)
            //  }
            }

          })
          }))
        }
        getData().then(data => {
           //       const callable2 = this.props.firebase.functions.httpsCallable('trasladarUsuariosDespacho')
   //       callable2()        
   //       .then((result) => {  

     //       const mela = result
       //   })
          if(this.state.comunidad&&this.state.comunidad !==''){
            nuevosUsuarios = nuevosUsuarios.filter(usuario => usuario.Comunidad===this.state.comunidad)
          }   
         this.setState({usuarios:nuevosUsuarios})
         this.setState({ mostrarTabla: true, open:false });  
         this.borrarFiltros();
        })
      }) 
  }
  borrarFiltros(){  
    this.filterRef['displayName']=''
    this.filterRef['Apellido']=''
    this.filterRef['Comunidad']=''
    this.filterRef['Telefono']=''
    this.filterRef['Categorias']=''
    this.filterRef['Roles']=''
    this.filterRef['Rol']=''
    this.filterRef['email']=''
    
    this.filterRef['DNI']=''
    this.filterRef['Direccion']=''
    
    this.filterRef['Numero']=''
    this.filterRef['Portal']=''
    this.filterRef['Escalera']=''    
    this.filterRef['Piso']=''
    this.filterRef['Letra']=''
    
    this.filterRef['emailVerified']=''
    this.filterRef['Fichar']=''

    this.filterRef['Porcentaje']=''
    this.filterRef['lastSignInTime']=''
    this.filterRef['creationTime']=''
    this.filterRef['convivientes']=''

    this.setState({ direccion:'',tenemosLista:false, seguro:false });
  }

  envioEmailVerificacion  = (email) => { 

  var guardar =false // l ohacemos por si se hace desd la ventan del detalle del usuario o no, para volverla abrir
  if(this.state.abrir_cambios){guardar=true}
                     
    
  this.setState({open:true,abrir_cambios:false,mostrarTabla:false})
    var actionCodeSettings = {
      url: 'https://comunidad365.es/home',
      handleCodeInApp: true,
 //     dynamicLinkDomain: 'c365.page.link'
   //   https://c365.page.link/jTpt
    };
    var generateEmailVerificationLink= this.props.firebase.functions.httpsCallable('generateEmailVerificationLink');
                                    
    new Promise((resolve,reject) => {  
         
      generateEmailVerificationLink({useremail:email,actionCodeSettings:actionCodeSettings}).then(function(result){
          // Read result of the Cloud Function.
        
          resolve(result.data)           
                        

        }).catch(function(error) {
          // Getting the Error details.
        
          reject(false)
         
          // ...
        });
      }).then(()=>{
        
        this.setState({open:false,mostrarTabla:true})      
        if(guardar){  this.setState({abrir_cambios:true})   }
        alert('Se ha mandado el correo de verificación a '+ email)
      })
 
      
  

}
 
comprobarDni(dni) {
    const despacho=this.state.despacho
    return validar_dni(dni,despacho.DatosPais)

  
}

  comprobarExisteUsuario(dni) {
  var existe=[];
  if(this.state.usuariosClave){
    existe = this.state.usuariosClave.filter(usuario =>{
      
      const usu= usuario.DNI
      
      return(usu.toUpperCase()===dni.toUpperCase())
    })
  
    
  }
  const usuariosAdmin= this.state.usuariosAdmin
  if(usuariosAdmin&&usuariosAdmin.length>0){
  existe = existe.concat(usuariosAdmin.filter(usu=>{
    const usur= usu.DNI
      
    return(usur.toUpperCase()===dni.toUpperCase())
   }))
  
  }
    return existe;       
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  };
  anularGremio() {
  new Promise(resolve => {
      const uidGremio=this.state.uid
      resolve(this.state.comus.forEach((comunidad) => {
        this.props.firebase.comu(comunidad.uid+"/ActivGremios/").once('value', snapshot => {
          snapshot.forEach((registro) => {
              const melon = registro.val()
              if (melon===uidGremio) {
                this.props.firebase.comu(comunidad.uid+"/ActivGremios/").update({[registro.key] :"-1",  })
                this.props.firebase.comu(comunidad.uid+"/ActivGremioAuxiliar/").update({[registro.key] :"-1",  })
                
              }
          });
          this.props.firebase.gremio(uidGremio).update({ "Borrado":"true",  })
    
    
        })    
       })
    
      
    )
 }).then(() => {
    this.setState({snack2:false, snack:true, error2:"El gremio ha sido borrado" });  
    this.buscarUsuarios();

    })
    .catch(error => { 
      this.setState({snack2:false, snack:true, error2:"Ha  habido un problema y no se ha podido borrar" });  
      this.setState({ error });
    });

  }
  checkEnSeguro = (oldData) => () =>{
  var newData= oldData
  var data = this.state.gremios;
  
    if(!oldData.Seguro&&window.confirm('¿Quieres que el Gremio pase a ser del Tipo compañia de Seguros?')){
        newData.Seguro = true;       
        data[data.indexOf(oldData)] = newData;
        this.setState({gremios:data }); 
        new Promise(resolve => {
          setTimeout(() => {
              resolve();
                this.props.firebase.gremio(oldData.uid+'/').update({ 
               
                  Fecha_Modificacion:firebase2.database.ServerValue.TIMESTAMP,
                  Seguro: true      
              })
          }, 1);
          }).then(() => { this.componentDidMount()})

    }
    else if(oldData.Seguro&&window.confirm('¿Quieres que el Gremio deje de ser del Tipo compañia de Seguros?')){
    
      newData.Seguro = false;      
      data[data.indexOf(oldData)] = newData;
      this.setState({gremios:data }); 
      new Promise(resolve => {
        setTimeout(() => {
            resolve();
              this.props.firebase.gremio(oldData.uid+'/').update({ 
             
                Fecha_Modificacion:firebase2.database.ServerValue.TIMESTAMP,
                Seguro: false    
            })
        }, 1);
        }).then(() => { this.componentDidMount()})

      
  }

  
  }
  handleChangeAct = (oldData) => (event) => {
  var newData= oldData

  var data = this.state.roles;
  newData.Roles = event.target.value;
  newData.Roles = newData.Roles.filter(rol => rol!=='');
   
  data[data.indexOf(oldData)] = newData;
  this.setState({roles:data }); 

  }

  handleChangeCat = (oldData) => (event) => {
    var newData= oldData
  
    var data = this.state.categorias;
    newData.Categorias = event.target.value;
    newData.Categorias = newData.Categorias.filter(rol => rol!=='');
     
    data[data.indexOf(oldData)] = newData;
    this.setState({categorias:data }); 
  
    }
  handleClickOpen (rowData)
   {
     this.setState({abrirContraseña:true, contraseña:'',clave:rowData, open:true})       };
  handleClickCloseOk () {
   return   new Promise((resolve) => {  
  
    this.traerClave(this.state.clave).then((clave)=>{
      var updatePass= this.props.firebase.functions.httpsCallable('updatePass');
      updatePass({uid:clave, contraseña:this.state.contraseña}).then((result)=>{
          // Read result of the Cloud Function.
          resolve(true)         
          alert("La contraseña ha sido actualizada. Nueva contraseña: " + this.state.contraseña)        
          this.setState({abrirContraseña:false, contraseña:'', clave:''})   

        }).catch(function(error) {  
          alert("hA HABIO UN ERROR: " + error)        
          this.setState({abrirContraseña:false, contraseña:'', clave:''})   
                  
          resolve(error)
        });
      })  
    })
  }
  handleClickClose = () => {this.setState({abrirContraseña:false, contraseña:'', clave:''})       }; 
  handleChange(event) {
   this.setState({ [event.target.name]: event.target.value });

   if(event.target.name === "directorio" ){
        directorioYfich(event,this,this.state.carpetas) 
   }

   if( event.target.name === "directorio_modificar"){
    directorioYfich(event,this,this.state.Pdfs) 
}
  } 
  comprobarUsuarioOtrosDepachos(usuario,borra){
    //Comprobamos si el usuario cuando  se va a borrar o modficar pertenece a otro despacho
    //Si borra positivo ademas se comprobar si exite el usuario en otros despachos, borra de la tabla Multiusuarios
    //Si borra es negativo simplemente comprueba si el usuario es Multidespacho o no
      const usuario_multi = this.state.usuariosClave.filter(usu=>usu.DNI===usuario.DNI&&usu.Clave!=='')
      const despacho=this.state.despacho
      var usuariosMultidespacho= this.props.firebase.functions.httpsCallable('usuariosMultidespacho');
      var actualizaMultidespacho= this.props.firebase.functions.httpsCallable('actualizaMultidespacho');
      var actualizaUsers= this.props.firebase.functions.httpsCallable('actualizaUsers');

      return new Promise((resolve,reject) => {  
         usuariosMultidespacho({clave:usuario_multi[0].Clave}).then(function(result){
            // Read result of the Cloud Function.
            var multi = result.data;
            if(multi&&multi.length>0){
              if(borra){
                 var nuevo_multi= multi.filter(des=>des.Despacho!==despacho.Codigo)
                 actualizaUsers({clave:usuario_multi[0].Clave, codigo:nuevo_multi[0].Despacho}).then(function(result){
                  
                  
                  if(nuevo_multi.length===1){nuevo_multi=[]}
                   actualizaMultidespacho({clave:usuario_multi[0].Clave, multi:nuevo_multi}).then(function(result){
                   resolve(true)}).catch(function(error) {
                   reject(error)
                  // ...
                  })  
                  }).catch(function(error) {
            
                  reject(error)
                  // ...
                })  

               
              }else{resolve(multi)}
           }else{ resolve(false)}
          }).catch(function(error) {
            
            reject(error)
            // ...
          })  
      })
    }

onDrop(file) {


      this.setState({open:true, mostrarTabla: false,abrir_documentos:false})  
  
  
        const putData = async () => {
          return Promise.all(file.map(fichero =>  
            this.props.firebase.storage_propio_completo(this.state.PathDirectorioActual+"/"+fichero.name).put(fichero)
            ))
        }
  
        putData().then(data => {

          this.buscarficheros(this.state.PathDirectorioActual,this.state.rowData)
           this.props.firebase.user(this.state.rowData.uid+'/').update({'tienefichero':true})
    
        })
    
   // }
  }   
buscarficheros (Path, rowData) {   
  this.setState({Pdfs:[]})
  this.setState({open:true,abrir_documentos:false, mostrarTabla: false})
//      var carpetas=[];
   var pdfs=[];
     
  let listRef = this.props.firebase.storage_propio_completo().child(Path);

  let Ref_padre = listRef.parent;
  this.setState({Ref_padre:Ref_padre, PathDirectorioActual:Path})
  var carpetas=[]
  const p0 = new Promise ((resolve,reject)  => {
  var num=1
    listRef.listAll().then((res)=> {
     
      res.prefixes.forEach(folderRef => { 
          carpetas.push({
            id: num,
            Nombre: folderRef.name,
        //    SoloPresi:listPresi,
            path:folderRef.location.path,
            image: require('../../Imagenes/Files-icon.png'),  
          });
          num++;
      })
  
      if(carpetas.length>0){
    
        carpetas.sort(function (A, B) {
          var a =Number(A.Nombre.trim())
          var b =Number(B.Nombre.trim())
          if(!a){a=100000}
          if(!b&&b!==0){b=100000}
          if( a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
    
          }
          return 1
        })
       this.setState({carpetas:carpetas})
       res.items.forEach(function(itemRef) {
        pdfs.push({
            items:itemRef

          })          
          }) 
        if(pdfs&&pdfs.length>0){
            resolve(pdfs)
        }else{resolve(false)}
      
        
      }
      else{
        
        this.setState({carpetas:carpetas})
 
        res.items.forEach(function(itemRef) {
          pdfs.push({
              items:itemRef
  
          })          
        }) 
        if(pdfs&&pdfs.length>0){
          resolve(pdfs)
        }else{resolve(false)}
      }
   
      })
     
  
  })
  p0.then( (data) => { 
           //     this.setState({dataCategoria3:carpetas})
                if(data){
                  this.traerPDFS(pdfs)
                }else{
                  if(Path==='usuarios/'+rowData.uid+'/'&&carpetas.length===0){
                    //si estamos en el directorio raiz y no hay ficheros ni directorios lo ponemos a false
                    this.props.firebase.user(this.state.rowData.uid+'/').update({'tienefichero':false})
                  }
                  this.setState({open:false,abrir_documentos:true, mostrarTabla: true})
                }
              

  })

}
traerPDFS  (items){
 if(items.length>0){ 
  const listRef2 = this.props.firebase.storage_propio_completo()
  var pdfs=[];
  let promises = items.map((itemRef) => {
    return   listRef2.child(itemRef.items.location.path).getDownloadURL() 
  });
  Promise.all(promises).then((downloadURLs) => {
    var i=0;
    items.forEach((itemRef) => {
          pdfs.push({
          id: i,
          Nombre: itemRef.items.name,
          url: downloadURLs[i] ,
          path: itemRef.items.location.path,
      //  fullpath: itemRef.items.fullPath,
          image: require('../../Imagenes/Mimetypes-pdf-icon.png'),  
        })
        i++;
      })
        pdfs = pdfs.filter(file => file.Nombre !== "temporal.txt")
          this.setState({Pdfs:pdfs})
          this.setState({open:false,abrir_documentos:true, mostrarTabla: true })
    });

  }else (this.setState({open:false,abrir_documentos:true, mostrarTabla: true})
  )
    //    this.setState({PDFS:pdfs})
 
}

rowAñadir(newData){
  return new Promise((resolve, reject) => {
    var ejecuta= true
     
             if(this.comprobarExisteUsuario(newData.DNI).length> 0&&newData.DNI!=='11111111')
              {alert("El DNI ya existe, por favor utiliza otro"); ejecuta= !ejecuta}
             else  if(!newData.DNI || newData.DNI ===''){alert("El campo DNI no puede ser vacío"); ejecuta= !ejecuta}
             else if(!this.comprobarDni(newData.DNI)){alert("El DNI introducido no es válido"); ejecuta= !ejecuta}
             else if(!newData.displayName||newData.displayName ===''){alert("El campo Nombre no puede ser vacío"); ejecuta= !ejecuta}
             else if(!newData.Apellido||newData.Apellido ===''){alert("El campo Apellido no puede ser vacío"); ejecuta= !ejecuta}
             else if(!newData.Comunidad||newData.Comunidad===''){alert("El campo Comunidad no puede ser vacío"); ejecuta= !ejecuta}
             else if(!newData.Direccion||newData.Direccion ===''){alert("El campo Dirección no puede ser vacío"); ejecuta= !ejecuta}
//             else if(!newData.Portal||newData.Portal ===''){alert("El campo Portal no puede ser vacío"); ejecuta= !ejecuta}
       //      else if(!newData.||newData.Codigo9 ===''){alert("El campo Código Comunidad no puede ser vacío"); ejecuta= !ejecuta}
             else if(!newData.email||newData.email ===''){alert("El campo Correo no puede ser vacío"); ejecuta= !ejecuta}
             else if(!newData.Telefono||newData.Telefono ===''){alert("El campo Teléfono no puede ser vacío");ejecuta= !ejecuta }
   
     //        else if(!newData.Numero||newData.Numero ===''){alert("El campo Número no puede ser vacío");ejecuta= !ejecuta }
   
       //      else if(!newData.Piso||newData.Piso ===''){alert("El campo Piso no puede ser vacío");ejecuta= !ejecuta }
         
      //       else if(!newData.Letra||newData.Letra ===''){alert("El campo Letra/Puerta no puede ser vacío");ejecuta= !ejecuta }
         
        

             if(ejecuta){
              var createUser= this.props.firebase.functions.httpsCallable('createUser');
              var nuevoUid=''
              new Promise((resolve,reject) => {  
                var telefono1 =newData.Telefono
                if (newData.Telefono.slice(0,3) !== "+34"){telefono1="+34" + newData.Telefono}
                if(!newData.Porcentaje){newData.Porcentaje=0}

                createUser({email: newData.email, telefono:telefono1, emailVerified:(newData.emailVerified ? newData.emailVerified:false),
                   nombre:newData.displayName, admin:  this.state.despacho.Codigo}).then((result)=>{
                     // Read result of the Cloud Function.
                     nuevoUid= result.data;
                     newData.Clave=result.data
                     resolve(nuevoUid)                                 
         
                   }).catch(function(error) {
                  
                     reject(error)
                     // ...
                   });
                 }).then((data) => {             
                  const despachoCode =this.props.firebase.despachoCode
                  var guardaDespacho = this.props.firebase.functions.httpsCallable('guardaDespacho');
                  guardaDespacho({'uid':data,'despacho':despachoCode.Codigo,'BaseDatos':despachoCode.BaseDatos, 
                  'Almacen':despachoCode.Almacen, 'Code':despachoCode.Code}).then(()=>{
                    traeUsuariosDespacho(this.props.firebase).then(users=>{this.setState({usuariosDespacho:users})})
                  })
                  var resul={}
                      let resultado =[]
                      //prearamos el  vector de roles11
                      if(newData.Roles&&newData.Roles.length>0){ 
                        resultado= newData.Roles.map((value,key) =>
                        resul[key]=value );      
                      }                                          
                      if(!resultado || resultado.length===0){resul={0:""}}
                
                      if(!newData.Rol){newData.Rol=""}
                      if(!newData.Escalera){newData.Escalera=""}
                      if(!newData.Portal){newData.Portal=""}
                      //prearamos el  vector de categoriass
                       var resul1={}
                       let resultado1 =[]
                       if(newData.Categorias&&newData.Categorias.length>0){ 
                        resultado1= newData.Categorias.map((value,key) =>
                        resul1[key]=value );      
                      }                                          
                      if(!resultado1 || resultado1.length===0){resul1={0:""}}
                      this.props.firebase.users().push({
                        Nombre: newData.displayName,
                        Administrador : false,
                        Apellido: newData.Apellido,
                        DNI : newData.DNI,
                        Comunidad: newData.Comunidad,
                        Roles : resul,
                        Categorias : resul1,                                                      
                        Rol:newData.Rol,
                        Fichar:(newData.Fichar ? newData.Fichar:false),
                        Borrado:false,
                        Direccion: newData.Direccion,
                        Fecha_Modificacion:firebase2.database.ServerValue.TIMESTAMP,
                        Email: newData.email,
                        Clave: data,
                        Telefono: newData.Telefono,
                        Numero: (newData.Numero  ? newData.Numero:'' ),
                        Portal:  (newData.Portal  ? newData.Portal:'' ),
                        Escalera:  (newData.Escalera  ? newData.Escalera:'' ),
                        Piso: ( newData.Piso  ?  newData.Piso:'' ),
                        Letra: ( newData.Letra  ?  newData.Letra:'' ),
                        Porcentaje:newData.Porcentaje,
                        lista_convivientes:(newData.lista_convivientes ? newData.lista_convivientes:[])                          
                     })      
                   .then((key)=>{        
                    var data =[] 
                    newData['lastSignInTime']=''
                    newData['creationTime']=''
                    newData.uid= key.key
                    data=data.concat(newData)
                    data=data.concat(this.state.usuarios)
                    this.setState({ usuarios: data ,usuariosClave:data});
                       alert('Usuario ' +newData.displayName+' creado correctamente')


                     //Vamos a dar de alta al usuario cons su campos corectos en Firebase
                
                     

                       resolve(true)              

                  })  
                })
                   .catch(function(error) {
                    if(error&&error.message==="01"){ alert("El formato del correo no es correcto: "+ newData.email)}
                    else if(error&&error.message==="02"){ alert("El formato del Teléfono no es correcto: "+ newData.Telefono)}
                    else if(error&&error.message==="03"){ alert("El correo ya existe en el sistema, por favor usa otro: "+ newData.email)}
            
                    else {alert('Ha habido un error creando el usuario: '+ error.message)}  
              
                  

                    resolve(false)
       
                           
                  })
                           
             }else {resolve(false)}
  
        
    
})
}
rowActualiza(newData, oldData){

  return( new Promise((resolve, reject) => {
    var ejecuta= true   
    setTimeout(() => {
          
       
          if(this.comprobarExisteUsuario(newData.DNI).length> 0
          && newData.DNI !== oldData.DNI &&newData.DNI !=='11111111' ){alert("El DNI ya existe, por favor utiliza otro"); ejecuta= !ejecuta}
           else  if(!newData.DNI || newData.DNI ===''){alert("El campo DNI no puede ser vacío"); ejecuta= !ejecuta}
            else if(!this.comprobarDni(newData.DNI)){alert("El DNI introducido no es válido"); ejecuta= !ejecuta}
             if(!newData.displayName||newData.displayName ===''){alert("El campo Nombre no puede ser vacío"); ejecuta= !ejecuta}
             else if(!newData.Apellido||newData.Apellido ===''){alert("El campo Apellido no puede ser vacío"); ejecuta= !ejecuta}
             else if(!newData.Comunidad||newData.Comunidad===''){alert("El campo Comunidad no puede ser vacío"); ejecuta= !ejecuta}
             else if(!newData.Direccion||newData.Direccion ===''){alert("El campo Dirección no puede ser vacío"); ejecuta= !ejecuta}
       //      else if(!newData.Portal||newData.Portal ===''){alert("El campo Portal no puede ser vacío"); ejecuta= !ejecuta}
          //   else if(newData.Codigo9 ===''){alert("El campo Código Comunidad no puede ser vacío"); ejecuta= !ejecuta}
             else if(!newData.email||newData.email ===''){alert("El campo Correo no puede ser vacío"); ejecuta= !ejecuta}
             else if(!newData.Telefono||newData.Telefono ===''){alert("El campo Teléfono no puede ser vacío");ejecuta= !ejecuta }
         
  //           else if(!newData.Numero||newData.Numero ===''){alert("El campo Número no puede ser vacío");ejecuta= !ejecuta }
         
  //           else if(!newData.Piso||newData.Piso ===''){alert("El campo Piso no puede ser vacío");ejecuta= !ejecuta }
         
  //           else if(!newData.Letra||newData.Letra ===''){alert("El campo Letra/Puerta no puede ser vacío");ejecuta= !ejecuta }  
            if(ejecuta){
               this.traerClave(oldData).then((clave)=>{
                var updateUser= this.props.firebase.functions.httpsCallable('updateUser');
              
                new Promise((resolve,reject) => {  
               
             //     if (newData.Telefono.slice(0,3) !== "+34"){newData.Telefono="+34" + newData.Telefono}
                  if(!newData.Porcentaje){newData.Porcentaje=0}

                  updateUser({uid:clave, email: newData.email,// telefono:telefono1, 
                    nombre:newData.displayName, emailVerified:(newData.emailVerified ? newData.emailVerified:false),}).then((result)=>{
                      // Read result of the Cloud Function.
                      traeUsuariosDespacho(this.props.firebase).then(users=>{this.setState({usuariosDespacho:users})})
                      resolve(ejecuta)                              
                    }).catch(function(error) {
                      // Getting the Error details.
                       reject(error)
                      // ...
                    });
                  }).then((datos) => { 
                  var data = this.state.roles;
                     data[data.indexOf(oldData)] = newData;
                     
                     this.setState({roles:data }); 

                     if(!newData.Rol){newData.Rol=""}
                     if(!newData.Escalera){newData.Escalera=""}
                     if(!newData.Portal){newData.Portal=""}
                    
                       var data2 = this.state.categorias;
                      data2[data2.indexOf(oldData)] = newData;     
                     this.setState({categorias:data2 }); 

                     this.props.firebase.user(newData.uid+'/').update({
                     
                       Nombre: newData.displayName,
                       Apellido: newData.Apellido,
                       DNI : newData.DNI,
                       Comunidad: newData.Comunidad,
                       Roles :( newData.Roles ?  newData.Roles:[]),
                       Categorias:(newData.Categorias ? newData.Categorias:[]),
                       Fichar: (newData.Fichar ? newData.Fichar:false),
                       Rol:newData.Rol,
                       Direccion: newData.Direccion,
                       Fecha_Modificacion:firebase2.database.ServerValue.TIMESTAMP,
                       Email: newData.email,
                       Telefono: newData.Telefono,
                       Numero: (newData.Numero  ? newData.Numero:'' ),
                       Portal:  (newData.Portal  ? newData.Portal:'' ),
                       Escalera:  (newData.Escalera  ? newData.Escalera:'' ),
                       Piso: ( newData.Piso  ?  newData.Piso:'' ),
                       Letra: ( newData.Letra  ?  newData.Letra:'' ),
                       Porcentaje:newData.Porcentaje,
                       lista_convivientes:(newData.lista_convivientes ? newData.lista_convivientes:[])
                      }).then(() => { 
                        this.comprobarUsuarioOtrosDepachos(oldData,false).then((existe)=>{

                          if(existe&&existe.length>0){
                            var actualizaUsuDespachos= this.props.firebase.functions.httpsCallable('actualizaUsuDespachos');
                            actualizaUsuDespachos({clave:clave, registro: newData,despachos:existe, codigo: this.state.despacho.Codigo,
                              }).then(function(result){
                                // Read result of the Cloud Function.
                             
                                resolve(true)                                 
                    
                              }).catch(function(error) {
                                // Getting the Error details.
                                 reject(error)
                                // ...
                              });

                          }
                        })
                  

                        alert("El registro se ha actualizado correctamente")
                        //  this.buscarUsuarios()
                        var data = this.state.usuarios;
                   
                        data[data.indexOf(oldData)] = newData;
                        this.setState({ usuarios: data,usuariosClave:data });
                        this.actualizadMulti(newData, data,oldData)
                        resolve(true)
                        })
       
                    }).catch(function(error) {
        
                      if(error.message==="01"){ alert("El formato del correo no es correcto: "+ newData.email)}
                      else if(error.message==="02"){ alert("El formato del Teléfono no es correcto: "+ newData.phoneNumber)}
                      else {alert(error.message)}  
                      resolve(false)
  
                              });

                })   
            }else {resolve(false)}
            
      
      
      }, 1);
      }))
}


  render() {

    const botonConvivientes =(rowData)=>
    <Button
         //  disabled ={disable}
          variant="outlined" color="primary" disableElevation
         
         onClick={e => {    
         
           this.setState({abrir_multicomunidad:false,mostrarConvivientes:true,lista_convivientes:rowData.lista_convivientes}) 
          
          }}
       >
           Convivientes
 </Button>

   const { mostrarTabla, texto_envio, texto_titulo, listaHorasTrabajadas ,texto_url , despacho} = this.state;
   const vertical= this.state.vertical;
   const horizontal= this.state.horizontal;
   const contraseña = this.state.contraseña
   const comus = this.state.comus
   var totalMensajesOK= 0
   var totalMensajesKO= 0
   var direcciones = []
   const resultado_aviso= this.state.resultado_aviso
   if(resultado_aviso&&resultado_aviso.length>0){
      totalMensajesOK= resultado_aviso.filter((usuario)=>usuario.error==='NO').length
      totalMensajesKO= resultado_aviso.filter((usuario)=>usuario.error==='SI').length
   }


   return (     
      <Grid  >
          {!this.state.open&&<>
        <Cabecera/>
         <hr/>
      
              <Grid style={{ position: 'absolute', right: 20, top: 20 , maxWidth:"15%", maxHeight:"15%"}}>
          <Button variant="contained" color="primary" onClick={() => { this.setState({ mostrarAyuda: true }) }}>
            AYUDA (TUTORIALES)
          </Button>
          {this.state.mostrarTabla&&<Grid  style={{height:'20%',  marginRight:10, marginTop:0}}>

</Grid>}
        </Grid>   
 


        
        
        <Grid style={{ position: 'absolute', left: 20, top: 5, width:"50%", height:"50%"}}>
        <img src={this.state.despacho.Logo}  alt='' style={{ width:"17%",height:'15 %'}}/>
        </Grid>  
        <BotonesTriger/>   </>}    
        <ThemeProvider theme={theme}>
            <Snackbar         anchorOrigin={{ vertical , horizontal }}
                          key={`${this.state.vertical},${this.state.horizontal}`}
                          open={this.state.snack}
                          autoHideDuration={5000}
                          onClose={this.handleClose}
                          message={this.state.error2}
                          action={
                            <div>
                              <Button color="primary" size="small" onClick={this.handleClose}>
                                Cerrar
                              </Button>
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={this.handleClose}
                              >
                                <CloseIcon/>
                              </IconButton>
                            </div>
                          }         />
           <Snackbar         anchorOrigin={{ vertical , horizontal }}
                          key={`${this.state.vertical},${this.state.horizontal}`}
                          open={this.state.snack2}
                          autoHideDuration={5000}
                          onClose={this.handleClose}
                          message={this.state.gremioBorrar}
                          action={
                          <div>
                                 <Button onClick={this.anularGremio}>  
                                      <Fab  size="small" color="primary" aria-label="add"  variant="extended">
                                          <DoneOutlineIcon/>
                                        CONFIRMAR
                                       </Fab>
                                  </Button>
                                  <Button onClick={this.handleClose}>  
                                      <Fab  size="small" color="secondary" aria-label="add"  variant="extended">
                                      <CloseIcon/>
                                        CANCELAR
                                       </Fab>
                                  </Button>
                            </div>
             }                />           
         {!this.state.open&&       <Grid item xs style={{marginLeft:10}}>
                  <Grid   container spacing={5} direction="row" margin={4}  alignItems="center">
                             <Grid   item >
                                    <FormControl styles= {{   minWidth: 120}} >
                                        <InputLabel id="demo-simple-select-label">Comunidad</InputLabel>
                                        <Select
                                          labelId="comunidad"
                                          id="comunidad"
                                          name ="comunidad"
                                          value={this.state.comunidad}
                                          onChange={this.handleChange}
                                        >
                                          <MenuItem key={-1} value="">{""}</MenuItem> 
                                          {comus && comus.map(comu => (

                                            <MenuItem key={comu.Id} value={comu.Id}>{comu.Id}</MenuItem>                 
                                            ))         
                                          }
                                        </Select>
                                        <FormHelperText>Búsqueda rápida por comunidades</FormHelperText>
                                    </FormControl>
                          </Grid>
                          <Grid item>
                                    <FormControl styles= {{   minWidth: 120}} >
                                          <Button onClick={this.buscarUsuarios} variant="outlined"  
                                            size="large" color="primary"  startIcon={<SearchIcon />}>
                                                          Listado de usuarios
                                          </Button>
                                    
                                    
                                    </FormControl>
                          </Grid>
                          <Grid item>
                                    <FormControl styles= {{   minWidth: 120}} >
                                    
                                          <Button onClick={() => { 
                                            this.setState({comunidad:''})
                                            this.borrarFiltros() } }
                                            variant="outlined"  size="large" color="primary"  startIcon={<DeleteIcon />}>
                                                BORRAR FILTROS
                                          </Button> 
                                    
                                    </FormControl>
                          </Grid>
                          <Grid item style={{marginLeft:20}}>
                            <Tooltip title="Envía avisos a los usuarios previmante seleccionados">
                                <span>
                                  <Button     onClick={e => {
                                    this.setState({PathDirectorioActual:'',directorio_modificar:''})
                                    this.dialogoEnvio('masivo')}} variant="outlined" 
                                  disabled= {!this.state.usuarios_enviar.length||this.state.usuarios_enviar.length===0} 
                                  size="large" color="primary"  startIcon={<SendIcon />}>ENVIAR AVISO
                                  </Button>
                                </span>
                              </Tooltip>
                         
                          </Grid>
                 
                  </Grid>                          
            </Grid>}
            {mostrarTabla&&
              <Paper style={{width:'98%', overflowX:'auto', margin:10}}>
                             <MaterialTable 
                              components={{
                                Body: props => <MTableBody {...props} onFilterChanged={(columnId, value) => {
                                    props.onFilterChanged(columnId, value);
                                    const field= props.columns[columnId].field
                                    this.filterRef[field] = value
                                }} />
                                }}
                               options={{
                                doubleHorizontalScroll:true,
                                searchFieldStyle: {width:'100%',
                                },
                                exportButton: {
                                  csv: true,
                                  pdf: false
                                },
                                addRowPosition:'first',
                                maxBodyHeight: "80vh",
                                filtering: true,
                                selection: true,   
                                exportDelimiter:";",
                                exportAllData:true,
                                pageSize: 50, 
                                pageSizeOptions:	[20, 40, 60],
                              
                                headerStyle: {    
                                  backgroundColor: '#00a049',
                                  color: 'white'},
                                  paddingTop:3,
                                  paddingBottom:3
                                        
                              }}
                              onSelectionChange={(rows) => {
                                if (rows.length>0){  this.setState({usuarios_enviar:rows})

                                }else { this.setState({usuarios_enviar:[]})}
                                //alert('You selected ' + rows[0].Nombre + ' rows')}
                                }
                              }
                              
                              icons={tableIcons}
                              localization={{ 
                                body: {
                                  editRow: {
                                    saveTooltip: "Guardar",
                                    cancelTooltip: "Cancelar",
                                    deleteText:  '¿Quieres borrar a este usuario?'
                                  },
                                  addTooltip: "Crear uno nuevo",
                                  deleteTooltip: "Borrar",
                                  editTooltip: "Modificar"
                                },                               
                                header: {
                                  actions: 'Acciones'                        
             
                                },
                                toolbar: {
                                  nRowsSelected: '{0} Usuario(s) seleccionados para el envío de AVISO'
                              },
                              }}
                              title={ <h3  style={{ color: '#00a049' }}>Usuarios</h3>}

                              columns={[
                                { title: 'Avatar', field: 'Foto', editable: 'never',
                                render: rowData => <img src={rowData.Foto} alt='' style={{width: 40, borderRadius: '50%'}}/> },

                              {title: 'Nombre', field: 'displayName', defaultFilter: this.filterRef['displayName']},
                              //   { title: 'Identificador', field: 'Uid', editable: 'never' },
                              {title: 'Apellido', field: 'Apellido', defaultFilter: this.filterRef['Apellido']},
                              {title: 'Comunidad', field: 'Comunidad', editable:'onAdd', defaultFilter:
                               this.filterRef['Comunidad'],// lookup: this.state.lista_comunidades,
                              editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                                
                                <FormControl>
                                  <Select
                                   //   disabled={!rowData.comunidad || rowData.estado=== "CANCELADA" || rowData.estado=== "CERRADA"}
                                      value={rowData.Comunidad}
                                      onChange={e => {
                                        var newRowData = { ...rowData, tipo: e.target.value };
                                        rowData.Comunidad = e.target.value 
                                  
                                        newRowData.Comunidad=e.target.value

                                        this.state.comus&&this.state.comus.forEach((comu) => {
                                            
                                         if( comu.Id===rowData.Comunidad){
                                              direcciones=[]
                                               comu.Direcciones&&comu.Direcciones.map((comun) => (
                                                 direcciones = direcciones.concat({Nombre:comun})
                                                 ))
                       
                                        }} )
                                        onRowDataChange(newRowData);
                                      }}    >
                                        {this.state.comus&&this.state.comus.map((comu) => (
                                                      <MenuItem value={comu.Id} >
                                                        {comu.Id}
                                                      </MenuItem>
                                                    ))
                                        }
                                                    </Select>
                                  </FormControl>
                              ) 
                                             
                            },
                              {title: 'Telefono', field: 'Telefono', defaultFilter: this.filterRef['Telefono']},
                              {title: 'Categorías', field: 'Categorias', defaultFilter: this.filterRef['Categorias'],
                              render: rowData =>selec_lectura('Categorias',rowData,true),

                                   editComponent: ({ onChange, onRowDataChange, rowData }) => {
                                      if (!rowData.Categorias|| rowData.Categorias.length===0){ 
                                        rowData.Categorias=[]
                                        rowData.Categorias[0]=""}
                                        var listMenu=[]
                                        this.state.categorias.forEach(function(actividad)  
                                          {    
                                                if(actividad.Comunidad&&actividad.Comunidad!==""){
                                                  const melon2 = rowData.Comunidad
                                                  if(actividad.Comunidad[melon2]){
                                                    listMenu = listMenu.concat({'Nombre':actividad.Nombre})
                                                  }
                                          }})
                                        
                                          return(selecto('Categorias','Categorias',onRowDataChange,rowData,this, false,listMenu))}
                                  },
                                   {title: 'Rol Asignado', field: 'Roles', defaultFilter: this.filterRef['Roles'],
                              render: rowData =>selec_lectura('Roles',rowData,true),
                                    editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
                                      if (!rowData.Roles|| rowData.Roles.length===0){ 
                                        rowData.Roles=[]
                                        rowData.Roles[0]=""}
                                      return(selecto('Roles','Roles',onRowDataChange,rowData,this, false,this.state.roles))}
                                  },
                              {title: 'Sub-Rol', field: 'Rol',  defaultFilter: this.filterRef['Rol']},
                              {title: 'Correo', field: 'email', defaultFilter: this.filterRef['email']},
                              {title: (despacho.DatosPais ? despacho.DatosPais.Dni:'Documento'), field: 'DNI', defaultFilter: this.filterRef['DNI']},
                              {title: 'Dirección', field: 'Direccion', defaultFilter: this.filterRef['Direccion'],
                              editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => ( 
                                <FormControl>
                                  <Select
                                   //   disabled={!rowData.comunidad || rowData.estado=== "CANCELADA" || rowData.estado=== "CERRADA"}
                                      value={rowData.Direccion}
                                      onChange={e => {
                                        var newRowData = { ...rowData, tipo: e.target.value };
                                        rowData.Direccion = e.target.value 
                                        newRowData.Direccion =  rowData.Direccion
                                   
                                        
                                //         var comunidad = comus.filter(comu =>comu.Id=== newRowData.comunidad)
                              
                                      onRowDataChange(newRowData);
                                      }}    >
                                        {
                                      
                                        this.state.comus&&this.state.comus.forEach((comu) => {
                                            
                                        if( comu.Id===rowData.Comunidad){
                                            if(direcciones&&direcciones.length>0){
                                              return(direcciones&&direcciones.map((comu) => (
                                                 
                                                <MenuItem value= {comu.Nombre} >
                                                {comu.Nombre}
                                              </MenuItem>
                                               )))
                                            } else{
                                          
                                             comu.Direcciones&&comu.Direcciones.map((comun) => (
                                               direcciones = direcciones.concat({Nombre:comun})
                                               ))
                                               return(direcciones&&direcciones.map((comu) => (
                                                 
                                                <MenuItem value= {comu.Nombre} >
                                                {comu.Nombre}
                                              </MenuItem>
                                               )))
                                            }
                                              
                                         }} )
                                                  
                                             
                       
                                        }
                                        
                                                    </Select>
                                  </FormControl>
                                  )   },
                              {title: 'Numero', field: 'Numero', defaultFilter: this.filterRef['Numero'],
                             },
                              {title: 'Portal Interno(Op)', field: 'Portal', defaultFilter: this.filterRef['Portal']},
                              {title: 'Escalera (Op)', field: 'Escalera', defaultFilter: this.filterRef['Escalera']},
                              {title: 'Piso', field: 'Piso', defaultFilter: this.filterRef['Piso']},
                              {title: 'Letra/Puerta', field: 'Letra', defaultFilter: this.filterRef['Letra']},
                            //  {title: 'Código Comunidad', field: 'Codigo9'},
                         
                              { title: "Verificación email",
                                    field: "emailVerified", defaultFilter: this.filterRef['emailVerified'],
                                    type: "boolean",

                                    render: rowData =>checkBoxLectura(rowData.emailVerified),
                                    editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                                      checkBoxModificar('emailVerified',false,rowData, onRowDataChange,null)
                                     )
                                 },
                            {title: "Fichar",
                                 field: "Fichar", defaultFilter: this.filterRef['Fichar'],
                                 type: "boolean",

                                 render: rowData =>checkBoxLectura(rowData.Fichar),
                                 editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                                 checkBoxModificar('Fichar',!rowData.Roles|| rowData.Roles.length===0,
                                   rowData, onRowDataChange,null))                     
                              },  

                            {title: "Porcentaje Propiedad %",field: "Porcentaje", defaultFilter: this.filterRef['Porcentaje'],type: "numeric"},
                            {title: 'Último-Acceso', field: 'lastSignInTime',type:'date', defaultFilter: this.filterRef['lastSignInTime'], editable: 'never',
                            customSort: (a, b) =>(Number(moment(a.metadata.lastSignInTime).format('YYYMMDDHHmmss'))-Number(moment(b.metadata.lastSignInTime).format('YYYMMDDHHmmss')))
                          },
                           
                            {title: 'Fecha Creación', field: 'creationTime', type: 'date',
                            customSort: (a, b) =>(Number(moment(a.metadata.creationTime).format('YYYMMDDHHmmss'))-Number(moment(b.metadata.creationTime).format('YYYMMDDHHmmss'))),
                         
                            editable: 'never' },
                         
                            {title: 'Convivientes', field: 'lista_convivientes',  hidden:true},
                            
                          
                          ]}
                          actions={ [
                      
                            {
                              //No conseguimos darle valor a rowData cuando va por render el icon
                              icon:(event, rowData)=> <NoteAddIcon style={{ color: "#00a049" }}/>,
                              tooltip: 'Ajuntar documentación',
                              position:'row',
                              onClick: (event, rowData) =>{
                                this.buscarficheros('usuarios/'+rowData.uid+"/", rowData)
                                this.setState({ rowData:rowData, directorioPrincipal:'Inicio'})
                              }
                            },
                            {
                                icon:  () => <AddBox />,
                                tooltip: 'Añadir un nuevo usuario',
                                // This makes add button to appear in table toolbar instead for each row
                                isFreeAction: true,
                                onClick: (event, rowData) => {
                                  this.setState({abrir_cambios:true, editar:true, tipoRegistro:'nuevo',
                                  rowData:[], 
                                  mNombre:'', mApellido:'', mComunidad:'',mTelefono:'',mCategorias:[],
                                  mRoles:[],mRol:'',memail:'', mDireccion:'',mDNI:'',
                                  mNumero:'',mPortal:'',mEscalera:'',mPiso:'',mLetra:'',
                                  memailVerified:false,mFichar:false, mPorcentaje:'',
                                  mlastSignInTime:'', mcreationTime:''
                                 })
                             }
                          }
                          ]}
                          data={this.state.usuarios}
                          detailPanel={[
                                {
                                  tooltip: 'Show Name',
                                 
                                  render: rowData => {
                                //    if( rowData.Roles&&rowData.Fichar){  this.traer_horas_trabajadas (rowData.uid)}
                                    return (
                                      <div
                                        style={{
                                          fontSize: 15,
                                          textAlign: 'start',
                                          color: 'primary',
                                          backgroundColor: 'azure',
                                          marginLeft:30,
                                          marginRight:30
                                        }}
                                      >
                                        <Box fontWeight="fontWeightBold" m={1}> 
                                              Fecha creación Usuario:{rowData.creationTime}
                                              <hr/>
                                              Último acceso App: {rowData.lastSignInTime}
                                              <hr/>
                                     {(!rowData.MultiCom||rowData.MultiCom===false)&&  <p> 
                                             <Button   onClick={() => {this.handleClickOpen(rowData)}} variant="contained"  
                                                size="large" color="primary"  startIcon={<LockOpen />}>
                                                                    RESETEAR LA CONTRASEÑA
                                              </Button>
                                         <hr/>
                                              <Button
                                               onClick={() => {this.envioEmailVerificacion(rowData.Email)}} 
                                               variant="contained"
                                                size="large" color="primary"  startIcon={<VerifiedUser />}>
                                                                    SOLICITAR CONFIRMACIÓN CUENTA POR EMAIL
                                              </Button>
                                               <hr/>
                                               <Tooltip title="Opciones de multicomunidad">
                                                  <span>
                                                    <Button     onClick={this.abrirMulticomunidad2(rowData)} variant="contained" 
                                                        
                                                        size="large" color="primary"  startIcon={<ApartmentIcon />}>MULTICOMUNIDAD
                                                    </Button>
                                             
                                                  </span>
                                                </Tooltip>
                                                <hr/>
                                            </p>}                                             
                                           
                                           
                                             
                                        </Box>
                                                           
                                     
                                      </div>
                                    )
                                  },
                                }
                          ]}
                         
                          onRowClick={(event, rowData, togglePanel) => {
                             const lastSignInTime=rowData.lastSignInTime
                            const creationTime =rowData.creationTime 
                            this.setState({tipoRegistro:'modificacion',rowData:rowData, abrir_cambios:true, mNombre:rowData.displayName, mApellido:rowData.Apellido,
                              mComunidad:rowData.Comunidad,mTelefono:rowData.Telefono,mCategorias:(rowData.Categorias ? rowData.Categorias:[]),
                              mRoles:(rowData.Roles ? rowData.Roles:[]),mRol:rowData.Rol,
                              memail:rowData.email, mDireccion:rowData.Direccion,mDNI:rowData.DNI,
                              mNumero:(rowData.Numero ? rowData.Numero:''),
                              mPortal:(rowData.Portal ? rowData.Portal:''),
                              mEscalera:(rowData.Escalera ? rowData.Escalera:''),
                              mPiso:(rowData.Piso ? rowData.Piso:''),
                              mLetra:(rowData.Letra ? rowData.Letra:''),
                              memailVerified:(rowData.emailVerified ? rowData.emailVerified:false),
                              mFichar:(rowData.Fichar ? rowData.Fichar:false),
                              mPorcentaje:(rowData.Porcentaje ? rowData.Porcentaje:0),
                              mlastSignInTime:(lastSignInTime ? lastSignInTime:''),
                              mcreationTime:(creationTime ? creationTime:''),
                              lista_convivientes:(rowData.lista_convivientes ? rowData.lista_convivientes:[])
        
                              })
                            }
                 /*        onRowClick={(event, rowData, togglePanel) =>
                                
                                {     this.state.comus&&this.state.comus.map((comu) => {
                                            
                                          if( comu.Id===rowData.Comunidad){
                                              direcciones=[]
                                              comu.Direcciones&&comu.Direcciones.map((comun) => (
                                                direcciones = direcciones.concat({Nombre:comun})
                                                ))
                      
                                          }} 
                                        )} */
                         }
                          editable={{
                            isEditable: rowData => 
                            !rowData.MultiCom || rowData.MultiCom === false, //
                            isDeletable: rowData =>
                             !rowData.MultiCom || rowData.MultiCom === false, //
                            onRowUpdate: (newData, oldData) =>
                            this.rowActualiza(newData, oldData)
                           ,
                            onRowDelete: oldData =>                            
                              new Promise(resolve => {

                                this.borrarMulticomu(oldData).then((clave)=>{

                                  
                                     this.comprobarUsuarioOtrosDepachos(oldData,true).then((existe)=>{

                                      if(!existe){
                                      var deleteUser= this.props.firebase.functions.httpsCallable('deleteUser');
                                                  
                                      new Promise((resolve,reject) => {  
                                        deleteUser({uid:clave}).then(function(result){
                                            // Read result of the Cloud Function.
                                         
                                            resolve(result)                                 
                                
                                          }).catch(function(error) {
                                            // Getting the Error details.
                                        
                                            reject(error)
                                            // ...
                                          })
                                      
                                      }).then(() => { 
                                       // this.buscarUsuarios()
                                       const users = this.state.usuarios.filter(usr => usr.uid!==oldData.uid)
                                       this.setState({ usuarios: users ,usuariosClave:users})
                                       this.setState({ mostrarTabla: true, open:false });  
                                        alert("Usuario borrado correctamente") 
                                        resolve(true)
                                      })
                                        .catch(function(error) {alert("No se ha podido borrar el registro")  });
                                      }else{
                                       // this.buscarUsuarios()
                                       const users = this.state.usuarios.filter(usr => usr.uid!==oldData.uid)
                                       this.setState({ usuarios: users ,usuariosClave:users})
                                       this.setState({ mostrarTabla: true, open:false });  
                                        alert("Usuario borrado correctamente")
                                        resolve(true)
              
                                      }  
                                      })                            
                                })
                              })              
                          }}
                            />
                  </Paper>
              }

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              open={this.state.abrir_cambios}
              onClose={e=>this.cerrarGestionCambios()}

            >
            <Paper style={{ height: '60%', marginTop:'-10%',  width:'80%'}}>
                <Paper  style={{padding:10,      maxHeight: '100%', overflowY: 'auto',
              
              }}> 
          
                <h3 style={{ color: 'white', backgroundColor:'#00a049', marginTop:'-0.5%' }}>Usuario: {this.state.mNombre} </h3>
               <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                <Grid container spacing={1}>
                  <Grid item sm={4} xs={12}>
                  {Textofield ('mNombre','Nombre del usuario',!this.state.editar,null,this)}
                  </Grid>
                  <Grid item sm={4} xs={12}>
                  {Textofield ('mApellido','Apellido usuario',!this.state.editar,null,this)}
                  </Grid>
                  <Grid item sm={4} xs={12}>
                  
                 {this.state.tipoRegistro==='nuevo'&& 
                  selecSimple('mComunidad','Comunidad',this,false)}
                      {this.state.tipoRegistro!=='nuevo'&&Textofield ('mComunidad','Comunidad',true,null,this)}
                        
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                <Grid item sm={4} xs={12}>
                  {Textofield ('mDNI','DNI',!this.state.editar,null,this)}
                  </Grid>
                  <Grid item sm={4} xs={12}>
                  {Textofield ('mTelefono','Teléfono',!this.state.editar,null,this)}
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    {Textofield ('memail','Correo',!this.state.editar,null,this)}
                    </Grid>          
                </Grid>
                <Grid container spacing={1}>
                  <Grid item sm={4} xs={12}>
                    <FormControl fullWidth margin='normal'>
                       <InputLabel shrink htmlFor="select-multiple-native">
                               Dirección
                        </InputLabel>
                        <Select
                            value={this.state.mDireccion}
                            disabled={!this.state.editar}
                            onChange={e => {this.setState({mDireccion:e.target.value})}}
                                >
                  

                               {this.state.comus&&this.state.comus.map((comu) => {
                                if( comu.Id===this.state.mComunidad){
                                  var direcciones=[]
                                     comu.Direcciones&&comu.Direcciones.map((comun) => (
                                     direcciones = direcciones.concat({Nombre:comun})
                                            ))
                                  return(direcciones&&direcciones.map((comu) => (
                                         <MenuItem value= {comu.Nombre} >
                                         {comu.Nombre}
                                    </MenuItem>
                                                                      )))
                                                                    
                                                                      
                                                                }} )}
                      </Select>
                      </FormControl> 


                   </Grid>
                    <Grid item sm={4} xs={12}>
                    {Textofield ('mNumero','Número',!this.state.editar,null,this)}
                     
                    </Grid>
                    <Grid item sm={4} xs={12}>
                    {Textofield ('mPortal','Portal',!this.state.editar,null,this)}                              
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                <Grid item sm={5} xs={4}>
                    {Textofield ('mPiso','Piso',!this.state.editar,null,this)}
                    </Grid>
                    <Grid item sm={5} xs={4}>
                    {Textofield ('mLetra','Puerta',!this.state.editar,null,this)}
                    </Grid>
                    <Grid item sm={2} xs={4}  style={{textAlign: "center", marginTop:'-0.5%'}}>
                    <h5 style={{ marginBottom:-2, color: 'white', backgroundColor:(this.state.editar ? '#00a049':'darkseagreen') }}>Verificación email</h5>
               
                  {checkBoxModificar ('emailVerified',!this.state.editar,this.state.rowData, null,this)}
                                 
                </Grid>
                          

                       
               </Grid>
            
                <Grid container spacing={1}>
                    <Grid item sm={6} xs={12}>
                    <h5 style={{ color: 'white', backgroundColor:(this.state.editar ? '#00a049':'darkseagreen')}}>Categorías</h5>
                    {selecto('Categorias','mCategorias',null,this.state.rowData,this, !this.state.editar,this.state.categorias)}
                    </Grid>
                    <Grid item sm={6} xs={12}>
                    <h5 style={{ color: 'white', backgroundColor:(this.state.editar ? '#00a049':'darkseagreen') }}>Roles asginados</h5>
                    {selecto('Roles','mRoles',null,this.state.rowData,this, !this.state.editar,this.state.roles)}
                    </Grid>
                   

                   
                </Grid>
                <Grid container spacing={1}>
                <Grid item sm={5} xs={4}>
                    {Textofield ('mPorcentaje','Porcentaje enteros',!this.state.editar,null,this)}
             
                    </Grid>
                    <Grid item sm={5} xs={4}>
                    {Textofield ('mRol','Subrol',!this.state.editar,null,this)}
                    </Grid>
                     <Grid item sm={2} xs={4}  style={{textAlign: "center", marginTop:'-0.5%'}}>
                    <h5 style={{  marginBottom:-2, color: 'white', backgroundColor:(this.state.editar ? '#00a049':'darkseagreen') }}>Fichar</h5>
                    {checkBoxModificar ('Fichar',!this.state.editar || !this.state.mRoles|| this.state.mRoles.length===0,
                        this.state.rowData, null,this)}
                    </Grid>
        
                </Grid>
                <Grid container spacing={1}>
                <Grid item sm={12} xs={12}>
                    
                        {TablaConvivientes(this)}
                    </Grid>
        
                </Grid>
                <Grid container spacing={1}>
             
                {(!this.state.rowData.MultiCom||this.state.rowData.MultiCom===false)&&this.state.tipoRegistro==='modificacion'&&
                     <Grid item sm={6}>
                     <hr/>
                     <Tooltip title="Resetea la contraseña del usuario creando una nueva">
                        
                        <Button
                        fullWidth
                        onClick={() => {this.handleClickOpen(this.state.rowData)}} variant="contained"  
                                                size="large" color="primary"  startIcon={<LockOpen/>}>
                                    RESETEAR LA CONTRASEÑA
                       </Button>
                       </Tooltip>
                         <hr/>
                        <Tooltip title="Vuelve a enviar la confirmación de cuenta al usuario sobre el correo que tiene asociado">
                        <Button
                        fullWidth
                                  onClick={() => {
                                    this.envioEmailVerificacion(this.state.rowData.Email)}} 
                                  variant="contained"
                                  size="large" color="primary"  startIcon={<VerifiedUser />}>
                                        SOLICITAR CONFIRMACIÓN CUENTA POR EMAIL
                        </Button>
                        </Tooltip>
                                               <hr/>
                         <Tooltip title="Opciones de multicomunidad">
                          <Button  
                             fullWidth   onClick={this.abrirMulticomunidad2(this.state.rowData)} variant="contained" 
                             size="large" color="primary"  startIcon={<ApartmentIcon />}>MULTICOMUNIDAD
                          </Button>
                             
                          </Tooltip>
                          <hr/>

                          </Grid>}   
        
             
      
                </Grid>
                </Grid>
         
                </Paper>
                {!this.state.editar&&
               
                  <Button   variant="contained" 
                      onClick={e => {                        
                        if(!this.state.rowData.MultiCom||this.state.rowData.MultiCom===false){
                          this.setState({editar:true})
                        }else{
                          alert('Este usuario es un usuario multicomunidad. Por favor, para cualquier modficación debes hacerlo desde su usuario principal en la tabla y ejecutando su opción MULTICOMUNIDAD.')
                        }
                      }}
                        
                    
                      color="primary"
                      style={{top:'1%' }}
                  >Editar</Button>
                }
               {this.state.editar&&
               <>
                  <Button  variant="contained"    style={{top:'1%', backgroundColor:'orange' }}
                      onClick={e => { 
                        if(this.state.tipoRegistro==='nuevo')  {   this.setState({ abrir_cambios:false})}
                        else{
                        const rowData=this.state.rowData
                        this.setState({ abrir_cambios:true,editar:false
                          ,mNombre:rowData.displayName, mApellido:rowData.Apellido,
                          mComunidad:rowData.Comunidad,mTelefono:rowData.Telefono,mCategorias:rowData.Categorias,
                          mRoles:(rowData.Roles ? rowData.Roles:[]),mRol:rowData.Rol,
                          memail:rowData.email, mDireccion:rowData.Direccion,mDNI:rowData.DNI,
                          mNumero:rowData.Numero,mPortal:rowData.Portal,mEscalera:rowData.Escalera,
                          mPiso:rowData.Piso,mLetra:rowData.Letra,
                          memailVerified:rowData.emailVerified,
                          mFichar:rowData.Fichar, mPorcentaje:rowData.Porcentaje,
                          lista_convivientes:(rowData.lista_convivientes ? rowData.lista_convivientes:[])
                      
                        })
                      }
                      }}    
                      color="primary"

                  >Cancelar</Button>
          
                 
                  <Button variant="contained"   color="primary" type="submit"  style={{top:'1%', left:5 }}

                    disabled={this.state.mNombre.trim()===''||this.state.mDNI.trim()===''||this.state.mApellido.trim()===''
                    ||this.state.mComunidad.trim()===''||this.state.memail.trim()===''||this.state.mDireccion.trim()===''
                    ||this.state.mTelefono.trim()===''}
                  
                    onClick={e => {
                      this.setState({editar:false})
                      const newData={...this.state.rowData ,displayName:this.state.mNombre, Apellido:this.state.mApellido,
                        Comunidad:this.state.mComunidad,Telefono:this.state.mTelefono,mCategorias:this.state.mCategorias,
                        Roles:this.state.mRoles,Rol:this.state.mRol,lista_convivientes:this.state.lista_convivientes,
                        email:this.state.memail, Direccion:this.state.mDireccion,DNI:this.state.mDNI,
                        Numero:this.state.mNumero,Portal:this.state.mPortal,Escalera:this.state.mEscalera,
                        Piso:this.state.mPiso,Letra:this.state.mLetra,
                        emailVerified:this.state.memailVerified,
                        Fichar:this.state.mFichar, Porcentaje:this.state.mPorcentaje}

                        if(this.state.tipoRegistro!=='nuevo')  {   
                          let guardar=false
                          if(this.state.abrir_cambios){guardar=true}
                          this.setState({open:true,abrir_cambios:false,abrirContraseña:false,mostrarTabla:false})
                    
                            this.rowActualiza(newData, this.state.rowData).then((data)=>{
                              if(data){this.setState({rowData:newData})}
                              else{  
                                this.setState({ 
                                mNombre:this.state.rowData.displayName, mApellido:this.state.rowData.Apellido,
                                mTelefono:this.state.rowData.Telefono,
                                mRoles:(this.state.rowData.Roles ? this.state.rowData.Roles:[]),mRol:this.state.rowData.Rol,
                                memail:this.state.rowData.email,mDNI:this.state.rowData.DNI,
                                memailVerified:this.state.rowData.emailVerified,
                                mFichar:this.state.rowData.Fichar, mPorcentaje:this.state.rowData.Porcentaje,
                            
                           
                              })
                            }
                            this.setState({open:false,mostrarTabla:true})
                            if(guardar){this.setState({abrir_cambios:true})}
                            })                
                        }else{
                          this.setState({abrir_cambios:false,open:true,mostrarTabla:false})
                          this.rowAñadir(newData).then((data)=>{
                            this.setState({abrir_cambios:true,open:false,mostrarTabla:true, editar:true})
                            if(data){ this.setState({abrir_cambios:false})}
                        })}
                    }}    
                  >Guardar</Button>
                  </>
                }
                </Paper>
            
            </Modal>
       
              <Modal
                                                      aria-labelledby="transition-modal-title"
                                                      aria-describedby="transition-modal-description"
                                                      style={{display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent: 'center'}}
                                                      
                                                      open={this.state.abrir_horas}
                                                      onClose={this.cerrarGestionHoras}
                                                    
                                                    >
                   <Paper style={{maxHeight: 500, overflow: 'auto',  marginLeft:20, marginRight:20,marginTop:20}}>                                 
                       <MaterialTable
                                 options={{
                                  exportButton: {
                                    csv: true,
                                    pdf: false
                                  },
                                 filtering: true,
                                 pageSize: 10,  
                                 grouping: true,
                                 exportDelimiter: ";",
                                 exportAllData: true,
                                 pageSizeOptions:	[5, 10, 20],
                                 headerStyle: {
                                   backgroundColor: '#00a049',
                                   color: 'white'},
                                   paddingTop:5,
                                   paddingBottom:5
                                         
                               }}
                               
                               icons={tableIcons}
                        
                               title={ <h2  style={{ color: '#00a049' }}>PLANTILLA CONTROL DE HORAS
                                          <h5  style={{ color: '#00a049' }}>{this.state.nombreyapellidos}</h5> </h2>}
 
                               columns={[
                                {title: 'Fecha', field: 'Fecha'},
                                {title: 'Entrada', field: 'Entrada'},
                                {title: 'Salida', field: 'Salida'},
                                {title: 'Horas', field: 'Horas'},

                              ]}
                        
                              data={listaHorasTrabajadas}
 
                          />
                  </Paper>
              </Modal>

              <Dialog open={this.state.abrirContraseña} /*onClick={this.handleClickClose}*/ aria-labelledby="form-dialog-title"
                                    fullWidth="true"
                                    maxWidth="sm"
                                  scroll="paper"
                                  >
                                  <DialogTitle id="form-dialog-title">Reseteo Contraseña</DialogTitle>
                                    <DialogContent dividers>
                                          <DialogContentText
                                            id="scroll-dialog-description"
                                        //    ref={descriptionElementRef}
                                            tabIndex={-1}
                                            style={{whiteSpace: "pre-wrap"}}
                                          >
                                       
                                            {"Se va resetear la contraseña del usuario, en el momento que se realice la actualización el usuario deberá acceder a la aplicación con la nueva contraseña"}
                                          </DialogContentText>
                                            <TextField
                                              autoFocus
                                              margin="dense"
                                              id="name"
                                              name ="contraseña"
                                              label="Añade la nueva contraseña (Al menos 6 dígitos)"
                                              type="text"
                                              value={contraseña}
                                              onChange={this.onChange}
                                              fullWidth
                                              />
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={this.handleClickClose} color="primary"
                                    >
                                      Cancelar
                                    </Button>
                                    <Button   
                                     disabled={contraseña.length<6}
                                     onClick={e => { 
                                      var guardar =false // l ohacemos por si se hace desd la ventan del detalle del usuario o no, para volverla abrir
                                      if(this.state.abrir_cambios){guardar=true}
                                      this.setState({open:true,abrir_cambios:false,abrirContraseña:false,mostrarTabla:false})
                                      this.handleClickCloseOk().then(()=>{
                                        this.setState({open:false,mostrarTabla:true})
                                        if(guardar){
                                          this.setState({abrir_cambios:true})
                                     
                                        }
                                      })
                                    }
                                    
                                    } color="primary"
                                    >
                                      Confirmar
                                    </Button>
                                  </DialogActions>
                                </Dialog>
              <Dialog open={this.state.abrirAviso} /*onClick={thi.handleClickClose}*/ aria-labelledby="form-dialog-title"
                                    fullWidth="true"    maxWidth="md"          scroll="paper"          >
                                  <DialogTitle id="form-dialog-title">Envío Aviso</DialogTitle>
                                    <DialogContent dividers>
                                         
                                          <TextField
                                              autoFocus
                                              margin="dense"
                                              id="titulo"
                                              name ="texto_titulo"
                                              label="Añade el título del AVISO"
                                              type="text"
                                              value={texto_titulo}
                                              onChange={this.onChange}
                                              fullWidth
                                              />
                                            <TextField
                                              
                                              multiline
                                              margin="dense"
                                              id="texto_envio"
                                              name ="texto_envio"
                                              label="Añade el texto del AVISO"
                                              type="text"
                                              value={texto_envio}
                                              onChange={this.onChange}
                                              fullWidth
                                              />
                                                <TextField

                                                    multiline
                                                    margin="dense"
                                                    id="texto_url"
                                                    name="texto_url"
                                                    label="Añade url (Opcional)"
                                                    type="text"
                                                    value={texto_url}
                                                    onChange={this.onChange}
                                                    fullWidth
                                                    />

                                              
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={this.cerrarAviso} color="primary"
                                    >
                                      Cancelar
                                    </Button>
                                    <Button
                                       disabled ={this.state.texto_envio==="" || this.state.texto_titulo==="" }
                                       onClick={(event) =>{this.enviaAvisoUsuario()}} color="primary"
                                    >
                                      Confirmar
                                    </Button>
                                  </DialogActions>
              </Dialog>
              <Modal
                           aria-labelledby="transition-modal-title"
                           aria-describedby="transition-modal-description"
                           style={{display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center'}}
                            open={this.state.avisos_enviados}
                            onClose={e=> this.setState({avisos_enviados:false,resultado_aviso:[],usuariosTotal:[]})}                                        
                                                    >
                   <Paper style={{maxHeight: 500, overflow: 'auto', alignContent:'center',  alignItems:'center',
                      marginLeft:20, marginRight:20,marginTop:20}}>   
                       <h2  style={{ justifyItems:'center',
                         marginLeft:20,color: '#00a049' }}>Resultado Avisos Enviados </h2>
                  
                       <h3  style={{  marginLeft:20, color: '#00a049' }}>Total USUARIOS que se ha enviado el AVISO: {this.state.usuariosTotal.length}</h3>                              
                       <h3  style={{  marginLeft:20, color: '#00a049' }}>Total dispositivos enviado AVISO: {this.state.resultado_aviso.length}</h3>                              
               
                       <h3  style={{  marginLeft:20, color: '#00a049' }}>Total dispositivos OK: {totalMensajesOK}</h3>                              
                       <h3  style={{  marginLeft:20, color: '#00a049' }}>Total dispositivos KO: {totalMensajesKO}</h3>                              
          
                       <MaterialTable
                                 options={{
                                  exportButton: {
                                    csv: true,
                                    pdf: false
                                  },
                                 filtering: true,
                                 selection: false,   
                                 pageSize: 10,  
                                 grouping: true,
                                 exportDelimiter: ";",
                                 exportAllData: true,
                                 pageSizeOptions:	[5, 10, 20],
                                 headerStyle: {
                                   backgroundColor: '#00a049',
                                   color: 'white'},
                                   paddingTop:5,
                                   paddingBottom:5
                                         
                               }}
                               
                               localization={{ 
                               
                                toolbar: {
                                  nRowsSelected: 'TOTAL DISPOSITIVOS que se ha enviado el aviso: {0}'
                              },
                              }}
 
                               icons={tableIcons}
                        
                               title={'TABLA RESULTADOS'}
 
                               columns={[
                                {title: 'Nombre', field: 'Nombre'},
                                {title: 'DNI', field: 'DNI'},
                                {title: 'Administrador', field: 'Administrador'},
                                
                                {title: 'Comunidad', field: 'Comunidad'},
                                {title: 'Error de Envío', field: 'error'},
                                {title: 'Código Error', field: 'codigo'},
                                {title: 'Mensaje Error', field: 'mensaje'},
                                {title: 'Dispositivo', field: 'Dispositivos'},

                              ]}
                 
                              data={this.state.resultado_aviso}
 
                          />
                  </Paper>
              </Modal>
     
               <Modal
                         aria-labelledby="transition-modal-title"
                         aria-describedby="transition-modal-description"
                         style={{display: 'flex',
                         alignItems: 'center',
                         justifyContent: 'center'}}
                         open={this.state.abrir_multicomunidad}
                         onClose={(e)=>{this.setState({editar:false,abrir_multicomunidad:false})}}
                                  
              >
             <p  style={{width:'80%',height:'80%', overflowX:'auto'}}>
              <Paper style={{backgroundColor:'mintcream'}}>
     
                 <form >
                       <Grid     container      direction="row"       justifyContent='space-around'     alignItems="center"      >
                          <TextField  InputLabelProps={{ shrink: true }}
                          //disabled ={true}
                          variant="filled"
                          margin="dense"
                          id="codigo"
                          label="Nombre"
                          name="codigo"
                          size="small"
                          autoComplete="codigo"
                          
                          value={this.state.rowData.displayName}
                     
                          type="text"
                      //    placeholder="Código Administrador"
                        />
                                <TextField  InputLabelProps={{ shrink: true }}
                          variant="filled"
                          margin="normal"
                          id="registro"
                          label="Apellido"
                          name="registro"
                          autoComplete="registro"
          
                          value={this.state.rowData.Apellido}
                          size="small"
                          type="text"
                          placeholder="Fecha de Registro"
                        />
                     
                          <TextField  InputLabelProps={{ shrink: true }}
                          variant="filled"
                          margin="normal"
                          id="registro"
                          label="Telefono"
                          name="registro"
                          autoComplete="registro"
          
                          value={this.state.rowData.Telefono}
                          size="small"
                          type="text"
                          placeholder="Fecha de Registro"
                        />
                    
                          </Grid>
                         <Grid     container      direction="row"       justifyContent='space-around'     alignItems="center"      >
                         <TextField  InputLabelProps={{ shrink: true }}
                         // disabled ={true}
                          variant="filled"
                          margin="normal"
                          name="cif"
                          label="Identificador"
                          id="cif"
                          autoComplete="cif"
                          value={this.state.rowData.DNI}
                          type="numeric"
                          placeholder="CIF o NIF"
                          size="small"
                         />
                        <TextField  InputLabelProps={{ shrink: true }}
                         // disabled ={true}
                          variant="filled"
                       
                          label="Correo"
                       
                          value={this.state.rowData.email}
                          type="numeric"
             //             placeholder="Teléfono Principal"
                          size="medium"
                         />
                      
                      </Grid>
          
                        </form>
               
              </Paper>
        
              
              <Paper style={{width:'100%', overflowX:'auto'  }}>
                             <MaterialTable 
                            
                               options={{
                                doubleHorizontalScroll:true,
                                searchFieldStyle: {width:'100%',
                                },
                                exportButton: {
                                  csv: true,
                                  pdf: false
                                },
                                addRowPosition:'first',
                                maxBodyHeight: "80vh",
                                filtering: true,
                              
                                exportDelimiter:";",
                                exportAllData:true,
                                pageSize: 50, 
                                pageSizeOptions:	[20, 40, 60],
                              
                                headerStyle: {
                                
                                  backgroundColor: '#00a049',
                                  color: 'white'},
                                paddingTop:3,
                                paddingBottom:3
                                        
                              }}
                       
                              icons={tableIcons}
                              localization={{ 
                                body: {
                                  editRow: {
                                    saveTooltip: "Guardar",
                                    cancelTooltip: "Cancelar",
                                    deleteText:  '¿Quieres borrar a este usuario?'
                                  },
                                  addTooltip: "Crear uno nuevo",
                                  deleteTooltip: "Borrar",
                                  editTooltip: "Modificar"
                                },                               
                                header: {
                                  actions: 'Acciones'                        
             
                                },
                                toolbar: {
                                  nRowsSelected: '{0} Usuario(s) seleccionados para el envío de AVISO'
                              },
                              }}
                              title={ <h3  style={{ color: '#00a049' }}>MULTICOMUNIDAD</h3>}

                              columns={[
            
                              {title: 'Comunidad', field: 'Comunidad', defaultFilter: this.filterRef['Comunidad'],// lookup: this.state.lista_comunidades,
                              editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                                
                                <FormControl>
                                  <Select
                                   //   disabled={!rowData.comunidad || rowData.estado=== "CANCELADA" || rowData.estado=== "CERRADA"}
                                      value={rowData.Comunidad}
                                      onChange={e => {
                                        var newRowData = { ...rowData, tipo: e.target.value };
                                        rowData.Comunidad = e.target.value 
                                  
                                        newRowData.Comunidad=e.target.value

                                        this.state.comus&&this.state.comus.forEach((comu) => {
                                            
                                          if( comu.Id===rowData.Comunidad){
                                              direcciones=[]
                                               comu.Direcciones&&comu.Direcciones.map((comun) => (
                                                 direcciones = direcciones.concat({Nombre:comun})
                                                 ))

                                              newRowData.direcciones=direcciones
                                        }} )
                                        
                                        onRowDataChange(newRowData);
                                      }}    >
                                        {this.state.comusMulti&&this.state.comusMulti.map((comu) => (
                                                      <MenuItem value={comu.Id} >
                                                        {comu.Id}
                                                      </MenuItem>
                                                    ))
                                        }
                                                    </Select>
                                  </FormControl>
                              ) 
                                             
                            },
                            {
                              title: 'Convivientes', field: 'convivientes', defaultFilter: this.filterRef['convivientes'],
                              render: rowData =>botonConvivientes(rowData)
                               ,

                              editComponent: ({ onChange, onRowDataChange, rowData, ...p }) =>
                              
                              {  if(!this.state.editar){this.setState({editar:true})}
                                 return(botonConvivientes(rowData))}
                                }
                            ,        
                           
                              {title: 'Categorías', field: 'Categorias', defaultFilter: this.filterRef['Categorias'],
                              render: rowData =><Select
                                  labelId="demo-mutiple-chip"
                            //      disabled={true}
                                  multiple
                                  value={rowData.Categorias}
                                  renderValue={(selected) => (
                                    <div style={{  display: 'flex', flexWrap: 'wrap',}}>
                                      {selected.map((value) => (
                                        <Chip variant="outlined" color='primary' key={value} label={value} 
                                        style={{margin:6}}/>
                                      ))}
                                    </div>
                                  )}>
                                  </Select>,

                                   editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
                                      if (!rowData.Categorias|| rowData.Categorias.length===0){ 
                                        rowData.Categorias=[]
                                        rowData.Categorias[0]=""}
                                      return(
                                        
                                          <FormControl>
                                             <Select
                                                    multiple
                                                    value={rowData.Categorias}
                                                    onChange={e => {
                                                      var newRowData = { ...rowData, Categorias: e.target.value };
                                                      newRowData.Categorias = e.target.value 
                                                       rowData.Categorias = e.target.value 
                                                      if(newRowData.Categorias.length>1){
                                                        newRowData.Categorias=newRowData.Categorias.filter((rol)=>rol!=='')
                                                      }else {  newRowData.Categorias.concat({0:''})}
                                                      
                                                 
                                                      onRowDataChange(newRowData);
                                                    }
                                                  }
                                                    renderValue={(selected) => (
                                                      <div style={{  display: 'flex', flexWrap: 'wrap',}}>
                                                        {selected.map((value) => (
                                                          <Chip variant="outlined" color='primary' key={value} 
                                                          label={value} style={{margin:6}}/>
                                                        ))}
                                                      </div>
                                                    )}
                                                    
                                                  >
                                                {this.state.categorias&&this.state.categorias.forEach(function(actividad)  
                                              {    
                                                    if(actividad.Comunidad&&actividad.Comunidad!==""){
                                                  const melon2 = rowData.Comunidad
                                                  if(actividad.Comunidad[melon2]){
                                                          return( <MenuItem key={actividad.Nombre} value={actividad.Nombre} >
                                                            {actividad.Nombre}

                                                          </MenuItem>)
                                                        }
                                                      }
                                                      
                                                    })}
                                     
                                                      
                                                  </Select>
                                                </FormControl>
                                    )}
                                  },
                           
                        
                                  {title: 'Rol Asignado', field: 'Roles', defaultFilter: this.filterRef['Roles'],
                              render: rowData =><Select
                                  labelId="demo-mutiple-chip-label"
                            //      disabled={true}
                                  multiple
                                  value={rowData.Roles}
                                  renderValue={(selected) => (
                                    <div style={{  display: 'flex', flexWrap: 'wrap',}}>
                                      {selected.map((value) => (
                                        <Chip variant="outlined" color='primary' key={value} label={value} 
                                        style={{margin:6}}/>
                                      ))}
                                    </div>
                                  )}>
                                  </Select>,
                                    editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
                                      if (!rowData.Roles|| rowData.Roles.length===0){ 
                                        rowData.Roles=[]
                                        rowData.Roles[0]=""}
                                      return(
                                        
                                          <FormControl>
                                             <Select
                                                    multiple
                                                    value={rowData.Roles}
                                                    onChange={e => {
                                                      var newRowData = { ...rowData, Roles: e.target.value };
                                                      newRowData.Roles = e.target.value 
                                                      rowData.Roles = e.target.value 
                                                      if(newRowData.Roles.length>1){
                                                        newRowData.Roles=newRowData.Roles.filter((rol)=>rol!=='')
                                                      }else {  newRowData.Roles.concat({0:''})}
                                                      
                                                 
                                                      onRowDataChange(newRowData);
                                                    }
                                                  }
                                                    renderValue={(selected) => (
                                                      <div style={{  display: 'flex', flexWrap: 'wrap',}}>
                                                        {selected.map((value) => (
                                                          <Chip variant="outlined" color='primary' key={value} label={value} style={{margin:6}}/>
                                                        ))}
                                                      </div>
                                                    )}
                                                    
                                                  >
                                                   {this.state.roles.map((ce) => (
                                                      <MenuItem key={ce.Nombre} value={ce.Nombre} >
                                                        {ce.Nombre}
                                                      </MenuItem>
                                                    ))}
                                     
                                                      
                                                  </Select>
                                                </FormControl>
                                    )}
                                  },
                              {title: 'Sub-Rol', field: 'Rol', defaultFilter: this.filterRef['Rol']},
                              {title: 'Dirección', field: 'Direccion', defaultFilter: this.filterRef['Direccion'],
                              editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => ( 
                                <FormControl>
                                  <Select
                                   //   disabled={!rowData.comunidad || rowData.estado=== "CANCELADA" || rowData.estado=== "CERRADA"}
                                      value={rowData.Direccion}
                                      onChange={e => {
                                        var newRowData = { ...rowData, tipo: e.target.value };
                                        rowData.Direccion = e.target.value 
                                        newRowData.Direccion =  rowData.Direccion
                                   
                                        
                                //         var comunidad = comus.filter(comu =>comu.Id=== newRowData.comunidad)
                              
                                      onRowDataChange(newRowData);
                                      }}    >
                                        {
                                      
                                      rowData.direcciones&&rowData.direcciones.length>0&&rowData.direcciones.map((comu) => {
                                                 
                                        return(   <MenuItem value= {comu.Nombre} >
                                                {comu.Nombre}
                                              </MenuItem>
                                      )   })
                                          }
                                                    </Select>
                                  </FormControl>
                                  )   },
                              {title: 'Numero', field: 'Numero', defaultFilter: this.filterRef['Numero']},
                              {title: 'Portal Interno(Op)', field: 'Portal', defaultFilter: this.filterRef['Portal']},
                              {title: 'Escalera (Op)', field: 'Escalera', defaultFilter: this.filterRef['Escalera']},
                              {title: 'Piso', field: 'Piso', defaultFilter: this.filterRef['Piso']},
                              {title: 'Letra/Puerta', field: 'Letra', defaultFilter: this.filterRef['Letra']},
                            //  {title: 'Código Comunidad', field: 'Codigo9'},
                         
                               {title: "Fichar",
                                 field: "Fichar", defaultFilter: this.filterRef['Fichar'],
                                 type: "boolean",

                                 render: rowData =>
                                 <Checkbox                                    
                                 checked={rowData.Fichar}
                                 color= "primary"
                       
                                 />,
                                 editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                                   <Checkbox
                                     {...p}
                                     disabled= {!rowData.Roles|| rowData.Roles.length===0}
                                     checked={rowData.Fichar}
                                     color= "primary"
                                     onChange={e => {
                                       const newRowData = { ...rowData, Fichar: e.target.checked };
                                       rowData.Fichar = e.target.checked 
                                       onRowDataChange(newRowData);
                                     }}
                                     />
                                   )
                              },  

                            {title: "Porcentaje Propiedad %",field: "Porcentaje", defaultFilter: this.filterRef['Porcentaje'],type: "numeric"},
                            {title: 'Convivientes', field: 'lista_convivientes',  hidden:true},
                            
                          ]}
                          data={this.state.usuario_multi}
                      
                          onRowClick={(event, rowData, togglePanel) =>
                                
                                {     this.state.comus&&this.state.comus.forEach((comu) => {
                                            
                                          if( comu.Id===rowData.Comunidad){
                                              direcciones=[]
                                              comu.Direcciones&&comu.Direcciones.map((comun) => (
                                                direcciones = direcciones.concat({Nombre:comun})
                                                ))
                      
                                          }} 
                          )}}
                          editable={{

                            onRowUpdateCancelled: rowData =>  this.setState({editar:false, lista_convivientes:rowData.lista_convivientes}),
                       
                                       
                            onRowAdd: newData =>
                                  new Promise((resolve, reject) => {
                                    var ejecuta= true
                                        setTimeout(() => {
                                            
                                              if(!newData.Comunidad||newData.Comunidad===''){alert("El campo Comunidad no puede ser vacío"); ejecuta= !ejecuta}
                                             else if(!newData.Direccion||newData.Direccion ===''){alert("El campo Dirección no puede ser vacío"); ejecuta= !ejecuta}
                                   
                                             else if(!newData.Numero||newData.Numero ===''){alert("El campo Número no puede ser vacío");ejecuta= !ejecuta }
                                   
                                             else if(!newData.Piso||newData.Piso ===''){alert("El campo Piso no puede ser vacío");ejecuta= !ejecuta }
                                         
                                             else if(!newData.Letra||newData.Letra ===''){alert("El campo Letra/Puerta no puede ser vacío");ejecuta= !ejecuta }
                                             const usus = this.state.usuario_multi.filter(us=>us.Comunidad===newData.Comunidad)
                                         
                                             if(usus&&usus.length>0)
                                              {alert("Ya dispones de este ususario con el perfil de esta comunidad");ejecuta= !ejecuta }
                                             if(ejecuta){
                                 
                                                     
                                                     
                                                      if(!newData.Rol){newData.Rol=""}
                                                      if(!newData.Escalera){newData.Escalera=""}
                                                      if(!newData.Portal){newData.Portal=""}
                                                      //prearamos el  vector de categoriass
                                                
                                                     
                                                      this.props.firebase.users().push({
                                                        Nombre: this.state.rowData.displayName,
                                                        Administrador : false,
                                                        Apellido: this.state.rowData.Apellido,
                                                        DNI : this.state.rowData.DNI,
                                                        Comunidad: newData.Comunidad,
                                                        Roles : (newData.Roles ? newData.Roles:['']),
                                                        Categorias : (newData.Categorias ? newData.Categorias:['']),                                                      
                                                        Rol:newData.Rol,
                                                        Fichar:(newData.Fichar ? newData.Fichar:false),
                                                        Borrado:false,
                                                        Direccion: newData.Direccion,
                                                        Fecha_Modificacion:firebase2.database.ServerValue.TIMESTAMP,
                                                        Email: this.state.rowData.email,
                                                        Clave:'', //dejamos la clave vacía para que solo hay un
                                                        //registro de usuario con  esa clave.
                                                        Telefono: this.state.rowData.Telefono,
                                                      
                                                        Numero: newData.Numero,
                                                        Portal: newData.Portal,
                                                        Escalera:newData.Escalera,
                                                        Piso: newData.Piso,
                                                        Letra: newData.Letra,
                                                        MultiCom:true,
                                                         Porcentaje:(newData.Porcentaje ? newData.Porcentaje:0),
                                                         lista_convivientes:(this.state.lista_convivientes ? this.state.lista_convivientes:[])
                                                       })                                     
                                                         
                                                   .then((data)=>{
                                                     newData.lista_convivientes=(  this.state.lista_convivientes ? this.state.lista_convivientes:[])
                                                    newData.uid=data.key
                                         var usuario_multi=[newData]
                                          usuario_multi=usuario_multi.concat(this.state.usuario_multi)
                                          const usuario_final= {...this.state.usuario_noMulti,...newData, 
                                            ...{'Clave':'', 'MultiCom':true,
                                            'Fichar':(newData.Fichar ? newData.Fichar:false),
                                            'Porcentaje':(newData.Porcentaje ? newData.Porcentaje:0),
                                         //   'Roles' : resul,
                                         //   'Categorias' : resul1,     
                                          }}
                                 //       const comusMulti =this.state.comusMulti.filter(comu=>comu.Id!==newData.Comunidad)
                                          var data1 = this.state.usuarios;                          
                                          data1 =data1.concat(usuario_final);
                                         this.setState({ usuarios: data1 });
                                 
                                          this.setState({snack:true, //comusMulti:comusMulti,
                                          error2:"El registro se ha creado correctamente",
                                                      usuario_multi:usuario_multi});  
                                         resolve(true)
                                                    // this.buscarUsuarios()          

                                                  })  
                                                  
                                                   .catch(function(error) {
                                                      alert(error.message)     
                                                      reject(); return
                                       
                                                    });
                                                           
                                             }else {reject(); return}
                                  
                                        
                                      }, 1)
                                })
                                ,
                            onRowUpdate: (newData, oldData) =>
                          
                             
                            new Promise((resolve, reject) => {
                              var ejecuta= true   
                              setTimeout(() => {
                                
                                    
                                    if (oldData) {
                                      if(!newData.Comunidad||newData.Comunidad===''){alert("El campo Comunidad no puede ser vacío"); ejecuta= !ejecuta}
                                       else if(!newData.Direccion||newData.Direccion ===''){alert("El campo Direccion no puede ser vacío"); ejecuta= !ejecuta}
                                 //      else if(!newData.Portal||newData.Portal ===''){alert("El campo Portal no puede ser vacío"); ejecuta= !ejecuta}
                                    //   else if(newData.Codigo9 ===''){alert("El campo Código Comunidad no puede ser vacío"); ejecuta= !ejecuta}
                                   
                                       else if(!newData.Numero||newData.Numero ===''){alert("El campo Número no puede ser vacío");ejecuta= !ejecuta }
                                   
                                       else if(!newData.Piso||newData.Piso ===''){alert("El campo Piso no puede ser vacío");ejecuta= !ejecuta }
                                   
                                       else if(!newData.Letra||newData.Letra ===''){alert("El campo Letra/Puerta no puede ser vacío");ejecuta= !ejecuta }
                                   
                                  
                                   
                                     
                                       if(ejecuta){
                                         //      var resul={}

                                              //prearamos el  vector de roles
                                        //       let resultado= newData.Roles.map((value,key) => resul[key]=value );      
                                               
                                               
                                          //     if(!resultado || resultado.length===0){resul={0:""}}
                                               var data = this.state.roles;
                                               data[data.indexOf(oldData)] = newData;
                                               
                                               this.setState({roles:data }); 
           
                                               if(!newData.Rol){newData.Rol=""}
                                               if(!newData.Escalera){newData.Escalera=""}
                                               if(!newData.Portal){newData.Portal=""}
                                              
                                                var data2 = this.state.categorias;
                                                data2[data2.indexOf(oldData)] = newData;     
                                               this.setState({categorias:data2 }); 
           
                                               this.props.firebase.user(newData.uid+'/').update({
                                               
                                                Nombre: this.state.rowData.displayName,
                                                Administrador : false,
                                                Apellido: this.state.rowData.Apellido,
                                                DNI : this.state.rowData.DNI,
                                                Comunidad: newData.Comunidad,
                                                Clave: (newData.Clave ? newData.Clave:''),
                                                Roles : newData.Roles,
                                                Categorias : newData.Categorias,                                                      
                                                Rol:newData.Rol,
                                                Fichar:newData.Fichar,
                                                Borrado:false,
                                         
                                                Fecha_Modificacion:firebase2.database.ServerValue.TIMESTAMP,
                                                Email: this.state.rowData.email,
                                   //             Clave:'', //dejamos la clave vacía para que solo hay un
                                                //registro de usuario con  esa clave.

                                                Telefono: this.state.rowData.Telefono,
                                                Direccion:newData.Direccion,
                                                Numero: newData.Numero,
                                                Portal: newData.Portal,
                                                Escalera:newData.Escalera,
                                                Piso: newData.Piso,
                                                Letra: newData.Letra,
                                                MultiCom:true,
                                              
                                                Porcentaje:(newData.Porcentaje ? newData.Porcentaje:0),
                                                lista_convivientes:(this.state.lista_convivientes ? this.state.lista_convivientes:[])
                                                                          
                                                 
                                                }).then(() => { 
                                                  this.setState({snack:true, error2:"El registro se ha actualizado correctamente",open:false  });  
                                                 //   this.buscarUsuarios()
                                                 var data2 = this.state.usuariosClave;
                                                 newData.lista_convivientes=(  this.state.lista_convivientes ? this.state.lista_convivientes:[])
                                                  
                                                  data2[data2.indexOf(oldData)] = newData;
                                                  var data = this.state.usuarios;
                                                  var data3= this.state.usuario_multi
                                                  data3[data3.indexOf(oldData)] = newData;
                                                  
                                                  data[data.indexOf(oldData)] = newData;
                                                  this.setState({ usuarios: data, usuariosClave:data2, usuariosMulti:data3 });
                                                  resolve(true)
                                                  })
                                 
                                             
                                      }else {reject(); return}
                                      }
                                
                                
                                }, 1);
                                }),
                            onRowDelete: oldData =>                            
                              new Promise(resolve => {
                               

                                   this.props.firebase.user(oldData.uid)
                                         .update({ "Borrado":true, 'Clave':''}).then(()=>{
                                          if(oldData.Clave){
                                            const usuario_multi = this.state.usuarios.filter(usu=>usu.DNI===oldData.DNI&&usu.uid!==oldData.uid
                                                &&usu.Borrado===false)
                                            if(usuario_multi&&usuario_multi.length>0){
                                              this.props.firebase.user( usuario_multi[0].uid).update({ "Clave":oldData.Clave})
                                              
                                            }
                                          }
                                          const usuario_multi=this.state.usuario_multi
                                          const multi= usuario_multi.filter(usu=>usu.uid!==oldData.uid)

                                          const usuarios =this.state.usuarios.filter(
                                            usu=>
                                            {
                                              if(usu.DNI!==this.state.rowData.DNI){return true}
                                              if(usu.Comunidad!==oldData.Comunidad){return true}
                                              else{return false}   })
                                          const usuarios2 =this.state.usuariosClave.filter(
                                                usu=>
                                                {
                                                  if(usu.DNI!==this.state.rowData.DNI){return true}
                                                  if(usu.Comunidad!==oldData.Comunidad){return true}
                                                  else{return false}   })

                                          this.setState({snack:true, error2:"Usuario borrado correctamente",
                                          usuario_multi:multi,usuarios:usuarios, usuariosClave:usuarios2  });                                 
                                            resolve(true)
                                         })
                                            
                              })                          
                                            
                          }}
                            />
                  </Paper>
              
                  </p>
              </Modal>
              <Modal
              aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}

              open={this.state.mostrarAyuda}
              onClose={e=>this.setState({mostrarAyuda:false})}


            >
            {this.state.mostrarAyuda&&<ModalTutoriales Pdfs= {this.state.VideoTuto}/> 
          }
           </Modal>
    
       {this.state.abrir_documentos&&    <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}

              open={this.state.abrir_documentos}
            
              onClose={e=>this.setState({abrir_documentos:false})}
 
            >
              <Paper style={{ maxHeight: 750, overflow: 'auto', marginLeft: 20, marginRight: 20, marginTop: 20 }}>
                <h3 style={{color:'#00a049', marginLeft:20}} >Documentación adjunta para: {this.state.rowData.Nombre} {this.state.rowData.Apellido} 
              </h3> 
              <Grid container direction='row'   justifyContent='space-around'  alignItems="center">
           
                  <h4 style={{color:'#00a049'}} >Directorio actual: {this.state.directorioPrincipal}
                  </h4>
                  
                  <Tooltip title="Envía aviso sobre el directorio de ficheros actualizado">
                        <span>
                          <Button       onClick={(event) => {
                              
                              this.setState({texto_titulo:'Comunicado sobre carpeta',directorio_modificar:"",pdfEnviar:""})
                              this.dialogoEnvio(this.state.rowData)}  } 
                          variant="outlined" 
                          disabled = {this.state.directorioPrincipal==="Directorio Raiz"}
                          size="large" color="primary"  startIcon={<SendIcon />}>ENVIAR AVISO
                          </Button>
                        </span>
                      </Tooltip>
                </Grid>
                  <Paper style={{maxHeight: 300, overflow: 'auto'}}>    
                 
                
                <ButtonGroup  size="small"  color="primary" variant="text" aria-label="volver">
                    
                <Button    onClick={(event) => this.setState({abrirDialog:true,directorio:false})}  >
                  
                  <Tooltip title="Añadir carpeta" aria-label="añadir">
                        
                        <AddCircleOutlineIcon/>
                      
                      </Tooltip>
                  </Button>
                  {this.state.directorioPrincipal!=="Inicio"&&<Button 
                  onClick={(event) => {
                    var directorioPrincipal= this.state.directorioPrincipal
                    directorioPrincipal= directorioPrincipal.replace('/'+this.state.carpetaActual,'')
                    buscarPadre(this.state.Ref_padre,this).then((carpetas)=>{

                      this.setState({carpetas:carpetas,directorioPrincipal:directorioPrincipal })
                    })}}>
                 <Tooltip title="Vover al directorio anterior" aria-label="seguir">
                        <ArrowBackIosIcon/> 
                    </Tooltip>
                  </Button>}
                </ButtonGroup> 
             
             <Paper style={{maxHeight: 490, overflow: 'auto'}}>     
        
                <List component="nav" dense="true"  aria-label="main mailbox folders" justify="space-around" alignItems="center" >
                  
                  {this.state.carpetas && this.state.carpetas.map((carpeta,indice) => (                  
                  <Grid>
                    <Divider />
                    <ListItem
                  button
                  selected={this.selectedIndex === carpeta.Nombre}
                  onClick={(event) => { this.buscarficheros(carpeta.path, this.state.rowData)
                    this.setState({directorioPrincipal:this.state.directorioPrincipal+'/'+carpeta.Nombre, carpetaActual:carpeta.Nombre})}}
                  >
                  <ListItemAvatar>
                      <Avatar  style ={{color:'lightgreen', backgroundColor:"#00a049"}}>  
                              <Folder/>
                      </Avatar>
                  </ListItemAvatar>

                  <ListItemText primary={carpeta.Nombre} style ={{maxWidth:'35%'}} />
                  <ListItemSecondaryAction>                  
                 
                    <IconButton edge="end" aria-label="delete"
                      disabled={carpeta.Nombre==='imagen' ||  carpeta.Nombre==='LOPD'||
                      carpeta.Nombre==='Documentación General'|| carpeta.Nombre==='incidencias'}
                      onClick={(event) =>{ 
                        this.setState({open:true, mostrarTabla: false,abrir_documentos:false})
                        
                        handleClickBorrar(carpeta.path,this,this.state.carpetas,true).then((lista)=>{

                          this.setState({carpetas:lista})
                          this.setState({open:false, mostrarTabla: true,abrir_documentos:true})
                      })
                      }} >
                
                      <DeleteIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="borderColor" 
                            onClick={(event) => {
                            this.setState({directorio_modificar:carpeta.Nombre, carpeta:carpeta,
                              modificarNombre:true})
                            }}>
                          <BorderColor />
                          </IconButton>
                   
                
                  </ListItemSecondaryAction>
                  </ListItem>                      
                  </Grid>
                  ))}
                </List>
              </Paper>
                  {this.state.Pdfs&&this.state.Pdfs.length>0&&<List component="nav" dense="true"  aria-label="main mailbox folders" justify="space-around" alignItems="center" >
                    {this.state.Pdfs&&this.state.Pdfs.map(actividad => (                  
                    <Grid>
                    <Divider/>
                    <ListItem
                    button
                    selected={this.selectedIndex === actividad.Nombre}
                    onClick={(event) =>  window.open(actividad.url) }
                    >
                    <ListItemAvatar>
                        <Avatar  style ={{color:'lightgreen', backgroundColor:"#00a049"}}>  

                          <InsertDriveFileIcon/>
                        </Avatar>
                      </ListItemAvatar>

                    <ListItemText primary={actividad.Nombre}/>
                    <ListItemSecondaryAction>
                    <div className={'listaDocumentos'}>
                  <div>
                              <IconButton edge="end" aria-label="delete"
                              tooltip='Para poder borrar el estado de la incidencia debe estar ABIERTA'
                                disabled= {this.state.rowData.estado==='CERRADA'||this.state.rowData.estado==='CANCELADA'}
                                onClick={(event) =>  handleClickBorrarFichero(actividad.Nombre, this, this.state.rowData)} 
                               >
                                
                                <DeleteIcon />
                              </IconButton>
                              <IconButton edge="end" aria-label="borderColor" 
                      
                                onClick={(event) => {
                              this.setState({directorio_modificar:actividad.Nombre, Pdf:actividad,extension:'fichero',
                              modificarNombre:true})
                            }}>
                          <BorderColor />
                          </IconButton>
                          </div>
                     <div style ={{marginLeft:10}}>
                              <span>
                              <Tooltip title="Envía aviso sobre el documento subido">
                                <Button       onClick={(event) => {
                              
                                 this.setState({texto_titulo:'Comunicado sobre documento',directorio_modificar:actividad.Nombre, pdfEnviar:actividad.Nombre})
                                 this.dialogoEnvio(this.state.rowData)}  } 
                                variant="outlined" 
                              //  disabled = {this.state.directorioPrincipal==="Directorio Raiz"}
                                size="small" color="primary"  startIcon={<SendIcon />}>ENVIAR AVISO
                                </Button>
                                </Tooltip>
                              </span>
                          
                      </div> </div>
                          
                            </ListItemSecondaryAction>
                         </ListItem>                      
                       
                    </Grid>
                      
                    ))}
                </List>}
                </Paper>   
                
                {this.state.rowData.estado!=='CERRADA'&&this.state.rowData.estado!=='CANCELADA'&&<DropzoneArea 
                        maxFileSize={10000000}
                        onDrop = {acceptedFiles => this.onDrop(acceptedFiles)}
                         showPreviewsInDropzone= {false} 
                         filesLimit={5}
                         showFileNames ={false}
                         dropzoneText = {"Arrastra el fichero o haz click aquí para cargarlo desde la ventana"}/>
                }
            
              </Paper>


            </Modal>  
        }

 

            <Dialog open={this.state.abrirDialog}  onClose={(event) =>  {this.setState({abrirDialog:false})}}   aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Nueva Carpeta</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Para crear la nueva carpeta introduce un nombre y seguidamente la opción "Aceptar"
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="directorio"
                    name="directorio"
                    label="Nombre del directorio"
                    type="text"

                    onChange={this.handleChange}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                <Button    onClick={(event) =>  {this.setState({abrirDialog:false, nombreDirectorio:""})}} color="primary">
                                      Cancelar
                </Button>
                <Button
                  disabled = {this.state.directorio===''||this.state.ErrorCaracter}
                  onClick={(event) =>  {      this.setState({abrirDialog:false})  
                  crearEstructurDirectorios2(this.state.PathDirectorioActual,this.state.directorio, '', this.props.firebase,true).then(()=>
                 { this.buscarficheros(this.state.PathDirectorioActual,this.state.rowData)}) }}
                  color="primary" >
                                      Aceptar
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={this.state.modificarNombre} 
                  maxWidth={'md'}
                  fullWidth={false}
        
              onClose={e => {this.setState({modificarNombre:false})}}>
                <DialogTitle id="form-dialog-title">Modificar Nombre</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                  Modifica el nombre y presiona "Aceptar"
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="directorio_modificar"
                    name="directorio_modificar"
                    label="Nombre del directorio"
                    type="text"
                    value={this.state.directorio_modificar}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                <Button  onClick={(event) =>{this.setState({modificarNombre:false,extension:''})}} color="primary">
                                      Cancelar
                </Button>
                <Button                                             
                  disabled = {this.state.directorio_modificar===''||this.state.ErrorCaracter
                  ||this.state.directorio_modificar===this.state.carpeta.Nombre
                  ||this.state.directorio_modificar===this.state.Pdf.Nombre}
                  onClick={(event) => {
           
                  
                    this.setState({open:true, mostrarTabla: false,abrir_documentos:false, modificarNombre:false})
                    const nuevoNombre =this.state.directorio_modificar
                    if(this.state.extension===''){
                      const  carpeta=this.state.carpeta
         
                      const pathnuevo = carpeta.path.slice(0,carpeta.path.length-carpeta.Nombre.length)
                      +nuevoNombre
                   
                    modificarNombrefun(carpeta.path,pathnuevo,this).then(()=>{
                      let lista =  this.state.carpetas
                      var nuevaLista=[]
                      lista.forEach(activ =>{
                          if(activ.path === carpeta.path){
                              var direct= activ
                              direct.path=pathnuevo
                              direct.Nombre=nuevoNombre
                              nuevaLista = nuevaLista.concat(direct)
                          }

                          else{
                            nuevaLista = nuevaLista.concat(activ)
                          }

                      })  
                    
                       

                     this.setState({carpetas:nuevaLista,extension:''})  
                     this.setState({modificarNombre:true,abrir_documentos:true, mostrarTabla: true})
                     this.setState({open:false})
                     alert('El cambio de directorio se ha realizado correctamente')
                    })
                    }else{
                      const Pdf=this.state.Pdf
                      const pathnuevo = Pdf.path.slice(0,Pdf.path.length-Pdf.Nombre.length)
                      +nuevoNombre

                      var moverFichero = this.props.firebase.functions.httpsCallable('moverFichero');
                      moverFichero({nombre: Pdf.path, bucket:this.props.firebase.deposito,
                      destino:pathnuevo}).then(()=>{
                        this.buscarficheros(this.state.PathDirectorioActual,this.state.rowData)
                        this.setState({extension:'',abrir_documentos:true,modificarNombre:false,open:false, mostrarTabla: true})  
                 
                        alert('El cambio de fichero se ha realizado correctamente')

                    
                      })          
                      
                    }
                  
                  }}
                   color="primary" >
                                      Aceptar
                </Button>
                </DialogActions>
            </Dialog>
    
            <Modal
              aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}

              open={this.state.mostrarConvivientes}
              onClose={e=>
                {alert('Acuerdate de salvar los cambios en la trasnsacción si has realizado alguno')
                 
                  this.setState({mostrarConvivientes:false,abrir_multicomunidad:true})}}


            >
            {this.state.mostrarConvivientes&&
              <Paper style={{ maxHeight: '65%', overflow: 'auto', marginLeft: '0.5%',width:'96%'}}>              
                    {TablaConvivientes(this)}
              </Paper>}
           </Modal>
              <Backdrop  color = 'primary' open={this.state.open}>
                            <CircularProgress color="primary" />
              </Backdrop>
                            
        </ThemeProvider>
   
  
    <Box mt={40}>
    {!this.state.open&&   <Copyright />}
    </Box>
  
  </Grid>
  );
 
}


}


const TablaConvivientes = (tthis) => 
<Paper style={{width:'100%', overflowX:'auto'  }}>
<MaterialTable 

  options={{
    search:false,
 //   showTitle:false,
   addRowPosition:'first',
   maxBodyHeight: "20%",
 
   exportDelimiter:";",
   pageSize: 4, 
   pageSizeOptions:	[4, 10, 15],
 
   headerStyle: {
   
     backgroundColor :(tthis.state.editar ? '#00a049':'darkseagreen'),
     color: 'white'},
  
           
 }}

 icons={tableIcons}
 localization={{ 
   body: {
     editRow: {
       saveTooltip: "Guardar",
       cancelTooltip: "Cancelar",
       deleteText:  '¿Quieres borrar a conviviente?'
     },
     addTooltip: "Crear un conviviente",
     deleteTooltip: "Borrar",
     editTooltip: "Modificar"
   },                               
   header: {
     actions: 'Acciones'                        

   },
   toolbar: {
     nRowsSelected: '{0} Usuario(s) seleccionados para el envío de AVISO'
 },
 }}
 title={   <h3 style={{ color:(tthis.state.editar ? '#00a049':'darkseagreen')}}>Convivientes</h3>}
   
 columns={[
 {title: 'Conviviente', field: 'conviviente',
 validate: rowData => !rowData.conviviente || rowData.conviviente==='' || incluyeConviviente (tthis, rowData)
  ? { isValid: false, helperText: 'Conviviente no puede ser vacío o estar repetido' } :true},
 {title: 'Documento', field: 'documento'},
 {title: 'Observaciones', field: 'observaciones'},
]}
data={tthis.state.lista_convivientes}

editable={{
onRowAdd:  !tthis.state.editar ? undefined :  newData =>
     new Promise((resolve, reject) => {
        setTimeout(() => {
                  
              const conviviente={
                'conviviente':newData.conviviente.trim(),
                'documento':(newData.documento ? newData.documento:''),
                'observaciones':(newData.observaciones ? newData.observaciones:'')
              }
              var lista_convivientes = tthis.state.lista_convivientes
              lista_convivientes=[conviviente].concat(lista_convivientes)
              tthis.setState({lista_convivientes:lista_convivientes})
              resolve(true)
                                  
         }, 1)
                           
                     
      })

   ,
onRowUpdate: !tthis.state.editar ? undefined :  (newData, oldData) =>


new Promise((resolve, reject) => {
    setTimeout(() => {
      
      const conviviente={
        'conviviente':newData.conviviente.trim(),
        'documento':(newData.documento ? newData.documento:''),
        'observaciones':(newData.observaciones ? newData.observaciones:'')
      }
    
      var lista_convivientes = tthis.state.lista_convivientes
      const indice= lista_convivientes.findIndex(convi=>convi===oldData)
      lista_convivientes[indice]=newData
      tthis.setState({lista_convivientes:lista_convivientes})
      resolve(true)
         
   }, 1);
   }),
onRowDelete: !tthis.state.editar ? undefined :  oldData =>                            
 new Promise(resolve => {
  setTimeout(() => {  
    let lista_convivientes = tthis.state.lista_convivientes
    const nueva= lista_convivientes.filter(convi=>convi!==oldData)
    tthis.setState({lista_convivientes:nueva})
    resolve(true)
       
 }, 1);
               
 })                          
               
}} 
/>
</Paper>

function incluyeConviviente (tthis, rowData){
    const estado=rowData.tableData
  
    let lista_convivientes= tthis.state.lista_convivientes
  if(estado&&estado.editing==='delete'){return false}
  if(estado&&estado.editing==='update'){
    const melon =lista_convivientes.filter((convi,indice) => indice!==estado.id)
    const melon2 =melon.some(convi=>convi.conviviente.trim()===rowData.conviviente.trim())
    return(melon2)
  }else{
    return(lista_convivientes.some(convi=>convi.conviviente.trim()===rowData.conviviente.trim()))
  }
    
   
}

const condition = authUser => !!authUser;
export default withFirebase(withAuthorization(condition)(Usuarios));