import React from 'react';

 
class Cabecera extends React.Component {
 
  render() {
 
    return (
        <div style={{width:'100%',overflowX:'over', textAlign:'center',  justifyContent:'center', alignItems:'center', flexDirection:'row'}}>
         
        
              <img src={require('../../Imagenes/COM365.png')}  
               alt="HTML5" style={{maxWidth:"50%", maxHeight:"50%"}}  />
          
        </div>
    )

  }
 
}
export default Cabecera;
