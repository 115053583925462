import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import SignUpPage from '../SignUp';
import SignInPage from '../SignIn';
import Incidencias from '../Incidencias';

import Inci_gremios  from '../Incidencias/inci_gremios';
import Superuser from '../SuperUser'
import Facturacion from '../SuperUser/facturacion'
import PasswordForgetPage from '../PasswordForget';
import HomePage from '../Home';
import AccountPage from '../Account';
import AdminPage from '../Admin';
import DatosMaestros from '../DatosMaestros';

import portataPruebammm from '../Landing/nueva';


import Actividades from '../DatosMaestros/actividades';
import Comunidades from '../DatosMaestros/comunidades';
import Gremios from '../DatosMaestros/gremios';
import Usuarios from '../DatosMaestros/usuarios';
import Zonas from '../DatosMaestros/zonascomunes';
import Roles from '../DatosMaestros/roles';
import Conecta from '../Conecta/';



import Documentos from '../DatosMaestros/documentos';
import * as ROUTES from '../../constants/routes';
import { withAuthentication } from '../Session';

import Votacion from '../JuntasVirtuales/Votacion' 
import Junta from '../JuntasVirtuales/Sesiones' 
import JuntasVirtuales from '../JuntasVirtuales'
import Privacidad from  '../Privacidad' 
import Politica_cookies from  '../Privacidad/politica_cookies'
import Politica_privacidad from  '../Privacidad/politica_privacidad'
import Politica_privacidad_habi from '../Privacidad/politica_privacidad_habi'
import Aviso_legal from  '../Privacidad/aviso_legal'
import Categorias from '../DatosMaestros/categorias';
import Lopd from '../Lopd';
import  { BreakpointProvider } from 'react-socks';


import Contact from '../CRM/pages/contact/contact.page';
import ContactList from '../CRM/pages/contact-list/contact-list.page';
import ContactsForm from '../CRM/pages/contact-form/contact-form.page';
import Deal from '../CRM/pages/deal/deal.page';
import DealList from '../CRM/pages/deal-list/deal-list.page';
import DealForm from '../CRM/pages/deal-form/deal-form.page';



const App = () => (
  <BreakpointProvider>
      <Router>
          <div>
          <Route exact path={ROUTES.INCI_GREMIOS} component={Inci_gremios} />         
          <Route exact path={ROUTES.LOPD} component={Lopd} />
          <Route exact path={ROUTES.PRIVACIDAD} component={Privacidad} />
          <Route exact path={ROUTES.AVISO_LEGAL} component={Aviso_legal} />
          <Route exact path={ROUTES.POLITICA_COOKIES} component={Politica_cookies} />
          <Route exact path={ROUTES.POLITICA_PRIVACIDAD} component={Politica_privacidad} />
          <Route exact path={ROUTES.POLITICA_PRIVACIDAD_HABI} component={Politica_privacidad_habi} />

          
          <Route exact path={ROUTES.PORTA} component={portataPruebammm} />
          <Route exact path={ROUTES.SUPERUSER} component={Superuser} />
          <Route exact path={ROUTES.FACTURACION} component={Facturacion} />         
      
          <Route exact path={ROUTES.LANDING} component={() => { window.location = 'https://corp.comunidad365.es'; return null;} }/>
          <Route exact path={ROUTES.ZONAS} component={Zonas} />
          <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
          <Route path={ROUTES.SIGN_IN} component={SignInPage} />
          <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
          <Route path={ROUTES.HOME} component={HomePage} />
          <Route path={ROUTES.ACCOUNT} component={AccountPage} />
          <Route path={ROUTES.ADMIN} component={AdminPage} />
          <Route path={ROUTES.MASTER} component={DatosMaestros} />
          <Route path={ROUTES.ACTIVIDADES} component={Actividades} />
          <Route path={ROUTES.COMUNIDADES} component={Comunidades} />
          <Route path={ROUTES.DOCUMENTOS} component={Documentos} />
          <Route path={ROUTES.GREMIOS} component={Gremios} />
          <Route path={ROUTES.INCI} component={Incidencias} />
          <Route path={ROUTES.USUARIOS} component={Usuarios} />
          <Route path={ROUTES.VOTACION} component={Votacion} />
          <Route path={ROUTES.JUNTA} component={Junta} />
          <Route path={ROUTES.ROLES} component={Roles} />
          <Route path={ROUTES.CATEGORIAS} component={Categorias} />
          <Route path={ROUTES.JUNTASVIRTUALES} component={JuntasVirtuales} />
     
        <Route path={ROUTES.CONTACTO} component={Contact} />
        <Route path={ROUTES.LISTA_CONTACTO} component={ContactList} />
        <Route path={ROUTES.FORM_CONTACTO} component={ContactsForm} />
        <Route  path={ROUTES.ACUERDO} component={Deal} />
        <Route  path={ROUTES.LISTA_ACUERDO} component={DealList} />
        <Route  path={ROUTES.FORM_ACUERDO} component={DealForm} />
        <Route  path={ROUTES.CONECTA} component={Conecta} />
        </div>
      </Router>
      
      </BreakpointProvider>
 );
 export default withAuthentication(App);