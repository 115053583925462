import React, { Component,forwardRef } from 'react';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import Mapa2 from '../Horario/Mapa2'
import {DoneOutline,Close,Search,ArrowDownward,Check,ChevronLeft,ChevronRight,Clear,
  DeleteOutline,Edit,FilterList,FirstPage,LastPage,Remove,
  SaveAlt,ViewColumn,AddBox} from '@material-ui/icons';
import {Snackbar,IconButton,Paper,Box,Backdrop,CircularProgress,Fab,Grid,Select,Chip,
  Checkbox,Button,MenuItem,FormControl,Modal} from '@material-ui/core';
import MaterialTable from 'material-table'
import BotonesTriger from '../Botones/botonTrige'
import Cabecera from '../Cabecera/index_365'
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import  green from '@material-ui/core/colors/green';
import  firebase2  from 'firebase'
import ModalTutoriales,{traerTutoriales} from  '../Tutoriales/tutoriales'
import Copyright from '../Privacidad/copyright';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
      claro: green[200]
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#e81446',
    },
  },
});



class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      VideoTuto:[],
      mostrarAyuda:false,
      grupos:[],
      uid:"",
      añadir: true,
      comus: [],
      open: false,
      mostrarTabla: false,
      error: null,
      error2: null,
      modificar: true,
      tenemosLista:false,
      snack:false, snack2:false, vertical: 'top',
      horizontal: 'center', 
      seguro : false,  
      deshabilitar:false,
  
      roles:[],
      despacho:
      {
        "Nombre": "",
        "CIFNIF": "",
        "Codigo": "",
        "Fecha_registro": "",
        "Telefono1":"",
        "Telefono2":"",
        "Correo":"",
        "Direccion":"",
        "Cp":"",
        "Poblacion":"",
        "Persona":"",
        "Logo":"",
        "Iban":"",
        "uid":""
      },
   
  };
  
    this.borrarFiltros = this.borrarFiltros.bind(this);
   
    this.comprobarExisteUsuario = this.comprobarExisteUsuario.bind(this)
    this.handleClose =     this.handleClose.bind(this)
    this.anularGremio = this.anularGremio.bind(this)
  
     this.handleClickOpen = this.handleClickOpen.bind(this)
    this.handleClickCloseOk = this.handleClickCloseOk.bind(this)
    this.buscarDespacho = this.buscarDespacho.bind(this)
    this.handleClickClose = this.handleClickClose.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.traerTablaCorreos=this.traerTablaCorreos.bind(this)
    this.envioEmailVerificacion= this.envioEmailVerificacion.bind(this)
    this.gestionZonas = this.gestionZonas.bind(this)
    this.cerrarGestionZonas= this.cerrarGestionZonas.bind(this)
    this.cerrarGestionZonasOK= this.cerrarGestionZonasOK.bind(this)
    this.traerUsuarioLogueado = this.traerUsuarioLogueado.bind(this)
  }



 
  handleClose () {
  this.setState({snack:false, snack2:false})
  };

  traerTablaCorreos(){
    var correosList =[]
    this.props.firebase.flujoCorreos().once('value', snapshot => {
      const usersObject = snapshot.val();
      if (usersObject){
        correosList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
      
     
        }
      }).then(()=>{  this.setState({flujoCorreos:correosList})

      })
  


  }
  buscarRoles(){
    this.setState({open:true, mostrarTabla:false}, ()=>{
      this.props.firebase.roles2().once('value', snapshot => {
        const usersObject = snapshot.val();
        var rolesList=[]
        if (usersObject){
    
          rolesList = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));
          rolesList.sort(function (a, b) {
              if (a.Nombre> b.Nombre) {
                return 1;
              }
              if (a.Nombre < b.Nombre) {
                return -1;
                
              }
            // a must be equal to b
              return 0;
            })
          } 
         this.setState({roles:rolesList, open:false, mostrarTabla:true})
    
        
        })
  
    })

  

  }
  componentDidMount() {
    traerTutoriales(this, 'Roles').then((data)=>{this.setState({VideoTuto:data})})
   
    this.setState({open:true})
 
    this.props.firebase.GrupoRoles().once('value', snapshot => {
      const usersObject = snapshot.val();
      if (usersObject){
  
     
        usersObject.sort(function (a, b) {
            if (a.Nombre> b.Nombre) {
              return 1;
            }
            if (a.Nombre < b.Nombre) {
              return -1;
              
            }
          // a must be equal to b
            return 0;
          })
        } 
       this.setState({grupos:usersObject})
      })
    this.buscarRoles()
 
}
  buscarDespacho(){

    var despacho= this.state.despacho
    

   this.props.firebase.despacho().once('value', snapshot => {
      const usersObject = snapshot.val();
      despacho.Nombre= usersObject.Nombre
      despacho.CIFNIF=usersObject.CIFNIF
      despacho.Codigo=usersObject.Codigo
      despacho.Fecha_registro=usersObject.Fecha_registro
      despacho.Telefono1=usersObject.Telefono1
      despacho.Telefono2=usersObject.Telefono2
      despacho.Correo=usersObject.Correo
      despacho.Direccion=usersObject.Direccion
      despacho.Cp=usersObject.Cp
      despacho.CentroTrabajo=usersObject.CentroTrabajo
      despacho.Poblacion=usersObject.Poblacion
      despacho.Persona=usersObject.Persona
      despacho.Logo=usersObject.Logo
      despacho.Iban=usersObject.Iban
      despacho.uid=snapshot.key
     }).then (()=> this.setState({despacho:despacho}))
    

  }
  traerUsuarioLogueado(){
    var user =  this.props.firebase.auth.currentUser;
    var usuario=[]
    if(user) {
      var usuariosList = this.state.usuarios
     
      usuario = usuariosList.filter(usu => usu.Clave ===user.uid) 
      if(usuario&&usuario.length>0){
          if (!usuario[0].SuperUser){  usuariosList = usuariosList.filter(usu => !usu.SuperUser) }
           
          this.setState({usuarios:usuariosList})
      }
     
     
    }    
    
    
  }
  
  envioEmailVerificacion = (email) => () =>{
    
        var actionCodeSettings = {
          url: 'https://comunidad365.es/home',
          handleCodeInApp: true,
     //     dynamicLinkDomain: 'c365.page.link'
       //   https://c365.page.link/jTpt
        };
        var generateEmailVerificationLink= this.props.firebase.functions.httpsCallable('generateEmailVerificationLink');
                                        
        new Promise((resolve,reject) => {  
             
          generateEmailVerificationLink({useremail:email,actionCodeSettings:actionCodeSettings}).then(function(result){
              // Read result of the Cloud Function.
            
              resolve(result.data)                                 
    
            }).catch(function(error) {
              // Getting the Error details.
            
              reject(error)
              // ...
            });
          }).then(()=>{

            alert('Se ha mandado el correo de verificación a '+ email)
          })
     
          
      
    
  }
 
  borrarFiltros(){ 
       this.setState({comunidad:'', direccion:'',
       tenemosLista:false, seguro:false });
  }

  comprobarExisteUsuario(dni) {
  var existe=[];
  if(this.state.usuarios){
    existe = this.state.usuarios.filter(usuario => usuario.DNI===dni )
  }
    return existe;       
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  };
  anularGremio() {
  new Promise(resolve => {
      const uidGremio=this.state.uid
      resolve(this.state.comus.forEach((comunidad) => {
        this.props.firebase.comu(comunidad.uid+"/ActivGremios/").once('value', snapshot => {
     //     const usersObject = snapshot.val();
          snapshot.forEach((registro) => {
          
              const melon = registro.val()
              if (melon===uidGremio) {
                this.props.firebase.comu(comunidad.uid+"/ActivGremios/").update({[registro.key] :"-1",  })
                this.props.firebase.comu(comunidad.uid+"/ActivGremioAuxiliar/").update({[registro.key] :"-1",  })
                
              }
          });
          this.props.firebase.gremio(uidGremio).update({ "Borrado":"true"})
    
    
        })    
       })
    
      
    )
 }).then(() => {
    this.setState({snack2:false, snack:true, error2:"El gremio ha sido borrado" });  
    this.buscarUsuarios();

    })
    .catch(error => { 
      this.setState({snack2:false, snack:true, error2:"Ha  habido un problema y no se ha podido borrar" });  
      this.setState({ error });
    });

  }
  checkEnSeguro = (oldData) => () =>{
  var newData= oldData
  var data = this.state.gremios;
  
    if(!oldData.Seguro&&window.confirm('¿Quieres que el Gremio pase a ser del Tipo compañia de Seguros?')){
        newData.Seguro = true;       
        data[data.indexOf(oldData)] = newData;
        this.setState({gremios:data }); 
        new Promise(resolve => {
          setTimeout(() => {
              resolve();
                this.props.firebase.gremio(oldData.uid+'/').update({ 
               
                  Fecha_Modificacion:firebase2.database.ServerValue.TIMESTAMP,
                  Seguro: true      
              })
          }, 1);
          }).then(() => { this.componentDidMount()})

    }
    else if(oldData.Seguro&&window.confirm('¿Quieres que el Gremio deje de ser del Tipo compañia de Seguros?')){
    
      newData.Seguro = false;      
      data[data.indexOf(oldData)] = newData;
      this.setState({gremios:data }); 
      new Promise(resolve => {
        setTimeout(() => {
            resolve();
              this.props.firebase.gremio(oldData.uid+'/').update({ 
             
                Fecha_Modificacion:firebase2.database.ServerValue.TIMESTAMP,
                Seguro: false    
            })
        }, 1);
        }).then(() => { this.componentDidMount()})

      
  }

  
  }
  handleChangeAct = (oldData) => (event) => {
  var newData= oldData
//  this.setState({actividades:event.target.value})
  var data = this.state.roles;
  newData.Roles = event.target.value;
  newData.Roles = newData.Roles.filter(rol => rol!=='');
   
  data[data.indexOf(oldData)] = newData;
  this.setState({roles:data }); 

  //  setPersonName(event.target.value);
  }
  handleClickOpen = (clave) => () => {this.setState({abrirContraseña:true, contraseña:'',clave:clave})       };
  handleClickCloseOk = () => {
    
    var updatePass= this.props.firebase.functions.httpsCallable('updatePass');
                                        
    var P1 =new Promise((resolve,reject) => {  
    
      updatePass({uid:this.state.clave, contraseña:this.state.contraseña}).then(function(result){
          // Read result of the Cloud Function.
        
          resolve(result)                                 

        }).catch(function(error) {
          // Getting the Error details.
     //   var code = error.code;
       //   var message = error.message;
         // var details = error;
          
          reject(error)
        
          // ...
        });
      }).then(() => { 
        alert("La contraseña ha sido actualizada. Nueva contraseña: " + this.state.contraseña)        
        this.setState({abrirContraseña:false, contraseña:'', clave:''})      
      })
      P1.catch((error) => { 
          alert("Ha habido un error y no se ha podid actualiar: " + error)    
          this.setState({abrirContraseña:false, contraseña:'', clave:''})      
        })
  }
  handleClickClose = () => {this.setState({abrirContraseña:false, contraseña:'', clave:''})       }; 


  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });

    
    const eventoNombre=event.target.name
    const eventoValor= event.target.value
    //reiniciamos centros de TRabajo
    var centrosList=[]
    this.props.firebase.puestosdeTrabajo().once('value' , snapshot => {
   
      const usersObject= snapshot.val()
      if (usersObject){
        centrosList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }))
        this.setState({centrosTrabajo:centrosList})
      }else{this.setState({centrosTrabajo:[]})
      }
    }).then(()=>{

      if (eventoNombre==='usuario'){
          const usuario = eventoValor
          this.setState({centrosTrabajoMostrar:centrosList, abrir_horas:false,
            listaHorasTrabajadas:[],Fecha_registro:''})
          if(usuario!==''){ 
            const centrosTrabajoMostrar=this.state.centrosTrabajo.filter(centro =>
              (centro.Usuarios&&centro.Usuarios.length>0 ? centro.Usuarios.includes(usuario.uid):false))
          


            this.setState({centrosTrabajoMostrar:centrosTrabajoMostrar})
            this.setState({Mapa1:[]})
            this.traer_horas_trabajadas (usuario.uid).then(()=>{
              this.setState({nombreyapellidos: usuario.Nombre+" "+usuario.Apellido})
              this.setState({abrir_horas:true})
              const registro={ "despacho":this.state.despacho, "centrosTrabajo":centrosTrabajoMostrar,
              "offset":this.state.offset}

              const Mapa1 =  <Mapa2 {...registro} />
             
              this.setState({Mapa1:Mapa1})
      
            
            })      
          }else{
        
            this.setState({Mapa1:[]})
            const registro={ "despacho":this.state.despacho, "centrosTrabajo":centrosList,
            "offset":this.state.offset}

            const Mapa1 =  <Mapa2 {...registro} />
           
            this.setState({Mapa1:Mapa1})

          }

      }
      else if (eventoNombre==='Fecha_registro'){
              this.setState({Mapa1:[]})
              const date =  eventoValor
              const listaHorasTrabajadas= this.state.listaHorasTrabajadas
              const fecha1 = date.slice(8,10) +"/"+date.slice(5,7)+"/"+date.slice(0,4);
              var centrosTrabajoMostrar=[]
              if(listaHorasTrabajadas&&listaHorasTrabajadas.length>0){

                const centrosTrabajo=centrosList
              
                centrosTrabajo.map(centro=>{
                  const centroMostrar = listaHorasTrabajadas.filter(slot => slot.Centro.uid===centro.uid&&
                        slot.Fecha===fecha1)
                  if(centroMostrar&&centroMostrar.length>0){
                    var centrol = centro
                    centrol.Entradas=centroMostrar
                    var totalHoras=0
                    centroMostrar.map(centro1=>
                        totalHoras=totalHoras+Number(centro1.Horas.replace(',','.')))
                    centrol.totalHoras=totalHoras
                    centrosTrabajoMostrar=centrosTrabajoMostrar.concat(centrol)
                  }
                  return (true)
                })
              }

              if(centrosTrabajoMostrar.length>1){
                
               // this.animateCircle()
                //this.setState({abrir_horas:false})
              }

              this.setState({centrosTrabajoMostrar:centrosTrabajoMostrar})
              const registro={ "despacho":this.state.despacho, "centrosTrabajo":centrosTrabajoMostrar,
              "offset":this.state.offset}

              const Mapa1 =  <Mapa2 {...registro} />
             
              this.setState({Mapa1:Mapa1})
              
              
              
      }
  
    })
  }

  seleccionarZonaEnvio  = (zona)  => () => {
    this.setState({tituloEnvio:zona.label, zonaEnvio:zona,  leftChecked : [], rightChecked:[]})
  }
  cerrarGestionZonas = () => {
   
 
    this.setState({abrir_zonas_envio:false, tituloEnvio:''})

  }

  cerrarGestionZonasOK = () => {
   
    const zonasEnvio = this.state.zonasEnvio
    
    var newData=this.state.rowData
    
 //   if(newData.correoZonas.Todos&&newData.correoZonas.Todos.length>0){ newData.correoZonas.Todos = []}
 //   if(newData.correoZonas.Incidencias&&newData.correoZonas.Incidencias.length>0){   newData.correoZonas.Incidencias =[]}
 //   if(newData.correoZonas.Siniestros&&newData.correoZonas.Siniestros.length>0){ newData.correoZonas.Siniestros =[]}
 //   if(newData.correoZonas.Cambios&&newData.correoZonas.Cambios.length>0){ newData.correoZonas.Cambios =[]}
 //   if(newData.correoZonas.AdminCon&&newData.correoZonas.AdminCon.length>0){ newData.correoZonas.AdminCon =[]}
 //   if(newData.correoZonas.Seguros&&newData.correoZonas.Seguros.length>0){ newData.correoZonas.Seguros =[] }
    
    if(!newData.correoZonas){newData.correoZonas={}}
     newData.correoZonas.Todos = []
     newData.correoZonas.Incidencias =[]
     newData.correoZonas.Siniestros =[]
     newData.correoZonas.Cambios =[]
     newData.correoZonas.AdminCon =[]
     newData.correoZonas.Seguros =[] 
     newData.correoZonas.Roles =[] 
    zonasEnvio[0].left.map((zona)=>newData.correoZonas.Todos =   newData.correoZonas.Todos.concat(zona.Id))
    zonasEnvio[1].left.map((zona)=>newData.correoZonas.Incidencias =   newData.correoZonas.Incidencias.concat(zona.Id))
    zonasEnvio[2].left.map((zona)=>newData.correoZonas.Siniestros =   newData.correoZonas.Siniestros.concat(zona.Id))
    zonasEnvio[3].left.map((zona)=>newData.correoZonas.Cambios=   newData.correoZonas.Cambios.concat(zona.Id))
    zonasEnvio[4].left.map((zona)=>newData.correoZonas.AdminCon=   newData.correoZonas.AdminCon.concat(zona.Id))
    zonasEnvio[5].left.map((zona)=>newData.correoZonas.Seguros=   newData.correoZonas.Seguros.concat(zona.Id))
    zonasEnvio[6].left.map((zona)=>newData.correoZonas.Roles=   newData.correoZonas.Roles.concat(zona.Id))
  
        var data = this.state.usuarios;
      
        var indice=-1
        data.forEach((usuario, ind)=>{
        if(usuario.uid===newData.uid){
          indice = ind
          }
        })
        data[indice]= newData

      
      
      this.setState({usuarios:data}); 
          
        
        this.setState({abrir_zonas_envio:false, tituloEnvio:'', rowData:newData})
  }

  gestionZonas = (rowData)  => () => {  
   
    var zonasEnvio= this.state.zonasEnvio
    var comunidades = this.state.comus.filter(comunidad => comunidad.uid !== -1 );    
    const zoqui =rowData.correoZonas
    if(zoqui){
      zonasEnvio[0].click=!rowData.Todo
      var comus = comunidades
      const Todoss=rowData.correoZonas.Todos
      if(Todoss&&Todoss.length>0){
          comus = comunidades
          var comufinal=[]
          var comun=[]
          Todoss.forEach((comu)=>{
              comun = comus.filter(com=>  com.Id===comu)
              if(comun){comufinal=comufinal.concat(comun)}
          })   
          zonasEnvio[0].left= comufinal
          comus = comunidades
          Todoss.map((comu)=>comus = comus.filter(com=>  com.Id!==comu))
        
          zonasEnvio[0].right=comus
      }else {
        zonasEnvio[0].left=[]
        zonasEnvio[0].right=comunidades
        
      }
      zonasEnvio[1].click=!rowData.Incidencias
      const Incidenciass =rowData.correoZonas.Incidencias
      if(Incidenciass&&Incidenciass.length>0){
          comus = comunidades
           comufinal=[]
           comun=[]
          Incidenciass.forEach((comu)=>{
            comun = comus.filter(com=>  com.Id===comu)
            if(comun){comufinal=comufinal.concat(comun)}
        })
          zonasEnvio[1].left= comufinal
        comus = comunidades
        Incidenciass.map((comu)=>comus = comus.filter(com=>  com.Id!==comu))
        zonasEnvio[1].right=comus
      }else {
        zonasEnvio[1].left=[]
        zonasEnvio[1].right=comunidades
        
      }
      zonasEnvio[2].click=!rowData.Siniestros
      const Siniestross=rowData.correoZonas.Siniestros
      if(Siniestross&&Siniestross.length>0){
           comus = comunidades
           comufinal=[]
           comun=[]
           Siniestross.forEach((comu)=>{
            comun = comus.filter(com=>  com.Id===comu)
            if(comun){comufinal=comufinal.concat(comun)}
         })
         zonasEnvio[2].left= comufinal
         comus = comunidades
         Siniestross.map((comu)=>comus = comus.filter(com=>  com.Id!==comu))
         zonasEnvio[2].right=comus
       }else {
         zonasEnvio[2].left=[]
         zonasEnvio[2].right=comunidades
         
       }
       
      zonasEnvio[3].click=!rowData.Cambios
      const Cambioss =rowData.correoZonas.Cambios
      if(Cambioss&&Cambioss.length>0){
          comus = comunidades
          comufinal=[]
          comun=[]
          Cambioss.forEach((comu)=>{
            comun = comus.filter(com=>  com.Id===comu)
            if(comun){comufinal=comufinal.concat(comun)}
        })
        zonasEnvio[3].left= comufinal
        comus = comunidades
        Cambioss.map((comu)=> comus = comus.filter(com=>  com.Id!==comu))
        zonasEnvio[3].right=comus
      }else {
        zonasEnvio[3].left=[]
        zonasEnvio[3].right=comunidades
        
      }

      zonasEnvio[4].click=!rowData.AdminCon
      const AdminConn=rowData.correoZonas.AdminCon
      if(AdminConn&&AdminConn.length>0){
          comus = comunidades
          comufinal=[]
          comun=[]
          AdminConn.forEach((comu)=>{
            comun = comus.filter(com=>  com.Id===comu)
            if(comun){comufinal=comufinal.concat(comun)}
        })
        zonasEnvio[4].left= comufinal
        comus = comunidades
        AdminConn.map((comu)=>comus = comus.filter(com=>  com.Id!==comu))
        zonasEnvio[4].right=comus
      }else {
        zonasEnvio[4].left=[]
        zonasEnvio[4].right=comunidades
        
      }
 

      zonasEnvio[5].click=!rowData.Seguros


      const Seguross=rowData.correoZonas.Seguros

      if(Seguross&&Seguross.length>0){
          comus = comunidades
          comufinal=[]
          comun=[]
          Seguross.forEach((comu)=>{
            comun = comus.filter(com=>  com.Id===comu)
            if(comun){comufinal=comufinal.concat(comun)}
        })
        zonasEnvio[5].left= comufinal
        comus = comunidades
        Seguross.map((comu)=>comus = comus.filter(com=>com.Id!==comu))
        zonasEnvio[5].right=comus
      }else {
        zonasEnvio[5].left=[]
        zonasEnvio[5].right=comunidades
        
      }
      zonasEnvio[6].click=false
      const Roless =rowData.correoZonas.Roles
      if(Roless&&Roless.length>0){
          comus = comunidades
          comufinal=[]
          comun=[]
          Roless.forEach((comu)=>{
            comun = comus.filter(com=>  com.Id===comu)
            if(comun){comufinal=comufinal.concat(comun)}
        })
        zonasEnvio[6].left= comufinal
        comus = comunidades
        Roless.map((comu)=> comus = comus.filter(com=>  com.Id!==comu))
        zonasEnvio[6].right=comus
      }else {
        zonasEnvio[6].left=[]
        zonasEnvio[6].right=comunidades
        
      }

     
    }else{
      rowData.correoZonas= {
          Todos:[],
          Incidencias:[],
          Siniestros:[],
          Cambios:[],
          AdminCon:[],
          Seguros:[]
      }
      zonasEnvio[0].click=!rowData.Todo
      zonasEnvio[0].left=[]
      zonasEnvio[0].right=comunidades
      
      zonasEnvio[1].click=!rowData.Incidencias
      zonasEnvio[1].left=[]
      zonasEnvio[1].right=comunidades
      
      zonasEnvio[4].click=!rowData.AdminCon
      zonasEnvio[4].left=[]
      zonasEnvio[4].right=comunidades
     
      zonasEnvio[3].click=!rowData.Cambios
      zonasEnvio[3].left=[]
      zonasEnvio[3].right=comunidades
      
      zonasEnvio[2].click=!rowData.Siniestros
      zonasEnvio[2].left=[]
      zonasEnvio[2].right=comunidades
      
      zonasEnvio[5].click=!rowData.Seguros
      zonasEnvio[5].left=[]
      zonasEnvio[5].right=comunidades
      zonasEnvio[6].click=false
      zonasEnvio[6].left=[]
      zonasEnvio[6].right=comunidades
    }

    this.setState({rowData:rowData, cambioZona:true})
    this.setState({abrir_zonas_envio:true,  zonasEnvio:zonasEnvio})
  }
   
  render() {
   const { mostrarTabla } = this.state;
   const vertical= this.state.vertical;
   const horizontal= this.state.horizontal;

   return (     
      <Grid  >
        <Cabecera/>
         <hr/>
         <Grid style={{ position: 'absolute', right: 20, top: 20 , maxWidth:"15%", maxHeight:"15%"}}>
          <Button variant="contained" color="primary" onClick={() => { this.setState({ mostrarAyuda: true }) }}>
            AYUDA (TUTORIALES)
          </Button>
        </Grid>
        <BotonesTriger/>       
        <ThemeProvider theme={theme}>
     
      
            <Snackbar         anchorOrigin={{ vertical , horizontal }}
                          key={`${this.state.vertical},${this.state.horizontal}`}
                          open={this.state.snack}
                          autoHideDuration={5000}
                          onClose={this.handleClose}
                          message={this.state.error2}
                          action={
                            <div>
                              <Button color="primary" size="small" onClick={this.handleClose}>
                                Cerrar
                              </Button>
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={this.handleClose}
                              >
                                <Close/>
                              </IconButton>
                            </div>
                          }         />
           <Snackbar         anchorOrigin={{ vertical , horizontal }}
                          key={`${this.state.vertical},${this.state.horizontal}`}
                          open={this.state.snack2}
                          autoHideDuration={5000}
                          onClose={this.handleClose}
                          message={this.state.gremioBorrar}
                          action={
                          <div>
                                 <Button onClick={this.anularGremio}>  
                                      <Fab  size="small" color="primary" aria-label="add"  variant="extended">
                                          <DoneOutline/>
                                        CONFIRMAR
                                       </Fab>
                                  </Button>
                                  <Button onClick={this.handleClose}>  
                                      <Fab  size="small" color="secondary" aria-label="add"  variant="extended">
                                      <Close/>
                                        CANCELAR
                                       </Fab>
                                  </Button>
                            </div>
                          }                />       
       <Grid   style={{marginTop:-40, marginLeft:20}}   >   
          <Button onClick={() => { this.buscarRoles()} } variant="outlined"  size="large" color="primary"  startIcon={<Search />}>
                    TRAER LISTADO
          </Button>
        </Grid>
                
        <Grid item xs style={{marginLeft:10, marginRight:10}}>
           
             {mostrarTabla&&
              <Paper style={{width:'100%', overflowX:'auto', margin:10}}>        
                               <MaterialTable 
                               options={{
                                doubleHorizontalScroll:true,
                                exportButton: {
                                  csv: true,
                                  pdf: false
                                },
                                addRowPosition:'first',
                                filtering: true,
                                exportDelimiter: ";",
                                exportAllData:true,
                                pageSize: 10,
                                maxBodyHeight: "70vh",
                                pageSizeOptions:	[5, 10, 20],
                                headerStyle: {
                                  backgroundColor: 'green',
                                  color: 'white'},
                                  paddingTop:5,
                                  paddingBottom:5
                                        
                              }}
                              
                              icons={tableIcons}
                              localization={{ body: { editRow:
                                 { deleteText: '¿Quieres borrar a este rol?' } } }}
                              title={ <h2  style={{ color: 'green' }}>ROLES</h2>}

                              columns={[
                              {title: 'Nombre', field: 'Nombre'},
                              {
                                title: 'Grupos', field: 'Grupos',
                                render: rowData => <Select
                                  labelId="demo-mutiple-chip"
                                  disabled
                                  multiple
                                  value={rowData.Grupos}
                                  renderValue={(selected) => (
                                    <div style={{ display: 'flex', flexWrap: 'wrap', }}>
                                      {selected.map((value) => (
                                        <Chip variant="outlined" color='primary' key={value} label={value}
                                          disabled
                                          style={{ margin: 6 }} />
                                      ))}
                                    </div>
                                  )}>
                                </Select>,
        
                                editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
                                  if (!rowData.Grupos || rowData.Grupos.length === 0) {
                                    rowData.Grupos = []
                                    rowData.Grupos[0] = ""
                                  }
                                  return (
        
                                    <FormControl>
                                      <Select
                                        multiple
                                        value={rowData.Grupos}
                                  
        
                                          onChange={e => {
                                            var newRowData = { ...rowData, Grupos: e.target.value };
                                            newRowData.Grupos = e.target.value
                                            if( newRowData.Grupos.length>1){
                                              newRowData.Grupos =   newRowData.Grupos.filter((cate)=>cate!=='')
                                            }
                                            else if(!newRowData.Grupos&&newRowData.Grupos.length===0){
                                              newRowData.Grupos.concat({0:''})
                                            }
                                          
                                            
                                
                                            onRowDataChange(newRowData);
                                          }}    
                                          
                                        
                                        renderValue={(selected) => (
                                          <div style={{ display: 'flex', flexWrap: 'wrap', }}>
                                            {selected.map((value) => (
                                              <Chip variant="outlined" color='primary' key={value}
        
                                                label={value} style={{ margin: 6 }} />
                                            ))}
                                          </div>
                                        )}
        
                                      >
                                        {this.state.grupos && this.state.grupos.map(grupo =>
                                          (<MenuItem key={grupo.Nombre} value={grupo.Nombre} >
                                            {grupo.Nombre}
        
                                          </MenuItem>
                                          ))}
        
        
                                      </Select>
                                    </FormControl>
                                  )
                                }
                              },
               
                              { title: "Acceso a todo", field: "Todo",
                                type: "boolean", initialEditValue:true,

                                  render: rowData =>
                                  <Checkbox                                    
                                  checked={rowData.Todo}
                                  color= "primary"
                        
                                  />,
                                  editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                                    <Checkbox
                                      {...p}
                                 //     disabled= {!rowData.Roles|| rowData.Roles.length==0}
                                      checked={rowData.Todo}
                                      color= "primary"
                                      
                                      onChange={e => {
                                        var  newRowData = { ...rowData, Todo: e.target.checked };
                                        rowData.Todo = e.target.checked
                                        
                                        newRowData.Incidencias= e.target.checked
                                        newRowData.Documentos= e.target.checked
                                        newRowData.Reservas= e.target.checked
                                        newRowData.Juntas= e.target.checked
                                    
                                        
                                        onRowDataChange(newRowData);
                                      }}
                                      />
                                    )
                              },
                              { title: "Incidencias", field: "Incidencias",initialEditValue:true,
            
                              type: "boolean",

                                render: rowData =>
                                <Checkbox                                    
                                checked={rowData.Incidencias}
                                color= "primary"
                      
                                />,
                                editComponent: ({ onChange, onRowDataChange, rowData }) => (
                                  <Checkbox
                                    disabled= {rowData.Todo}
                                    checked={rowData.Incidencias}
                                    color= "primary"
                                    onChange={e => {
                                      const newRowData = { ...rowData, Incidencias: e.target.checked };
                                      onRowDataChange(newRowData);
                                    }}
                                    />
                                  )
                              },
                              { title: "Documentación", field: "Documentos",initialEditValue:true,
                              type: "boolean",

                                render: rowData =>
                                <Checkbox                                    
                                checked={rowData.Documentos}
                                color= "primary"
                      
                                />,
                                editComponent: ({ onChange, onRowDataChange, rowData }) => (
                                  <Checkbox
                                 
                                    disabled= {rowData.Todo}
                                    checked={rowData.Documentos}
                                    color= "primary"
                                    onChange={e => {
                                      const newRowData = { ...rowData, Documentos: e.target.checked };
                                      rowData.Documentos = e.target.checked 
                                      onRowDataChange(newRowData);
                                    }}
                                    />
                                  )
                              },
                              { title: "Zonas de reserva", field: "Reservas",initialEditValue:true,
                              type: "boolean",

                                render: rowData =>
                                <Checkbox                                    
                                checked={rowData.Reservas}
                                color= "primary"
                      
                                />,
                                editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                                  <Checkbox
                                    {...p}
                                    disabled= {rowData.Todo}
                                    checked={rowData.Reservas}
                                    color= "primary"
                                    onChange={e => {
                                      const newRowData = { ...rowData, Reservas: e.target.checked };
                                      rowData.Reservas = e.target.checked 
                                      onRowDataChange(newRowData);
                                    }}
                                    />
                                  )
                              },
                              { title: "Juntas y votaciones", field: "Juntas",initialEditValue:true,
                              type: "boolean",

                                render: rowData =>
                                <Checkbox                                    
                                checked={rowData.Juntas}
                                color= "primary"
                      
                                />,
                                editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                                  <Checkbox
                                    {...p}
                                    disabled= {rowData.Todo}
                                    checked={rowData.Juntas}
                                    color= "primary"
                                    onChange={e => {
                                      const newRowData = { ...rowData, Juntas: e.target.checked };
                                      rowData.Juntas = e.target.checked 
                                      onRowDataChange(newRowData);
                                    }}
                                    />
                                  )
                              },                            
                              {title: 'Observaciones', field: 'Observaciones',
                         
                              },
                            ]}
                              data={this.state.roles}
                              editable={{
                             onRowUpdateCancelled: () =>{
                             this.setState({roles:this.state.roles})                            
                              },
                             onRowAdd: newData =>
                                  new Promise((resolve, reject)  => {
                                    var ejecuta= true
                                        setTimeout(() => {
                                            
                                           if(!newData.Nombre || newData.Nombre ===''){alert("El campo Nombre no puede ser vacío"); ejecuta= !ejecuta}
                                           let melon =this.state.roles.filter(rol=>rol.Nombre===newData.Nombre)

                                           if(melon&&melon.length>0)
                                           {alert("El rol "+newData.Nombre+" ya existe, utiliza otro"); ejecuta= !ejecuta}
                                    
                                       
                                           if(ejecuta){
                                         
                                              if(!newData.Incidencias ||newData.Incidencias ===''){newData.Incidencias =false}
                                              if(!newData.Documentos ||newData.Documentos ===''){newData.Documentos =false}
                                              if(!newData.Reservas ||newData.Reservas ===''){newData.Reservas =false}
                                              if(!newData.Juntas ||newData.Juntas ===''){newData.Juntas =false}
                                              if(!newData.Todo ||newData.Todo ===''){newData.Todo =false}
                                              var roles = this.state.roles
                                              roles.map((rol)=>{
                                                rol.uid=null
                                                rol.tableData=null                                              })

                                              roles =[{
                                                Nombre: newData.Nombre,
                                                Grupos: (newData.Grupos ? newData.Grupos:{0:""}),
                                                Incidencias:(newData.Incidencias ? newData.Incidencias:false),
                                                Documentos:(newData.Documentos ? newData.Documentos:false),
                                                Reservas:(newData.Reservas ? newData.Reservas:false),
                                                Juntas:(newData.Juntas ? newData.Juntas:false),
                                                Todo:(newData.Todo ? newData.Todo:false),
                                                Observaciones:(newData.Observaciones ? newData.Observaciones:'')
                                          
                                               }].concat(roles)
                                          
                                              new Promise((resolve,reject) => {   
                                                this.props.firebase.roles2().set(roles)
                                                   resolve(true)
                                                   roles.map((rol,index)=>{
                                                     rol.uid=index
                                                     rol.tableData={"id": index}
                                                   })
                                                
                                                }).then(()=> {
                                              this.setState({roles:roles})
                                              this.setState({snack:true, 
                                                        error2:"El registro ha sido creado correctamente" });                                       
                                                    resolve(true);    
                                                    this.setState({ open:false });  
                                               
                                                
                                                })
                                                           
                                             }else {reject(); return}
                                  
                                        
                                      }, 1)
                                })
                                ,
                            onRowUpdate: (newData, oldData) =>
                          
                             
                            new Promise((resolve,reject) => {
                              var ejecuta= true   
                              setTimeout(() => {
                                
                                    
                               if (oldData) {
                                  if(!newData.Nombre || newData.Nombre ==='')
                                  {alert("El campo Nombre no puede ser vacío"); ejecuta= !ejecuta}
                                  
                                  let melon =this.state.roles.filter(rol=>rol.Nombre===newData.Nombre)

                                  if(melon&&melon.length>0&&newData.Nombre!==oldData.Nombre)
                                  {alert("El rol "+newData.Nombre+" ya existe, utiliza otro"); ejecuta= !ejecuta}
                           
                                  if(ejecuta){
                                    
                                    this.props.firebase.rol2(newData.uid+'/').update({                                               
                                      Nombre: newData.Nombre,
                                      Grupos: (newData.Grupos ? newData.Grupos:{0:""}),
                                      Incidencias:(newData.Incidencias ? newData.Incidencias:false),
                                      Documentos:(newData.Documentos ? newData.Documentos:false),
                                      Reservas:(newData.Reservas ? newData.Reservas:false),
                                      Juntas:(newData.Juntas ? newData.Juntas:false),
                                      Todo:(newData.Todo ? newData.Todo:false),
                                      Observaciones:(newData.Observaciones ? newData.Observaciones:''),
                                     }).then(() => {                     

                                      if(newData.Nombre!==oldData.Nombre){

                                         //Atualizar los roles asinados a un usuario si ha cambiado el nombre quees la clave

                                         var usuariosList = this.state.usuarios
                                         
this.props.firebase.users().once('value', snapshot => {
  const usersObject = snapshot.val();
  let borrar = true
  if (usersObject){
      var usuariosList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));
  
      usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false ) 
      usuariosList.map(usuario =>{
        let roles =usuario.Roles
        if(roles&&roles.includes(oldData.Nombre)){
           const indi=roles.findIndex(fruit => fruit === oldData.Nombre);
           roles[indi]= newData.Nombre
           this.props.firebase.user(usuario.uid+'/Roles').update(roles)   
        }

   })
      
      
      
}})
     
                                        
                                      }
                                          this.setState({snack:true, open:false,
                                              error2:"El registro de "+newData.Nombre+" ha sido actualizado correctamente" });  
                                          var data = this.state.roles;
                                          const index = data.findIndex(rol => rol.uid === newData.uid);
                                        
                                          data[index] = newData;
                                          this.setState({roles:data}, ()=>{
                                            resolve(true)
                                          });                         
                                           
                                       }).catch(function(error) {
                                                resolve(false);
                                                return
                                  
                                              });
                                                      
                                      }else {reject(); return}
                                      }
                                
                                
                                }, 1);
                                }),
                            onRowDelete: oldData =>                            
                              new Promise(resolve => {
                              
// comporbamos si algún usuario tiene ese rol en uso.

this.props.firebase.users().once('value', snapshot => {
  const usersObject = snapshot.val();
  let borrar = true
  if (usersObject){
      var usuariosList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));
  
      usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false ) 
      usuariosList.map((usuario)=> {
        let rol = usuario.Roles&&usuario.Roles.filter(rol =>rol === oldData.Nombre)
        if(rol&&rol.length>0){
          alert("No se ha podido borrar el registro, este rol continúa asignado a algún usuario. Usuario con este rol: "+usuario.Nombre+' ' +usuario.Apellido)
         
          borrar=false
          resolve (false)
          return false
          
        }
        return true
      })
    
  
   }

   if(borrar){

    var roles =this.state.roles.filter(rol => rol.uid !== oldData.uid)

    roles.map((rol)=>{
      rol.uid=null
      rol.tableData=null
    })


  this.props.firebase.roles2().set(roles).then(()=>{
     
    roles.map((rol,index)=>{
      rol.uid=index
      rol.tableData={"id": index}
    })

    this.setState({snack2:false, snack:true, open:false, roles:roles,
        error2:"El Role  "+oldData.Nombre+" ha sido borrado"});  
        resolve(true)                  
                                          
                          //  alert("Usuario borrado correctamente")
          }).catch(function(error) {alert("No se ha podido borrar el registro")
          resolve(false)  })
   }
 
 })})}}
                     />
                  </Paper>
              }
     
         </Grid>


         <Modal
              aria-labelledby="transition-modal-title"
               aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}

              open={this.state.mostrarAyuda}
              onClose={e=>this.setState({mostrarAyuda:false})}


            >
            {this.state.mostrarAyuda&&<ModalTutoriales Pdfs= {this.state.VideoTuto}/> 
          }
           </Modal>
       <Backdrop  color = 'primary' open={this.state.open}>
                  <CircularProgress color="primary" />
       </Backdrop>   
     
      </ThemeProvider>
    <Box mt={40}>
          <Copyright />
    </Box>
    
  
  </Grid>
  );
  }

}

const condition = authUser => !!authUser;
export default withFirebase(withAuthorization(condition)(Roles));