import React  from 'react';
import CookieConsent, { Cookies } from "react-cookie-consent";

function Miscookies() {

return (<CookieConsent
            location="top"
           // debug={true}
            buttonText="ACEPTAR"
            declineButtonText="CONFIGURAR/RECHAZAR"
            cookieName="CookieConsent"
            enableDeclineButton
            hideOnAccept={true}
            acceptOnScroll
            onAccept={(e) =>{
             //   const hola = Cookies.set('CookieConsent')
            
                }}
            acceptOnScrollPercentage={50}
            onDecline={(e) => { //Cookies.remove('_ga')
          
             Cookies.remove('CookieConsent')
            }}
           
         //   flipButtons
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
           // ButtonComponent
            
            >
                

Utilizamos cookies propias y de terceros para prestar nuestros servicios, recoger información estadística y mostrarle publicidad relacionada con sus preferencias mediante el análisis de sus hábitos de navegación. Puede obtener más información sobre las cookies, o bien conocer cómo cambiar la configuración en nuestra Política de Cookies.
        </CookieConsent> 
)}


export default Miscookies;