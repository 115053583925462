import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import * as serviceWorker from './serviceWorker';

import App from './components/App';
import Firebase, { FirebaseContext } from './components/Firebase';

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-TKVLNZH',
    dataLayerName: 'PageDataLayer'
}


class Appo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      
      firebase:false,
      nolohagas:false
    }
  }
  componentDidMount(){
    TagManager.initialize(tagManagerArgs)
    
    //Nos hace falta para llamar al usuario en Firebase y traer su perfil
    const firebase = new Firebase('https://dogarfirbase.firebaseio.com','')
    this.setState({firebase:firebase})
 
const props=this.props
    
    firebase.auth.onAuthStateChanged(authUser => {
      if (authUser){
        const user=   firebase.auth.currentUser
        user.getIdToken(true)

      const callable2 = firebase.functions.httpsCallable('getUser')
       callable2({uid:authUser.uid})        
       .then((result) => {  
        const res=result.data
        if(!res.customClaims){
        this.cambiarBasedatos(firebase,authUser)
        }else{
          const girebase = firebase
          var base={
            'Almacen':res.customClaims.almacen,
            'BaseDatos':res.customClaims.basedatos,
            'Code':res.customClaims.code,
            'Nombre':res.customClaims.despacho,
            'Codigo':res.customClaims.despacho,
            'Lopd':false
          }
            
         girebase.appp.delete().then(()=>{
          const firebase = new Firebase(base.BaseDatos,base.Almacen,base.Code,base)
          this.setState({firebase:firebase})
          this.setState({nolohagas: true})
          authUser.getIdToken(true)

          

         })
        }
      })
    }      
    else{this.setState({nolohagas:true})}
   
    
    },
    
     );
 }

 cambiarBasedatos (firebase,authUser){

    const girebase = firebase
    var despacho=[]
    
    
    new Promise(resolve => {  
       firebase.userss(authUser.uid).once('value', function(snapshot) {
              resolve(snapshot.val())
    })
    
    
  })
    
    .then((ususariourl)=>{

      firebase.despachos().once('value', function(snapshot) {
    
      const despachos= snapshot.val()
       despacho = despachos.filter(des=>des.Codigo===ususariourl)
  
      }).then((data)=>{
        var url='https://dogarfirbase-'+ususariourl+'.firebaseio.com'
        if(despacho&&despacho.length>0&&despacho[0].BaseDatos){
          url=despacho[0].BaseDatos
         }

         if(despacho&&despacho.length>0){   const almacen= (despacho[0].Almacen ? despacho[0].Almacen: 'dogarfirbase-'+ususariourl)
      
        girebase.appp.delete().then(()=>{
          const firebase = new Firebase(url,almacen, (despacho[0].Code ? despacho[0].Code:null),despacho[0])
        
          this.setState({firebase:firebase})
          this.setState({nolohagas: true})
  
        })
      }else{ this.setState({nolohagas: true})}        
      })


    })
  }
 


  render(){
    const {nolohagas}=this.state
    return (
      (nolohagas&&
      <FirebaseContext.Provider value={this.state.firebase}>
        <App />
      </FirebaseContext.Provider>
    ));
  }
}

ReactDOM.render(React.createElement(Appo, null), document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();