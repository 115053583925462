import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import Snackbar from '@material-ui/core/Snackbar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Box from '@material-ui/core/Box';

import SaveIcon from '@material-ui/icons/Save';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
//import ModidyIcon from '@material-ui/icons/Modify';
import VisibilityIcon from '@material-ui/icons/Visibility';

import Fab from '@material-ui/core/Fab';

import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import BotonesTriger from '../Botones/botonTrige'
import Cabecera from '../Cabecera/index_365'
import MenuItem from '@material-ui/core/MenuItem';

import ListItemText from '@material-ui/core/ListItemText';

import Checkbox from '@material-ui/core/Checkbox';

import Button from '@material-ui/core/Button';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import  green from '@material-ui/core/colors/green';


import  firebase2  from 'firebase'
import Copyright from '../Privacidad/copyright';

const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
      claro: green[200]
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#e81446',
    },
  },
});


class Actividades extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid:"",
      tipo_actividad:'',
      añadir: true,
      incis: [],
      identIncial:'',
      comus: [],
      open: false,
      comunidad:'', 
      tipo:'guardar',
      estandar:'',
      label_estandar:false,
      actividad:'',
      mostrarTabla: false,
      selectedIndex:'',
      email: '',
      actividadBorrar:"",
      password: '',
      actividades_tipo:[],
      error: null,
      error2: null,
      modificar: true,
      listaComunidades: [],
      ComunidadesAsig:[],
      ComunidadesNoAsig:[],
      tenemosLista:false,
      leftChecked:[],
      rightChecked:[],
      left:[1,2,3,4],
      leftOriginal:[],
      right:[5,6,711], 
      checked:[],
      snack:false, snack2:false, vertical: 'top',
      horizontal: 'center', 

  };
    //this.handleChange = this.handleChange.bind(this);
    this.BuscarActividades= this.BuscarActividades.bind(this);
    this.handleCheckedRight=this.handleCheckedRight.bind(this);
    this.preparaComunidad= this.preparaComunidad.bind(this);
    this.borrarFiltros = this.borrarFiltros.bind(this);
    this.añadir = this.añadir.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.buscarComunidades = this.buscarComunidades.bind(this);
    this.comprobarExisteActividad = this.comprobarExisteActividad.bind(this)
    this.traerActividades=this.traerActividades.bind(this)
    this.traerActividades2=this.traerActividades2.bind(this)
    this.handleClose =     this.handleClose.bind(this)
    this.anularActividad = this.anularActividad.bind(this)

  }

handleClose () {
  this.setState({snack:false, snack2:false})
};

componentDidMount() {
  this.buscarComunidades().then(()=>{
  this.traerActividades2()
  this.props.firebase.actividad_tipo().once('value', snapshot => {
    const usersObject = snapshot.val();
    if(usersObject){
      
      const tipos = Object.entries(usersObject).map(([key, val]) => ({
        "Nombre":val,
     
      }));
      tipos.sort(function (a, b) {
        if (a.Nombre> b.Nombre) {
          return 1;
        }
        if (a.Nombre < b.Nombre) {
          return -1;
        }
       // a must be equal to b
        return 0;
      });
      this.setState({actividades_tipo:tipos})
    }
   
  
  });


  }) 

}
  
buscarComunidades(){
 return  new Promise(resolve => {
    this.props.firebase.comus().on('value', snapshot => {
    const usersObject = snapshot.val();
    if(usersObject){
      
    var comusList = Object.keys(usersObject).map(key => ({
      ...usersObject[key],
      uid: key,
    }));
    comusList= comusList.filter(comunidad => comunidad.uid !== -1 );
    this.setState({comus: comusList});

    resolve(true)
  
  
    }else{ this.setState({comus: []});
    resolve(true)}
  });
   
  })
}
componentWillUnmount() {

  this.props.firebase.comus().off()
}
traerActividades(){
    this.props.firebase.activs().once('value', snapshot => {
      const usersObject = snapshot.val();
      const actividadList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));
      actividadList.sort(function (a, b) {
        if (a.Nombre> b.Nombre) {
          return 1;
        }
        if (a.Nombre < b.Nombre) {
          return -1;
        }
       // a must be equal to b
        return 0;
      });

      
  //    this.setState({activs: actividadList });
      this.recuperaComunidad(actividadList)
    }).then( () => { 
       this.setState({ mostrarTabla: true, open:false });  })

  }

recuperaComunidad(actividadList){
  const comus = this.state.comus
  var listaComus = []
  var listaComus2 = []
  actividadList.forEach((actividad)=>{
    if(!actividad.Comunidad||actividad.Comunidad.lenght===0 ){
        comus.forEach((comunidad)=>{
        if(comunidad.ActivGremios){
          if(comunidad.ActivGremios[actividad.uid]){
            const Id= comunidad.Id
            listaComus[Id]=Id
            listaComus2=listaComus2.concat(Id)

        }
        
        }
          
        })
    
    if(listaComus&&listaComus.length>0){
      actividad.Comunidad=listaComus
      this.props.firebase.activ(actividad.uid).update({"Comunidad":listaComus2})
      listaComus = []
      listaComus2=[]
    }
    }else{
      var comuni=[]
      actividad.Comunidad.map((activ)=>comuni[activ]=activ)
      actividad.Comunidad=comuni
    }
  })
  this.setState({activs:actividadList})

}
traerActividades2(){
    this.props.firebase.activs().once('value', snapshot => {
      const usersObject = snapshot.val();
      const actividadList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));
      actividadList.sort(function (a, b) {
        if (a.Nombre> b.Nombre) {
          return 1;
        }
        if (a.Nombre < b.Nombre) {
          return -1;
        }
       // a must be equal to b
        return 0;
      });
      this.recuperaComunidad(actividadList)
    })

  }
BuscarActividades(){
    this.buscarComunidades();
    this.setState({open:true, mostrarTabla: false, añadir: true, tenemosLista:false })  
    this.borrarFiltros();
    this.traerActividades();
  } 
borrarFiltros(){ 
        this.setState({ComunidadesNoAsig:[] , ComunidadesAsig:[], activs:[], nombre:'', descripcion:'', 
        tenemosLista:false });
  }

filtrarComunidad(actividad){;

    const comunidades2 =this.state.comus.filter(comunidad => comunidad.uid !== -1 );
    var comus3=[]
    var comunidades = this.state.comus.filter(comunidad =>{ 
            const activ = comunidad.ActivGremios
            const uid = actividad.uid
            if(activ&&activ[uid]){ return true

            }else{ comus3=comus3.concat(comunidad)
                return false}
          });

    if(comunidades&&comunidades.length>0){ 
      this.setState({left: comunidades,leftOriginal:comunidades, right: comus3, tenemosLista:true, leftChecked : [],
        rightChecked:[], checked:[]})
     }
    else{  this.setState({left:[], right: comunidades2, tenemosLista:true, leftChecked : [], leftOriginal:[],
      rightChecked:[], checked:[]})  }
 
 
 
  }
handleListItemClick (event, actividad, activ){   
    this.filtrarComunidad(actividad)
    this.setState({tipo_actividad:""})
    this.setState({actividad: actividad, identIncial:actividad.Nombre,tipo_actividad:actividad.Tipo,
      nombre:actividad.Nombre, descripcion:actividad.Descripcion, estandar:actividad.Estandar})
    if (activ === "visualizar"){ 
      
       this.setState({ modificar:true }) 
    }
    else if (activ === "modificar"){
      this.setState({ modificar:false ,tipo:"guardar" }) 
    }
    else if (activ === "delete"){
      this.setState({uid:actividad.uid, modificar:true,snack2:true,
      actividadBorrar:"La actividad "+ actividad.Nombre+ " será borrada"})
     // alert('¿Deseas borrar la actividad?: ' + actividad.Nombre )

    }

    if(actividad.Estandar){ 
      this.setState({label_estandar:'Se asociará de forma automática a la Comunidad al crearla'})
     }
    else{
     this.setState({label_estandar:'No se asociará de forma automática cuando se cree una Comunidad'})
      }



 }; 
preparaComunidad(){
  var resul=[]
  this.state.left.map((value) => resul=resul.concat(value.Id) ); 
  return resul;
}
 onSubmit = event => {
//  const resul = this.preparaComunidad(); 
  const comprobar =this.comprobarExisteActividad()
  
      if(this.state.tipo==="guardar"){
        if(this.state.identIncial===this.state.nombre || 
          (this.state.identIncial !==this.state.nombre&&comprobar===0) ){
             this.props.firebase.activ(this.state.actividad.uid).update({
              Nombre: this.state.nombre,
              Descripcion: this.state.descripcion,
          //    Comunidad : resul,
              Tipo: this.state.tipo_actividad,
              Estandar: this.state.estandar,
              Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
            }).then(() => {
                //Eliminamos la relacion de la actividad para las comunidad de la columna izquierda
                this.anularyasociarComunidadDeActividad(this.state.actividad.uid)
                this.setState({snack:true, 
                  error2:"La actividad " +this.state.nombre+" has sido actualizada correctamente" })
              })
              .catch(error => {
                this.setState({ error });
              });
            
        }else{ this.setState({snack:true, 
            error2:"La actividad " +this.state.nombre+" ya existe, por favor verificar el nombre" });}
      }
     else {
      
          if (comprobar.length === 0){
                this.props.firebase.activs().push({
                Nombre: this.state.nombre,
                Descripcion: this.state.descripcion,
                Tipo: this.state.tipo_actividad,
             //   Comunidad : resul,
                Estandar: this.state.estandar,
                Id:firebase2.database.ServerValue.TIMESTAMP,
                Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
              }).then((data) => {
                this.anularyasociarComunidadDeActividad(data.key)
                this.setState({snack:true, 
                  error2:"La actividad " +this.state.nombre+" se ha creado correctamente" })
              
                })
                .catch(error => { this.setState({ error });
                });

          }else{ this.setState({snack:true, 
            error2:"La actividad " +this.state.nombre+" ya existe, por favor verificar el nombre" });}
         }

    event.preventDefault();
};
comprobarExisteActividad() {
  var existe=[];
  const actividades =this.state.activs;
  existe = actividades.filter(actividad => actividad.Nombre===this.state.nombre )
  return existe;       
}
onChange = event => {
  this.setState({ [event.target.name]: event.target.value });
};

anularyasociarComunidadDeActividad(uid){
  //la primera promesa para cancelar las comunidades de la columna de la izquierda
  new Promise(resolve => {
    
    const listaderecha = this.state.right
    if(listaderecha&&listaderecha.length>0){
        this.state.right.forEach((comunidad) => {
      //      let Com = this.state.comus.filter(comuni => comuni.Id === comunidad)
            this.props.firebase.comu(comunidad.uid+'/ActivGremioAuxiliar/').update({
              [uid]:"-1" ,})
        //     this.componentDidMount();
            this.props.firebase.comu(comunidad.uid+'/ActivGremios/'+uid).remove()
            .then(() => {
              
            })
            .then(() => {resolve()})
        })
      }else{ resolve()}

    }).then (()=>{ 
      new Promise(resolve => {
        setTimeout(() => {
          //MIRAMOS CUALES SON LAS NUEVAS RESPECTO A LA LISTA INICIAL DE COMUNIDADES ASOCIADAS
            var  crearnueva=this.state.left.filter(comunidad => (!this.state.leftOriginal.includes(comunidad))) 
            
            if (crearnueva){
                crearnueva.forEach((comunidad) => {
                    this.props.firebase.comu(comunidad.uid+'/ActivGremioAuxiliar/').update({
                      [uid]:"-1" ,})
                        
                      this.props.firebase.comu(comunidad.uid+'/ActivGremios/').update({
                        [uid]:"-1" ,})
                    .then(() => {
                    
                    })
                  })
            }
          //Actualicemos o no porque no haya qeu actualizar. si que la lista original de la ziqueirda cambia
          this.setState({leftOriginal:this.state.left})
          resolve()
        }, 1);
      })
      .then (()=>{ 
      this.BuscarActividades()   
    })
    })
}
onChange2 = event => {
  this.setState({ [event.target.name]: event.target.checked });
    if(event.target.checked){ 
                 this.setState({label_estandar:'Se asociará de forma automática a la Comunidad al crearla'})
                }
         else{
                this.setState({label_estandar:'No se asociará de forma automática cuando se cree una Comunidad'})
        }
      }
anularActividad() {
  
 this.state.left.forEach((comunidad) => { 
    this.props.firebase.comu(comunidad.uid+'/ActivGremioAuxiliar/').update({
      [this.state.actividad.uid]:"-1" ,})
//     this.componentDidMount();
  this.props.firebase.comu(comunidad.uid+'/ActivGremios/'+this.state.actividad.uid).remove()
 .then(() => {
    this.props.firebase.activ(this.state.actividad.uid+"/Comunidad/"+comunidad.Id)
    .remove()    
    })
  })
  this.props.firebase.activ(this.state.uid).remove()
 .then(() => {

    this.setState({snack2:false, snack:true, error2:"La actividad ha sido borrada" });  
    this.BuscarActividades();

    })
    .catch(error => { 
      this.setState({snack2:false, snack:true, error2:"Ha  habido un problema y no se ha podido borrar" });  
      this.setState({ error });
    });

}

añadir(){
    
    this.setState({añadir: false, mostrarTabla:false
    , modificar: false, tenemosLista: true, nombre:'', descripcion:'',tipo_actividad:'', tipo:"añadir",estandar:false,
    label_estandar:'No se asociará de forma automática cuando se cree una Comunidad'})
    this.setState({left:[], right: this.state.comus, tenemosLista:true, leftChecked : [], leftOriginal:[],
      rightChecked:[], checked:[]})
}
cancelar(){
  this.setState({añadir: true, mostrarTabla:true, tenemosLista: false
  , modificar: true})
  this.borrarFiltros()
}

 render() {

    const { mostrarTabla, label_estandar } = this.state;
     const activs = this.state.activs;
    const { nombre, descripcion, error } = this.state;
    var tipo_actividad= this.state.tipo_actividad
    if(!tipo_actividad){tipo_actividad = ''}
    const isInvalid = nombre === '' || descripcion === '' ||  tipo_actividad === '';
   const vertical= this.state.vertical;
   const horizontal= this.state.horizontal;
  
   return (     
      <Grid >
        <Cabecera/>
         <hr/>
        <BotonesTriger/>       
        <Grid container spacing={0}>
        <ThemeProvider theme={theme}>
         <Snackbar         anchorOrigin={{ vertical , horizontal }}
                          key={`${this.state.vertical},${this.state.horizontal}`}
                          open={this.state.snack}
                          autoHideDuration={5000}
                          onClose={this.handleClose}
                          message={this.state.error2}
                          action={
                            <div>
                              <Button color="primary" size="small" onClick={this.handleClose}>
                                Cerrar
                              </Button>
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={this.handleClose}
                              >
                                <CloseIcon/>
                              </IconButton>
                            </div>
                          }
         />
           <Snackbar         anchorOrigin={{ vertical , horizontal }}
                          key={`${this.state.vertical},${this.state.horizontal}`}
                          open={this.state.snack2}
                          autoHideDuration={5000}
                          onClose={this.handleClose}
                          message={this.state.actividadBorrar}
                          action={
                          <div>
                                 <Button onClick={this.anularActividad}>  
                                      <Fab  size="small" color="primary" aria-label="add"  variant="extended">
                                          <DoneOutlineIcon/>
                                        CONFIRMAR
                                       </Fab>
                                  </Button>
                                  <Button onClick={this.handleClose}>  
                                      <Fab  size="small" color="secondary" aria-label="add"  variant="extended">
                                      <CloseIcon/>
                                        CANCELAR
                                       </Fab>
                                  </Button>
                            </div>
                          }
         />           
        
              <Grid item xs style={{marginLeft:20}}>
                  <Button onClick={this.BuscarActividades} variant="outlined"  
                  size="large" color="primary"  startIcon={<SearchIcon />}>
                                      LISTADO ACTUAL DE ACTIVIDADES
                  </Button>
                  <Backdrop  color = 'green' open={this.state.open}>
                            <CircularProgress color="green" />
                  </Backdrop>
                 {(this.state.añadir ?
                                 <Button onClick={this.añadir}>  
                                      <Fab  size="small" color="primary" aria-label="add"  variant="extended">
                                          <AddIcon />
                                        Añadir
                                       </Fab>
                                  </Button>
                      :
                                <Button onClick={this.cancelar}>  
                                    <Fab  size="small" color="secondary" aria-label="cancel"   variant="extended">
                                    <CloseIcon/>
                                      Cancelar
                                    </Fab>
                                </Button>
                   )}
                
                  {(mostrarTabla&& <Paper style={{maxHeight: 490, overflow: 'auto', width:600}}>
              
                    <List component="nav" dense="true"  aria-label="main mailbox folders" justify="space-around" alignItems="center" >
                      {activs && activs.map(actividad => (                  
                      <Grid>
                        <Divider />
                      <ListItem
                      button
                      selected={this.selectedIndex === actividad.Nombre}
                      onClick={(event) => this.handleListItemClick(event, actividad, "visualizar" )}
                      >
                      <ListItemText primary={actividad.Nombre} secondary={actividad.Id} />
                      <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete"
                          onClick={(event) => this.handleListItemClick(event, actividad, "delete" )}>
                          <DeleteIcon />
                          </IconButton>               
                          <IconButton edge="end" aria-label="visibility"
                          onClick={(event) => this.handleListItemClick(event, actividad, "visualizar" )}>
                          <VisibilityIcon />
                          </IconButton>
                          <IconButton edge="end" aria-label="borderColor" 
                          onClick={(event) => this.handleListItemClick(event, actividad, "modificar" )}>
                          <BorderColorIcon />
                          </IconButton>
                      </ListItemSecondaryAction>
                      </ListItem>                      
                      </Grid>
                      ))}
                  </List>
              </Paper>)}
              </Grid>
              {(this.state.tenemosLista&&<Grid item xs style={{marginRight:20, marginTop:10}}>
              <Paper style={{ width:700}}>
                 <form >
                      <Button onClick={this.onSubmit}    fullWidth 
                             size="large" color="primary"  startIcon={<SaveIcon />   }   variant="contained"
                             disabled ={this.state.modificar ||isInvalid}   >                                  
                             GUARDAR
                       
                        </Button>
                        {error && <p>{error.message}</p>} 
                      
                        <TextField  InputLabelProps={{ shrink: true }}
                          disabled ={this.state.añadir}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="nombre"
                          
                          label="Nombre Actividad"
                          name="nombre"
                          autoComplete="nombre"
                          autoFocus
                          value={this.state.nombre}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Nombre Actividad"
                        />
                        <TextField  InputLabelProps={{ shrink: true }}
                        disabled ={this.state.modificar}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          name="descripcion"
                          label="Descripción"
                          id="descripcion"
                          autoComplete="descripcion"
                         
                          value={descripcion}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Descripción Actividad"
                        />
                        <TextField  InputLabelProps={{ shrink: true }} 
                        value={tipo_actividad} select
                        disabled ={this.state.modificar}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="tipo_actividad"
                        label="Tipo de Actividad"
                        id="tipo_actividad"
                      
                        onChange={this.onChange}
                        type="text"
                        placeholder="Tipo de Actividad">
                          
                        {this.state.actividades_tipo&&this.state.actividades_tipo.map(tipo => (
                                                        <MenuItem value={tipo.Nombre} >
                                                          {tipo.Nombre}
                                                        </MenuItem>
                                                      ))
                                          }
                        </TextField>
                         <hr/>
                        <FormGroup >
                                <FormLabel component="legend" color="primary">Actividad Estándar</FormLabel>
                                <FormControlLabel
                                    fullWidth
                                  disabled={this.state.modificar}
                                  control={<Switch checked={this.state.estandar} onChange={this.onChange2}
                                  name="estandar" 
                                  color="primary"/>}
                                  label={label_estandar}
                                 
                                />
                          </FormGroup>
                          <hr/>
                   
                        <Grid container spacing={1} justifyContent="center" alignItems="center" margin= 'auto'>
     
                          <Grid item>{this.customList('Comunidades Asignadas', this.state.left,"izquierdo")}</Grid>
                          <Grid item>
                            <Grid container direction="column" alignItems="center">
                            <Button
                              variant="outlined"
                              size="small"
                            
                              onClick={this.handleCheckedRight}
                              disabled={(this.state.leftChecked.length === 0)||(this.state.left.length === 0)
                                 || this.state.modificar} 
                              aria-label="move selected right"
                            >
                              &gt;
                            </Button>
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={this.handleCheckedLeft}
                              disabled={(this.state.rightChecked.length === 0)||(this.state.right.lenght === 0)
                                 || this.state.modificar }
                              aria-label="move selected left"
                            >
                              &lt;
                            </Button>
                          </Grid>
                        </Grid>
                        <Grid item>{this.customList('Comunidades Seleccionables', this.state.right,"derecho")}</Grid>
                      </Grid>
                      <hr/>
                 
                    </form>  

              </Paper>
              </Grid>)}
            </ThemeProvider>
      </Grid>
      
      <Box mt={40}>
              <Copyright />
      </Box>
      
    </Grid>
    );
  }
  handleCheckedLeft = () => {
    this.setState({left: this.state.left.concat(this.state.rightChecked)})
    this.setState({right: this.not(this.state.right,this.state.rightChecked)})
    this.setState({checked:this.not(this.state.checked, this.state.rightChecked)});
    this.setState({ leftChecked : this.intersection(this.state.checked, this.state.left),
      rightChecked:[]})
    
  };
  handleCheckedRight = () => {
    
    this.setState({right: this.state.right.concat(this.state.leftChecked)})
    this.setState({left: this.not(this.state.left,this.state.leftChecked)})
    this.setState({checked: this.not(this.state.checked,this.state.leftChecked)});
    
      
    this.setState({ leftChecked : [],
      rightChecked:this.intersection(this.state.checked, this.state.right)})
};

numberOfChecked (items) {
    return (this.intersection(this.state.checked, items).length)
}

handleToggleAll = (items, lado) => () => {
    if (this.numberOfChecked(items) === items.length) {
   //   setChecked(not(this.state.checked, items.index));
      this.setState({checked:this.not(this.state.checked, items)});
      if (lado==="derecho"){
        this.setState({  rightChecked:[]});
      }else{
         this.setState({ leftChecked:[]});
      }   
    } else {
      this.setState({checked:this.union(this.state.checked, items)});
      if (lado==="derecho"){
        this.setState({  rightChecked: this.state.right});
      }else{
         this.setState({ leftChecked:this.state.left});
      }
   
    }
   
    
  };

not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

union(a, b) {
  return [...a, ...this.not(b, a)];
}
handleToggle (value){
  const currentIndex = this.state.checked.indexOf(value);
  const newChecked = this.state.checked;

  if (currentIndex === -1) {
    newChecked.push(value);
  } else {
    newChecked.splice(currentIndex, 1);
  }

  this.setState({checked: newChecked})
  this.setState({  leftChecked:this.intersection(this.state.checked, this.state.left) });
  this.setState({  rightChecked:this.intersection(this.state.checked, this.state.right) });
    
};
customList = (title, items, lado) => (
  <Card >
     <ThemeProvider>
    <CardHeader
      avatar={
        <Checkbox  
          onClick={this.handleToggleAll(items,lado)}
          checked={this.numberOfChecked(items) === items.length && items.length !== 0}
          indeterminate={this.numberOfChecked(items) !== items.length && this.numberOfChecked(items) !== 0}
          disabled={items.length === 0 || this.state.modificar}
          inputProps={{ 'aria-label': 'all items selected' }}
          color= "primary"
        />
      }
      title={title}
      subheader={`${this.numberOfChecked(items)}/${items.length} selected`}
    />
    <Divider />
    <List style={{width: 200, height: 230,backgroundColor: "primary", overflow: 'auto',}} dense component="div" role="list">
      {items.map((value) => 
           <ListItem key={value} role="listitem" button 
           onClick={() => {this.handleToggle(value)}}
           disabled = {this.state.modificar } >
            <ListItemIcon>
              <Checkbox
                checked={this.state.checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple1 
                disabled = {this.state.modificar}
                inputProps={{ 'aria-labelledby': `transfer-list-all-item-${value}-label` }}
                color= "primary"
              />
              
            </ListItemIcon>
            <ListItemText id={`transfer-list-all-item-${value}-label`}primary={`${value.Id} - ${value.Nombre}`}/> 
            
          </ListItem>
      )}
    </List>
    </ThemeProvider>
  </Card>
 );
}

const condition = authUser => !!authUser;
export default withFirebase(withAuthorization(condition)(Actividades));