import moment from 'moment';

 function  enviarAvisos (comunidades,texto_envio,texto_titulo,texto_url,firebase,path, pdfEnviar, id, Categorias,
   logueado, despacho, roles,Votacion, Pdfs) {
      //id = es el tipo de nodificacion que vamos a mandar, =1 envio notificacion de docubuscarDispositivosComunidadmentación
    return  new Promise(resolve => {  

            //por cada comunidad buscamos sus usuarios y sus dispostivos
            var tipo =(id==='1' ? 'documento':(Votacion ? 'votacion':null))
           
            const getData = async () => {
            return Promise.all(comunidades.map(comunidad =>buscarDispositivosComunidad(comunidad, firebase,Categorias, roles,tipo)))
            }
        
            getData().then(data => {
       //         const usuari = data[0].usuarios
                var dispositivos= []
                var usuarios= []
         //       if(usuari&&usuari.length>0){
       
                 
                    data.forEach(registro =>{ 
                      if(registro.dispositivos.length>0&&registro.usuarios.length>0){
                        dispositivos= dispositivos.concat(registro.dispositivos)
                        usuarios= usuarios.concat(registro.usuarios)
                      }
                  })
           //     }
           if(usuarios.length>0){
                buscarDispositivosAdmin(firebase,despacho).then((listaUsuarios)=>{
                  usuarios = usuarios.concat(listaUsuarios.usuarios)
                
                  if(window.confirm("¿Deseas envíar el aviso también a todos los implicados por email?" )){
                   let Pdfss=Pdfs
                   if(Pdfss&&Pdfss.length>0){
                    //Tenemos que incluir en el path la lista de enlaces
                    if(pdfEnviar!==''){
                      Pdfss=Pdfs.filter(pdff=>pdff.Nombre===pdfEnviar)
                      //el mensaje va asociado sobre solo 1 documento

                    }
                   }
                    const despachoCode =firebase.despachoCode
                    var envioEmailAviso = firebase.functions.httpsCallable('envioEmailAviso');
                   
                  
                    envioEmailAviso({'BaseDatos':despachoCode.BaseDatos,'titulo':texto_titulo,'texto':texto_envio,
                    'url':texto_url,'path':path,'usuarios':usuarios, 'Code':despachoCode.Code,'Pdfs':Pdfss}).then(()=>{
       
                       alert('Se ha realizado en el envío de email correctamente')
                       }).catch((e)=>{
                         alert(e)
                       })
                       
                    
               
                  }    
                    dispositivos = dispositivos.concat(listaUsuarios.dispositivos)
                    if(usuarios.length>0){
                        //Aqui meter el envío de email

                        guardarNotificaciones(firebase,usuarios,texto_envio,texto_titulo,texto_url,path, pdfEnviar
                          ,logueado,comunidades,Votacion).then((key)=>{
                           if(dispositivos.length>0&&usuarios.length>0){
                          resolve (enviaAviso(dispositivos,usuarios,texto_envio,texto_titulo, firebase,path,pdfEnviar,id,key))
                          }else{resolve(false)}
                        })
                        
                
                    } else{
                      const re=[]
                      resolve(re)}             })

            }else{
              resolve (false)
            }
                //})
            
          }).catch(e=>{
          
          })
        }) 
}


export function buscarUsuariosAdmin(firebase,despacho){
   var usuariosList = []
   var usuariosList2 = []
   return new Promise(resolve => {
      firebase.users().orderByChild("Administrador").equalTo(true).once('value', snapshot => {
        const usersObject = snapshot.val();
        if (usersObject) {
         usuariosList = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));
//            usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false && usuario.Administrador === false )
            usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false)

            usuariosList.forEach(usuario =>{
              usuario.Comunidad = despacho.Com_interna
              usuariosList2=usuariosList2.concat(usuario)

          })

          //   usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false && usuario.Administrador === true )
          resolve (usuariosList2)
      
        }else{resolve([])}
      })
      })

}

export function guardarNotificaciones(firebase,usuarios,texto_envio,texto_titulo,texto_url,path, pdfEnviar,logueado,
  comunidades2,Votacion){
  var notificacion = {}
  var comunidades = []

 
 return   new Promise(resolve=>{
 // var path1=''
 // if(path&&path!==''){path1=path}
  var doc=''
  if(pdfEnviar&&pdfEnviar!==''){doc=pdfEnviar}
  usuarios.forEach(usu =>{
    if(!notificacion[usu.Comunidad]){ 
                       notificacion[usu.Comunidad]={                                   
                        'Usuarios':[],
                        'Doc':doc,
                        'Path':path,
                        'Fecha': moment(new Date()).format('DD-MM-YYYY HH:mm'),
                        'Texto':texto_envio,
                        'Titulo':texto_titulo,
                        'Usuario_Registro': logueado.Nombre+' '+logueado.Apellido,
                        'Comunidades' :comunidades2,
                        'Url':(texto_url ? texto_url:'')    ,       
                        'Votacion':(Votacion ? Votacion:null)          
                      }
            comunidades.push(usu.Comunidad)
    }
    //  notificacion[usu.Comunidad].Usuarios=[]}

  notificacion[usu.Comunidad].Usuarios=notificacion[usu.Comunidad].Usuarios.concat({
      'Usuario':usu.uid,
      'Fecha_lectura':'',
      'Leida':false })
   
  })
  comunidades= comunidades.filter(comu =>comu!=='')
  new Promise(resolve=>{
    resolve(firebase.notificacion(comunidades[0]+'/').push(notificacion[comunidades[0]]))
    
  }).then((key)=>
    {
      const key1 = key.key
      comunidades.forEach( (comu,index)=>      {
        if(index!==0){
          firebase.notificacion(comu+'/').update( {[key1]:notificacion[comu]})
        }
      })
      resolve(key1)
    }
  )

 })
 
 

  
}

export function  buscarDispositivosAdmin(firebase,despacho) {
    var usuariosList = []
    var usuariosList2=[]
    return new Promise(resolve => {
      firebase.users().orderByChild("Administrador").equalTo(true).once('value', snapshot => {
        const usersObject = snapshot.val();
        if (usersObject) {
         usuariosList = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));
//            usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false && usuario.Administrador === false )
            usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false)

          //   usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false && usuario.Administrador === true )

    
            usuariosList.forEach(usuario =>{
                usuario.Comunidad = despacho.Com_interna
                usuariosList2=usuariosList2.concat(usuario)

            })
      
        }
      }).then(() => {

        const getData = async () => {
          return Promise.all(usuariosList2.map(usuario =>buscarDispositivosUsuario(usuario.uid, firebase)))
        }

        getData().then(data => {
          //   console.log(data)
             var dispositivos= []
             data.map(dispositivo => dispositivos= dispositivos.concat(dispositivo) )
             const registro={'dispositivos':dispositivos, 'usuarios':usuariosList2}
             resolve(registro)
           })
      })



    })

  }
 export function enviarDispositivosAdmin(texto_envio,
  texto_titulo, firebase1,PathDirectorioActual,pdfEnviar, id, envioPrevio){
    return  new Promise(resolve => {  
      buscarDispositivosAdmin(firebase1).then((dispo)=>{             
      var dispositivos= []
      var usuarios= []
         
      dispositivos = dispositivos.concat(dispo.dispositivos)
      usuarios = usuarios.concat(dispo.usuarios)


      //Envíamos aviso a los administradores
      guardarNotificaciones(firebase1,usuarios,texto_titulo,texto_envio,PathDirectorioActual, pdfEnviar).then((key)=>{

   //     resolve (enviaAviso(dispositivos,usuarios,texto_envio,texto_titulo, firebase,path,pdfEnviar,id,key))
        resolve( enviaAviso(dispositivos,usuarios,texto_envio,texto_titulo, firebase1,PathDirectorioActual,
          pdfEnviar, id,key))
    
      })

    
      })
    })
  }
  
export function enviaAviso(dis,usuarios, texto_envio,texto_titulo, firebase,path, pdfEnviar,id,key){
      
   var tokensList = []
   dis.map(dispo => tokensList = tokensList.concat(dispo.Dispositivos)  )
    var messaging= firebase.functions.httpsCallable('messaging');      
   // alert(id)
 
     //dividimos token list en vectores de 400 unidades 
     var tokens =[]
     const size = 400;
    
     while (tokensList.length > 0)  {tokens.push(tokensList.splice(0, size))}
     
     return  new Promise(resolve => {  
     const getData = async () => {
        return Promise.all(
          tokens.map(tokenes =>{
            return new Promise (resolve =>{
       //       const resul ={'Data':[path]} 
                    
         //     resolve (resul)                               

           
               messaging({ texto: texto_envio, titulo:texto_titulo, tokens: tokenes
              ,path:path,pdfEnviar:pdfEnviar,id:id,key:key }).then(function(result){
                    // Read result of the Cloud Function.
                      const resul ={'Data':result.data} 
                    
                      resolve (resul)                               
        
                    }).catch(function(error) {
                      // Getting the Error details.
                 
                      return error
        
                      // ...
                    })
                  
              })
              } ))
        }

      getData().then(data => {
          var Data=[]
          data.forEach((registro) =>{ 
              const drata= registro.Data
              if(drata&&drata.length>0){Data= Data.concat(drata)}
          })
            
          const registro={'Dispositivos':dis, 'Usuarios':usuarios,'Data':Data}
          resolve( registro)
        })
    
      })
  }
  function buscarDispositivosComunidad(comunidad, firebase, Categorias, roles,tipo){
    var usuariosList =[]
    return  new Promise((resolve,reject) => {
      firebase.users().orderByChild("Comunidad").equalTo(comunidad).once('value', snapshot => {
        const usersObject = snapshot.val();
        if (usersObject){
            usuariosList = Object.keys(usersObject).map(key => ({
              ...usersObject[key],
              uid: key,
            }));
          usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false && usuario.Administrador === false )
        //  usuariosList = usuariosList.filter(usuario => usuario.Borrado=== false )
       
        if(roles&&roles.length>0){

         usuariosList = usuariosList.filter(usuario => {
          const RolesUsuario = usuario.Roles        
          if(!RolesUsuario || RolesUsuario.length<1){return false}
          var esta = false
          RolesUsuario.forEach(rol=>{
            if(roles.includes(rol)){
             esta= true}
            })
            return esta
         
         })


        }
         
        if(Categorias&&Categorias.length>0){
         usuariosList= usuariosList.filter(function(usu){
            var zoquete= false
         if(usu.Categorias&&usu.Categorias.length>0)
            {   usu.Categorias.forEach(catt =>{
                const melon = Categorias.filter(cate => cate===catt)

                if(melon&&melon.length>0){zoquete= true}
                
            })
          }
            return zoquete
        })  


      }
    
       
        } 
      }).then(()=>{
       
        new Promise((resolve,reject) => {
          if(tipo&&usuariosList&&usuariosList.length>0){
            //Si es una votacion hay que quitar los usuarios que por algun motivo tienen la votacion y juntas bloquedas en su rol
            firebase.roles2().once('value', snapshot => {
              const usersObject = snapshot.val();
              if (usersObject){
                  var roles2 = Object.keys(usersObject).map(key => ({
                  ...usersObject[key],
                  uid: key,
                }));
                roles2=roles2.filter((rol)=>rol.Todo===false&&(tipo==='votacion'? rol.Juntas===false:rol.Documentos===false ))
       

                if(roles2&&roles2.length>0){
                  var elroles=[]
                  roles2.map(rola => elroles=elroles.concat(rola.Nombre))
          
                  usuariosList = usuariosList.filter(usuario => {
                   const RolesUsuario = usuario.Roles        
                   if(!RolesUsuario || RolesUsuario.length<1){return true}
                   var esta = true
                   RolesUsuario.forEach(rol=>{
                     if(elroles.includes(rol)){
                      esta= false}
                     })
                     return esta
                  
                  })
         
                    resolve(usuariosList)
                 }

              }else{resolve(usuariosList)}
            })
         
    
          }else{
            resolve(usuariosList)
          }
           
        })
          .then(()=>{

        if(usuariosList&&usuariosList.length>0){
          const getData = async () => {
            return Promise.all(usuariosList.map(usuario => buscarDispositivosUsuario(usuario.uid, firebase)))
          }
    
          getData().then(data => {
         //   console.log(data)
            var dispositivos= []
            data.map(dispositivo => dispositivos= dispositivos.concat(dispositivo) )
            const registro={'dispositivos':dispositivos, 'usuarios':usuariosList}
            resolve(registro)
          })
  
        }else {
          const registro={'dispositivos':[], 'usuarios':[]}
          resolve(registro)}
        }) 
      })
    
    .catch((e)=>{
     
    reject (e)})
  })
  
  }

export function buscarDispositivosUsuario(id, firebase){
    var dispositivosList=[]
    return  new Promise(resolve => {
      firebase.dispo(id).once('value', snapshot => {
      const usersObject = snapshot.val();
      if (usersObject){
          dispositivosList = Object.entries(usersObject ).map(([key, val]) => ({
            "Dispositivos": key+ ":"+ val , 
            "uid": id,
          })); 
          resolve(dispositivosList)
       }else resolve (dispositivosList)
      })
    })
    
  }

export function cargarResultado (data2,data3,firebase,logueado){
  var Usuarios=[]
  var Dispositivos =[]
  var Data=[]

  if(data2){

   if(data2.Dispositivos&&data2.Dispositivos.length>0){ Dispositivos =Dispositivos.concat(data2.Dispositivos)}
   if(data2.Data&&data2.Data.length>0){  Data= Data.concat(data2.Data)}
   if(data2.Usuarios&&data2.Usuarios.length>0){  Usuarios=Usuarios.concat(data2.Usuarios)}
  
   
  }
  var usuarios_enviar= Usuarios
 
  if(!logueado.SuperUser){
    usuarios_enviar= Usuarios.filter(usuario=> usuario.SuperUser!==true)
  }
 
  var dispositivos =[]
  Dispositivos.forEach((dispo)=>{
    const disposi = Data.filter(di => di.tokens===dispo.Dispositivos)
    var  dispo2 = dispo
    if (disposi&&disposi.length>0){
      dispo2.error='SI'
      dispo2.codigo=disposi[0].error.errorInfo.code
      dispo2.mensaje=disposi[0].error.errorInfo.message
    
    }else{   dispo2.error='NO'
            dispo2.codigo=''
            dispo2.mensaje=''
    }
      dispositivos= dispositivos.concat(dispo2)
  })

    var lista_final=[]
    var lista_sinerror = []
    usuarios_enviar.forEach((usuario)=>{

      const usu =  dispositivos.filter(dispo=> dispo.uid ===usuario.uid)
      if(usu&&usu.length>0){
          usu.forEach((usu2)=>{
            var Obj = {...usuario, ...usu2}
            lista_final=  lista_final.concat(Obj)
            if(usu2.error==='NO'){
              lista_sinerror = lista_sinerror.concat(Obj)
            }
          })
      
      }else{
        const usu2={error:'SI', Dispositivos:'NO DISPONE DE DISPOSITIVO ASOCIADO'}

        var Obj = {...usuario, ...usu2}
        lista_final=  lista_final.concat(Obj)

      }

      })  

      var archivo_final = lista_final
      lista_final.forEach((dispo)=>{
        if(dispo.error==='SI'){
          const esta = lista_sinerror.filter(disposi=>disposi.Nombre===dispo.Nombre&&disposi.DNI===dispo.DNI)
          if(esta&&esta.length>0){
            //borrar el dispositivo de la tabla para que no vuelva a dar error, hay al menos un dispositivo que si le ha llegado
            const tokens = dispo.Dispositivos.split(':')
            firebase.dispo(dispo.uid + '/' + tokens[0]).remove()
            archivo_final=archivo_final.filter(archi=>archi!==dispo)}
        }
    })
    const registro={ "usuariosTotal":Usuarios,"archivo_final":archivo_final }
    return registro


}
  export default enviarAvisos;