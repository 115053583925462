import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import Typography from '@material-ui/core/Typography';
import Copyright from './copyright';
import  Cabecera  from '../Cabecera/index_365';
import { createTheme , responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import  green from '@material-ui/core/colors/green';
//let theme = createTheme ();

let theme = createTheme ({
  typography: {
    // Tell Material-UI what the font-size on the html element is.
    htmlFontSize: 14,
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
      claro: green[200]
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#e81446',
    },
  
  },
});
theme = responsiveFontSizes(theme);
theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};
theme.typography.h4 = {
  fontSize: '0.9rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.1rem',
  },
};

class Aviso_legal extends Component {

    render() {
        return (
          <Grid >   <Cabecera/>    <hr/> 
          <Grid style={{ marginLeft: 20, marginRight:20}}>
          <ThemeProvider theme={theme}>
          <br/>
          <Typography variant='h3' color='primary'  align='center'> 
                AVISO LEGAL
              </Typography>
              <br/>
              <hr/>
              <br/>
              <Typography variant='h4' color='primary'  align='center'> 
              1.	Información legal
              </Typography>
              <Typography align='justify'>


              <br/>
            1.1.	En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico (LSSICE) presentamos la siguiente información:
            <br/>
            <Typography variant='body1'  align='justify'>
            Razón Social: DESARROLLO DE IDEAS DE GESTIÓN APP PENTA C365
            <br/>
            Dirección: Calle Pablo Sarasate 20 LO, CP: 50010, Zaragoza
            <br/>
            C.I.F.: B01857911
            <br/>
            Teléfono: 976120160
            <br/>
            E-mail: soporte@comunidad365.es 
            <br/>
            Datos de la inscripción en el Registro Mercantil de Zaragoza: Número de Entrada: 1/2020/6.370,0, Diario:350, Asiento: 1202,  
            Tomo: 4481, Folio: 26
          
              </Typography>
            </Typography>
            <hr/>

            <Typography variant='h4' color='primary'  align='center'> 
            2.	Protección de datos
              </Typography>
            <Typography   align='justify'>

            <br/>
            2.1.	Usted puede obtener la información sobre el tratamiento de sus datos personales en la Política de Privacidad o solicitarlos en nuestra dirección de contacto.
            <br/>
            <br/>
            2.2.	Los datos personales recogidos en nuestra página web son tratados cumpliendo con las exigencias del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos así como con la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.
            <br/>
            <br/>
            2.3.	Así mismo, garantizamos que los datos personales son tratados de manera lícita, leal y transparente, y son recogidos con fines determinados, explícitos y legítimos, manteniéndose limitados a dichos fines y actualizados si fuera necesario. No se permitirá la identificación de los interesados durante más tiempo del necesario y se garantizará su seguridad mediante las medidas técnicas y organizativas adecuadas.
            <br/>
            <br/>
          
            2.4.	Puede ejercer los derechos de acceso, rectificación, supresión, oposición, así como los demás derechos que le otorga la normativa de protección de datos sobre los datos de carácter personal presentando una copia de un documento identificativo en la dirección de contacto del responsable. Si desea dejar de recibir nuestras comunicaciones de carácter comercial, también puede hacer llegar su solicitud a la dirección anterior.
            <br/>
            <br/>
          
              2.5.	Puede encontrar más información sobre sus derechos o presentar una reclamación ante la autoridad de control competente en la Agencia Española de Protección de Datos http://www.agpd.es - C/ Jorge Juan, 6. 28001 – Madrid (901 100 099 - 912 663 517).
            </Typography>
            <hr/>
            <Typography variant='h4' color='primary'  align='center'> 
            3.	Limitación de Responsabilidades
              </Typography>
             <Typography  align='justify'>
             <br/>
            3.1.	La entidad proporciona el sitio web corporativo como medio de dar a conocer sus productos y servicios, y permitir el contacto con los clientes. La información de la página web se presenta de buena fe como cierta, pero nos reservamos el derecho a modificarla no asumiendo compromiso alguno de comunicar cambios con el propósito de actualizarla, corregirla, eliminarla y cualesquiera otras en interés de la entidad o de nuestros clientes. 
            <br/>
            <br/>
            3.2.	La entidad no se hace responsable de que la información contenida en la página web corresponda con las expectativas del Usuario. Del mismo modo, no responde de la veracidad, exactitud, suficiencia, integridad o actualización de la información que no sea elaborada propiamente por el responsable. En ningún caso la entidad se hace responsable de las opiniones o comentarios que puedan aparecer en la página web. 
            <br/>
            <br/>
            3.3.	Con la intención de que el usuario pueda encontrar información adicional, la entidad podrá incluir enlaces que permitan el acceso a otras páginas webs. En ningún caso, la existencia de dichos enlaces supone recomendación, promoción o conformidad de la entidad con las manifestaciones, contenidos, o servicios ofrecidos en la página web enlazada. En esos casos,  la entidad actúa como un prestador de servicios de intermediación, por lo que, en virtud del artículo 17 LSSI, la entidad no será responsable de los servicios y contenidos proporcionados a través de dichos enlaces, excepto que en el caso de que sea consciente de la ilicitud del contenido y no haya procedido a actuar con la debida diligencia. 
            <br/>
            <br/>
            3.4.	La entidad no se responsabiliza por los daños y perjuicios de toda naturaleza que pueda sufrir el usuario mientras visita nuestra página web por la presencia de virus o fallos informáticos en los contenidos, que puedan producir alteraciones en el sistema informático, en documentos electrónicos o en ficheros de los Clientes.

            </Typography>
            <hr/>
            <Typography variant='h4' color='primary'  align='center'> 
            4.	Propiedad Intelectual
              </Typography>
    
              <br/>
            <Typography   align='justify'>

            4.1.	Todos los contenidos del presente sitio web, entendiendo por tales los distintos elementos que lo constituyen (textos, dibujos, gráficos, fotografías, imágenes, vídeos, música, código, diseño de web y cualquier otra creación), corresponden a la entidad o a sus licenciantes estando todos ellos protegidos con arreglo a la Ley española de Propiedad Intelectual y demás legislación española e internacional vigente relativa a los derechos de autor. 
            <br/>
            <br/>    
            4.2.	Asimismo, la entidad es titular de los derechos de propiedad industrial derivados del uso de marcas, signos distintivos y nombres comerciales que figuran en la web. 
            <br/>
            <br/>     
             4.3.	En ningún caso se entenderá que la puesta a disposición al público de la página web constituye renuncia, cesión o licencia de los derechos que la entidad tiene sobre los contenidos y los signos distintivos de la misma. En consecuencia, salvo en aquellos supuestos en los que esté legalmente permitido o exista autorización previa del Responsable, queda expresamente prohibido al usuario la reproducción, transformación, distribución, comunicación pública, puesta a disposición, extracción y/o reutilización del sitio web, sus contenidos y los signos 
             <br/>
            <br/>
            4.4.	distintivos tanto del responsable, como de aquellos terceros que aparezcan en el sitio web. La entidad se reserva la posibilidad de ejercer las acciones legales correspondientes contra los Usuarios que infrinjan las condiciones de uso anteriormente establecidas.
            <br/>
            <br/>
              4.5.	La reproducción, transformación, distribución, comunicación pública, puesta a disposición, extracción y/o reutilización del sitio web, sin la correspondiente autorización de los titulares de los derechos de propiedad intelectual o de sus cesionarios, supone la comisión de infracciones tipificadas por la normativa aplicable, penalmente castigadas con penas de prisión de hasta dos años.

              </Typography>

            <hr/>
            <Box mt={5}>
                <Copyright />
          </Box>     
          <Box mt={5}>
              
          </Box>     
          </ThemeProvider>
        </Grid>
        
        </Grid>
        );
      }
    }

export default Aviso_legal;