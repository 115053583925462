
import Firebase,{ withFirebase } from '../../Firebase';
import { withAuthorization } from '../../Session';


export const createDocument = async (document, type, firebase) => {
  if(!document) return;
  return  new Promise(resolve => {  
    const callable1 =firebase.functions.httpsCallable('crearDatosTablas') 
    callable1({tabla:'/CRM/'+type,registro:document})        
    .then((result2) => { 
      const result=result2.data
      resolve (result);
      })               
  .catch ((error)=> {
    console.error(`error adding contact ${type}`, error)
    return(false)
  })})
}

export const getDocuments = async (documentId,type, firebase) => {
  return  new Promise(resolve => {  
    const callable2 =firebase.functions.httpsCallable('traerDatosTabla') 
    callable2({tabla:'/CRM/'+type,base:{'BaseDatos':'https://dogarfirbase.firebaseio.com'}})        
    .then((result) => {       
    const usersObject = result.data
    if(usersObject){
          const lista = Object.entries(usersObject).map(([key, val]) => ({
            ...val,  id:key
        }))
        if(documentId){
         const devuelve= lista.find(deal=>deal.id===documentId)
          resolve (devuelve)

        }else{resolve (lista)}
    }else {resolve (false)}
    })
  })
}

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if(!userAuth) return;

  const userRef ='' // firestore.doc(`users/${userAuth.uid}`);

  const snapShot = await userRef.get();

  if(!snapShot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData
      })
    } catch (error) {
      console.log('error creating user ', error);
    }
  }

  return userRef;
}



