import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import DeleteIcon from '@material-ui/icons/Delete';
import {List,Typography} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Cabecera from '../Cabecera/index_365'
import Divider from '@material-ui/core/Divider';

import Button from '@material-ui/core/Button';
import UpdateIcon from '@material-ui/icons/Update';
import { DropzoneArea } from "material-ui-dropzone";

import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import './Inci.css'
import { withFirebase } from '../Firebase';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';


import { createTheme  } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import  green from '@material-ui/core/colors/green';
import Grid from '@material-ui/core/Grid';
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import Firebase from '../Firebase';
import CircularProgress from '@material-ui/core/CircularProgress';
import Copyright from '../Privacidad/copyright'

import { Breakpoint } from 'react-socks';

const theme = createTheme ({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
});
const SignInPage = () => (
  <div>
    
    <SignInForm />
  
  </div>
);
const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
  firebase:'',
  usuarios:[],
  entrar:true,
  open:false,
  Estado:''
};
class Inci_gremios extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE, 
      inci:'', open:false,Pdfs:[],
      comunidad:'',
      firebase:'',
      Texto_añadir:'',
      estado_inicial:false,
      estados:[], estado:'',
      image:null,
  
      registro:{},
     
     };
  
  }

  componentDidMount() {
    this.setState({open:true})
    const id=this.props.match.params.id.split('*')
    const inci_codigo0 = id[1].toString()
    const inci_key0 = id[5].toString()

    const inci_codigo= inci_codigo0.substring(4);
    const inci_key= inci_key0.substring(8, inci_key0.length-8);
    const basedatos0= id[3].toString()
    const basedatos= basedatos0.substring(2);
    const registro = {
        'inci_codigo': inci_codigo,
        'inci_key': inci_key,
        'basedatos':basedatos

    }
    this.setState({registro:registro})

    this.onSubmitburnno(registro.basedatos)
  
  }

  handleClickBorrarFichero = (nombre) => {
   this.setState({open:true})
    var respuesta = window.confirm("¿Quieres borrar el fichero "+ nombre + "?")
      if(respuesta){
        const inci=this.state.inci
        let ref = this.state.firebase.storage_propio_completo().child('incidencias/'+inci.Comunidad+"/"+inci.Codigo+'/');

        ref.child(nombre).getDownloadURL()
        .then((url)=>{
          this.state.firebase.inci(inci.uid).once('value', snapshot => {
            let incidencia = snapshot.val() 
            if(incidencia){
              var ImagenesUrl = incidencia.ImagenesUrl
              if(ImagenesUrl&&ImagenesUrl.length>0){
                ImagenesUrl=ImagenesUrl.filter(urle=> urle!==url)
                if(ImagenesUrl.length==0){ incidencia.Imagen='NO'}
                incidencia.Fecha_Modificacion= moment(new Date()).format('DD-MM-YYYY HH:mm')
                incidencia.ImagenesUrl=ImagenesUrl
                this.state.firebase.inci(inci.uid).update(incidencia)

              }
             }

          })
          const p0 = new Promise ((resolve,reject)  => {  
            resolve( ref.child(nombre).delete());
         
            })
            .catch(error => {
                alert(error);
            })
          
      p0.then( (data) => {  this.buscarficheros('incidencias/'+inci.Comunidad+"/"+inci.Codigo+'/')
      this.setState({open:false})})
          

          
        })
           
        }    
  };
  componentWillUnmount() {
    this.state.firebase.inci(this.state.registro.inci_key).off()
   
  }
 
  onSubmitburnno = (bd) => { 
 
    var despacho=[]
    var firebase2=''
    this.props.firebase
      .doSignInWithEmailAndPassword('incidencias@comunidad365.es', '##12##55KKh')
      .then(() => {
        
        this.props.firebase.auth.onAuthStateChanged(
          authUser => {
        if (authUser){       
          this.props.firebase.appp.delete().then(()=>{
          firebase2 = new Firebase('https://dogarfirbase.firebaseio.com','')
          const db =  firebase2.db
          db.ref('/Despachos').once('value',(snapshot) =>{
                const despachos= snapshot.val()
                 despacho = despachos.find(des=>des.Codigo===bd)                
                 const guardaDespacho =  firebase2.functions.httpsCallable('guardaDespacho');
                 guardaDespacho({'uid':authUser.uid,'despacho':bd,'BaseDatos':despacho.BaseDatos, 
                 'Almacen':despacho.Almacen, 'Code':despacho.Code}).then(()=>{           
                  firebase2.appp.delete().then(()=>{
                    var firebase = '' 
                    if(despacho&&despacho.BaseDatos){
                      firebase = new Firebase(despacho.BaseDatos,despacho.Almacen,despacho.Code,despacho)
                    }else{
                      firebase = new Firebase('https://dogarfirbase-'+bd+'.firebaseio.com', 
                    'gs://dogarfirbase-'+bd)                
                    }
                 this.setState({firebase:firebase})
                    this.traerRegistro(firebase)
              }).catch((ee)=>{
                  const eer=ee
              })
            })
          })
           })
          }  
          },
        );
        
        
  
    

      })
      .catch(error => {

        if(error.code==='auth/wrong-password'){   this.setState({ error:"La contraseña no es correcta", open:false });}
        
        else if(error.code==='auth/invalid-email'){   this.setState({ error:"El formato del email no es correcto", open:false});}
     //   this.setState({ error });
  
        else if(error.code==='auth/user-not-found'){   this.setState({ error:"Usuario no encontrado" , open:false});}
   
    });
  
  };
  traerRegistro(firebase){     
    return new Promise (resolve => {
             var inci=''
             const registro=this.state.registro
             const inci_codigo=registro.inci_codigo
         
             firebase.inci(registro.inci_key).once('value', snapshot => {        
               const usersObject = snapshot.val();
               if(usersObject&&usersObject.Borrado===false){
                  inci={...usersObject,'uid':registro.inci_key}
                if(inci.Codigo===Number(inci_codigo)){ 
                  this.setState({inci:inci,estado:inci.Estado})
                    this.buscarficheros('incidencias/'+inci.Comunidad+"/"+inci.Codigo+'/')
                } 
             
              this.state.firebase.estados().once('value', snapshot => {
                const usersObject = snapshot.val();
                var estadosList = Object.keys(usersObject).map(key => ({
                  ...usersObject[key],
                  uid: key,
                }));
                
                if(inci.Estado!=='CANCELADA'){
                  estadosList = estadosList.filter(estado => estado.Nombre!=='CANCELADA')
                }
                var estado_inicial= false
                if(inci.Estado==='CERRADA'){
                  estado_inicial=true
                  alert('La incidencia se encuentra cerrada, si deseas hacer algún cambio ponte en contacto con el administrador, gracias')
                }
                if(inci.Estado==='CANCELADA'){
                  estado_inicial=true
                  alert('La incidencia se encuentra cancelada, si deseas hacer algún cambio ponte en contacto con el administrador, gracias')
                }
      
                this.state.firebase.comus().once('value', snapshot => {
                  const usersObject = snapshot.val();
                  const comuList = Object.keys(usersObject).map(key => ({
                    ...usersObject[key],
                    uid: key,
                  }))
      
                  const comu = comuList.filter(com =>com.Id===inci.Comunidad)
                  this.setState({ estados: estadosList,comunidad:comu[0].Nombre,
                  estado_inicial:estado_inicial,open:false});
             
                })
             
                });

              }else{alert('No existe la incidencia o ha sido borrada')}
      
         
       }).then((u)=>{
        const unu=u
       }).catch((e)=>{
        const rtt=e
       })
    })
       
  

   }

  buscarficheros (Path) {   
      this.setState({Pdfs:[]})
     
       var pdfs=[];
         
      let listRef = this.state.firebase.storage_propio_completo().child(Path);
    
    //    let Ref_padre = listRef.parent;
    //    this.setState({Ref_padre:Ref_padre, PathDirectorioActual:Path})
      const p0 = new Promise ((resolve,reject)  => {
    
        listRef.listAll().then(function(res) {
    
            
          res.items.forEach(function(itemRef) {
            pdfs.push({
                items:itemRef
    
            })          
          })
            resolve(pdfs)
            
          })
         
      
      })
      p0.then( (data) => { 
               //     this.setState({dataCategoria3:carpetas})
                    
                     this.traerPDFS(pdfs)
    
      })
    
    }
  traerPDFS  (items){
    const registro = this.state.registro
   
     if(items.length>0){ 
      this.state.firebase.inci(registro.inci_key+'/').update({'hayFichero':true})    
      const listRef2 = this.state.firebase.storage_propio_completo()
      var pdfs=[];
      let promises = items.map((itemRef) => { 
         return new Promise (resolve => {
          listRef2.child(itemRef.items.location.path).getMetadata().then(function(metadata) {
            listRef2.child(itemRef.items.location.path).getDownloadURL().then(function(url) {
            resolve(  { 'url':url, 'meta':metadata.customMetadata })
            })

        }).catch(function(error) {
          // Uh-oh, an error occurred!
        })
      })
      });
      Promise.all(promises).then((downloadURLs) => {
        var i=0;
        items.forEach((itemRef) => {
              pdfs.push({
              id: i,
              Nombre: itemRef.items.name,
              url: downloadURLs[i].url ,
              metaDatos:(downloadURLs[i].meta ? downloadURLs[i].meta:null ),
              path: itemRef.items.location.path,
          //  fullpath: itemRef.items.fullPath,
              image: require('../../Imagenes/Mimetypes-pdf-icon.png'),  
            })
            i++;
          })
            
          pdfs = pdfs.filter(file => file.Nombre !== "temporal.txt")
          this.setState({Pdfs:pdfs,open:false})
             
        }); 


        
    
      }else{
        this.state.firebase.inci(registro.inci_key+'/').update({'hayFichero':false})    
      
      }
    }
  onDrop(file) {

    const Pdfs =this.state.Pdfs
    const registro=this.state.registro
    this.setState({open:true})

    const inci=this.state.inci
    const metadata = { customMetadata: { 'propietario':'gremio' } }

    const putData = async () => {
        return Promise.all(file.map(fichero =>  {
          return new Promise (resolve => {
     
          if(Pdfs&&Pdfs.length>0){
            const pdf =Pdfs.filter(file=>file.Nombre===fichero.name)
            if(pdf&&pdf.length>0){
              if(!pdf[0].meta){
                alert('El fichero '+fichero.name+' ya existe y no se puede modificar')
                resolve(false)

              }
            }
            else{
              this.state.firebase.storage_propio_completo('incidencias/'+inci.Comunidad+"/"+inci.Codigo+'/'+fichero.name).put(fichero,metadata)
              .then((data)=>{
                this.state.firebase.inci(registro.inci_key+'/').update({'hayFichero':true})    
                this.state.firebase.storage_propio_completo('incidencias/'+inci.Comunidad+"/"+inci.Codigo+'/'+fichero.name).getDownloadURL()
                .then((url)=>{
                  resolve(url)
                })
              })
                
            }

        }else{
            const path='incidencias/'+inci.Comunidad+"/"+inci.Codigo+'/'+fichero.name
     //       const pathResul='incidencias/'+inci.Comunidad+"/"+inci.Codigo
            this.state.firebase.storage_propio_completo(path).put(fichero,metadata)
           .then(()=>{
            this.state.firebase.inci(registro.inci_key+'/').update({'hayFichero':true})
      
            this.state.firebase.storage_propio_completo(path).getDownloadURL()
            .then((url)=>{
              resolve(url)
            })
            // const dato = data.metadata
        
      
            
           })
          
          }
        })
        }))
     
      }

      putData().then(urls => {
        urls=urls.filter((url)=>url!=false)
        this.state.firebase.inci(inci.uid).once('value', snapshot => {
          let incidencia = snapshot.val() 
          if(incidencia){
            var ImagenesUrl = (incidencia.ImagenesUrl&&incidencia.ImagenesUrl.length>0 ? urls.concat(incidencia.ImagenesUrl): urls)
            incidencia.ImagenesUrl=ImagenesUrl
            incidencia.Imagen='SI'
            incidencia.Fecha_Modificacion= moment(new Date()).format('DD-MM-YYYY HH:mm')
            this.state.firebase.inci(inci.uid).update(incidencia)
           }
        })
        
        this.buscarficheros('incidencias/'+inci.Comunidad+"/"+inci.Codigo+'/')
      //  this.setState({open:false})
                      
      })
  
 // }
}
 
onChange = event => {this.setState({ [event.target.name]: event.target.value })}

actualizar = () =>{
  if(!window.confirm('Se van a enviar los cambios realizados al usuario/s y adminsitrador'))
        {return true}
  if(this.state.estado==='CERRADA'){
    if(!window.confirm('¿Quieres cambiar el estad a CERRADA?, si lo cambias ya no podrás modificar la incidencia'))
        {return true}

  }
  this.setState({open:true})
  const registro=this.state.registro
  let inci= this.state.inci
  inci.Estado = this.state.estado
  inci.inciLeida=[]


  let fecha=  moment(new Date()).format('DD-MM-YYYY HH:mm')
  inci.Fecha_Modificacion=fecha
  const descripcion_final='\n'+ 
  "<"+inci.Proveedor+'> ' +fecha +' '+ this.state.Texto_añadir
  
  inci.Texto_publicar = inci.Texto_publicar+descripcion_final 
  inci.Descripcion = inci.Descripcion+descripcion_final 
  this.state.firebase.inci(registro.inci_key).update(inci).then(()=>{
    this.setState({estado_inicial:(inci.Estado==='CERRADA' ? true:false) });
    this.setState({inci:inci,Texto_añadir:'',open:false},()=>{
      alert('La incidencia se ha actualizado correctamente')

    })

  })


}


  render() {
    const inci= this.state.inci
    const estados = this.state.estados

    const Documentos =          
    <>
             
  {((this.state.Pdfs&&this.state.Pdfs.length>0) ||!this.state.estado_inicial)&&
    <Paper >   
    {this.state.Pdfs && this.state.Pdfs.length>0&& 
    <List component="nav" dense="true"  aria-label="main mailbox folders" justify="space-around" alignItems="center" >
      <h4 style={{color:'green', justifyContent:'center'}} >Documentación adjunta</h4>  
      {this.state.Pdfs && this.state.Pdfs.map(actividad => {
        const metaDatos=actividad.metaDatos
        return (                  
      <Grid>
        
      <Divider />
      <ListItem 
      button
      selected={this.selectedIndex === actividad.Nombre}
      onClick={() => {
          window.open(actividad.url)  }}
      >
      <ListItemAvatar>
        <Avatar  style ={{color:'lightgreen', backgroundColor:green[500]}}>  
            <InsertDriveFileIcon/>
        </Avatar>
      </ListItemAvatar>
        <ListItemText  className='listaFicheros' primary={actividad.Nombre}/>
        <ListItemSecondaryAction >

              <IconButton edge="end" aria-label="delete"
              tooltip='Para poder borrar el estado de la incidencia debe estar ABIERTA'
              disabled={this.state.estado_inicial || !metaDatos||metaDatos.propietario!=='gremio' ||!metaDatos.quien}
                onClick={(event) => this.handleClickBorrarFichero(actividad.Nombre)} 
              >
                
                <DeleteIcon />
        </IconButton>


</ListItemSecondaryAction>
      </ListItem>                      
        
      </Grid>
        
      )})}
    </List>}
   
    </Paper>}
  
    <Breakpoint large up>   
      {!this.state.estado_inicial&&<DropzoneArea 
          maxFileSize={10000000}
          onDrop = {acceptedFiles => this.onDrop(acceptedFiles)}
          showPreviewsInDropzone= {false} 
          filesLimit={5}
        
          showFileNames ={false}
          dropzoneText = {"Arrastra el fichero o haz click aquí para cargarlo desde la ventana"}/>}
    </Breakpoint>
    <Breakpoint medium down>   
      {!this.state.estado_inicial&&<DropzoneArea 
          maxFileSize={10000000}
          onDrop = {acceptedFiles => this.onDrop(acceptedFiles)}
          showPreviewsInDropzone= {false} 
          filesLimit={5}
        
          showFileNames ={false}
          dropzoneText = {"Haz click aquí para cargar un fichero o adjuntar una foto"}/>}
    </Breakpoint>


</>


  
    return (     

     
      <Grid >
          <ThemeProvider theme={theme}> 
          {!this.state.open&&<Cabecera/>}
          {!this.state.open&&<hr/>}
     
          {!this.state.open&&<div>  <Grid container direction="column" justifyContent="center" alignItems="center">
            <Paper elevation={4}  style={{ maxWidth:800,  marginLeft: 10, marginRight: 10, marginTop: 10 }}  >
            <Breakpoint large up>   
              <Grid container direction="row" justifyContent='space-between' alignItems="center">
          
              <h4 style={{color:'green', marginLeft:20}} >Incidencia: 
              <spam style={{color:'gray'}} > {inci.Codigo}</spam>  </h4>  
              <h4 style={{color:'green', marginRight:20}} >Gremio: 
              <spam style={{color:'gray'}} > {inci.Proveedor}</spam>  </h4>  

              </Grid>
         </Breakpoint>
         <Breakpoint  medium down>  
              <Grid>
          
              <h4 style={{color:'green', marginLeft:20}} >Incidencia: 
              <spam style={{color:'gray'}} > {inci.Codigo}</spam>  </h4>  
             
              <h4 style={{color:'green', marginLeft:20}} >Gremio: 
              <spam style={{color:'gray'}} > {inci.Proveedor}</spam>  </h4>  

              </Grid>
         </Breakpoint>
    
         <div style={{ marginLeft:20, marginRight:20}} >
          {!this.state.estado_inicial&&<TextField  InputLabelProps={{ shrink: true }}
                         disabled={this.state.estado_inicial}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="Texto_añadir"
                          multiline
                          rowsMax={4}
                          
                          label="Añade comentarios y envíalos"
                          name="Texto_añadir"
                          autoComplete="Texto_añadir"
                          autoFocus
                          value={this.state.Texto_añadir}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Texto a añadir"
                        />}
                     
          {!this.state.estado_inicial&&<hr/>}  
                        
              <TextField  InputLabelProps={{ shrink: true }}
                       
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="estado"
                          disabled={this.state.Texto_añadir ==='' ||this.state.Texto_añadir.trim()===''
                        ||  this.state.estado_inicial}
                                           
                          label="Estado"
                          name="estado"
                          autoComplete="estado"
                          onChange={this.onChange}

                          value={this.state.estado}
                       
                          select
                          placeholder="Nombre zona"
                        >
                           <MenuItem value="">{""}</MenuItem> 
                {estados && estados.map(comu => (                  
                  <MenuItem value={comu.Nombre}>{comu.Nombre}</MenuItem>                
                ))}
                      
                  </TextField>
              <Button    onClick={this.actualizar}
                          disabled={this.state.Texto_añadir ==='' ||this.state.Texto_añadir.trim()===''||
                          this.state.estado_inicial}
                         
                          size="large" color="primary" variant="contained"  startIcon={<UpdateIcon />}>
                                      ACTUALIZAR
                          </Button>

        
              <TextField  InputLabelProps={{ shrink: true }}
                          disabled ={true}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="nombre"
                          multiline
                          rowsMax={4}
                          label="Descripción de la Incidencia"
                          name="nombre"
                          autoComplete="nombre"
                         
                          value={inci.Texto_publicar}
                       
                          type="text"
                          placeholder="Nombre zona"
                        />
              <TextField  InputLabelProps={{ shrink: true }}
                         disabled ={true}
                          variant="outlined"
                          margin='normal'
                          fullWidth                     
                          name="comunidad"
                          label="Comunidad"
                          id="descripcion"
                          autoComplete="comunidad"
                          value={this.state.comunidad}
                          type="text"
                          placeholder="Comunidad"
                        />
              {inci.ImagenUrl&&<div  > 
              <Typography className='imagen'>Imagen asociada: <a href={inci.ImagenUrl} >Pincha aquí</a></Typography>
              
              </div>}
              <TextField  InputLabelProps={{ shrink: true }}
                          disabled ={true}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="nombre"
                          
                          label="Última Modificación"
                          name="nombre"
                          autoComplete="nombre"
                          autoFocus
                          value={inci.Fecha_Modificacion}                      
                          type="text"
                          placeholder="Nombre zona"
                        />
            
              <TextField  InputLabelProps={{ shrink: true }}
                          disabled ={true}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="nombre"
                          label="Actividad"
                          autoComplete="nombre"
                          autoFocus
                          value={inci.Actividad}
                       
                          type="text"
                          placeholder="Nombre zona"
                        />
              <TextField  InputLabelProps={{ shrink: true }}
                          disabled ={true}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="nombre"
                          
                          label="gremio Responsable"
                          name="nombre"
                          autoComplete="nombre"
                          autoFocus
                          value={inci.Proveedor}
                       
                          type="text"
                          placeholder="Nombre zona"
                        />

            
                {Documentos}
                          
        </div>
            </Paper>
        </Grid>
            
         <Box mt={40}>
          <Copyright />
          </Box>
          </div>}
          <Backdrop  color = 'primary' open={this.state.open}>
               <CircularProgress color="primary" />
         </Backdrop>    
        
        </ThemeProvider>
        </Grid>
      
    );
  
  }
}

//const condition = authUser => !!authUser;

const SignInForm = compose(
  withRouter,
  withFirebase)(Inci_gremios);
 // const SignInForm = compose(
//    withRouter,
 //   withFirebase,
 //   withAuthorization(condition))(Inci_gremios);
export default SignInPage;
export { SignInForm };