import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Copyright from './copyright';
import Cabecera from '../Cabecera/index_365';

import { createTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
//let theme = createTheme ();

let theme = createTheme({
  
  typography: {
    // Tell Material-UI what the font-size on the html element is.
    htmlFontSize: 14,
  },
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
      claro: green[200]
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#e81446',
    },

  },
});
theme = responsiveFontSizes(theme);

theme.typography.h3 = {
  fontSize: '1.2rem',
  '@media (min-width:600px)': {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.4rem',
  },
};
theme.typography.h4 = {
  fontSize: '0.9rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.1rem',
  },
};


class Aviso_legal extends Component {

  render() {
    return (
      <Grid >   <Cabecera />    <hr />

        <Grid style={{ marginLeft: 20, marginRight: 20 }}>
          <ThemeProvider theme={theme}>
          <br />
            <Typography variant='h3' color='primary' align='center'>
              POLÍTICA DE PRIVACIDAD
              </Typography>
            <br />
            <hr />
            <br />
            <Typography align='justifyContent'>
              <Typography variant='h4' color='primary' align='center'>
                TRATAMIENTO DE LOS DATOS PERSONALES
              </Typography>
              <br />

                     Los datos personales recogidos en nuestra página web y en nuestra aplicación son tratados cumpliendo con las exigencias del Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos, así como con la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.
                    <br />
              <br />
                    Así mismo, garantizamos que los datos personales son tratados de manera lícita, leal y transparente, y son recogidos con fines determinados, explícitos y legítimos, manteniéndose limitados a dichos fines y actualizados si fuera necesario. No se permitirá la identificación de los interesados durante más tiempo del necesario y se garantizará su seguridad mediante las medidas técnicas y organizativas adecuadas.
                    <br />
                    <br />
              <Typography variant='h4' color='primary' align='center'>
                ¿Quiénes somos?
              </Typography>
              <br />


                    Razón Social: DESARROLLO DE IDEAS DE GESTIÓN APP PENTA C365
                    <br />
                    Dirección: Calle Pablo Sarasate 20 LO, CP: 50010, Zaragoza
                    <br />
                    C.I.F.: B01857911
                    <br />
                    Teléfono: 976120160
                    <br />
                    E-mail: soporte@comunidad365.es
                     <br />
              <Typography variant='h4' color='primary' align='center'>
                ¿Para qué tratamos sus datos y qué nos permite hacerlo?
                    <br />
                    ¿Por qué podemos tratar sus datos?
              </Typography>
              <br />



                    Sus datos van a ser tratados por Comunidad365 (C365 en adelante) con las siguientes finalidades:
                    <br />
                    <br />
              <Typography variant='h5' color='primary' align='left'>
              Como encargado del tratamiento de los datos:
                  </Typography>
              <br />
                     Los datos personales que usted proporciona a través del formulario inicial van a ser tratados por C365, siguiendo las instrucciones de aquel que ha contratado el servicio, para llevar a cabo las siguientes finalidades:
                    <br />
              <br />

                    1.	Aviso inmediato de incidencias con registro por triplicado al administrador, presidente y gremio: Cuando el interesado comunica una avería, la misma será enviada por correo electrónico junto con sus datos de contacto al profesional del gremio que se va a encargar de resolverla para poder ponerse en contacto con usted en caso de ser necesario.  Así mismo, se enviará una copia de dicha incidencia al Administrador y/o Presidente de su comunidad para poder llevar a cabo el registro y seguimiento de su solicitud.
                    <br />
              <br />

                    2.	Comunicación de partes por siniestro. Cuando el interesado envía a través de la aplicación una notificación de siniestro sus datos de contacto serán puestos a disposición de la aseguradora contratada por su Comunidad de Propietarios para poder llevar a cabo la gestión de la incidencia de la que desea informar y ponerse en contacto con usted para ampliar información sobre el siniestro en caso de que sea necesario.
                    <br />
              <br />

                    3.	Cambios de titularidad, datos, on-line. Cuando el interesado envía a través de la App la solicitud de cambio de titularidad o de sus datos personales, los mismos serán puestos a disposición de su Administrador para, una vez comprobada la identidad del usuario y la veracidad de los datos, pueda llevar a cabo el cambio efectivo de los mismos o, en su caso, de la titularidad.
                    <br />
              <br />

                    4.	Tratamos los datos de la ubicación del dispositivo móvil del trabajador cuya comunidad tenga contratado el servicio de control horario, exclusivamente para verificar que el fichaje realizado mediante la captación de un código QR, se produce efectivamente en el lugar de trabajo acordado. Esta información será suprimida una vez que el trabajador haya procedido al fichaje.
                    <br />
              <br />

                    5.	Gestión de reservas zonas comunes. Cuando el interesado hace la reserva de cualquiera de las instalaciones comunes, sus datos personales facilitados a tal efecto pueden ser consultados por el Administrador y/o Presidente de su Comunidad de Propietarios cuando exista un incidente que así lo justifique para resolver, en su caso, las posibles responsabilidades. Sus datos personales no serán conocidos por el resto de los usuarios que quieran hacer uso del apartado de reservas.
                    <br />
              <br />

                    6.	Juntas virtuales y votaciones. El uso de la cámara y el micrófono para acceder a la Junta Virtual es totalmente voluntario. En caso de que el usuario active alguna de estas herramientas, su imagen y su voz no serán utilizados para ninguna finalidad distinta al desarrollo de la propia junta y no serán almacenados por el responsable de la aplicación en ningún caso. Queda prohibida la captación y difusión de la imagen y la voz de los asistentes. Dichas prácticas pueden ser constitutivas de un delito tipificado por la normativa aplicable y pueden llegar a ser penalmente castigadas. La aplicación no se hace cargo del mal uso que puedan realizar los participantes, no teniendo la misma ninguna responsabilidad legal al respecto.
                    <br />
              <br />

                    7.	Avisos informativos a través del terminal relativos a los servicios proporcionados por la aplicación. El usuario recibirá avisos en su terminal de todos aquellos servicios que proporcione la aplicación (Reuniones, alerta, estado de las incidencias, notificación de junta, etc.) El usuario puede gestionar dichas alertas a través de los ajustes del propio dispositivo. (PROVISIONAL)
                    <br />
              <br />

                    8.	Análisis de Seguros de vivienda e impagos. Si el usuario decide utilizar esta herramienta, todos los datos solicitados para llevar a cabo dicho análisis, incluido, en su caso, las fotografías de las pólizas que él mismo quiera adjuntar, podrán ser consultadas únicamente por su Administrador, que será el encargado de resolver su consulta o solicitud de información a través de cualquiera de los medios dispuestos al efecto.
                    <br />
                    <br />
              <Typography variant='h5' color='primary' align='left'>
                Como responsable del tratamiento de los datos:
                  </Typography>
              <br />


                    Los datos personales serán tratados por nosotros como responsables cuando usted los proporcione completando los formularios de registro o de contacto, enviando correos electrónicos o llamando por teléfono.
                    <br />
              <br />

                    1.	Responder a consultas y solicitudes de información. Cuando el interesado nos hace llegar una consulta o solicitud de información tratamos sus datos con su consentimiento para darle respuesta sobre la consulta o solicitud.
                    <br />
              <br />

                    2.	Comunicarnos comercialmente con los interesados por cualquier medio. Cuando el interesado se registra como usuario en la aplicación móvil o registra su correo electrónico en otros formularios dispuestos a tal efecto, le pediremos permiso para mantenerle informado mediante ofertas, promociones, actividades, productos o servicios propios y de terceros, en relación con sus intereses, sobre viajes y turismo, equipamiento deportivo, ocio, cultura, automoción, hostelería y restauración, educación y/o formación, servicios y/o productos financieros, medios de comunicación, informática, tecnología, internet, telefonía y telecomunicaciones, salud y/o belleza e inmobiliaria, legitimados por su consentimiento.
                    Los interesados pueden oponerse en cada una de las comunicaciones enviadas y adicionalmente mediante el mecanismo previsto en el apartado “¿Cuáles son sus derechos?”.
                    <br />

              <br />
                    3.	Utilizar dispositivos de almacenamiento y recuperación de datos. Cuando el interesado visita nuestra página, utilizaremos con su consentimiento cookies y otras herramientas con las finalidades previstas en nuestra Política de Cookies donde puede acceder a más información.
                    <br />
              <br />

                    4.	Mediante la información obtenida de dispositivos de seguimiento, almacenamiento y recuperación de datos con el consentimiento de los visitantes que navegan por la página web y utilizan la aplicación móvil, y el análisis de su navegación y comportamiento, se llevará a cabo la elaboración de perfiles comerciales de clientes que permitan enviar comunicaciones comerciales electrónicas personalizadas a aquellos usuarios que se identifiquen con un correo electrónico. Puede obtener información complementaria en la Política de Cookies.
                    <br />
              <br />

                    5.	Comparador energético. Si el usuario utiliza esta función, los datos personales facilitados por el mismo para llevar a cabo el estudio de su situación energética serán puestos a disposición de la empresa reflejada en la aplicación para poder llevar a cabo la propuesta que más rentable sea en relación con sus necesidades. Será la propia empresa energética la responsable de hacerle llegar a través de su correo electrónico la propuesta indicada.
                    <br />
              <Typography variant='h4' color='primary' align='center'>
                ¿A quién proporcionamos sus datos?
                  </Typography>
              <br />


                    Podemos comunicar los datos personales a terceros proveedores encargados del tratamiento, con la única finalidad de que nos presten determinados servicios (principalmente el alojamiento de la información recogida por nuestra aplicación y página web) siguiendo nuestras instrucciones sin utilizar los datos para sus propias finalidades y con objeto de llevar a cabo las actividades que nos son propias, o a las administraciones públicas competentes en cumplimiento de obligaciones legales.
                    <br />
              <br />

                    También comunicaremos los datos cuando el usuario solicite la prestación de servicios adicionales como el comparador energético, según las finalidades previstas.

                    <br />
              <Typography variant='h4' color='primary' align='center'>
                ¿Cuánto tiempo conservamos sus datos?

                  </Typography>
              <br />
                    Por regla general los datos serán conservados mientras sean compatibles con la finalidad con la que fueron recogidos, en cumplimiento de obligaciones legales de conservación y para hacer frente a las responsabilidades que pudieran derivarse del tratamiento, para después ser suprimidos.
                    <br />
              <br />

                    Los datos recogidos con la finalidad de comunicarnos comercialmente serán conservados mientras perdure dicha finalidad o el usuario se oponga al tratamiento.
                    <br />
              <Typography variant='h4' color='primary' align='center'>
                ¿Cuáles son sus derechos?
                  </Typography>
              <br />

                    Usted podrá:
                    <br />
              <br />
                    1.	Obtener más información
                    <br />
              <br />
                    2.	Retirar su consentimiento cuando lo hubiera prestado para un tratamiento determinado
                    <br />
              <br />
                    3.	Dejar de recibir nuestras comunicaciones de carácter comercial, enviando “Quiero dejar de recibir comunicaciones comerciales” a soporte@comunidad365.es
                    <br />
              <br />
                    4.	Ejercer sus derechos de:
                    <br />
        
                    •	Acceso, o conocer que datos personales tratamos de usted;
                    <br />

                    •	Rectificación, o corregir datos personales incorrectos o inexactos;
                    <br />

                    •	Supresión, o a eliminar los datos personales que tenemos de usted que no estemos obligados legalmente a conservar;
                    <br />

                    •	Oposición, o a que dejemos de tratar sus datos personales con una finalidad concreta;
                    <br />
                    •	Limitación, o a restringir el tratamiento que realizamos;
                    <br />
                    •	Portabilidad, o a que le proporcionemos los datos personales que obran en nuestro poder;
                    Para poder ejercer cualquiera de los derechos que le otorga la normativa de protección de datos, usted podrá solicitar al responsable un formulario para el ejercicio de derechos o descargar directamente cualquiera de los formularios propuestos por la Agencia Española de Protección de Datos a través de este enlace y presentarlo cumplimentado junto con una copia del DNI o cualquier otro documento identificativo válido en la dirección o correo electrónico del responsable.
                    Puede encontrar más información sobre sus derechos o presentar una reclamación ante la autoridad de control competente en la Agencia Española de Protección de Datos http://www.agpd.es - C/ Jorge Juan, 6. 28001 – Madrid (901 100 099 - 912 663 517).
                    <br />
                    <br />
              <Typography variant='h4' color='primary' align='center'>
                ¿Cómo garantizamos la seguridad de sus datos?
                  </Typography>
              <br />

                    Hemos adoptado las medidas técnicas y organizativas apropiadas para garantizar un nivel de seguridad adecuado que evite la destrucción, pérdida o alteración accidental o ilícita de datos personales, o la comunicación o acceso no autorizados a dichos datos.
                    También hemos habilitado procedimientos para responder en los plazos legales oportunos ante cualquier brecha de seguridad que pudiera producirse sobre tus datos personales.
                    Al contratar a proveedores, garantizamos que implantan las medidas de seguridad adecuadas para la protección de los datos personales.
                    <br />
                 
                    <br />
              <Typography variant='h4' color='primary' align='center'>
              Cambios en la Política de Privacidad
                  </Typography>
              <br />
              

                    Hemos elaborado esta Política para según los requisitos y obligaciones del Reglamento General de Protección de Datos.
                    Esta Política es de fecha: septiembre 2020




                   </Typography>
            <hr />
            <Box mt={5}>
              <Copyright />
            </Box>
            <Box mt={5}>

            </Box>
          </ThemeProvider>
        </Grid>

      </Grid>
    );
  }
}

export default Aviso_legal;