import React from 'react';

import { Breakpoint } from 'react-socks';

import './Header.scss';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles} from '@material-ui/core/styles';

export function llevarFoco(ventana1) {
  var ventana = document.getElementById(ventana1);
 if(ventana){ 
 
 if(ventana.id==='ventana1'){
//  ventana.style.marginTop = "15px";
  
  ventana.scrollIntoView({behavior: "smooth"});

  //ventana.scrollTop = ventana.offsetTop+10;
  }else{

    ventana.scrollIntoView({behavior: "smooth"});
  }
 
   
  }
}

export const BootstrapButton = withStyles({
  root: {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '2.5vh',
    color:'white',
    height:'7vh',
    maxWidth:'30vh',
 //   border: '1px solid',
 //   lineHeight: 1.5,
    backgroundColor:'transparent',
    //borderColor: '#0063cc',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      backgroundColor: 'rgb(255, 255, 255, 0.5) !important',
     // borderColor: '#0062cc',
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: 'rgb(255, 255, 255, 0.5)',
     // borderColor: '#005cbf',
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgb(100, 165, 120,.5)',
      backgroundColor: 'rgb(255, 255, 255, 0.5)',
    },
  },
})(Button);


const Header = () => {
  
  return (
 //<div className="header">   
    <nav className="navbar-wrapper" id="cabecera" >

      <Breakpoint large up>
        <ul className="navbar-links" id="cabe2">
          <p>
           
            <img src={require('../../../Imagenes/LOGOC365.png')}  className='img2' alt="logo" />
           
          </p>
      
          <div className='wrapper'>
         

                 <div >
                  <Tooltip  title="">
                  <BootstrapButton id='boton1' fullWidth={true} onClick={(e) => { llevarFoco('ventana1') }} >Quienes somos</BootstrapButton>
                  </Tooltip>
                  </div>
                <div >
                  <Tooltip  title="">
                  <BootstrapButton id='boton2' onClick={(e) => { llevarFoco('ventana2') }}>Producto</BootstrapButton>
                  </Tooltip>
                </div>
          
                <div >
                  <Tooltip  disableTouchListener title="">
                  <BootstrapButton id='boton3' onClick={(e) => { llevarFoco('ventana3') }}>Ventajas</BootstrapButton>
                  </Tooltip>
                </div>
               
                <div>
                  <Tooltip  disableTouchListener title="">
                  <BootstrapButton id='boton4' onClick={(e) => { llevarFoco('ventana4') }}>Valores</BootstrapButton>
                  </Tooltip>
                </div>

                <div>
                  <Tooltip  disableTouchListener title="">
                  <BootstrapButton  id='boton5'onClick={(e) => { llevarFoco('ventana5') }}>Contratación</BootstrapButton>
                  </Tooltip>
                </div>
                <div >
                  <Tooltip  disableTouchListener title="">
                  <BootstrapButton  id='boton6'onClick={(e) => { llevarFoco('ventana6') }}>Medio ambiente</BootstrapButton>
                  </Tooltip>
                </div>
                <div >
                  <Tooltip  disableTouchListener title="">
                  <BootstrapButton  id='boton7'onClick={(e) => { llevarFoco('ventana7') }}>Equipo</BootstrapButton>
                  </Tooltip>
                </div>
               
                <div >
                  <Tooltip  disableTouchListener title="">
                  <BootstrapButton  id='boton8'onClick={(e) => { llevarFoco('ventana8') }}>Actualidad</BootstrapButton>
                  </Tooltip>
                </div>
              
          </div>
        </ul>
      </Breakpoint>
    

      
    </nav>
  
 //   </div>
  );
};





export default Header;