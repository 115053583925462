
import React from 'react';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import  green from '@material-ui/core/colors/green';
import ListItemText from '@material-ui/core/ListItemText';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import MovieIcon from '@material-ui/icons/Movie';

export function traerTutoriales(tis,tabla ){
    return new Promise ((resolve,reject)  => {
        traerManuales(tis, tabla).then((data)=>{
         traerManualesLink(tis,tabla).then((data2)=>{
            const Pdfs1 = (data.Pdfs ? data.Pdfs: [])
            const Pdfs = (data2? Pdfs1.concat(data2):Pdfs1)
            resolve(Pdfs)
         })
        })

    })
}


export function traerManualesLink(tis, tabla){

  var app22 ={}
  const apps = tis.props.firebase.app.apps
  if (apps.length > 1) {
    const App22  = apps.filter(ap=>ap.name_==='app22')
    if(App22&&App22.length>0){app22= App22[0]}
    else{
      app22 = tis.props.firebase.app.initializeApp({
        databaseURL: 'https://dogarfirbase.firebaseio.com'
      }, 'app222');
      
    }
  }else{  
    app22 = tis.props.firebase.app.initializeApp({
      databaseURL: 'https://dogarfirbase.firebaseio.com'
    }, 'app222');
  }
    const firebase =tis.props.firebase.app.database(app22);
    return new Promise ((resolve,reject)  => {
      firebase.ref("Manuales/"+tabla).once('value', snapshot => {
        const usersObject = snapshot.val();
        if(usersObject){resolve(usersObject)}
        else{resolve(false)}    
        app22.delete()
     })
  
    })
  }

  
export function traerManuales(tis, tabla){

    var carpetas=[];
    var pdfs=[];
       
    const storage2 = tis.props.firebase.app.storage()
  
  
    let listRef = storage2.ref().child('Manuales/'+tabla)
   
    return new Promise ((resolve,reject)  => {
   
  
    listRef.listAll()
    .then((res)=> {
        res.prefixes.forEach(folderRef =>{
          let num =1;
          if(folderRef.name!=='logo'){
            carpetas.push({
              id: num,
              Nombre: folderRef.name,
              path:folderRef.location.path,
              image: require('../../Imagenes/Files-icon.png'),  
            });
            num++;
    
          }
        })
          
        res.items.map(itemRef => 
          pdfs.push({
              items:itemRef
  
          })          
        )
    
        
       carpetas.sort(function (A, B) {
        var a =Number(A.Nombre.trim())
        var b =Number(B.Nombre.trim())
        if(!a){a=100000}
        if(!b&&b!==0){b=100000}
        if( a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
  
        }
        return 1
      })
        tis.setState({carpetas:carpetas})
        if(pdfs&&pdfs.length>0){
          traerPDFS(tis, pdfs)
          .then(data => {
            const registro = {'carpetas':carpetas , Pdfs:data}
            resolve(registro)
          })
        }else{resolve([])}
     })
     .catch((e)=>{
     reject (e)})
  
  
    })
  
  }

  export function traerPlantillas(tis, fichero){

    var carpetas=[];
    var pdfs=[];
       
    const storage2 = tis.props.firebase.app.storage()
  
  
    let listRef = storage2.ref().child('Manuales/Plantillas/')
   
    return new Promise ((resolve,reject)  => {
   
  
    listRef.listAll()
    .then((res)=> {
        res.items.map(itemRef =>{
          const item = itemRef.location.path_
          if(item==='Manuales/Plantillas/'+fichero){
         
          pdfs.push({
              items:itemRef
  
          })}
                  }
        )
    
        
        if(pdfs&&pdfs.length>0){
          traerPDFS(tis, pdfs)
          .then(data => {
       
            resolve(data[0])
          })
        }else{resolve([])}
     })
     .catch((e)=>{
     reject (e)})
  
  
    })
  
  }

function traerPDFS  (tis, items){

    return new Promise(resolve =>{ 
    if(items.length>0){ 
      const storage2 = tis.props.firebase.app.storage()
      
      let listRef2 = storage2.ref()
   
      var pdfs=[];
      let promises = items.map((itemRef) => {
       return   listRef2.child(itemRef.items.location.path).getDownloadURL() 
      });
    
        
      Promise.all(promises).then((downloadURLs) => {
       
          var i=0;
         items.forEach(itemRef => {
             pdfs.push({
             id: i,
             Nombre: itemRef.items.name,
             url: downloadURLs[i] ,
             path: itemRef.items.location.path,
         //  fullpath: itemRef.items.fullPath,
             Video:false,
             image: require('../../Imagenes/Mimetypes-pdf-icon.png'),  
           })
           i++;
           
         })
             pdfs = pdfs.filter(file => file.Nombre !== "temporal.txt")
             tis.setState({Pdfs:pdfs})
             tis.setState({abrirBackdrop:false})
             resolve(pdfs)
        
       })
      
   
     }else {tis.setState({abrirBackdrop:false})
     }
    
    
    })
    
   }
 
const ModalTutoriales = (pdfs) => { 
  return(   
    <Paper style={{ maxHeight: 500, overflow: 'auto',justifyContent:'center' }}>
      <h5  style={{ color: 'green', textAlign: 'center', alignSelf: 'center'}}>
        CONSULTA DE MANUALES</h5>
      <Paper style={{maxHeight: 200, overflow: 'auto'}}>  
   
        <List component="nav" dense="true"  aria-label="main mailbox folders" justify="space-around" alignItems="center" >
          {pdfs.Pdfs && pdfs.Pdfs.map((pdf,index) => (                  
         <Grid>
           <Divider />
          
        
           <ListItem
              //   selected={this.selectedIndex === pdf.Nombre}
                 onClick={() => {window.open(pdf.url) }}
                 >
         
               <ListItemAvatar>
                   <Avatar  style ={{color:'lightgreen', backgroundColor:green[500]}}>  
                    {pdf.Video ? <MovieIcon/>:<InsertDriveFileIcon/>}
                     
                   </Avatar>
                 </ListItemAvatar>

               <ListItemText primary={pdf.Nombre}/>
        
             </ListItem>                            
      
         </Grid>
          ))}
         
        </List>
      
      </Paper>    


   </Paper>
)}

export default (ModalTutoriales)