export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const INCI = '/inci';
export const MASTER = '/datosmaestros';
export const PASSWORD_FORGET = '/pw-forget';
export const ACTIVIDADES = '/actividades';
export const COMUNIDADES = '/comunidades';
export const GREMIOS = '/gremios';
export const DOCUMENTOS = '/documentos';
export const USUARIOS = '/usuarios';
export const ROLES = '/roles';
export const ZONAS = '/zonascomunes';
export const VOTACION = '/votaciones';
export const JUNTA = '/junta';
export const JUNTASVIRTUALES = '/virtuales';
export const CATEGORIAS = '/categorias';
export const PRIVACIDAD = '/privacidad';
export const AVISO_LEGAL = '/aviso_legal';
export const POLITICA_COOKIES = '/politica_cookies';
export const POLITICA_PRIVACIDAD = '/politica_privacidad';
export const POLITICA_PRIVACIDAD_HABI = '/politica_privacidad_pro';
export const LOPD = '/lopd';
export const SUPERUSER = '/superuser';
export const FACTURACION ='/facturacion'
export const PORTA = '/portalpruebammm';

export const INCI_GREMIOS = '/inci_gremios/:id'


export const CONTACTO= '/contact/:contactId'

export const LISTA_CONTACTO= '/contact-list'

export const FORM_CONTACTO= '/contact-form'
export const ACUERDO= '/deal/:dealId'
export const LISTA_ACUERDO= '/deal-list'
export const FORM_ACUERDO= '/deal-form' 

export const CONECTA= '/conecta' 
