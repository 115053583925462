import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { getDocuments } from '../../firebase/firebase.utils';

import CustomButton from '../../components/custom-button/custom-button.component';
import Header from '../../components/header/header.component';
import './contact-list.styles.scss';

import { withFirebase } from '../../../Firebase';
import { withAuthorization } from '../../../Session';
const ContactList= props => {
  const type = 'contact';

  const [contactListState, setContactListState] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const contacts = await getDocuments(null, type, props.firebase);
      
      setContactListState(contacts);
    }
    getData();
  }, []);

  const getTableData = contacts =>
    contacts.map(contact => (
      <tr key={contact.id}>
        <td><Link to={`/contact/${contact.id}`}>{contact.name}</Link></td>
        <td>{contact.company}</td>
        <td>{contact.email}</td>
        <td>{contact.position}</td>
      </tr>
    ));

  return (
    <>
    <Header/>
    <div className='contact-list-page'>
      <Link className='option' to='/contact-form'>
        <CustomButton customClass='action-button'> Añadir Lead </CustomButton>
      </Link>
      <div className='contact-list-table'>
        <table className='table'>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Compañía</th>
              <th>Email</th>
              <th>Posición</th>
            </tr>
          </thead>
          <tbody>
            {getTableData(contactListState)}
          </tbody>
        </table>
      </div>
    </div>
    </>
  )
}



const condition = authUser => !!authUser;
export default withFirebase(withAuthorization(condition)(ContactList));
