
import React, { Component,forwardRef } from 'react';
import ValidateIBAN from './validadIBAN'
import {InputLabel,TextField,Select,Checkbox,FormControl,Chip,MenuItem,FormControlLabel} from '@material-ui/core';

import {AddBox,ArrowDownward, Check,ChevronLeft,ChevronRight,Clear,DeleteOutline
  ,Edit,FilterList,FirstPage,LastPage,Remove,SaveAlt,Search,ViewColumn} from '@material-ui/icons'
  import CloseIcon from '@material-ui/icons/Close';
  import SendIcon from '@material-ui/icons/Send';
  import SearchIcon from '@material-ui/icons/Search';


  
export const Textofield = ( Nombre,helperTexto, bloquear, maxLongitud,tthis) =>  
     
<TextField InputLabelProps={{ shrink: true }} //inputProps={{ type: 'number'}}
       disabled={bloquear}
       multiline
       color="primary"
       margin="normal"
       label={helperTexto}
       inputProps={{ maxLength: maxLongitud }}
       fullWidth
       id={Nombre}
       name={Nombre}
       maxRows={5}
       value={tthis.state[Nombre]}
       type="text"
      onChange={e => {tthis.setState({ [e.target.name] : e.target.value })
     
         if(e.target.name==='Codigo_seguridad'){
           tthis.setState({Codigo_vecino:tthis.state.despacho.Codigo+tthis.state.Id+e.target.value})
         }


       }}
     />


export const TextofieldIBAN = ( Nombre,helperTexto, bloquear, maxLongitud,tthis, errorIban) =>  
     
<TextField InputLabelProps={{ shrink: true }} //inputProps={{ type: 'number'}}   
       disabled={bloquear}
       multiline
       error={tthis.state[errorIban]}
       color="primary"
       margin="normal"
       label={helperTexto}
       inputProps={{ maxLength: maxLongitud }}
       fullWidth
       id={Nombre}
       name={Nombre}
       helperText={tthis.state['textoError']}
       maxRows={5}
       value={tthis.state[Nombre]}
       type="text"
      onChange={e => {
        let IBAN= e.target.value
       IBAN=IBAN.toString()
       IBAN=IBAN.trim()
        tthis.setState({ [e.target.name] : IBAN.toUpperCase() })
     
        const esbueno= ValidateIBAN(IBAN) || IBAN===''
         if(esbueno){
           tthis.setState({[errorIban]:false,textoError:''})
         }else{ tthis.setState({[errorIban]:true,textoError:'Formato incorrecto'})}

    //     if(e.target.name==='IBAN'&&!esbueno&&tthis.state.Pago_App===true){
    //      alert('Para que se pueda aplicar el pago de la app por parte de la comunidad, es necesario que tenga el iban de la comunidad relleno')
    //    }
       }}
     />

  
export const Textofield3 = ( Nombre,helperTexto, bloquear, maxLongitud,tthis,type) =>  
     
<TextField InputLabelProps={{ shrink: true }} 
     fullWidth
     color="primary"
     disabled={bloquear}        
     required
     id={Nombre}
     margin="normal"
     inputProps={{ maxLength: maxLongitud }}
     name={Nombre}
     label={helperTexto}
     type={type}
     value={tthis.state[Nombre]}
     
     onChange={e => {
   
      
      tthis.setState({ [e.target.name] : e.target.value })
     
   

  
   }}
     
       
     />
 export const Textofield2 = ( Nombre,helperTexto, bloquear, maxLongitud,tthis,type,defaultValue) =>  

     <TextField
     fullWidth
     color="primary"
     disabled={bloquear}        
     required
     id={Nombre}
     margin="none"
     inputProps={{ maxLength: maxLongitud }}
     name={Nombre}
     label={helperTexto}
     type={type}
     value={tthis.state[Nombre]}
     
     onChange={e => {
       
      if(e.target.name === 'numreservasslot'&&Number(e.target.value)!==1){
        tthis.setState({slots_seguidos:1})
      }
      
      tthis.setState({ [e.target.name] : e.target.value })
     
     if(e.target.name==='Codigo_seguridad'){
       tthis.setState({Codigo_vecino:tthis.state.despacho.Codigo+tthis.state.Id+e.target.value})
     }

  
   }}
      defaultValue={defaultValue}
       
   />

export const selec_lectura = (nombre, rowData,bloquear) =>     <Select
labelId="demo-mutiple-chip"
disabled={bloquear}
multiple
value={rowData[nombre]}
renderValue={(selected) =>{ 
  if(!selected || selected.length===0){
    selected= selected.concat({0:''})
  }
  return(
  <div style={{  display: 'flex', flexWrap: 'wrap',}}>
    {selected.map((value) => (
      <Chip variant="outlined" color='primary' key={value} label={value} 
      style={{margin:6}}/>
    ))}
  </div>
)}}>
</Select>


export const selecto = (original,nombre,onRowDataChange,rowData,tthis, bloquear,menu) =>    {
    
    const menu2= (menu ? menu:[])
    let Nombre =(onRowDataChange ? rowData[nombre] : tthis.state[nombre])
 
    return(
    <FormControl fullWidth>
    <Select
       multiple
       value={Nombre}
       disabled={bloquear}
       onChange={e => {
         var newRowData = { ...rowData, [original]: e.target.value };
         newRowData[original] = e.target.value 
        
         if(newRowData[original].length>1){
           newRowData[original]=newRowData[original].filter((rol)=>rol!=='')
         }else {  newRowData[original].concat({0:''})}
         
         if(onRowDataChange){

            rowData[original] = e.target.value 
            onRowDataChange(newRowData);
         }else{
             tthis.setState({[nombre]:newRowData[original]})
         }
       
       }
     }
     renderValue={(selected) =>{ 
      if(!selected || selected.length===0){
        selected= selected.concat({0:''})
      }
      return(
      <div style={{  display: 'flex', flexWrap: 'wrap',}}>
        {selected.map((value) => (
          <Chip variant="outlined" color='primary' key={value} label={value} 
          style={{margin:6}}/>
        ))}
      </div>
    )}}>
      {menu2.map((ce) => (
         <MenuItem key={ce.Nombre} value={ce.Nombre} >
           {ce.Nombre}
         </MenuItem>
       ))}

         
     </Select>
   </FormControl>)
}

export const checkBoxLectura = (chequeo) =>
    <Checkbox                                    
    checked={chequeo}
    color= "primary"

    />

export const selectStandar= (tthis,valor,Nombre,names,listaMenu,valordefecto,bloquear)=>
  <FormControl  fullWidth >
  <InputLabel htmlFor="age-native-simple">{Nombre}</InputLabel>
  
  <Select
  defaultValue={valordefecto}
  disabled ={tthis.state.modificar||bloquear}
  value={valor}
  name={names}


  onChange={tthis.onChange}  
  >{listaMenu}
  

  </Select>
  </FormControl> 

export const checkBoxModificar = (nombre,disabled, rowData, onRowDataChange,tthis) =>{
const Nombre= 'm'+nombre
return(
<Checkbox
disabled={disabled}
checked={(onRowDataChange ? rowData[nombre]:tthis.state[Nombre] )}
color= "primary"
name={(onRowDataChange ? nombre:Nombre )}
onChange={e => {
if(onRowDataChange){


  let newRowData = { ...rowData, [nombre]: e.target.checked };
  rowData[nombre]= e.target.checked 
  if(e.target.name==='Todo'){
    newRowData.Incidencias= e.target.checked
    newRowData.AdminCon= e.target.checked
    newRowData.Cambios= e.target.checked
    newRowData.Siniestros= e.target.checked
    newRowData.Seguros= e.target.checked
  }

  onRowDataChange(newRowData);
}else{
    const pepe= 'm'+nombre
    tthis.setState({[pepe]:e.target.checked})
    if(e.target.name==='mTodo'){

      tthis.setState({mIncidencias:e.target.checked,mSiniestros:e.target.checked,mAdminCon:e.target.checked,
        mCambios:e.target.checked,mSeguros:e.target.checked})
    }
    else if(e.target.name==='mPublicar'){
      if(e.target.checked){alert('Al activar esta opción la incidencia será publica para todos los vecinos de la comunidad. Cualquier modificación y posterior envío de aviso se realizará a todos los propietarios registrados.')}
      else{alert('Al  desactivar esta opción la incidencia únicamente será visible para el usuario que la registre, así como para el administrador.')}
    }


}
}}
/>)}

export const selecSimple = (nombre,label,tthis)=>
  <FormControl fullWidth margin='normal'>
    <InputLabel shrink htmlFor="select-multiple-native">
            {label}
    </InputLabel>
    <Select
        value={tthis.state[nombre]}
        name={nombre}
        onChange={e => {
          if(e.target.name==='mComunidad'){
        
            tthis.setState({[nombre]:e.target.value,mDireccion:''})
          }
     }}    >
      {tthis.state.comus&&tthis.state.comus.map((comu) => (
              <MenuItem value={comu.Id} >
                  {comu.Id}
               </MenuItem>
         ))}
   </Select>
   </FormControl>

export const checkBoxStandar = (Nombre, etiqueta,tthis,modificar)=>
  <FormControlLabel fullWidth
  disabled ={modificar}
  control={
  <Checkbox 
  checked={tthis.state[Nombre]}
  name={Nombre}
    color="primary"
    onChange={(e)=>{ tthis.setState({[Nombre]: e.target.checked})
    if(e.target.name==='consumirReservaHoraActual'){
      tthis.setState({NoconsumirStockDia: false})
    
    }
    const IBAN=tthis.state.IBAN.trim()
    const esbueno= ValidateIBAN(IBAN) || IBAN===''
    if(e.target.name==='Pago_App'&& e.target.checked===true&&!esbueno){
      alert('Para que se pueda aplicar el pago de la app por parte de la comunidad, es necesario que tenga el iban de la comunidad relleno')
    }
  }}
    />}
  label={etiqueta}
/>


export const tablaIconos ={

    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  
  
}