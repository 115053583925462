import React from 'react';
import PasswordForgetForm from '../PasswordForget';
import PasswordChangeForm from '../PasswordChange';
import { AuthUserContext, withAuthorization } from '../Session';

const AccountPage = () => (
  <AuthUserContext.Consumer>
  {authUser => (
      <div>
      
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', flexDirection:'row'}}>
            <div style={{margin:0, textAlign:'center'}}>
      
          <h1>Grestiona tu cuenta: {authUser.email}</h1>

          <PasswordForgetForm />
          <PasswordChangeForm />
        </div>
        </div>
        </div>
    )}
    </AuthUserContext.Consumer>
);
const condition = authUser => !!authUser;
export default withAuthorization(condition)(AccountPage);