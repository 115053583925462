import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes'; 

import { withFirebase } from '../Firebase';
import MaterialTable, { MTableBodyRow } from 'material-table'
import { withAuthorization } from '../Session';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

import Snackbar from '@material-ui/core/Snackbar';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';

//import ModidyIcon from '@material-ui/icons/Modify';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SendIcon from '@material-ui/icons/Send';
import { crearEstructurDirectorios2 } from '../DatosMaestros/documentos'
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import FolderIcon from '@material-ui/icons/Folder';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { forwardRef } from 'react';
import Fab from '@material-ui/core/Fab';

import RootRef from "@material-ui/core/RootRef";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import BotonesTriger from '../Botones/botonTrige'
import Cabecera from '../Cabecera/index_365'
import NoteAddIcon from '@material-ui/icons/NoteAdd';         
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import  {cargarResultado,enviarDispositivosAdmin} from '../DatosMaestros/enviarAvisos'

import FormHelperText from '@material-ui/core/FormHelperText';
import { createTheme  } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import green from '@material-ui/core/colors/green';

import firebase2 from 'firebase'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import { DropzoneArea } from "material-ui-dropzone";

import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import Modal from '@material-ui/core/Modal';


import Copyright from '../Privacidad/copyright';

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)"
  })
});

const theme = createTheme ({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
      claro: green[200]
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#e81446',
    },
  },
});
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};

class Lopd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abrirBackdrop: false,
      comus_enviar: [],
      directorio:"",
      directorioPrincipal:"",
      Ref_padre: [],
      PathDirectorioActual:"",
      dataCategoria3: [],
      abrir_documentos:false,
      abrir_vista_previa: false,
      ficheroCsv: [],
      abrir_direcciones: false,
      direcciones: [],
      texto_titulo:'',
      texto_envio:'',
      resumen_carga: false,
      resumen_lista: [],
      abrir_zonas: false,
      zonas: [],
      listaZonasRecuperadas: [],
      //ListaActivComunidad: [],
      nombreZona: '',
      uid: "",
      gremio: '',
      direccion: "",
      incis: [],
      comus: [],
      comus2: [],
      mostrarAyuda: false,
      open: false,
      comunidad: '',
      tipo: 'guardar',
      gremio_poliza: [],
      actividad: '',
      identificador: '',
      mostrarTabla: true,
      selectedIndex: '',
      email: '',
      comunidadBorrar: "",
      password: '',
      error: null,
      error2: null,
      modificar: true,
      listaComunidades: [],
      ComunidadesAsig: [],
      ComunidadesNoAsig: [],
      tenemosLista: false,
      leftChecked: [],
      rightChecked: [],
      left: [1, 2, 3, 4],
      right: [5, 6, 711],
      checked: [],
      snack: false, snack2: false, vertical: 'top',
      horizontal: 'center',
      identIncial: '',
      ListaActivComunidad: [],
      direccionfinal: '',
      lista_actividades: {},
      abrirDescrip: false,
      descripicion: "",
      descripcionfinal: '',
      abrirAviso: false,
      usuarioLogueado: '',
      usuarios: [],
      comunidades_enviar:[],
      dispostivos: [],
      usuariosComu: [],
    
      zonascomunes: [],
      categorias: [],
      rowData: [],
      usuariosTotal:[],
      index: '',
      despacho:
      {
        "Nombre": "",
        "CIFNIF": "",
        "Codigo": "",
        "Fecha_registro": "",
        "Telefono1": "",
        "Telefono2": "",
        "Correo": "",
        "Direccion": "",
        "Cp": "",
        "Poblacion": "",
        "Persona": "",
        "Logo": "",
        "Iban": "",
        "uid": ""
      },
      resultado_aviso:[],  
      avisos_enviados:false
    };
    this.BuscarComus = this.BuscarComus.bind(this);
   
    this.buscarComunidades = this.buscarComunidades.bind(this);
    this.handleClose = this.handleClose.bind(this)
    this.traerUsuarioLogueado = this.traerUsuarioLogueado.bind(this)
    this.comprobarCif = this.comprobarCif.bind(this)
    this.buscarDespacho = this.buscarDespacho.bind(this)
   

  }

  handleOnDrop = (data) => {

    var listaComunidades = []
    data.forEach(comunidad => {
      if (comunidad.data[0] && comunidad.data[0] !== '') {
        listaComunidades = listaComunidades.concat({
          Nombre: comunidad.data[0], Cif: comunidad.data[1], Id: comunidad.data[2],
          Codigo_seguridad: comunidad.data[3], Categorias: comunidad.data[4], Direcciones: comunidad.data[5],
          Cp: comunidad.data[6], Poblacion: comunidad.data[7], Seguro: comunidad.data[8],
          Poliza: comunidad.data[9], IBAN: comunidad.data[10],Vecinos:comunidad.data[11],
           Observaciones: comunidad.data[12]
        })

      }

    })

    this.setState({ abrir_vista_previa: true, ficheroCsv: data, ficheroList: listaComunidades })
  }

  handleOnError = (err, file, inputElem, reason) => {
    console.log(err)
    alert(err, file, inputElem, reason)
  }
  handleClickOpen = (pdffile) => () => {
       
    window.open(pdffile.url);  
  
    
  }    
  handleClickBorrar = (path) => {
    var respuesta = window.confirm("¿Quieres borrar el directorio "+ path + "?")
      if(respuesta){
          this.setState({abrirDialog:false})  
            let lista =  this.state.dataCategoria3
            lista = lista.filter(directorio => directorio.path !== path)
            this.setState({dataCategoria3:lista})  
    
          let ref = this.props.firebase.storage_propio_completo().child(path);
            let Ref = this.props.firebase.storage_propio_completo();
            const p0 = new Promise ((resolve,reject)  => {  
              resolve()
              ref.listAll()
              .then(dir => {
                dir.items.forEach(fileRef => {
                //  this.deleteFile(ref.fullPath, fileRef.name);
                  let ref1 = Ref.child(ref.fullPath);
                  const childRef = ref1.child(fileRef.name);
                  childRef.delete()
                });
                dir.prefixes.forEach(folderRef => {
                  this.handleClickBorrar(folderRef.fullPath);
                })
              })
              .catch(error => {
                  alert(error);
              })
            })
            p0.then( (data) => { 
                // this.buscarficheros("")
                })
        }    
    };  
handleClickBorrarFichero = (path) => {
    var respuesta = window.confirm("¿Quieres borrar el fichero "+ path + "?")
      if(respuesta){
        const rowData=this.state.rowData
        let ref = this.props.firebase.storage_propio_completo().child('LOPD/'+rowData.Id+"/");
            const p0 = new Promise ((resolve,reject)  => {  
              resolve( ref.child(path).delete());
           
              })
              .catch(error => {
                  alert(error);
              })
            
        p0.then( (data) => { 
                this.buscarficheros('LOPD/'+rowData.Id+"/")
                })
        }    
  };
onDrop(file) {
    this.setState({open:true})
      const putData = async () => {
        return Promise.all(file.map(fichero =>  
          this.props.firebase.storage_propio_completo(this.state.PathDirectorioActual+"/"+fichero.name).put(fichero)
          ))
      }

      putData().then(data => {
        this.buscarficheros(this.state.PathDirectorioActual,this.state.directorioPrincipal)
       // this.setState({open:false})
                      
      })
  
 // }
}

handleClickClose = () => {this.setState({abrirDialog:false, nombreDirectorio:""})       }; 
handleClickCloseOk = () => {
  this.setState({abrirDialog:false})  
   crearEstructurDirectorios2(this.state.PathDirectorioActual,this.state.directorio, this.state.directorioPrincipal, 
     this.props.firebase,true).then(()=>
  { this.buscarficheros(this.state.PathDirectorioActual,this.state.directorioPrincipal)}) 
} 

traerPDFS(items){
    if(items.length>0){ 
     const listRef2 = this.props.firebase.storage_propio_completo()
     var pdfs=[];
     let promises = items.map((itemRef) => {
       return   listRef2.child(itemRef.items.location.path).getDownloadURL() 
     });
     Promise.all(promises).then((downloadURLs) => {
       var i=0;
       items.forEach((itemRef) => {
             pdfs.push({
             id: i,
             Nombre: itemRef.items.name,
             url: downloadURLs[i] ,
             path: itemRef.items.location.path,
         //  fullpath: itemRef.items.fullPath,
             image: require('../../Imagenes/Mimetypes-pdf-icon.png'),  
           })
           i++;
         })
           pdfs = pdfs.filter(file => file.Nombre !== "temporal.txt")
             this.setState({Pdfs:pdfs})
             this.setState({open:false,abrir_documentos:true,mostrarTabla:true })
       });
   
     }else (this.setState({open:false,abrir_documentos:true,mostrarTabla:true})
     )
       //    this.setState({PDFS:pdfs})
    
   }


buscarPadre = (listRef)   => () =>{
  this.setState({open:true,abrir_documentos:false,mostrarTabla:false})
    let nom= listRef.name
    if(nom===""){nom="Directorio Raiz"
      this.setState({lacomunidad:''})
    }
  
    let path= listRef.fullPath
    this.setState({Pdfs:[], dataCategoria3:[], directorioPrincipal:nom,PathDirectorioActual:path})
    var carpetas=[];
    var pdfs=[];
    let Ref_padre = listRef.parent;
   
    this.setState({Ref_padre:Ref_padre})
  
  
  
    const p0 = new Promise ((resolve,reject)  => {
  
      listRef.listAll().then(function(res) {
        res.prefixes.forEach(function(folderRef) {
          let num =1;
          if(folderRef.name!=='logo'){
              carpetas.push({
                id: num,
                Nombre: folderRef.name,
                path:folderRef.location.path,
                image: require('../../Imagenes/Files-icon.png'),  
              });
        }
          num++;
        
        })
          
        res.items.forEach(function(itemRef) {
          pdfs.push({
              items:itemRef
  
          })          
        })
    
        
          resolve(pdfs)
          
        })
       
    
    })
    p0.then( (data) => { 
                  this.setState({dataCategoria3:carpetas})
                  // this.setState({mostrarTabla:true, open:false});
               //    this.setState({PDFS:pdfs})
                   this.traerPDFS(pdfs)
  
      })
  
  }

buscarficheros (Path, nombre) {
    this.setState({open:true,abrir_documentos:false,mostrarTabla:false})
    if(this.state.directorioPrincipal==="Directorio Raiz"&&nombre!=="Directorio Raiz"){
      this.setState({lacomunidad:nombre})
  
    }
   
  
    this.setState({Pdfs:[], dataCategoria3:[], directorioPrincipal:nombre})
 //   this.setState({abrirBackdrop:true})
 this.setState({mostrarTabla:false, open:true});
    var carpetas=[];
     var pdfs=[];
       
    let listRef = this.props.firebase.storage_propio_completo().child(Path);
    
    let Ref_padre = listRef.parent;
    this.setState({Ref_padre:Ref_padre, PathDirectorioActual:Path})
    const p0 = new Promise ((resolve,reject)  => {
  
      listRef.listAll().then(function(res) {
        res.prefixes.forEach(function(folderRef) {
          let num =1;
          if(folderRef.name!=='logo'){
            carpetas.push({
              id: num,
              Nombre: folderRef.name,
              path:folderRef.location.path,
              image: require('../../Imagenes/Files-icon.png'),  
            });
            num++;
    
          }
        
        })
          
        res.items.forEach(function(itemRef) {
          pdfs.push({
              items:itemRef
  
          })          
        })
    
        
          resolve(pdfs)
          
        })
       
    
    })
    p0.then( (data) => { 
                  this.setState({dataCategoria3:carpetas})
                 
                   this.traerPDFS(pdfs)
                //   this.setState({mostrarTabla:true, open:false});
  
    })
  
  }
 
handleOnRemoveFile = (data) => {

    console.log(data)
  }

  handleChangeCat = (oldData) => (event) => {
    var newData = oldData
    newData.categorias = event.target.value;
    newData.categorias = newData.categorias.filter(rol => rol !== '');
    oldData.categorias = newData.categorias
    // data[data.indexOf(oldData)] = newData;
   // this.setState({ categorias: data });

    //  setPersonName(event.target.value);
  }

  buscarDespacho() {

    var despacho = this.state.despacho


    this.props.firebase.despacho().once('value', snapshot => {
      const usersObject = snapshot.val();
      despacho.Nombre = usersObject.Nombre
      despacho.CIFNIF = usersObject.CIFNIF
      despacho.Codigo = usersObject.Codigo
      despacho.Fecha_registro = usersObject.Fecha_registro
      despacho.Telefono1 = usersObject.Telefono1
      despacho.Telefono2 = usersObject.Telefono2
      despacho.Correo = usersObject.Correo
      despacho.Direccion = usersObject.Direccion
      despacho.Cp = usersObject.Cp
      despacho.Poblacion = usersObject.Poblacion
      despacho.Persona = usersObject.Persona
      despacho.Logo = usersObject.Logo
      despacho.Iban = usersObject.Iban
      despacho.uid = snapshot.key
    }).then(() => this.setState({ despacho: despacho }))


  }
  handleClose() {
    this.setState({ snack: false, snack2: false })
  };
 
  comprobarCif(cif) {
    var CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
    var match = cif.match(CIF_REGEX);
    if (!match) { return false }
    var letter = match[1], number = match[2], control = match[3];
    var even_sum = 0;
    var odd_sum = 0;
    var n;

    for (var i = 0; i < number.length; i++) {
      n = parseInt(number[i], 10);

      // Odd positions (Even index equals to odd position. i=0 equals first position)
      if (i % 2 === 0) {
        // Odd positions are multiplied first.
        n *= 2;

        // If the multiplication is bigger than 10 we need to adjust
        odd_sum += n < 10 ? n : n - 9;

        // Even positions
        // Just sum them
      } else {
        even_sum += n;
      }

    }
    var control_digit = (10 - (even_sum + odd_sum).toString().substr(-1));
    var control_letter = 'JABCDEFGHI'.substr(control_digit, 1);

    // Control must be a digit
    if (letter.match(/[ABEH]/)) {
      return control === control_digit;

      // Control must be a letter
    } else if (letter.match(/[KPQS]/)) {
      return control === control_letter;

      // Can be either
    } else {
      return control === control_digit || control === control_letter;
    }

  }

  traerUsuarioLogueado() {
    var user = this.props.firebase.auth.currentUser;
    if (user) {
      var usuario = ''
      var usuariosList2 = []
      this.props.firebase.users().once('value', function (snapshot) {
  
        const usersObject = snapshot.val();
        if (usersObject) {
          var usuariosList = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));
          usuariosList2 = usuariosList
          usuariosList = usuariosList.filter(usu => usu.Clave === user.uid)
  
          usuario = usuariosList[0]
        
        
          
  
  
        }
      }).then(() => { this.setState({ usuarioLogueado: usuario, usuarios: usuariosList2 }) })
    }
  }

  componentWillMount() {
    this.props.firebase.comus().off()
    this.props.firebase.categorias().off()
    this.props.firebase.despacho().off()
  }

  componentDidMount() {
    this.props.firebase.despacho().on('value', snapshot => {
      const usersObject = snapshot.val();
      if(usersObject){
          const lopd =  usersObject.Lopd
          if (!lopd) {
            this.props.history.push(ROUTES.SIGN_IN);
        //  this.setState({sin_lopd:false})
          }

      }else{  //this.setState({sin_lopd:true}) 
         this.props.history.push(ROUTES.SIGN_IN);
        
        }
    
     
    })

    this.traerUsuarioLogueado()
    this.buscarDespacho()
  
        this.props.firebase.comus().once('value', snapshot => {

          const usersObject = snapshot.val();
          if (usersObject) {
            var comusList = Object.keys(usersObject).map(key => ({
              ...usersObject[key],
              uid: key,
            }));
            comusList = comusList.filter(comu => comu.Id !== '')
            comusList.sort(function (a, b) {
              if (a.Id > b.Id) {
                return 1;
              }
              if (a.Id < b.Id) {
                return -1;

              }
              // a must be equal to b
              return 0;
            });
            this.setState({ comus2: comusList, comus:comusList,mostrarTabla:true })
          } else { this.setState({ comus2: [] }) }

        })

  }

  buscarComunidades() {
    
    this.props.firebase.comus().once('value', snapshot => {

      const usersObject = snapshot.val();

      if (usersObject) {
        var comusList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
        comusList = comusList.filter(comu => comu.Id !== '')
        comusList.sort(function (a, b) {
          if (a.Nombre > b.Nombre) {
            return 1;
          }
          if (a.Nombre < b.Nombre) {
            return -1;

          }
          // a must be equal to b
          return 0;
        });


      
     this.setState({ comus2: comusList })
        if (this.state.comunidad !== "") {
          comusList = comusList.filter(comunid => comunid.Id === this.state.comunidad)
        }

        this.setState({ comus: comusList });
        //quitamos las de id  negativo -1
        this.filtrarComunidad(comusList);
    
      } else { this.setState({ comus: [] }) }

      this.setState({ mostrarTabla: true, open: false });
    })
  }

  BuscarComus() {
    this.setState({ open: true, mostrarTabla: false, tenemosLista: false })
    this.componentDidMount();
    this.buscarComunidades();
  }

 
  filtrarComunidad(comusList) {
    //quitamos las de código negativo
    var comunidades = comusList.filter(comunidad => comunidad.uid !== -1);
    this.setState({ comus: comunidades })
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  
  };
  handleClickOpenDialog = () => {this.setState({abrirDialog:true})       };
  checkGremioAuxiliar = (oldData) => () => {

    var newData = oldData
    var respuesta = false
    if (newData.Auxiliar === "-1") {
      newData.Auxiliar = "1"
      respuesta = window.confirm("¿Quieres que el Gremio " + oldData.GremioNombre + " pase a ser sobre la actividad "
        + oldData.Nombre + " un gremio Auxiliar?")
    }
    else {
      respuesta = window.confirm("¿Quieres que el Gremio " + oldData.GremioNombre + " pase a ser sobre la actividad "
        + oldData.Nombre + " un gremio Principal?")
      newData.Auxiliar = "-1"
    }
    if (respuesta) {
      var Actividad = this.state.activs.filter(actividad => actividad.Nombre === newData.Nombre)
      var data = this.state.ListaActivComunidad;
      data[data.indexOf(oldData)] = newData;
      this.setState({ ListaActivComunidad: data });
      this.props.firebase.comu( this.state.uid + '/ActivGremioAuxiliar/').update({
        [Actividad[0].uid]: newData.Auxiliar,
      })
      this.componentDidMount();
    }
  }

  dialogoEnvio = () => () => { 
    
    var titulo = 'AVISO LOPD'
    var texto = 'Comunidades incluidas: '
    const comunidades= this.state.comunidades_enviar
    var i =0
    comunidades.forEach((comu)=>{
      i++
      texto = texto + comu.Id
       if (comunidades.length!==i){texto = texto + ' ,'}

    })
    this.setState({ abrirAviso: true , texto_titulo:titulo, texto_envio: texto}) };

  cerrarAviso = () => { this.setState({ abrirAviso: false, texto_envio: '', texto_titulo: '' }) };


  enviarAviso =  () => {
    this.cerrarAviso()
    this.setState({open:true,mostrarTabla:false})
     enviarDispositivosAdmin(this.state.texto_envio,
        this.state.texto_titulo, this.props.firebase,
         "","", "otro",[]).then((data3)=>{  
                        const registro= cargarResultado(data3,[], this.props.firebase,this.state.usuarioLogueado)  
   
                        this.setState({resultado_aviso:registro.archivo_final,avisos_enviados:true,open: false,
                    mostrarTabla:true, abrirBackdrop:false, texto_envio: '', texto_titulo: '', 
                    usuariosTotal:registro.usuariosTotal})   
          
        })
    
              
                
     
  }
  
  render() {

    const comus = this.state.comus
    const { texto_envio,texto_titulo } = this.state;
  
    const vertical = this.state.vertical;
    const horizontal = this.state.horizontal;
    
   
    const resultado_aviso= this.state.resultado_aviso
    var totalMensajesOK= 0
    var totalMensajesKO= 0
    if(resultado_aviso&&resultado_aviso.length>0){
       totalMensajesOK= resultado_aviso.filter((usuario)=>usuario.error==='NO').length
       totalMensajesKO= resultado_aviso.filter((usuario)=>usuario.error==='SI').length
    }
   
    return ( 
      <Grid flex={1}  >
        <Cabecera />
        <hr />
        <Grid style={{ position: 'absolute', right: 20, top: 20 , maxWidth:"15%", maxHeight:"15%"}}>
          <Button variant="contained" color="primary" onClick={() => { this.setState({ mostrarAyuda: true }) }}>
            AYUDA (TUTORIALES)
          </Button>
        </Grid>

        <BotonesTriger />
        <ThemeProvider theme={theme}>
     
       
            <Snackbar anchorOrigin={{ vertical, horizontal }}
              key={`${this.state.vertical},${this.state.horizontal}`}
              open={this.state.snack}
              autoHideDuration={7000}
              onClose={this.handleClose}
              message={this.state.error2}
              action={
                <div>
                  <Button color="primary" size="small" onClick={this.handleClose}>
                    Cerrar
                              </Button>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              }
            />
            <Snackbar anchorOrigin={{ vertical, horizontal }}
              key={`${this.state.vertical},${this.state.horizontal}`}
              open={this.state.snack2}
              autoHideDuration={5000}
              onClose={this.handleClose}
              message={this.state.comunidadBorrar}
              action={
                <div>
                  <Button onClick={this.anularComunidad}>
                    <Fab size="small" color="primary" aria-label="add" variant="extended">
                      <DoneOutlineIcon />
                                        CONFIRMAR
                                       </Fab>
                  </Button>
                  <Button onClick={this.handleClose}>
                    <Fab size="small" color="secondary" aria-label="add" variant="extended">
                      <CloseIcon />
                                        CANCELAR
                                       </Fab>
                  </Button>
                </div>
              }
            />
            <Grid container direction="row" alignItems="center" style={{ marginTop: 0 }} >
              <Grid item xs style={{ marginLeft: 20 }}>
                <FormControl styles={{ minWidth: 120 }} >
                  <Select
                    labelId="comunidad"
                    id="comunidad"
                    name="comunidad"
                    value={this.state.comunidad}
                    onChange={this.onChange}
                  >
                    <option value="">{""}</option>
                    {this.state.comus2 && this.state.comus2.map(comu => (

                      <option value={comu.Id}>{comu.Id}</option>
                    ))
                    }
                  </Select>
                  <FormHelperText>Búsqueda rápida por ID de comunidad</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs style={{ marginLeft: 20 }}>
                <Tooltip title="Recupera las comunidades de la base de datos">
                  <Button onClick={this.BuscarComus} variant="outlined"
                    size="large" color="primary" startIcon={<SearchIcon />}>BUSCAR COMUNIDADES
                  </Button>
                </Tooltip>
                <Backdrop color='primary' open={this.state.open}>
                  <CircularProgress color="primary" />
                </Backdrop>

              </Grid>

              <Grid item xs style={{ marginLeft: 20 }}>
                <Tooltip title="Carpetas para el LOPD propia del Administrador">
                  <span>
                  <Button onClick={(event) =>    this.buscarficheros('LOPD','LOPD')} variant="outlined"
                    //  disabled={!this.state.comus_enviar.length > 0}
                      size="large" color="primary" startIcon={<NoteAddIcon />}>LOPD ADMINISTRADOR
                    </Button>
                  </span>
                </Tooltip>
                <Backdrop color='primary' open={this.state.open}>
                  <CircularProgress color="primary" />
                </Backdrop>

              </Grid>
                 <Grid item xs style={{ marginLeft: 20 }}>
                <Tooltip title="Envía avisos a las comunidades previmante seleccionadas">
                  <span>
                    <Button onClick={this.dialogoEnvio()} variant="outlined"
                      disabled={!this.state.comunidades_enviar.length > 0}
                      size="large" color="primary" startIcon={<SendIcon />}>ENVIAR AVISO
                    </Button>
                  </span>
                </Tooltip>
                <Backdrop color='primary' open={this.state.open}>
                  <CircularProgress color="primary" />
                </Backdrop>

              </Grid>
       

            </Grid>

            {(this.state.mostrarTabla &&
              <Grid  >
                <Paper style={{maxHeight: 1000, overflow: 'auto',  marginLeft:20, marginRight:20}}>                           
        

                  <MaterialTable
                    actions={[
                    { 
                        name: 'adjuntar',
                        icon: () => <NoteAddIcon />,
                        tooltip: 'Subir Documentación',
                        position: 'row',
                        disabled : false, 
                        onClick: (event, rowData) =>{ 
                          this.buscarficheros('LOPD/'+rowData.Id+"/",rowData.Id)
                          this.setState({ rowData:rowData})
                        }
                    },
                    

                    ]}
                    components={{
                      Row: props => {
                        const propsCopy = { ...props };
                        propsCopy.actions.find(a => a.name === 'adjuntar').disabled = !propsCopy.data.Lopd.Contratada;
              //          propsCopy.actions.find(a => a.name === 'account').disabled = propsCopy.data.name !== 'Paper';
                        return <MTableBodyRow {...propsCopy} />
                      }
                    }}
                    options={{
                      exportButton: {
                        csv: true,
                        pdf: false
                      },
                      addRowPosition: 'first',
                      filtering: true,
                
                      maxBodyHeight: 600,
                      pageSize: 15,
                      selection: true,   
                  //    actionsColumnIndex: -1,
                      exportDelimiter: ";",
                      exportAllData:true,
                      pageSizeOptions: [10, 20, 30],
                      headerStyle: {
                        backgroundColor: 'green',
                        color: 'white',
                        paddingTop: 5,
                        paddingBottom: 5
                      },

                    }}
                    //               onRowClick={(event, rowData, togglePanel) =>{ togglePanel()}}
                   

                    icons={tableIcons}
                    onSelectionChange={(rows) => {
                      if (rows.length>0){  this.setState({comunidades_enviar:rows})

                      }else { this.setState({comunidades_enviar:[]})}
                      //alert('You selected ' + rows[0].Nombre + ' rows')}
                      }
                    }
             
                    localization={{
                      body: {
                        editRow: {
                          saveTooltip: "Guardar",
                          cancelTooltip: "Cancelar",
                          deleteText: '¿Quieres borrar la comunidad?'
                        },
                        addTooltip: "Crear una nueva",
                        deleteTooltip: "Borrar",
                        editTooltip: "Modificar"
                      },
                      header: {
                        actions: 'Acciones'

                      },
                       toolbar: {
                                  nRowsSelected: '{0} Usuario(s) seleccionados para el envío de AVISO'
                              },
                    }}
                    title={<h2 style={{ color: 'green' }}>LOPD COMUNIDADES </h2>}
                    columns={[
                      {title: 'Contratada LOPD', field: 'Lopd.Contratada', type: "boolean",

                      render: rowData =>
                        <Checkbox
                          checked={rowData.Lopd.Contratada}
                          color="primary"
                          disabled={true}

                        />,
                      editComponent: ({ onChange, onRowDataChange, rowData, ...p }) =>
                       ( <Checkbox
                          {...p}
                          //    disabled= {!rowData.Roles|| rowData.Roles.length===0}
                          checked={rowData.Lopd.Contratada}
                          color="primary"
                          onChange={e => {
                            const newRowData = { ...rowData};
                            rowData.Lopd.Contratada = e.target.checked
                            onRowDataChange(newRowData);
                          }}
                        />
                      )
                    },
                      {
                        title: 'Nombre', field: 'Nombre',  editable: 'never', cellStyle: {
                          width: 300,
                          minWidth: 300
                        },
                        headerStyle: {
                          width: 300,
                          minWidth: 300
                        }
                      },
                      {title: 'Id Comunidad', field: 'Id', editable: 'never'},
                      {title: 'Contrato Firmado con el Admministrador', field: 'Lopd.Contrato_fir', type: "boolean",

                      render: rowData =>
                        <Checkbox
                          checked={rowData.Lopd.Contrato_fir}
                          color="primary"

                        />,
                      editComponent: ({ onChange, onRowDataChange, rowData, ...p }) =>
                       ( <Checkbox
                          {...p}
                          //    disabled= {!rowData.Roles|| rowData.Roles.length===0}
                          checked={rowData.Lopd.Contrato_fir}
                          color="primary"
                          onChange={e => {
                            const newRowData = { ...rowData};
                            rowData.Lopd.Contrato_fir = e.target.checked
                            onRowDataChange(newRowData);
                          }}
                        />
                      )
                      },
                      {title: 'Firma Presidente', field: 'Lopd.Firma_presi', type: "boolean",

                    render: rowData =>
                      <Checkbox
                        checked={rowData.Lopd.Firma_presi}
                        color="primary"

                      />,
                    editComponent: ({ onChange, onRowDataChange, rowData, ...p }) =>
                     ( <Checkbox
                        {...p}
                        //    disabled= {!rowData.Roles|| rowData.Roles.length===0}
                        checked={rowData.Lopd.Firma_presi}
                        color="primary"
                        onChange={e => {
                          const newRowData = { ...rowData};
                          rowData.Lopd.Firma_presi = e.target.checked
                          onRowDataChange(newRowData);
                        }}
                      />
                    )
                      },
                      {title: 'Personal', field: 'Lopd.Personal', type: "boolean",

                  render: rowData =>
                    <Checkbox
                      checked={rowData.Lopd.Personal}
                      color="primary"

                    />,
                  editComponent: ({ onChange, onRowDataChange, rowData, ...p }) =>
                   ( <Checkbox
                      {...p}
                      //    disabled= {!rowData.Roles|| rowData.Roles.length===0}
                      checked={rowData.Lopd.Personal}
                      color="primary"
                      onChange={e => {
                        const newRowData = { ...rowData};
                        rowData.Lopd.Personal = e.target.checked
                        onRowDataChange(newRowData);
                      }}
                    />
                  )
                      },
                      {title: 'Docs Personal Firmado', field: 'Lopd.Firma_personal', type: "boolean",

                      render: rowData =>
                        <Checkbox
                          checked={rowData.Lopd.Firma_personal}
                          color="primary"
    
                        />,
                      editComponent: ({ onChange, onRowDataChange, rowData, ...p }) =>
                       ( <Checkbox
                          {...p}
                          //    disabled= {!rowData.Roles|| rowData.Roles.length===0}
                          checked={rowData.Lopd.Firma_personal}
                          color="primary"
                          onChange={e => {
                            const newRowData = { ...rowData};
                            rowData.Lopd.Firma_personal = e.target.checked
                            onRowDataChange(newRowData);
                          }}
                        />
                      )
                          },
                      {title: 'Videovigilancia', field: 'Lopd.Videovigilancia', type: "boolean",

                      render: rowData =>
                        <Checkbox
                          checked={rowData.Lopd.Videovigilancia}
                          color="primary"

                        />,
                      editComponent: ({ onChange, onRowDataChange, rowData, ...p }) =>
                       ( <Checkbox
                          {...p}
                          //    disabled= {!rowData.Roles|| rowData.Roles.length===0}
                          checked={rowData.Lopd.Videovigilancia}
                          color="primary"
                          onChange={e => {
                            const newRowData = { ...rowData};
                            rowData.Lopd.Videovigilancia = e.target.checked
                            onRowDataChange(newRowData);
                          }}
                        />
                      )
                      },
                      {title: 'Docs Videovigilancia Firmado', field: 'Lopd.Firma_video', type: "boolean",

                      render: rowData =>
                        <Checkbox
                          checked={rowData.Lopd.Firma_video}
                          color="primary"

                        />,
                      editComponent: ({ onChange, onRowDataChange, rowData, ...p }) =>
                       ( <Checkbox
                          {...p}
                          //    disabled= {!rowData.Roles|| rowData.Roles.length===0}
                          checked={rowData.Lopd.Firma_video}
                          color="primary"
                          onChange={e => {
                            const newRowData = { ...rowData};
                            rowData.Lopd.Firma_video = e.target.checked
                            onRowDataChange(newRowData);
                          }}
                        />
                      )
                      },
                  
               
                      {title: 'Fin de Prestaciones', field: 'Lopd.Fin_prestaciones', type: "boolean",

                      render: rowData =>
                        <Checkbox
                          checked={rowData.Lopd.Fin_prestaciones}
                          color="primary"

                        />,
                      editComponent: ({ onChange, onRowDataChange, rowData, ...p }) =>
                       ( <Checkbox
                          {...p}
                          //    disabled= {!rowData.Roles|| rowData.Roles.length===0}
                          checked={rowData.Lopd.Fin_prestaciones}
                          color="primary"
                          onChange={e => {
                            const newRowData = { ...rowData};
                            rowData.Lopd.Fin_prestaciones = e.target.checked
                            onRowDataChange(newRowData);
                          }}
                        />
                      )
                    },

                      { title: 'CIF', field: 'Cif',  editable: 'never' },
          //            { title: 'Fecha de alta', field: 'Fecha_Creacion', editable: 'never' },

                

                  
                      { title: 'Código Postal', field: 'Cp', editable: 'never' },
                      { title: 'Población', field: 'Poblacion', editable: 'never' },
         

                      {
                        title: 'Observaciones', field: 'Observaciones', cellStyle: {
                          width: 400,
                          minWidth: 400
                        },
                        headerStyle: {
                          width: 400,
                          minWidth: 400
                        },
                        render: rowData =>
                          <div>
                            <TextField InputLabelProps={{ shrink: true }}
                              margin="normal"
                              fullWidth
                              multiline
                              rowsMax={3}
                              
                              id="descripcion"
                              name="descripcion"
                              value={rowData.Observaciones}
                              type="text"
                            />
                          </div>,

                        editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => {
                          if (!rowData.descripcionfinal) { rowData.descripcionfinal = '' }
                          return (
                            <div>
                              <TextField InputLabelProps={{ shrink: true }}
                                margin="normal"
                                fullWidth
                                multiline
                                rowsMax={3}
                                name="descripcionfinal"
                                id="descripcionfinal"
                                value={rowData.descripcionfinal}

                                onChange={e => {
                                  const newRowData = { ...rowData, descripcionfinal: e.target.value };
                                  rowData.descripcionfinal = e.target.value

                                  onRowDataChange(newRowData);
                                }}
                                variant="filled"
                                type="text"
                              />
                            </div>)

                        }
                      },



                    ]}
                    data={comus}
                    editable={{
                      isEditable: (rowData) => this.state.usuarioLogueado.Lopd, 
                      //  isDeletable: (rowData) => !this.state.modificar,
                    onRowUpdate: (newData, oldData) =>
                      new Promise((resolve,reject) => {
                          setTimeout(() => {
                              var observaciones = newData.Observaciones
                            if(newData.descripcionfinal&&newData.descripcionfinal!==''){
                             let fecha = new Date()
                             observaciones = newData.Observaciones+ '\n<' + this.state.usuarioLogueado.Nombre
                                + "> " + fecha.toLocaleString("en-GB") + ":"+ newData.descripcionfinal
                                newData.Observaciones=observaciones
                              }

                              if(! newData.Lopd.Contratada){ newData.Lopd.Contratada=false}
                              if(! newData.Lopd.Contrato_fir){ newData.Lopd.Contrato_fir=false}
                              if(! newData.Lopd.Firma_presi){ newData.Lopd.Firma_presi=false}
                              if(! newData.Lopd.Personal){ newData.Lopd.Personal=false}
                              if(! newData.Lopd.Firma_personal){ newData.Lopd.Firma_personal=false}
                              if(! newData.Lopd.Videovigilancia){ newData.Lopd.Videovigilancia=false}
                              if(! newData.Lopd.Firma_video){ newData.Lopd.Firma_video=false}
                              if(! newData.Lopd.Fin_prestaciones){ newData.Lopd.Fin_prestaciones=false}
                             
                                this.props.firebase.comu(newData.uid).update({

                                  "Lopd" : {
                                    "Contratada" : newData.Lopd.Contratada,
                                    "Contrato_fir" : newData.Lopd.Contrato_fir,
                                    "Firma_presi" : newData.Lopd.Firma_presi,
                                    "Personal" : newData.Lopd.Personal,
                                    "Firma_personal" : newData.Lopd.Firma_personal,
                                    "Videovigilancia" : newData.Lopd.Videovigilancia,
                                    "Firma_video" : newData.Lopd.Firma_video,
                                    "Fin_prestaciones" : newData.Lopd.Fin_prestaciones,                        
                                  },
           
                                  Observaciones: observaciones,
                                  Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
                              }).then(() => {

                               this.setState({
                                    snack: true,
                                    error2: "El registro de " + newData.Nombre + " ha sido actualizado correctamente"
                                  });

                                  var data = this.state.comus;

                                  data[data.indexOf(oldData)] = newData;
                                  this.setState({ comus: data, descripcionfinal: "" });
                                  resolve()


                                })

                                  .catch(error => {
                                    this.setState({ error });
                                    this.setState({ descripcionfinal: "" });
                                  });
                              
                            
                          }, 1);
                        }),
                    }}

                  />


                </Paper>
              </Grid>)}


      
              <Dialog open={this.state.abrirAviso} /*onClick={thi.handleClickClose}*/ aria-labelledby="form-dialog-title"
              fullWidth="true" maxWidth="md" scroll="paper"          >
              <DialogTitle id="form-dialog-title">ENVÍO AVISO</DialogTitle>
              <DialogContent dividers>

                <TextField
                  autoFocus
                  margin="dense"
                  id="titulo"
                  name="texto_titulo"
                  label="Añade el título del AVISO"
                  type="text"
                  value={texto_titulo}
                  onChange={this.onChange}
                  fullWidth
                />
                <TextField

                  multiline
                  margin="dense"
                  id="texto_envio"
                  name="texto_envio"
                  label="Añade el texto del AVISO"
                  type="text"
                  value={texto_envio}
                  onChange={this.onChange}
                  fullWidth
                />

              </DialogContent>
              <DialogActions>
                <Button onClick={this.cerrarAviso} color="primary"
                >
                  Cancelar
                                    </Button>
                <Button
                  disabled={this.state.texto_envio === "" || this.state.texto_titulo === ""}
                  onClick={this.enviarAviso} color="primary"
                >
                  Confirmar
                                    </Button>
              </DialogActions>
            </Dialog>

              <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                maxHeight: 600, overflow: 'auto', 
                alignItems: 'center',
                justifyContent: 'center'
              }}

              open={this.state.abrir_documentos}
            
              onClose={e=>this.setState({abrir_documentos:false})}
 
            >
                   <Paper style={{ marginLeft: 20, marginRight: 20,marginBottom:20, marginTop: 20 }}>
             
             <Grid container spacing={3}  >
              <Grid item  xs={2} style={{marginLeft:20}}>
                
                <h3 style={{ color: 'green' }} >DIRECTORIO</h3>
                 <ButtonGroup  size="small"  color="primary" variant="text" aria-label="volver">
                          <Button  disabled = {this.state.directorioPrincipal===this.state.rowData.Id}
                           onClick={ this.buscarPadre(this.state.Ref_padre)}>
                           <Tooltip title="Vover al directorio anterior" aria-label="seguir">
                                 <ArrowBackIosIcon/> 
                            </Tooltip>
                          </Button>
                </ButtonGroup> 
             
                {((!this.state.abrirBackdrop)&&<Paper elevation={5} variant="outlined" square style={{  margin:0}}> 
                        <Button  onClick={(event) => 
                        this.buscarficheros(this.state.PathDirectorioActual,this.state.directorioPrincipal)} variant="text"  
                                size="large" color="primary"  startIcon={<FolderIcon />} endIcon ={<ArrowForwardIosIcon/>}>
                                  {this.state.directorioPrincipal}
                        </Button>
                  </Paper>)}
                    {((this.state.rowData.Id!=='')&&(!this.state.abrirBackdrop)&&<Paper elevation={5} 
                    variant="outlined" square style={{  margin:0}}> 
               
                             <h4 style={{ color: 'green', margin:10 }} >Comunidad Actual:</h4>
                             <p style={{ color: 'green', margin:10 }}> {this.state.rowData.Id} - {this.state.rowData.Nombre }</p>
                
                    </Paper>)}
     
              </Grid>

              <Grid item xs = {3}>  
                 
                  <h3  style={{ color: 'green' }}>SUBDIRECTORIOS</h3>          
                  <ButtonGroup  size="small"  color="primary" variant="text" aria-label="volver">
                         
                          <Button  onClick={this.handleClickOpenDialog}  >
                            
                          <Tooltip title="Añadir carpeta" aria-label="añadir">
                                
                                <AddCircleOutlineIcon/>
                              
                              </Tooltip>
                          </Button>
                         
                    </ButtonGroup> 
     
                  {((!this.state.abrirBackdrop)&&
                   <Paper style={{maxHeight: 490, overflow: 'auto', marginTop:0}}>     
                  
                          <List component="nav" dense="true"  aria-label="main mailbox folders" justify="space-around" alignItems="center" >
                            
                            {this.state.dataCategoria3 && this.state.dataCategoria3.map(actividad => (                  
                            <Grid>
                              <Divider />
                            <ListItem
                            button
                            selected={this.selectedIndex === actividad.Nombre}
                            onClick={(event) => this.buscarficheros(actividad.path, actividad.Nombre)}
                            >
                            <ListItemAvatar>
                                <Avatar  style ={{color:'lightgreen', backgroundColor:green[500]}}>  
                                        <FolderIcon />
                                </Avatar>
                            </ListItemAvatar>

                            <ListItemText primary={actividad.Nombre} />
                            <ListItemSecondaryAction>
                      
                              <IconButton edge="end" aria-label="delete"
                                disabled={actividad.Nombre==='imagen' || actividad.Nombre==='LOPD'}
                                onClick={(event) => this.handleClickBorrar(actividad.path)} >
                           
                                <DeleteIcon />
                                </IconButton>
                        
                            </ListItemSecondaryAction>
                            </ListItem>                      
                            </Grid>
                            ))}
                          </List>
                        </Paper>)}
              </Grid >
              <Grid item xs={6} >    
           
              <Grid container direction='row'   justifyContent="space-between"  alignItems="center">
                               <h3  style={{ color: 'green' }}>FICHEROS Y ÁREA DE CARGA</h3>
                       
                              <Backdrop  color = 'primary' open={this.state.open}>
                                          <CircularProgress color="primary" />
                              </Backdrop>
                            
               </Grid>
              {((!this.state.abrirBackdrop)&& 
              <Paper >
                  <Paper style={{maxHeight: 200, overflow: 'auto'}}>  
                  <DragDropContext
                                onBeforeCapture={this.onBeforeCapture}
                                onBeforeDragStart={this.onBeforeDragStart}
                                onDragStart={this.onDragStart}
                                onDragUpdate={this.onDragUpdate}
                                onDragEnd={this.onDragEnd}
                              >
                      <Droppable droppableId="droppable">
                             {(provided, snapshot) => (
                          <RootRef rootRef={provided.innerRef}>
                      
                  <List component="nav" dense="true"  aria-label="main mailbox folders" justify="space-around" alignItems="center" >
                    {this.state.Pdfs && this.state.Pdfs.map((actividad,index) => (                  
                    <Grid>
                      <Divider />
                      <Draggable key={actividad.Nombre} draggableId={actividad.Nombre} index={index}>
                          {(provided, snapshot) => (
                            <ListItem
                            selected={this.selectedIndex === actividad.Nombre}
                        
                              ContainerComponent="li"
                              ContainerProps={{ ref: provided.innerRef }}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                    
                          <ListItemAvatar>
                              <Avatar  style ={{color:'lightgreen', backgroundColor:green[500]}}>  

                                <InsertDriveFileIcon/>
                              </Avatar>
                            </ListItemAvatar>

                          <ListItemText primary={actividad.Nombre}/>
                          <ListItemSecondaryAction>

                                <IconButton edge="end" aria-label="view"
                                     //   onClick={(event) => this.handleClickBorrar(actividad.path)} >
                                       onClick={this.handleClickOpen(actividad)}>
                                      <VisibilityIcon />
                                    </IconButton>
                                    <IconButton edge="end" aria-label="delete"
                                      onClick={(event) => this.handleClickBorrarFichero(actividad.Nombre)} >
                                
                                      <DeleteIcon />
                                      
                                    </IconButton>
                                 
                                
                                  </ListItemSecondaryAction>
                              </ListItem>                            
                          )}
                      </Draggable>
                    </Grid>
                  ))}
                  {provided.placeholder}
                </List>
              </RootRef>
            )}
          </Droppable>
        </DragDropContext>
                </Paper>    
                <DropzoneArea 
                        maxFileSize={10000000}
                        onDrop = {acceptedFiles => this.onDrop(acceptedFiles)}
                         showPreviewsInDropzone= {false} 
                         filesLimit={5}
                         showFileNames ={false}
                         dropzoneText = {"Arrastra el fichero o haz click aquí para cargarlo desde la ventana"}/>
                   
  

            </Paper>)}
              </Grid >
          
          
            </Grid>
        
             
             </Paper>
 
            </Modal>  
            <Dialog open={this.state.abrirDialog} onClose={this.handleClickClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Nueva Carpeta</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Para crear la nueva carpeta introduce un nombre y seguidamente la opcion "Aceptar"
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="directorio"
                    name="directorio"
                    label="Nombre del directorio"
                    type="text"
                    onChange={this.onChange}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                <Button onClick={this.handleClickClose} color="primary">
                                      Cancelar
                </Button>
                <Button onClick={this.handleClickCloseOk} color="primary" >
                                      Aceptar
                </Button>
                </DialogActions>
            </Dialog>
       
               
            <Modal
                           aria-labelledby="transition-modal-title"
                           aria-describedby="transition-modal-description"
                           style={{display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center'}}
                            open={this.state.avisos_enviados}
                            onClose={e=> this.setState({avisos_enviados:false,resultado_aviso:[],usuariosTotal:[]})}                                        
                                                    >
                   <Paper style={{maxHeight: 500, overflow: 'auto', alignContent:'center',  alignItems:'center',
                      marginLeft:20, marginRight:20,marginTop:20}}>   
                       <h2  style={{ justifyItems:'center',
                         marginLeft:20,color: 'green' }}>RESULTADOS AVISOS ENVIADOS </h2>
                  
                       <h3  style={{  marginLeft:20, color: 'green' }}>Total USUARIOS que se ha enviado el AVISO: {this.state.usuariosTotal.length}</h3>                              
                       <h3  style={{  marginLeft:20, color: 'green' }}>Total dispositivos enviado AVISO: {this.state.resultado_aviso.length}</h3>                              
               
                       <h3  style={{  marginLeft:20, color: 'green' }}>Total dispositivos OK: {totalMensajesOK}</h3>                              
                       <h3  style={{  marginLeft:20, color: 'green' }}>Total dispositivos KO: {totalMensajesKO}</h3>                              
          
                       <MaterialTable
                                 options={{
                                 
                                 exportButton: {
                                  csv: true,
                                  pdf: false
                                },
                                 filtering: true,
                                 selection: false,   
                                 pageSize: 10,  
                                 grouping: true,
                                 exportDelimiter: ";",
                                 exportAllData: true,
                                 pageSizeOptions:	[5, 10, 20],
                                 headerStyle: {
                                   backgroundColor: 'green',
                                   color: 'white'},
                                   paddingTop:5,
                                   paddingBottom:5
                                         
                               }}
                               
                               localization={{ 
                               
                                toolbar: {
                                  nRowsSelected: 'TOTAL DISPOSITIVOS que se ha enviado el aviso: {0}'
                              },
                              }}
 
                               icons={tableIcons}
                        
                               title={'TABLA RESULTADOS'}
 
                               columns={[
                                {title: 'Nombre', field: 'Nombre'},
                                {title: 'DNI', field: 'DNI'},
                                {title: 'Administrador', field: 'Administrador'},                              
                                {title: 'Comunidad', field: 'Comunidad'},
                                {title: 'Error de Envío', field: 'error'},
                                {title: 'Código Error', field: 'codigo'},
                                {title: 'Mensaje Error', field: 'mensaje'},
                                {title: 'Dispositivo', field: 'Dispositivos'},

                              ]}
                 
                              data={this.state.resultado_aviso}
 
                          />
                  </Paper>
              </Modal>

       
        <Box mt={40}>  <Copyright />  </Box>
        </ThemeProvider>
      </Grid>
    );
  }
}
const condition = authUser => !!authUser;
export default withFirebase(withAuthorization(condition)(Lopd));