import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';
class Privacidad extends Component {

    render() {
        return (
          <Grid >
              <h2>Política privacidad uso de CÁMARA e IMÁGENES del dispositivo por aplicación C365</h2>
              <hr/>
              <h5>El uso de la CÁMARA por la aplicación de comunidad 365 (C365, en adelante) estará únicamente destinado
                para la toma de imágenes por el propio usuario y su posterior enlace, sobre la inicidencia, siniestro o consulta que  
                el propio usuario esta dando de alta por medio de dicha aplicación C365.
              </h5>
              <h5>El acceso a las carpetas de IMÁGENES del dispositivo por C365 estará únicmante destinado para que  el usuario 
                pueda acceder a sus imágenes y las pueda enlazar sobre la incidencia, siniestro o consulta que esté
                gestionando por medio de la app C365. </h5>
                    
        </Grid>
        );
      }
    }

export default Privacidad;