import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Deal from '../../components/deal/deal.component';
//import EditInput from '../../components/edit-input/edit-input.component'

import { getDocuments } from '../../firebase/firebase.utils';
import { withFirebase } from '../../../Firebase';
import { withAuthorization } from '../../../Session';
const Contact = props => {
  const [contactState, setContactState] = useState({});
  const [contactDealState, setContactDealState] = useState(null);
  const { contactId } = useParams();

  useEffect(() => {
    const getContacts = async () => {
      const type = 'contact';
      const contact = await getDocuments(contactId, type,props.firebase);
      setContactState(contact);
    }
    getContacts();
  }, [contactId]);

  useEffect(() => {
    const getDeals = async contact => {
      const type = 'deal';
      const where = {
        field: 'name',
        operator: '==',
        condition: contact.name
      }
      let dealList = await getDocuments(null, type, props.firebase);
      dealList= dealList.filter((contacto)=>contacto.name===contact.name)
    
  
      setContactDealState(dealList);
    }
    if (contactState.name)
      getDeals(contactState);
  }, [contactState]);

  return (
    <div className='contact-page'>
      <div className='contact-details'>
        Página de Leads
        <h3>{contactState.name}</h3>
        <div>Organización: {contactState.company}</div>
        <div>Email: {contactState.email}</div>
        <div>Posición: {contactState.position}</div>
      </div>
      <div className='contact-deals'>
        <h3>Deals:</h3>
        {
          contactDealState ?
          contactDealState.map(
            deal =>
            (
              <Deal key={deal.id} deal={deal} />
            )
          )
          : null
        }
      </div>
    </div>
)};

const condition = authUser => !!authUser;
export default withFirebase(withAuthorization(condition)(Contact));
