import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';
import moment from 'moment';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import Box from '@material-ui/core/Box';
import SaveIcon from '@material-ui/icons/Save';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteIcon from '@material-ui/icons/Delete';
//import ModidyIcon from '@material-ui/icons/Modify';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import enviarAvisos, {cargarResultado} from '../DatosMaestros/enviarAvisos'

import {Fab,Dialog,DialogActions,
  DialogContent,DialogContentText,DialogTitle} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import List from '@material-ui/core/List';
//import ListItem from '@material-ui/core/ListItem';
import Grid from '@material-ui/core/Grid';
import BotonesTriger from '../Botones/botonTrige'
import Cabecera from '../Cabecera/index_365'
import MuiListItem from "@material-ui/core/ListItem";
import {withStyles } from '@material-ui/core/styles';

import InputLabel from '@material-ui/core/InputLabel';
import  {Select,FormControl,Chip,MenuItem} from '@material-ui/core';

import ListItemText from '@material-ui/core/ListItemText';

import Button from '@material-ui/core/Button';

import FormHelperText from '@material-ui/core/FormHelperText';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import  green from '@material-ui/core/colors/green';
import SendIcon from '@material-ui/icons/Send';
import  firebase2  from 'firebase'
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { forwardRef } from 'react';
import MaterialTable from 'material-table'
import Copyright from '../Privacidad/copyright'

import Modal from '@material-ui/core/Modal';
import ModalTutoriales,{traerTutoriales} from  '../Tutoriales/tutoriales'



const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
      claro: green[200]
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#e81446',
    },
  },
});



const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "green",
      color: "white"
    },
    "&$selected:hover": {
      backgroundColor: "lightgreen",
      color: "white"
    },
    "&:hover": {
      backgroundColor: "lightgreen",
      color: "white"
    }
  },
  selected: {}
})(MuiListItem);
class Votacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abrirBackdrop:false,
      avisos_enviados:false,
      usuarioLogueado:'',
      despacho:{},
      votaciones:[],
      texto_envio:'',
      texto_url:'',
      selectedIndex : '',
      usuariosComu:[],
      publicada:'',
      resultado:'',
      abierta:'',
      Pdfs:[],
      criterio:'',
      criterios:['Ninguna opción de las propuestas'],
      usuariosVoto:[],
      uid:"",
      fecha_publicacion:'',
      añadir: true,
      incis: [],
      identIncial:'',
      comus: [],
      open: false,
      radioValor:'principal',
      tipo:'guardar',
      porcentajeSI:0,
      porcentajeNO:0,
      porcentajeABS:0,
      votacion:'',
      mostrarTabla: false,
      email: '',
      password: '',
      error: null,
      error2: null,
      modificar: true,
      temporizador:'',
      comunidad:'',
      grupo:[],
      roles:[],
      comunidad2:'',
      sis:'', nos:'', abstenciones:'', total:'',
      listaComunidades: [],
      votaciones_todas:[],
      ComunidadesAsig:[],
      ComunidadesNoAsig:[],
      tenemosLista:false,
      label_automatico:'CIERRE MANUAAAAAAAAL',
      label_publicar:"SIN PUBLICAR",
      label_abrir:"CERRADA",
      label_publicarResul:"SIN PUBLICAR",
      label_publicarResul_por:"SIN PUBLICAR",
      
      snack:false, snack2:false, vertical: 'top',
      horizontal: 'center', 
      automatico: false,
      publicar: false,
      publicarResul:false,
      publicarResul_por:false,
      abrir: false,
      columnas : [
        {title: 'Nombre', field: 'Nombre'},
        {title: 'Apellido', field: 'Apellido'},
        {title: 'DNI', field: 'DNI'},
        {title: 'Dirección', field: 'Direccion'},
        {title: 'Portal/Escalera', field: 'Portal'},
        {title: 'SI', field: 'SI'},
        {title: 'NO', field: 'NO'},
        {title: 'Abstención', field: 'ABS'},
    
      ]

  };
    this.handleChange = this.handleChange.bind(this);
    this.BuscarVotaciones= this.BuscarVotaciones.bind(this);
 
   
    this.borrarFiltros = this.borrarFiltros.bind(this);
    this.añadir = this.añadir.bind(this);
    this.cancelar = this.cancelar.bind(this);
    this.buscarComunidades = this.buscarComunidades.bind(this);
    this.comprobarExisteVotacion = this.comprobarExisteVotacion.bind(this)
    this.traerVotaciones=this.traerVotaciones.bind(this)
  //  this.traerVotaciones2=this.traerVotaciones2.bind(this)
    this.handleClose =     this.handleClose.bind(this)
    this.anularVotacion = this.anularVotacion.bind(this)

  }

  buscarDespacho() {
    this.props.firebase.despacho().once('value', snapshot => {
      const usersObject = snapshot.val();
      if(usersObject){
        this.setState({ despacho: usersObject}) 

      }
    })
  }
  
  traerUsuarioLogueado() {
    return new Promise ((resolve,reject)  => {  
    var user = this.props.firebase.auth.currentUser;
    if (user) {
        this.props.firebase.users().orderByChild('/Clave').equalTo(user.uid)
      .once('value', snapshot => {
        const usersObject = snapshot.val();
        if (usersObject) {
          const usuario = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));
  
          this.setState({ usuarioLogueado: usuario[0] })  
          resolve(usuario[0])
        }
      })
    }else{resolve(false)}  
  })
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value, tenemosLista:false,selectedIndex:'' });
    let votaciones = this.state.votaciones_todas
    
    if (event.target.name === "comunidad2" &&event.target.value!=='-1'){
       votaciones = votaciones.filter((votacion)=>votacion.Comunidad=== event.target.value)
    }else if (event.target.name !== "comunidad2"&&this.state.comunidad2 !=="" && this.state.comunidad2 !=="-1" ){
      votaciones = votaciones.filter((votacion)=>votacion.Comunidad=== this.state.comunidad2)
    }

    if (event.target.name === "publicada" ){
      if( event.target.value!=='-1'){
       votaciones = votaciones.filter((votacion)=>votacion.Publicar=== event.target.value)
      }

    }else if (this.state.publicada !=="" && this.state.publicada !=="-1" ){
      votaciones = votaciones.filter((votacion)=>votacion.Publicar=== this.state.publicada)
    }


    if (event.target.name === "abierta" ){
      if( event.target.value!=='-1'){
       votaciones = votaciones.filter((votacion)=>votacion.Abrir=== event.target.value)
      }
    
    }else if  (this.state.abierta!=='' && this.state.abierta !=="-1" ){
      votaciones = votaciones.filter((votacion)=>votacion.Abrir=== this.state.abierta)
    }
  

    if (event.target.name === "resultado" ){
      if( event.target.value!=='-1'){
       votaciones = votaciones.filter((votacion)=>votacion.PublicarResultado=== event.target.value)
      }
      
    }else if  (this.state.resultado !=='' && this.state.resultado !=="-1" ){
      votaciones = votaciones.filter((votacion)=>votacion.PublicarResultado=== this.state.resultado)
    }
   

    this.setState({ votaciones:votaciones}) 

    



  }
  filtrarDespuesGuardar(votaciones) {
  
    
    if (this.state.comunidad2 !=="" && this.state.comunidad2 !=="-1" ){
      votaciones = votaciones.filter((votacion)=>votacion.Comunidad=== this.state.comunidad2)
    }


    if (this.state.publicada !=="" && this.state.publicada !=="-1" ){
      votaciones = votaciones.filter((votacion)=>votacion.Publicar=== this.state.publicada)
    }


    if  (this.state.abierta!=='' && this.state.abierta !=="-1" ){
      votaciones = votaciones.filter((votacion)=>votacion.Abrir=== this.state.abierta)
    }
  

 
      
    if  (this.state.resultado !=='' && this.state.resultado !=="-1" ){
      votaciones = votaciones.filter((votacion)=>votacion.PublicarResultado=== this.state.resultado)
    }
   

    this.setState({ votaciones:votaciones}) 

    



  }

handleClose () {
  this.setState({snack:false, snack2:false})
};

componentDidMount() {
  this.traerUsuarioLogueado()
  this.buscarDespacho()
  this.props.firebase.roles2().on('value', snapshot => {
    const usersObject = snapshot.val();
    if(usersObject){
        var roles2 = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));
      roles2=roles2.filter((rol)=>rol.Todo===true||rol.Juntas===true)

      
    //const roles=usersObject.concat({Nombre:''})
    //Quitamos los roles que tenga deshabilitado la votación

    this.setState({roles: roles2});
      
  }else{ this.setState({roles: []});}
  

  })


  this.buscarComunidades(); this.traerVotaciones(); this.buscarUsuarios()
  traerTutoriales(this, 'Votaciones').then((data)=>{this.setState({Pdfs:data})})
//Traems los grpos
  
}
buscarComunidades(){
  this.props.firebase.comus().on('value', snapshot => {
    const usersObject = snapshot.val();
    if(usersObject){
      
    const comusList = Object.keys(usersObject).map(key => ({
      ...usersObject[key],
      uid: key,
    }));
    this.setState({comus: comusList});
  
  }else{ this.setState({comus: []});}
  });

}

enviarAvisoPrevia=()=>{

  
    if(!this.state.abrir || !this.state.publicar ){
      var aviso=''
      if(!this.state.abrir&&!this.state.publicar )
      aviso = "La votación se encuentra en estado CERRADA y NO PUBLICADA, los usuarios que reciban el aviso no podrán acceder a la votación, ¿deseas continuar?"
      else if(!this.state.abrir)
      aviso = "La votación se encuentra en estado CERRADA, los usuarios que reciban el aviso podrán acceder a la votación pero no votar, ¿deseas continuar?"
      else if(!this.state.publicar )
      aviso = "La votación se encuentra en estado NO PUBLICADA, los usuarios que reciban el aviso no podrán acceder a la votación, ¿deseas continuar?"
      
      
      if(window.confirm(aviso)){
        this.enviarAviso()    }  
    
    }else{
      this.enviarAviso()    
    }


}
enviarAviso = () => {
  var aviso = "Se va a enviar el aviso al grupo de usuarios incluidos en la votación de la comunidad "+ this.state.comunidad
const roles=this.state.grupo
  if(roles&&roles.length>0){
    var  elrol=''
    roles.map((rol,index)=>elrol= (index+1===roles.length ? elrol+rol+'.' : elrol+rol+', '))
    aviso= aviso+ ' .Esta votación sólo será compartida para los usuarios de dicha comunidad que incluyan los siguientes roles: '+elrol
  }
 

  if(window.confirm(aviso))
    { 
      this.setState({abrirBackdrop:true, abrirAviso:false}) 
     
          new Promise((resolve, reject) => {
           //enviamos el aviso a los usuarios según comunidad
         resolve (enviarAvisos([this.state.comunidad],
          this.state.texto_envio,this.state.nombre,this.state.texto_url, this.props.firebase,
          '','', "otro",[],this.state.usuarioLogueado, 
          this.state.despacho, this.state.grupo,this.state.votacion.uid))})
        .then(data2 => {
          if (data2){


        //    enviarDispositivosAdmin(this.state.texto_envio,
         //     this.state.texto_titulo, this.props.firebase,
           //   this.state.PathDirectorioActual,this.state.pdfEnviar, "1", data2).then((data3)=>{             
                const registro= cargarResultado(data2,[], this.props.firebase,this.state.usuarioLogueado)  

                this.setState({resultado_aviso:registro.archivo_final,avisos_enviados:true,texto_envio:'',texto_url:'',
                  usuariosTotal:registro.usuariosTotal, abrirBackdrop:false})   
          //     alert("El envío se ha realizado correctamente")
         //       this.setState({Categorias:[]})
         //       })
          }else { alert("No dispones de usuarios destinatarios para este aviso, por favor revisa si los destinatarios tienen asignados algún rol que les impide votar, o has seleccionado para esta votación unos roles que no están asignados a nadie")
              this.setState({ abrirBackdrop:false})    
          //    this.setState({Categorias:[]})
          }
            
              this.cerrarAviso()  
        })

        .catch(reason => { 
          console.log(reason)
        });
        

      
 
    }
  
}
buscarUsuarios(){
  this.props.firebase.users().on('value', snapshot => {
    const usersObject = snapshot.val();
    if (usersObject){
        const lista= Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }))
    this.setState({usuariosComu:lista})
  }else{ this.setState({usuariosComu:[]});}
  
})
}
componentWillUnmount() {
  this.props.firebase.votaciones().off()
  this.props.firebase.comus().off()
  this.props.firebase.GrupoRoles().off()
  this.props.firebase.users().off()
}
traerVotaciones(){
    this.props.firebase.votaciones().on('value', snapshot => {
      const usersObject = snapshot.val();
      
        if (usersObject){
            const votacionesList = Object.keys(usersObject).map(key => ({
            ...usersObject[key],
            uid: key,
          }));
          votacionesList.sort(function (a, b) {
              return a.Nombre.localeCompare(b.Nombre, 'es', { sensitivity: 'base' , numeric:true })
  
          });

          var votacionesList2=[]
          votacionesList.forEach(votacion =>{
            var objeto = votacion.Voto  
            var votofinal= votacion
            if(objeto){
                 
                  var votos = Object.entries(objeto).map(([key, val]) => ({
                    "voto":  val ,  "usuario":  key 
                  })); 
                 
                  votofinal.Voto =votos
                  votofinal.SI= votos.filter(vot =>vot.voto==='SI').length
                  votofinal.NO= votos.filter(vot =>vot.voto==='NO').length
                  votofinal.Abstenciones= votos.filter(vot =>vot.voto==='ABS').length        
                  votofinal.Total =votofinal.SI+votofinal.NO+votofinal.Abstenciones
            }else {
              votofinal.Voto=[]
              votofinal.SI=0
              votofinal.NO=0
              votofinal.Abstenciones=0
              votofinal.Total=0


            }

            votacionesList2= votacionesList2.concat(votofinal)

          })

          this.filtrarDespuesGuardar(votacionesList2)

        this.setState({ votaciones_todas:votacionesList2 });
        this.setState({ mostrarTabla: true, open:false });
 
      }
        else{  this.setState({votaciones: [], votaciones_todas:[]});
        alert('No hay ninguna votación creada')}
        this.setState({open:false });
    
           
      
      })

  }

BuscarVotaciones(){
   // this.buscarComunidades();
    this.setState({open:true, mostrarTabla: false, añadir: true, tenemosLista:false, comunidad:'' })  
    this.borrarFiltros();
    this.traerVotaciones();
  } 
borrarFiltros(){ 
        this.setState({ votaciones:[], nombre:'', descripcion:'', resultado:'', abierta:'',publicada:'',comunidad2:'',
        tenemosLista:false });
  }
  
cargarUsuarios(comu, votacion,columnas ){
  const voto= votacion.Voto
  var resultado=[]
  var porcentajeSI=0
  var porcentajeNO=0
  var porcentajeABS=0

  if(voto&&voto.length>0)
   {
   const usuarios = this.state.usuariosComu.filter(usuario => usuario.Comunidad= comu)
   if (votacion.Tipo!== "secundario")
        {voto.forEach(vot =>{
        const usuariosMostrar =   usuarios.filter(usuario=> usuario.uid===vot.usuario)
        
        if(usuariosMostrar&&usuariosMostrar.length>0){ 
          if(vot.voto==='SI'){

            resultado= resultado.concat({"Nombre":usuariosMostrar[0].Nombre, "Apellido":usuariosMostrar[0].Apellido,
          "DNI":usuariosMostrar[0].DNI,
          'Porcentaje':usuariosMostrar[0].Porcentaje,
          "Direccion":usuariosMostrar[0].Direccion,
          'Numero':usuariosMostrar[0].Numero,
          'Portal':usuariosMostrar[0].Portal,
          'Escalera':usuariosMostrar[0].Escalera,
          'Piso':usuariosMostrar[0].Piso,
          'Letra':usuariosMostrar[0].Letra,   "SI":"X", "NO":"", "ABS":"" })
        if(   usuariosMostrar[0].Porcentaje){
          porcentajeSI= porcentajeSI + Number(usuariosMostrar[0].Porcentaje)

        }
         

          }
          else if(vot.voto==='NO'){

            resultado= resultado.concat({"Nombre":usuariosMostrar[0].Nombre, "Apellido":usuariosMostrar[0].Apellido,
            "DNI":usuariosMostrar[0].DNI,
            'Porcentaje':usuariosMostrar[0].Porcentaje,
            "Direccion":usuariosMostrar[0].Direccion,
            'Numero':usuariosMostrar[0].Numero,
            'Portal':usuariosMostrar[0].Portal,
            'Escalera':usuariosMostrar[0].Escalera,
            'Piso':usuariosMostrar[0].Piso,
            'Letra':usuariosMostrar[0].Letra, "SI":"", "NO":"X", "ABS":"" })
         
            if(usuariosMostrar[0].Porcentaje){
              porcentajeNO= porcentajeNO + Number(usuariosMostrar[0].Porcentaje)
    
            }
          }
          else if(vot.voto==='ABS'){

            resultado= resultado.concat({"Nombre":usuariosMostrar[0].Nombre, "Apellido":usuariosMostrar[0].Apellido,
            "DNI":usuariosMostrar[0].DNI,
            'Porcentaje':usuariosMostrar[0].Porcentaje,
            "Direccion":usuariosMostrar[0].Direccion,
            'Numero':usuariosMostrar[0].Numero,
            'Portal':usuariosMostrar[0].Portal,
            'Escalera':usuariosMostrar[0].Escalera,
            'Piso':usuariosMostrar[0].Piso,
            'Letra':usuariosMostrar[0].Letra, "SI":"", "NO":"", "ABS":"X" })
            if(usuariosMostrar[0].Porcentaje){
              porcentajeABS= porcentajeABS + Number(usuariosMostrar[0].Porcentaje)
    
            }
          }
        }
    })
    resultado= resultado.concat({"Nombre":"", "Apellido":"","Direccion":"","Portal":"TOTAL",
    "Escalera":votacion.Total,"SI":votacion.SI, "NO":votacion.NO, "ABS":votacion.Abstenciones })
    this.setState({porcentajeABS:porcentajeABS.toFixed(3),porcentajeSI:porcentajeSI.toFixed(3),porcentajeNO:porcentajeNO.toFixed(3)})
   }
   else{


    voto.forEach(vot =>{
      const usuariosMostrar =   usuarios.filter(usuario=> usuario.uid===vot.usuario)
      
      if(usuariosMostrar&&usuariosMostrar.length>0){ 
      
        var resul= {"Nombre":usuariosMostrar[0].Nombre, "Apellido":usuariosMostrar[0].Apellido,
          "DNI":usuariosMostrar[0].DNI,
          'Porcentaje':usuariosMostrar[0].Porcentaje,
          "Direccion":usuariosMostrar[0].Direccion,
          'Numero':usuariosMostrar[0].Numero,
          'Portal':usuariosMostrar[0].Portal,
          'Escalera':usuariosMostrar[0].Escalera,
          'Piso':usuariosMostrar[0].Piso,
          'Letra':usuariosMostrar[0].Letra}
      
        columnas.forEach(columna =>{
            if(columna.title!=="Nombre"&&columna.title!=="Apellido" && columna.title!=="DNI"&&columna.title!=="Dirección"
            &&columna.title!=="Portal"&&columna.title!=="Numero"&&columna.title!=="Escalera"&&columna.title!=="Piso"&&
            columna.title!=="Letra"){      
              const title =columna.title
              if (title===vot.voto){ 
                resul[title]="X"
              }
              else{  resul[title]=" "}
            }
          })


          resultado= resultado.concat(resul)
       
      }
      })
    
      resultado= resultado.concat({"Nombre":"", "Apellido":"","Direccion":"TOTAL VOTOS",
    "Portal":votacion.Voto.length, })

    
    
    }

   }
   
  

  this.setState({usuariosVoto: resultado})
}
 
handleListItemClick (event, votacion, vota){   
    this.setState({votacion: votacion, identIncial:votacion.Nombre})
    this.setState({nombre:votacion.Nombre, descripcion:votacion.Descripcion, fecha_publicacion:votacion.Fecha_publicacion,grupo:(votacion.Grupo ? votacion.Grupo:false),
      identificador: votacion.Identificador, total:votacion.Total, sis:votacion.SI, nos:votacion.NO,grupo:(votacion.Roles ? votacion.Roles:[]),
      abstenciones:votacion.Abstenciones, publicar:votacion.Publicar, abrir:votacion.Abrir, 
      automatico:votacion.Automatico,selectedIndex : votacion.Nombre,  publicarResul_por:votacion.PublicarPorcentaje,
      publicarResul:votacion.PublicarResultado,criterios:votacion.Criterios, criterio:'', radioValor:votacion.Tipo,
      comunidad:votacion.Comunidad })
      var columnas = []
      if(votacion.Tipo=== 'principal'){
  
        columnas = [
          {title: 'Nombre', field: 'Nombre'},
          {title: 'Apellido', field: 'Apellido'},
          {title: 'DNI', field: 'DNI'},
          {title: 'Porcentaje %', field: 'Porcentaje'},
          {title: 'Dirección', field: 'Direccion'},
          {title: 'Número', field: 'Numero'},
          {title: 'Portal(Op)', field: 'Portal'},
          {title: 'Escalera', field: 'Escalera'},
          {title: 'Piso', field: 'Piso'},
          {title: 'Letra/Puerta', field: 'Letra'},
          {title: 'SI', field: 'SI'},
          {title: 'NO', field: 'NO'},
          {title: 'Abstención', field: 'ABS'},
      
        ]
      }else {
        columnas = [
          {title: 'Nombre', field: 'Nombre'},
          {title: 'Apellido', field: 'Apellido'},
          {title: 'DNI', field: 'DNI'},
          {title: 'Dirección', field: 'Direccion'},
          {title: 'Número', field: 'Numero'},
          {title: 'Portal(Op)', field: 'Portal'},
          {title: 'Escalera', field: 'Escalera'},
          {title: 'Piso', field: 'Piso'},
          {title: 'Letra/Puerta', field: 'Letra'},
         
      
        ]
  
        const criterios1 =votacion.Criterios 
        criterios1&&criterios1.forEach(function(direc) {
          columnas=columnas.concat( {title: direc, field: direc})
  
        })
  
      }
   

      this.cargarUsuarios(votacion.Comunidad, votacion, columnas)
      if(votacion.Publicar){ this.setState({label_publicar:'PUBLICADA'})} else{
        this.setState({label_publicar:'SIN PUBLICAR'})
      }
      if(votacion.Abrir){ this.setState({label_abrir:'ABIERTA'})} else{
        this.setState({label_abrir:'CERRADA'})
      }
      if(votacion.Automatico){ this.setState({label_automatico:'CIERRE PROGRAMADO'})} else{
        this.setState({label_automatico:'CIERRE MANUAL'})
      }
      if(votacion.PublicarResultado){ this.setState({label_publicarResul:'PUBLICADA'})} else{
        this.setState({label_publicarResul:'SIN PUBLICAR'})
      }     
      if(votacion.PublicarPorcentaje){ this.setState({label_publicarResul_por:'PUBLICADA'})} else{
        this.setState({label_publicarResul_por:'SIN PUBLICAR'})
      }     
    
    if (vota === "visualizar"){ 
      this.setState({      modificar:true })
     

    }
    else if (vota === "modificar"){
      this.setState({ modificar:false,tipo:"guardar" }) 

    }
    else if (vota === "delete"){
      this.setState({ modificar:false, snack2:true,votacionBorrar:"La votación "+ votacion.Nombre+ " será borrada"})
   

    }
     this.setState({ tenemosLista:true, columnas:columnas})

 }; 

 onSubmit = event => {
 
  
 // const comprobar =this.comprobarExisteVotacion()
      if( !this.state.criterios ||this.state.criterios.length<1){
       this.setState({criterios:['']})
      }
      if(this.state.tipo==="guardar"){
   //     if(this.state.identIncial ===this.state.nombre  ){
            this.props.firebase.votacion(this.state.votacion.uid).update({
              Nombre: this.state.nombre,
              Descripcion: this.state.descripcion,
              Fecha_publicacion : this.state.fecha_publicacion,
              Publicar: this.state.publicar,
              PublicarResultado: this.state.publicarResul,
              Roles:(this.state.grupo ? this.state.grupo:[]),
              PublicarPorcentaje:(this.state.publicarResul_por ? this.state.publicarResul_por:false),
           //   Automatico:this.state.automatico,
              Abrir: this.state.abrir,
              Criterios: this.state.criterios,
              Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
            }).then(() => {
    //            this.BuscarVotaciones()   
             //    this.filtrarDespuesGuardar()
                 this.setState({snack:true,
                  error2:"La votación " +this.state.nombre+" has sido actualizada correctamente" })
                  
              })
              .catch(error => {
                this.setState({ error });
              });
            
     //   }//else{ //this.setState({snack:true, 
           // error2:"La votación " +this.state.nombre+" ya existe, por favor verificar el nombre" });
          //}
      }
     else {

        //  if (comprobar.length === 0){

              this.props.firebase.votaciones().push({
                Nombre: this.state.nombre,
                Descripcion: this.state.descripcion,
                Comunidad : this.state.comunidad,
                Roles:(this.state.grupo ? this.state.grupo:[]),
                Identificador:firebase2.database.ServerValue.TIMESTAMP,
                Publicar: this.state.publicar,
                PublicarResultado: this.state.publicarResul,
                PublicarPorcentaje:this.state.publicarResul_por,
                Abrir: this.state.abrir,
            //    Automatico:this.state.automatico,
                Total:0,
                Fecha_publicacion:  this.state.fecha_publicacion,
                SI:0,
                NO:0,
                Abstenciones:0,
                Tipo:this.state.radioValor,
                Criterios: this.state.criterios,
                Fecha_Modificacion: firebase2.database.ServerValue.TIMESTAMP,
              }).then((data) => {
                this.BuscarVotaciones()   

                this.setState({snack:true, añadir: true,
                  error2:"La votación " +this.state.nombre+" se ha creado correctamente" })
              
                })
                .catch(error => { this.setState({ error });
                });

       //   }else{ this.setState({snack:true, 
         //   error2:"La votación " +this.state.nombre+" ya existe, por favor verificar el nombre" });}
         }

    event.preventDefault();
};
comprobarExisteVotacion() {

  var existe=[];
  const votaciones =this.state.votaciones;
  existe = votaciones.filter(votacion => votacion.Nombre===this.state.nombre )
  return existe;       
}
onChange = event => {

  this.setState({ [event.target.name]: event.target.value });
};

onChange2 = event => {
  this.setState({ [event.target.name]: event.target.checked });
  
  if(event.target.name==='publicar')
        { if(event.target.checked){ 
                 const fecha = new Date()
                 var fecha_publicacion= moment(fecha).format('DD/MM/YYYY HH:mm')
                 this.setState({label_publicar:'PUBLICADA ', fecha_publicacion:fecha_publicacion})
                }
         else{
                this.setState({label_publicar:'SIN PUBLICAR',fecha_publicacion:''})
        }
}else if(event.target.name==='abrir') {

        if(event.target.checked){ 
                this.setState({label_abrir:'ABIERTA'})} 
        else{
          this.setState({label_abrir:'CERRADA'})
        }

}else if(event.target.name==='automatico') {

  if(event.target.checked){ 
    
          const fecha = new Date()
          const cierre=  moment(fecha).format('YYYY-MM-DDTHH:mm')

          this.setState({label_automatico:'CIERRE PROGRAMADO', temporizador:cierre})} 
  else{
    this.setState({label_automatico:'CIERRE MANUAL'})
  }
 
}else if (event.target.name==='publicarResul') {
      if(event.target.checked){ 
        this.setState({label_publicarResul:'PUBLICADA' })} 
    else{
    this.setState({label_publicarResul:'SIN PUBLICAR'})
  }
}
  else if (event.target.name==='publicarResul_por') {
    if(event.target.checked){ 
      this.setState({label_publicarResul_por:'PUBLICADA' })} 
  else{
  this.setState({label_publicarResul_por:'SIN PUBLICAR'})
  }
}



};


anularVotacion() {
  

  this.props.firebase.votacion(this.state.votacion.uid).remove()
 .then(() => {

    this.setState({snack2:false, snack:true, error2:"La votación ha sido borrada" });  
    this.BuscarVotaciones();

    })
    .catch(error => { 
      this.setState({snack2:false, snack:true, error2:"Ha habido un problema y no se ha podido borrar" });  
      this.setState({ error });
    });

}

añadir(){
    
    this.setState({añadir: false, mostrarTabla:false, identificador:'',fecha_publicacion:'',publicar:false,
    publicarResul:false,publicarResul_por:false, criterios:['Ninguna opción de las propuestas'], radioValor:'principal',
    comunidad:'',comunidad2:'',selectedIndex : '',grupo:[],
    abrir:false, modificar: false, nombre:'', descripcion:'', votaciones:[], votaciones_todas:[],
     tipo:"añadir", tenemosLista:true,  sis:'', nos:'', abstenciones:'', total:'',criterio:'', votacion:''})
   
}
cancelar(){
  this.setState({añadir: true, mostrarTabla:true, tenemosLista: false, modificar: true,criterio:'',
  comunidad:'',comunidad2:'',  criterios:[] })
  this.borrarFiltros()
}
handleRadioChange = (event) => {
  this.setState({radioValor: event.target.value})
  
};

handleAdd =   () => {
  var criterios = this.state.criterios;
  criterios = criterios.concat(this.state.criterio)
   
    this.setState({criterios:criterios, criterio:''})
}

handleDelete = (chipToDelete) => () => {
  var criterios = this.state.criterios;

  if(criterios&&criterios.length>0){
    criterios =  criterios.filter((criterio) => criterio !== chipToDelete.label2)
   
   this.setState({criterios:criterios})
  }
}
 render() {

     const { mostrarTabla, sis, nos, abstenciones, total,label_abrir, criterio, columnas,votacion,texto_envio,texto_url } = this.state;
    const comus = this.state.comus
    const votaciones = this.state.votaciones;
    const { nombre, descripcion, error, comunidad, roles } = this.state;

  const grupo=(this.state.grupo ? this.state.grupo:[])
   const vertical= this.state.vertical;
   const criterios1 =this.state.criterios 
   const horizontal= this.state.horizontal;
   var votoTotal= 0
   const isInvalid = nombre === '' || descripcion === '' || comunidad==='' || (this.state.radioValor==='secundario'&& criterios1.length===0);
  
   if(this.state.radioValor==='secundario'&& criterios1.length===0 && criterio===''){
    alert("Los criterios para este tipo de votación no pueden estar vacíos")
   }
  
   
   if(votacion.Voto){ votoTotal=votacion.Voto.length}

  
   
   var label_publicar = this.state.label_publicar
   var label_publicarResul = this.state.label_publicarResul
   var label_publicarResul_por = this.state.label_publicarResul_por
   

  if(this.state.fecha_publicacion){ label_publicar = this.state.label_publicar+' ' + this.state.fecha_publicacion}
  const porSI=this.state.porcentajeSI
  const porNo=this.state.porcentajeNO
  const porAbs=this.state.porcentajeABS
  let toti = Number(porSI) + Number(porNo)+ Number(porAbs)
  toti=toti.toFixed(3)
  const porcentajeTOTAL = "TOTAL "+ toti.toString()+"%"
  const porcentajeSI = "SI " +this.state.porcentajeSI+"%"
  const porcentajeNO = "NO " +this.state.porcentajeNO+"%"
  const porcentajeABS = "Abstenciones " +this.state.porcentajeABS+"%"
  var totalPorcentaje=0
  if( this.state.radioValor==='secundario'){
    const usuariosVoto =this.state.usuariosVoto
    var criterios=[]
    var i=0
    var voto = votacion.Voto
    var totalVotos=0
     criterios1&&criterios1.forEach(function(direc)  {
      var porcen =0
      usuariosVoto.forEach(usuario =>{
        if(usuario[direc]==='X'&&usuario.Porcentaje)
         //       totalVotos=totalVotos+Number(usuario.Porcentaje)
            {   porcen=porcen+Number(usuario.Porcentaje)
                totalVotos=totalVotos+Number(usuario.Porcentaje)
              }
      })


      var num =0
      if(voto){ num = voto.filter(vot =>vot.voto===direc).length}
      porcen=porcen.toFixed(3)
      const dir = {
         key: i, label: direc+" "+porcen+"%", click:false , voto: num,label2:direc,
      }
      i++
      criterios = criterios.concat(dir)
      })
      totalVotos=totalVotos.toFixed(3)
      totalPorcentaje= "Nº total de votaciones "+totalVotos+"%"

  }

  var totalMensajesOK= 0
  var totalMensajesKO= 0

  const resultado_aviso= this.state.resultado_aviso
  if(resultado_aviso&&resultado_aviso.length>0){
      totalMensajesOK= resultado_aviso.filter((usuario)=>usuario.error==='NO').length
      totalMensajesKO= resultado_aviso.filter((usuario)=>usuario.error==='SI').length
  }

 
   return (  

      <Grid >
      {!this.state.abrirBackdrop&&  <Cabecera/>}
      {!this.state.abrirBackdrop&&<hr />}

        {!this.state.abrirBackdrop&&<Grid style={{ position: 'absolute', right: 20, top: 20 , maxWidth:"15%", maxHeight:"15%"}}>
          <Button variant="contained" color="primary" onClick={() => { this.setState({ mostrarAyuda: true }) }}>
            AYUDA (TUTORIALES)
          </Button>
        </Grid>}
        {!this.state.abrirBackdrop&&        <BotonesTriger/>       }
        {!this.state.abrirBackdrop&&<Grid container spacing={0}>
        <ThemeProvider theme={theme}>
         <Snackbar         anchorOrigin={{ vertical , horizontal }}
                          key={`${this.state.vertical},${this.state.horizontal}`}
                          open={this.state.snack}
                          autoHideDuration={5000}
                          onClose={this.handleClose}
                          message={this.state.error2}
                          action={
                            <div>
                              <Button color="primary" size="small" onClick={this.handleClose}>
                                Cerrar
                              </Button>
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={this.handleClose}
                              >
                                <CloseIcon/>
                              </IconButton>
                            </div>
                          }
         />
           <Snackbar         anchorOrigin={{ vertical , horizontal }}
                          key={`${this.state.vertical},${this.state.horizontal}`}
                          open={this.state.snack2}
                          autoHideDuration={5000}
                          onClose={this.handleClose}
                          message={this.state.votacionBorrar}
                          action={
                          <div>
                                 <Button onClick={this.anularVotacion}>  
                                      <Fab  size="small" color="primary" aria-label="add"  variant="extended">
                                          <DoneOutlineIcon/>
                                        CONFIRMAR
                                       </Fab>
                                  </Button>
                                  <Button onClick={this.handleClose}>  
                                      <Fab  size="small" color="secondary" aria-label="add"  variant="extended">
                                      <CloseIcon/>
                                        CANCELAR
                                       </Fab>
                                  </Button>
                            </div>
                          }
         />           
            <Grid container >
            
            <Grid container spacing={1} style={{ marginLeft:'0.5%' }}>
                  <Grid item sm={3}>
         
                  <Button onClick={this.BuscarVotaciones} variant="outlined"  
                   size="large" color="primary"  startIcon={<SearchIcon />}>
                                      LISTADO ACTUAL DE VOTACIONES
                  </Button>
                  <Backdrop  color = 'green' open={this.state.open}>
                            <CircularProgress color="green" />
                  </Backdrop>
               

                  </Grid>
                  <Grid item sm={3}>
                  {(this.state.añadir ?
                                 <Button onClick={this.añadir}>  
                                      <Fab  size="small" color="primary" aria-label="add"  variant="extended">
                                          <AddIcon />
                                        Añadir
                                       </Fab>
                                  </Button>
                      :
                                <Button onClick={this.cancelar}>  
                                    <Fab  size="small" color="secondary" aria-label="cancel"   variant="extended">
                                    <CloseIcon/>
                                      Cancelar
                                    </Fab>
                                </Button>
                   )}
          
                  </Grid>
                  <Grid container spacing={1} style={{ marginLeft:'0.5%' }}>
                  <Grid item sm={1.5}>
          
                        <FormControl styles= {{minWidth: 120}} >
                          <InputLabel id="demo-simple-select-label">Comunidad</InputLabel>
                              <Select
                                labelId="comunidad"
                                id="comunidad2"
                                name ="comunidad2"
                                disabled={!this.state.votaciones_todas||this.state.votaciones_todas.length===0}
                                value={this.state.comunidad2}
                                onChange={this.handleChange}
                              >
                                  <MenuItem  key={'-1'} value={'-1'}> </MenuItem>
                                {comus && comus.map(comu => (
                
                          <MenuItem key={comu.Id} value={comu.Id}>
                                    {comu.Id}
                                  </MenuItem>
                                ))}
                  
                            
                              </Select>
                              <FormHelperText>Lista de comunidades</FormHelperText>

                          </FormControl>
                  </Grid>
                  <Grid item sm={1.5}> 
                        <FormControl styles= {{minWidth: 120}} >
                
                            <InputLabel id="demo-simple-select-label">Por publicación</InputLabel>
                              <Select
                                labelId="publicada"
                                id="publicada"
                                name ="publicada"
                                disabled={!this.state.votaciones_todas||this.state.votaciones_todas.length===0}
                                value={this.state.publicada}
                                onChange={this.handleChange}
                              >

                              <MenuItem  key={'-1'} value={'-1'}> </MenuItem>
                              <MenuItem  key={1} value={true}>{'PUBLICADA'}</MenuItem>
                              <MenuItem key={2} value={false}> {'SIN PUBLICAR'} </MenuItem>
                              
                
                  
                            
                              </Select>
                              <FormHelperText>Votaciones Publicadas</FormHelperText>
                      
                        </FormControl>
                        </Grid>
                  <Grid item sm={1.5}> 
                  
                        <FormControl styles= {{minWidth: 120}} >
                
                <InputLabel id="demo-simple-select-label">Apertura</InputLabel>
                  <Select
                    labelId="abierta"
                    id="abierta"
                    name ="abierta"
                    disabled={!this.state.votaciones_todas||this.state.votaciones_todas.length===0}
                    value={this.state.abierta}
                    onChange={this.handleChange}
                  >
                
                                    
                    <MenuItem  key={'-1'} value={'-1'}> </MenuItem>
                    <MenuItem  key={1} value={true}>{'ABIERTAS'}</MenuItem>
                      <MenuItem key={2} value={false}>{'CERRADAS'}</MenuItem>
                
                  </Select>
                  <FormHelperText>Votaciones Abiertas</FormHelperText>
          
                        </FormControl>
                  </Grid>
                  <Grid item sm={1.5}> 
                  
                        <FormControl styles= {{minWidth: 120}} >
                
                <InputLabel id="demo-simple-select-label">Por resultados</InputLabel>
                  <Select
                    labelId="resultado"
                    id="resultado"
                    name ="resultado"
                    disabled={!this.state.votaciones_todas||this.state.votaciones_todas.length===0}
                    value={this.state.resultado}
                    onChange={this.handleChange}
                  >              
                    <MenuItem  key={'-1'} value={'-1'}> </MenuItem>
                    <MenuItem  key={1} value={true}>{'PUBLICADO'}</MenuItem>
                    <MenuItem key={2} value={false}>{'SIN PUBLICAR'}</MenuItem>           
                  </Select>
                  <FormHelperText>Votaciones Publicadas</FormHelperText>
          
                      </FormControl>
                  </Grid>
                  </Grid>
            
                    <Grid container spacing={1}>
                    {mostrarTabla&&  <Grid item sm={6}>
                      <Paper style={{maxHeight: 490, overflow: 'auto', width:'96%', maxWidth:700}}>
              
                    <List component="nav" dense="true"  aria-label="main mailbox folders" justify="space-around" alignItems="center" >
                      {votaciones && votaciones.map(votacion => (                  
                      <Grid>
                        <Divider />
                      <ListItem
                      button
                      
                      selected={this.state.selectedIndex === votacion.Nombre}
                      onClick={(event) => this.handleListItemClick(event, votacion, "visualizar" )}
                      >
                      <ListItemText  style={{maxWidth:'85%'}} primary={votacion.Nombre}
                       secondary={ "Comunidad: "+votacion.Comunidad +" Ident:"+votacion.Identificador } 
                         />
                      <ListItemSecondaryAction>
                          <IconButton edge="end" aria-label="delete"
                          onClick={(event) => this.handleListItemClick(event, votacion, "delete" )}>
                          <DeleteIcon />
                          </IconButton>               
                          <IconButton edge="end" aria-label="visibility"
                          onClick={(event) => this.handleListItemClick(event, votacion, "visualizar" )}>
                          <VisibilityIcon />
                          </IconButton>
                          <IconButton edge="end" aria-label="borderColor" 
                          onClick={(event) => this.handleListItemClick(event, votacion, "modificar" )}>
                          <BorderColorIcon />
                          </IconButton>
                      </ListItemSecondaryAction>
                      </ListItem>                      
                      </Grid>
                      ))}
                  </List>
                      </Paper>
                    </Grid> }
                    <Grid item sm={(!mostrarTabla ? 8:6)}  style={{ marginTop:'-7.5%' , marginLeft:(!mostrarTabla ? '40%':null) }}>
                    {this.state.tenemosLista&&
                   <Paper style={{ width:'98%'}}>
                 <form >
               
                       <Button onClick={this.onSubmit}    fullWidth 
                             size="large" color="primary"  startIcon={<SaveIcon />   }   variant="contained"
                             disabled ={this.state.modificar ||isInvalid}   >                                  
                             GUARDAR
                       
                        </Button>
      
                        {error && <p>{error.message}</p>} 
                  <Grid container justifyContent='space-around' direction='row'  >
      
                        <TextField  InputLabelProps={{ shrink: true }}
                          disabled 
                          variant="outlined"
                          margin="normal"
                          required
                          
                          id="identificador"
                          size="small"
                          label="Identificador único"
                          name="identificador"
                          autoComplete="identificador"
                        
                          value={this.state.identificador}
                        
                          type="text"
                          placeholder="Identificador votación"
                        />
                     
              <Tooltip title="Envía aviso sobre la votación">
                              <Grid style={{alignSelf:'center',marginTop:'1%'}}>
                            <Button variant='outlined' color="primary"  size="large"
                           disabled={this.state.modificar}
                             onClick={() => { this.setState({ abrirAviso: true }) }}
                            startIcon={<SendIcon />}>ENVIAR AVISO VOTACIÓN
                            </Button>
                          </Grid>
                            
                          </Tooltip>         
        
                          </Grid>
        
                        <TextField  InputLabelProps={{ shrink: true }}
                          disabled ={this.state.añadir}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="nombre"
                          size="small"
                          label="Nombre de la votación"
                          name="nombre"
                          autoComplete="nombre"
                          autoFocus
                          value={this.state.nombre}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Nombre de la votación"
                        />
                        <TextField  InputLabelProps={{ shrink: true }}
                        disabled ={this.state.modificar}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          size="small"
                          label="Descripción"
                          id="descripcion"
                          autoComplete="descripcion"
                          name="descripcion"
                          value={descripcion}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Descripción de la votación"
                        />
                         <TextField  InputLabelProps={{ shrink: true }}
                         disabled = {this.state.añadir}
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          
                          select
                          size="small"
                          label="Comunidad"
                          id="comunidad"
                          autoComplete="comunidad"
                          name="comunidad"
                          value={comunidad}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Comunidad donde se realiza la votación"
                         >
                            {comus.map((comunidad) => (
                                <MenuItem key={comunidad.Id} value= {comunidad.Id}>
                                  {comunidad.Id+" - " +comunidad.Nombre}
                                </MenuItem>
                              ))}
                           </TextField>
                           
     <FormControl fullWidth>
    
     <FormLabel component="legend">Votación por Rol (escoge el rol o roles de los usuarios que solo podrán votar)</FormLabel>
    
    <Select  InputLabelProps={{ shrink: true }}
       multiple
       margin="normal"
       required
       fullWidth
       size="small"
       label="Votación por Rol"
       disabled={this.state.modificar}
     
       placeholder="Es una votación por roles?"
       select
       id="grupo"
       autoComplete="grupo"
       name="grupo"
       value={grupo}
       onChange={this.onChange}
     
     renderValue={(selected) =>{ 
      if(!selected || selected.length===0){
        selected= []
      selected[0]=''     }
      return(
      <div style={{  display: 'flex', flexWrap: 'wrap',}}>
        {selected.map((value) => (
          <Chip variant="outlined" color='primary' key={value} label={value} 
          style={{margin:6}}/>
        ))}
      </div>
    )}}>
      {roles.map((rol) => {
                          return(      <MenuItem key={rol.Nombre} value= {rol.Nombre}>
                                  {rol.Nombre}
                                </MenuItem>)
            })}

         
     </Select>
   </FormControl>
                    
                           
    <FormGroup row >
    
      <FormLabel component="legend">Tipo de votación</FormLabel>
      <RadioGroup row aria-label="position" name="position" value={this.state.radioValor} onChange={this.handleRadioChange}>
      <FormControlLabel value="principal" control={<Radio  disabled={this.state.añadir} color="primary" />}
         label="SI/NO/ABS" />
        
      <FormControlLabel value="secundario" control={<Radio disabled={this.state.añadir} color="primary" />} 
       label="SELECCIÓN" />
      </RadioGroup>
  
    </FormGroup>
    <hr/>
                        <FormGroup row >
                              <FormGroup >
                                <FormLabel component="legend" color="primary">Publicar la votación</FormLabel>
                                <FormControlLabel
                                    fullWidth
                                  disabled={this.state.modificar}
                                  control={<Switch checked={this.state.publicar} onChange={this.onChange2}
                                  name="publicar" 
                                  color="primary"/>}
                                  label={label_publicar}
                                 
                                />
                              </FormGroup>
                              <hr/>
                              <FormGroup >
                                <FormLabel component="legend" color="primary">Publicar Resultado</FormLabel>
                                <FormControlLabel
                                    fullWidth
                                  disabled={this.state.modificar}
                                  control={<Switch checked={this.state.publicarResul} onChange={this.onChange2}
                                  name="publicarResul" 
                                  color="primary"/>}
                                  label={label_publicarResul}
                                 
                                />
                              </FormGroup>
                              <hr/>
                              <FormGroup >
                                <FormLabel component="legend" color="primary">Publicar por %</FormLabel>
                                <FormControlLabel
                                    fullWidth
                                  disabled={this.state.modificar}
                                  control={<Switch checked={this.state.publicarResul_por} onChange={this.onChange2}
                                  name="publicarResul_por" 
                                  color="primary"/>}
                                  label={label_publicarResul_por}
                                 
                                />
                              </FormGroup>
                              <hr/>

                              <FormGroup >
                              
                                  <FormLabel component="legend" color="primary">Abrir la votación</FormLabel>
                                  <FormControlLabel
                                      fullWidth
                                    disabled={this.state.modificar}
                                    control={
                                      <Switch
                                        checked={this.state.abrir}
                                        onChange={this.onChange2}
                                        name="abrir"
                                        color="primary"
                                      />
                                    }
                                  
                                    label={label_abrir}
                                    
                                  />
                            </FormGroup>
                          </FormGroup>


                    {this.state.radioValor==='secundario' &&     <Paper  style={{
                          justifyContent: 'center',
                          flexWrap: 'wrap', margin:20,
                          padding:10,maxWidth:700,
                          listStyle: 'none'}}>
                    
                   <h3  style={{ color: 'green' }}>CRITERIOS DE VOTACIÓN</h3>
                 
           
                   <Paper style={{ display: 'flex',
                          justifyContent: 'center',
                          flexWrap: 'wrap', margin:20,
                          padding:10,
                          listStyle: 'none'
                        }}> 

                     {criterios.map((data) => {
                        return (
                          <li key={data.key}>
                               <Tooltip title={data.label} aria-label="add">
                                
                                <Chip
                            //      icon={icon}
                                  label={data.label2}
                                  variant="outlined"
                                  disabled= {this.state.modificar}
                                  clickable
                                  color="primary" 
                                  style={{margin:6, maxWidth:400}}
                                  

                              //   onClick={this.seleccionarZonaEnvio(data)}
                                  onDelete={data.label === 'React' ? undefined : this.handleDelete(data)}
    //                              className={classes.chip}
                                />
                              </Tooltip>
                          </li>
                        );
                      })}
                    </Paper>
                    <hr/>

                    <Paper style={{ display: 'flex',
                          justifyContent: 'center',
                          flexWrap: 'wrap', margin:20,
                          padding:10,
                          listStyle: 'none'
                        }}> 
                              <TextField
                                    
                                              multiline
                                              margin="dense"
                                              id="criterio"
                                              name ="criterio"
                                              label="Añade un criterio nuevo"
                                              type="text"
                                              disabled={this.state.modificar}
                                              value={criterio}
                                              onChange={this.onChange}
                                              fullWidth
                                              />

                    </Paper>  
              
                                   <Button
                                       disabled ={!this.state.criterio || this.state.criterio===""}
                                      onClick={this.handleAdd}
                                     color="primary"
                                    >
                                      AÑADIR
                                    </Button>
                  
               
                  
      
                  </Paper>
  
                      }


                {this.state.radioValor!=='secundario' &&  <FormGroup row >
                          
                            <TextField  InputLabelProps={{ shrink: true }}
                            
                            variant="outlined"
                            margin="normal"
                            required
                            size="small"
                            label={porcentajeTOTAL}
                            id="total"
                            autoComplete="total"
                            name="total"
                            value={total}
                          
                            type="number"
                            placeholder="Nº total de votaciones"
                          />
                        
                            
                              <hr/>
                           
                          <TextField  InputLabelProps={{ shrink: true }}
                      
                            variant="outlined"
                            margin="normal"
                            required
                            size="small"
                           
                            label={porcentajeSI}
                            id="sis"
                            autoComplete="sis"
                            name="sis"
                            value={sis}
                     //       onChange={this.onChange}
                            type="number"
                            placeholder="SI"
                          />
                             <hr/>
 
                         <TextField  InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              margin="normal"
                              required
                              size="small"
                              label={porcentajeNO}
                              id="nos"
                              autoComplete="nos"
                              name="nos"
                              value={nos}
                              type="number"
                              placeholder="NO"
                            />
                               <hr/>
 
                            <TextField  InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              margin="normal"
                              required
                              size="small"
                              name="abstenciones"
                              label={porcentajeABS}
                              id="abstenciones"
                              autoComplete="abstenciones"
                              value={abstenciones}
                       //       onChange={this.onChange}
                              type="number"
                              placeholder="Abstenciones"
                            />
                      
                      </FormGroup>}
                {this.state.radioValor==='secundario' &&  <FormGroup row >
                          
                          <TextField  InputLabelProps={{ shrink: true }}
                          
                          variant="outlined"
                          margin="normal"
                          required
                        //  size="small"
                          label={totalPorcentaje}
                          id="total"
                          autoComplete="total"
                          name="total"
                          value={votoTotal}
                        
                          type="number"
                          placeholder="Nº Total de votaciones"
                        />
                           {criterios.map((data) => {
                        return (
                          
                          <TextField  InputLabelProps={{ shrink: true }}
                          
                          variant="outlined"
                          margin="normal"
                          required
                  
                          label={data.label}
                          id="total"
                          fullWidth
                          autoComplete="total"
                          name="total"
                          value={data.voto}
                        
                          type="number"
                         
                        />
                                )})}
                        </FormGroup>}
                    </form>  

                    </Paper>}
                    </Grid>
                    
                    
                    </Grid>
   
   
   
             
             </Grid>
               {(this.state.tenemosLista&&
               <Grid container spacing={1} style={{ marginLeft:'0.5%' }}>
               <Grid item sm={12}>
                 <Paper style={{maxHeight: 1000, overflow: 'auto', width:'99%', marginTop:20}}>                                 
                            <MaterialTable
                                      options={{
                                        exportButton: {
                                          csv: true,
                                          pdf: false
                                        },
                                      filtering: true,
                                      pageSize: 50,  
                                      grouping: true,
                                      exportFileName: this.state.identificador,
                                      exportDelimiter:";",
                                      exportAllData:true,
                                      pageSizeOptions:	[10, 20, 50],
                                      headerStyle: {
                                        backgroundColor: 'green',
                                        color: 'white'},
                                        paddingTop:5,
                                        paddingBottom:5
                                              
                                    }}
                                    
                                    icons={tableIcons}
                              
                                    title={ <h3  style={{ color: 'green' }}>LISTADO DE VOTOS REALIZADOS: {this.state.nombre}</h3>}
      
                                    columns={columnas}
                          
      
                                    data={this.state.usuariosVoto}
      
                                />
                        </Paper>
                </Grid></Grid>
              )}
          <Dialog open={this.state.abrirAviso} /*onClick={thi.handleClickClose}*/ aria-labelledby="form-dialog-title"
                                    fullWidth="true"    maxWidth="md"          scroll="paper"          >
                                  <DialogTitle id="form-dialog-title">COMUNICADO SOBRE VOTACIÓN</DialogTitle>
                                    <DialogContent dividers>
                                 
                                   
                                          <TextField 
                                              margin="dense"
                                              id="nombre"
                                              name ="nombre"
                                              label="Título aviso votación"
                                              type="text"
                                              value={this.state.nombre}
                                               onChange={this.onChange}
                                             fullWidth/>
                              
                                       
                                          <TextField                                
                                              
                                              autoFocus
                                              margin="dense"
                                              id="texto_envio"
                                              name ="texto_envio"
                                              label="Añade el texto del AVISO"
                                              type="text"
                                              value={texto_envio}
                                              onChange={this.onChange}
                                              fullWidth
                                              />
                                          
                                          <TextField

                                              multiline
                                              margin="dense"
                                              id="texto_url"
                                              name="texto_url"
                                              label="(Opcional) Añade url"
                                              type="text"
                                              value={texto_url}
                                              onChange={this.onChange}
                                              fullWidth
                                              />

                               
                                              
                                  </DialogContent>
                                  <DialogActions>
                                    <Button  onClick={() => { this.setState({ abrirAviso: false,texto_envio:'',texto_url:'' }) }} color="primary"
                                    >
                                      Cancelar
                                    </Button>
                                    <Button
                                       disabled ={this.state.texto_envio===""}
                                       onClick={() => {this.enviarAvisoPrevia()}} color="primary"
                                    >
                                      Confirmar
                                    </Button>
                                  </DialogActions>
                     </Dialog>              </Grid>


       <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}

              open={this.state.mostrarAyuda}
              onClose={e=>this.setState({mostrarAyuda:false})}


            >
            {this.state.mostrarAyuda&&<ModalTutoriales Pdfs= {this.state.Pdfs}/> 
          }
           </Modal>
         </ThemeProvider>
      </Grid>}
  
     {this.state.avisos_enviados&&<Modal
                           aria-labelledby="transition-modal-title"
                           aria-describedby="transition-modal-description"
                           style={{display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center'}}
                            open={this.state.avisos_enviados}
                            onClose={e=> this.setState({avisos_enviados:false,resultado_aviso:[], usuariosTotal:[]})}                                        
                                                    >
                   <Paper style={{maxHeight: 500, overflow: 'auto',maxWidth:'80%', alignContent:'center',  alignItems:'center',
                      marginLeft:20, marginRight:20,marginTop:20}}>   
                       <h2  style={{   marginLeft:20,color: 'green' }}>RESULTADOS AVISOS ENVIADOS </h2>
                       <h3  style={{  marginLeft:20, color: 'green' }}>Total USUARIOS que se ha enviado el AVISO: {this.state.usuariosTotal.length}</h3>                              
                       <h3  style={{  marginLeft:20, color: 'green' }}>Total dispositivos enviado AVISO: {this.state.resultado_aviso.length}</h3>                              
                  
                     
                       <h3  style={{  marginLeft:20, color: 'green' }}>Total dispositivos Ok: {totalMensajesOK}</h3>                              
                       <h3  style={{  marginLeft:20, color: 'green' }}>Total dispositivos KO: {totalMensajesKO}</h3>                              
          
                       <MaterialTable
                                 options={{
                               
                                     exportButton: {
                          csv: true,
                          pdf: false
                        },
                                 filtering: true,
                                 selection: false,   
                                 pageSize: 10,
                                 exportDelimiter: ";",
                                 exportAllData: true,
                                 grouping: true,
                                 pageSizeOptions:	[5, 10, 20],
                                 headerStyle: {
                                   backgroundColor: 'green',
                                   color: 'white'},
                                   paddingTop:5,
                                   paddingBottom:5
                                         
                               }}
                               
                               localization={{ 
                               
                                toolbar: {
                                  onRowsSelected: 'TOTAL DISPOSITIVOS que se ha enviado el aviso: {0}'
                              },
                              }}
 
                               icons={tableIcons}
                        
                               title={'TABLA DETALLE RESULTADOS ENVÍO AVISO'}
 
                               columns={[
                                {title: 'Nombre', field: 'Nombre'},
                                {title: 'Apellido', field: 'Apellido'},
                                {title: 'DNI', field: 'DNI'},
                                {title: 'Administrador', field: 'Administrador'},
                                
                                {title: 'Comunidad', field: 'Comunidad'},
                                {title: 'Error de Envío', field: 'error'},
                                {title: 'Código Error', field: 'codigo'},
                                {title: 'Mensaje Error', field: 'mensaje'},
                                
                                {title: 'Dispositivo', field: 'Dispositivos', cellStyle: {
                                  width: 200,
                                  minWidth: 200
                                },
                                headerStyle: {
                                  width: 200,
                                  minWidth: 200
                                }},

                              ]}
                 
                              data={this.state.resultado_aviso}
 
                          />
                  </Paper>
            </Modal>}

      <Box mt={40}>{!this.state.abrirBackdrop&&<Copyright />} </Box>
      

      <Backdrop  color = 'primary' open={this.state.abrirBackdrop}>
                            <CircularProgress color="green" />
      </Backdrop>
      
    </Grid>
    );
  }
 
}

const condition = authUser => !!authUser;
export default withFirebase(withAuthorization(condition)(Votacion));