import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withFirebase } from '../Firebase';
import Button from '@material-ui/core/Button';
const SignOutButton = ({ firebase }) => (
  <Tooltip disableFocusListener disableTouchListener title="Salir">
  <Button type="button" onClick={firebase.doSignOut}>
    Salir
  </Button>
  </Tooltip>
);

export default withFirebase(SignOutButton);