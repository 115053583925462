import React, { Component,forwardRef} from 'react';
import enviarAvisos, {cargarResultado} from '../DatosMaestros/enviarAvisos'

import { withFirebase } from '../Firebase';
import MaterialTable , {MTableBody,  MTableAction} from 'material-table'
import { withAuthorization } from '../Session';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';

import ValidateIBAN from '../Fomulario/validadIBAN'
 
import {Paper,Snackbar,InputLabel,IconButton,TextField, Box, Backdrop,Tooltip,Avatar,
  CircularProgress,DialogActions,DialogContent, Dialog,FormHelperText,
  DialogTitle, Fab, Grid, List,ListItem,ListItemText,Select,Checkbox,FormControl,Modal,
Chip,Button,MenuItem} from '@material-ui/core';

import moment from 'moment';

import { crearEstructurDirectorios2 } from '../DatosMaestros/documentos'
import BotonesTriger from '../Botones/botonTrige'
import Cabecera from '../Cabecera/index_365'

import { ThemeProvider } from '@material-ui/styles';
//import {green} from '@material-ui/core/colors';

import {checkBoxLectura,checkBoxModificar} from '../Fomulario/formulario'


import {AddBox,ArrowDownward, Check,ChevronLeft,ChevronRight,Clear,DeleteOutline
,Edit,FilterList,FirstPage,LastPage,Remove,SaveAlt,Search,ViewColumn, VolumeMute} from '@material-ui/icons'
import CloseIcon from '@material-ui/icons/Close';
import AttachMoney from '@material-ui/icons/AttachMoney';
import SearchIcon from '@material-ui/icons/Search';
import { createTheme } from '@material-ui/core/styles'
import ModalTutoriales,{traerTutoriales,traerPlantillas} from  '../Tutoriales/tutoriales'

import Copyright from '../Privacidad/copyright';

import {loadStripe} from '@stripe/stripe-js';

import {crearCliente,recuperarCliente,generarFactura,lanzarFactura,crearSetupIntentIncial,actualizaMetodoPagoCliente,
  crearNuevoPrecioSuscripcion} from '../Stripe/StripeSEPA';
import {Textofield, Textofield3,checkBoxStandar,TextofieldIBAN} from '../Fomulario/formulario'

//const Stripe='sk_test_51LNCvDItPJ2Wwqc9TJcg0d6QVK2ehQ01He7kgUnyR9kIQq156hdwdB5D4lpqzbY7dHf6domMxESv44KTS4dQz7ht00RIURd92W'
 const Stripe='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'
const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: "#00a049",
      claro: "#00a049"
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#e81446',
    },
  },
});
//<Formulario tthis={this} />

const tableIcons = {   
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};
const IBAN_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  }
};
const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  placeholderCountry: 'ES',
  style: IBAN_STYLE,
};


class Facturacion extends Component {
  tableRef = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      total_a_cobrar:0,
      sepa_enviar:[],
      ibanMal:0,
      errorIBANComunidad:false,textoError:'',
      errorIBANDespacho:false,
      stripe:'',
      Despacho:'',
      IBANDespacho:'',
      Cobro:'',
      abrir_vista_previa_estruct:false,
      activarEstructura:false,
      editar:false,
     Nombre:'', Cif:'',Fecha_Creacion:'',Id:'',Codigo_seguridad:'',Codigo_vecino:'',categorias1:[],direccionfinal1:'',tipoRegistro:'', 
     Cp:'',Poblacion:'',Poliza:'',Seguro_nombre:'', Observaciones:'', Seguro:'',
     IBAN:'',Vecinos:'',Pago_App:'',
     direcciones1:[],
      abrir_cambios:false,
      UID:'',
      rowData:"",
      comus_enviar: [],
      abrir_vista_previa: false,
      abrir_direcciones: false,
      resumen_carga: false,
      VideoTuto:[],
      uid: "",
      direccion: "",
      comus: [],
      mostrarAyuda: false,
      open: false,
      comunidad: '',
      tipo: 'guardar',
      actividad: '',
      identificador: '',
      mostrarTabla: true,
      selectedIndex: '',
      email: '',
      comunidadBorrar: "",
      error: null,
      error2: null,
      modificar: true,
      listaComunidades: [],

      despachos:[],
   
      snack: false, snack2: false, vertical: 'top',
      horizontal: 'center',
      abrirAviso: false,
      texto_envio: '',
      texto_url:'',
      texto_titulo: '',
      index: '',
      despacho:
      {
        "Nombre": "",
        "CIFNIF": "",
        "Codigo": "",
        "Fecha_registro": "",
        "Telefono1": "",
        "Telefono2": "",
        "Correo": "",
        "Direccion": "",
        "Cp": "",
        "Poblacion": "",
        "Persona": "",
        "Logo": "",
        "Iban": "",
        "uid": ""
      },
      resultado_aviso:[],  
      avisos_enviados:false,
    };
    this.enviarAviso = this.enviarAviso.bind(this)  
    this.handleClose = this.handleClose.bind(this)
    this.busquedaAyuda = this.busquedaAyuda.bind(this)
    this.dialogoEnvio = this.dialogoEnvio.bind(this)

  }

  filterRef () {return ( React.createRef(""))}
  filterRef2 () {return ( React.createRef(""))}

cerrarGestionCambios (){
  this.setState({rowData:[], abrir_cambios:false,
    Despacho:'',
    IBANDespacho:'',
    Cobro:0,Nombre:'', Cif:'',
    Fecha_Creacion:'',Id:'',IBAN:'',Vecinos:'',Pago_App:''
     })
  }
  handleClose() {
    this.setState({ snack: false, snack2: false })
  };


  componentWillMount() {
    this.props.firebase.comus().off()
  }

  componentDidMount() {  
  if(this.state.stripe===''){
  //const Stripe='pk_test_51LNCvDItPJ2Wwqc93NXyF8gX7EA60uJ7G2HmzrgbQBWjLCTv9HdaSM3dG5Ktlg4VVkV7B8ZXATEOB3kncfZzo9lK00Cw75Y0cs'
  const Stripe='pk_live_51LNCvDItPJ2Wwqc9cd0ycFPSizitWWBfMjqkgv75Z2rA1NT3EdmDDqyCLnQDbsp9i83KHuDYcAmXUSviBJGAiU4L00PzDi3XTj'
    loadStripe(Stripe)     
   .then(stripe=>{
        this.setState({stripe:stripe})
      })
   .catch((e)=>{
    const ee=e 
   })  
  }

    this.setState({ mostrarTabla: false });

    const callable2 = this.props.firebase.functions.httpsCallable('traerDespachos')
    callable2()        
    .then((result) => {  
        let despachos=result.data
        this.setState({despachos:despachos})
        const getData = async () => {
          return Promise.all(despachos.map(despacho => {
            return new Promise(resolve => {
              const code= (despacho.Code ? despacho.Code:'')
              let callable1 = this.props.firebase.functions.httpsCallable('traerDatosTabla')
              callable1({tabla:code+'/Comunidad',base:{'BaseDatos':despacho.BaseDatos}})        
              .then((result) => {       

                callable1({tabla:code+'/Despacho',base:{'BaseDatos':despacho.BaseDatos}})        
                .then((result2) => {     
                    const elresult2=  result2.data
                    const resultado={
                      'comus':result.data,
                      'despacho':{...despacho, ...elresult2}
                    }
                        resolve(resultado)
                  })
                  .catch((e)=>{
                    const error=e
                    resolve([])
                  })
                
                })
                .catch((e)=>{
                  const error=e
                  resolve([]) 
                })
              
        })})
    
    )}
        getData().then(data => {
      var comunidadesFinal=[]
    
       data.map(datos=>{
        const comus=datos.comus
        const despacho=datos.despacho

        if(comus){
          let comusList = Object.keys(comus).map(key => ({
            ...comus[key],
            uid: key,
            despacho:despacho
          }));
          comusList = comusList.filter(comu => comu.Id !== '')
          comunidadesFinal=comunidadesFinal.concat(comusList)  
        } 
      }) 
       
       comunidadesFinal.sort(function (A, B) {
            var a =Number(A.Id.trim())
            var b =Number(B.Id.trim())
            if(!a){a=-1}
            if(!b){b=-1}
            if( a > b) {
              return 1;
            }
            if (a < b) {
              return -1;
  
            }
            // a must be equal to b
            return 0;
          })
  
          this.setState({comus:comunidadesFinal,mostrarTabla:true,open: false})
    
       })
  }) 
  
  }

  dialogoEnvio = () => () => { this.setState({ abrirAviso: true }) };

  cerrarAviso = () => { this.setState({ abrirAviso: false, texto_envio: '', texto_url:'',texto_titulo: '' }) };

  enviarAviso = () => {

    this.setState({ open: true,mostrarTabla:false, abrirAviso: false })
    var comunidades = this.state.comus
    this.setState({ dispositivos: [] })
    
    //traemis kas comunidades
    // comunidades = comunidades.filter(comunidad => comunidad.Id==='050')
    comunidades = this.state.comus_enviar
    
    
   
  var comus=[]
   comunidades.map((value) => comus= comus.concat(value.Id))
 
 //  const getData = async () => {
   //    return Promise.all(comus.map(comunidad =>{ 
         //enviamos el aviso a los usuarios según comunidad
         new Promise((resolve, reject) => {
         resolve (enviarAvisos(comus,
          this.state.texto_envio,this.state.texto_titulo,this.state.texto_url, this.props.firebase,
        "","", "otro",[], this.state.usuarioLogueado, this.state.despacho,[]))})
     // getData().then(data2 => {
      .then(data2 => {
        if (data2){
    //      enviarDispositivosAdmin(this.state.texto_envio,
      //      this.state.texto_titulo, this.props.firebase,
        //    this.state.PathDirectorioActual,this.state.pdfEnviar, "otro", data2).then((data3)=>{  
                    const registro= cargarResultado(data2,[], this.props.firebase,this.state.usuarioLogueado)  
           
              this.setState({resultado_aviso:registro.archivo_final,avisos_enviados:true,open: false,mostrarTabla:true,
                usuariosTotal:registro.usuariosTotal,  texto_envio: '', texto_titulo: '',texto_url:''})   
        //     alert("El envío se ha realizado correctamente")
              this.setState({Categorias:[]})
          //    })
            }else { alert("Ha habido un problema y no se ha podido enviar")
              this.setState({Categorias:[]})}

          
            this.cerrarAviso()
      })

      .catch(reason => { 
        console.log(reason)
      });
      
  }
  filtrarComunidad(comusList) {
    //quitamos las de código negativo
    var comunidades = comusList.filter(comunidad => comunidad.uid !== -1);
    this.setState({ comus: comunidades })
  }
  busquedaAyuda(nombre) {
    var busquedAyuda = this.state.gremios.filter(function (gremio) {
      var esta = []
      if (gremio.Actividades && gremio.Actividades.length > 0) {
        esta = gremio.Actividades.filter(actividad => actividad === nombre)
      }
      if (esta.length > 0) { return true }
      return false
    })
    // if(busquedAyuda.length===0){busquedAyuda=[]}
    busquedAyuda = busquedAyuda.concat("")

    return busquedAyuda;
  }
  
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value })
  };
  
 
  render() {

    const comus = this.state.comus
    const vertical = this.state.vertical;
    const horizontal = this.state.horizontal;
    
    const despacho = this.state.despacho
    const { texto_titulo, texto_envio, texto_url,listaZonasRecuperadas,listaZonasRecuperadasFiltrada, zonas } = this.state


    var totalMensajesOK= 0
    var totalMensajesKO= 0

    const resultado_aviso= this.state.resultado_aviso
    if(resultado_aviso&&resultado_aviso.length>0){
       totalMensajesOK= resultado_aviso.filter((usuario)=>usuario.error==='NO').length
       totalMensajesKO= resultado_aviso.filter((usuario)=>usuario.error==='SI').length
    }
    let cuentaCobro =[]
    if(comus&&comus.length>0){ cuentaCobro =comus.filter(comu=>(!comu.Cobro||comu.Cobro===0))}

 //   var gremio_seguros = this.state.gremio_seguros
  //  gremio_seguros = gremio_seguros.concat({ Nombre: "" })
    return (
      <Grid>
          {!this.state.open&&<>
        <Cabecera />
        <hr />
      
        <BotonesTriger /> </>}
        <ThemeProvider theme={theme}>
     
       
            <Snackbar anchorOrigin={{ vertical, horizontal }}
              key={`${this.state.vertical},${this.state.horizontal}`}
              open={this.state.snack}
              autoHideDuration={7000}
              onClose={this.handleClose}
              message={this.state.error2}
              action={
                <div>
                  <Button color="primary" size="small" onClick={this.handleClose}>
                    Cerrar
                              </Button>
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    onClick={this.handleClose}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              }
            />
            <Snackbar anchorOrigin={{ vertical, horizontal }}
              key={`${this.state.vertical},${this.state.horizontal}`}
              open={this.state.snack2}
              autoHideDuration={5000}
              onClose={this.handleClose}
              message={this.state.comunidadBorrar}
              action={
                <div>
                  <Button onClick={this.anularComunidad}>
                    <Fab size="small" color="primary" aria-label="add" variant="extended">
                      <DoneOutlineIcon />
                                        CONFIRMAR
                                       </Fab>
                  </Button>
                  <Button onClick={this.handleClose}>
                    <Fab size="small" color="secondary" aria-label="add" variant="extended">
                      <CloseIcon />
                                        CANCELAR
                                       </Fab>
                  </Button>
                </div>
              }
            />
            {!this.state.open&&  <Grid container direction="row" spacing={1} style={{marginLeft:'1%', width:'98%'}} >
              <Grid item sm={4}  style={{ textAlign:'start'}}>
                <FormControl styles={{ minWidth: 120 }} >
                  <Select
                    labelId="comunidad"
                    id="comunidad"
                    name="comunidad"
                    value={this.state.comunidad}
                    onChange={this.onChange}
                  >
                    <MenuItem key={-1} value="">{""}</MenuItem>
                    {this.state.comus2 && this.state.comus2.map(comu => (

                      <MenuItem key={comu.Id} value={comu.Id}>{comu.Id}</MenuItem>
                    ))
                    }
                  </Select>
                  <FormHelperText>Búsqueda rápida por ID de comunidad</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item sm={4}  style={{ textAlign: "center"}}>
                <Tooltip title="Recupera las comunidades de la base de datos">
                  <Button  onClick={e => {this.setState({ open: true, mostrarTabla: false })
                                          this.componentDidMount()}} 
                    variant="outlined"
                    size="large" color="primary" startIcon={<SearchIcon />}>BUSCAR COMUNIDADES
                  </Button>
                </Tooltip>
       
              </Grid>

              <Grid item sm={4}  style={{ textAlign: 'end'}}>
                <Tooltip title="Lanza la lista previa de cobros">
                
                    <Button onClick={e => {
                        if (this.tableRef.current) {
                      //Lo hacemos para quitar la seleccion de la tabla 1 que la lleva a la tabla 2
                        this.tableRef.current.onAllSelected(false)
                        }
                        const comus_enviar =[...this.state.comus_enviar]
                        const comus_no_enviar=comus_enviar.filter((comu)=>(!comu.Pago_App||comu.Pago_App===false
                          ||(comu.Pago_App===true&&comu.IBAN.trim()==='')))
                        let nuevosDespacho=[]
                        this.state.despachos.map(despacho => {
                                let total=0
                                let lacomu=''
                                comus_no_enviar.map((comu)=>{
                                  const despNombre= comu.despacho.Nombre
                                  const despNombre2=despacho.Nombre
                                  if(despNombre.toUpperCase()===despNombre2.toUpperCase()&&comu.despacho.Codigo===despacho.Codigo){
                                    if(comu.Cobro&&comu.Cobro>0){
                                      total=total+Number(comu.Cobro)
                                      lacomu=comu
                                    }
                                  }
                                })
                                if(total>0){  
                                  
                                  lacomu.IBAN=lacomu.despacho.Iban; lacomu.Cif=lacomu.despacho.CIFNIF; lacomu.Nombre='';lacomu.Id=''
                                  nuevosDespacho= nuevosDespacho.concat({...lacomu, 'Cobro':total.toFixed(2)})
                                }
                              

                        })
                          //Tenemos que agrupar las comuidaddes que no tiene el pago activado al despacho que pertenecen, así mandar la factura al despacho
                          const comus_enviar_fin=comus_enviar.filter((comu)=>(comu.Pago_App&&comu.Pago_App===true&&comu.IBAN.trim()!==''&&comu.Cobro&&comu.Cobro>0))
                        //  comus_enviar_fin.map((comu)=>{
                        //    comu.despacho.Iban=''
                           // comu.despacho.Nombre=''
                         // })
                          const total_cobros=nuevosDespacho.concat(comus_enviar_fin)
                          let mal=0
                          //Contamos los IBAN que está mal
                          total_cobros.map((cobro)=>{
                            cobro.suscripcion=false
                            if(!ValidateIBAN(cobro.IBAN)){
                              mal=mal+1
                            }
                          })

                        
                          this.setState({ comus_enviar:total_cobros},()=>{
                        
                            this.setState({ activarEstructura:true,ibanMal:mal})
                          
                          }) 
                        
                        
                        }} variant="outlined"

                          disabled={!this.state.comus_enviar.length > 0}
                      size="large" color="primary" startIcon={<AttachMoney />}>LANZAR COBROS
                    </Button>
                
                </Tooltip>

              </Grid>
         

            </Grid>}

            {this.state.mostrarTabla &&
              
           <Paper style={{width:'98%', overflowX:'auto', margin:10}}>          

                  <MaterialTable
                    tableRef ={this.tableRef}
                    components={{
                      Body: props => <MTableBody {...props} onFilterChanged={(columnId, value) => {
                          props.onFilterChanged(columnId, value);
                          const field= props.columns[columnId].field
                          this.filterRef[field] = value
                      }} />
                      }}

                    options={{
                      doubleHorizontalScroll:true,
                      actionsColumnIndex: -1 ,
                      exportButton: {
                        csv: true,
                        pdf: false 
                      },
                      addRowPosition: 'first',
                      filtering: true,
                      selection: true,
                      pageSize: 20,
                      exportDelimiter: ";",
                      exportAllData:true,
                      maxBodyHeight: "70vh",
                      pageSizeOptions: [20, 50, 100],
                      headerStyle: {
                        backgroundColor: '#00a049',
                        color: 'white',
                        paddingTop: 5,
                        paddingBottom: 5
                      },

                    }}
                    onSelectionChange={(rows) => {
                      
                      if (rows.length > 0) {
                        const rowss=[...rows]
                        this.setState({ comus_enviar: rowss })

                      } 
                    }}

                    icons={tableIcons}
                    localization={{
                      body: {
                        editRow: {
                          saveTooltip: "Guardar",
                          cancelTooltip: "Cancelar",
                          deleteText: '¿Quieres borrar la comunidad?'
                        },
                        addTooltip: "Crear una nueva",
                        deleteTooltip: "Borrar",
                        editTooltip: "Modificar"
                      },
                      header: {
                        actions: 'Acciones'

                      },
                      toolbar: {
                        nRowsSelected: '{0} Comunidad(es) seleccionadas lanzamiento de cobro'
                      },
                    }}
                    title={<h2 style={{ color: '#00a049' }}>COBROS Y FACTURACIÓN, comunidades con cobro 0 Euros: {cuentaCobro.length}</h2>}
                    columns={[
                      {
                        title: 'Despacho', field: 'despacho.Nombre',editable:'never', defaultFilter: this.filterRef['despacho.Nombre'], cellStyle: {
                          width: 200,
                          minWidth: 200
                        },
                        headerStyle: {
                          width: 200,
                          minWidth: 200
                        }
                      },
                      {
                        title: 'IBAN Despacho', field: 'despacho.Iban', defaultFilter: this.filterRef['despacho.Iban'], cellStyle: {
                          width: 200,
                          minWidth: 200
                        },
                        headerStyle: {
                          width: 200,
                          minWidth: 200
                        },
                        editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => 
                        {
                          const IBAN=rowData.despacho.Iban
                          return(                         
                          <TextField InputLabelProps={{ shrink: true }} //inputProps={{ type: 'number'}}   
                         // disabled={bloquear}
                     //     multiline
                          error={!ValidateIBAN(IBAN)}
                          color="primary"
                          margin="normal"
                   //       label={helperTexto}
                       //   inputProps={{ maxLength: maxLongitud }}
                          fullWidth
                          helperText={ValidateIBAN(IBAN) ? '':'Formato incorrecto'}
                          //maxRows={5}
                          value={rowData.despacho.Iban}
                          type="text"
                          onChange={e => {
                           
                           // rowData..despacho.Iban = e.target.checked
                           const IBAN= e.target.value
                          const eliban= IBAN.toUpperCase()
                           let newRowData = { ...rowData };
                           newRowData.despacho['Iban']=eliban
                        
                           onRowDataChange(newRowData);
                          }}
                        />
                   
                        )}
                      },


                      {
                        title: 'Cobro Euros SIN IVA', field: 'Cobro', type:'numeric', defaultFilter: this.filterRef['Cobro'], cellStyle: {
                          width: 150,
                          minWidth: 150
                        },
                        headerStyle: {
                          width: 150,
                          minWidth: 150
                        }
                      },
                      {
                        title: 'Comunidad', field: 'Nombre',editable:'never', defaultFilter: this.filterRef['Comunidad'], cellStyle: {
                          width: 300,
                          minWidth: 300
                        },
                        headerStyle: {
                          width: 300,
                          minWidth: 300
                        }
                      },
                      { title: 'CIF', field: 'Cif',editable:'never', defaultFilter: this.filterRef['Cifcomunidad'], cellStyle: {
                        width: 100,
                        minWidth: 100
                      },
                      headerStyle: {
                        width: 100,
                        minWidth: 100
                      }
                     },
                      { title: 'Fecha de alta', field: 'Fecha_Creacion',editable:'never', defaultFilter: this.filterRef['Fecha_Creacion'], editable: 'never' },

                      {
                        title: 'Id Comunidad', field: 'Id', defaultFilter: this.filterRef['Id'],editable:'never'
                    
                      },

                     
                      { title: 'Cuenta IBAN', field: 'IBAN', defaultFilter: this.filterRef['IBAN'], },
                      { title: 'Número de Vecinos',editable:'never', field: 'Vecinos', defaultFilter: this.filterRef['Vecinos'], },
                      {
                        title: 'Pago Mensual APP', field: 'Pago_App', defaultFilter: this.filterRef['Pago_App'], type: "boolean",

                        render: rowData =>
                          <Checkbox
                            checked={rowData.Pago_App}
                            color="primary"

                          />,
                        editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                          <Checkbox
                            {...p}
                            //    disabled= {!rowData.Roles|| rowData.Roles.length===0}
                            checked={rowData.Pago_App}
                            color="primary"
                            onChange={e => {
                              const newRowData = { ...rowData, Pago_App: e.target.checked };
                              rowData.Pago_App = e.target.checked
                              onRowDataChange(newRowData);
                            }}
                          />
                        )
                      },
                    ]}
                   onRowClick={(event, rowData, togglePanel) => {
                
                    const esbueno= ValidateIBAN(rowData.IBAN)||rowData.IBAN===''
                    if(esbueno){
                      this.setState({errorIBANComunidad:false,textoError:''})
                    }else{ this.setState({errorIBANComunidad:true,textoError:'Formato incorrecto'})}

                    const esbueno2= ValidateIBAN(rowData.despacho.Iban)||rowData.despacho.Iban===''
                    if(esbueno2){
                      this.setState({errorIBANDespacho:false,textoError:''})
                    }else{ this.setState({errorIBANDespacho:true,textoError:'Formato incorrecto'})}


                    this.setState({rowData:rowData, abrir_cambios:true,
                      Despacho:rowData.despacho.Nombre,IBANDespacho:rowData.despacho.Iban,
                      Cobro:(rowData.Cobro ? rowData.Cobro:0),Nombre:rowData.Nombre, Cif:rowData.Cif,Pago_App:rowData.Pago_App,
                      Fecha_Creacion:rowData.Fecha_Creacion,Id:rowData.Id,IBAN:rowData.IBAN,Vecinos:rowData.Vecinos,
                       })
                    }}
             
                    data={comus}
                    editable={{
                
            onBulkUpdate: (changes) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                // write your logic here
          
               const cambios= Object.values(changes)
               const resul=validarCambio(cambios,this.state.comus)
               if(resul===true){
           
                 actualizar(cambios,this.state.comus,this.props.firebase).then((lista)=>{
                  if(lista){this.setState({comus:lista})}
                  else{this.setState({open:true})
                    //Llamamos a unaactualizacion completa porque se ha  cambiado un dato del despacho y afecta a varias lineas de la tabla
                    this.componentDidMount()}
                  resolve()
                 })
             
              }else{
                  alert(resul)
                  reject();
              }
              
                /* setData([...data, newData]); */
               
              }, 1000);
            })
                    }}
                  />
           </Paper>
              }

          <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              open={this.state.abrir_cambios}
              onClose={e=>this.cerrarGestionCambios()}

            >
          <Paper direction="column" justifyContent="flex-start" alignItems="flex-start" elevation={0}
          style={{   width:'80%',
          maxHeight:'30%'
        
        }} >
              <Paper  style={{padding:10,   width:'100%',
                height:'100%', overflowY: 'auto',
              
              }}> 
          
                <h3 style={{ color: 'white', backgroundColor:'#00a049', marginTop:'-0.5%' }}>Comunidad: {this.state.Nombre} </h3>
               <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
               <Grid container spacing={1}>
                  <Grid item sm={4}>
                  {Textofield ('Despacho','Nombre Despacho',true,null,this)}
                  </Grid>
                  <Grid item sm={4}>
                  {TextofieldIBAN ('IBANDespacho','IBAN del despacho',!this.state.editar,null,this,'errorIBANDespacho')}
                  </Grid>
                  <Grid item sm={4}>
                  {Textofield3 ('Cobro','Cobro de la comunidad',!this.state.editar,null,this,'number')}

                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item sm={4}>
                  {Textofield ('Nombre','Nombre de la comunidad',true,null,this)}
                  </Grid>
                  <Grid item sm={4}>
                  {Textofield ('Cif','Cif de la comunidad',true,null,this)}
                  </Grid>
                  <Grid item sm={4}>
                  {TextofieldIBAN ('IBAN','Iban comunidad',!this.state.editar,null,this,'errorIBANComunidad')}
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item sm={4}>
                  {Textofield ('Id','Identificador de la comunidad',true,5,this)}
                  </Grid>
                  <Grid item sm={2}>
                    {Textofield ('Vecinos','Número de Vecinos',true,null,this)}
                    </Grid>
                    <Grid item sm={2}   style={{textAlign: "center", marginTop:'-0.5%'}}>
                    <h5 style={{ marginBottom:-2, color: 'white', backgroundColor:(this.state.editar ? '#00a049':'darkseagreen') }}>Pago mensual App</h5>
                  
                    {checkBoxStandar('Pago_App','',this,!this.state.editar)}
                
                                 
                </Grid>
                    <Grid item sm={4}>
                  {Textofield ('Fecha_Creacion','Feha de creación comunidad',true,null,this)}
                  </Grid>
                </Grid>
              
                
                </Grid>
                </Paper>

                {!this.state.editar&&
               
                  <Button   variant="contained" 
                      onClick={e => { this.setState({editar:true})

                    }}    
                      color="primary"
                      style={{top:'1%' }}
                  >Editar</Button>
                }
               {this.state.editar&&
               <>
             
                  <Button  variant="contained"    style={{top:'1%', backgroundColor:'orange' }}
                      onClick={e => { 
                        const rowData=this.state.rowData
                        const esbueno= ValidateIBAN(rowData.IBAN)||rowData.IBAN===''
                        if(esbueno){
                          this.setState({errorIBANComunidad:false,textoError:''})
                        }else{ this.setState({errorIBANComunidad:true,textoError:'Formato incorrecto'})}
                      
                        const esbueno2= ValidateIBAN(rowData.despacho.Iban)||rowData.despacho.Iban===''
                        if(esbueno2){
                          this.setState({errorIBANDespacho:false,textoError:''})
                        }else{ this.setState({errorIBANDespacho:true,textoError:'Formato incorrecto'})}
    
                      
                        this.setState({ IBAN:rowData.IBAN, IBANDespacho:rowData.despacho.Iban,
                          editar:false,Cobro:rowData.Cobro,
                        })
                  
                      
                      }}    
                      color="primary"

                  >Cancelar</Button>
          
                 
                  <Button variant="contained"   color="primary" type="submit"  style={{top:'1%', left:5 }}

                    disabled={this.state.IBANDespacho.trim()===''|| !this.state.Cobro || this.state.IBAN===''&&this.state.Pago_App===true}
                    onClick={e => {
                      this.setState({editar:false})
                      const despacho={...this.state.rowData.despacho,
                                      'Iban':this.state.IBANDespacho}
                      const newData={...this.state.rowData,                             
                        IBAN:this.state.IBAN,
                        Cobro:this.state.Cobro,
                       despacho:despacho,
                       Pago_App:this.state.Pago_App
                      }
                   //  newData.despacho['Iban']=this.state.IBANDespacho
                      const cambios=[{'newData':newData, oldData:this.state.rowData}]

                      actualizar(cambios,this.state.comus,this.props.firebase).then((lista)=>{
                        if(lista){this.setState({comus:lista})}
                        else{this.setState({open:true})
                          //Llamamos a unaactualizacion completa porque se ha  cambiado un dato del despacho y afecta a varias lineas de la tabla
                          this.componentDidMount()}
                      })
                                          
                      
                    }}    
                  >Guardar</Button>
                  </>
                }
             </Paper>

            </Modal>
  

            <Modal
                           aria-labelledby="transition-modal-title"
                           aria-describedby="transition-modal-description"
                           style={{display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center'}}
                            open={this.state.avisos_enviados}
                            onClose={e=> this.setState({avisos_enviados:false,resultado_aviso:[],
                              usuariosTotal:[]})}                                        
                                                    >
                   <Paper style={{maxHeight: 500, overflow: 'auto', alignContent:'center',  alignItems:'center',
                      marginLeft:20, marginRight:20,marginTop:20}}>   
                       <h2  style={{   marginLeft:20,color: '#00a049' }}>RESULTADOS AVISOS ENVIADOS </h2>
                  
                     
                       <h3  style={{  marginLeft:20, color: '#00a049' }}>Total dispositivos Ok: {totalMensajesOK}</h3>                              
                       <h3  style={{  marginLeft:20, color: '#00a049' }}>Total dispositivos KO: {totalMensajesKO}</h3>                              
          
                       <MaterialTable
                                 options={{
                                  doubleHorizontalScroll:true,
                                  exportButton: {
                                    csv: true,
                                    pdf: false
                                  },
                                 filtering: true,
                                 selection: false,   
                                 pageSize: 10,  
                                 grouping: true,
                                 exportDelimiter: ";",
                                 exportAllData: true,
                                 pageSizeOptions:	[5, 10, 20],
                                 headerStyle: {
                                   backgroundColor: '#00a049',
                                   color: 'white'},
                                   paddingTop:5,
                                   paddingBottom:5
                                         
                               }}
                               
                               localization={{ 
                               
                                toolbar: {
                                  nRowsSelected: 'TOTAL DISPOSITIVOS que se ha enviado el aviso: {0}'
                              },
                              }}
 
                               icons={tableIcons}
                        
                               title={'TABLA DETALLE RESULTADOS ENVÍO AVISO'}
 
                               columns={[
                                {title: 'Nombre', field: 'Nombre'},
                                {title: 'Apellido', field: 'Apellido'},
                                {title: 'DNI', field: 'DNI'},
                                {title: 'Administrador', field: 'Administrador'},
                                
                                {title: 'Comunidad', field: 'Comunidad'},
                                {title: 'Error de Envío', field: 'error'},
                                {title: 'Código Error', field: 'codigo'},
                                {title: 'Mensaje Error', field: 'mensaje'},
                            
                                {title: 'Dispositivo', field: 'Dispositivos'},

                              ]}
                 
                              data={this.state.resultado_aviso}
 
                          />
                  </Paper>
              </Modal>

              <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}

              open={this.state.activarEstructura}
              onClose={e=>{
                this.setState({activarEstructura:false, comus_enviar:[]})
                if (this.tableRef.current) {
                  //Lo hacemos para quitar la seleccion de la tabla 1 que la lleva a la tabla 2
                    this.tableRef.current.onAllSelected(false)
                  }}}

            >
           {this.state.activarEstructura&&
            <Paper style={{maxHeight: 500, overflow: 'auto', alignContent:'center',  alignItems:'center',
                        marginLeft:20, marginRight:20,marginTop:20}}>   
                        <Tooltip title="Lanzamos los cobros contra el SEPA">
                
                            <Button 
                            onClick={e => {
                                this.setState({open:true, mostrarTabla: false ,activarEstructura:false})
                                const getData = async () => {
                                   return Promise.all(
                                                 
                                    this.state.sepa_enviar.map((registro)=>{
                                      return new Promise(resolve => {  
                                                      //Miramos el cliente si es despacho o comunidad. Esto se mira si en el listado campo Id comunidad está vacio,sería despacho
                  
                                              let Pagos=''
                                              const despacho=registro.despacho
                                              const tabla= ( (registro.Id===''&&registro.Nombre==='') ? '/Despacho':'/Comunidad/'+registro.uid)
            
                                              if(registro.Id===''&&registro.Nombre===''){
                                                  //Estamos en un pago po despacho.
                                                  Pagos =despacho.Pagos
                  
                                              }else{
                                                Pagos =registro.Pagos
                  
                                              }
                                              if(Pagos&&Pagos.Cliente){
                                                //EL cliente ya exite y recogemos los datos
                                               const cliente=Pagos.Cliente
                                               recuperarCliente(cliente.id,this.props.firebase,Stripe).then((resultado)=>{      
                                                const precioid=( (registro.Id===''&&registro.Nombre==='') ? despacho.Pagos.Cliente.precio.id:registro.Pagos.Cliente.precio.id)                             
                    
                                                if(registro.IBAN!==cliente.metodoPago.IBAN){
                                                  alert('Se ha actualizado el IBAN y se debe generar un nuevo método de pago para su correcto cobro')
                                                //SE ha modificado el IBAN del cliente y hay que generar un nuevo método de pago
                                                actualizaMetodoPagoCliente(cliente.id,tabla,despacho.BaseDatos, despacho.Code,this.props.firebase,registro.IBAN,Stripe).then((res)=>{
                                        
                                                  crearSetupIntentIncial(this.state.stripe,this.props.firebase,cliente.id,res,tabla,despacho.BaseDatos, despacho.Code,Stripe).then((pi)=>{
                                                    const devuelve={...resultado,metodoPago:res,despacho:despacho,tabla:tabla,IBAN:registro.IBAN,cantidad:registro.Cobro,Pagos:Pagos,
                                                      suscripcion:registro.suscripcion, meses:registro.meses
                                                      ,precioid:precioid}
                                
                                                   
                                                    resolve(devuelve)
            
                                                  })
            
                                                }) 
                                                }else{
                                                  const devuelve={...resultado,metodoPago:cliente.metodoPago,despacho:despacho,tabla:tabla,IBAN:registro.IBAN,cantidad:registro.Cobro,
                                                    suscripcion:registro.suscripcion, meses:registro.meses
                                                    ,precioid:precioid,Pagos:Pagos}
                                                    resolve(devuelve)
            
                                                  }
                                              })
                                              }else{
                                                //CLiente no existe y hay que crearlo
                                                const correo=despacho.Correo
                                                const registroFinal= ( (registro.Id===''&&registro.Nombre==='') ? {
                                                  //Es un despacho 
                                           
                                                  email:correo.trim(),
                                                  metadata:{'codigo':despacho.Codigo,CIF:despacho.CIFNIF, 'Code':(despacho.Code ? despacho.Code:''),'tabla':'/Despacho','tipo':'despacho'},
                                                  name:despacho.Nombre,
                                                  address: {
                                                    "city": despacho.Poblacion,
                                                    "country": "ES",
                                                    "line1": despacho.Direccion,
                                                    "line2": "",
                                                    "postal_code": despacho.Cp,
                                                    "state": ""
                                                  }, expand: ['tax'],
                                                
                                                  
                                                }:{
                                                  //Comporbamos que la comunidad tiene un correo para la facturación, sino usará el del 
                                                  email:(registro.Correo ? registro.Correo:correo.trim()),
                                                  metadata:{'codigo':despacho.Codigo,CIF:registro.Cif, 'Code':(despacho.Code ? despacho.Code:''),'tabla':'/Comunidad/'+registro.uid,'comunidad':registro.uid, 'tipo':'comunidad'},
                                                  name:registro.Nombre,
                                                  address: {
                                                    "city": registro.Poblacion,
                                                    "country": "ES",
                                                    "line1": registro.Direcciones[0],
                                                    "line2": "",
                                                    "postal_code": registro.Cp,
                                                    "state": ""
                                                  }, expand: ['tax'],
                                                
                                                  
                                                }
                                                )
                                                crearCliente(registroFinal,tabla,despacho.BaseDatos, despacho.Code,this.props.firebase,registro.Cobro,registro.IBAN,Stripe).then((resultado)=>{
                                                if(resultado){
                                                  //Si el nuevo cliente es un despacho hay que actuizar el registro despacho 
                                                  const metadata=registroFinal.metadata
                                                  if(metadata.tipo==='despacho'){
                                                    despacho['Pagos']={'Cliente':resultado}
            //                                        despacho.Pagos['Cliente']=resultado
                                                  }
                                                  
                                                  const precioid=resultado.precio.id                             
                                                  const devuelve={...resultado,despacho:despacho,tabla:tabla,IBAN:registro.IBAN,cantidad:registro.Cobro, suscripcion:registro.suscripcion,
                                                  meses:registro.meses,precioid:precioid}
                                                  crearSetupIntentIncial(this.state.stripe,this.props.firebase,devuelve.id,devuelve.metodoPago,tabla,despacho.BaseDatos, despacho.Code,Stripe).then((pi)=>{
                                                  resolve(devuelve)
                                                  })
                                                }else{alert('No se ha  creado el cliente correctamente '+registroFinal.name); resolve(false)}
                                                })
                                             
                                                }
                                      })
                                 }))}
                                 getData().then(data => {
                                    //Lanzamos de cada uno los cobros
                                    data=data.filter((dat=>dat!==false))
                                    const getData2 = async () => {
                                      
                                      return Promise.all(
                                                 
                                      data.map(cobro=>{
                                        return new Promise(resolve => {  
                                          const despacho=cobro.despacho

                                          if(cobro.suscripcion){
                                            //El metodo de pago actual lo ponemos por defecto por si no ha puesto alcrear el cliente.
                                              crearNuevoPrecioSuscripcion(cobro.id,cobro.tabla,despacho.BaseDatos, despacho.Code,this.props.firebase,cobro.cantidad,Stripe,
                             
                             //Test
                       //                        (cobro.meses ? cobro.meses:1),cobro.metodoPago.id,'txr_1LuYkIItPJ2Wwqc99CGZy30s'                                    
                            //Produccion
                                             (cobro.meses ? cobro.meses:1),cobro.metodoPago.id,'txr_1Ltuc9ItPJ2Wwqc9rw5dRImb'
                                                ).then(()=>{
                                                resolve('suscripcion')})
    
                                          }else{
                         //         
                                         
                                          // Test                           
                          //                generarFactura(this.props.firebase,cobro,'txr_1LuYkIItPJ2Wwqc99CGZy30s',this,Stripe).then((result)=>{
                                            //Produccion
                                        generarFactura(this.props.firebase,cobro,'txr_1Ltuc9ItPJ2Wwqc9rw5dRImb',this,Stripe).then((result)=>{
                                            if(result.ok){
                                              resolve(result)
                                            }else{alert('La factura no se ha creado correctamente '+result.registro.name)
                                              resolve(false)}
                                            })
                           //               })
                                          }
                                        })
                                       })
                                    )}
                                    getData2().then(data2 => {
                                      data2=data2.filter((dat=>dat!==false))
                                      // Mirmamos cuantas faturas se han generado bien y pedimos confirmación de lanzarlas
                                      let log='Pagos que no han generado factura:\n'
                                      data2.map((factura)=>{
                                          if(!factura.ok&&factura!=='suscripcion'){
                                            log=log+factura.intent.name+'\n' 
                                          }
                                      })
                                      let facturabuena=data2.filter((fact)=>fact!=='suscripcion'&&fact.ok===true)
                                      let suscripcionBuena=data2.filter((fact)=>fact==='suscripcion')
                                      let cadena=''
                                      if(facturabuena.length>0){
                                      cadena='Se han generado '+facturabuena.length+' PRE FACTURAS, ¿Quieres lanzar el cobro y cerrar la factura?'+(data2.length!==facturabuena.length+suscripcionBuena.length ? '\n'+log:'\n')
                                      }
                                      if(suscripcionBuena.length>0){
                                        cadena=cadena+'\n'+'Se han generado '+suscripcionBuena.length+' suscripcion/es'
                                        
                                      }
                                      
                                      if(window.confirm(cadena)){
                                        if(facturabuena.length>0){
                                          lanzarFactura(this.props.firebase,facturabuena,Stripe).then((resultado=>{
                                            if(resultado){alert('Facturas lanzadas para cobro correctamente')}
                                            this.setState({open:false, mostrarTabla: true ,activarEstructura:true})
                                          }))
                                    
                                        }else  this.setState({open:false, mostrarTabla: true ,activarEstructura:true}) 
                                      }else{   this.setState({open:false, mostrarTabla: true,activarEstructura:true})}
            
                                    })
            
          
                                  })
                              }}
                              

                            
                                variant="outlined"
                                disabled={this.state.sepa_enviar.length === 0}
                                size="large" color="primary" startIcon={<AttachMoney />}>LANZAR SEPA
                            </Button>
                        
                           </Tooltip>
                        
                          
                        
                        
                        <MaterialTable
                            components={
                              {
                        Body: props => ( <MTableBody {...props} onFilterChanged={(columnId, value) => {
                            props.onFilterChanged(columnId, value);
                            const field= props.columns[columnId].field
                            this.filterRef2[field] = value
                        }} />),

                 
                        
                        
                        }}
                        localization={{
                         
                          header: {
                            actions: 'Acciones'
    
                          },
                          toolbar: {
                            nRowsSelected: '{0} Filas, total cobro (SIN IVA): '+this.state.total_a_cobrar
                          },
                        }}
                        onSelectionChange={(rows) => {
                      
                          if (rows.length > 0) {
                            const rowss=[...rows]
                            this.setState({ sepa_enviar: rowss })

                          //sumamos los totales de cobro para indicar el coro total de las filas seleccionadas
                            let total=0
                            rows.map((fila)=>{
                              total=total+Number(fila.Cobro)
                            })
                            this.setState({total_a_cobrar:total})
                          }else{ this.setState({total_a_cobrar:0, sepa_enviar: []})}
                           
                        }}
                            
                        options={{
                            doubleHorizontalScroll:false,
                            actionsColumnIndex: -1 ,
                            exportButton: {
                              csv: true,
                              pdf: false
                            },
                            addRowPosition: 'first',
                            filtering: true,
                            selection: true,
                            pageSize: 15,
                            exportDelimiter: ";",
                            exportAllData:true,
                            maxBodyHeight: "100vh",
                            pageSizeOptions: [5, 15, 20],
                            headerStyle: {
                              backgroundColor: '#00a049',
                              color: 'white',
                              paddingTop: 5,
                              paddingBottom: 5
                            },
                          }}
                        icons={tableIcons}
                        title={<h4 style={{ color: '#00a049' }}>VISTA PREVIA LANZAR PAGOS, total lanzar: {this.state.comus_enviar.length}, código IBAN con error:{this.state.ibanMal}</h4>}
                        columns={[
                          {
                            title: 'Despacho', field: 'despacho.Nombre',editable:'never', defaultFilter: this.filterRef['despacho.Nombre'], cellStyle: {
                              width: 300,
                              minWidth: 300
                            },
                            headerStyle: {
                              width: 300,
                              minWidth: 300
                            }
                          },
                          {
                            title: 'IBAN a cargar cobro', field: 'IBAN',editable:'never',
                            
                            render: rowData =>
                            {
                              const IBAN=rowData.IBAN
                              return(                         
                              <TextField InputLabelProps={{ shrink: true }} //inputProps={{ type: 'number'}}   
                              disabled={true}
                         //     multiline
                              error={!ValidateIBAN(IBAN)}
                              color="primary"
                              margin="normal"
                              fullWidth
                              helperText={ValidateIBAN(IBAN) ? '':'Formato incorrecto'}
                              //maxRows={5}
                              value={IBAN}
                              type="text"
                            />)
                          },
                            
                            defaultFilter: this.filterRef['despacho.Iban'], cellStyle: {
                              width: 300,
                              minWidth: 300
                            },
                            headerStyle: {
                              width: 300,
                              minWidth: 300
                            }
                          },
                          { title: 'CIF', field: 'Cif',editable:'never', defaultFilter: this.filterRef['Cifcomunidad'], cellStyle: {
                            width: 100,
                            minWidth: 100
                          },
                          headerStyle: {
                            width: 100,
                            minWidth: 100
                          }
                        },
                          {
                            title: 'Comunidad', field: 'Nombre',editable:'never', defaultFilter: this.filterRef['Comunidad'], cellStyle: {
                              width: 300,
                              minWidth: 300
                            },
                            headerStyle: {
                              width: 300,
                              minWidth: 300
                            }
                          },
                          {
                            title: 'Id Comunidad', field: 'Id', defaultFilter: this.filterRef['Id'],editable:'never'
                        
                          },
            
                          {
                            title: 'Cantidad sin IVA', field: 'Cobro',editable:'never', type:'numeric', defaultFilter: this.filterRef['Cobro'], cellStyle: {
                              width: 200,
                              minWidth: 200
                            },
                            headerStyle: {
                              width: 200,
                              minWidth: 200
                            }
                          },
                          { title: "Lanzar como Suscripcion",
                          field: "suscripcion", defaultFilter: this.filterRef['suscripcion'],
                          type: "boolean",

                          render: rowData =>checkBoxLectura(rowData.suscripcion),
                          editComponent: ({ onChange, onRowDataChange, rowData, ...p }) => (
                            checkBoxModificar('suscripcion',false,rowData, onRowDataChange,null)
                           )
                       }, {
                        title: 'Cada cuanto meses', field: 'meses', type:'numeric',initialEditValue:1, cellStyle: {
                          width: 100,
                          minWidth: 100
                        },
                        headerStyle: {
                          width: 100,
                          minWidth: 100
                        }
                      },
            
                        ]}
                        editable={{                
                          onBulkUpdate: (changes) =>
                          new Promise((resolve, reject) => {
                            setTimeout(() => {
                              // write your logic here
                              const cambios= Object.values(changes)
                              let lista_nueva=this.state.comus_enviar
                              cambios.map(cambio=>{             
                                const newData=cambio.newData
                                const index =Number(cambio.oldData.tableData.id)
                                lista_nueva[index]=newData
                                })
                                this.setState({comus_enviar:lista_nueva})
                                resolve()
                             
                            }, 500);
                          })
                                  }}
                        data={this.state.comus_enviar}
                      /> 
            </Paper>  } 
           </Modal>
       
<Modal
       aria-labelledby="transition-modal-title"
       aria-describedby="transition-modal-description"
       style={{
           display: 'flex',
           alignItems: 'center',
           justifyContent: 'center'
       }}
       open={this.state.mostrarAyuda}
       onClose={e=>this.setState({mostrarAyuda:false})}
 >
  {this.state.mostrarAyuda&&<ModalTutoriales Pdfs= {this.state.VideoTuto}/> }
</Modal>
       
<Box mt={40}>{!this.state.open&& <Copyright />}  </Box>
     <Backdrop color='primary' open={this.state.open}>
                  <CircularProgress color="primary" />
    </Backdrop>
     </ThemeProvider>
</Grid>
    );
  }
}

function actualizar(cambios,lista_final,firebase){
  
  return new Promise(resolve => {  
    if(cambios.length===0){resolve(lista_final)}
    else{
      const lista_nueva=[...lista_final]
      const callable1 =firebase.functions.httpsCallable('actualizaTabla') 
      let soloLista=true 
      const getData = async () => {
        return Promise.all(
          cambios.map(cambio=>{
            return new Promise(resolve => { 
            const newData=cambio.newData
            const oldData=cambio.oldData
            const newdespacho=newData.despacho
            const oldespacho=oldData.despacho
            const index =Number(cambio.oldData.tableData.id)
            lista_nueva[index]=newData
            const despacho =newData.despacho
            const tabla='/Comunidad/'+newData.uid
            const cobro=newData.Cobro
            const registro={
              'Cobro':cobro,
              'IBAN':newData.IBAN,
              'Pago_App':newData.Pago_App
            }
            //Miramos si ha cambiado el precio del servicio, sies así atualizamos el precio en Stipe y en la tabla si ya existe de la comunidad
            const Pagos=newData.Pagos
            if(newData.Cobro!==oldData.Cobro&&Pagos&&Pagos.Cliente){
              const precioid = Pagos.Cliente.precio.id
              const callable2 =firebase.functions.httpsCallable('actualizaPrecio')
              callable2({tabla: (despacho.Code ? despacho.Code:'')+tabla,BaseDatos:despacho.BaseDatos,cantidad:newData.Cobro,
              precioid:precioid})        
              .then((result2) => {     
                const mela= 'ok'
               
                })
                .catch((e)=>{
                  const mela= 'ko'
                
                })

            }

            callable1({tabla: (despacho.Code ? despacho.Code:'')+tabla,BaseDatos:despacho.BaseDatos,registro:registro})        
          .then((result2) => {     
            if(newdespacho.Iban !==oldespacho.Iban){
               soloLista=false
              callable1({tabla: (despacho.Code ? despacho.Code:'')+'/Despacho',BaseDatos:despacho.BaseDatos,registro:{'Iban':newdespacho.Iban}})        
              .then((result2) => {     
                resolve(true)
               
                })
                .catch((e)=>{
                  resolve(false)
                
                })
            }else{resolve(false)}
            })
            .catch((e)=>{
              resolve(false)
      
            })
            })
      }))
          
      
      }
      
      getData().then(data => {
        if(soloLista){
          const lista  =lista_nueva
         resolve(lista)
        }else{resolve(false)}
      })
      

    }

})

}   
                    
function validarCambio(cambios, lista_final){
return true
var correcto=true
cambios.map(cambio=>{
    var newData=cambio.newData
    const tableDataid=cambio.oldData

//    if(newData.portal===''){newData.portal=undefined}
//    if(newData.piso===''){newData.piso=undefined}
 //   if(newData.letra===''){newData.letra=undefined}
    //if(newData.direccion===''&&newData.numero===''&&newData.portal===''&&newData.piso===''&&newData.letra===''){
        //Hay que borrar la línea
    //    correcto=false
    //  return}

    if(newData.direccion===''||!newData.direccion){
      correcto=false
      if(tableDataid){
        const linea =Number(tableDataid.tableData.id)+1
        correcto='La dirección no puede ser vacía, línea de la tabla: ' + linea
        //alert('La dirección no puede ser vacía, línea de la tabla: ' + linea)
      }else{
        correcto='La dirección no puede ser vacía'
       // alert('La dirección no puede ser vacía')
      }
      return
    }


    if(newData.numero===''||!newData.numero){
      //El numero es vacio  no puede ser
    //  correcto=false
      if(tableDataid){
        const linea =Number(tableDataid.tableData.id)+1
        correcto='El número no puede ser vacío, línea de la tabla: ' + linea
       // alert('El número no puede ser vacío, línea de la tabla: ' + linea)
      }else{
        correcto='El número no puede ser vacío'
//        alert('El número no puede ser vacío')
      }
      return}
      if((newData.portal===''||!newData.portal)&&(newData.piso&&newData.piso!==''||newData.letra&&newData.letra!=='')){
        //El portal y piso o letra tiene algun valor
     //   correcto=false
        if(tableDataid){
          const linea =Number(tableDataid.tableData.id)+1
          correcto='El portal no puede ser vacío si en la misma línea piso o letra tiene algún valor, línea de la tabla: ' + linea
      ///    alert('El portal no puede ser vacío si en la misma línea piso o letra tiene algún valor, línea de la tabla: ' + linea)
        }else{
          correcto='El portal no puede ser vacío si en la misma línea piso o letra tiene algún valor'
       //   alert('El portal no puede ser vacío si en la misma línea piso o letra tiene algún valor')
        }
        return}

        if((newData.piso===''||!newData.piso)&&newData.letra&&newData.letra!==''){
          //El  piso es vacío y letra tiene algun valor
      //    correcto=false
          if(tableDataid){
            const linea =Number(tableDataid.tableData.id)+1
            correcto='El piso no puede ser vacío si en la misma línea letra tiene algún valor, línea de la tabla: ' + linea
      
          //  alert('El piso no puede ser vacío si en la misma línea letra tiene algún valor, línea de la tabla: ' + linea)
          }else{
            correcto='El piso no puede ser vacío si en la misma línea letra tiene algún valor'
      
      //      alert('El piso no puede ser vacío si en la misma línea letra tiene algún valor') 
          }
          return}
      
          //Comprobamos que el campo de porcentaje sea número

          if(isNaN(newData.porcentaje)){
            correcto='El campo porcentaje debe ser un valor númerico'
            return

          }

  
      
        const filtroRepe = lista_final.filter((dire, index)=>{
          
          return(
            dire.direccion===newData.direccion&&
            dire.numero===newData.numero&&
            dire.portal===newData.portal&&
            dire.piso===newData.piso&&
            dire.letra===newData.letra&& index!==(tableDataid ? tableDataid.tableData.id:-199))
        })
        if(filtroRepe.length>0){
        //El cambio o el nuevo registro está repetido y no se puede grabar
      //  correcto=false
          if(tableDataid){
            const linea =Number(tableDataid.tableData.id)+1
            correcto='Ya existe una combinación con los mismos datos de dirección, número, portal, piso y letra. Por favor cambia alguno de los valores para que el registro sea único,, línea en la tabla: ' + linea
//            alert('Ya existe una combinación con los mismos datos de dirección, número, portal, piso y letra. Por favor cambia alguno de los valores para que el registro sea único,, línea en la tabla: ' + linea)
          }else{
            correcto='Ya existe una combinación con los mismos datos de dirección, número, portal, piso y letra. Por favor, cambia alguno de los valores para que el registro sea único'
//            alert('Ya existe una combinación con los mismos datos de dirección, número, portal, piso y letra. Por favor, cambia alguno de los valores para que el registro sea único')
          }
          return
        }
        //Miramos si el nuevo registro o el nuevo cambio existen pero con mas datos
        const filtro = lista_final.filter((dire, index)=>{
          //Primer paso es que si el rgustro nuevo tiene menos datos que el registro a comparar
          const primerPaso= dire.direccion===newData.direccion&&
          dire.numero===newData.numero&&(newData.portal ? (dire.portal===newData.portal&&(newData.piso ? dire.piso===newData.piso
            &&(dire.letra ? dire.letra===newData.letra:true):true )):true)&& index!==(tableDataid ? tableDataid.tableData.id:-199)
         // dire.piso===(newData.piso ? newData.piso:''||undefined)&&
          if(primerPaso){return(true)}
          else{
            //Segunfdo paso es que el regstro nuevo tiene mas datos que el registro que ya existe.
            return(
              dire.direccion===newData.direccion&&
              dire.numero===newData.numero&&(dire.portal ? (newData.portal===dire.portal&&(dire.piso ? newData.piso===dire.piso
                &&(newData.letra ? newData.letra===dire.letra:true):true )):true)&& index!==(tableDataid ? tableDataid.tableData.id:-199)
             // dire.piso===(newData.piso ? newData.piso:''||undefined)&&
              //dire.letra===(newData.letra ? newData.letra:''||undefined)&& index!==(tableDataid ? tableDataid.tableData.id:-199)
              )
  
          }
        })
        if(filtro.length>0){
        //  correcto=false
          if(tableDataid){
            const linea =Number(tableDataid.tableData.id)+1
            correcto='Ya existe una combinación que está usando portal, piso y/o letra, por lo que no puedes usarla. Por favor cambia alguno de los valores para que el registro sea único, línea en la tabla: ' + linea
//            alert('Ya existe una combinación que está usando portal, piso y/o letra, por lo que no puedes usarla. Por favor cambia alguno de los valores para que el registro sea único, línea en la tabla: ' + linea)
          }else{
            correcto='Ya existe una combinación que está usando portal, piso y/o letra, por lo que no puedes usarla. Por favor, cambia alguno de los valores para que el registro sea único'
//            alert('Ya existe una combinación que está usando portal, piso y/o letra, por lo que no puedes usarla. Por favor, cambia alguno de los valores para que el registro sea único')
          }
       
          return
        }


      
    })
return correcto
}

const condition = authUser => !!authUser;
export default withFirebase(withAuthorization(condition)(Facturacion));