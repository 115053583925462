import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Portada from '../Landing/index copy'
import Grid from '@material-ui/core/Grid';
import { createTheme  } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import  green from '@material-ui/core/colors/green';
import Cabecera from '../Cabecera/index_365';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import Copyright from '../Privacidad/copyright'

const theme = createTheme ({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
});


const PasswordForgetPage = () => (
  <div>
    <PasswordForgetForm />
  </div>
);
const INITIAL_STATE = {
  email: '',
  error: null,
};
class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }
  onSubmit = event => {
    const { email } = this.state;
    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });
    event.preventDefault();
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
  
    const { email, error } = this.state;
    return (
      <Grid >
            <ThemeProvider theme={theme}>       
      <Cabecera/>
      <hr/>      
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={1}>  
       
            <Grid item  xs={12} sm={12} md={7}  >
                <Portada/>
                </Grid>  
            
            
            
                <Grid item  xs={11} sm={11} md={5}  alignItems="center" spacing={1}  >
            <Grid    style={{position:'absolute', top:130, right: 10, width:'40%'  }}>      
         
                  <Grid container direction="row"  justifyContent="center" >    
                      <Avatar   backgroundColor= '#12C10F'>
                               <LockOutlinedIcon />
                      </Avatar>      
                  </Grid> 

                  <Grid container direction="row"  justifyContent="center" >
                      <h1>Recupera tu contraseña</h1>
                  </Grid>    
                  <form onSubmit={this.onSubmit}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          label="Correo electrónico"
                          name="email"
                          autoComplete="email"
                          autoFocus
                          value={email}
                          onChange={this.onChange}
                          type="text"
                          placeholder="Introduce el correo de tu cuenta en Comunidad 365"
        
                        />
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                        
                          >
                          Recuperar
                        </Button>
                        {error && <p>{error.message}</p>} 
                        </form>
                       
             
            </Grid></Grid>
    
    </Grid>
    <Box mt={5}>
      <Copyright />
    </Box>
       

    </ThemeProvider>  
   </Grid>
    );
  }
}
const PasswordForgetLink = () => (
  <p>
    <Link to={ROUTES.PASSWORD_FORGET}>¿Has olvidado tu contraseña?</Link>
  </p>
);
export default PasswordForgetPage;
const PasswordForgetForm = withFirebase(PasswordForgetFormBase);
export { PasswordForgetForm, PasswordForgetLink };