//
// Primero se crea el cliente, un cliente nuevo por cada despacho que paque su factura y por cada  comunidad que pague su actura.
// Crear cliente crear el cliente en Stripe con los datos fiscales adecuados, tambien crea un Proscuto con un precio inicial.
// Prodcuto  precio no se utilizan para una fatura maunual, se utilizará para la subscripción.
// Se ha añadido cuando se modifica el precio en el registro de la Comunidad 365, que se actualice el precio y producto creado en Stripe
// Creado el cliente se crea el SetupIntent, este sirve para autorizar el cobro por SEPA del IBAN asociado al nuevo cliente.
// Una vez autorizado podemos lanzar la nueva factura. 


export function crearCliente (registro,tabla,BaseDatos,Code,firebase,cantidad,IBAN,stripe){
    
    return new Promise((resolve) => { 
    const callable2 = firebase.functions.httpsCallable('crearClienteTest')
    const NombreProducto='DIGITALIZACIÓN DE PROCESOS DE GESTIÓN COMUNIDAD, MANTENIMIENTO, SOPORTE, LICENCIAS Y ACTUALIZACIONES, CONTROL LABORAL, INCIDENCIAS, AVISOS, Y SINIESTROS 24/365, DIGITALIZACIÓN DE ARCHIVO Y CARGA WEB, RESERVA DE ESPACIOS, VIDEOS SEGURAS, ENVÍO AVISOS'
    callable2({registro:registro,tabla:tabla,BaseDatos:BaseDatos,Code:Code,cantidad:cantidad,IBAN:IBAN,NombreProducto:NombreProducto
    ,stripe:stripe}).then((result) => { 
       resolve(result.data)
    })
    .catch(error => {
        resolve(false)
        const a = error
    }) 
    })

} 

export function crearNuevoPrecioSuscripcion (cliente,tabla,BaseDatos,Code,firebase,cantidad,stripe,carencia,default_payment_method,tax){
    
    return new Promise((resolve) => { 
        const callable3 = firebase.functions.httpsCallable('anadirPagoPorDefecto')
        callable3({cliente:cliente,default_payment_method:default_payment_method
            ,stripe:stripe}).then((dooo) => { 
  
            const callable2 = firebase.functions.httpsCallable('crearNuevoPrecioSuscripcion')
            const NombreProducto='DIGITALIZACIÓN DE PROCESOS DE GESTIÓN COMUNIDAD, MANTENIMIENTO, SOPORTE, LICENCIAS Y ACTUALIZACIONES, CONTROL LABORAL, INCIDENCIAS, AVISOS, Y SINIESTROS 24/365, DIGITALIZACIÓN DE ARCHIVO Y CARGA WEB, RESERVA DE ESPACIOS, VIDEOS SEGURAS, ENVÍO AVISOS'
            callable2({cliente:cliente,tabla:tabla,BaseDatos:BaseDatos,Code:Code,cantidad:cantidad,carencia:carencia,NombreProducto:NombreProducto
            ,stripe:stripe,tax:tax}).then((result) => { 
            resolve(result.data)
            })
            .catch(error => {
                resolve(false)
                const a = error
            }) 
        })
        .catch(error => {
            resolve(false)
            const a = error
        })
    })

} 




export function actualizaMetodoPagoCliente (clienteid,tabla,BaseDatos,Code,firebase,IBAN,stripe){
    
    return new Promise((resolve) => { 
    const callable2 = firebase.functions.httpsCallable('actualizaMetodoPagoClienteTest')
    callable2({tabla:tabla,BaseDatos:BaseDatos,Code:Code,clienteid:clienteid,IBAN:IBAN,stripe:stripe}).then((result) => { 
       resolve(result.data)
    })
    .catch(error => {
        resolve(false)
        const a = error
    }) 
    })

} 
export function crearSetupIntentIncial(stripe,firebase,idcliente,metodoPago,tabla,BaseDatos,Code,Stripe){
 
    return new Promise((resolve, reject) => {
    const callable2 = firebase.functions.httpsCallable('setupIntentTest')
    callable2({id:idcliente,tabla:tabla,BaseDatos:BaseDatos,Code:Code,stripe:Stripe})//cobro.email})        
    //      generarPedido(firebase,cobro)
      .then((result) => {
        const intent=result.data
        if(window.confirm('El cliente es nuevo debes confirmar el mandato '+intent.id)){
            stripe.confirmSepaDebitSetup(intent.client_secret, {
                payment_method: metodoPago.id
            }).then((result)=>{
              if (result.error) {
    
                // Show error to your customer.
                    resolve(false)
          
             }else {
                alert('Mandato confirmado')
                const callable1 =firebase.functions.httpsCallable('actualizaTabla') 
                callable1({tabla: (Code ? Code:'')+tabla+'/Pagos/Cliente/',BaseDatos:BaseDatos,registro:result})        
                .then((result2) => {  resolve(result)    })               
              }
            }).catch(error => {
                resolve(false)
            })

        }
      

        
       })
    })
}
export function  generarFactura(firebase,registro,tasa,tthis,stripe)  {
    const callable2 = firebase.functions.httpsCallable('generarFacturaTest')
    return new Promise((resolve, reject) => {
const metadata=registro.metadata
const despacho=registro.despacho
//Si la cantidad del total de cada item es dirente a la ultima factura que emitió se debe actulizar el precio
const cantidad=Number(registro.cantidad)
let unit_amount=cantidad*100
if(registro.Pagos&&registro.Pagos!==''&&registro.Pagos.Cliente){
    const cliente= registro.Pagos.Cliente
   
    const precio= cliente.precio
    unit_amount=precio.unit_amount
}


if(unit_amount!==cantidad*100){
    const callable3 =firebase.functions.httpsCallable('actualizaPrecioTest')
    callable3({tabla: (despacho.code ? despacho.code:'')+registro.tabla,BaseDatos:despacho.BaseDatos,cantidad:cantidad,
    precioid:registro.precioid,stripe:stripe})        
    .then((result2) => {    
        const resul=result2.data
        const registro2={...registro,precioid:resul.id} 
      
        let comus_enviar=tthis.state.comus_enviar
        const index = comus_enviar.findIndex(ele=>ele.IBAN===registro.IBAN)
        comus_enviar[index].despacho.Pagos.Cliente.precio=resul
        tthis.setState({comus_enviar:comus_enviar})
      
        callable2({registro:registro2,tasa:tasa,stripe:stripe})        
        .then((result) => { 
            const intent=result.data
            resolve({ok:true,intent,metodoPagoId:registro.metodoPago.id})
        }).catch(error => {
            resolve({ok:false,registro})
        }) 
     
      })

}else{
    callable2({registro:registro,tasa:tasa,stripe:stripe})          
    .then((result) => { 
        const intent=result.data
        resolve({ok:true,intent,metodoPagoId:registro.metodoPago.id})
    }).catch(error => {
        resolve({ok:false,registro})
    }) 
 



}
})  

};

export function  lanzarFactura(firebase,facturas,stripe)  {
    const callable2 = firebase.functions.httpsCallable('lanzarFacturaTest')
    return new Promise((resolve, reject) => {

    callable2({facturas:facturas,stripe:stripe})            
    .then((result) => { 
        const intent=result.data
        resolve(true)
    }).catch(error => {
        resolve(false)
    }) 
    })  
};



export function recuperarCliente (id,firebase,stripe){
    return new Promise((resolve, reject) => {
    const callable2 = firebase.functions.httpsCallable('recuperarClienteTest')
    callable2({cliente:id,stripe:stripe}).then((result) => { 
        resolve(result.data)
    })
    .catch(error => {
        resolve(false)
        const a = error
    })
    })
    


}

//***********************Funciones que no se usan********************************************************************************************
export function  lanzarCobroDirecto  (firebase,stripe,cobro)  {
    const despacho= cobro.despacho
    const Code = (despacho.Code ? despacho.Code:null)
    const callable2 = firebase.functions.httpsCallable('generarCobros')
    const metadatos=cobro.metadata
    return new Promise((resolve, reject) => {

    const cantidad=0// Number(cobro.cantidad)*100
 //   callable2({BaseDatos:despacho.BaseDatos,Code:Code,cantidad:cantidad,tipo:metadatos.tipo,id:cobro.id,tabla:cobro.tabla})        
    //      generarPedido(firebase,cobro)
    crearSetupIntentIncial(firebase,cobro.id)
      .then((result) => { 
                const intent=result.data
                stripe.confirmSepaDebitPayment(intent.client_secret, {
                        payment_method: {
                              sepa_debit:{'iban':cobro.IBAN},
                              billing_details: {'name': cobro.name,'email':cobro.email},
                        }
                    }).then((result)=>{
                      if (result.error) {
         
                        // Show error to your customer.
                            resolve(false)
                  
                     }else {
               
                        resolve(result)
                      }
                    }).catch(error => {
                        resolve(false)
                    })
            }).catch(error => {
                const a = error
                resolve(false)
            }) 
            })  
        
};


export function  generarPedido  (firebase,registro)  {
    const despacho= registro.despacho
    const Code = (despacho.Code ? despacho.Code:null)
    const callable2 = firebase.functions.httpsCallable('generarPedido')
    //const metadatos=registro.metadata
    return new Promise((resolve, reject) => {
     const precio =Number(registro.cantidad)*100
    callable2({BaseDatos:despacho.BaseDatos,Code:Code,id:registro.id,precio:precio,tabla:registro.tabla})        
    .then((result) => {
        const intent1=result.data
        const callable3 = firebase.functions.httpsCallable('recuperarPI')
        callable3({BaseDatos:despacho.BaseDatos,Code:Code,pi:intent1.payment_intent})        
        .then((result) => {
            resolve(result)
        }).catch(error => {
          
            resolve(false)
        }) 
      
    }).catch(error => {
       
        resolve(false)
    }) 
    })  
};

export default function  lanzamientoCobroSepa (IBAN,tthis) {
    // We don't want to let default form submission happen here,
    // which would refresh the page.


    const {stripe} = tthis.tthis.state


    if (!stripe) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const despachoCode= tthis.tthis.firebase.despachoCode
    const callable2 = tthis.tthis.firebase.functions.httpsCallable('setupIntent')
    callable2()        
            .then((result) => { 
              const setupIntent=result.data
             const iban =IBAN
    // For brevity, this example is using uncontrolled components for
    // the accountholder's name and email. In a real world app you will
    // probably want to use controlled components.
    // https://reactjs.org/docs/uncontrolled-components.html
    // https://reactjs.org/docs/forms.html#controlled-components

//    const accountholderName = event.target['accountholder-name'];
  //  const email = event.target.email;

    stripe.confirmSepaDebitSetup( setupIntent.client_secret, {
      payment_method: {
        sepa_debit: iban,
        billing_details: {
    //      name: accountholderName.value,
      //    email: email.value,
        },
      }
    }).then((result)=>{
       
        if (result.error) {
            // Show error to your customer.
            console.log(result.error.message);
          } else {
            const setupIntent=result.setupIntent
            
            const callable3 = tthis.tthis.firebase.functions.httpsCallable('actualizarCliente2')
            callable3({cliente:'cus_M66z3OuwVmhz3B',default_payment_method:setupIntent.payment_method})        
            .then((custom) => { 
                      const cliente=custom.data
                      const callable4 = tthis.tthis.firebase.functions.httpsCallable('crearSubscripcion')
                      callable4({cliente:'cus_M66z3OuwVmhz3B',precio:'price_1LQ46vItPJ2Wwqc9loFRMJXu',cantidad:'44'})        
                      .then((subscribcion) => {

                            const sub=subscribcion.data
                      })
            // Show a confirmation message to your customer.
            // The PaymentIntent is in the 'processing' state.
            // SEPA Direct Debit payments are asynchronous,
            // so funds are not immediately available.
          
                    })
                    .catch(error => {

                        const a = error
                    })
                    
        }
      
    }) .catch(error => {

        const a = error
    })
    }) 
};
