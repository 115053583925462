import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getDocuments } from '../../firebase/firebase.utils';
import Header from '../../components/header/header.component';

import { withFirebase } from '../../../Firebase';
import { withAuthorization } from '../../../Session';
import './deal.styles.scss';

const Deal= props => {
  const type = 'deal';

  const [dealState, setDealState] = useState({});
  const { dealId } = useParams();

  useEffect(() => {
    const getData = async () => {
      const deal = await getDocuments(dealId, type,props.firebase);
      
      setDealState(deal);
    }
    getData();
  }, [dealId]);

  return (
    <>
    <Header/>
    <div className='deal-page'>
      Deal Page
      <h3>{dealState.title}</h3>
      <div>Nombre: {dealState.name}</div>
      <div>Organización: {dealState.company}</div>
      <div>Valor Deal: {dealState.dealValue}</div>
      <div>Fecha: {dealState.date}</div>
    </div>  
    </>
  );
}



const condition = authUser => !!authUser;
export default withFirebase(withAuthorization(condition)(Deal));
