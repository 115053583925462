import React, { Component, forwardRef  } from 'react';
import ModalTutoriales,{traerTutoriales} from  '../Tutoriales/tutoriales'
import { withFirebase } from '../Firebase';
import { withAuthorization } from '../Session';


import {Paper,Box,TextField,IconButton,Grid,Backdrop,CircularProgress,List,ListItem,Input,
  Modal, Avatar,ListItemText, ListItemAvatar,ListItemSecondaryAction,Divider,Tooltip,Chip,
 InputLabel,MenuItem, FormControl,Select,Checkbox, Button,ButtonGroup,Dialog,DialogActions,
 DialogContent,DialogContentText,DialogTitle,FormHelperText } from '@material-ui/core';


import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { DropzoneArea } from "material-ui-dropzone";
import BorderColor from '@material-ui/icons/BorderColor';

import DeleteIcon from '@material-ui/icons/Delete';
import enviarAvisos, {cargarResultado} from './enviarAvisos'

import  { Breakpoint } from 'react-socks';


import MaterialTable from 'material-table'

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import 'firebase/storage';

import FolderIcon from '@material-ui/icons/Folder';
import './styles.css'


import BotonesTriger from '../Botones/botonTrige'
import Cabecera from '../Cabecera/index_365'

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import  green from '@material-ui/core/colors/green';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import SendIcon from '@material-ui/icons/Send';
import Copyright from '../Privacidad/copyright';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 350,
    },
  },
};


const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};



const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: green[500],
      claro: green[200]
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#e81446',
    },
  },
});

function carpeta (firebase){
  return new Promise ((resolve,reject)  => {  
    var usersObject = ''
    firebase.carpetas().once('value', snapshot => {
    usersObject = snapshot.val();
    })
    .then(()=> { resolve( usersObject )})
  })  
}


export function modificarNombrefun (path, pathnuevo,tthis){
  var moverFichero = tthis.props.firebase.functions.httpsCallable('moverFichero');
 

  let ref = tthis.props.firebase.storage_propio_completo(path);
  return new Promise ((resolve,reject)  => {  
   // resolve()
    ref.listAll().then(dir => {
     
      const getData = async () => {
        return Promise.all(dir.items.map(fileRef => {   
          return new Promise ((resolve,reject)  => { 
            moverFichero({nombre:path+"/"+fileRef.name, bucket:tthis.props.firebase.deposito,
           destino:pathnuevo+"/"+fileRef.name}).then(()=>{
              // Read result of the Cloud Function.
              
                if(dir.prefixes&&dir.prefixes.length>0){
                  dir.prefixes.forEach(folderRef => {
                     
                    const pathnuevo2 = folderRef.fullPath.replace(path,"" ); 
                    const pathnuevo1 =  pathnuevo+pathnuevo2
                  resolve(modificarNombrefun(folderRef.fullPath, pathnuevo1,tthis))
                  })                       

                }else{
                  resolve(true)}
               })
          })
        }) )}

        getData().then(data => {
         resolve (true)
            })    
      
     
    })
    .catch(error => {
        alert(error);
    })
  })

 
  
}
export function crearEstructurDirectorios2(PathDirectorioActual,directorio, directorioPrincipal, firebase, dentroDocumentos) {
  return new Promise ((resolve,reject)  => {  
            var carpetasApp = false
            var path1= PathDirectorioActual+ "/"+ directorio 
            if(directorioPrincipal==="Directorio Raiz"&&dentroDocumentos){
              carpetasApp =  window.confirm('Estás en el DIRECTORIO RAIZ, ¿Quieres crear en la nueva carpeta '+  directorio 
              + ' la estructura de directorios estándar para su uso en al App móvil?')
              }
            if(!dentroDocumentos){carpetasApp = true}

              const p0 = new Promise ((resolve,reject)  => {  
                          var newData=[] 
                          let  path=  PathDirectorioActual+ "/"+ directorio 
                          newData.push({
                            id: 1,

                            Nombre: directorio,
                            path: PathDirectorioActual+ "/"+ directorio,
                            image: "/static/media/Files-icon.2bad3ce9.png",
                            
                          })
                      
                        let storage2 =firebase.storage_propio_completo(`${path}/temporal.txt`) 
                        resolve( storage2.putString("temporal"))

                        })
                        p0.then( (data) => { 
                          if(carpetasApp){ 
                            carpeta(firebase).then((carpetas)=>{
                                  let promises = carpetas.map((itemRef) => {
                                    let pathNow = path1 +"/"+ itemRef
                                  let sigue= firebase.storage_propio_completo(`${pathNow}/temporal.txt`) 
                                    return( sigue.putString("temporal").then(
                                      
                                    ))
                                  
                                });
                                  Promise.all(promises).then(() => {    resolve (true)  })
                           })
                     
                          }else (resolve(false))
                        })
                        p0.catch((e)=>{
                        })
   })
}
export function handleClickBorrarFichero  (path, tthis, directorioPrincipal)  {
  
  var respuesta = window.confirm("¿Quieres borrar el fichero "+ path + "?")
    if(respuesta){
     
      let ref = tthis.props.firebase.storage_propio_completo(tthis.state.PathDirectorioActual)
          const p0 = new Promise ((resolve,reject)  => {  
            resolve( ref.child(path).delete());
         
            })
            .catch(error => {
                alert(error);
            })
          
      p0.then( (data) => { 
              tthis.buscarficheros(tthis.state.PathDirectorioActual,directorioPrincipal)
              })
      }    
}; 

export function handleClickBorrar  (path, tthis,Lista,entra)  {
  
  var respuesta =(entra ? window.confirm("¿Quieres borrar el directorio "+ path + "?"):true)
    if(respuesta){
        tthis.setState({abrirDialog:false})  
          let lista = Lista
          lista = lista.filter(directorio => directorio.path !== path)
        //  tthis.setState({dataCategoria3:lista})  
  
        let ref = tthis.props.firebase.storage_propio_completo(path);
          let Ref = tthis.props.firebase.storage_propio_completo();
         return new Promise ((resolve,reject)  => {  
            
            ref.listAll()
            .then(dir => {
              dir.items.forEach(fileRef => {
              //  this.deleteFile(ref.fullPath, fileRef.name);
                let ref1 = Ref.child(ref.fullPath);
                const childRef = ref1.child(fileRef.name);
                childRef.delete()
              });
              resolve(lista)
              
                dir.prefixes.forEach(folderRef => {
                 handleClickBorrar(folderRef.fullPath,tthis, lista,false)
                })
              
            })
            .catch(error => {
                alert(error);
            })
          })
          
      }    
};
export function directorioYfich(event,tthis,lista1) {
  if(event.target.name === "directorio" || event.target.name === "directorio_modificar"){

    if (event.target.value.match(/^[0-9a-zA-Z(\-_ñÑáÁéÉíÍóÓúÚ)(' ')]+$/)){
      tthis.setState({ [event.target.name]: event.target.value });
      tthis.setState({ErrorCaracter: false})
    }else if(event.target.value!=='') {
      alert('Por favor, no uses caracteres especiales en el nombre del directorio:('+event.target.value+')');
      tthis.setState({ ErrorCaracter: true });
    } 
    var valor=event.target.value
    var lista=[]
    var texto =''
    if (tthis.state.extension===''){
      lista = lista1
    texto= 'Este nombre de DIRECTORIO ya se está usando en esta carpeta, por favor usa otro' }
    else{lista = tthis.state.Pdfs
      texto= 'Este nombre de FICHERO ya se está usando en este directorio, por favor usa otro'
      valor=valor+'.'+tthis.state.extension}

    const siexsite = lista.filter(item=>item.Nombre===valor)
    if(siexsite&&siexsite.length>0&&tthis.state.actividad.Nombre!==valor){
      alert(texto);
      tthis.setState({ ErrorCaracter: true });
    }else{  tthis.setState({ ErrorCaracter: false });}


  }
}  
export function  buscarPadre(listRef,tthis){
  let nom= listRef.name
  const fire= tthis.props.firebase
  if(nom==="" || fire.codeDespacho&&fire.codeDespacho===nom){nom="Directorio Raiz" 
    tthis.setState({lacomunidad:''})
    
  }

  let path= listRef.fullPath
  tthis.setState({Pdfs:[], directorioPrincipal:nom,PathDirectorioActual:path, abrirBackdrop:true})
  var carpetas=[];
  var pdfs=[];
  let Ref_padre = listRef.parent;
 
  tthis.setState({Ref_padre:Ref_padre,comunidad:''})

  const SoloPresi=  tthis.props.firebase
   
  return new Promise ((resolve,reject)  => {

    listRef.listAll().then(res => {  
     
      const getData = async () => {
        return Promise.all( res.prefixes.map(folderRef => {  
               let num =1;
            return new Promise ((resolve,reject)  => { 
              SoloPresi.soloPresi(folderRef.location.path).once('value', snapshot => {
                const usersObject = snapshot.val();
                var listPresi=[]
                if (usersObject){
                  var  listaFicha = Object.entries(usersObject ).map(([key, val]) => ({
            
                    "val":val,
                  }));
                  listaFicha=  listaFicha.filter(solo =>  typeof solo.val === 'string')
            //     listaFicha= listaFicha.filter(solo =>{ !solo.val.includes('/'))
                  listaFicha.map(ficha=> listPresi=listPresi.concat(ficha.val) )
                }
                if(folderRef.name!=='logo'){
                  carpetas.push({
                    id: num,
                    Nombre: folderRef.name,
                    SoloPresi:listPresi,
                    path:folderRef.location.path,
                    image: require('../../Imagenes/Files-icon.png'),  
                  });
              }
              num++;
              resolve(carpetas)
              })
            })
         })
         )}
    
        getData().then(data => {
          res.items.forEach(function(itemRef) {
            pdfs.push({
                items:itemRef
    
            })      
         

          })
     //     resolve(pdfs)
     
          carpetas.sort(function (A, B) {
            var a =Number(A.Nombre.trim())
            var b =Number(B.Nombre.trim())
            if(!a){a=100000}
            if(!b&&b!==0){b=100000}
            if( a > b) {
              return 1;
            }
            if (a < b) {
              return -1;
      
            }
            return 1
            })
            if(nom==="Directorio Raiz"){
              carpetas= carpetas.filter(carpeta=>carpeta.Nombre!=='usuarios'&&carpeta.Nombre!=='incidencias'&&carpeta.Nombre!=='imagen')
            }
         
       
        resolve(carpetas)
    
             //    this.setState({PDFS:pdfs})
     tthis.traerPDFS(pdfs)
      
     
        
      })
  
  })

   

    })

}
class Documentos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rolesList:[],
      listaRoles:[],
      extension:'',
      modificarNombre:false,
      mostrarAyuda:false,
      resultado_aviso:[],
      avisos_enviados:false,
      dropezone:true,
      despacho:
      {
        "Nombre": "",
        "CIFNIF": "",
        "Codigo": "",
        "Fecha_registro": "",
        "Telefono1": "",
        "Telefono2": "",
        "Correo": "",
        "Direccion": "",
        "Cp": "",
        "Poblacion": "",
        "Persona": "",
        "Logo": "",
        "Iban": "",
        "uid": ""
      },
      usuarioLogueado: '',
      Categorias:[],
      categorias:[],
      categoriasMostrar:[],
      uid:"",
      añadir: true,      
      incis: [],
      VideoTuto:[],
      identIncial:'',
      comus: [],
      comunidad:'', 
      tipo:'guardar',
      actividad:'',
      mostrarTabla: false,
      selectedIndex:'',
      email: '',
      actividadBorrar:"",
      password: '',
      error: null,
      error2: null,
      modificar: true,
      listaComunidades: [],
      ComunidadesAsig:[],
      ComunidadesNoAsig:[],
      tenemosLista:false,
      snack:false, snack2:false, vertical: 'top',
      horizontal: 'cente`[r',
      dataCategoria3: [],
      Pdfs:[],
      PDFS:[],
      usuariosTotal:[],
      nombreDirectorio:"",
      abrirDialog:false,
      directorio:"",
      directorio_modificar:"",
      directorioPrincipal:"",
      Ref_padre: [],
      PathDirectorioActual:"",
      abrirBackdrop: false,
      abrirAviso:false,
      texto_envio:'',
      texto_url:'',
      texto_titulo:'',
      lacomunidad:'',
      pdfEnviar:'',
      ErrorCaracter:false,

  };

  this.handleChange = this.handleChange.bind(this)
  this.handleClickClose = this.handleClickClose.bind(this)
  this.handleClickOpenDialog = this.handleClickOpenDialog.bind(this)

  this.onDrop = this.onDrop.bind(this)

  this.dialogoEnvio = this.dialogoEnvio.bind(this) 
  this.traerComunidades = this.traerComunidades.bind(this)
  this.enviarAviso= this.enviarAviso.bind(this)
}
onBeforeCapture = () => {
  /*...*/
};

onBeforeDragStart = () => {
  /*...*/
};

onDragStart = () => {
  /*...*/
};
onDragUpdate = () => {
  /*...*/
};
onDragEnd = (result) => {
  return
  // the only one that is required
};

dialogoEnvio =(pdf)=> () => {
  
  this.setState({abrirAviso:true, texto_titulo:'PUBLICACIÓN DOCUMENTO COMUNIDAD'})  
  this.setState({pdfEnviar:pdf})
  if(this.state.categorias&&this.state.categorias.length>0){

    var categorias = this.state.categorias
    categorias = categorias.filter(cate =>{ 
      const comunidades = cate.Comunidad
      if(comunidades&&comunidades[this.state.lacomunidad]){return true}
      else{return false}
      })
    this.setState({categoriasMostrar:categorias})
  }  
  
  
  this.props.firebase.soloPresi(this.state.PathDirectorioActual)
  .once('value', snapshot => {
    const usersObject = snapshot.val();
    if (usersObject) {
      this.setState({listaRoles:usersObject})
    }else{
      this.setState({listaRoles:[]})
    }
    })


};
cerrarAviso = () => {this.setState({abrirAviso:false, texto_envio:'', texto_titulo:'',texto_url:'', pdfEnviar:''})       };

enviarAviso = () => {
  var aviso = "Se va a enviar el aviso al grupo de usuarios pertenecientes a la comundiad asociada  con Id "+ this.state.lacomunidad

  if(this.state.lacomunidad==="Documentación General"){
    aviso = "Se va a enviar el aviso A TODAS LAS COMUNIDADES"
  }

  if(window.confirm(aviso))
    { 
      this.setState({abrirBackdrop:true, abrirAviso:false}) 
      var comus=[]
      if(this.state.lacomunidad==="Documentación General"){
        const comunidades = this.state.comus
        comunidades.forEach((value) => {  comus= comus.concat(value.Id)}  )   
      }else{   
          comus[0] =this.state.lacomunidad
        } 
     
          new Promise((resolve, reject) => {
           //enviamos el aviso a los usuarios según comunidad
         resolve (enviarAvisos(comus,
          this.state.texto_envio,this.state.texto_titulo,this.state.texto_url, this.props.firebase,
          this.state.PathDirectorioActual,this.state.pdfEnviar, "1",this.state.Categorias,this.state.usuarioLogueado, 
          this.state.despacho, this.state.listaRoles,null,this.state.Pdfs))})
        .then(data2 => {
          if (data2){


        //    enviarDispositivosAdmin(this.state.texto_envio,
         //     this.state.texto_titulo, this.props.firebase,
           //   this.state.PathDirectorioActual,this.state.pdfEnviar, "1", data2).then((data3)=>{             
                const registro= cargarResultado(data2,[], this.props.firebase,this.state.usuarioLogueado)  

                this.setState({resultado_aviso:registro.archivo_final,avisos_enviados:true,texto_envio:'',
                  usuariosTotal:registro.usuariosTotal, abrirBackdrop:false})   
          //     alert("El envío se ha realizado correctamente")
                this.setState({Categorias:[]})
         //       })
          }else { alert("No dispones de usuarios destinatarios para este envío, por favor revisa los roles asignados a esta carpeta")
              this.setState({ abrirBackdrop:false})    
              this.setState({Categorias:[]})}
            
              this.cerrarAviso()  
        })

        .catch(reason => { 
          console.log(reason)
        });
        

      
 
    }
  
}
traerUsuarioLogueado() {
  return new Promise ((resolve,reject)  => {  
  var user = this.props.firebase.auth.currentUser;
  if (user) {
      this.props.firebase.users().orderByChild('/Clave').equalTo(user.uid)
    .once('value', snapshot => {
      const usersObject = snapshot.val();
      if (usersObject) {
        const usuario = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));

        this.setState({ usuarioLogueado: usuario[0] })  
        resolve(usuario[0])
      }
    })
  }else{resolve(false)}  
})
}
buscarDespacho() {
  return new Promise ((resolve,reject)  => {  
  var despacho = this.state.despacho
  this.props.firebase.despacho().once('value', snapshot => {
    const usersObject = snapshot.val();
    despacho.Nombre = usersObject.Nombre
    despacho.CIFNIF = usersObject.CIFNIF
    despacho.Codigo = usersObject.Codigo
    despacho.Fecha_registro = usersObject.Fecha_registro
    despacho.Telefono1 = usersObject.Telefono1
    despacho.Telefono2 = usersObject.Telefono2
    despacho.Correo = usersObject.Correo
    despacho.Direccion = usersObject.Direccion
    despacho.Cp = usersObject.Cp
    despacho.Com_interna = usersObject.Com_interna
    despacho.Poblacion = usersObject.Poblacion
    despacho.Persona = usersObject.Persona
    despacho.Logo = usersObject.Logo
    despacho.Iban = usersObject.Iban
    despacho.uid = snapshot.key
    despacho.Tipo = usersObject.Tipo
  }).then(() => this.setState({ despacho: despacho },()=>{resolve(true)}))
  })
}

traerRoles() {

  this.props.firebase.roles2().once('value', snapshot => {
    const usersObject = snapshot.val();
   
    if (usersObject){

      var rolesList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));
      rolesList.sort(function (a, b) {
        if (a.Nombre> b.Nombre) {
          return 1;
        }
        if (a.Nombre < b.Nombre) {
          return -1;
          
        }
      // a must be equal to b
        return 0;
      })
     
   
      this.setState({rolesList:rolesList})
    }else{
      this.setState({ rolesList: [] })
    }
  })


}

handleClose () {
  this.setState({snack:false, snack2:false})
};
traerComunidades(){
  this.props.firebase.comus().once('value', snapshot => {
    const usersObject = snapshot.val();
    if(usersObject){
      var comusList = Object.keys(usersObject).map(key => ({
        ...usersObject[key],
        uid: key,
      }));
      var salida_comus = {}
      comusList = comusList.filter(comu => comu.Id&&comu.Id!=='')
      comusList&&comusList.forEach(comu =>{
        const id  = comu.Id;
        if (id&&id!=='')  {salida_comus[ id ] = id.toString()}
      } )  
      comusList.sort(function (A, B) {
        var a =Number(A.Id.trim())
        var b =Number(B.Id.trim())
        if(!a){a=-1}
        if(!b){b=-1}
        if( a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
  
        }
        return 1;
      })
      
      this.setState({  comus: comusList,  salida_comus:salida_comus   });
   
    }else{ this.setState({  comus: [],  salida_comus:[]   });
 
    }
  })
}

componentWillMount(){
  this.props.firebase.categorias().off()
}
componentDidMount() {
  this.traerRoles()
  
  //this.buscarDespacho()
  this.setState({mostrarTabla:false});
  this.setState({abrirBackdrop:true})
  this.traerComunidades()
  
  traerTutoriales(this, 'Documentos').then((data)=>{this.setState({VideoTuto:data})})
  this.traerUsuarioLogueado().then((user)=>{
    
  this.buscarDespacho().then(()=>{
    if(!user.Administrador|| this.state.despacho.Tipo==='Comunidades'){
      this.setState({lacomunidad:user.Comunidad})
      this.buscarficheros( user.Comunidad, user.Comunidad, true)

    }else{
        this.buscarficheros("", "Directorio Raiz", true)
    }
  })
})
  this.props.firebase.categorias().on('value', snapshot => {
    const usersObject = snapshot.val();
    if (usersObject){
    
        const categoriasList = Object.keys(usersObject).map(key => ({
          ...usersObject[key],
          uid: key,
        }));
    
     
     this.setState({categorias:categoriasList})
      }else{  this.setState({categorias:[]})}
    })
}

buscarficheros (Path, nombre, inicial) {

  if(this.state.directorioPrincipal==="Directorio Raiz"&&nombre!=="Directorio Raiz"){
    this.setState({lacomunidad:nombre})

  }
 

  this.setState({Pdfs:[], dataCategoria3:[], directorioPrincipal:nombre})
  this.setState({abrirBackdrop:true})
  var carpetas=[];
   var pdfs=[];
  
   this.setState({ PathDirectorioActual:Path})
   let listRef =''
  if(inicial){ 
   listRef =(this.props.firebase.codeDespacho ? this.props.firebase.storage_propio_completo(this.props.firebase.codeDespacho+'/'+Path):
    this.props.firebase.storage_propio_completo(Path))
    this.setState({PathDirectorioActual:this.props.firebase.codeDespacho+'/'+Path})
 
  }else{ listRef= this.props.firebase.storage_propio_completo(Path)}

 
 
  let Ref_padre = listRef.parent;
  this.setState({Ref_padre:Ref_padre})


  const presi=  this.props.firebase

   
  const p0 = new Promise ((resolve,reject)  => {
    listRef.listAll()
    .then(function(res) {
      const getData = async () => {
        return Promise.all( res.prefixes.map(folderRef => {  
               let num =1;
           return new Promise ((resolve,reject)  => { 
            presi.soloPresi(folderRef.location.path).once('value', snapshot => {
                const usersObject = snapshot.val();
                var listPresi=[]
                if (usersObject){
                var  listaFicha = Object.entries(usersObject ).map(([key, val]) => ({
          
                  "val":val,
                 }));
                 listaFicha=  listaFicha.filter(solo =>  typeof solo.val === 'string')
           //     listaFicha= listaFicha.filter(solo =>{ !solo.val.includes('/'))
                 listaFicha.map(ficha=> listPresi=listPresi.concat(ficha.val) )
                 
 
                }
              
                if(folderRef.name!=='logo'){
                  carpetas.push({
                    id: num,
                    Nombre: folderRef.name,
                    SoloPresi:listPresi,
                    path:folderRef.location.path,
                    image: require('../../Imagenes/Files-icon.png'),  
                  });
                }
                num++;
                resolve(carpetas)
 

              })
         }) })
         )}

    
        getData().then(data => {
          res.items.forEach(function(itemRef) {
            pdfs.push({
                items:itemRef
    
            })      
         

          })
          resolve(pdfs)
     
        
         
        })
  
      
     
        
    })
    .catch((e)=>{
      const error= e  
    })
  })
  p0.then( (data) => { 
    carpetas.sort(function (A, B) {
      var a =Number(A.Nombre.trim())
      var b =Number(B.Nombre.trim())
      if(!a){a=100000}
      if(!b&&b!==0){b=100000}
      if( a > b) {
        return 1;
      }
      if (a < b) {
        return -1;

      }
      return 1;
    })
    if(nombre==="Directorio Raiz"){
      carpetas= carpetas.filter(carpeta=>carpeta.Nombre!=='usuarios'&&carpeta.Nombre!=='incidencias'&&carpeta.Nombre!=='imagen')
    }
      this.setState({dataCategoria3:carpetas})
       this.traerPDFS(pdfs)

  })

}
traerPDFS  (items){
 if(items.length>0){ 
  const listRef2 = this.props.firebase.storage_propio_completo()
  var pdfs=[];
  let promises = items.map((itemRef) => {
    return   listRef2.child(itemRef.items.location.path).getDownloadURL() 
  });
  Promise.all(promises).then((downloadURLs) => {
    var i=0;
    items.forEach((itemRef) => {
          pdfs.push({
          id: i,
          Nombre: itemRef.items.name,
          url: downloadURLs[i] ,
          path: itemRef.items.location.path,
      //  fullpath: itemRef.items.fullPath,
          image: require('../../Imagenes/Mimetypes-pdf-icon.png'),  
        })
        i++;
      })
        pdfs = pdfs.filter(file => file.Nombre !== "temporal.txt")
          this.setState({Pdfs:pdfs})
          this.setState({abrirBackdrop:false, mostrarTabla:true})
    });

  }else {this.setState({abrirBackdrop:false, mostrarTabla:true})}
    //    this.setState({PDFS:pdfs})
 
}
handleChange(event) {
 
   
  if (event.target.name === "comunidad"){
    this.setState({ [event.target.name]: event.target.value });
    var melon=event.target.value 
    if (event.target.value===""){melon="Directorio Raiz"}
    this.setState({lacomunidad:event.target.value})
    this.buscarficheros(event.target.value,melon,false)
  }

  if(event.target.name === "directorio" || event.target.name === "directorio_modificar"){
    directorioYfich(event,this, this.state.dataCategoria3 )
   

  }

}

handleClickOpenDialog = () => {this.setState({abrirDialog:true,directorio:''})       };
handleClickCloseOk = () => {
             this.setState({abrirDialog:false})  
              crearEstructurDirectorios2(this.state.PathDirectorioActual,this.state.directorio, this.state.directorioPrincipal, this.props.firebase,true).then(()=>
             {
                this.buscarficheros(this.state.PathDirectorioActual,this.state.directorioPrincipal,false)
                alert('El directorio '+this.state.directorio+' se ha creado correctamente')}) 
} 


handleClickClose = () => {this.setState({abrirDialog:false, nombreDirectorio:""})       }; 
onDrop(file) {

    this.setState({abrirBackdrop:true})

      const putData = async () => {
        return Promise.all(file.map(fichero =>  
          this.props.firebase.storage_propio_completo(this.state.PathDirectorioActual+"/"+fichero.name).put(fichero)
          ))
      }
      putData().then(data => {
        this.buscarficheros(this.state.PathDirectorioActual,this.state.directorioPrincipal,false)
        this.setState({abrirBackdrop:false})
        
      })
  
 // }
}

onChange = event => {
  this.setState({ [event.target.name]: event.target.value })
 
};

handleChangeCat =  event => {
 // var newData= oldData
  this.setState({Categorias:event.target.value})
 // var data = this.state.categorias;
  //newData.Categorias = event.target.value;
  //newData.categorias = newData.categorias.filter(rol => rol!=='');
   
 // data[data.indexOf(oldData)] = newData;
  //this.setState({categorias:data }); 

  //  setPersonName(event.target.value);
}

 render() {
var comunidadtexto= ''
if(!this.state.usuarioLogueado.Administrador){
  const comunidad = this.state.comus.filter(comu => comu.Id===this.state.usuarioLogueado.Comunidad)
  if(comunidad&&comunidad.length>0){ comunidadtexto=comunidad[0].Nombre}
  if(this.state.dataCategoria3.length>0){
    var carpetaFinal= this.state.dataCategoria3
    const carpetas= this.state.dataCategoria3
    const usuarioRoles= (this.state.usuarioLogueado.Roles ? this.state.usuarioLogueado.Roles:[])   
    carpetas.forEach((carpeta)=>{
      const roles=(carpeta.SoloPresi ? carpeta.SoloPresi : [])
      if(roles&&roles.length>0){
        //comparamos que el usuario logueado tenga ese rol para poder visualizar la carpeta.
        roles.forEach((rol)=>{
          if(!usuarioRoles.includes(rol)){
            carpetaFinal = carpetaFinal.filter(carp=>carp.Nombre !== carpeta.Nombre)
            this.setState({dataCategoria3:carpetaFinal})
          }
        })

      }
    })
   
  }   
}
const despacho=this.state.despacho
  
const VentanPrincipal  = (pos) => {

   return(

    <Paper elevation={0}  style={{marginLeft:'1%',  width:'98%'}}>
       {!this.state.usuarioLogueado.Administrador&&
        <Paper elevation={0}  style={{marginTop:'-1%',  width:'100%'}}>
        <h2 style={{ color: 'green' }} >Comunidad: <span style={{ color: 'black' }}>{comunidadtexto}</span>  </h2>
        <ButtonGroup  size="small"  color="primary" variant="text" aria-label="volver">
          <Tooltip title="Volver al directorio anterior" aria-label="seguir">
    
              <Button  
             
              disabled = {this.state.directorioPrincipal==="Directorio Raiz" || (this.state.usuarioLogueado.Comunidad===this.state.lacomunidad&&this.state.despacho.Tipo==='Comunidades'&&this.state.directorioPrincipal===this.state.lacomunidad)
                    ||(!this.state.usuarioLogueado.Administrador&&this.state.directorioPrincipal===this.state.usuarioLogueado.Comunidad)}
                  onClick={(event) => { buscarPadre(this.state.Ref_padre,this).then((carpetas)=>{
                      this.setState({dataCategoria3:carpetas})
                      this.setState({mostrarTabla:true});
                    }) 
                  } }>
                    
                             <ArrowBackIosIcon/> Volver
                   
                    </Button>

                    </Tooltip>
        </ButtonGroup>
        <h2 style={{marginTop:'-0.3%', color: 'green', marginBottom:'-0.5%' }} >Directorio Actual: <span style={{ color: 'black' }}>{this.state.directorioPrincipal}</span> </h2>
        <hr/>
       </Paper>}

       <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={1}>  
       { this.state.usuarioLogueado.Administrador&&  <Grid item  xs={12} sm={12} md={(this.state.usuarioLogueado.Administrador ? 3:4)}  >
          <ButtonGroup  size="small"  color="primary" variant="text" aria-label="volver">
          <Tooltip title="Volver al directorio anterior" aria-label="seguir">
    
              <Button  
             
              disabled = {this.state.directorioPrincipal==="Directorio Raiz" || (this.state.usuarioLogueado.Comunidad===this.state.lacomunidad&&this.state.despacho.Tipo==='Comunidades'&&this.state.directorioPrincipal===this.state.lacomunidad)
                    ||(!this.state.usuarioLogueado.Administrador&&this.state.directorioPrincipal===this.state.usuarioLogueado.Comunidad)}
                  onClick={(event) => { buscarPadre(this.state.Ref_padre,this).then((carpetas)=>{
                      this.setState({dataCategoria3:carpetas})
                      this.setState({mostrarTabla:true});
                    }) 
                  } }>
                    
                             <ArrowBackIosIcon/> Volver
                   
                    </Button>

                    </Tooltip>
          </ButtonGroup>
      
          {((!this.state.abrirBackdrop)&&<Paper elevation={5} variant="outlined" square style={{  margin:0}}> 
                  <Button  
                   style={{textAlign:'left', alignContent:'flex-start',  alignItems:"flex-start"}}
                  fullWidth
                  onClick={(event) => {
                  this.buscarficheros(this.state.PathDirectorioActual,this.state.directorioPrincipal,false)}} variant="text"  
                          size="large" color="primary"  >
                        
                            <FolderIcon />
                            Directorio Actual: {this.state.directorioPrincipal}
                          
                         
                  </Button>
            </Paper>)}
          {((this.state.lacomunidad!=='')&&(!this.state.abrirBackdrop)&&this.state.usuarioLogueado.Administrador&&<Paper elevation={5} 
              variant="outlined" square style={{  margin:0}}> 
        
                      <h4 style={{ color: 'green', margin:10 }} >Comunidad Actual:</h4>
                      <p style={{ color: 'green', margin:10 }}> {this.state.lacomunidad}</p>
          
              </Paper>)}
          {this.state.usuarioLogueado.Administrador&& this.state.despacho.Tipo!=='Comunidades'&&
              <FormControl styles= {{   minWidth: 120}} >
            
                  <InputLabel id="demo-simple-select-label">Comunidad</InputLabel>
                  <Select
                    labelId="comunidad"
                    id="comunidad"
                    name ="comunidad"
                    value={this.state.comunidad}
                    onChange={this.handleChange}
                  >
                    <MenuItem key={''} value={''}>{''}</MenuItem>
                    {comus && comus.map(comu => (
                      <MenuItem key={comu.Id} value={comu.Id}>{comu.Id}</MenuItem>
                    ))         
                    }
                  </Select>
                  <FormHelperText>Búsqueda rápida por comunidades</FormHelperText>
          
              </FormControl>}
      
        </Grid>}
       

        {(this.state.usuarioLogueado.Administrador ||this.state.dataCategoria3.length>0)&&
        <Grid  item  xs={12} sm={12} md={(this.state.usuarioLogueado.Administrador ? 4:6)} >  
        
            <h2  style={{ color: 'green' }}>Subdirectorios</h2>  
                  
            {this.state.usuarioLogueado.Administrador&&this.state.directorioPrincipal!=="Directorio Raiz"&&  <ButtonGroup  size="small"  color="primary" variant="text" aria-label="volver">
                  
                    <Button  onClick={this.handleClickOpenDialog}  >
                      
                    <Tooltip title="Añadir carpeta" aria-label="añadir">
                          
                          <AddCircleOutlineIcon/>
                        
                        </Tooltip>
                    </Button>
                  
              </ButtonGroup> }

            {((!this.state.abrirBackdrop)&&
            <Paper style={{maxHeight: 490, overflow: 'auto'}}>     
            
                    <List component="nav" dense="true"  aria-label="main mailbox folders" justify="space-around" alignItems="center" >         
                      {this.state.dataCategoria3 && this.state.dataCategoria3.map((actividad,indice) => (                  
                      <Grid>
                        <Divider />
                        <ListItem
                      button
                      selected={this.selectedIndex === actividad.Nombre}
                      onClick={(event) => this.buscarficheros(actividad.path, actividad.Nombre,false)}
                      >
                      <ListItemAvatar>
                          <Avatar  style ={{color:'lightgreen', backgroundColor:green[500]}}>  
                                  <FolderIcon />
                          </Avatar>
                      </ListItemAvatar>

                      <ListItemText primary={actividad.Nombre} style ={{maxWidth:'35%'}} />
                      <ListItemSecondaryAction>
                          
                      
                        {this.state.directorioPrincipal!=="Directorio Raiz"&& 
                      this.state.usuarioLogueado.Administrador&&        <FormControl  style ={{ width: 120}}>
                          <InputLabel id="demo-mutiple-name-label">Sólo para:</InputLabel>
                              <Select
                              labelId="demo-mutiple-checkbox-label"
                              id="demo-mutiple-checkbox"
                              multiple
                              value={actividad.SoloPresi}
                              onChange={e => {
                                actividad.SoloPresi=e.target.value
                                var dataCategoria3=this.state.dataCategoria3
                                dataCategoria3[indice]=actividad
                                this.setState({dataCategoria3:dataCategoria3})
          
                                this.props.firebase.SoloPresis().update({[actividad.path]:e.target.value})
                              }}
                              input={<Input />}
                              renderValue={(selected) =>      selected.join(', ')}
                              MenuProps={MenuProps}
                            >
                              {rolesList.map((rol) => (
                                <MenuItem key={rol.Nombre} value={rol.Nombre}>
                                <Checkbox color="primary"
                                    size="small"
                                    checked={ (actividad.SoloPresi&&actividad.SoloPresi.length>0 ?
                                      actividad.SoloPresi.filter(valor=>valor===rol.Nombre).length>0:false)}
                                
                                  />
                                  <ListItemText primary={rol.Nombre} />
                                </MenuItem>
                              ))}
                            </Select>
                        </FormControl>
                        }
                      {this.state.usuarioLogueado.Administrador&&<>           
                      <IconButton edge="end" aria-label="delete"
                          disabled={actividad.Nombre==='imagen' ||  actividad.Nombre==='LOPD'|| 
                          (this.state.directorioPrincipal==="Directorio Raiz"&&despacho.Tipo==='Comunidades')||
                          actividad.Nombre==='Documentación General'|| actividad.Nombre==='incidencias'}
                          onClick={(event) => handleClickBorrar(actividad.path,this, this.state.dataCategoria3,true).then((lista)=>{
                                this.setState({dataCategoria3:lista})
                          })} >
                    
                          <DeleteIcon />
                          </IconButton>
                          <IconButton edge="end" aria-label="borderColor" 
                            disabled={actividad.Nombre==='imagen' ||  actividad.Nombre==='LOPD'||
                            this.state.directorioPrincipal==="Directorio Raiz" ||
                            actividad.Nombre==='Documentación General'|| actividad.Nombre==='incidencias'}
                            onClick={(event) => {
                                this.setState({directorio_modificar:actividad.Nombre, actividad:actividad,
                                  modificarNombre:true})
                                }}>
                              <BorderColor />
                              </IconButton>
                              </>}
                    
                      </ListItemSecondaryAction>
                      </ListItem>                      
                      </Grid>
                      ))}
                    </List>
                  </Paper>)}

                  {pos.pos.tamaño==='pequeña'&& <hr/>}
        </Grid >}
        
        {(this.state.usuarioLogueado.Administrador ||this.state.Pdfs.length>0)&& this.state.directorioPrincipal!=="Directorio Raiz"&&
        <Grid  item  xs={12} sm={12} md={(this.state.usuarioLogueado.Administrador ? 5:6)} >             
          <Grid container direction='row'   justifyContent="space-between"  alignItems="center">
                      
      
          <h2  style={{ color: 'green' }}>{(
            this.state.usuarioLogueado.Administrador ? 'Ficheros y área de carga': 'Ficheros'
          )}</h2>
                        
          {this.state.usuarioLogueado.Administrador&&   <Tooltip title="Envía aviso sobre el directorio de ficheros actualizado">
                            <span>
                              <Button     onClick={this.dialogoEnvio("")} 
                              variant="outlined" 
                              disabled = {this.state.directorioPrincipal==="Directorio Raiz"}
                              size="large" color="primary"  startIcon={<SendIcon />}>ENVIAR AVISO
                              </Button>
                            </span>
                          </Tooltip>}                        
          </Grid>
          {((!this.state.abrirBackdrop)&& 
            <>  
                <Paper style={{maxHeight: 390, overflow: 'auto'}}>  
             <List>  
         
                    
                {this.state.Pdfs && this.state.Pdfs.map((actividad,index) => (     
                  
                    <ListItem
                       divider
                        button
                        disableRipple
                        selected={this.selectedIndex === actividad.Nombre} 
                        >
               
               <Grid  container direction="row" justifyContent="center" alignItems="center" spacing={1}>  
            
           
                  <Grid   item  xs={(this.state.usuarioLogueado.Administrador ? 6:12)}
                   sm={(this.state.usuarioLogueado.Administrador ? 6:12)}
                    md={(this.state.usuarioLogueado.Administrador ? 6:12)} >  
                     <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                     <Button       onClick={(event) =>  window.open(actividad.url) }>
                       <Grid   item  xs={3} sm={3} md={2} >
                        
                          <Avatar  style ={{color:'lightgreen', backgroundColor:green[500]}}>  
                            <InsertDriveFileIcon/>
                          </Avatar>
                          </Grid>
                         <Grid   item  xs={9} sm={9 } md={10} >
                                 {actividad.Nombre}                        
                          </Grid>
                          </Button>
                        </Grid>
                      </Grid>
         
            
                      {this.state.usuarioLogueado.Administrador&&
                       
                <Grid   item  xs={ 6} sm={6}    md={6} >  
                    <Grid  container direction="row" justifyContent='flex-end' alignItems="center" spacing={1}>  
                   <Grid  item  xs={2} sm={2} md={2} >  
                        <IconButton edge="start" aria-label="delete"
                            fullWidth
                            onClick={(event) =>  handleClickBorrarFichero(actividad.Nombre, this, this.state.directorioPrincipal)} >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    <Grid  item  xs={2} sm={2} md={2} >  
                    
                        <IconButton edge="start" aria-label="borderColor" 
                           fullWidth
                              onClick={(event) => {
                                //Quitamos la extensión
                                  const nombre= actividad.Nombre.split('.')[0]
                                this.setState({directorio_modificar:nombre, actividad:actividad, extension:actividad.Nombre.split('.')[1],
                                  modificarNombre:true})
                                }}>
                              <BorderColor />
                            </IconButton>
                            </Grid>
                    <Grid  item  xs={8} sm={8} md={8} >  
              
                                   <Button 
                                     fullWidth
                                    onClick={this.dialogoEnvio(actividad.Nombre)} 
                                    variant="outlined" 
                                    disabled = {this.state.directorioPrincipal==="Directorio Raiz"}
                                    size="small" color="primary" >
                              
                                               <SendIcon/>ENVIAR AVISO
                            
                                    </Button>
                                    
                              
                    </Grid>
                    </Grid>
                </Grid>               
                       }
                       </Grid>   
                     
                    
                  </ListItem>                            
                     
  
                
              ))}
            
              </List>
              </Paper>
            
            {this.state.usuarioLogueado.Administrador&&    <DropzoneArea 
                    maxFileSize={20000000}
                    onDrop = {acceptedFiles => this.onDrop(acceptedFiles)}
                    showPreviewsInDropzone= {false} 
                    filesLimit={5}
                    showFileNames ={false}
                    dropzoneText = {"Arrastra el fichero o haz click aquí para cargarlo desde la ventana"}/>}
              
          </>)}
        </Grid >}
       </Grid>
    </Paper>)
  
}

  const { texto_envio, texto_titulo, texto_url} = this.state;
  const comus = this.state.comus
  const rolesList=this.state.rolesList
  var directorio_modificar =this.state.directorio_modificar
      if(this.state.extension!==''){directorio_modificar=directorio_modificar+'.'+this.state.extension}
  var totalMensajesOK= 0
  var totalMensajesKO= 0

  const resultado_aviso= this.state.resultado_aviso
  if(resultado_aviso&&resultado_aviso.length>0){
      totalMensajesOK= resultado_aviso.filter((usuario)=>usuario.error==='NO').length
      totalMensajesKO= resultado_aviso.filter((usuario)=>usuario.error==='SI').length
  }


  return (     
      <Grid >
         <ThemeProvider theme={theme}>    
          {!this.state.abrirBackdrop&&<>
        <Cabecera/>
         <hr/>
         <Breakpoint large up>
         {this.state.usuarioLogueado.Administrador&&
         <Grid style={{ position: 'absolute', right: 20, top: 20 }}>
           
           <Button variant="contained" color="primary" onClick={() => { this.setState({ mostrarAyuda: true }) }}>
            AYUDA (TUTORIALES)
          </Button>
         
         </Grid>}
         </Breakpoint>
        <BotonesTriger props={this.props} usur={this.state.usuarioLogueado}/>       
       
      
       
        <Breakpoint large up>
       <VentanPrincipal pos={{'pos':"space-evenly",'tamaño': 'grande'}} />  
        </Breakpoint>
        <Breakpoint medium down>
        {this.state.usuarioLogueado.Administrador&& <Grid style={{ marginLeft: 10 }}>
           
           <Button variant="contained" color="primary" onClick={() => { this.setState({ mostrarAyuda: true }) }}>
             AYUDA (TUTORIALES)
           </Button>
           <hr/>
        </Grid>}
     <VentanPrincipal pos={{'pos':"flex-start",'tamaño': 'pequeña'}} />    
        </Breakpoint>
      

         
            <Dialog open={this.state.abrirDialog} onClose={this.handleClickClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Nueva Carpeta</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Para crear la nueva carpeta introduce un nombre y seguidamente la opción "Aceptar"
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="directorio"
                    name="directorio"
                    label="Nombre del directorio"
                    type="text"
                    value={this.state.directorio}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                <Button onClick={this.handleClickClose} color="primary">
                                      Cancelar
                </Button>
                <Button
                  disabled = {this.state.directorio===''||this.state.ErrorCaracter}
                  onClick={this.handleClickCloseOk} color="primary" >
                                      Aceptar
                </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={this.state.abrirAviso} /*onClick={thi.handleClickClose}*/ aria-labelledby="form-dialog-title"
                                    fullWidth="true"    maxWidth="md"          scroll="paper"          >
                                  <DialogTitle id="form-dialog-title">COMUNICADO DE PUBLICACIÓN DE DOCUMENTOS</DialogTitle>
                                    <DialogContent dividers>
                                 
                                   
                                          <TextField 
                                              margin="dense"
                                              id="titulo"
                                              name ="texto_titulo"
                                              label="Añade el título del AVISO"
                                              type="text"
                                              value={texto_titulo}
                                              onChange={this.onChange}
                                             fullWidth/>
                              
                                       
                                          <TextField                                
                                              
                                              autoFocus
                                              margin="dense"
                                              id="texto_envio"
                                              name ="texto_envio"
                                              label="Añade el texto del AVISO"
                                              type="text"
                                              value={texto_envio}
                                              onChange={this.onChange}
                                              fullWidth
                                              />
                                          <TextField
                                                multiline
                                                margin="dense"
                                                id="texto_url"
                                                name="texto_url"
                                                label="Añade url (Opcional)"
                                                type="text"
                                                value={texto_url}
                                                onChange={this.onChange}
                                                fullWidth
                                                />
                                          <FormControl
                                            fullWidth>
                                               <InputLabel id="demo-mutiple-chip-label">Selección de categorías, 
                                               envío grupos específicos</InputLabel>
                                             <Select
                                                    multiple
                                                    
                                                    value={this.state.Categorias}
                                                    onChange={this.handleChangeCat}
                                                    renderValue={(selected) => (
                                                      <div style={{  display: 'flex', flexWrap: 'wrap',}}>
                                                        {selected.map((value) => (
                                                          <Chip variant="outlined" color='primary' key={value} 
                                                          label={value} style={{margin:6}}/>
                                                        ))}
                                                      </div>
                                                    )}
                                                    
                                                  >
                                                {this.state.categoriasMostrar&&this.state.categoriasMostrar.map(actividad => 
                                                (        <MenuItem key={actividad.Nombre} value={actividad.Nombre} >
                                                            {actividad.Nombre}

                                                          </MenuItem>                                                 
                                                ))}
                                     
                                                      
                                                  </Select>
                                                </FormControl>
                                         
                               
                                              
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={this.cerrarAviso} color="primary"
                                    >
                                      Cancelar
                                    </Button>
                                    <Button
                                       disabled ={this.state.texto_envio==="" || this.state.texto_titulo===""}
                                       onClick={this.enviarAviso} color="primary"
                                    >
                                      Confirmar
                                    </Button>
                                  </DialogActions>
                     </Dialog>
            <Dialog open={this.state.modificarNombre} 
                  maxWidth={'md'}
                  fullWidth={false}
        
              onClose={e => {this.setState({modificarNombre:false})}}>
                <DialogTitle id="form-dialog-title">Modificar Nombre</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                  Modifica el nombre y presiona "Aceptar"
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="directorio_modificar"
                    name="directorio_modificar"
                    label="Nombre del directorio"
                    type="text"
                    value={this.state.directorio_modificar}
                    onChange={this.handleChange}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                <Button  onClick={(event) =>{this.setState({modificarNombre:false,extension:''})}} color="primary">
                                      Cancelar
                </Button>
                <Button
                  
                                                  
                  disabled = {this.state.directorio_modificar===''||this.state.ErrorCaracter
                  ||directorio_modificar===this.state.actividad.Nombre}
                  onClick={(event) => {
           
                  
                    this.setState({abrirBackdrop:true, modificarNombre:false})
                    const  actividad=this.state.actividad
                    const nuevoNombre =directorio_modificar
                    const pathnuevo = actividad.path.slice(0,actividad.path.length-actividad.Nombre.length)
                        +nuevoNombre
                    if(this.state.extension===''){

                    
                    modificarNombrefun(actividad.path,pathnuevo,this).then(()=>{


                      let lista =  this.state.dataCategoria3
                      var nuevaLista=[]
                      lista.forEach(activ =>{
                          if(activ.path === actividad.path){
                              var direct= activ
                              direct.path=pathnuevo
                              direct.Nombre=nuevoNombre
                              nuevaLista = nuevaLista.concat(direct)
                          }

                          else{
                            nuevaLista = nuevaLista.concat(activ)
                          }

                      })  
                    
                       

                     this.setState({dataCategoria3:nuevaLista,extension:''})  
                     this.setState({modificarNombre:false,})
                     this.setState({abrirBackdrop:false})
                     alert('El cambio de directorio se ha realizado correctamente')
                    })
                    }else{
                    
                      var moverFichero = this.props.firebase.functions.httpsCallable('moverFichero');
                      moverFichero({nombre: actividad.path, bucket:this.props.firebase.deposito,
                      destino:pathnuevo}).then(()=>{
                        this.buscarficheros(this.state.PathDirectorioActual,this.state.directorioPrincipal,false)
                        this.setState({extension:''})  
                        this.setState({modificarNombre:false,})
                   //     this.setState({abrirBackdrop:false})
                        alert('El cambio de fichero se ha realizado correctamente')

                    
                      })          
                      
                    }
                  
                  }}
                   color="primary" >
                                      Aceptar
                </Button>
                </DialogActions>
            </Dialog>
                                 
            <Modal
                           aria-labelledby="transition-modal-title"
                           aria-describedby="transition-modal-description"
                           style={{display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center'}}
                            open={this.state.avisos_enviados}
                            onClose={e=> this.setState({avisos_enviados:false,resultado_aviso:[], usuariosTotal:[]})}                                        
                                                    >
                   <Paper style={{maxHeight: 500, overflow: 'auto',maxWidth:'80%', alignContent:'center',  alignItems:'center',
                      marginLeft:20, marginRight:20,marginTop:20}}>   
                       <h2  style={{   marginLeft:20,color: 'green' }}>RESULTADOS AVISOS ENVIADOS </h2>
                       <h3  style={{  marginLeft:20, color: 'green' }}>Total USUARIOS que se ha enviado el AVISO: {this.state.usuariosTotal.length}</h3>                              
                       <h3  style={{  marginLeft:20, color: 'green' }}>Total dispositivos enviado AVISO: {this.state.resultado_aviso.length}</h3>                              
                  
                     
                       <h3  style={{  marginLeft:20, color: 'green' }}>Total dispositivos Ok: {totalMensajesOK}</h3>                              
                       <h3  style={{  marginLeft:20, color: 'green' }}>Total dispositivos KO: {totalMensajesKO}</h3>                              
          
                       <MaterialTable
                                 options={{
                               
                                     exportButton: {
                          csv: true,
                          pdf: false
                        },
                                 filtering: true,
                                 selection: false,   
                                 pageSize: 10,
                                 exportDelimiter: ";",
                                 exportAllData: true,
                                 grouping: true,
                                 pageSizeOptions:	[5, 10, 20],
                                 headerStyle: {
                                   backgroundColor: 'green',
                                   color: 'white'},
                                   paddingTop:5,
                                   paddingBottom:5
                                         
                               }}
                               
                               localization={{ 
                               
                                toolbar: {
                                  onRowsSelected: 'TOTAL DISPOSITIVOS que se ha enviado el aviso: {0}'
                              },
                              }}
 
                               icons={tableIcons}
                        
                               title={'TABLA DETALLE RESULTADOS ENVÍO AVISO'}
 
                               columns={[
                                {title: 'Nombre', field: 'Nombre'},
                                {title: 'Apellido', field: 'Apellido'},
                                {title: 'DNI', field: 'DNI'},
                                {title: 'Administrador', field: 'Administrador'},
                                
                                {title: 'Comunidad', field: 'Comunidad'},
                                {title: 'Error de Envío', field: 'error'},
                                {title: 'Código Error', field: 'codigo'},
                                {title: 'Mensaje Error', field: 'mensaje'},
                                
                                {title: 'Dispositivo', field: 'Dispositivos', cellStyle: {
                                  width: 200,
                                  minWidth: 200
                                },
                                headerStyle: {
                                  width: 200,
                                  minWidth: 200
                                }},

                              ]}
                 
                              data={this.state.resultado_aviso}
 
                          />
                  </Paper>
            </Modal>


      <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}

              open={this.state.mostrarAyuda}
              onClose={e=>this.setState({mostrarAyuda:false})}


            >
            {this.state.mostrarAyuda&&<ModalTutoriales Pdfs= {this.state.VideoTuto}/> 
          }
      </Modal>

      <Box mt={40}>{!this.state.abrirBackdrop&&<Copyright />} </Box>
      </>}

      <Backdrop  color = 'primary' open={this.state.abrirBackdrop}>
                            <CircularProgress color="primary" />
      </Backdrop>
      </ThemeProvider>
      
    </Grid>
    );
  }
}
const condition = authUser => !!authUser;
export default withFirebase(withAuthorization(condition)(Documentos));