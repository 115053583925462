import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import AuthUserContext from './context';
const withAuthorization = condition => Component => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        authUser => {
          if (!condition(authUser)) {
            this.props.history.push(ROUTES.SIGN_IN);
          }
          this.traerUsuarioLogueado().then((usu)=>{
            if(!usu){ this.props.history.push(ROUTES.SIGN_IN)}
            else if(!usu.Administrador){ 
              if(this.props.location.pathname !== '/documentos'){
                this.props.history.push(ROUTES.DOCUMENTOS)}
              }
            else if(this.props.location.pathname !== '/lopd'&&this.props.location.pathname !== '/despachos' && this.props.location.pathname !== '/home'){
              if(usu.Lopd){
                this.props.history.push(ROUTES.HOME);
                alert('No estás autorizado para acceder a este módulo')
              }         
            }
          })
        },
      );
    }
    traerUsuarioLogueado() {

      return new Promise ((resolve,reject)  => {  
      var user = this.props.firebase.auth.currentUser;
      if (user) { 
        this.props.firebase.users().once('value', snapshot => {
          const usersObject = snapshot.val();
          if (usersObject) {
            var usuariosList = Object.keys(usersObject).map(key => ({
              ...usersObject[key],
              uid: key,
            }));
            usuariosList = usuariosList.filter(usu => usu.Clave === user.uid)
            if (usuariosList&&usuariosList.length>0){resolve(usuariosList[0])}
            else{resolve(false)}
            
          
          }else{resolve(false)}
        })
      }else{resolve(false)}
      })
    }
    componentWillUnmount() {
      this.listener();
    }
    render() {
      return (
        <AuthUserContext.Consumer>
        {authUser =>
          condition(authUser) ? <Component {...this.props} /> : null
        }
      </AuthUserContext.Consumer>
      );
    }
  }
  return compose(
    withRouter,
    withFirebase,
  )(WithAuthorization);
};
export default withAuthorization;
